import React, { Component } from "react";
import {
  Wrapper,
  Title,
  Card,
  CardBody,
  Row,
  KeyboardView
} from "app/NativeComponents/common";
import { InlineTabs } from "app/NativeComponents/snippets";

import Permissions from "./Permissions";

class TabbedItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: "permissions"
    };
  }

  selectTab(tab) {
    this.setState({ tab });
  }

  render() {
    if (this.props.user.can_mimic_team_owner == 1) {
      if (this.props.active_team_member.member_type == "team_member") {
        return (
          <>
            <Permissions tab={this.state.tab} {...this.props} />
          </>
        );
      }
    }

    return <Wrapper />;
  }
}

export default TabbedItems;
