import React, { useMemo } from "react";
import {
  Wrapper,
  Row,
  Spin,
  Copy,
  Icon,
  DraggableList
} from "app/NativeComponents/common";
import { SelectItem } from "app/NativeComponents/snippets";

const ListView = React.memo(({ list_columns, column_search, checkListItem, reorderItems }) => {

  // Optimize column search using useMemo to prevent unnecessary calculations
  const filteredColumns = useMemo(() => {
    if (!list_columns || list_columns.length === 0) return [];

    return list_columns.filter(column =>
      !column.main_column &&
      (!column_search || column.title.toLowerCase().includes(column_search.toLowerCase()))
    );
  }, [list_columns, column_search]);

  // If no columns are available, show a loading state
  if (!list_columns || list_columns.length === 0) {
    return (
      <Wrapper style={{ padding: 20, alignItems: "center", justifyContent: "center" }}>
        <Row>
          <Spin size="small" />
          <Copy style={{ marginLeft: 10 }}>Loading...</Copy>
        </Row>
      </Wrapper>
    );
  }

  return (
    <DraggableList
      items={filteredColumns}
      reverse={false}
      style={{ flex: 1, width: 400 }}
      disabled={true}
      renderHandle={() => (
        <Wrapper
          className="dm-drag"
          style={{ padding: 15, paddingLeft: 0, alignItems: "center", justifyContent: "center" }}
        >
          <Icon icon="drag-indicator" size={18} />
        </Wrapper>
      )}
      renderItem={({ item }) => (
        <SelectItem
          key={item.slug}
          selected={!!item.show}
          onPress={() => checkListItem(item)}
          select_type="check"
        >
          {item.title}
        </SelectItem>
      )}
      onReorder={({ previousIndex, nextIndex }) => reorderItems({ previousIndex, nextIndex })}
    />
  );
});

export default ListView;
