import React, { Component } from "react";

import { Wrapper } from "app/NativeComponents/common";
import { IconButton, SearchBar } from "app/NativeComponents/snippets";

import { store } from "app/store";
class SearchPostcards extends Component {
  constructor(props) {
    super(props);

    this.state = { search: props.search };
  }

  render() {
    const isMobile = store.getState().native.isMobile;
    return (
      <Wrapper
        style={isMobile || this.props.global_templates ? { flex: 1 } : {}}
      >
        <SearchBar
          style={
            isMobile || this.props.global_templates
              ? { margin: 5, borderWidth: 0, borderBottomWidth: 0 }
              : {
                  width: isMobile || this.props.global_templates ? "100%" : 300,
                  marginBottom:
                    isMobile || this.props.global_templates ? 10 : 0,
                  marginRight: isMobile || this.props.global_templates ? 10 : 0
                }
          }
          value={this.state.search}
          title="Search Designs"
          onChange={value => {
            this.setState({
              search: value
            });
          }}
          showButton={isMobile || this.props.global_templates ? false : true}
          disabledButton={
            this.props.search_loading ||
            (this.state.search == this.props.search &&
              !this.props.search_loading)
          }
          buttonText={
            this.state.search !== this.props.search &&
            this.state.search.length == 0
              ? "Clear"
              : "Search"
          }
          buttonLoading={this.props.search_loading}
          onPress={() => {
            this.props.updateSearch(this.state.search);
          }}
          renderRight={
            !!this.state.search && !this.props.search_loading
              ? () => {
                  return (
                    <IconButton
                      icon={"close"}
                      button_type="small"
                      onPress={() => {
                        this.setState({
                          search: ""
                        });
                        this.props.updateSearch("");
                      }}
                    />
                  );
                }
              : null
          }
        />
      </Wrapper>
    );
  }
}

export default SearchPostcards;
