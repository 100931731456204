import React, { Component } from "react";

import {
  Wrapper,
  Copy,
  InnerCopy,
  Bold,
  Row,
  Icon
} from "app/NativeComponents/common";

import MoreInfoButton from "app/DealMachineCore/reuseable/MoreInfoButton";

import moment from "moment";
import { store } from "app/store";

import { getHelperDescription } from "app/NativeActions";

class PersonFlags extends Component {
  constructor(props) {
    super(props);

    this.renderMobileWrapper = this.renderMobileWrapper.bind(this);
  }

  renderMobileWrapper(renderContent = () => {}, key, title, content) {
    const device = store.getState().native.device;

    if (device === "mobile") {
      return (
        <MoreInfoButton
          key={key}
          title={title}
          content={content}
          style={{ alignSelf: "stretch" }}
          innerStyle={{ alignSelf: "stretch" }}
          renderComponent={({ hovering, pressedIn, buttonText }) => {
            return renderContent();
          }}
        />
      );
    }

    return renderContent();
  }

  render() {
    const { person } = this.props;
    const device = store.getState().native.device;
    const colors = store.getState().settings.colors;

    return (
      <Row style={{ flexWrap: "wrap" }}>
        {person?.known_litigator ? (
          <>
            {this.renderMobileWrapper(
              () => {
                return (
                  <Wrapper
                    noPress={true}
                    style={
                      this.props.round
                        ? {
                            backgroundColor: colors.error_color_muted,
                            borderRadius: 5,
                            padding: 5,
                            paddingRight: 10,
                            paddingLeft: 10,
                            marginRight: 5,
                            marginBottom: 5
                          }
                        : {
                            backgroundColor: colors.error_color_muted,
                            borderRadius: 5,
                            padding: 5,
                            paddingRight: 15,
                            paddingLeft: 15,
                            marginRight: 5,
                            marginBottom: 5
                          }
                    }
                  >
                    <Row>
                      <Copy style={{ fontSize: this.props.round ? 10 : 12 }}>
                        {"Known Litigator"}
                      </Copy>

                      {device === "desktop" ? (
                        <Wrapper
                          tooltip={
                            "Please note: This contact is a known litigator. We've removed all phone numbers from this contact. Proceed at your own risk."
                          }
                          tooltipPlacement="top"
                          style={{ marginLeft: 5 }}
                        >
                          <Icon
                            icon={"help"}
                            color={colors.light_text_color}
                            size={this.props.round ? 12 : 12}
                          />
                        </Wrapper>
                      ) : null}
                    </Row>
                  </Wrapper>
                );
              },
              "known_litigator",
              "Known Litigator",
              "Please note: This contact is a known litigator. We've removed all phone numbers from this contact. Proceed at your own risk."
            )}
          </>
        ) : null}
        {person?.person_flags.map((flag, i) => {
          if (!!flag.value) {
            let b_color = colors.gray_selected_color;
            let subtext = "";

            if (!this.props.round || i < 2) {
              return this.renderMobileWrapper(
                () => {
                  return (
                    <Wrapper
                      noPress={true}
                      key={"flag" + i}
                      style={
                        this.props.round
                          ? {
                              backgroundColor: b_color,
                              borderRadius: 5,
                              padding: 5,
                              paddingRight: 10,
                              paddingLeft: 10,
                              marginRight: 5,
                              marginBottom: 5
                            }
                          : {
                              backgroundColor: b_color,
                              borderRadius: 5,
                              padding: 5,
                              paddingRight: 15,
                              paddingLeft: 15,
                              marginRight: 5,
                              marginBottom: 5
                            }
                      }
                    >
                      <Row>
                        <Copy style={{ fontSize: this.props.round ? 10 : 12 }}>
                          {flag?.label}
                        </Copy>
                        {device === "desktop" ? (
                          <Wrapper
                            tooltipPlacement={"top"}
                            tooltip={getHelperDescription(flag?.value)}
                            style={{ marginLeft: 5 }}
                          >
                            <Icon
                              icon={"help"}
                              color={colors.light_text_color}
                              size={this.props.round ? 12 : 12}
                            />
                          </Wrapper>
                        ) : null}
                      </Row>
                    </Wrapper>
                  );
                },
                "flag" + i,
                flag?.label,
                getHelperDescription(flag?.value)
              );
            }
            if (this.props.round && i === 2) {
              //get the rest of the flags and combine the value key into a string seperated by a comma with a space
              let rest_of_flags = "";
              for (let i = 1; i < person?.person_flags.length; i++) {
                if (i === 2) {
                  rest_of_flags += person?.person_flags[i].label;
                } else {
                  rest_of_flags += ", " + person?.person_flags[i].label;
                }
              }
              return this.renderMobileWrapper(() => {
                return (
                  <Wrapper
                    key={"flag" + i}
                    style={{
                      backgroundColor: b_color,
                      borderRadius: 5,
                      padding: 5,
                      paddingRight: 10,
                      paddingLeft: 10,
                      marginRight: 5,
                      marginBottom: 5
                    }}
                    tooltip={rest_of_flags}
                    tooltipPlacement={"top"}
                  >
                    <Row>
                      <Copy style={{ fontSize: 10 }}>
                        +{person?.person_flags.length - 2}
                      </Copy>
                    </Row>
                  </Wrapper>
                );
              });
            }
          }
          return null;
        })}
      </Row>
    );
  }
}

export default PersonFlags;
