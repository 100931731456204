import React, { Component } from "react";
import { connect } from "react-redux";

import {
  updateActiveCall,
  updateUserSettings,
  setActiveDialerContact,
  updateVoiceClone,
  getQueuedNumbers,
  getCallSession,
  pushSidePanel,
  playAudio
} from "app/NativeActions";

import { Row, Wrapper } from "app/NativeComponents/common";
import { IconButton } from "app/NativeComponents/snippets";

import DialButton from "./DialButton";

class DialPad extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dtmfTones: {
        1: "/assets/sounds/dtmf/dtmf-1.mp3",
        2: "/assets/sounds/dtmf/dtmf-2.mp3",
        3: "/assets/sounds/dtmf/dtmf-3.mp3",
        4: "/assets/sounds/dtmf/dtmf-4.mp3",
        5: "/assets/sounds/dtmf/dtmf-5.mp3",
        6: "/assets/sounds/dtmf/dtmf-6.mp3",
        7: "/assets/sounds/dtmf/dtmf-7.mp3",
        8: "/assets/sounds/dtmf/dtmf-8.mp3",
        9: "/assets/sounds/dtmf/dtmf-9.mp3",
        0: "/assets/sounds/dtmf/dtmf-0.mp3",
        "*": "/assets/sounds/dtmf/dtmf-star.mp3",
        "#": "/assets/sounds/dtmf/dtmf-hash.mp3"
      }
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {}

  dialerNumber(number) {
    // Play the DTMF tone for the pressed number
    playAudio({
      web_file: this.state.dtmfTones[number],
      volume: 0.5
    });

    this.props.phoneCall({
      token: this.props.token,
      type: "press_keypad",
      call_id: this.props.active_call?.call_id,
      keypad_number: number
    });
  }

  render() {
    const { colors } = this.props;

    return (
      <Wrapper style={{ padding: 10 }}>
        <Row style={{ justifyContent: "center" }}>
          <DialButton
            colors={colors}
            onPress={() => {
              this.dialerNumber("1");
            }}
            label="1"
          />
          <DialButton
            colors={colors}
            onPress={() => {
              this.dialerNumber("2");
            }}
            label="2"
          />
          <DialButton
            colors={colors}
            onPress={() => {
              this.dialerNumber("3");
            }}
            label="3"
          />
        </Row>
        <Row style={{ justifyContent: "center" }}>
          <DialButton
            colors={colors}
            onPress={() => {
              this.dialerNumber("4");
            }}
            label="4"
          />
          <DialButton
            colors={colors}
            onPress={() => {
              this.dialerNumber("5");
            }}
            label="5"
          />
          <DialButton
            colors={colors}
            onPress={() => {
              this.dialerNumber("6");
            }}
            label="6"
          />
        </Row>
        <Row style={{ justifyContent: "center" }}>
          <DialButton
            colors={colors}
            onPress={() => {
              this.dialerNumber("7");
            }}
            label="7"
          />
          <DialButton
            colors={colors}
            onPress={() => {
              this.dialerNumber("8");
            }}
            label="8"
          />
          <DialButton
            colors={colors}
            onPress={() => {
              this.dialerNumber("9");
            }}
            label="9"
          />
        </Row>
        <Row style={{ justifyContent: "center" }}>
          <DialButton
            colors={colors}
            onPress={() => {
              this.dialerNumber("*");
            }}
            label="*"
          />
          <DialButton
            colors={colors}
            onPress={() => {
              this.dialerNumber("0");
            }}
            label="0"
          />
          <DialButton
            colors={colors}
            onPress={() => {
              this.dialerNumber("#");
            }}
            label="#"
          />
        </Row>
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ auth, settings, dialer, native, billing }) => {
  const { token, user } = auth;
  const { device, desktopMobile } = native;
  const { dark_mode, colors } = settings;
  const { active_dialer_contact, active_call } = dialer;

  return {
    token,
    user,
    dark_mode,
    device,
    desktopMobile,
    colors,
    active_dialer_contact,
    active_call
  };
};

export default connect(mapStateToProps, {
  updateActiveCall,
  setActiveDialerContact,
  updateUserSettings,
  updateVoiceClone,
  getQueuedNumbers,
  getCallSession,
  pushSidePanel
})(DialPad);
