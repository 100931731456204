import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  NewHeader,
  SelectItem,
  SmallLabel,
  UpsellButton
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  pushSidePanel,
  getLimitsForAllPlan,
  setPhotoToCameraroll,
  updateUserSettings,
  checkIfUserHasMetadata
} from "app/NativeActions";

class AppSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoCameraRoll: props.photoCameraRoll,
      hide_alma: props.user?.user_settings?.hide_alma
    };
  }
  handleBack() {
    this.props.popSidePanel();
  }

  render() {
    const { source_of_truth } = this.props;
    const limits = getLimitsForAllPlan(source_of_truth);
    const phone_plan = checkIfUserHasMetadata("dialer");
    const call_tracking = checkIfUserHasMetadata("call_tracking");
    const { photoCameraRoll } = this.state;

    if (!source_of_truth) {
      return null;
    }

    return (
      <Container>
        <NewHeader
          title="Application Settings"
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <>
            {this.props.user?.can_send_mail == 1 ? (
              <SelectItem
                select_type="none"
                icon="keyboard-arrow-right"
                onPress={() => {
                  this.props.pushSidePanel({
                    slug: "mail_settings",
                    overlay: true
                  });
                }}
              >
                Mail Settings
              </SelectItem>
            ) : null}

            {this.props.user?.can_edit_custom_fields == 1 ? (
              <SelectItem
                select_type="none"
                icon="keyboard-arrow-right"
                onPress={() => {
                  this.props.pushSidePanel({
                    slug: "manage_lead_tags",
                    overlay: true
                  });
                }}
              >
                Lead Tags
              </SelectItem>
            ) : null}
            {this.props.user?.user_version >= 6 &&
            this.props.user.can_edit_custom_fields == 1 ? (
              <UpsellButton
                meta_slug="custom_fields_limit"
                onLockPressOverride={() => {
                  if (!checkIfUserHasMetadata("paid_plan")) {
                    this.props.pushSidePanel({
                      slug: "purchase_plan_prompt",
                      overlay_modal: true,
                      data: {
                        title: "Get Custom Fields With DealMachine Pro.",
                        dismiss_button:
                          "No thanks. Let me continue without Custom Fields.",
                        no_free: true,
                        event_slug: "in_app_custom_fields"
                      },
                      locked: true
                    });
                  } else {
                    this.props.pushSidePanel({
                      slug: "custom_fields",
                      overlay: true
                    });
                  }
                }}
                onPress={() => {
                  if (!checkIfUserHasMetadata("paid_plan")) {
                    this.props.pushSidePanel({
                      slug: "purchase_plan_prompt",
                      overlay_modal: true,
                      data: {
                        title: "Get Custom Fields With DealMachine Pro.",
                        dismiss_button:
                          "No thanks. Let me continue without Custom Fields.",
                        no_free: true,
                        event_slug: "in_app_custom_fields"
                      },
                      locked: true
                    });
                  } else {
                    this.props.pushSidePanel({
                      slug: "custom_fields",
                      overlay: true
                    });
                  }
                }}
                renderLockedChildren={({ hovering }) => {
                  return (
                    <SelectItem
                      select_type="none"
                      icon={"lock"}
                      noPress={true}
                      pressedIn={hovering}
                    >
                      Custom Lead Fields
                    </SelectItem>
                  );
                }}
                renderChildren={({ hovering }) => {
                  return (
                    <SelectItem
                      select_type="none"
                      icon={"keyboard-arrow-right"}
                      noPress={true}
                      pressedIn={hovering}
                    >
                      Custom Lead Fields
                    </SelectItem>
                  );
                }}
              />
            ) : (
              <SelectItem
                select_type="none"
                icon={
                  limits.number_of_custom_fields == 0 ||
                  this.props.user.can_edit_custom_fields == 0
                    ? "lock"
                    : "keyboard-arrow-right"
                }
                onPress={() => {
                  if (!checkIfUserHasMetadata("paid_plan")) {
                    this.props.pushSidePanel({
                      slug: "purchase_plan_prompt",
                      overlay_modal: true,
                      data: {
                        title: "Get Custom Fields With DealMachine Pro.",
                        dismiss_button:
                          "No thanks. Let me continue without Custom Fields.",
                        no_free: true,
                        event_slug: "in_app_custom_fields"
                      },
                      locked: true
                    });
                  } else {
                    this.props.pushSidePanel({
                      slug: "custom_fields",
                      overlay: true
                    });
                  }
                }}
                disabled={
                  limits.number_of_custom_fields == 0 ||
                  this.props.user.can_edit_custom_fields == 0
                    ? true
                    : false
                }
              >
                Custom Lead Fields
              </SelectItem>
            )}

            {this.props.user?.user_version >= 6 &&
            this.props.user.can_edit_custom_fields == 1 &&
            !this.props.user?.workspace_id ? (
              <UpsellButton
                meta_slug="custom_statuses"
                onLockPressOverride={() => {
                  if (!checkIfUserHasMetadata("paid_plan")) {
                    this.props.pushSidePanel({
                      slug: "purchase_plan_prompt",
                      overlay_modal: true,
                      data: {
                        title: "Get Custom Statuses With DealMachine Pro.",
                        dismiss_button:
                          "No thanks. Let me continue without Custom Statuses.",
                        no_free: true,
                        event_slug: "in_app_custom_statuses"
                      },
                      locked: true
                    });
                  } else {
                    this.props.pushSidePanel({
                      slug: "custom_statuses",
                      overlay: true
                    });
                  }
                }}
                onPress={() => {
                  if (!checkIfUserHasMetadata("paid_plan")) {
                    this.props.pushSidePanel({
                      slug: "purchase_plan_prompt",
                      overlay_modal: true,
                      data: {
                        title: "Get Custom Statuses With DealMachine Pro.",
                        dismiss_button:
                          "No thanks. Let me continue without Custom Statuses.",
                        no_free: true,
                        event_slug: "in_app_custom_statuses"
                      },
                      locked: true
                    });
                  } else {
                    this.props.pushSidePanel({
                      slug: "custom_statuses",
                      overlay: true
                    });
                  }
                }}
                renderLockedChildren={({ hovering }) => {
                  return (
                    <SelectItem
                      select_type="none"
                      icon={"lock"}
                      noPress={true}
                      pressedIn={hovering}
                    >
                      Lead Statuses
                    </SelectItem>
                  );
                }}
                renderChildren={({ hovering }) => {
                  return (
                    <SelectItem
                      select_type="none"
                      icon={"keyboard-arrow-right"}
                      noPress={true}
                      pressedIn={hovering}
                    >
                      Lead Statuses
                    </SelectItem>
                  );
                }}
              />
            ) : null}

            {this.props.user.can_send_message == 1 ||
            (!checkIfUserHasMetadata("paid_plan") &&
              !checkIfUserHasMetadata("dialer")) ? (
              <SelectItem
                select_type={"none"}
                icon={
                  !checkIfUserHasMetadata("dialer")
                    ? "lock"
                    : "keyboard-arrow-right"
                }
                onPress={() => {
                  if (!checkIfUserHasMetadata("paid_plan")) {
                    this.props.pushSidePanel({
                      slug: "purchase_plan_prompt",
                      overlay_modal: true,
                      data: {
                        title: "Get an AI-Powered Dialer With DealMachine Pro.",
                        dismiss_button:
                          "No thanks. Let me continue without an AI-Powered Dialer.",
                        no_free: true,
                        event_slug: "in_app_dialer"
                      },
                      locked: true
                    });
                  } else {
                    this.props.pushSidePanel({
                      slug: "dialer_settings",
                      overlay: true
                    });
                  }
                }}
              >
                Dialer Settings
              </SelectItem>
            ) : null}

            {this.props.user.can_edit_templates == 1 ? (
              <SelectItem
                select_type="none"
                icon={
                  !checkIfUserHasMetadata("paid_plan")
                    ? "lock"
                    : "keyboard-arrow-right"
                }
                onPress={() => {
                  if (!checkIfUserHasMetadata("paid_plan")) {
                    this.props.pushSidePanel({
                      slug: "purchase_plan_prompt",
                      overlay_modal: true,
                      data: {
                        title:
                          "Get access to the Call Tracking With DealMachine Pro.",
                        dismiss_button:
                          "No thanks. Let me continue without the Call Tracking.",
                        no_free: true,
                        event_slug: "in_app_call_tracking"
                      },
                      locked: true
                    });
                  } else {
                    this.props.pushSidePanel({
                      slug: "call_tracking",
                      overlay: true
                    });
                  }
                }}
              >
                Call Tracking
              </SelectItem>
            ) : null}

            {this.props.user.can_mimic_team_owner == 1 ? (
              <SelectItem
                select_type="none"
                icon="keyboard-arrow-right"
                onPress={() => {
                  this.props.pushSidePanel({
                    slug: "basic_automation",
                    overlay: true
                  });
                }}
              >
                Basic Automation
              </SelectItem>
            ) : null}
          </>

          {this.props.user?.can_export_data == 1 ? (
            <>
              {source_of_truth.subscription.status === "trialing" ? (
                <SelectItem
                  select_type="none"
                  icon={"lock"}
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "end_trial",
                      data: {
                        title:
                          "You cannot access the API until your free trial ends.",
                        description:
                          "Pay now and start your subscription to export your leads."
                      }
                    });
                  }}
                >
                  API
                </SelectItem>
              ) : (
                <SelectItem
                  select_type="none"
                  icon={!limits?.api_access ? "lock" : "keyboard-arrow-right"}
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "api",
                      overlay: true
                    });
                  }}
                  disabled={!limits?.api_access ? true : false}
                >
                  API
                </SelectItem>
              )}
            </>
          ) : null}

          <SelectItem
            select_type="none"
            icon="keyboard-arrow-right"
            onPress={() => {
              this.props.pushSidePanel({
                slug: "notification_settings",
                overlay: true
              });
            }}
          >
            Notifications
          </SelectItem>

          {this.props.device == "mobile" ? (
            <SelectItem
              select_type="toggle"
              selected={photoCameraRoll}
              onPress={() => {
                this.setState({
                  photoCameraRoll: !photoCameraRoll
                });
                this.props.setPhotoToCameraroll(!photoCameraRoll);
                this.props.updateUserSettings({
                  token: this.props.token,
                  type: "save_to_camera_roll",
                  value: !photoCameraRoll
                });
              }}
              description={
                "When you take a photo, a photo will always be saved to your camera roll."
              }
            >
              Save photos to camera roll?
            </SelectItem>
          ) : null}
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors, photoCameraRoll } = settings;
  const { plan_modules, source_of_truth } = billing;

  return {
    token,
    user,
    device,
    colors,
    photoCameraRoll,
    plan_modules,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  getLimitsForAllPlan,
  setPhotoToCameraroll,
  updateUserSettings
})(AppSettings);
