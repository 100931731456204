import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Row,
  Bold,
  Copy,
  Card,
  InternalImage,
  Spin,
  ProgressBar,
  Icon,
  Title
} from "app/NativeComponents/common";
import {
  InlineButton,
  IconButton,
  InputBox,
  ConfirmInlineButton,
  UpsellButton,
  SelectItem
} from "app/NativeComponents/snippets";

import AddLocation from "./AddLocation";

import {
  numberWithCommas,
  startBuildingListv2,
  setActiveList,
  tabNavigation,
  addListToListTabs,
  getSourceOfTruth,
  pushSidePanel,
  renderDate,
  showErrorMessage,
  popAllSidePanels
} from "app/NativeActions";

class ListBuilder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: null,
      editing_title: ""
    };

    this.buildList = this.buildList.bind(this);
    this.getCoordinatesFromBounds = this.getCoordinatesFromBounds.bind(this);
    this.executeBuild = this.executeBuild.bind(this);
  }

  componentDidMount() {
    this.props.getSourceOfTruth({
      token: this.props.token,
      no_loading: true
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(this.props.active_list) !==
        JSON.stringify(prevProps.active_list) &&
      this.props.active_list?.id == this.state.list?.id
    ) {
      this.setState({ list: this.props.active_list });
    }

    if (this.state.list?.building !== prevState.list?.building) {
      this.props.refreshMap();
      this.props.getSourceOfTruth({
        token: this.props.token,
        no_loading: true
      });
    }
  }

  getCoordinatesFromBounds() {
    const { bounds } = this.props;

    //get drawing coordinates from the area bounds and return as an array similar to the drawing coordinates
    if (
      bounds &&
      !!bounds?.westLng &&
      !!bounds?.eastLng &&
      !!bounds?.northLat &&
      !!bounds?.southLat
    ) {
      const coordinates = [
        {
          longitude: bounds?.westLng,
          latitude: bounds?.northLat
        },
        {
          longitude: bounds?.eastLng,
          latitude: bounds?.northLat
        },
        {
          longitude: bounds?.eastLng,
          latitude: bounds?.southLat
        },
        {
          longitude: bounds?.westLng,
          latitude: bounds?.southLat
        },
        {
          longitude: bounds?.westLng,
          latitude: bounds?.northLat
        }
      ];
      return coordinates;
    }

    return [];
  }

  buildList() {
    const { source_of_truth, all_feature_metadata } = this.props;

    const list_builder_current_limit =
      parseInt(
        source_of_truth?.subscription?.metadata?.leads_added_from_list_builder
      ) || 0;
    let list_builder_count =
      parseInt(
        source_of_truth?.current_limits?.number_of_leads_added_from_list_builder
          ?.amount
      ) || 0;

    if (
      list_builder_current_limit !== "unlimited" &&
      list_builder_count + this.props.properties_on_map_count >
        list_builder_current_limit
    ) {
      let feature_upsell_info = null;

      for (let i = 0; i < all_feature_metadata?.length; i++) {
        if (
          all_feature_metadata[i].slug == "leads_added_from_list_builder" &&
          all_feature_metadata[i].unlock_limit <=
            list_builder_count + this.props.properties_on_map_count &&
          (all_feature_metadata[i].unlock_limit >
            feature_upsell_info?.unlock_limit ||
            feature_upsell_info == null)
        ) {
          feature_upsell_info = all_feature_metadata[i];
        }
      }

      this.props.pushSidePanel({
        slug: "unlock_feature_modal",
        overlay: true,
        data: {
          feature_upsell_info,
          renderContent: () => {
            return (
              <Wrapper style={{ padding: 25 }}>
                <Copy>
                  You have added{" "}
                  <Bold>{numberWithCommas(list_builder_count)} leads</Bold>{" "}
                  leads from List Builder. Adding these{" "}
                  <Bold>
                    {numberWithCommas(this.props.properties_on_map_count)}
                  </Bold>{" "}
                  properties will exceed your monthly limit of{" "}
                  <Bold>{numberWithCommas(list_builder_current_limit)}</Bold>.
                  Upgrade to continue. Your account will reset on{" "}
                  <Bold>
                    {renderDate(
                      source_of_truth?.subscription?.monthly_limit_end_date,
                      true
                    )}
                  </Bold>
                  .
                </Copy>
              </Wrapper>
            );
          },
          buttonText: "Upgrade And Build",
          confirmText: "Confirm Upgrade?",
          onSuccess: () => {
            this.executeBuild();
          }
        }
      });
    } else {
      this.executeBuild();
    }
  }

  executeBuild() {
    let sl = JSON.parse(JSON.stringify(this.props.search_locations));
    sl = sl.map(item => {
      item.coordinates = null;
      return item;
    });

    this.props.startBuildingListv2({
      token: this.props.token,
      title: "My List",
      list_filters: this.props.applied_highlight,
      location_type:
        this.props.drawing_coordinates &&
        this.props.drawing_coordinates?.length > 2
          ? "draw"
          : this.props.zoom < 13
          ? "zip"
          : "view_area",
      zip: this.props.zip,
      city: this.props.city,
      fips: this.props.fips,
      state: this.props.state,
      drawing_coordinates:
        this.props.drawing_coordinates &&
        this.props.drawing_coordinates?.length > 2
          ? this.props.drawing_coordinates
          : this.getCoordinatesFromBounds(),
      estimated_count: this.props.properties_on_map_count,
      property_flags: this.props.property_flags
        .map(({ value }) => value)
        .join(","),
      property_types: this.props.property_types
        .map(({ value }) => value)
        .join(","),
      property_flags_and_or: this.props.property_flags_and_or,
      value_range_min: this.props.value_range_min,
      value_range_max: this.props.value_range_max,
      price_type: this.props.price_type,
      beds_min: this.props.beds_min,
      baths_min: this.props.baths_min,
      use_beds_exact: this.props.use_beds_exact,
      search_locations: sl,
      onLoading: () => {
        this.props.updateMainState({
          start_building: true
        });
      },
      onError: error => {
        this.props.updateMainState({
          start_building: false
        });
      },
      onSuccess: results => {
        this.setState(
          {
            list: results?.list,
            editing_title: results.list?.title
          },
          () => {
            this.props.setActiveList(results.list);

            if (results.list?.building == 0) {
              this.props.refreshMap();
            }

            this.props.updateMainState({
              start_building: false
            });
          }
        );
        this.props.popAllSidePanels();
      }
    });
  }

  render() {
    const { isMobile, colors, source_of_truth } = this.props;
    const { list } = this.state;

    let progress = Math.ceil(
      (parseInt(this.props.active_list_progress) /
        parseInt(this.state.list?.estimated_count)) *
        100
    );
    if (progress > 100) {
      progress = 100;
    }
    let require_confirm = true;
    const list_builder_current_limit = parseInt(
      source_of_truth?.subscription?.metadata?.leads_added_from_list_builder
    );
    let list_builder_count = parseInt(
      source_of_truth?.current_limits?.number_of_leads_added_from_list_builder
        ?.amount
    );
    if (
      list_builder_current_limit !== "unlimited" &&
      list_builder_count + this.props.properties_on_map_count >
        list_builder_current_limit
    ) {
      require_confirm = false;
    }

    return (
      <>
        <Card
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            margin: 0
          }}
        >
          <>
            {list && list?.id ? (
              <>
                <Wrapper
                  style={{
                    backgroundColor: colors.card_color,
                    width: "100%"
                  }}
                >
                  <Wrapper
                    className={"list-builder-progress-bar"}
                    style={{
                      backgroundColor: colors.success_color_muted,
                      width:
                        this.state.list?.building == 1
                          ? progress + "%"
                          : "100%",
                      height: 55
                    }}
                  />
                </Wrapper>

                <Wrapper
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: "100%",
                    height: "100%",
                    justifyContent: "center"
                  }}
                >
                  <Row>
                    {this.state.list?.building == 1 ? (
                      <Wrapper style={{ paddingLeft: 25 }}>
                        <Spin size={"small"} />
                      </Wrapper>
                    ) : (
                      <Wrapper style={{ paddingLeft: 25 }}>
                        <Icon
                          icon={"check-circle"}
                          color={colors.success_color}
                        />
                      </Wrapper>
                    )}
                    <Wrapper
                      style={{ paddingRight: 25, paddingLeft: 25, flex: 1 }}
                    >
                      <Copy style={{ color: colors.actionable_text_color }}>
                        <Bold>{this.state.list?.title}</Bold>
                      </Copy>
                      <Copy style={{ fontSize: 12 }}>
                        {this.state.list?.building == 1
                          ? "Building " +
                            numberWithCommas(this.props.active_list_progress) +
                            " of "
                          : "Build complete for "}
                        <Bold>
                          {numberWithCommas(this.state.list?.estimated_count)}{" "}
                        </Bold>
                        {this.state.list?.estimated_count == 1
                          ? "property."
                          : " properties."}

                        {this.props
                          .active_list_other_workspace_team_success_counter >
                          0 &&
                          (
                            this.props.user?.workspace_id ||
                            this.props.user?.team_type === "workspace"
                          )(
                            <Copy style={{ fontSize: 12 }}>
                              {numberWithCommas(
                                this.props
                                  .active_list_other_workspace_team_success_counter
                              )}{" "}
                              properties were already added by other teams.
                            </Copy>
                          )}
                      </Copy>
                    </Wrapper>
                    <InlineButton
                      onPress={() => {
                        this.props.addListToListTabs(this.state.list);
                        this.props.tabNavigation({ slug: "leads" });
                      }}
                      disabled={this.state.list?.building == 1}
                    >
                      View List
                    </InlineButton>
                    <IconButton
                      onPress={() => {
                        this.setState({
                          list: null,
                          editing_title: ""
                        });
                      }}
                      tooltipPlacement={"top"}
                      tooltip={"Dismiss"}
                      icon={"close"}
                    />
                  </Row>
                </Wrapper>
              </>
            ) : (
              <Row>
                <Wrapper
                  style={{
                    paddingRight: 25,
                    paddingLeft: 25,
                    flex: 1
                  }}
                >
                  <Row>
                    {this.props.properties_on_map_count_loading ? (
                      <Wrapper style={{ marginRight: 5 }}>
                        <Spin size={"small"} />
                      </Wrapper>
                    ) : (
                      <Copy style={{ marginRight: 5 }}>
                        <Bold>
                          {numberWithCommas(this.props.properties_on_map_count)}
                        </Bold>
                      </Copy>
                    )}
                    <Copy>
                      {this.props.list_view_type === "contacts"
                        ? this.props.properties_on_map_count == 1
                          ? " contact in "
                          : " contacts in "
                        : this.props.properties_on_map_count == 1
                        ? " property in "
                        : " properties in "}
                      {this.props.search_locations?.length == 0 ? (
                        <>
                          <Bold
                            style={{
                              color: this.props.colors.actionable_text_color
                            }}
                          >
                            {this.props.drawing_coordinates &&
                            this.props.drawing_coordinates?.length > 2
                              ? "drawn area"
                              : this.props.zoom < 13
                              ? !!this.props.zip
                                ? this.props.zip
                                : "zip code"
                              : "viewing area"}
                          </Bold>
                          {this.props.checkIfThereAreFilters()
                            ? " matching filters."
                            : "."}
                        </>
                      ) : null}
                    </Copy>
                    {this.props.search_locations?.length > 0 ? (
                      <>
                        {this.props.search_locations.map((location, i) => {
                          return (
                            <InlineButton
                              key={"location_" + i}
                              style={{
                                borderRadius: 5,
                                borderWidth: 1,
                                borderColor: colors.active_color,
                                borderStyle: "solid",
                                margin: 0,
                                marginLeft: 5,
                                paddingRight: 5,
                                paddingTop: 0,
                                paddingBottom: 0
                              }}
                              selected={true}
                              onPress={() => {
                                this.props.updateLatLng({
                                  longitude: location?.longitude,
                                  latitude: location?.latitude,
                                  heading: 0,
                                  pitch: 0,
                                  zoom: 11,
                                  coordinates: location?.coordinates
                                });
                              }}
                            >
                              <Row>
                                <Copy>{location.label}</Copy>
                                <IconButton
                                  icon={"close"}
                                  button_type={"small"}
                                  style={{
                                    margin: 0
                                  }}
                                  innerStyle={{}}
                                  onPress={() => {
                                    //remove location from this.state.search_locations by i
                                    this.props.updateMapFilters({
                                      search_locations:
                                        this.props.search_locations.filter(
                                          (l, index) => {
                                            return index != i;
                                          }
                                        )
                                    });
                                  }}
                                />
                              </Row>
                            </InlineButton>
                          );
                        })}
                      </>
                    ) : null}
                  </Row>
                  {this.props.main_map_location?.zoom < 12 &&
                  this.props.search_locations?.length === 0 &&
                  this.props.user?.has_routes ? (
                    <Row>
                      <Copy style={{ fontSize: 12 }}>
                        Zoom in to see driven routes.
                      </Copy>
                    </Row>
                  ) : null}
                </Wrapper>

                {this.props.user?.can_build_lists == 1 ? (
                  this.props.source_of_truth?.subscription?.metadata
                    .list_builder ? (
                    <ConfirmInlineButton
                      require_confirm={require_confirm}
                      button_type="full"
                      icon={"playlist-add"}
                      confirm_text="Confirm Build?"
                      confirm_icon="check"
                      loading_text="Building..."
                      loading={this.props.start_building}
                      disabled={
                        this.props.properties_on_map_count_loading ||
                        (this.props.drawing &&
                          this.props.drawing_coordinates.length <= 2) ||
                        this.props.select_drive_start ||
                        this.props.properties_on_map_count == 0
                      }
                      onPress={this.buildList}
                      primary={true}
                    >
                      Build List
                    </ConfirmInlineButton>
                  ) : (
                    <UpsellButton
                      meta_slug="list_builder"
                      onPress={this.buildList}
                      disabled={
                        this.props.properties_on_map_count_loading ||
                        (this.props.drawing &&
                          this.props.drawing_coordinates.length <= 2) ||
                        this.props.select_drive_start ||
                        this.props.properties_on_map_count == 0
                      }
                      renderLockedChildren={({ hovering }) => {
                        return (
                          <>
                            <InlineButton
                              select_icon="lock"
                              noPress={true}
                              pressedIn={hovering}
                              primary={true}
                              disabled={
                                this.props.properties_on_map_count_loading ||
                                (this.props.drawing &&
                                  this.props.drawing_coordinates.length <= 2) ||
                                this.props.select_drive_start ||
                                this.props.properties_on_map_count == 0
                              }
                            >
                              Build List
                            </InlineButton>
                          </>
                        );
                      }}
                      renderChildren={({ hovering }) => {
                        return (
                          <>
                            <InlineButton
                              select_icon="playlist-add"
                              noPress={true}
                              pressedIn={hovering}
                              primary={true}
                            >
                              Build List
                            </InlineButton>
                          </>
                        );
                      }}
                    />
                  )
                ) : (
                  <Wrapper style={{ minHeight: 58 }} />
                )}
              </Row>
            )}
          </>
        </Card>
      </>
    );
  }
}

const mapStateToProps = ({
  auth,
  settings,
  native,
  property_map,
  list,
  billing
}) => {
  const { token, user } = auth;
  const { main_map_location } = property_map;
  const { colors } = settings;
  const { device, isMobile } = native;
  const {
    building_lists,
    active_list,
    active_list_progress,
    active_list_other_workspace_team_success_counter
  } = list;
  const { source_of_truth, all_feature_metadata } = billing;
  return {
    token,
    user,
    colors,
    device,
    isMobile,
    building_lists,
    active_list,
    active_list_progress,
    active_list_other_workspace_team_success_counter,
    source_of_truth,
    all_feature_metadata,
    main_map_location
  };
};

export default connect(mapStateToProps, {
  startBuildingListv2,
  setActiveList,
  addListToListTabs,
  tabNavigation,
  getSourceOfTruth,
  pushSidePanel,
  showErrorMessage,
  popAllSidePanels
})(ListBuilder);
