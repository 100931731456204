import React, { Component } from "react";

import { Wrapper, Copy, Row, Icon, Bold } from "app/NativeComponents/common";
import {
  GhostButton,
  InlineButton,
  SelectItem
} from "app/NativeComponents/snippets";
class MappingRequirements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapping_check_array: [
        {
          title: "Full Address",
          slug: "full_address_check"
        },
        {
          title: "Lat/Long",
          slug: "lat_lng_check"
        },
        {
          title: "Property ID",
          slug: "property_id_check"
        },
        {
          title: "APN",
          slug: "apn_check"
        }
      ]
    };
  }
  render() {
    const anyFieldSelected = Object.values(this.props.fields_are_selected).some(
      Boolean
    );
    let colors = this.props.colors;

    return (
      <>
        <SelectItem
          select_type={"icon"}
          select_icon={"key"}
          noPress={true}
          selected={true}
          description={
            "Map fields on the right to the columns in your spreadsheet. Only one of the options below must be mapped."
          }
          renderRight={() => (
            <InlineButton
              noPress={true}
              primary={false}
              tooltip={
                "DealMachine uses the values from your upload to find and add properties to your account. You must map one of the required fields to a column in your spreadsheet in order to import to DealMachine."
              }
              tooltipPlacement={"top"}
              icon={"help"}
              icon_color={colors.gray_selected_color}
              button_type={"small"}
              style={{ paddingRight: 25 }}
            ></InlineButton>
          )}
        >
          Fields required to import properties
        </SelectItem>

        {this.state.mapping_check_array.map((item, i) => (
          <SelectItem
            key={"mapping_check_" + i}
            noPress={true}
            selected={this.props.fields_are_selected[item.slug]}
            disabled={!this.props.fields_are_selected[item.slug]}
          >
            {item.title}
          </SelectItem>
        ))}
      </>
    );
  }
}

export default MappingRequirements;
