import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  KeyboardView,
  Row,
  Wrapper,
  Bold,
  Copy,
  Spin
} from "app/NativeComponents/common";
import {
  NewHeader,
  SelectItem,
  InlineButton,
  SpinWrapper,
  InputBox
} from "app/NativeComponents/snippets";

import {
  generateWorkspaceTeamName,
  popSidePanel,
  editWorkspaceTeam,
  showErrorMessage,
  numberWithCommas,
  pushSidePanel,
  renderPrice,
  getBilling,
  editWorkspaceSettings
} from "app/NativeActions";

class ManageWorkspaceTeamBudget extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    this.state = {
      workspace_team: panel_data?.team,

      loading: false,
      marketing_credits: null,

      should_team_handle_funds_loading: false,
      does_team_have_allowance_loading: false,
      monthly_allowance_cents_loading: false
    };

    this._team_name = React.createRef();

    this.onPurchaseSuccess = this.onPurchaseSuccess.bind(this);
  }

  componentDidMount() {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    if (!panel_data?.team) {
      this.props.popSidePanel();
    }

    this.props.getBilling({
      token: this.props.token,
      type: "deal_credits",
      team_id: this.state.workspace_team?.id,
      onLoading: () => {
        this.setState({ loading: true });
      },
      onError: () => {
        this.setState({ loading: false });
      },
      onSuccess: results => {
        this.setState({
          loading: false,
          marketing_credits: results?.deal_credits
        });
      }
    });
  }

  onPurchaseSuccess(results) {
    this.setState({
      marketing_credits: results?.deal_credits
    });
  }

  render() {
    const { marketing_credits } = this.state;

    return (
      <Container>
        <NewHeader
          title={"Manage Budget"}
          subtitle={""}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.props.popSidePanel();
            }
          }}
        />

        <KeyboardView style={{ flex: 1 }}>
          {this.state.loading ? (
            <Wrapper style={{ marginRight: 25, marginLeft: 25 }}>
              <SpinWrapper text="Loading" />
            </Wrapper>
          ) : (
            <>
              <SelectItem
                innerStyle={{ paddingTop: 15, paddingBottom: 15 }}
                select_type="none"
                noPress={true}
                subtext={"Marketing Credits"}
                renderRight={() => {
                  if (this.props.platform !== "ios") {
                    return (
                      <InlineButton
                        onPress={() => {
                          if (
                            this.props.source_of_truth?.has_stripe_card_on_file
                          ) {
                            this.props.pushSidePanel({
                              slug: "add_marketing_credits",
                              overlay: true,
                              data: {
                                team_id: this.state.workspace_team?.id,
                                team_name: this.state.workspace_team?.team_name,
                                onPurchaseSuccess: this.onPurchaseSuccess
                              }
                            });
                          } else {
                            this.props.pushSidePanel({
                              slug: "add_card_prompt",
                              overlay_modal: true,
                              data: {
                                title:
                                  "Add a card to add more credit to your account.",
                                dismiss_button:
                                  "No thanks. Let me continue without adding credits."
                              },
                              locked: true
                            });
                          }
                        }}
                        icon_right="keyboard-arrow-right"
                      >
                        Add Credits
                      </InlineButton>
                    );
                  }
                  return null;
                }}
              >
                {renderPrice(marketing_credits?.deal_credits_remaining?.cents)}
              </SelectItem>

              <SelectItem
                selected={!this.state.workspace_team?.should_team_handle_funds}
                select_type="toggle"
                description={
                  "When set to true, this administrator account will cover all marketing charges for this team. Otherwise the team leader will be billed for any charges."
                }
                disabled={this.state?.should_team_handle_funds_loading}
                onPress={() => {
                  this.setState(
                    {
                      should_team_handle_funds_loading: true,
                      workspace_team: {
                        ...this.state.workspace_team,
                        should_team_handle_funds:
                          !this.state.workspace_team?.should_team_handle_funds
                      }
                    },
                    () => {
                      this.props.editWorkspaceSettings({
                        token: this.props.token,
                        type: "edit_workspace_team_budget",
                        team_id: this.state.workspace_team?.id,
                        setting_key: "should_team_handle_funds",
                        setting_value:
                          this.state?.workspace_team?.should_team_handle_funds,
                        onLoading: () => {
                          this.setState({
                            should_team_handle_funds_loading: true
                          });
                        },
                        onError: () => {
                          this.setState({
                            should_team_handle_funds_loading: false
                          });
                        },
                        onSuccess: results => {
                          this.setState({
                            workspace_team: results?.workspace_team,
                            should_team_handle_funds_loading: false
                          });
                        }
                      });
                    }
                  );
                }}
              >
                Cover Marketing Charges
              </SelectItem>

              {this.state.workspace_team?.should_team_handle_funds ? (
                <>
                  <SelectItem
                    selected={
                      this.state.workspace_team?.does_team_have_allowance
                    }
                    select_type="toggle"
                    description={
                      "Set a monthly allowance for this team. This will add X number of marketing credits to the team every month. Any marketing credit charges over the allowance will be billed to the team leader."
                    }
                    disabled={
                      this.state?.does_team_have_allowance_loading ||
                      this.state?.should_team_handle_funds_loading
                    }
                    onPress={() => {
                      this.setState(
                        {
                          does_team_have_allowance_loading: true,
                          workspace_team: {
                            ...this.state.workspace_team,
                            does_team_have_allowance:
                              !this.state.workspace_team
                                ?.does_team_have_allowance
                          }
                        },
                        () => {
                          this.props.editWorkspaceSettings({
                            token: this.props.token,
                            type: "edit_workspace_team_budget",
                            team_id: this.state.workspace_team?.id,
                            setting_key: "does_team_have_allowance",
                            setting_value:
                              this.state?.workspace_team
                                ?.does_team_have_allowance,
                            onLoading: () => {
                              this.setState({
                                does_team_have_allowance_loading: true
                              });
                            },
                            onError: () => {
                              this.setState({
                                does_team_have_allowance_loading: false
                              });
                            },
                            onSuccess: results => {
                              this.setState({
                                workspace_team: results?.workspace_team,
                                does_team_have_allowance_loading: false
                              });
                            }
                          });
                        }
                      );
                    }}
                  >
                    Add Allowance
                  </SelectItem>
                  {this.state.workspace_team?.does_team_have_allowance && (
                    <InputBox
                      input_ref={this._allowance}
                      name="allowance"
                      placeholder="Monthly Allowance"
                      type="text"
                      disabled={
                        this.state?.monthly_allowance_cents_loading ||
                        this.state?.does_team_have_allowance_loading ||
                        this.state?.should_team_handle_funds_loading
                      }
                      loading={this.state?.monthly_allowance_cents_loading}
                      renderRight={
                        this.state?.monthly_allowance_cents_loading
                          ? () => {
                              return <Spin size="small" />;
                            }
                          : null
                      }
                      onChange={value => {
                        const numbers = parseInt(value.replace(/\D+/g, ""));

                        this.setState({
                          monthly_allowance_cents:
                            parseInt(numbers) === 0 || !numbers
                              ? ""
                              : parseInt(numbers)
                        });

                        this.setState(
                          {
                            monthly_allowance_cents_loading: true,
                            workspace_team: {
                              ...this.state.workspace_team,
                              monthly_allowance_cents:
                                parseInt(numbers) === 0 || !numbers
                                  ? ""
                                  : parseInt(numbers)
                            }
                          },
                          () => {
                            this.props.editWorkspaceSettings({
                              token: this.props.token,
                              type: "edit_workspace_team_budget",
                              team_id: this.state.workspace_team?.id,
                              setting_key: "monthly_allowance_cents",
                              setting_value:
                                this.state?.workspace_team
                                  ?.monthly_allowance_cents,
                              onLoading: () => {
                                this.setState({
                                  monthly_allowance_cents_loading: true
                                });
                              },
                              onError: () => {
                                this.setState({
                                  monthly_allowance_cents_loading: false
                                });
                              },
                              onSuccess: results => {
                                this.setState({
                                  workspace_team: results?.workspace_team,
                                  monthly_allowance_cents_loading: false
                                });
                              }
                            });
                          }
                        );
                      }}
                      value={
                        !!this.state.workspace_team?.monthly_allowance_cents
                          ? "$" +
                            numberWithCommas(
                              parseInt(
                                this.state.workspace_team
                                  ?.monthly_allowance_cents / 100
                              ).toString()
                            )
                          : ""
                      }
                      options={[
                        {
                          value: "10000",
                          label: "$100"
                        },
                        {
                          value: "20000",
                          label: "$200"
                        },
                        {
                          value: "30000",
                          label: "$300"
                        },
                        {
                          value: "40000",
                          label: "$400"
                        },
                        {
                          value: "50000",
                          label: "$500"
                        },
                        {
                          value: "100000",
                          label: "$1,000"
                        },
                        {
                          value: "200000",
                          label: "$2,000"
                        },
                        {
                          value: "300000",
                          label: "$3,000"
                        },
                        {
                          value: "400000",
                          label: "$4,000"
                        },
                        {
                          value: "500000",
                          label: "$5,000"
                        }
                      ]}
                    />
                  )}
                </>
              ) : null}
            </>
          )}
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user, user_partner_info } = auth;
  const { device, isIphoneX, isMobile, platform, desktopMobile } = native;
  const { colors } = settings;
  const { source_of_truth } = billing;
  return {
    user,
    user_partner_info,
    token,
    device,
    isIphoneX,
    isMobile,
    platform,
    desktopMobile,
    colors,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  generateWorkspaceTeamName,
  editWorkspaceTeam,
  showErrorMessage,
  pushSidePanel,
  popSidePanel,
  getBilling,
  editWorkspaceSettings
})(ManageWorkspaceTeamBudget);
