import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Wrapper, Copy } from "app/NativeComponents/common";
import {
  NewHeader,
  SpinWrapper,
  SelectItem,
  List
} from "app/NativeComponents/snippets";

import {
  getHighlightTemplates,
  popSidePanel,
  setAppliedHighlight,
  setMapProperties,
  updateMapFilters,
  pushSidePanel
} from "app/NativeActions";

class SavedSearches extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searches_loading: false,
      selected_highlight: null
    };
  }

  componentDidMount() {
    this.props.getHighlightTemplates({
      token: this.props.token,
      onLoading: () => {
        this.setState({
          searches_loading:
            this.props.saved_highlights?.length == 0 ? true : false
        });
      },
      onError: () => {
        this.setState({
          searches_loading: false
        });
      },
      onSuccess: () => {
        this.setState({
          searches_loading: false
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {}

  render() {
    const { colors, isMobile, saved_highlights } = this.props;

    return (
      <Container style={{ backgroundColor: colors.card_color }}>
        <NewHeader
          title={"Saved Searches"}
          leftButton={{
            icon: this.props.device == "desktop" ? "close" : "arrow-back",
            onPress: () => {
              this.props.popSidePanel();
            }
          }}
        />

        {this.state.searches_loading ? (
          <SpinWrapper text="Loading" />
        ) : !this.state.searches_loading && saved_highlights.length === 0 ? (
          <Wrapper
            style={{
              padding: 25,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Copy style={{ textAlign: "center" }}>
              You have no saved searches.
            </Copy>
          </Wrapper>
        ) : (
          <>
            <List
              items={saved_highlights}
              itemStructure={({ item, index }) => {
                return (
                  <SelectItem
                    key={"highlight_templates_" + index}
                    icon={"keyboard-arrow-right"}
                    select_type="none"
                    selected={this.state.selected_highlight == item.id}
                    onPress={() => {
                      this.setState({
                        selected_highlight: item.id
                      });
                    }}
                    confirm_text="Apply"
                    require_confirm={
                      this.state.selected_highlight ? true : false
                    }
                    onConfirm={() => {
                      this.props.setMapProperties([]);

                      if (!!item?.filters) {
                        this.props.setAppliedHighlight({
                          applied_highlight: item?.filters
                        });
                      }

                      this.props.updateMapFilters({
                        property_flags: item?.property_flags || [],
                        property_types: item?.property_types || [],
                        property_flags_and_or:
                          item?.property_flags_and_or || "or",
                        value_range_max: item?.value_range_max || "",
                        value_range_min: item?.value_range_min || "",
                        price_type: item?.price_type || "estimated_value",
                        beds_min: item?.beds_min || "",
                        baths_min: item?.baths_min || "",
                        use_beds_exact: item?.use_beds_exact || false
                      });
                      this.props.popSidePanel();
                    }}
                    secondaryButton={"Edit"}
                    onSecondaryPress={() => {
                      this.props.pushSidePanel({
                        slug: "saved_search",
                        overlay: true,
                        data: {
                          saved_filter: item
                        }
                      });
                    }}
                  >
                    {item.title}
                  </SelectItem>
                );
              }}
            />
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, property_map }) => {
  const { token, user } = auth;
  const { device, window_height, isMobile } = native;
  const { colors, dark_mode } = settings;
  const { highlight_templates, saved_highlights } = property_map;

  return {
    token,
    user,
    device,
    isMobile,
    window_height,
    colors,
    dark_mode,
    highlight_templates,
    saved_highlights
  };
};

export default connect(mapStateToProps, {
  getHighlightTemplates,
  popSidePanel,
  setAppliedHighlight,
  setMapProperties,
  updateMapFilters,
  pushSidePanel
})(SavedSearches);
