import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  DropzoneButton,
  Row
} from "app/NativeComponents/common";
import {
  NewHeader,
  SelectItem,
  SpinWrapper,
  InlineButton
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  pushSidePanel,
  getLimitsForAllPlan,
  setPhotoToCameraroll,
  updateUserSettings,
  getWorkspaceSettings,
  editWorkspaceSettings,
  uploadFile,
  showErrorMessage
} from "app/NativeActions";

class WorkspaceAppSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      workspace_settings: props.workspace_settings,
      should_scrub_dnc_phone_numbers_loading: false,
      should_hide_dnc_phone_numbers_loading: false,
      should_see_other_teams_leads_loading: false,
      uploading: false
    };
  }

  componentDidMount() {
    this.props.getWorkspaceSettings({
      token: this.props.token,
      onLoading: () => {
        this.setState({ loading: true });
      },
      onError: () => {
        this.setState({ loading: false });
      },
      onSuccess: results => {
        this.setState({
          loading: false,
          workspace_settings: results?.workspace_settings
        });
      }
    });
  }

  handleBack() {
    this.props.popSidePanel();
  }

  render() {
    const { source_of_truth } = this.props;

    if (!source_of_truth) {
      return null;
    }
    const workspace_image = this.state?.workspace_settings?.workspace_image;
    return (
      <Container>
        <NewHeader
          title="Application Settings For All Teams"
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          {this.state.loading ? (
            <SpinWrapper text="Loading" />
          ) : (
            <>
              <SelectItem
                select_type={"none"}
                noPress={true}
                renderRight={() => {
                  return (
                    <Row>
                      <DropzoneButton
                        accept="image/jpeg, image/png"
                        maxSize={5242880}
                        onDrop={acceptedFiles => {
                          this.setState(
                            {
                              uploading: true
                            },
                            () => {
                              this.props.uploadFile({
                                acceptedFiles: acceptedFiles[0],
                                token: this.props.token,
                                onSuccess: results => {
                                  this.props.editWorkspaceSettings({
                                    token: this.props.token,
                                    type: "edit_workspace_settings",
                                    setting_key: "workspace_image",
                                    setting_value: results?.file,
                                    onLoading: () => {},
                                    onError: error => {
                                      this.setState({
                                        uploading: false
                                      });
                                      this.props.showErrorMessage(
                                        error || "Error uploading file",
                                        "Error"
                                      );
                                    },
                                    onSuccess: results => {
                                      this.setState({
                                        uploading: false,
                                        workspace_settings:
                                          results?.workspace_settings
                                      });
                                    }
                                  });
                                },
                                onError: error => {
                                  this.setState({
                                    uploading: false
                                  });

                                  this.props.showErrorMessage(
                                    error || "Error uploading file",
                                    "Error"
                                  );
                                }
                              });
                            }
                          );
                        }}
                        renderComponent={options => {
                          return (
                            <InlineButton
                              icon="file-upload"
                              noPress={true}
                              pressedIn={options.hovering}
                              primary={false}
                              upload_type="photos"
                              loading={this.state.uploading}
                            >
                              Upload Photo
                            </InlineButton>
                          );
                        }}
                      ></DropzoneButton>
                      {!!workspace_image ? (
                        <InlineButton
                          icon="delete"
                          onPress={() => {
                            this.setState(
                              {
                                removing_logo: true
                              },
                              () => {
                                this.props.editWorkspaceSettings({
                                  token: this.props.token,
                                  type: "edit_workspace_settings",
                                  setting_key: "workspace_image",
                                  setting_value: "",
                                  onLoading: () => {},
                                  onError: () => {
                                    this.setState({
                                      removing_logo: false
                                    });
                                  },
                                  onSuccess: results => {
                                    this.setState({
                                      removing_logo: false,
                                      workspace_settings:
                                        results?.workspace_settings
                                    });
                                  }
                                });
                              }
                            );
                          }}
                          loading={this.state.removing_logo}
                        >
                          Remove Logo
                        </InlineButton>
                      ) : null}
                    </Row>
                  );
                }}
                innerStyle={{ lineBreak: "anywhere" }}
                label={"Account Logo"}
              >
                {workspace_image || "Upload an logo for your account."}
              </SelectItem>

              <SelectItem
                select_type="toggle"
                selected={
                  this.state?.workspace_settings?.should_see_other_teams_leads
                }
                disabled={this.state?.should_see_other_teams_leads_loading}
                onPress={() => {
                  this.setState(
                    {
                      workspace_settings: {
                        ...this.state.workspace_settings,
                        should_see_other_teams_leads:
                          !this.state.workspace_settings
                            ?.should_see_other_teams_leads
                      }
                    },
                    () => {
                      this.props.editWorkspaceSettings({
                        token: this.props.token,
                        type: "edit_workspace_settings",
                        setting_key: "should_see_other_teams_leads",
                        setting_value:
                          this.state?.workspace_settings
                            ?.should_see_other_teams_leads,
                        onLoading: () => {
                          this.setState({
                            should_see_other_teams_leads_loading: true
                          });
                        },
                        onError: () => {
                          this.setState({
                            should_see_other_teams_leads_loading: false
                          });
                        },
                        onSuccess: results => {
                          this.setState({
                            should_see_other_teams_leads_loading: false,
                            workspace_settings: results?.workspace_settings
                          });
                        }
                      });
                    }
                  );
                }}
                description={
                  "This will show an alert when a property is added by another team and prevent the property from being added when building a list or when imported."
                }
              >
                Duplicate Prevention
              </SelectItem>

              <SelectItem
                select_type="toggle"
                selected={
                  this.state?.workspace_settings?.should_scrub_dnc_phone_numbers
                }
                disabled={this.state?.should_scrub_dnc_phone_numbers_loading}
                onPress={() => {
                  this.setState(
                    {
                      workspace_settings: {
                        ...this.state.workspace_settings,
                        should_scrub_dnc_phone_numbers:
                          !this.state.workspace_settings
                            ?.should_scrub_dnc_phone_numbers
                      }
                    },
                    () => {
                      this.props.editWorkspaceSettings({
                        token: this.props.token,
                        type: "edit_workspace_settings",
                        setting_key: "should_scrub_dnc_phone_numbers",
                        setting_value:
                          this.state?.workspace_settings
                            ?.should_scrub_dnc_phone_numbers,
                        onLoading: () => {
                          this.setState({
                            should_scrub_dnc_phone_numbers_loading: true
                          });
                        },
                        onError: () => {
                          this.setState({
                            should_scrub_dnc_phone_numbers_loading: false
                          });
                        },
                        onSuccess: results => {
                          this.setState({
                            should_scrub_dnc_phone_numbers_loading: false,
                            workspace_settings: results?.workspace_settings
                          });
                        }
                      });
                    }
                  );
                }}
                description={
                  "Prevent your teams from calling numbers on the DNC list."
                }
              >
                DNC Scrub
              </SelectItem>
              {/*this.state?.workspace_settings
                ?.should_scrub_dnc_phone_numbers ? (
                <SelectItem
                  select_type="toggle"
                  selected={
                    this.state?.workspace_settings
                      ?.should_hide_dnc_phone_numbers &&
                    this.state?.workspace_settings
                      ?.should_scrub_dnc_phone_numbers
                  }
                  disabled={
                    this.state?.should_hide_dnc_phone_numbers_loading ||
                    !this.state?.workspace_settings
                      ?.should_scrub_dnc_phone_numbers
                  }
                  onPress={() => {
                    this.setState(
                      {
                        workspace_settings: {
                          ...this.state.workspace_settings,
                          should_hide_dnc_phone_numbers:
                            !this.state.workspace_settings
                              ?.should_hide_dnc_phone_numbers
                        }
                      },
                      () => {
                        this.props.editWorkspaceSettings({
                          token: this.props.token,
                          type: "edit_workspace_settings",
                          setting_key: "should_hide_dnc_phone_numbers",
                          setting_value:
                            this.state?.workspace_settings
                              ?.should_hide_dnc_phone_numbers,
                          onLoading: () => {
                            this.setState({
                              should_hide_dnc_phone_numbers_loading: true
                            });
                          },
                          onError: () => {
                            this.setState({
                              should_hide_dnc_phone_numbers_loading: false
                            });
                          },
                          onSuccess: results => {
                            this.setState({
                              should_hide_dnc_phone_numbers_loading: false,
                              workspace_settings: results?.workspace_settings
                            });
                          }
                        });
                      }
                    );
                  }}
                  description={
                    "This will hide all DNC numbers from your teams."
                  }
                >
                  Hide DNC Numbers
                </SelectItem>
              ) : null*/}
            </>
          )}
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing, workspaces }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors, photoCameraRoll } = settings;
  const { plan_modules, source_of_truth } = billing;
  const { workspace_settings } = workspaces;

  return {
    token,
    user,
    device,
    colors,
    photoCameraRoll,
    plan_modules,
    source_of_truth,
    workspace_settings
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  getLimitsForAllPlan,
  setPhotoToCameraroll,
  updateUserSettings,
  getWorkspaceSettings,
  editWorkspaceSettings,
  uploadFile,
  showErrorMessage
})(WorkspaceAppSettings);
