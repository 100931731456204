import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import { NewHeader } from "app/NativeComponents/snippets";

import Body from "./Body";

import {
  popSidePanel,
  editCredits,
  showSuccess,
  /*common functions*/
  dismissMobileKeyboard
} from "app/NativeActions";

class AddMarketingCredits extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    this.state = {
      credit_amount: props.user?.team_default_credit_reload,
      loading: false,
      team_name: panel_data?.team_name,
      team_id: panel_data?.team_id,
      onPurchaseSuccess: panel_data?.onPurchaseSuccess || null
    };

    this.handleBack = this.handleBack.bind(this);
    this.purchaseCredits = this.purchaseCredits.bind(this);
    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
    this.creditPurchaseChanged = this.creditPurchaseChanged.bind(this);
  }

  handleBack() {
    dismissMobileKeyboard();
    this.props.popSidePanel();
  }

  purchaseCredits(card_token, error) {
    dismissMobileKeyboard();

    this.props.editCredits({
      token: this.props.token,
      type: "purchase_credits",
      team_id: this.state.team_id,
      payload: {
        amount: this.state.credit_amount
      },
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          loading: false
        });

        if (this.state.team_id && this.state.onPurchaseSuccess) {
          this.state.onPurchaseSuccess(results);
        }
        this.props.popSidePanel();

        this.props.showSuccess("Thank you for your purchase.", "Success!");
      }
    });
  }

  checkIfNeedsToSave() {
    return true;
  }

  creditPurchaseChanged(credit_amount) {
    this.setState({
      credit_amount
    });
  }

  render() {
    if (this.props.user?.can_mimic_team_owner == 1) {
      return (
        <Container>
          <NewHeader
            title={
              !!this.state.team_id
                ? "Add Marketing Credits"
                : "Add Marketing Credits To Account"
            }
            subtitle={
              !!this.state.team_id
                ? `Add marketing credits to ${this.state.team_name}`
                : null
            }
            leftButton={{
              icon: "arrow-back",
              onPress: this.handleBack
            }}
          />
          <KeyboardView style={{ flex: 1 }}>
            <Body
              {...this.props}
              credit_amount={this.state.credit_amount}
              loading={this.state.loading}
              purchaseCredits={this.purchaseCredits}
              checkIfNeedsToSave={this.checkIfNeedsToSave}
              creditPurchaseChanged={this.creditPurchaseChanged}
              team_name={this.state.team_name}
              team_id={this.state.team_id}
            />
          </KeyboardView>
        </Container>
      );
    }

    return null;
  }
}

const mapStateToProps = ({ auth, native, billing, drawer }) => {
  const { token, user, onboarding } = auth;
  const { device, platform } = native;
  const { editCreditPurchase } = billing;
  const { stats } = drawer;

  return {
    token,
    user,
    onboarding,
    device,
    platform,
    editCreditPurchase,
    stats
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  editCredits,
  showSuccess
})(AddMarketingCredits);
