import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Row,
  Wrapper,
  KeyboardView,
  Copy,
  Bold,
  ContentfulRichText
} from "app/NativeComponents/common";
import {
  GhostButton,
  List,
  PopoverMenu,
  InlineButton,
  SpinWrapper,
  AttentionBox,
  IconButton,
  UpsellButton
} from "app/NativeComponents/snippets";

import {
  changeTab,
  pushSidePanel,
  getMailerTemplates,
  loadOnboardingText,
  setStartMailerCampaignPage,
  getTotalLeadsCount,
  numberWithCommas,
  openUrl
} from "app/NativeActions";
import MainHeader from "app/DealMachineCore/reuseable/MainHeader";
import Postcard from "./Postcard";
import SearchPostcards from "./SearchPostcards";
import MoreInfoButton from "app/DealMachineCore/reuseable/MoreInfoButton";
class PostcardDesigner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      filter_type: "all",
      search_loading: false,
      mailer_templates: [],
      mailer_templates_begin: 0,
      mailer_templates_limit: 25,
      mailer_templates_loading: false,
      mailer_templates_refreshing: false,
      mailer_templates_loaded_all: false,
      mailer_templates_count: 0,
      default_bracket_variables: null,
      contentful_content: null,
      contentful_loading: false,
      all_leads_count_loading: false,
      all_leads_count: 0
    };

    this.updateMailTemplate = this.updateMailTemplate.bind(this);
    this.createMailTemplate = this.createMailTemplate.bind(this);
    this.removeFirstChoiceFromAll = this.removeFirstChoiceFromAll.bind(this);
    this.pushCreateTemplateModal = this.pushCreateTemplateModal.bind(this);
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
  }

  componentDidMount() {
    if (this.props.device == "mobile") {
      this.props.changeTab("postcard_designer");
    }
    this.getData({ load_type: "refresh" });

    this.loadData("postcard_designer");

    this.getTotalLeadCount();
  }

  getTotalLeadCount() {
    this.setState({
      all_leads_count_loading: true
    });
    this.props.getTotalLeadsCount({
      token: this.props.token,
      list_id: "all_leads",
      onLoading: () => {
        this.setState({
          all_leads_count_loading: true
        });
      },
      onError: () => {
        this.setState({
          all_leads_count_loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          all_leads_count: results,
          all_leads_count_loading: false
        });
      }
    });
  }

  loadData(slug) {
    loadOnboardingText(slug)
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              contentful_content: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              loading: false
            });
          }
        }
        this.setState(() => ({ contentful_loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ contentful_loading: false }));
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.search !== prevState.search ||
      this.state.filter_type !== prevState.filter_type
    ) {
      this.getData({ load_type: "refresh" });
    }
  }

  getData({ load_type = "load" }) {
    const {
      mailer_templates_loading,
      mailer_templates_begin,
      mailer_templates_limit,
      search,
      filter_type
    } = this.state;
    if (!mailer_templates_loading) {
      this.props.getMailerTemplates({
        token: this.props.token,
        load_type: load_type,
        global_templates: this.props.global_templates,
        begin: load_type !== "load_more" ? 0 : mailer_templates_begin,
        limit: mailer_templates_limit,
        search,
        filter_type,
        onLoading: () => {
          this.setState({
            mailer_templates:
              load_type == "refresh" ? [] : this.state.mailer_templates,
            mailer_templates_loading: load_type == "refresh" ? false : true,
            mailer_templates_refreshing: load_type == "refresh" ? true : false,
            mailer_templates_loaded_all: false
          });
        },
        onError: () => {
          this.setState({
            mailer_templates_loading: false,
            mailer_templates_refreshing: false,
            search_loading: false
          });
        },
        onSuccess: results => {
          this.setState({
            mailer_templates_loading: false,
            mailer_templates_refreshing: false,
            mailer_templates:
              load_type == "refresh"
                ? results?.mailer_templates || []
                : [
                    ...this.state.mailer_templates,
                    ...results?.mailer_templates
                  ],
            mailer_templates_loaded_all:
              results?.mailer_templates?.length < mailer_templates_limit
                ? true
                : false,
            mailer_templates_count: results?.mailer_template_count,
            default_bracket_variables: results?.default_bracket_variables,
            search_loading: false
          });
        }
      });
    }
  }

  createMailTemplate(mail_template) {
    this.setState({
      mailer_templates_count: this.state.mailer_templates_count + 1,
      mailer_templates: [mail_template, ...this.state.mailer_templates]
    });
  }

  onDeleteSuccess(mail_template_id) {
    this.setState({
      mailer_templates: this.state.mailer_templates.filter(
        ({ id }) => id != mail_template_id
      )
    });
  }

  updateMailTemplate(mail_template) {
    let new_mailer_templates = JSON.parse(
      JSON.stringify(this.state.mailer_templates)
    );
    for (let i = 0; i < new_mailer_templates.length; i++) {
      if (mail_template?.id == new_mailer_templates[i].id) {
        new_mailer_templates[i] = mail_template;
      }
    }

    this.setState({
      mailer_templates: new_mailer_templates
    });
  }

  removeFirstChoiceFromAll() {
    let new_mailer_templates = JSON.parse(
      JSON.stringify(this.state.mailer_templates)
    );
    for (let i = 0; i < new_mailer_templates.length; i++) {
      new_mailer_templates[i] = {
        ...new_mailer_templates[i],
        is_default_template: false
      };
    }

    this.setState({
      mailer_templates: new_mailer_templates
    });
  }

  pushCreateTemplateModal(template_type) {
    const { mailer_templates_count, default_bracket_variables } = this.state;
    const { user, token } = this.props;
    switch (template_type) {
      case "handwritten":
        //custom set for ballpoint mailers....this is a legacy fix

        this.props.pushSidePanel({
          slug: "postcard_designer_widget",
          overlay: true,
          focus_modal: true,
          locked: true,
          data: {
            global_templates: this.props.global_templates,
            template_type,
            mailer: {
              id: "new_template",
              template_title: "Mail #" + (mailer_templates_count + 1),
              template_type,
              back_height: 434,
              back_width: 600,
              front_height: 508,
              front_width: 375,
              front_layers: [
                {
                  layer_type: "text",
                  id: "content_intro",
                  side: "front",
                  x: 0,
                  y: 0,
                  height: "84px",
                  width: "375px",
                  title: "Content Intro",
                  backgroundType: "color",
                  background: "transparent",
                  padding_top: "25",
                  padding_left: "50",
                  padding_bottom: "25",
                  padding_right: "50",
                  content: "Dear {{owner_firstname}},",
                  text_align: "left",
                  text_color: "#003DDC",
                  font_size: "14",
                  line_height: "28",
                  font_family: "Rose",
                  font_family_variants: ["regular", "500", "600", "700"],
                  font_family_files: {
                    500: "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf",
                    600: "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf",
                    700: "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf",
                    regular:
                      "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf"
                  },
                  image_fit: "cover",
                  image_type: "upload_new_image",
                  image_url: "",
                  border_style: "none",
                  border_color: "#000000",
                  border_width: 0,
                  border_radius: 0,
                  locked: false
                },
                {
                  layer_type: "text",
                  id: "content_body",
                  side: "front",
                  x: 0,
                  y: 75,
                  height: "291px",
                  width: "375px",
                  title: "Content Body",
                  backgroundType: "color",
                  background: "transparent",
                  padding_top: "25",
                  padding_left: "50",
                  padding_bottom: "25",
                  padding_right: "50",
                  content:
                    "What are your plans for {{property_address}}? I've prepared a cash offer that I'd like to discuss with you. If you're interested in selling as-is, please give my office a call at {{signature_phone}}. Thanks in advance.",
                  text_align: "left",
                  text_color: "#003DDC",
                  font_size: "14",
                  line_height: "28",
                  font_family: "Rose",
                  font_family_variants: ["regular", "500", "600", "700"],
                  font_family_files: {
                    500: "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf",
                    600: "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf",
                    700: "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf",
                    regular:
                      "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf"
                  },
                  image_fit: "cover",
                  image_type: "upload_new_image",
                  image_url: "",
                  border_style: "none",
                  border_color: "#000000",
                  border_width: 0,
                  border_radius: 0,
                  locked: false
                },
                {
                  layer_type: "text",
                  id: "content_sign_off",
                  side: "front",
                  x: 3,
                  y: 361,
                  height: "141px",
                  width: "372px",
                  title: "Content Sign Off",
                  backgroundType: "color",
                  background: "transparent",
                  padding_top: "25",
                  padding_left: "50",
                  padding_bottom: "25",
                  padding_right: "50",
                  content:
                    "Sincerely, <br/>{{signature_name}}<br/>{{signature_phone}}<br/>",
                  text_align: "left",
                  text_color: "#003DDC",
                  font_size: "14",
                  line_height: "28",
                  font_family: "Rose",
                  font_family_variants: ["regular", "500", "600", "700"],
                  font_family_files: {
                    500: "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf",
                    600: "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf",
                    700: "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf",
                    regular:
                      "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf"
                  },
                  image_fit: "cover",
                  image_type: "upload_new_image",
                  image_url: "",
                  border_style: "none",
                  border_color: "#000000",
                  border_width: 0,
                  border_radius: 0,
                  locked: false
                }
              ],
              back_layers: [
                {
                  layer_type: "image",
                  id: "back_layer_1",
                  side: "back",
                  x: 0,
                  y: 0,
                  height: "434px",
                  width: "600px",
                  title: "Envelope",
                  backgroundType: "color",
                  background: "transparent",
                  padding_top: 0,
                  padding_left: 0,
                  padding_bottom: 0,
                  padding_right: 0,
                  content: "",
                  text_align: "center",
                  text_color: "#003DDC",
                  font_size: 12,
                  line_height: 12,
                  font_family: "Rose",
                  font_family_variants: [
                    "300",
                    "300italic",
                    "regular",
                    "italic",
                    "600",
                    "600italic",
                    "700",
                    "700italic",
                    "800",
                    "800italic"
                  ],
                  font_family_files: {
                    300: "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf",
                    600: "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf",
                    800: "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf",
                    "300italic":
                      "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf",
                    regular:
                      "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf",
                    italic:
                      "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf",
                    "600italic":
                      "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf",
                    "700italic":
                      "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf",
                    "800italic":
                      "https://api-feature-2.dealmachine.com/assets/fonts/Rose-Regular-0.1.0.ttf"
                  },
                  image_fit: "cover",
                  image_type: "upload_new_image",
                  image_url:
                    "https://api-feature-2.dealmachine.com/preview/templates/images/letter-background.jpg",
                  border_style: "none",
                  border_color: "#000000",
                  border_width: 0,
                  border_radius: 0,
                  locked: true
                }
              ],
              bracket_variables: default_bracket_variables
            },
            onSuccess: results => {
              this.getData({ load_type: "refresh" });
            }
          }
        });

        break;

      case "postcard":
      case "postcard6x9":
      case "postcard6x11":
      default:
        this.props.pushSidePanel({
          slug: "postcard_designer_widget",
          overlay: true,
          focus_modal: true,
          locked: true,
          data: {
            global_templates: this.props.global_templates,
            mailer: {
              id: "new_template",
              template_title: "Mail #" + (mailer_templates_count + 1),
              template_type,
              bracket_variables: default_bracket_variables
            },
            template_system_type: "design_json",
            template_type,
            select_design: true,
            onSuccess: results => {
              this.getData({ load_type: "refresh" });
            }
          }
        });

        break;
    }
  }

  render() {
    const { colors, isMobile, user } = this.props;
    const {
      mailer_templates,
      mailer_templates_loading,
      mailer_templates_refreshing,
      mailer_templates_loaded_all,
      search,
      search_loading,
      filter_type,
      contentful_content
    } = this.state;
    if (user?.can_edit_templates == 1) {
      return (
        <Container
          fill={this.props.global_templates ? false : true}
          title={"Postcards"}
          style={{
            display: "flex",
            overflow: "hidden",
            backgroundColor: colors.card_color
          }}
        >
          {!this.props.global_templates ? (
            <Row style={{ alignItems: "flex-start" }}>
              <Wrapper style={{ flex: 1 }}>
                <MainHeader title={"Postcards"} />
              </Wrapper>
            </Row>
          ) : null}

          <Row
            style={{
              padding: isMobile || this.props.global_templates ? 0 : 20,
              paddingLeft: isMobile || this.props.global_templates ? 10 : 20,
              paddingRight: isMobile || this.props.global_templates ? 10 : 20,
              flexWrap: "wrap",
              borderBottomWidth: 1,
              borderBottomStyle: "solid",
              borderBottomColor: colors.border_color
            }}
          >
            <Wrapper style={{ flex: 1 }}>
              <Row>
                <SearchPostcards
                  search={search}
                  global_templates={this.props.global_templates}
                  search_loading={search_loading}
                  updateSearch={search => {
                    this.setState({ search, search_loading: true });
                  }}
                />

                <PopoverMenu
                  renderComponent={options => {
                    if (isMobile || this.props.global_templates) {
                      return (
                        <IconButton
                          icon="tune"
                          tooltip="Filter by Mail Type"
                          tooltipPlacement="top"
                          noPress={true}
                          pressedIn={options.hovering || options.pressedIn}
                        />
                      );
                    }
                    return (
                      <InlineButton
                        icon="tune"
                        noPress={true}
                        pressedIn={options.hovering || options.pressedIn}
                      >
                        Mail Type
                      </InlineButton>
                    );
                  }}
                  menu_items={[
                    {
                      title: "All",
                      select_type: "radio",
                      selected: filter_type == "all",
                      onPress: () => {
                        this.setState({ filter_type: "all" });
                      }
                    },
                    {
                      title: "4x6 Postcards",
                      select_type: "radio",
                      selected: filter_type == "postcard4x6",
                      onPress: () => {
                        this.setState({ filter_type: "postcard4x6" });
                      }
                    },
                    {
                      title: "6x9 Postcards",
                      select_type: "radio",
                      selected: filter_type == "postcard6x9",
                      onPress: () => {
                        this.setState({ filter_type: "postcard6x9" });
                      }
                    },
                    {
                      title: "6x11 Postcards",
                      select_type: "radio",
                      selected: filter_type == "postcard6x11",
                      onPress: () => {
                        this.setState({ filter_type: "postcard6x11" });
                      }
                    },
                    {
                      title: "Ballpoint Letters",
                      select_type: "radio",
                      selected: filter_type == "ballpoint",
                      onPress: () => {
                        this.setState({ filter_type: "ballpoint" });
                      }
                    }
                  ]}
                />
              </Row>
            </Wrapper>

            {this.props.user?.can_edit_templates == 1 ? (
              <PopoverMenu
                renderComponent={options => {
                  if (isMobile || this.props.global_templates) {
                    return (
                      <InlineButton
                        icon={"add"}
                        primary={true}
                        noPress={true}
                        pressedIn={options.hovering || options.pressedIn}
                      >
                        New
                      </InlineButton>
                    );
                  }
                  return (
                    <GhostButton
                      icon={"add"}
                      primary={true}
                      noPress={true}
                      pressedIn={options.hovering || options.pressedIn}
                    >
                      New Design
                    </GhostButton>
                  );
                }}
                menu_items={[
                  {
                    title: "New 4x6 Postcard",
                    select_type: "none",
                    icon: "keyboard-arrow-right",
                    onPress: () => {
                      this.pushCreateTemplateModal("postcard");
                    }
                  },
                  {
                    title: "New 6x9 Postcards",
                    select_type: "none",
                    icon: "keyboard-arrow-right",
                    onPress: () => {
                      this.pushCreateTemplateModal("postcard6x9");
                    }
                  },
                  {
                    title: "New 6x11 Postcards",
                    select_type: "none",
                    icon: "keyboard-arrow-right",
                    onPress: () => {
                      this.pushCreateTemplateModal("postcard6x11");
                    }
                  },
                  {
                    title: "New Ballpoint Letter",
                    select_type: "none",
                    icon: "keyboard-arrow-right",
                    onPress: () => {
                      this.pushCreateTemplateModal("handwritten");
                    }
                  },
                  {
                    title: "Order Door Hangers",
                    select_type: "none",
                    icon: "keyboard-arrow-right",
                    onPress: () => {
                      openUrl(
                        "https://www.ballpointmarketing.com/product/deal-machine/?oid=4&affid=2027"
                      );
                    }
                  }
                ]}
              />
            ) : null}
          </Row>
          <Row style={{ flex: 1, overflow: "hidden" }}>
            <Wrapper
              style={{
                flex: 1,
                alignSelf: "stretch",
                position: "relative",
                overflow: "hidden",
                minWidth:
                  isMobile || this.props.global_templates ? "100%" : 1000
              }}
            >
              {(mailer_templates_loading || mailer_templates_refreshing) &&
              mailer_templates?.length == 0 &&
              !this.state.search_loading ? (
                <SpinWrapper text={"Loading"} />
              ) : mailer_templates?.length > 0 ? (
                <>
                  <List
                    rowNumber={1}
                    innerStyle={
                      isMobile || this.props.global_templates
                        ? {
                            flex: 1
                          }
                        : {
                            flex: 1,
                            flexDirection: "row",
                            flexWrap: "wrap",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            padding: 20
                          }
                    }
                    items={mailer_templates}
                    infiniteScroll={true}
                    itemStructure={({ item, index }) => {
                      return (
                        <Postcard
                          mailer={item}
                          key={"postcard_" + index}
                          updateMailTemplate={this.updateMailTemplate}
                          removeFirstChoiceFromAll={
                            this.removeFirstChoiceFromAll
                          }
                          onDeleteSuccess={this.onDeleteSuccess}
                          global_templates={this.props.global_templates}
                        />
                      );
                    }}
                    canRefresh={false}
                    onRefresh={() => {}}
                    is_refreshing={mailer_templates_refreshing}
                    canLoadMore={false}
                    isLoadingMore={false}
                    onLoadMore={() => {}}
                  />
                </>
              ) : (
                <AttentionBox
                  title={"There is nothing to display."}
                  description={
                    "You do not have any designs matching the search criteria."
                  }
                />
              )}
            </Wrapper>
          </Row>
        </Container>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile, device } = native;
  return {
    token,
    user,
    isMobile,
    colors,
    device
  };
};

export default connect(mapStateToProps, {
  pushSidePanel,
  setStartMailerCampaignPage,
  getMailerTemplates,
  getTotalLeadsCount,
  changeTab
})(PostcardDesigner);
