import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Wrapper } from "app/NativeComponents/common";

import MainHeader from "app/DealMachineCore/reuseable/MainHeader";

class WorkspaceMail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // Add any necessary initialization logic
  }

  render() {
    return (
      <Container>
        <Row style={{ flex: 1, overflow: "hidden" }}>
          <Wrapper
            style={{ flex: 1, alignSelf: "stretch", position: "relative" }}
          >
            <MainHeader title={"Mail Center"} />
          </Wrapper>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  // Add necessary state mappings
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceMail);
