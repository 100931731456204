import React, { Component } from "react";

import {
  Wrapper,
  Button,
  Icon,
  Copy,
  Row,
  Spin,
  Bold
} from "app/NativeComponents/common";

import {
  GhostButton,
  CircleButton,
  UpsellButton
} from "app/NativeComponents/snippets";
import LeadStatusButton from "app/DealMachineCore/reuseable/LeadStatusButton";
import {
  getLeadStatusColor,
  checkIfUserHasSubscription,
  numberWithCommas,
  checkIfUserHasMetadata
} from "app/NativeActions";

class StatusButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      temp_value: null,
      deleting: false
    };
  }

  determineDescription = property => {
    let listName = "";
    if (
      property &&
      property.deal?.lists?.some(list => list?.list_type === "build_list") &&
      property.deal.list_titles.length > 0
    ) {
      listName = (
        <>
          <Copy inline={true} style={{ fontWeight: "bold" }}>
            {
              property.deal.lists.find(list => list.list_type == "build_list")
                .title
            }
          </Copy>
          <Copy inline={true}> list.</Copy>
        </>
      );
    } else {
      listName = "a smart list.";
    }
    if (
      property &&
      property.deal?.lists?.some(list => list?.list_type === "build_list")
    ) {
      return (
        <Copy>
          <Copy inline={true}> This lead is a part of </Copy>
          {listName}
          <Copy inline={true}>
            When deleted, this lead will be added back on the next Smart List
            update. To avoid this, please move the lead to Trash.
          </Copy>
        </Copy>
      );
    } else {
      return "Are you sure you want to permanently delete this lead? This will completely remove it from your account. You cannot undo this.";
    }
  };

  render() {
    const {
      token,
      property,
      user,
      add_deal_loading,
      colors,
      isMobile,
      current_route,
      device,
      source_of_truth,
      all_feature_metadata
    } = this.props;

    const { loading, temp_value } = this.state;

    const deleteConfirmationDescription = this.determineDescription(property);

    if (
      property?.deal &&
      !property?.deal?.removed &&
      property?.deal?.deal_status_id
    ) {
      return (
        <Row>
          <LeadStatusButton
            popoverPlacement="bottom"
            onLoading={({ loading, temp_value }) => {
              this.setState({
                loading,
                temp_value
              });
            }}
            propertyUpdated={this.props.propertyUpdated}
            renderComponent={({ pressedIn, hovering }) => {
              return (
                <GhostButton
                  noPress={true}
                  style={{ marginLeft: 0 }}
                  innerStyle={{
                    padding: 14,
                    backgroundColor:
                      pressedIn || hovering
                        ? colors.actionable_text_color
                        : colors.popover_color,
                    borderColor:
                      pressedIn || hovering
                        ? colors.actionable_text_color
                        : colors.border_color
                  }}
                  pressedIn={pressedIn || hovering}
                  tooltip="Change Lead Status"
                  tooltipPlacement="top"
                  icon_right="keyboard-arrow-down"
                  loading={loading}
                >
                  {loading ? temp_value : property.deal.deal_status_title}
                </GhostButton>
              );
            }}
            property={property}
          />
          {property?.deal?.archived == 1 ? (
            <>
              <CircleButton
                style={{ marginLeft: 0 }}
                tooltip={"Delete Lead"}
                tooltipPlacement={"top"}
                icon="delete"
                loading={this.state.deleting}
                onPress={() => {
                  this.props.setModal({
                    title: "Permanently Delete Lead",
                    description: deleteConfirmationDescription,
                    icon: "delete",
                    submit: "Permanently Delete",
                    buttonType: "destroy",
                    require_complete_function: true,
                    onPress: complete => {
                      this.props.updateLead({
                        token: token,
                        type: "permanently_delete",
                        deal_ids: property.deal.id,
                        no_loading: true,
                        onLoading: () => {
                          this.props.updateModal({
                            submit_loading: true
                          });
                        },
                        onError: () => {
                          this.props.updateModal({
                            submit_loading: false
                          });
                        },
                        onSuccess: () => {
                          this.props.updateModal({
                            submit_loading: false
                          });
                          this.props.popSidePanel();
                          complete();
                        }
                      });
                    },
                    cancel: "Cancel",
                    onCancel: () => {}
                  });
                  this.props.toggleModal({ show: true, type: "normal" });
                }}
              />
            </>
          ) : null}
        </Row>
      );

      //status if the user is a dealfinder
      return null;
    }
    if (!property?.deal || property?.deal?.removed) {
      const loading_lead = add_deal_loading.some(
        p => p.property_id === property.property_id
      );
      return (
        <UpsellButton
          meta_slug="leads_added_from_map"
          onPress={() => {
            if (!property.deal && !loading_lead) {
              this.props.addDeal({
                token: token,
                property: property,
                route_id: current_route ? current_route.route_id : 0,
                address: property.property_address,
                address2: property.property_address2,
                city: property.property_address_city,
                state: property.property_address_state,
                zip: property.property_address_zip,
                devicetype: isMobile ? "mobile_map" : "desktop_map",
                onSuccess: results => {
                  this.props.propertyUpdated(results);
                },
                onError: error => {
                  if (!checkIfUserHasMetadata("paid_plan")) {
                    this.props.pushSidePanel({
                      slug: "purchase_plan_prompt",
                      overlay_modal: true,
                      locked: true,
                      data: {
                        title: "Add More Properties With DealMachine Pro.",
                        dismiss_button:
                          "No thanks. Let me continue without adding this property.",
                        no_free: true,
                        event_slug: "in_app_add_property"
                      },
                      locked: true
                    });
                  } else {
                    const upsell_info = error?.upsell_info;
                    this.props.pushSidePanel({
                      slug: "unlock_feature_modal",
                      overlay: true,
                      data: {
                        feature_upsell_info: upsell_info.feature_metadata,
                        renderContent: () => {
                          return (
                            <Wrapper style={{ padding: 25 }}>
                              <Copy>
                                You have added{" "}
                                <Bold>
                                  {numberWithCommas(upsell_info.current_count)}{" "}
                                  leads
                                </Bold>{" "}
                                Adding this property will exceed your limit of{" "}
                                <Bold>
                                  {numberWithCommas(
                                    upsell_info.subscription.metadata
                                      .total_number_of_leads
                                  )}
                                </Bold>
                                . Permanently delete a lead or upgrade to
                                continue.
                              </Copy>
                            </Wrapper>
                          );
                        },
                        buttonText: "Upgrade And Add Lead",
                        confirmText: "Confirm Upgrade?",
                        onSuccess: () => {
                          this.props.popSidePanel();
                          const { property, token, isMobile, current_route } =
                            this.props;
                          this.props.addDeal({
                            token: token,
                            property: property,
                            route_id: current_route
                              ? current_route.route_id
                              : 0,
                            address: property.property_address,
                            address2: property.property_address2,
                            city: property.property_address_city,
                            state: property.property_address_state,
                            zip: property.property_address_zip,
                            devicetype: isMobile ? "mobile_map" : "desktop_map",
                            onSuccess: results => {
                              this.props.propertyUpdated(results);
                            }
                          });
                        }
                      }
                    });
                  }
                }
              });
            }
          }}
          renderLockedChildren={({ hovering }) => {
            return (
              <GhostButton
                style={{ marginLeft: 0 }}
                innerStyle={{
                  padding: 14
                }}
                primary={true}
                icon={
                  checkIfUserHasSubscription(
                    this.props.user,
                    this.props.source_of_truth
                  )
                    ? "lock"
                    : "add"
                }
                noPress={true}
                pressedIn={hovering}
              >
                {"Add Lead"}
              </GhostButton>
            );
          }}
          renderChildren={({ hovering }) => {
            return (
              <GhostButton
                style={{ marginLeft: 0 }}
                innerStyle={{
                  padding: 14
                }}
                primary={true}
                icon="add"
                loading={loading_lead}
                noPress={true}
                pressedIn={hovering}
              >
                {loading_lead ? "Adding Lead" : "Add Lead"}
              </GhostButton>
            );
          }}
        />
      );
    }
    return null;
  }
}

export default StatusButton;
