import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Wrapper, Copy, Bold } from "app/NativeComponents/common";

class DialButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovering: false
    };
  }

  render() {
    const { colors, onPress = () => {}, label } = this.props;

    const { hovering } = this.state;

    return (
      <Button
        onPress={onPress}
        onHover={() => {
          this.setState({ hovering: true });
        }}
        onHoverOut={() => {
          this.setState({ hovering: false });
        }}
        style={{
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: colors.actionable_text_color,
          borderRadius: 50,
          width: 46,
          height: 46,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: colors.active_color_muted,
          margin: 10
        }}
      >
        <Wrapper
          style={{
            borderRadius: 50,
            alignItems: "center",
            justifyContent: "center",
            width: 46,
            height: 46,
            backgroundColor: hovering ? colors.hover_color : "transparent"
          }}
        >
          <Copy style={{ color: colors.actionable_text_color, fontSize: 24 }}>
            {label}
          </Copy>
        </Wrapper>
      </Button>
    );
  }
}

export default DialButton;
