import React, { Component } from "react";

import {
  Card,
  Wrapper,
  Copy,
  Bold,
  Row,
  Title
} from "app/NativeComponents/common";
import {
  SelectItem,
  GhostButton,
  InlineButton,
  ConfirmInlineButton
} from "app/NativeComponents/snippets";
import {
  AppConfig,
  displayIntercom,
  openUrl,
  renderPrice
} from "app/NativeActions";
import { store } from "app/store";
class Addon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };

    this.addToTrial = this.addToTrial.bind(this);
  }
  componentDidMount() {}

  addToTrial() {
    this.props.manageSubscription({
      token: this.props.token,
      type: this.props.switch_plan
        ? "switch_plan"
        : !!this.props.purchase_type
        ? this.props.purchase_type
        : "add_to_subscription",
      assign_to: this.props.assign_to,
      seat_type: this.props.seat_type,
      stripe_price_id:
        this.props.plan_interval === "year"
          ? this.props.stripe_price_id_year
          : this.props.stripe_price_id_month,
      offer_promo: !!this.props.offer_promo ? this.props.offer_promo : null,
      offer_type: !!this.props.offer_type ? this.props.offer_type : null,
      force_interval: this.props.force_interval,
      event_slug: this.props.event_slug,

      no_messaging: true,
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: this.props.onSuccess
    });
  }

  render() {
    const {
      featured = false,
      title,
      description,
      buttonText,
      confirmText,
      colors,
      desktopMobile,
      device,
      subtext,
      price,
      plan_interval,
      year_price,
      includes_items,
      marketing_slug,
      upsell = false,
      switch_plan,
      current_user_subscription
    } = this.props;

    const user = store.getState().auth.user;
    const current_user_plan = current_user_subscription?.products?.find(
      x => (x.product_type = "plan" && x.product_slug == "dealmachine")
    );
    const current_user_plan_interval = current_user_plan?.interval;
    const current_user_plan_price_id = current_user_plan?.stripe_price_id;

    let current_user_product = false;
    if (
      current_user_plan_price_id == this.props.stripe_price_id_month &&
      plan_interval == "month"
    ) {
      current_user_product = true;
    }
    if (
      current_user_plan_price_id == this.props.stripe_price_id_year &&
      plan_interval == "year"
    ) {
      current_user_product = true;
    }

    return (
      <Card
        style={
          upsell
            ? {
                flex: 1,
                alignSelf: device === "mobile" ? "stretch" : "center",
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: colors.border_color,
                boxShadow: "1px 1px 5px rgba(0,0,0,0.25)",
                margin: desktopMobile && device === "desktop" ? "10px auto" : 10
              }
            : featured
            ? {
                flex: 1,
                alignSelf: device === "mobile" ? "stretch" : "center",
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: colors.active_color,
                boxShadow: "1px 1px 5px rgba(0,0,0,0.25)",
                maxWidth: 400,
                minWidth: 320,
                margin: desktopMobile && device === "desktop" ? "10px auto" : 10
              }
            : {
                flex: 1,
                alignSelf: device === "mobile" ? "stretch" : "center",

                boxShadow: "1px 1px 5px rgba(0,0,0,0.25)",
                borderWidth: 0,
                maxWidth: 400,
                minWidth: 320,
                margin: desktopMobile && device === "desktop" ? "10px auto" : 10
              }
        }
      >
        <SelectItem
          select_type="none"
          subtext={subtext}
          noPress={true}
          selected={featured || upsell}
        >
          {title}
        </SelectItem>
        <Wrapper style={{ paddingRight: 25, paddingLeft: 25 }}>
          <Row style={{ alignItems: "flex-end" }}>
            <Title style={{ fontSize: 36 }}>
              <Bold>
                {plan_interval == "year"
                  ? renderPrice(year_price / 12, "no_decimal")
                  : renderPrice(price, "no_decimal")}
              </Bold>
            </Title>
            <Copy style={{ marginLeft: 5 }}>{" / mo"}</Copy>
            {plan_interval == "year" ? (
              <Copy style={{ marginLeft: 5, color: colors.light_text_color }}>
                {" - " +
                  renderPrice(year_price, "no_decimal") +
                  " billed annually"}
              </Copy>
            ) : null}
          </Row>
        </Wrapper>
        <Wrapper style={{ padding: 25 }}>
          <Copy>{description}</Copy>
        </Wrapper>
        {includes_items.map((item, i) => {
          return (
            <SelectItem
              key={"item_" + i}
              select_type="icon"
              select_icon={"check"}
              icon_color={colors.success_color}
              noPress={true}
              tooltip={!!item?.tooltip ? item.tooltip : ""}
              tooltipPlacement={"top"}
              innerStyle={{
                paddingTop: 0,
                paddingRight: 0
              }}
              textStyle={
                !!item?.tooltip
                  ? {
                      textDecoration: "underline",
                      textDecorationStyle: "dotted"
                    }
                  : {}
              }
            >
              {item?.text}
            </SelectItem>
          );
        })}

        {user?.can_mimic_team_owner == 1 ? (
          <Wrapper style={{ padding: 25, paddingTop: 0 }}>
            {switch_plan &&
            ((current_user_plan_interval !== plan_interval &&
              current_user_plan_interval == "month") ||
              current_user_product) ? (
              <InlineButton
                loading_text="Loading..."
                loading={this.state.loading}
                button_type={"full"}
                icon_right={current_user_product ? "" : "arrow-right-alt"}
                onPress={() => displayIntercom()}
                noPress={current_user_product}
                marketing_slug={marketing_slug}
                innerStyle={
                  featured || upsell
                    ? {
                        borderColor: colors.active_color
                      }
                    : {}
                }
              >
                {current_user_product ? "Current Plan" : "Talk to Support"}
              </InlineButton>
            ) : (
              <ConfirmInlineButton
                confirm_text={!!confirmText ? confirmText : "Confirm?"}
                loading_text="Loading..."
                loading={this.state.loading}
                icon_right={"arrow-right-alt"}
                button_type={"full"}
                marketing_slug={marketing_slug}
                innerStyle={
                  featured || upsell
                    ? {
                        borderColor: colors.active_color
                      }
                    : {}
                }
                onPress={() => {
                  this.setState(
                    {
                      loading: true
                    },
                    () => {
                      this.addToTrial();
                    }
                  );
                }}
              >
                {buttonText}
              </ConfirmInlineButton>
            )}
          </Wrapper>
        ) : (
          <Wrapper style={{ padding: 25, paddingTop: 0 }}>
            <InlineButton
              confirm_text={!!confirmText ? confirmText : "Confirm?"}
              loading_text="Loading..."
              loading={this.state.loading}
              button_type={"full"}
              icon={"lock"}
              noPress={true}
              marketing_slug={marketing_slug}
              innerStyle={
                featured || upsell
                  ? {
                      borderColor: colors.active_color
                    }
                  : {}
              }
            >
              {buttonText}
            </InlineButton>
            <Copy style={{ fontSize: 12, textAlign: "center", marginTop: 15 }}>
              You do not have permission to manage your team's subscription.
            </Copy>
          </Wrapper>
        )}
      </Card>
    );
  }
}

export default Addon;
