import React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { Card, Wrapper, Copy } from "app/NativeComponents/common";
import { GhostButton } from "app/NativeComponents/snippets";

import { renderPrice } from "app/NativeActions";

function PaymentMethodForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement
    });

    if (error) {
      console.error(error);
      props.showErrorMessage(error.message, "Error");
    } else {
      // Send paymentMethod.id to your server (PHP) to attach to the customer

      props.addStripePaymentMethod({
        token: props.token,
        paymentMethodId: paymentMethod.id,
        error: error,
        type: "add_stripe_payment_method",
        onLoading: () => {
          props.triggerLoading(true);
        },
        onError: error => {
          props.triggerLoading(false);
          props.showErrorMessage(error, "Error");
        },
        onSuccess: () => {
          if (props.onboarding) {
            props.updateUser({
              token: props.token,
              type: "onboarding_stepthrough_step",
              payload: {
                onboarding_stepthrough_current_step: "final_step"
              },
              onLoading: () => {},
              onError: () => {
                props.triggerLoading(false);
              },
              onSuccess: () => {
                props.triggerLoading(false);
              }
            });
          }
        }
      });
    }
  };

  const { colors } = props;

  return (
    <form onSubmit={handleSubmit} style={{ margin: 0, alignSelf: "stretch" }}>
      <Card>
        <CardElement
          options={{
            hidePostalCode: false
          }}
        />
      </Card>
      <Wrapper style={{ flex: 1 }}>
        <GhostButton
          button_type="full"
          formButton={true}
          loading={props.loading}
          style={{ alignSelf: "stretch" }}
        >
          Add Card And Continue
        </GhostButton>
      </Wrapper>
    </form>
  );
}

export default PaymentMethodForm;
