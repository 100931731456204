import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Copy,
  Bold,
  Row,
  Scroll,
  KeyboardView
} from "app/NativeComponents/common";
import {
  SpinWrapper,
  SmallLabel,
  SelectItem,
  InlineButton,
  IconButton,
  GhostButton,
  SearchBar,
  SmallHeader,
  InlineTabs
} from "app/NativeComponents/snippets";
import PropertyInfoNew from "app/DealMachineCore/components/Property/PropertyInfoNew";

import PropertyView from "app/DealMachineCore/components/MainMap/PropertyView";

import {
  selectActiveProperty,
  setMapProperties,
  setStreetViewCoords,
  pushSidePanel,
  updateTrackedRoute,
  updateMapLocation
} from "app/NativeActions";

class MapPropertyItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info_search: "",
      info_tab: "info",
      focus: false,
      loading: false,
      content_offset_y: 0,
      loading_options: [],
      property_card_height: 0
    };

    this.state = {
      is_dealfinder: props.user?.team_clearance_level == 0 ? true : false
    };
    this._scroll_view = React.createRef();
    this.propertyUpdated = this.propertyUpdated.bind(this);
    this.scrollToSection = this.scrollToSection.bind(this);
  }

  propertyUpdated(property) {
    this.setState({
      property
    });

    this.props.setMapProperties(
      this.props.map_properties.map(p => {
        if (property?.property_id == p.property_id) {
          return property;
        }
        return p;
      })
    );

    if (!this.props.next_property) {
      this.props.selectActiveProperty(null, property);
    }
  }

  scrollToSection(section) {
    if (section.offset) {
      let y = section.offset + 90;

      this._scroll_view.current.scrollTo({
        x: 0,
        y: y,
        animated: true
      });
    }
  }

  render() {
    const {
      user,
      token,
      device,
      coordinates,
      property,
      colors,
      active_property_loading,
      isMobile,
      current_route,
      add_deal_loading,

      next_property,
      route_plan_distance_left,
      show_street_button = true
    } = this.props;
    const { focus, loading, content_offset_y } = this.state;
    const height = this.state.property_card_height;
    return (
      <Wrapper
        style={{
          flex: 1,
          backgroundColor: colors.popover_color,
          overflow: "hidden"
        }}
      >
        <PropertyView
          show_street_button={show_street_button}
          loading_height={271}
          includeImageOnPropertyPress={this.props.includeImageOnPropertyPress}
          select_drive_start={
            this.props.select_virtual_drive_start ||
            this.props.show_start_driving
          }
          onboarding_map={this.props.onboarding_map}
          renderTop={
            next_property && !this.props.route_plan_completed
              ? () => {
                  return (
                    <>
                      <Row style={{ justifyContent: "space-between" }}>
                        <SmallLabel
                          style={{
                            paddingRight: 5,
                            paddingLeft: 15,
                            paddingTop: 0,
                            paddingBottom: 0,
                            marginTop:
                              this.props.platform !== "android" ? 0 : 10,
                            marginBottom:
                              this.props.platform !== "android" ? 0 : -10
                          }}
                        >
                          Driving to property:
                        </SmallLabel>
                        <Wrapper
                          style={{
                            paddingRight: 15,
                            paddingLeft: 5,
                            marginTop:
                              this.props.platform !== "android" ? 0 : 10,
                            marginBottom:
                              this.props.platform !== "android" ? 0 : -10
                          }}
                        >
                          <Copy
                            style={{
                              marginLeft: 5,
                              color:
                                route_plan_distance_left <= 0.03
                                  ? colors.orange_color
                                  : colors.text_color
                            }}
                          >
                            <Bold>
                              {route_plan_distance_left === 0
                                ? "Calculating..."
                                : route_plan_distance_left.toFixed(2)}
                            </Bold>
                            <Copy
                              style={{
                                color:
                                  route_plan_distance_left <= 0.03
                                    ? colors.orange_color
                                    : colors.text_color
                              }}
                            >
                              {route_plan_distance_left === 0 ? "" : " mi left"}
                            </Copy>
                          </Copy>
                        </Wrapper>
                      </Row>
                    </>
                  );
                }
              : null
          }
          onStreetViewPress={
            !next_property
              ? ({ street_view_data, heading }) => {
                  //this.props.pushMapSidePanel("street_view");

                  this.props.setStreetViewCoords({
                    latitude: street_view_data?.location?.lat,
                    longitude: street_view_data?.location?.lng,
                    heading: heading,
                    zoom: 17,
                    pitch: 0
                  });

                  this.props.updateMapLocation({
                    coordinates: {
                      latitude: street_view_data?.location?.lat,
                      longitude: street_view_data?.location?.lng,
                      heading: heading
                    }
                  });

                  if (
                    this.props.track_route &&
                    this.props.track_route_type == "virtual" &&
                    this.props.current_route &&
                    this.props.current_route_section?.coordinates?.length > 0
                  ) {
                    this.props.updateTrackedRoute({
                      token: this.props.token,
                      route_id: this.props.current_route?.route_id,
                      coordinates:
                        this.props.current_route_section?.coordinates,
                      current_route: this.props.current_route
                    });
                  }

                  this.props.pushSidePanel({
                    slug: "street_view",
                    overlay: true,
                    data: {
                      onboarding_map: this.props.onboarding_map
                    }
                  });
                }
              : null
          }
          property={property}
          next_property={next_property}
          route_plan_distance_left={route_plan_distance_left}
          coordinates={property ? property.location : coordinates}
          no_refresh={property ? true : false}
          propertyUpdated={property => {
            this.propertyUpdated(property);
          }}
          include_tags={true}
          onTagsResize={({ height }) => {
            if (typeof this.props.updateTagsHeight === "function") {
              this.props.updateTagsHeight({
                height: height
              });
            }
          }}
          onPropertyResize={({ height }) => {
            this.setState({
              property_card_height: height
            });
            this.props.updateSliderHeight({
              height: height + 100
            });
          }}
        />
        {property &&
        !this.props.next_planned_property_loading &&
        (this.props.index > 1 || this.props.platform === "ios") ? (
          <>
            <Scroll
              scroll_ref={this._scroll_view}
              //scrollEnabled={this.props.index > 1 ? true : false}
            >
              <Row>
                <SearchBar
                  title="Search Information"
                  value={!!this.state.info_search ? this.state.info_search : ""}
                  onChange={value => {
                    this.setState({
                      info_search: value
                    });
                  }}
                  onFocus={() => {
                    this.setState({
                      focus: true
                    });
                  }}
                  onBlur={() => {
                    this.setState({
                      focus: false
                    });
                  }}
                  style={{
                    flex: 1,
                    margin: 20,
                    marginTop: 30,
                    marginRight: !!property?.mls?.listing_number ? 0 : 10,
                    borderBottomWidth: 0,
                    borderWidth: 0,
                    backgroundColor: colors.background_color
                  }}
                />
              </Row>
              <PropertyInfoNew
                property={property}
                search={this.state.info_search}
                no_editing={true}
                include_custom_fields={false}
                include_more_data={true}
                no_county_sync={true}
                propertyUpdated={property => {
                  this.propertyUpdated(property);
                }}
                show_photos={this.state.info_tab == "photos"}
                {...this.props}
                scrollToSection={this.scrollToSection}
              />
            </Scroll>
          </>
        ) : null}
      </Wrapper>
    );
  }
}

const mapStateToProps = ({
  auth,
  settings,
  native,
  deal,
  property_map,
  route
}) => {
  const { token, user } = auth;
  const { colors } = settings;

  const { device, isMobile, platform } = native;
  const { all_tags } = deal;

  const {
    active_property_loading,
    add_deal_loading,
    map_properties,
    route_plan_completed,
    next_planned_property_loading
  } = property_map;
  const {
    current_route,
    track_route,
    current_route_id,
    current_route_section,
    track_route_type,
    route_plan_distance_left,
    select_virtual_drive_start,
    route_plan_active
  } = route;
  return {
    token,
    user,
    colors,
    device,
    platform,
    isMobile,
    active_property_loading,
    add_deal_loading,
    map_properties,
    current_route,
    track_route,
    current_route_id,
    all_tags,
    current_route_section,
    track_route_type,
    route_plan_distance_left,
    route_plan_completed,
    next_planned_property_loading,
    select_virtual_drive_start,
    route_plan_active
  };
};

export default connect(mapStateToProps, {
  selectActiveProperty,

  setMapProperties,
  setStreetViewCoords,
  updateTrackedRoute,
  pushSidePanel,
  updateMapLocation
})(MapPropertyItem);
