import React, { Component } from "react";
import { connect } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import {
  Page,
  Spinner,
  Wrapper,
  Card,
  Copy,
  Bold,
  Row
} from "app/NativeComponents/common";
import {
  InputBox,
  GhostButton,
  InlineButton,
  OnboardingWrapper,
  OnboardingNavbar,
  SmallLabel,
  SelectBox
} from "app/NativeComponents/snippets";

import { Header1, Header3 } from "app/NativeComponents/common";
import PaymentMethodForm from "./PaymentMethodForm";

import {
  AppConfig,
  logout,
  startRedirect,
  pushSidePanel,
  updateUser,
  showSuccess,
  getMarketingOnboardingData,
  showErrorMessage,
  addStripePaymentMethod,
  dismissMobileKeyboard
} from "app/NativeActions";
class CreditCardStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    if (this.props.device == "desktop" && this.props.onboarding) {
      this.props.startRedirect("/onboarding/add-a-card");
    }
  }

  triggerLoading = loading => {
    this.setState({ loading });
  };

  render() {
    const { colors, user } = this.props;
    if (this.props.init && this.props.user) {
    } else if (this.props.loading) {
      return <Spinner />;
    }

    const stripePromise = loadStripe(AppConfig().stripe_live);

    return (
      <Page
        metaTitle={"One more thing - DealMachine for Real Estate"}
        responsive_design={true}
      >
        <OnboardingWrapper>
          <>
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: 25,
                maxWidth: 500,
                margin: this.props.device == "desktop" ? "0px auto" : 0
              }}
            >
              <Header1
                style={{
                  textAlign: "center"
                }}
              >
                <Bold>Add a credit card on file.</Bold>
              </Header1>

              {this.props.user?.can_mimic_team_owner == 1 ? (
                <>
                  <Wrapper style={{ padding: 25, paddingBottom: 0 }}>
                    <Header3
                      style={{
                        textAlign: "center"
                      }}
                    >
                      We need a credit card on file for your team to handle any
                      overages on marketing spend.
                    </Header3>
                    <Copy style={{ textAlign: "center" }}>
                      Any overages on marketing spend will be charged to this
                      card.
                    </Copy>
                  </Wrapper>

                  <Elements stripe={stripePromise}>
                    <PaymentMethodForm
                      colors={colors}
                      showErrorMessage={this.props.showErrorMessage}
                      showSuccess={this.props.showSuccess}
                      triggerLoading={this.triggerLoading}
                      loading={this.state.loading}
                      token={this.props.token}
                      updateUser={this.props.updateUser}
                      addStripePaymentMethod={this.props.addStripePaymentMethod}
                      onboarding={this.props.onboarding}
                    />
                  </Elements>
                </>
              ) : (
                <>
                  <Wrapper style={{ padding: 25, paddingBottom: 0 }}>
                    <Header3
                      style={{
                        textAlign: "center"
                      }}
                    >
                      We need a credit card on file for your team to handle any
                      overages on marketing spend.
                    </Header3>
                    <Copy style={{ textAlign: "center" }}>
                      Take to your team leader to add a credit card on file.
                    </Copy>
                  </Wrapper>
                </>
              )}
            </Wrapper>

            <Wrapper
              style={{
                maxWidth: 500,
                width: "100%",
                margin: this.props.device == "desktop" ? "5px auto" : 0
              }}
            ></Wrapper>
          </>
        </OnboardingWrapper>
      </Page>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user, init, loading } = auth;

  const { colors, states } = settings;
  const { isMobile, device, initialRedirect } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect,
    states
  };
};

export default connect(mapStateToProps, {
  logout,
  startRedirect,
  pushSidePanel,
  updateUser,
  showSuccess,
  addStripePaymentMethod,
  getMarketingOnboardingData
})(CreditCardStep);
