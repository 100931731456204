import apisauce from "apisauce";
import { AppConfig } from "app/NativeActions";

const create = (baseURL = AppConfig().api_url) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      // Here if necessary
    },
    timeout: 30000
  });

  const logMarketingEvent = ({ slug, token, device, platform }) =>
    api.post("marketing/event/", {
      slug,
      token,
      device,
      platform
    });

  const submitMarketingForm = ({
    token,
    type,
    property_slug,
    property_value
  }) =>
    api.post("marketing/form/", {
      token,
      type,
      property_slug,
      property_value
    });
  const getMobileRedirectSessionLink = ({ token, type }) =>
    api.get("marketing/mobile-redirect-link/", {
      token,
      type
    });

  const logMarketingVisit = ({
    visitor_token,
    device,
    platform,
    app_version,
    experiments
  }) =>
    api.post("marketing/visit/", {
      visitor_token,
      device,
      platform,
      app_version,
      dealmachine_visitor_key: "StAdCBhhR00F@0",
      experiments
    });

  const getMarketingOnboardingData = ({
    token,
    type,
    filter,
    location,
    firstname,
    lastname,
    phone,
    address,
    address2,
    city,
    state,
    zip,
    password
  }) =>
    api.post("marketing/onboarding/", {
      token,
      type,
      filter,
      location,
      firstname,
      lastname,
      phone,
      address,
      address2,
      city,
      state,
      zip,
      password
    });

  const getPartnerFromPromo = ({ promo }) =>
    api.get("private/get-partner-from-promo/", {
      promo
    });

  const getDataFromHubspot = ({ type, hubspot_cookie }) =>
    api.get("marketing/hubspot/", {
      type,
      hubspot_cookie
    });
  const getRoutesForLiveEvent = ({ token, type, promo_codes }) =>
    api.get("marketing/live-event/", {
      token,
      type
    });

  return {
    getMobileRedirectSessionLink,
    logMarketingEvent,
    submitMarketingForm,
    logMarketingVisit,
    getMarketingOnboardingData,
    getPartnerFromPromo,
    getDataFromHubspot,
    getRoutesForLiveEvent
  };
};

export default {
  create
};
