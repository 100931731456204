import { store } from "app/store";
import moment from "moment";

let propertyPolygons = [];

export const renderProperties = (
  map,
  properties,
  onPropertyPress,
  property_map_options,
  togglePolygonClicked,
  zoom,
  mapType,
  user
) => {
  propertyPolygons.map((propertyPolygon, i) => {
    window.google.maps.event.clearListeners(propertyPolygon, "click");
    propertyPolygon.setMap(null);
    return propertyPolygon;
  });

  if (zoom > 16) {
    properties.map((property, i) => {
      for (let i = 0; i < property.coordinates.length; i++) {
        let coordinates = [];
        for (let j = 0; j < property.coordinates[i].length; j++) {
          coordinates.push({
            lat: parseFloat(property.coordinates[i][j].latitude),
            lng: parseFloat(property.coordinates[i][j].longitude)
          });
        }

        //get owner street number
        let fillColor = renderPropertyColor(
          property,
          property_map_options,
          "fill",
          mapType,
          user
        );
        let strokeColor = renderPropertyColor(
          property,
          property_map_options,
          "stroke",
          mapType,
          user
        );
        let drawnPolygon = new window.google.maps.Polygon({
          strokeColor: strokeColor,
          fillColor: fillColor,
          strokeWeight: renderPropertyColor(
            property,
            property_map_options,
            "stroke_width",
            mapType,
            user
          ),
          strokeOpacity: 0.8,
          fillOpacity: 0.35,
          property: property,
          path: coordinates,
          click: e => {}
        });

        drawnPolygon.setMap(map);

        /*
        window.google.maps.event.addListener(drawnPolygon, 'click', function (event) {
            //alert the index of the polygon
            togglePolygonClicked();
            onPropertyPress(drawnPolygon.property);
        });
        */
        propertyPolygons.push(drawnPolygon);
      }
      return property;
    });
  }
};

const renderPropertyColor = (
  property,
  property_map_options,
  type,
  mapType,
  user
) => {
  const darkMode = store.getState().settings.darkMode;
  let is_highlighted = false;
  let halt_check = false;
  if (property_map_options) {
    if (property_map_options.show_property_highlight) {
      if (
        property_map_options.highlight_option_owner_status != "none" &&
        property_map_options.highlight_option_owner_status != null
      ) {
        if (
          property_map_options.highlight_option_owner_status ==
          property.owner_status_info.slug
        ) {
          is_highlighted = true;
        } else {
          is_highlighted = false;
          halt_check = true;
        }
      }

      if (
        property_map_options.highlight_option_sale_date != "none" &&
        property_map_options.highlight_option_sale_date != null &&
        !halt_check
      ) {
        if (property.saledate) {
          let saledate = moment(property.saledate);
          switch (property_map_options.highlight_option_sale_date) {
            case "over_2_years":
              if (saledate < moment().subtract(2, "year")) {
                is_highlighted = true;
              } else {
                is_highlighted = false;
                halt_check = true;
              }

              break;

            case "over_5_years":
              if (saledate < moment().subtract(5, "year")) {
                is_highlighted = true;
              } else {
                is_highlighted = false;
                halt_check = true;
              }
              break;

            case "over_10_years":
              if (saledate < moment().subtract(10, "year")) {
                is_highlighted = true;
              } else {
                is_highlighted = false;
                halt_check = true;
              }
              break;
            default:
              break;
          }
        } else {
          is_highlighted = false;
          halt_check = true;
        }
      }

      if (
        property_map_options.highlight_option_home_value != "none" &&
        property_map_options.highlight_option_home_value != null &&
        !halt_check
      ) {
        if (property.calculated_total_value) {
          switch (property_map_options.highlight_option_home_value) {
            case "under_50k":
              if (property.calculated_total_value < 50000) {
                is_highlighted = true;
              } else {
                is_highlighted = false;
                halt_check = true;
              }

              break;

            case "between_50k_199k":
              if (
                property.calculated_total_value > 50000 &&
                property.calculated_total_value < 200000
              ) {
                is_highlighted = true;
              } else {
                is_highlighted = false;
                halt_check = true;
              }

              break;

            case "between_200k_499k":
              if (
                property.calculated_total_value > 200000 &&
                property.calculated_total_value < 500000
              ) {
                is_highlighted = true;
              } else {
                is_highlighted = false;
                halt_check = true;
              }

              break;

            case "over_500k":
              if (property.calculated_total_value > 500000) {
                is_highlighted = true;
              } else {
                is_highlighted = false;
                halt_check = true;
              }

              break;
            default:
              break;
          }
        } else {
          is_highlighted = false;
          halt_check = true;
        }
      }

      if (
        property_map_options.highlight_option_home_equity != "none" &&
        property_map_options.highlight_option_home_equity != null &&
        !halt_check
      ) {
        if (property.calculated_equity_percent) {
          let equity_percent = property.calculated_equity_percent * 100;

          switch (property_map_options.highlight_option_home_equity) {
            case "over_10_percent":
              if (equity_percent > 10) {
                is_highlighted = true;
              } else {
                is_highlighted = false;
                halt_check = true;
              }

              break;

            case "over_25_percent":
              if (equity_percent > 25) {
                is_highlighted = true;
              } else {
                is_highlighted = false;
                halt_check = true;
              }

              break;

            case "over_50_percent":
              if (equity_percent > 50) {
                is_highlighted = true;
              } else {
                is_highlighted = false;
                halt_check = true;
              }

              break;
            default:
              break;
          }
        } else {
          is_highlighted = false;
          halt_check = true;
        }
      }
    }
  }

  if (darkMode) {
    switch (type) {
      case "stroke_width":
        if (is_highlighted == true) {
          return 2;
        }
        return 1;

      case "stroke":
        if (is_highlighted == true) {
          return "rgba(78,232,195, 0.2)";
        }
        if (mapType == "hybrid") {
          return "rgba(255, 255, 255, 0.2)";
        } else {
          return "rgba(255,255,255,0.3)";
        }

      case "fill":
        if (is_highlighted == true) {
          if (mapType == "hybrid") {
            return "rgba(255,255,255, 0.5)";
          } else {
            return "rgba(78,232,195, 0.75)";
          }
        }
        return "rgba(99, 89, 92, 0.0)";
      default:
        break;
    }
  } else {
    switch (type) {
      case "stroke_width":
        if (is_highlighted == true) {
          return 2;
        }
        return 1;

      case "stroke":
        if (is_highlighted == true) {
          if (mapType == "hybrid") {
            return "rgba(255,255,255, 0.2)";
          } else {
            return "rgba(78,232,195, 0.2)";
          }
        }
        if (mapType == "hybrid") {
          return "rgba(255, 255, 255, 0.2)";
        } else {
          return "rgba(99, 89, 92, 0.2)";
        }

      case "fill":
        if (is_highlighted == true) {
          if (mapType == "hybrid") {
            return "rgba(255,255,255, 0.5)";
          } else {
            return "rgba(78,232,195, 0.75)";
          }
        }

        return "rgba(99, 89, 92, 0.0)";
      default:
        break;
    }
  }

  return "rgba(0,0,0,0.0)";
};
