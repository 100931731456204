import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Gradient, Wrapper, Row } from "app/NativeComponents/common";
import { CircleButton, IconButton } from "app/NativeComponents/snippets";

import {
  tabNavigation,
  setModal,
  toggleModal,
  changeTab,
  pushSidePanel,
  searchDealMachine,
  selectActiveProperty,
  setActiveList,
  nextOnboardingStep,
  loadNewsAndUpdates,
  getDashboardStats,
  getWorkspaceTeams,
  setActiveWorkspaceTeam,
  accessWorkspaceTeam,
  getWorkspaceSettings
} from "app/NativeActions";

import TeamAccess from "./TeamAccess";

class WorkspaceNavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      intercom_opened: false,
      local_tab: "",
      show_mail_menu: false,
      loading_seen_slugs: true,
      news_slugs: [],
      seen_news_slugs: [],
      show_team_selector: false,
      team_search: "",
      access_team_loading: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tab !== this.props.tab) {
      this.setState({
        local_tab: this.props.tab
      });
    }

    if (this.props.location?.pathname !== prevProps.location?.pathname) {
      this.setTabFromURLPath();
    }

    if (
      JSON.stringify(this.state.news_slugs) !==
        JSON.stringify(prevState.news_slugs) ||
      prevProps.tab !== this.props.tab
    ) {
      this.getNotifications();
    }
  }

  setTabFromURLPath() {
    //
    const main_path = window?.location.pathname || "";

    if (main_path.startsWith("/dashboard")) {
      this.setState({
        local_tab: "dashboard"
      });
    } else if (main_path.startsWith("/account-settings")) {
      this.setState({
        local_tab: "account-settings"
      });
    } else if (main_path.startsWith("/dialer")) {
      this.setState({
        local_tab: "dialer"
      });
    } else if (main_path.startsWith("/mail-center")) {
      this.setState({
        local_tab: "mail-center"
      });
    } else {
      this.setState({
        local_tab: "dashboard"
      });
    }
    if (!main_path.startsWith("/dashboard")) {
      this.loadDashboardNotifications();
    }
  }

  componentDidMount() {
    this.setTabFromURLPath();

    this.props.getWorkspaceSettings({
      token: this.props.token,
      onLoading: () => {},
      onError: () => {},
      onSuccess: results => {}
    });
  }

  loadDashboardNotifications() {
    this.setState(
      {
        loading_seen_slugs: true
      },
      () => {
        const { data } = loadNewsAndUpdates();

        loadNewsAndUpdates()
          .then(data => {
            let slugs = [];
            if (data && data.items) {
              //for all of the data items..get the slug_id from each one and put them in slugs
              data.items.map(item => {
                if (
                  item.fields &&
                  item.fields.slug_id &&
                  item.fields.show_notification
                ) {
                  slugs.push(item.fields.slug_id);
                }
              });

              this.setState({
                news_slugs: slugs
              });
            }
            this.setState(() => ({ contentful_loading: false }));
          })
          .catch(err => {
            this.setState(() => ({ contentful_loading: false }));
          });
      }
    );
  }

  getNotifications() {
    this.setState(
      {
        loading_seen_slugs: true
      },
      () => {
        this.props.getDashboardStats({
          token: this.props.token,
          type: "get_news_and_updates_notifications",
          slugs: this.state.news_slugs.join(","),
          onLoading: () => {},
          onError: error => {},
          onSuccess: results => {
            this.setState({
              loading_seen_slugs: false,
              seen_news_slugs: results?.seen_news_slugs
            });
          }
        });
      }
    );
  }

  render() {
    const { colors, device, tab, source_of_truth, user } = this.props;
    const { local_tab } = this.state;

    return (
      <Gradient
        style={{
          alignSelf: "stretch",
          height: 60,
          justifyContent: "center",
          minWidth: 950
        }}
        color1={colors.side_gradient_color_1}
        color2={colors.side_gradient_color_2}
      >
        <Wrapper
          style={{
            height: 60
          }}
        >
          <Row>
            <Row>
              <TeamAccess local_tab={local_tab} />
            </Row>
            <Wrapper style={{ flex: 1 }}>
              <Row style={{ marginRight: 10, justifyContent: "flex-end" }}>
                <IconButton
                  tooltip="Help & Support"
                  icon_color={colors.white_text_color}
                  icon={"contact-support"}
                  borderColor={colors.white_text_color}
                  image_size={36}
                  no_border={true}
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "help_center",
                      overlay: true
                    });
                    //displayIntercom();
                  }}
                  size={22}
                  style={{
                    borderRadius: 0,
                    margin: 0,
                    height: 60,
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: 0,
                    paddingBottom: 0
                  }}
                />

                <CircleButton
                  icon="person"
                  button_type="profile_pic"
                  user={this.props.user}
                  show_notification={false}
                  notification_color={colors.active_color}
                  notification_icon={""}
                  borderColor={colors.white_text_color}
                  image_size={36}
                  no_border={true}
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "settings",
                      overlay: true
                    });
                  }}
                />
              </Row>
            </Wrapper>
          </Row>
        </Wrapper>
      </Gradient>
    );
  }
}

const mapStateToProps = ({
  auth,
  native,
  settings,
  route,
  drawer,
  partner,
  tasks,
  feature_toggle,
  onboarding,
  billing,
  dialer,
  workspaces
}) => {
  const { token, user, user_partner_info } = auth;
  const { device, isIphoneX, isMobile, platform, desktopMobile } = native;
  const { colors } = settings;
  const { track_route } = route;
  const { tab } = drawer;
  const { is_partner } = partner;
  const { active_task_count } = tasks;
  const { source_of_truth, all_feature_metadata } = billing;
  const { mailCenter, listBuilderMap } = feature_toggle;
  const { current_call_session } = dialer;
  const { workspace_teams, active_workspace_team } = workspaces;
  return {
    user,
    user_partner_info,
    token,
    device,
    isIphoneX,
    isMobile,
    platform,
    desktopMobile,
    colors,
    track_route,
    tab,
    is_partner,
    active_task_count,
    onboarding,
    source_of_truth,
    mailCenter,
    listBuilderMap,
    all_feature_metadata,
    current_call_session,
    workspace_teams,
    active_workspace_team
  };
};

export default withRouter(
  connect(mapStateToProps, {
    tabNavigation,
    setModal,
    changeTab,
    pushSidePanel,
    searchDealMachine,
    selectActiveProperty,

    toggleModal,
    setActiveList,
    nextOnboardingStep,
    getDashboardStats,
    getWorkspaceTeams,
    setActiveWorkspaceTeam,
    accessWorkspaceTeam,
    getWorkspaceSettings
  })(WorkspaceNavBar)
);
