import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  KeyboardView,
  Spinner,
  Wrapper,
  Title,
  Copy,
  Bold,
  Button,
  Row,
  GoogleOAuth,
  AppleLogin,
  Spin
} from "app/NativeComponents/common";
import {
  NewHeader,
  InlineButton,
  BottomNavBar,
  InputBox
} from "app/NativeComponents/snippets";

import {
  /*mobile*/
  toggleCanPop,
  authFieldChanged,
  registerUser,
  appRedirect,
  getUser,
  getStats,
  updateMarketingUser,
  setModal,
  toggleModal,
  /*common functions*/
  loadSignUpOnboarding,
  dismissMobileKeyboard,
  setOAuthInfo,
  showErrorMessage,
  loginUser,
  getUserFromAppleAuth,
  openUrl,
  popSidePanel,
  pushSidePanel,
  replaceSidePanel,
  getDataFromHubspot,
  displayIntercom,
  getCookies
} from "app/NativeActions";
import axios from "axios";

import Body from "./Body";
import { store } from "../../../store";
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      promoText: "",
      errors: {},
      defaultPromoText: this.props.user_info.promoText,
      onboarding_content: null,
      onboarding_content_loading: true,
      successfulValidations: {},

      loading: false,
      oauth_loading: false,
      ip_address: null
    };
    this.login = this.login.bind(this);
    this.appleLogin = this.appleLogin.bind(this);
    this.renderAuth = this.renderAuth.bind(this);
    this.getIPAddress = this.getIPAddress.bind(this);

    this.handleBack = this.handleBack.bind(this);
  }

  confirmValidation = () => {
    let valid = true;
    let validate = ["email", "password", "firstname", "lastname"];
    validate.forEach(name => {
      let check = this.handleValidation(name, this.props[name], true);
      if (!check) {
        valid = false;
      }
    });
    return valid;
  };

  handleValidation = (name, value = "") => {
    let { errors, successfulValidations } = this.state;
    if (!value) {
      value = "";
    }
    switch (name) {
      case "firstname":
        errors.firstname =
          value.trim().length < 1 ? "*First Name is required" : "";
        successfulValidations.firstname = !errors.firstname;
        break;
      case "lastname":
        errors.lastname =
          value.trim().length < 1 ? "*Last Name is required" : "";
        successfulValidations.lastname = !errors.lastname;
        break;
      case "email":
        errors.email = validEmailRegex.test(
          value && value != "" ? value : this.props.user_info.email
        )
          ? ""
          : "*Email is not valid";
        successfulValidations.email = !errors.email;
        break;
      case "phone":
        errors.phone = !value.match(
          /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}/
        )
          ? "*Phone number is not valid"
          : "";
        successfulValidations.phone = !errors.phone;
        break;
      case "password":
        errors.password =
          value.trim().length < 5
            ? "*Password must be at least 5 characters long"
            : "";
        successfulValidations.password = !errors.password;
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value, successfulValidations });
    return !errors[name];
  };

  handleChange(value) {
    this.setState({
      promoText: value,
      defaultPromoText: ""
    });
  }

  componentDidMount() {
    let oauthed = false;

    this.setSignUpFields(oauthed);
    this.getOnboardingData();

    //get the ip address
    this.getIPAddress();

    if (
      this.props.device === "desktop" &&
      this.props.user_info?.signup_type !== "team_invite"
    ) {
      const cookies = getCookies();
      const hubspot_cookie = cookies?.hubspot_cookie;
      this.props.getDataFromHubspot({
        type: "get_contact_from_cookie",
        hubspot_cookie,
        onSuccess: results => {
          if (!this.props.email && !!results?.properties?.email?.value) {
            this.props.authFieldChanged({
              prop: "email",
              value: results?.properties?.email?.value
            });
          }
        }
      });
    }
  }

  async getIPAddress() {
    const res = await axios.get("https://geolocation-db.com/json/");
    this.setState({
      ip_address: res?.data?.IPv4
    });
  }

  setSignUpFields = oauthed => {
    if (this.props.oauth_info && !oauthed) {
      if (this.props.oauth_info.profileObj) {
        if (!this.props.oauth_info.apple_id) {
          this.props.authFieldChanged({
            prop: "firstname",
            value: this.props.oauth_info.profileObj.givenName
          });
          this.props.authFieldChanged({
            prop: "lastname",
            value: this.props.oauth_info.profileObj.familyName
          });
          this.props.authFieldChanged({
            prop: "oauth_token",
            value: this.props.oauth_info.accessToken
          });
        } else {
          this.props.authFieldChanged({
            prop: "apple_id",
            value: this.props.oauth_info.apple_id
          });
        }
        this.props.authFieldChanged({
          prop: "email",
          value: this.props.oauth_info.profileObj.email
        });
      }
    }
  };

  getOnboardingData() {
    loadSignUpOnboarding("new_onboarding")
      .then(data => {
        if (data && data.items && data.items.length > 0) {
          this.setState(() => ({
            onboarding_content: data.items[0]
              ? data.items[0].fields
                ? data.items[0].fields
                : null
              : null,
            onboarding_content_loading: false
          }));
        }
        this.setState(() => ({ onboarding_content_loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ onboarding_content_loading: false }));
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user_info && this.props.user_info) {
      if (prevProps.user_info.promoText != this.props.user_info.promoText) {
        this.setState({
          promoText: "",
          defaultPromoText: this.props.user_info.promoText
        });
      }
    }
  }

  handleBack() {
    /* mobile */
    dismissMobileKeyboard();
    this.props.popSidePanel();
  }

  register() {
    let valid = this.confirmValidation();
    /* mobile */
    dismissMobileKeyboard();

    const cookies = getCookies();
    if (valid) {
      const {
        firstname,
        lastname,
        password,
        user_info,
        accepted_terms,
        platform,
        oauth_token,
        oAuthIdToken,
        device,
        apple_id,
        dealfinder_team_id,
        phone
      } = this.props;

      this.props.registerUser({
        firstname,
        lastname,
        email:
          this.props.email && this.props.email != ""
            ? this.props.email
            : this.props.user_info.email,
        password,
        accepted_terms,
        phone,
        check_phone:
          this.props.visitor?.marketing_experiments?.dealmachine_free ===
            "dealmachine_free" && this.props.device === "desktop"
            ? 1
            : 0,
        from_campaign: user_info.from_campaign,
        from_source: user_info.from_source,
        branch_id: user_info.branch_id,
        promo:
          user_info.signup_type !== "team_invite"
            ? !!this.state.defaultPromoText
              ? this.state.defaultPromoText
              : this.state.promoText
            : null,
        first_promoter_ref:
          user_info.signup_type !== "team_invite"
            ? cookies?.first_promoter_ref
            : null,
        first_promoter_cookie:
          user_info.signup_type !== "team_invite"
            ? cookies?.first_promoter_cookie
            : null,
        device,
        platform:
          this.props.device == "desktop"
            ? this.props.isMobile
              ? "mobile"
              : "desktop"
            : this.props.platform,
        oAuthToken: oauth_token,
        oAuthIdToken: oAuthIdToken,
        apple_id: apple_id,
        team_id: !!user_info?.team_id ? user_info.team_id : dealfinder_team_id,
        hubspot_cookie: cookies?.hubspot_cookie,
        hubspot_page_url:
          this.props.device == "mobile"
            ? "dealmachine://"
            : "https://app.dealmachine.com/sign-up",
        hubspot_page_title:
          this.props.device == "mobile"
            ? this.props.platform == "ios"
              ? "iOS Signup Page"
              : "Android Signup Page"
            : "Web App Signup Page",
        ip_address: this.state.ip_address,
        onLoading: () => {
          this.setState({ loading: true });
        },
        onError: () => {
          this.setState({ loading: false });
        },
        onSuccess: results => {}
      });
    }
  }

  checkIfNeedsToSave() {
    if (
      (this.props.email && this.props.email != "") ||
      (!this.props.email &&
        this.props.email == "" &&
        this.props.user_info.email &&
        this.props.user_info.email != "") ||
      (this.props.firstname && this.props.firstname != "") ||
      (this.props.lastname && this.props.lastname != "") ||
      (this.props.company && this.props.company != "") ||
      (this.props.city && this.props.city != "") ||
      (this.state.defaultPromoText && this.state.defaultPromoText != "") ||
      (this.props.password && this.props.password != "")
    ) {
      return true;
    }

    return false;
  }
  getWindowDimensions() {
    if (window) {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height
      };
    } else {
      return {
        width: 900,
        height: 900
      };
    }
  }

  appleLogin(appleresponse = "") {
    /* mobile */
    dismissMobileKeyboard();
    const {
      password,
      device,
      initialRedirect,
      platform,
      user_info,
      dealfinder_team_id,
      getUserFromAppleAuth
    } = this.props;
    const cookies = getCookies();

    if (appleresponse) {
      //this.props.setOAuthInfo(oAuth);
      getUserFromAppleAuth({
        appleToken:
          device === "mobile"
            ? appleresponse?.identityToken
            : appleresponse?.authorization?.id_token,
        onSuccess: results => {
          if (results?.user_exists) {
            this.props.loginUser({
              device,
              initialRedirect,
              apple_id:
                device === "mobile"
                  ? appleresponse?.identityToken
                  : appleresponse?.authorization?.id_token,
              onLoading: () => {
                this.setState({
                  oauth_loading: true
                });
              },
              onError: () => {
                this.setState({
                  oauth_loading: false
                });
              },
              onSuccess: results => {}
            });
          } else {
            this.props.registerUser({
              email:
                device === "mobile"
                  ? appleresponse.email || results?.user_email
                  : appleresponse?.user?.email || results?.user_email,
              firstname:
                device === "mobile"
                  ? appleresponse.fullName.givenName || results?.user_firstname
                  : appleresponse?.user?.name?.firstName ||
                    results?.user_firstname,
              lastname:
                device === "mobile"
                  ? appleresponse.fullName.familyName || results?.user_lastname
                  : appleresponse?.user?.name?.lastName ||
                    results?.user_lastname,
              accepted_terms: 1,
              check_phone: 0,
              initialRedirect,
              from_campaign: user_info?.from_campaign,
              from_source: user_info?.from_source,
              branch_id: user_info?.branch_id,
              team_id: !!user_info?.team_id
                ? user_info.team_id
                : dealfinder_team_id,
              affiliate_partner: this.props.affiliate_partner ? 1 : 0,
              platform,
              device,
              apple_id:
                device === "mobile"
                  ? appleresponse?.identityToken
                  : appleresponse?.authorization?.id_token,
              promo:
                user_info?.signup_type !== "team_invite"
                  ? !!this.state.defaultPromoText
                    ? this.state.defaultPromoText
                    : this.state.promoText
                  : null,
              first_promoter_ref:
                user_info?.signup_type !== "team_invite"
                  ? cookies?.first_promoter_ref
                  : null,
              first_promoter_cookie:
                user_info?.signup_type !== "team_invite"
                  ? cookies?.first_promoter_cookie
                  : null,
              hubspot_cookie: cookies?.hubspot_cookie,
              hubspot_page_url:
                this.props.device == "mobile"
                  ? "dealmachine://"
                  : "https://app.dealmachine.com/sign-up",
              hubspot_page_title:
                this.props.device == "mobile"
                  ? "App Signup Page"
                  : "Web App Signup Page",
              ip_address: this.state.ip_address,

              onLoading: () => {
                this.setState({ oauth_loading: true });
              },
              onError: () => {
                this.setState({ oauth_loading: false });
              },
              onSuccess: results => {}
            });
          }
        }
      });
    } else {
      this.props.showErrorMessage("Apple authentication failed.", "Error");
    }
  }

  login(oAuth = null, oAuthProfile = null) {
    /* mobile */
    dismissMobileKeyboard();
    const {
      password,
      device,
      initialRedirect,
      platform,
      user_info,
      dealfinder_team_id
    } = this.props;
    const cookies = getCookies();

    if (oAuth) {
      const { email, familyName, givenName, imageUrl } = oAuth?.profileObj;
      this.props.setOAuthInfo(oAuth);
      this.props.registerUser({
        email: email,
        device,
        firstname: givenName,
        lastname: familyName,
        image: imageUrl,
        accepted_terms: 1,
        check_phone: 0,
        initialRedirect,
        oAuthToken: oAuth?.accessToken,
        oAuthIdToken: oAuth?.idToken,
        from_campaign: user_info?.from_campaign,
        from_source: user_info?.from_source,
        branch_id: user_info?.branch_id,
        team_id: !!user_info?.team_id ? user_info?.team_id : dealfinder_team_id,
        affiliate_partner: this.props.affiliate_partner ? 1 : 0,
        platform:
          this.props.device == "desktop"
            ? this.props.isMobile
              ? "mobile"
              : "desktop"
            : this.props.platform,
        promo:
          user_info?.signup_type !== "team_invite"
            ? !!this.state.defaultPromoText
              ? this.state.defaultPromoText
              : this.state.promoText
            : null,
        first_promoter_ref:
          user_info?.signup_type !== "team_invite"
            ? cookies?.first_promoter_ref
            : null,
        first_promoter_cookie:
          user_info?.signup_type !== "team_invite"
            ? cookies?.first_promoter_cookie
            : null,
        hubspot_cookie: cookies?.hubspot_cookie,
        hubspot_page_url:
          this.props.device == "mobile"
            ? "dealmachine://"
            : "https://app.dealmachine.com/sign-up",
        hubspot_page_title:
          this.props.device == "mobile"
            ? "App Signup Page"
            : "Web App Signup Page",
        ip_address: this.state.ip_address,
        onLoading: () => {
          this.setState({ loading: true });
        },
        onError: () => {
          this.setState({ loading: false });
        },
        onSuccess: results => {
          this.setState({ loading: false });
          this.setSignUpFields(false);
          if (
            results?.user &&
            results.user?.team_owner == 1 &&
            results.user?.finished_onboarding != 1 &&
            this.props.device == "mobile"
          ) {
            this.props.replaceSidePanel({
              slug: "start_trial",
              overlay: true
            });
          }
        }
      });
    } else {
      this.props.showErrorMessage("Google authentication failed.", "Error");
    }
  }
  renderHeader() {
    if (this.props.device == "desktop") {
      return (
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 25
          }}
        >
          <Wrapper style={{ marginBottom: 25 }}>
            <InlineButton
              buton_type="full_button"
              onPress={() => {
                this.props.appRedirect({ redirect: "login" });
              }}
            >
              Already have an account? <Bold>Sign in</Bold>
            </InlineButton>
          </Wrapper>
          {this.props.affiliate_partner ? (
            <>
              <Title style={{ textAlign: "center" }}>
                Join our affiliate partner program
              </Title>
            </>
          ) : !this.props.dealfinder_team_id &&
            this.props.user_info?.signup_type != "team_invite" ? (
            <>
              <Title style={{ textAlign: "center" }}>
                {this.state.onboarding_content
                  ? this.state.onboarding_content.signUpTitle
                  : ""}
              </Title>
              <Copy style={{ textAlign: "center" }}>
                {this.state.onboarding_content
                  ? this.state.onboarding_content.signUpDescription
                  : ""}
              </Copy>
            </>
          ) : (
            <Title style={{ textAlign: "center" }}>
              Join {this.props.user_info?.team_name}'s team
            </Title>
          )}
          {!this.props.dealfinder_team_id &&
          this.props.user_info?.signup_type != "team_invite" ? (
            <Wrapper style={{ flex: 1, alignSelf: "stretch" }}>
              <Row
                style={{
                  marginTop: 25,
                  alignSelf: "stretch",
                  justifyContent: "center"
                }}
              >
                <Wrapper
                  style={{
                    flex: 1,
                    margin: 5
                  }}
                >
                  <GoogleOAuth
                    buttonText={"Sign up with Google"}
                    onSuccess={this.login}
                  />
                </Wrapper>
                <Wrapper
                  style={{
                    flex: 1,
                    margin: 5
                  }}
                >
                  <AppleLogin
                    title={"Sign up with Apple"}
                    onSucc={this.appleLogin}
                    redirectPath={"sign-up"}
                  />
                </Wrapper>
              </Row>
              <Row
                style={{
                  padding: 25,
                  alignSelf: "stretch"
                }}
              >
                <Wrapper
                  style={{ flex: 1, height: 1, backgroundColor: "#CCC" }}
                />
                <Copy
                  style={{
                    width: "10%",
                    textAlign: "center",
                    marginLeft: 5,
                    marginRight: 5
                  }}
                >
                  Or
                </Copy>
                <Wrapper
                  style={{ flex: 1, height: 1, backgroundColor: "#CCC" }}
                />
              </Row>
            </Wrapper>
          ) : null}
        </Wrapper>
      );
    }
  }

  renderAuth() {
    if (!this.props.email) {
      return (
        <Wrapper style={{ marginTop: this.props.device == "mobile" ? 25 : 0 }}>
          <Wrapper
            style={{ flex: 1, margin: this.props.device == "mobile" ? 5 : 0 }}
          >
            <GoogleOAuth
              buttonText={"Sign up with Google"}
              onSuccess={this.login}
            />
          </Wrapper>
          {this.props.platform !== "android" ? (
            <Wrapper style={{ width: "100%", marginLeft: 5 }}>
              <AppleLogin
                title={"Sign up with Apple"}
                onSucc={this.appleLogin}
                redirectPath={"sign-up"}
              />
            </Wrapper>
          ) : null}
          <Row
            style={{
              maxWidth: "100%",
              margin: this.props.device == "mobile" ? 20 : 0,
              marginTop: 20,
              marginBottom: 20,
              justifyContent: "center"
            }}
          >
            <Wrapper style={{ flex: 1, height: 1, backgroundColor: "#CCC" }} />
            <Copy
              style={{
                width: "10%",
                textAlign: "center",
                marginLeft: 5,
                marginRight: 5
              }}
            >
              Or
            </Copy>
            <Wrapper style={{ flex: 1, height: 1, backgroundColor: "#CCC" }} />
          </Row>
        </Wrapper>
      );
    }
  }

  render() {
    if (this.props.noheader) {
      return (
        <Body
          {...this.props}
          errors={this.state.errors}
          handleValidation={this.handleValidation.bind(this)}
          successfulValidations={this.state.successfulValidations}
          register={this.register.bind(this)}
          checkIfNeedsToSave={this.checkIfNeedsToSave.bind(this)}
          handleBack={this.handleBack.bind(this)}
          handleChange={this.handleChange.bind(this)}
          promoText={this.state.promoText}
          defaultPromoText={this.state.defaultPromoText}
          onboarding_content={this.state.onboarding_content}
          loading={this.state.loading}
          login={this.login}
          appleLogin={this.appleLogin}
        />
      );
    }
    return (
      <Container title={"Create an account"}>
        {this.props.device == "mobile" ? (
          <NewHeader
            title={
              this.props.user_info?.signup_type == "team_invite"
                ? "Join " + this.props.user_info?.team_name + "'s Team"
                : "Create an Account"
            }
            leftButton={{
              icon: "arrow-back",
              onPress: this.handleBack
            }}
          />
        ) : null}
        <KeyboardView style={{ flex: 1 }}>
          <Body
            {...this.props}
            errors={this.state.errors}
            handleValidation={this.handleValidation.bind(this)}
            successfulValidations={this.state.successfulValidations}
            register={this.register.bind(this)}
            checkIfNeedsToSave={this.checkIfNeedsToSave.bind(this)}
            handleBack={this.handleBack.bind(this)}
            handleChange={this.handleChange.bind(this)}
            promoText={this.state.promoText}
            defaultPromoText={this.state.defaultPromoText}
            onboarding_content={this.state.onboarding_content}
            loading={this.state.loading}
            login={this.login}
            appleLogin={this.appleLogin}
            buttonText={this.props.buttonText}
          />
        </KeyboardView>

        {this.props.device == "mobile" ? (
          <BottomNavBar>
            <Row style={{ justifyContent: "space-between" }}>
              <InlineButton
                onPress={() => {
                  this.props.replaceSidePanel({
                    slug: "login",
                    overlay: true
                  });
                }}
              >
                Log in
              </InlineButton>
              <InlineButton
                onPress={() => displayIntercom()}
                id="intercom_button"
              >
                Need help? Talk to a human.
              </InlineButton>
            </Row>
          </BottomNavBar>
        ) : (
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              padding: 25
            }}
          >
            <InlineButton internal={true} rel={""} href={"/"}>
              Already have an account? Login
            </InlineButton>
          </Wrapper>
        )}

        {this.state.oauth_loading ? (
          <Wrapper
            style={{
              backgroundColor: "rgba(0,0,0,0.6)",
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Spin color={this.props.colors.white_text_color} />
          </Wrapper>
        ) : null}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, marketing, settings, native }) => {
  const {
    signup_type,
    signup_team_name,
    signup_team_company,

    email,
    phone,
    city,
    password,
    firstname,
    lastname,
    company,
    loading,
    accepted_terms,
    from_campaign,
    from_source,
    oauth_info,
    oauth_token,
    apple_id
  } = auth;

  const { user_info, partner_info, signup_funnel, visitor } = marketing;
  const { device, platform, isMobile } = native;
  const { colors } = settings;
  return {
    email,
    phone,
    city,
    password,
    firstname,
    lastname,
    company,
    accepted_terms,
    loading,
    device,
    user_info,
    partner_info,
    signup_funnel,
    oauth_info,
    oauth_token,
    apple_id,
    platform,
    isMobile,
    colors,
    visitor
  };
};

export default connect(mapStateToProps, {
  /*mobile*/
  toggleCanPop,
  appRedirect,
  loginUser,
  setOAuthInfo,
  getUser,
  getStats,
  setModal,
  toggleModal,
  authFieldChanged,
  updateMarketingUser,
  showErrorMessage,
  registerUser,
  getUserFromAppleAuth,
  popSidePanel,
  pushSidePanel,
  replaceSidePanel,
  getDataFromHubspot
})(Register);
