import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  ExternalImage,
  Row,
  Copy,
  Icon,
  Spin,
  Button
} from "app/NativeComponents/common";
import { PopoverMenu, PhotoOverlay } from "app/NativeComponents/snippets";
class PhotoGalleryImage extends Component {
  render() {
    const {
      colors,
      property: { deal },
      additional_image,
      loading_images = [],
      overlay_text = "",
      includes_primary_button = true
    } = this.props;

    let is_loading = false;
    for (let i = 0; i < loading_images.length; i++) {
      if (
        (additional_image.id && additional_image.id == loading_images[i].id) ||
        (!additional_image.id &&
          additional_image.image_type == loading_images[i].image_type)
      ) {
        is_loading = true;
      }
    }

    const primary_image =
      deal?.image == additional_image?.image ||
      (deal?.from_google_street_view == 1 &&
        additional_image?.image_type == "street_view") ||
      (!deal?.image && additional_image?.image_type == "street_view");

    return (
      <Wrapper
        style={{
          position: "relative",
          width: this.props.photo_width,
          height: this.props.photo_height,
          overflow: "hidden",
          borderRadius: 5,
          borderWidth: 1,
          borderColor: colors.border_color,
          borderStyle: "solid",
          ...this.props.photoStyle
        }}
      >
        <ExternalImage
          style={{
            width: this.props.photo_width,
            height: this.props.photo_height,
            overflow: "hidden",
            borderRadius: 5
          }}
          image={additional_image?.image}
          no_lazy={true}
        />

        {!!overlay_text ? (
          <Wrapper
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              padding: 5,
              margin: 5,
              backgroundColor: "rgba(0,0,0,0.3)",
              overflow: "hidden",
              borderRadius: 5
            }}
          >
            <Row>
              {!!overlay_text ||
              (primary_image && deal.from_google_street_view == 1) ? (
                <Copy style={{ fontSize: 12, color: colors.white_text_color }}>
                  {overlay_text
                    ? overlay_text
                    : primary_image && deal.from_google_street_view == 1
                    ? "Street Pic"
                    : ""}
                </Copy>
              ) : null}
              {((!is_loading && additional_image.id) ||
                additional_image.image_type == "street_view") &&
              includes_primary_button == true ? (
                <Button
                  tooltip="Set Primary Image"
                  tooltipPlacement="top"
                  onPress={() => {
                    this.props.updatePropertyImage({
                      token: this.props.token,
                      lead_id: deal.id,
                      property_image_id: additional_image?.id,
                      type:
                        additional_image.image_type == "street_view"
                          ? "set_google_street_pic_as_primary"
                          : "set_primary_house_image",
                      onLoading: () => {
                        this.props.setLoadingImages({
                          loading_images: [...loading_images, additional_image]
                        });
                      },
                      onError: results => {
                        this.props.setLoadingImages({
                          loading_images: loading_images.filter(
                            ({ id }) => id !== results.property_image_id
                          )
                        });
                      },
                      onSuccess: results => {
                        this.props.setLoadingImages({
                          loading_images: loading_images.filter(
                            ({ id }) => id !== results.property_image_id
                          )
                        });
                        if (this.props.propertyUpdated && results.property) {
                          this.props.propertyUpdated(results.property);
                        }
                      }
                    });
                  }}
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: !!overlay_text ? 5 : 0
                  }}
                >
                  <Icon
                    icon={primary_image ? "star" : "star-outline"}
                    color={colors.white_text_color}
                    size={18}
                  />
                </Button>
              ) : null}
            </Row>
          </Wrapper>
        ) : null}

        {!primary_image &&
        !is_loading &&
        additional_image.id &&
        this.props.include_menu ? (
          <Wrapper
            style={{
              position: "absolute",
              top: 0,
              right: 0
            }}
          >
            <PopoverMenu
              icon={"more-vert"}
              style={{
                backgroundColor: "rgba(0,0,0,0.3)",
                padding: 5,
                margin: 5,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 5
              }}
              icon_size={18}
              color={colors.white_text_color}
              menu_items={[
                {
                  title: "Set Primary Image",
                  type: "normal",
                  onPress: () => {
                    this.props.updatePropertyImage({
                      token: this.props.token,
                      lead_id: deal.id,
                      property_image_id: additional_image.id,
                      type: "set_primary_house_image",
                      onLoading: () => {
                        this.props.setLoadingImages({
                          loading_images: [...loading_images, additional_image]
                        });
                      },
                      onError: results => {
                        this.props.setLoadingImages({
                          loading_images: loading_images.filter(
                            ({ id }) => id !== results.property_image_id
                          )
                        });
                      },
                      onSuccess: results => {
                        this.props.setLoadingImages({
                          loading_images: loading_images.filter(
                            ({ id }) => id !== results.property_image_id
                          )
                        });

                        if (this.props.propertyUpdated && results.property) {
                          this.props.propertyUpdated(results.property);
                        }
                      }
                    });
                  }
                },
                {
                  title: additional_image.use_for_marketing
                    ? "Hide From Marketing"
                    : "Use For Marketing",
                  type: "normal",
                  onPress: () => {
                    this.props.updatePropertyImage({
                      token: this.props.token,
                      lead_id: deal.id,
                      property_image_id: additional_image.id,
                      type: "edit_image_marketing",
                      onLoading: () => {
                        this.props.setLoadingImages({
                          loading_images: [...loading_images, additional_image]
                        });
                      },
                      onError: results => {
                        this.props.setLoadingImages({
                          loading_images: loading_images.filter(
                            ({ id }) => id !== results.property_image_id
                          )
                        });
                      },
                      onSuccess: results => {
                        this.props.setLoadingImages({
                          loading_images: loading_images.filter(
                            ({ id }) => id !== results.property_image_id
                          )
                        });

                        if (this.props.propertyUpdated && results.property) {
                          this.props.propertyUpdated(results.property);
                        }
                      }
                    });
                  }
                },
                additional_image.image_type !== "street_view" &&
                additional_image.image_type !== "roadmap" &&
                additional_image.image_type !== "satellite" &&
                additional_image.id
                  ? {
                      title: "Delete Image",
                      type: "delete",
                      onPress: () => {
                        this.props.updatePropertyImage({
                          token: this.props.token,
                          lead_id: deal.id,
                          property_image_id: additional_image.id,
                          type: "delete_deal_image",
                          onLoading: () => {
                            this.props.setLoadingImages({
                              loading_images: [
                                ...loading_images,
                                additional_image
                              ]
                            });
                          },
                          onError: results => {
                            this.props.setLoadingImages({
                              loading_images: loading_images.filter(
                                ({ id }) => id !== results.property_image_id
                              )
                            });
                          },
                          onSuccess: results => {
                            this.props.setLoadingImages({
                              loading_images: loading_images.filter(
                                ({ id }) => id !== results.property_image_id
                              )
                            });

                            if (
                              this.props.propertyUpdated &&
                              results.property
                            ) {
                              this.props.propertyUpdated(results.property);
                            }
                          }
                        });
                      }
                    }
                  : null
              ]}
            />
          </Wrapper>
        ) : null}

        {is_loading ? (
          <Wrapper
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "#ffffff85",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Spin size="small" />
          </Wrapper>
        ) : null}
      </Wrapper>
    );
  }
}

export default PhotoGalleryImage;
