import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Row,
  Draggable,
  Animation,
  Copy,
  Bold,
  Icon,
  Blur
} from "app/NativeComponents/common";
import { IconButton, InlineButton } from "app/NativeComponents/snippets";

import {
  pushSidePanel,
  isMobilePhoneCheck,
  isTabletCheck
} from "app/NativeActions";

import CurrentConversation from "app/DealMachineCore/components/DialerWidget/CurrentConversation";
import MainButtons from "app/DealMachineCore/components/DialerWidget/MainButtons";
import IncomingCallButtons from "app/DealMachineCore/components/DialerWidget/IncomingCallButtons";

class DialerComponents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 80,
      outer_height: 95,
      show_more_height:
        props.device === "mobile" || props.desktopMobile ? 550 : 550,
      show_more: props.onboarding ? true : false,
      width:
        (props.device === "mobile" || props.desktopMobile) && props.onboarding
          ? "100%"
          : 500,
      x: props.window_width / 2 - 225,
      y: props.window_height - 105
    };

    this.checkMicPermissions = this.checkMicPermissions.bind(this);
    this.micPermissionHandler = this.micPermissionHandler.bind(this);

    this.hideMore = this.hideMore.bind(this);

    this.canvasRef = React.createRef();
  }

  micPermissionHandler({
    permission,
    onSuccess = () => {},
    onError = () => {}
  }) {
    this.setState(
      {
        mic_permissions: permission
      },
      () => {
        switch (permission) {
          case "granted":
            onSuccess(permission);
            break;

          case "denied":
          case "prompt":
          default:
            onError(permission);
            break;
        }
      }
    );
  }

  async checkMicPermissions({ onSuccess = () => {}, onError = () => {} }) {
    try {
      const permission = await navigator.permissions.query({
        name: "microphone"
      });

      permission.onchange = () => {
        this.micPermissionHandler({
          permission: permission?.state,
          onSuccess,
          onError
        });
      };

      this.micPermissionHandler({
        permission: permission?.state,
        onSuccess,
        onError
      });
    } catch (error) {
      console.error("Error checking microphone permissions:", error);
    }
  }

  setupAudioVisualization(stream, remote = false) {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();

    // Create a source from the stream
    const source = audioContext.createMediaStreamSource(stream);
    source.connect(analyser);

    // Rest of the visualization setup (similar to what was described earlier)
    this.drawAudio(analyser, remote);
  }
  drawAudio(analyser, remote) {
    const canvas = this.canvasRef.current;
    const canvasContext = canvas.getContext("2d");
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      requestAnimationFrame(draw);

      analyser.getByteFrequencyData(dataArray);

      // Clear the canvas
      canvasContext.clearRect(0, 0, canvas.width, canvas.height);

      const barWidth = (canvas.width / bufferLength) * 2.5;
      let barHeight;
      let x = 0;

      // Set base color based on contact_speaking
      const baseColor = remote
        ? { r: 0, g: 137, b: 123 }
        : { r: 2, g: 143, b: 163 };

      for (let i = 0; i < bufferLength; i++) {
        barHeight = dataArray[i];

        // Modify the color based on barHeight
        const brightness = Math.max(0, Math.min(255, barHeight + 100));
        canvasContext.fillStyle = `rgb(${(brightness * baseColor.r) / 255}, ${
          (brightness * baseColor.g) / 255
        }, ${(brightness * baseColor.b) / 255})`;

        const halfCanvasHeight = canvas.height / 2;
        const adjustedBarHeight = barHeight / 4; // Adjust as needed

        // Draw the bar upwards from the middle
        canvasContext.fillRect(
          x,
          halfCanvasHeight - adjustedBarHeight / 2,
          barWidth,
          adjustedBarHeight / 2
        );

        // Draw the bar downwards from the middle
        canvasContext.fillRect(
          x,
          halfCanvasHeight,
          barWidth,
          adjustedBarHeight / 2
        );

        x += barWidth + 1;
      }
    };

    draw();
  }

  componentWillUnmount() {}

  componentDidMount() {
    this.checkMicPermissions({
      onSuccess: permission => {
        this.props.handleNumberChange({
          mic_permissions: permission,
          first_number: true
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.localstream !== prevProps.localstream &&
      this.props.localstream &&
      prevProps.localstream === null
    ) {
      this.setupAudioVisualization(this.props.localstream);
    }

    if (
      ((this.props.active_call.call_status !==
        prevProps.active_call.call_status &&
        this.props.active_call.call_status === "call_ended") ||
        (this.props.active_call.answered_by !==
          prevProps.active_call.answered_by &&
          this.props.active_call.answered_by === "machine") ||
        (this.props.active_call.call_status !==
          prevProps.active_call.call_status &&
          this.props.active_call.call_status === "answered")) &&
      !this.state.show_more
    ) {
      this.setState({
        show_more: true,
        y: this.state.y - (this.state.show_more_height + 15),
        outer_height: this.determineHeight(true)
      });
    }
  }

  determineHeight(show_more) {
    if (show_more) {
      return this.state.outer_height + this.state.show_more_height + 15;
    }

    return this.state.outer_height - (this.state.show_more_height + 15);
  }

  hideMore() {
    //dummy function for mobile to work
  }

  renderInner() {
    const { colors } = this.props;
    const { call_status } = this.props.active_call;
    return (
      <Wrapper
        style={{
          backgroundColor: colors.dark_phone_color,
          borderTopRightRadius: 50,
          borderTopLeftRadius: 50,
          borderBottomLeftRadius: 50,
          borderBottomRightRadius: 50
        }}
        className={this.props.onboarding ? "dm-dialer onboarding" : "dm-dialer"}
      >
        {this.props.incoming_call &&
        !this.props.onboarding &&
        !this.props.demo_call &&
        ((this.props.active_dialer_contact?.individual_key !=
          this.props.incoming_call_info?.contact?.individual_key &&
          !this.props.active_dialer_contact?.incoming_call) ||
          (this.props.active_dialer_contact?.incoming_call &&
            call_status === "call_ended")) ? (
          <Animation
            type={"fadeInUp"}
            duration={250}
            style={{
              position: "absolute",
              top: -60,
              height: 100,
              width: this.state.width - 2,
              left: 0,
              right: 0,
              margin: "auto",
              backgroundColor: colors.dark_phone_inner_color,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: colors.dark_phone_inner_color,
              borderRadius: 50,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              justifyContent: "flex-start"
            }}
          >
            <IncomingCallButtons
              incoming_call_info={this.props.incoming_call_info}
              incoming_call={this.props.incoming_call}
              rejectIncomingCall={this.props.rejectIncomingCall}
              acceptIncomingCall={this.props.acceptIncomingCall}
              handleHangup={this.props.handleHangup}
            />
          </Animation>
        ) : !this.props.onboarding &&
          !this.props.demo_call &&
          this.props.source_of_truth?.dialer_seats &&
          this.props.source_of_truth?.dialer_seats &&
          this.props.source_of_truth?.dialer_seats.filter(
            seat => seat.seat_type === "dialer"
          ).length === 0 &&
          !this.props.hide_purchase_upsell &&
          !this.props.active_dialer_contact?.incoming_call &&
          !this.props.active_dialer_contact?.answered_on_another_device &&
          !this.props.user?.workspace_id &&
          this.props.user?.team_type !== "workspace" ? (
          <Animation
            type={"fadeInUp"}
            duration={250}
            style={{
              position: "absolute",
              top: -50,
              height: 90,
              width: this.state.width - 2,
              left: 0,
              right: 0,
              margin: "auto",
              backgroundColor: colors.dark_phone_inner_color,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: colors.dark_phone_inner_color,
              borderRadius: 50,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              justifyContent: "flex-start"
            }}
          >
            <Wrapper
              style={{
                paddingRight: 25,
                paddingLeft: 25,
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Row>
                <IconButton
                  button_type={"small"}
                  noPress={true}
                  icon={"all-inclusive"}
                  icon_color={colors.orange_color}
                />
                <Wrapper style={{ flex: 1 }}>
                  <Copy
                    style={{
                      color: colors.orange_color,
                      fontSize: 12
                    }}
                  >
                    <Bold>Tired of paying by the minute?</Bold>
                  </Copy>
                  <Copy
                    style={{ fontSize: 10, color: colors.white_text_color }}
                  >
                    Buy a Dialer License and get unlimited minutes.
                  </Copy>
                </Wrapper>
                <Row>
                  <InlineButton
                    icon={"attach-money"}
                    onPress={() => {
                      this.props.pushSidePanel({
                        slug: "buy_dialer_license",
                        overlay: true
                      });
                    }}
                    primary={true}
                    hover_color={colors.hover_white_color}
                  >
                    Purchase
                  </InlineButton>
                  <IconButton
                    button_type={"small"}
                    onPress={() => {
                      this.props.hidePurchaseUpsell();
                    }}
                    icon={"close"}
                    tooltip={"Dismiss"}
                    tooltipPlacement={"top"}
                    icon_color={colors.white_text_color}
                    hover_color={colors.hover_white_color}
                  />
                </Row>
              </Row>
            </Wrapper>
          </Animation>
        ) : null}
        <Wrapper style={{ position: "relative", overflow: "hidden" }}>
          <Animation
            style={{ overflow: "hidden" }}
            type={
              this.props.newNumberAnimation === "exit"
                ? "fadeOutLeft"
                : this.props.newNumberAnimation === "enter"
                ? "fadeInRight"
                : null
            }
            duration={250}
          >
            <Wrapper
              style={{
                width: this.state.width,
                height: this.state.height,
                backgroundColor: colors.dark_phone_color,
                borderRadius: 50
              }}
            >
              <canvas
                ref={this.canvasRef}
                width={this.state.width - 70}
                style={{ marginLeft: 70 }}
                height={this.state.height}
              ></canvas>
            </Wrapper>

            <Wrapper
              style={{
                position: "absolute",
                top: 0,

                right: 0,
                left: 0,
                bottom: 0
              }}
            >
              <MainButtons
                start_call_loading={this.props.start_call_loading}
                call_time={this.props.call_time}
                incoming_call={this.props.incoming_call}
                handleHangup={this.props.handleHangup}
                startCall={this.props.startCall}
                closeSession={this.props.closeSession}
                call={this.props.call}
                call_start_time={this.props.call_start_time}
                isTimerActive={this.props.isTimerActive}
                height={this.state.height}
                getAudioDevices={this.props.getAudioDevices}
                selectNewAudioInputDevice={this.props.selectNewAudioInputDevice}
                selectNewAudioOutputDevice={
                  this.props.selectNewAudioOutputDevice
                }
                audio_input_devices={this.props.audio_input_devices}
                audio_output_devices={this.props.audio_output_devices}
                selected_input_device={this.props.selected_input_device}
                selected_output_device={this.props.selected_output_device}
                acceptIncomingCall={this.props.acceptIncomingCall}
                countdown={this.props.countdown}
                clearCountdown={this.props.clearCountdown}
                bypassCountdown={this.props.bypassCountdown}
                call_canceled={this.props.call_canceled}
                newNumberAnimation={this.props.newNumberAnimation}
                hideMore={this.hideMore}
                muteCall={this.props.muteCall}
                mute={this.props.mute}
                onboarding={this.props.onboarding}
                demo_call={this.props.demo_call}
              />
            </Wrapper>

            {(this.state.show_more ||
              isMobilePhoneCheck() ||
              isTabletCheck()) &&
            !this.props.onboarding ? (
              <Wrapper
                className={"dm-dialer-dragger"}
                style={{
                  position: "absolute",
                  top: 0,
                  height: 15,
                  width: 150,
                  right: 0,
                  left: 0,
                  margin: "auto",
                  backgroundColor: "transparent",
                  justifyContent: "center",
                  textAlign: "center",
                  opacity: 0.5,
                  background: "rgba(255, 255, 255, 0.1)",
                  borderBottomLeftRadius: 30,
                  borderBottomRightRadius: 30
                }}
              >
                <Icon
                  icon={"drag-handle"}
                  size={24}
                  color={colors.white_text_color}
                />
              </Wrapper>
            ) : null}

            {this.state.mic_permissions !== "granted" ? (
              <>
                <Wrapper
                  style={{
                    position: "absolute",
                    top: 0,

                    right: 0,
                    left: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0,0,0,0.4)",
                    borderRadius: 50,
                    overflow: "hidden"
                  }}
                >
                  <Blur
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0
                    }}
                  ></Blur>
                  <Wrapper
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    {this.state.mic_permissions === "prompt" ? (
                      <>
                        <Row style={{ justifyContent: "center" }}>
                          <Icon
                            icon={"mic"}
                            color={colors.orange_color}
                            style={{ marginRight: 5 }}
                            size={18}
                          />
                          <Copy
                            style={{
                              color: colors.orange_color,
                              textAlign: "center"
                            }}
                          >
                            <Bold>Allow mic access</Bold>
                          </Copy>
                        </Row>

                        <Copy
                          style={{
                            color: colors.white_text_color,
                            textAlign: "center",
                            fontSize: 12
                          }}
                        >
                          Click Allow to use your microphone here.
                        </Copy>
                      </>
                    ) : (
                      <>
                        <Row style={{ justifyContent: "center" }}>
                          <Icon
                            icon={"mic"}
                            color={colors.orange_color}
                            style={{ marginRight: 5 }}
                            size={18}
                          />
                          <Copy
                            style={{
                              color: colors.orange_color,
                              textAlign: "center"
                            }}
                          >
                            <Bold>Allow mic access</Bold>
                          </Copy>
                        </Row>
                        <Copy
                          style={{
                            textAlign: "center",
                            color: colors.white_text_color,
                            fontSize: 12
                          }}
                        >
                          Click Allow to use your microphone here.
                        </Copy>
                        <Copy
                          style={{
                            textAlign: "center",
                            color: colors.white_text_color,
                            fontSize: 12
                          }}
                        >
                          In your browser settings, allow Voice to access your
                          microphone
                        </Copy>
                      </>
                    )}
                  </Wrapper>
                </Wrapper>

                <Wrapper
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 10,

                    bottom: 0,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <IconButton
                    onPress={this.props.closeSession}
                    icon={"close"}
                    tooltip={"Close"}
                    tooltipPlacement={"top"}
                    icon_color={colors.white_text_color}
                    hover_color={colors.hover_white_color}
                  />
                </Wrapper>
              </>
            ) : null}

            <CurrentConversation
              telnyx_client={this.props.telnyx_client}
              show_more={this.state.show_more}
              show_more_height={this.state.show_more_height}
              closeSession={this.props.closeSession}
              completeSession={this.props.completeSession}
              complete_session_loading={this.props.complete_session_loading}
              handleHangup={this.props.handleHangup}
              getAudioDevices={this.props.getAudioDevices}
              selectNewAudioInputDevice={this.props.selectNewAudioInputDevice}
              selectNewAudioOutputDevice={this.props.selectNewAudioOutputDevice}
              audio_input_devices={this.props.audio_input_devices}
              audio_output_devices={this.props.audio_output_devices}
              selected_input_device={this.props.selected_input_device}
              selected_output_device={this.props.selected_output_device}
              countdown={this.props.countdown}
              clearCountdown={this.props.clearCountdown}
              call_canceled={this.props.call_canceled}
              hideMore={this.hideMore}
              resetTelnyxDevice={this.props.resetTelnyxDevice}
              onboarding={this.props.onboarding}
              demo_call={this.props.demo_call}
            />
          </Animation>
        </Wrapper>

        {this.state.show_more || isMobilePhoneCheck() || isTabletCheck() ? (
          <Wrapper
            className={"dm-dialer-dragger"}
            style={{
              position: "absolute",
              bottom: 0,
              height: 10,
              width: "100%",
              right: 0,
              left: 0,
              margin: "auto",
              backgroundColor: "transparent",
              justifyContent: "center",
              textAlign: "center"
            }}
          ></Wrapper>
        ) : null}

        {!this.props.onboarding ? (
          <Wrapper
            style={{
              position: "absolute",
              bottom: -8,

              height: 20,
              width: 80,
              left: 0,
              right: 0,
              margin: "auto",
              backgroundColor: colors.dark_phone_inner_color,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: colors.dark_phone_color,
              borderRadius: 30,
              justifyContent: "center"
            }}
          >
            <IconButton
              icon_color={colors.white_text_color}
              disabled={this.state.mic_permissions !== "granted"}
              onPress={() => {
                this.setState({
                  show_more: !this.state.show_more,
                  y: !this.state.show_more
                    ? this.state.y - this.state.show_more_height
                    : this.state.y + this.state.show_more_height,
                  outer_height: this.determineHeight(this.state.show_more)
                });
              }}
              tooltip={this.state.show_more ? "Show Less" : "Show More"}
              tooltipPlacement={"top"}
              icon={
                this.state.show_more
                  ? "keyboard-arrow-up"
                  : "keyboard-arrow-down"
              }
              button_type={"full"}
              style={{
                height: 20,
                width: 80,
                borderRadius: 30,
                margin: 0
              }}
              hover_color={colors.hover_white_color}
            />
          </Wrapper>
        ) : null}
      </Wrapper>
    );
  }

  render() {
    const { window_width, window_height } = this.props;

    if (this.props.onboarding) {
      return (
        <Wrapper
          style={{
            position: "relative",
            width: this.state.width,
            height: this.state.outer_height
          }}
        >
          {this.renderInner()}
        </Wrapper>
      );
    }

    return (
      <Draggable
        style={{ zIndex: 1 }}
        container_width={window_width}
        dragHandleClassName={
          this.state.show_more || isMobilePhoneCheck() || isTabletCheck()
            ? "dm-dialer-dragger"
            : null
        }
        container_height={window_height}
        enableResizing={false}
        element={{
          x: this.state.x,
          y: this.state.y,
          width: this.state.width,
          height: this.state.outer_height
        }}
        onDrag={({ x, y }) => {
          this.setState({ x, y });
        }}
      >
        <Animation type={"fadeInUp"} duration={150}>
          {this.renderInner()}
        </Animation>
      </Draggable>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, dialer, billing }) => {
  const { token, user } = auth;

  const { dark_mode, colors } = settings;
  const { window_width, window_height, device, desktopMobile } = native;
  const { source_of_truth } = billing;
  const {
    active_dialer_contact,
    active_call,
    current_conversation,
    current_call_session,
    queued_numbers,
    called_numbers
  } = dialer;
  return {
    token,
    user,
    dark_mode,
    colors,
    window_width,
    window_height,
    active_dialer_contact,
    active_call,
    current_conversation,
    current_call_session,
    queued_numbers,
    called_numbers,
    source_of_truth,
    device,
    desktopMobile
  };
};

export default connect(mapStateToProps, {
  pushSidePanel
})(DialerComponents);
