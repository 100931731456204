import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { KeyboardView, Wrapper, Row, Copy } from "app/NativeComponents/common";
import {
  InputBox,
  SearchBar,
  AttentionBox,
  InlineButton,
  NewHeader,
  GhostButton,
  BottomNavBar
} from "app/NativeComponents/snippets";
import {
  changeTab,
  tabNavigation,
  addListToListTabs,
  editBulkUploadInfo,
  setUploadListHeaders,
  popAllSidePanels,
  popSidePanel,
  addProgressBar,
  validateUploadMappings
} from "app/NativeActions";

import MappingObject from "./MappingObject";
import MappingRequirements from "./MappingRequirements";

const IGNORE_VALUE = "IGNORE";

const MAPPING_VALUES_TO_VALIDATE = [
  "FULL_ADDRESS",
  "ADDRESS",
  "ZIP",
  "CITY",
  "STATE",
  "COUNTY",
  "PARCEL_NUMBER",
  "PROPERTY_ID",
  "LATITUDE",
  "LONGITUDE"
];

const validationExamples = {
  FULL_ADDRESS: "12345 Sample Ct, Fort Worth, TX 76126",
  ADDRESS: "12345 Sample Ct",
  ZIP: "76126",
  CITY: "Fort Worth",
  STATE: "TX",
  COUNTY: "Tarrant County",
  PARCEL_NUMBER: "03658937",
  PROPERTY_ID: "2136090699",
  LATITUDE: "32.7880255",
  LONGITUDE: "-97.2917369"
};

class UploadMapping extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.getMappingState();
  }

  getMappingState() {
    let header_edited_map = [];
    let formatted_custom_fields = [];
    if (this.props.upload_list_info) {
      if (this.props.upload_list_info.header) {
        for (let i = 0; i < this.props.upload_list_info.header.length; i++) {
          header_edited_map.push({
            header_title: this.props.upload_list_info.header[i].name,
            label: this.props.upload_list_info.header[i].name,
            header_samples: this.props.upload_list_info.header[i].samples,
            header_index: i,
            header_mapped_value:
              this.props.upload_list_info.header[i].header_mapped_value || "",
            value: "",
            validationErrors:
              this.props.upload_list_info.header[i]
                .number_of_column_validation_errors,
            totalValidatedRows:
              this.props.upload_list_info.header[i].header_validation_samples
                .length,
            header_loading: false
          });
        }
      }
      if (this.props.upload_list_info?.custom_fields) {
        for (
          let i = 0;
          i < this.props.upload_list_info.custom_fields.length;
          i++
        ) {
          formatted_custom_fields.push({
            label: this.props.upload_list_info.custom_fields[i].title,
            value: this.props.upload_list_info.custom_fields[i].slug
          });
        }
      }
    }

    return {
      new_list_name: !!this.props.upload_list_info?.list_title
        ? this.props.upload_list_info?.list_title
        : "Imported List",
      search: "",
      loading: false,
      header_edited_map: header_edited_map,
      fields_are_selected: {
        full_address_check: false,
        lat_lng_check: false,
        property_id_check: false,
        apn_check: false
      },
      header_options: [
        {
          label: "N/A",
          value: ""
        },
        {
          label: "Skip Column",
          value: IGNORE_VALUE
        },
        {
          label: "Full Address",
          value: "FULL_ADDRESS"
        },
        {
          label: "Address Line 1",
          value: "ADDRESS"
        },
        {
          label: "Address Line 2",
          value: "ADDRESS_2"
        },
        {
          label: "Address Number",
          value: "ADDRESS_RANGE"
        },
        {
          label: "Address Street",
          value: "ADDRESS_STREET"
        },
        {
          label: "City",
          value: "CITY"
        },
        {
          label: "State",
          value: "STATE"
        },
        {
          label: "Zipcode",
          value: "ZIP"
        },
        { label: "County", value: "COUNTY" },
        {
          label: "APN (Parcel ID)",
          value: "PARCEL_NUMBER"
        },
        { label: "Property ID", value: "PROPERTY_ID" },
        {
          label: "Latitude",
          value: "LATITUDE"
        },
        {
          label: "Longitude",
          value: "LONGITUDE"
        },
        ...formatted_custom_fields
      ]
    };
  }

  componentDidMount() {
    this.updateFieldSelectionStatus({ onMount: true });
  }

  onCloseError = title => {
    this.setState({
      header_edited_map: this.state.header_edited_map.map(x => {
        if (x.header_title == title) {
          return { ...x, validationErrors: 0 };
        } else {
          return x;
        }
      })
    });
  };

  updateFieldSelectionStatus(options = {}) {
    const fields_are_selected = {
      full_address_check: this.checkIfAllPropertyAddressFieldsAreSelected(),
      apn_check: this.checkIfAllPropertyParcelFieldsAreSelected(),
      property_id_check: this.checkIfPropertyIdFieldIsSelected(),
      lat_lng_check: this.checkIfAllLatLngFieldsAreSelected()
    };
    let ai_mapping_success = false;
    if (options?.onMount) {
      ai_mapping_success = Object.values(fields_are_selected).some(
        x => x == true
      );
    }
    this.setState({
      fields_are_selected,
      ai_mapping_success
    });
  }

  checkIfAllPropertyAddressFieldsAreSelected() {
    let address_check = false;
    let address_street_check = false;
    let address_range_check = false;

    let city_check = false;
    let state_check = false;
    let zip_check = false;

    for (let i = 0; i < this.state.header_edited_map.length; i++) {
      switch (this.state.header_edited_map[i].header_mapped_value) {
        case "FULL_ADDRESS":
          return true;

        case "ADDRESS":
          address_check = true;
          break;

        case "ADDRESS_RANGE":
          address_range_check = true;
          break;

        case "ADDRESS_STREET":
          address_street_check = true;
          break;

        case "CITY":
          city_check = true;
          break;

        case "STATE":
          state_check = true;
          break;

        case "ZIP":
          zip_check = true;
          break;

        default:
          break;
      }
    }

    if (address_street_check && address_range_check) {
      address_check = true;
    }

    return address_check && city_check && state_check && zip_check;
  }

  checkIfAllPropertyParcelFieldsAreSelected() {
    let parcel_check = false;
    let county_check = false;
    let state_check = false;
    let zip_check = false;

    for (let i = 0; i < this.state.header_edited_map.length; i++) {
      switch (this.state.header_edited_map[i].header_mapped_value) {
        case "PARCEL_NUMBER":
          parcel_check = true;
          break;
        case "STATE":
          state_check = true;
          break;
        case "COUNTY":
          county_check = true;
          break;
        case "ZIP":
          zip_check = true;
          break;
        default:
          break;
      }
    }

    // Return true if any of the following conditions are met
    return (
      (parcel_check && county_check && state_check) || // Condition 1
      (parcel_check && zip_check) || // Condition 2
      (parcel_check && county_check && state_check && zip_check) // Condition 3
    );
  }

  checkIfPropertyIdFieldIsSelected() {
    let property_id_check = false;

    for (let i = 0; i < this.state.header_edited_map.length; i++) {
      switch (this.state.header_edited_map[i].header_mapped_value) {
        case "PROPERTY_ID":
          property_id_check = true;
          break;

        default:
          break;
      }
    }

    return property_id_check;
  }

  checkIfAllLatLngFieldsAreSelected() {
    let lat_check = false;
    let lng_check = false;

    for (let i = 0; i < this.state.header_edited_map.length; i++) {
      switch (this.state.header_edited_map[i].header_mapped_value) {
        case "LATITUDE":
          lat_check = true;
          break;

        case "LONGITUDE":
          lng_check = true;
          break;

        default:
          break;
      }
    }

    return lat_check && lng_check;
  }

  resetHeaderOptions() {
    this.setState(prevState => ({
      header_edited_map: prevState.header_edited_map.map(header => ({
        ...header,
        header_mapped_value: "", // Clear all mapped values in header_edited_map
        value: "", // Clear any additional value field
        validationErrors: 0 // Clear any validation errors
      }))
    }));
  }

  isMappingCleared() {
    return this.state.header_edited_map.every(
      header =>
        !header.header_mapped_value ||
        header.header_mapped_value === "IGNORE" ||
        header.header_mapped_value === ""
    );
  }

  setHeaderOption(header_index, value) {
    if (MAPPING_VALUES_TO_VALIDATE.includes(value)) {
      //set header loading
      this.setState({
        header_edited_map: this.state.header_edited_map.map((header, i) => {
          if (i === header_index) {
            return {
              ...header,
              header_loading: true
            };
          } else {
            return header;
          }
        })
      });
      const headerToValidate = this.props.upload_list_info.header[header_index];
      ///revalidate the header
      this.props.validateUploadMappings({
        token: this.props.token,
        validationSamples: headerToValidate?.header_validation_samples,
        headerMapping: value,
        onLoading: () => {
          this.setState({
            header_edited_map: this.state.header_edited_map.map((header, i) => {
              if (i === header_index) {
                return {
                  ...header,
                  header_loading: true
                };
              } else {
                return header;
              }
            })
          });
        },
        onError: () => {
          this.setState({
            header_edited_map: this.state.header_edited_map.map((header, i) => {
              if (i === header_index) {
                return {
                  ...header,
                  header_loading: false
                };
              } else {
                return header;
              }
            })
          });
        },
        onSuccess: results => {
          this.setState({
            header_edited_map: this.state.header_edited_map.map((header, i) => {
              if (i === header_index) {
                return {
                  ...header,
                  header_mapped_value: value,
                  header_loading: false,
                  validationErrors: results.number_of_column_validation_errors,
                  totalValidatedRows: results.header_validation_samples.length
                };
              } else {
                return header;
              }
            })
          });
        }
      });

      //set the header options as normal
    } else {
      this.setState({
        header_edited_map: this.state.header_edited_map.map((header, i) => {
          if (i === header_index) {
            return {
              ...header,
              value,
              header_mapped_value: value,
              validationErrors: 0
            };
          }
          if (
            value === header.header_mapped_value &&
            value !== "" &&
            value !== IGNORE_VALUE &&
            (header.header_mapped_value === "FULL_ADDRESS" ||
              header.header_mapped_value === "ADDRESS" ||
              header.header_mapped_value === "ADDRESS_2" ||
              header.header_mapped_value === "ADDRESS_RANGE" ||
              header.header_mapped_value === "ADDRESS_STREET" ||
              header.header_mapped_value === "CITY" ||
              header.header_mapped_value === "STATE" ||
              header.header_mapped_value === "ZIP")
          ) {
            return {
              ...header,
              header_mapped_value: IGNORE_VALUE
            };
          }

          if (
            value === "FULL_ADDRESS" &&
            (header.header_mapped_value === "FULL_ADDRESS" ||
              header.header_mapped_value === "ADDRESS" ||
              header.header_mapped_value === "ADDRESS_2" ||
              header.header_mapped_value === "ADDRESS_RANGE" ||
              header.header_mapped_value === "ADDRESS_STREET" ||
              header.header_mapped_value === "CITY" ||
              header.header_mapped_value === "STATE" ||
              header.header_mapped_value === "ZIP")
          ) {
            return {
              ...header,
              header_mapped_value: IGNORE_VALUE
            };
          }

          if (
            value === "ADDRESS" &&
            (header.header_mapped_value === "ADDRESS_RANGE" ||
              header.header_mapped_value === "ADDRESS_STREET")
          ) {
            return {
              ...header,
              header_mapped_value: IGNORE_VALUE
            };
          }
          if (
            value === "PARCEL_NUMBER" &&
            (header.header_mapped_value === "PARCEL_NUMBER" ||
              header.header_mapped_value === "COUNTY")
          ) {
            return {
              ...header,
              header_mapped_value: IGNORE_VALUE
            };
          }
          if (
            value === "PROPERTY_ID" &&
            header.header_mapped_value === "PROPERTY_ID"
          ) {
            return {
              ...header,
              header_mapped_value: IGNORE_VALUE
            };
          }
          if (
            header.header_mapped_value === "LONGITUDE" &&
            header.header_mapped_value === "LATITUDE"
          ) {
            return {
              ...header,
              header_mapped_value: IGNORE_VALUE
            };
          }

          if (
            header.header_mapped_value === "ADDRESS" &&
            (value === "ADDRESS_RANGE" || value === "ADDRESS_STREET")
          ) {
            return {
              ...header,
              header_mapped_value: IGNORE_VALUE
            };
          }

          return header;
        })
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.upload_list_info &&
      this.props.upload_list_info !== prevProps.upload_list_info
    ) {
      this.setState(this.getMappingState());
    }

    if (prevState.header_edited_map !== this.state.header_edited_map) {
      this.updateFieldSelectionStatus();
      if (
        (this.checkIfAllPropertyAddressFieldsAreSelected() ||
          this.checkIfAllPropertyParcelFieldsAreSelected() ||
          this.checkIfPropertyIdFieldIsSelected() ||
          this.checkIfAllLatLngFieldsAreSelected()) &&
        !this.state.address_fields_are_selected
      ) {
        this.setState({
          address_fields_are_selected: true
        });

        this.setState({
          header_edited_map: this.state.header_edited_map.map((header, i) => {
            if (
              header.header_mapped_value === "" ||
              header.header_mapped_value === "N/A" ||
              header.header_mapped_value === null
            ) {
              return {
                ...header,
                header_mapped_value: IGNORE_VALUE
              };
            }
            return header;
          })
        });
      } else if (
        (!this.checkIfAllPropertyAddressFieldsAreSelected() ||
          !this.checkIfAllPropertyParcelFieldsAreSelected() ||
          !this.checkIfPropertyIdFieldIsSelected() ||
          !this.checkIfAllLatLngFieldsAreSelected()) &&
        this.state.address_fields_are_selected
      ) {
        this.setState({
          address_fields_are_selected: false
        });
      }
    }
  }

  submitList() {
    let mapping = [];
    for (let i = 0; i < this.state.header_edited_map.length; i++) {
      if (this.state.header_edited_map[i].header_mapped_value !== "") {
        mapping.push(this.state.header_edited_map[i].header_mapped_value);
      } else {
        mapping.push(IGNORE_VALUE);
      }
    }

    this.props.setUploadListHeaders({
      token: this.props.token,
      bulk_job_id: this.props.upload_list_info.id,
      new_list_name: this.state.new_list_name,
      mapping: JSON.stringify(mapping),
      options: JSON.stringify({
        new_list_name: this.state.new_list_name
      }),
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          loading: false
        });

        this.props.popSidePanel();

        //make this uploaded list the active one
        if (results.list && results.bulk_job_id) {
          this.props.addProgressBar({
            id: results?.bulk_job_id,
            type: "import",
            progress: results.completed_count,
            completed: results.completed,
            total_count: results.total_count,
            data: {
              list: results.list
            }
          });
        }
      }
    });
  }

  render() {
    return (
      <>
        <Row
          style={{
            overflow: "hidden",
            flex: 1
          }}
        >
          <Wrapper
            style={{
              width: 450,
              alignSelf: "stretch",
              borderRightWidth: 1,
              borderRightColor: this.props.colors.border_color,
              borderRightStyle: "solid"
            }}
          >
            <NewHeader
              title={"Import List"}
              subtitle={this.props.subtitle}
              leftButton={{
                icon: "close",
                onPress: () => {
                  this.props.popSidePanel();
                }
              }}
            />
            <KeyboardView style={{ flex: 1 }}>
              <InputBox
                input_ref={this._filter_name}
                autoFocus={false}
                name="filter_name"
                disabled={false}
                returnKeyType="done"
                placeholder={
                  !!this.state.new_list_name
                    ? "Imported list name"
                    : "Enter a name for this imported list"
                }
                onFocus={() => {}}
                onChange={value => {
                  this.setState({
                    new_list_name: value
                  });
                }}
                blurOnSubmit={true}
                value={this.state.new_list_name}
                input_type="text"
                loading={this.state.loading}
              />

              {!this.checkIfAllPropertyAddressFieldsAreSelected() ||
              !this.checkIfAllPropertyParcelFieldsAreSelected() ||
              !this.checkIfPropertyIdFieldIsSelected() ||
              !this.checkIfAllLatLngFieldsAreSelected() ||
              this.state.ai_mapping_success ? (
                <AttentionBox
                  title={
                    this.state.ai_mapping_success
                      ? "Our system has mapped some fields for you."
                      : "Map columns in your file to import properties."
                  }
                  description={
                    <>
                      {this.state.ai_mapping_success ? (
                        <Copy style={{ paddingBottom: 10 }}>
                          Please take a moment to check that the mapping is
                          correct.
                        </Copy>
                      ) : null}

                      <a
                        href="https://help.dealmachine.com/en/articles/6109824-how-do-i-upload-import-my-own-list-into-dealmachine"
                        target="_blank"
                        style={{
                          color: this.props.colors.actionable_text_color
                        }}
                      >
                        Learn how to import properties to DealMachine here.
                      </a>
                    </>
                  }
                />
              ) : null}
              <MappingRequirements
                colors={this.props.colors}
                fields_are_selected={this.state.fields_are_selected}
              />
              {!this.props.upload_list_info?.has_header_row ? (
                <AttentionBox
                  title={"It looks like you don't have a header row."}
                  description={
                    "For better results, please include a header row on the file you are attempting to import."
                  }
                />
              ) : null}
            </KeyboardView>
            <BottomNavBar>
              <GhostButton
                button_type="full"
                loading={this.state.loading}
                primary={true}
                style={{
                  marginRight: 20,
                  marginLeft: 20
                }}
                onPress={() => {
                  this.submitList();
                }}
                disabled={
                  !Object.values(this.state.fields_are_selected).some(
                    value => value
                  ) || !this.state.new_list_name
                }
              >
                Complete Import
              </GhostButton>
              <InlineButton
                button_type="full"
                onPress={() => {
                  this.resetHeaderOptions();
                }}
                disabled={this.isMappingCleared()}
              >
                Clear Mapping
              </InlineButton>
            </BottomNavBar>
          </Wrapper>
          <Wrapper style={{ flex: 1, alignSelf: "stretch" }}>
            <SearchBar
              title="Search columns in your file"
              value={this.state.search}
              onChange={value => {
                this.setState({
                  search: value
                });
              }}
              style={{ margin: 5 }}
            />
            <KeyboardView style={{ flex: 1 }}>
              {this.state.header_edited_map.map((header, i) => {
                if (
                  header.header_title
                    .toLowerCase()
                    .includes(this.state.search.toLowerCase())
                ) {
                  return (
                    <MappingObject
                      key={"upload_" + i}
                      header_item={header}
                      header_index={i}
                      header_options={this.state.header_options}
                      setHeaderOption={this.setHeaderOption.bind(this)}
                      upload_list_info={this.props.upload_list_info}
                      colors={this.props.colors}
                      showError={header.validationErrors > 0}
                      totalValidatedRows={header.totalValidatedRows}
                      validationErrors={header.validationErrors}
                      onErrorClose={() =>
                        this.onCloseError(header.header_title)
                      }
                      validationExample={
                        validationExamples[header?.header_mapped_value]
                      }
                    />
                  );
                }
                return null;
              })}
            </KeyboardView>
          </Wrapper>
        </Row>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { token, user } = auth;
  const { dark_mode, colors } = settings;

  return {
    token,
    user,
    dark_mode,
    colors
  };
};

export default connect(mapStateToProps, {
  changeTab,
  tabNavigation,
  editBulkUploadInfo,
  setUploadListHeaders,
  validateUploadMappings,
  popAllSidePanels,
  popSidePanel,
  addListToListTabs,
  addProgressBar
})(UploadMapping);
