import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Wrapper,
  KeyboardView,
  Copy,
  Bold,
  InternalImage
} from "app/NativeComponents/common";
import {
  AttentionBox,
  GhostButton,
  SelectItem,
  BottomNavBar,
  InlineButton,
  IconButton,
  SmallHeader,
  SearchBar,
  PopoverMenu,
  InlineTabs,
  List,
  SpinWrapper,
  DateRangePicker,
  NewHeader,
  UpsellButton
} from "app/NativeComponents/snippets";

import {
  getTeam,
  getRoutes,
  pushSidePanel,
  selectActiveRoute,
  selectActiveProperty,
  selectActiveCoordinates,
  popSidePanel,
  renderDate,
  openUrl,
  numberWithCommas,
  AppConfig
} from "app/NativeActions";

class Routes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_routes_on_map: true,
      search: "",
      team_search: "",
      filter_user: "team_drives",
      sort_by: "date_created_desc",
      start_date: null,
      end_date: null,
      isCalendarOpen: false
    };

    this.getData = this.getData.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.toggleCalendar = this.toggleCalendar.bind(this);
  }

  componentDidMount() {
    if (!this.props.team_loading) {
      this.props.getTeam({ token: this.props.token, type: "load" });
    }

    this.getData("refresh");
  }

  getData(load_type = "load") {
    if (!this.props.route_loading && !this.props.route_refreshing) {
      this.props.getRoutes({
        token: this.props.token,
        type: load_type,
        begin: load_type == "load_more" ? this.props.route_begin : 0,
        filter_user: this.state.filter_user,
        sort_by: this.state.sort_by,
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        search: this.state.search
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.filter_user != prevState.filter_user ||
      this.state.sort_by != prevState.sort_by ||
      this.state.start_date != prevState.start_date ||
      this.state.end_date != prevState.end_date ||
      prevProps.track_route !== this.props.track_route
    ) {
      this.getData("refresh");
    }
  }

  componentWillUnmount() {
    clearInterval(this._search_interval);
  }

  updateSearch(search) {
    this.setState({ search });

    clearInterval(this._search_interval);
    this._search_interval = setTimeout(() => {
      this.getData("refresh");
    }, 250);
  }

  toggleCalendar() {
    this.setState(prevState => {
      return { isCalendarOpen: !prevState.isCalendarOpen };
    });
  }

  exportRoutesString() {
    const { filter_user, start_date, end_date } = this.state;

    var export_string = "";

    if (start_date == null && end_date == null) {
      export_string =
        "v2/" +
        "routes/?token=" +
        this.props.token +
        "&type=export&limit=500&filter_user=" +
        filter_user;
    } else {
      export_string =
        "v2/" +
        "routes/?token=" +
        this.props.token +
        "&type=export&limit=1000&filter_user=" +
        filter_user +
        "&start_date=" +
        start_date +
        "&end_date=" +
        end_date;
    }
    return export_string;
  }

  render() {
    const { all_leads_count, all_leads_count_loading } = this.state;
    const {
      colors,
      isMobile,
      device,
      active_route,
      track_route,
      current_route,
      track_route_type
    } = this.props;
    if (this.props.select_drive_start) {
      return (
        <Wrapper
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <InternalImage
            style={{
              height: 50
            }}
            resizeMode={"contain"}
            source={
              this.props.device == "mobile"
                ? require("app/assets/images/person_pin_orange.png")
                : null
            }
            image={
              this.props.device == "desktop"
                ? "/assets/images/person_pin_orange.png"
                : ""
            }
          />
          <Wrapper style={{ padding: 25 }}>
            <Copy style={{ textAlign: "center" }}>
              <Bold>Select an area on the map</Bold>
            </Copy>
            <Copy style={{ textAlign: "center" }}>
              Click an area on the map to choose where your drive will start.
            </Copy>
          </Wrapper>

          <InlineButton
            button_type="full"
            onPress={() => {
              this.props.toggleSelectDriveStart(false);
            }}
          >
            Cancel Virtual Drive
          </InlineButton>
        </Wrapper>
      );
    }

    return (
      <>
        <DateRangePicker
          {...this.props}
          isCalendarOpen={this.state.isCalendarOpen}
          toggleCalendar={this.toggleCalendar}
          selectDates={({ start_date, end_date }) => {
            this.setState({
              start_date,
              end_date
            });
          }}
        />

        <Row>
          <Wrapper style={{ flex: 1 }}>
            <Row>
              <PopoverMenu
                show={this.state.show_filters}
                no_swipe={true}
                no_cancel={true}
                onShow={s => {
                  this.setState({
                    show_filters: s
                  });
                }}
                popover_width={350}
                popoverSheetTop={"80%"}
                popoverPlacement={"bottom"}
                popover_title={"Filter Drives"}
                includeCloseButton={true}
                renderComponent={options => {
                  return (
                    <InlineButton
                      icon={"tune"}
                      noPress={true}
                      pressedIn={
                        options.hovering ||
                        options.pressedIn ||
                        this.state.show_filters
                      }
                    >
                      Filter
                    </InlineButton>
                  );
                }}
                renderMenu={() => {
                  return (
                    <Wrapper style={{ alignSelf: "stretch" }}>
                      {/*<Wrapper
                    style={{
                      margin: 10,
                      marginBottom: 0,
                      alignSelf: "stretch",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <InlineTabs
                      selected_tab={this.state.filter_by}
                      tabs={[
                        {
                          onPress: () => {
                            this.setState({
                              filter_by: "Active Tasks"
                            });
                          },
                          title: "Active Tasks",
                          slug: "Active Tasks"
                        },
                        {
                          onPress: () => {
                            this.setState({
                              filter_by: "Completed Tasks"
                            });
                          },
                          title: "Completed Tasks",
                          slug: "Completed Tasks"
                        }
                      ]}
                    />
                  </Wrapper>*/}
                      <SearchBar
                        title={"Search Team"}
                        onChange={value => {
                          this.setState({
                            team_search: value
                          });
                        }}
                        value={this.state.team_search}
                        style={{
                          margin: 10
                        }}
                      />
                      <KeyboardView
                        style={
                          this.props.device == "desktop"
                            ? {
                                maxHeight: 300,
                                alignSelf: "stretch"
                              }
                            : {
                                alignSelf: "stretch"
                              }
                        }
                      >
                        {!this.state.team_search ||
                        "My Drives"
                          .toLowerCase()
                          .includes(this.state.team_search.toLowerCase()) ? (
                          <SelectItem
                            select_type="radio"
                            selected={
                              this.state.filter_user == this.props.user.id
                            }
                            onPress={() => {
                              this.setState({
                                filter_user: this.props.user.id,
                                show_filters: false
                              });
                            }}
                          >
                            My Drives
                          </SelectItem>
                        ) : null}
                        {!this.state.team_search ||
                        "All Team Drives"
                          .toLowerCase()
                          .includes(this.state.team_search.toLowerCase()) ? (
                          <SelectItem
                            select_type="radio"
                            selected={this.state.filter_user == "team_drives"}
                            onPress={() => {
                              this.setState({
                                filter_user: "team_drives",
                                show_filters: false
                              });
                            }}
                          >
                            All Team Drives
                          </SelectItem>
                        ) : null}

                        {this.props.my_team &&
                          this.props.my_team.map((team_member, i) => {
                            const name =
                              team_member.firstname && team_member.lastname
                                ? team_member.firstname +
                                  " " +
                                  team_member.lastname
                                : team_member?.email;
                            if (
                              team_member.id != this.props.user.id &&
                              (!this.state.team_search ||
                                name
                                  .toLowerCase()
                                  .includes(
                                    this.state.team_search.toLowerCase()
                                  ))
                            ) {
                              return (
                                <SelectItem
                                  select_type="radio"
                                  selected={
                                    this.state.filter_user == team_member.id
                                  }
                                  onPress={() => {
                                    this.setState({
                                      filter_user: team_member.id,
                                      show_filters: false
                                    });
                                  }}
                                >
                                  {name}
                                </SelectItem>
                              );
                            }

                            return null;
                          })}
                      </KeyboardView>
                    </Wrapper>
                  );
                }}
                menu_items={null}
              />
              <PopoverMenu
                popover_width={350}
                renderComponent={({ pressedIn, hovering }) => {
                  return (
                    <InlineButton
                      onPress={() => {}}
                      noPress={true}
                      style={{ marginLeft: 0 }}
                      pressedIn={pressedIn || hovering}
                    >
                      Sort
                    </InlineButton>
                  );
                }}
                menu_items={[
                  {
                    title: "Latest Drive",
                    select_type: "radio",
                    selected: this.state.sort_by == "date_created_desc",
                    onPress: () => {
                      this.setState({
                        sort_by: "date_created_desc"
                      });
                    }
                  },
                  {
                    title: "Earliest Drive",
                    select_type: "radio",
                    selected: this.state.sort_by == "date_created_asc",
                    onPress: () => {
                      this.setState({
                        sort_by: "date_created_asc"
                      });
                    }
                  }
                ]}
              />
              <InlineButton
                onPress={() => {this.toggleCalendar();}}
                style={{ marginLeft: 0 }}
              >
                Date
              </InlineButton>
            </Row>
          </Wrapper>

          <PopoverMenu
            popover_width={350}
            renderComponent={({ pressedIn, hovering }) => {
              return (
                <IconButton
                  onPress={() => {}}
                  noPress={true}
                  style={{ marginLeft: 0 }}
                  pressedIn={pressedIn || hovering}
                  icon="more-vert"
                />
              );
            }}
            menu_items={[
              {
                title: "Export Drives",
                select_type: "none",
                onPress: () => {
                  openUrl(AppConfig().api_url + this.exportRoutesString());
                }
              }
            ]}
          />
        </Row>

        {(this.props.route_loading && this.props.routes.length == 0) ||
        this.props.route_refreshing ? (
          <SpinWrapper text="Loading Past Drives..." />
        ) : (
          <>
            <List
              rowNumber={1}
              style={{ flex: 1 }}
              items={this.props.routes}
              infiniteScroll={true}
              itemStructure={({ item, index }) => {
                let title = "";
                if (!!item.title) {
                  title = item.title;
                } /*else if (item.user_id == this.props.user?.id) {
                title = "Drive";
              } */ else {
                  title =
                    item.user_firstname && item.user_lastname
                      ? "Drive by " +
                        item.user_firstname +
                        " " +
                        item.user_lastname
                      : "Drive by " + item.user_email;
                }

                const description =
                  item.properties_added +
                  " leads added - " +
                  item.total_miles +
                  " miles driven - " +
                  item.total_time.toLowerCase();
                const selected =
                  active_route?.route_id == item.route_id &&
                  active_route?.route_type == item.route_type;
                return (
                  <SelectItem
                    select_type="none"
                    //select_icon="route"
                    hover_icon={selected ? "" : "launch"}
                    hover_icon_color={colors.orange_color}
                    key={"route_" + index}
                    description={description}
                    selected={selected}
                    onPress={() => {
                      this.props.selectActiveCoordinates(null);
                      this.props.selectActiveProperty(this.props.token, null);
                      this.props.selectActiveRoute(item);

                      if (isMobile && this.props.device == "mobile") {
                        if (this.props.closeRoutesPopover) {
                          this.props.closeRoutesPopover();
                        }
                      } else {
                        this.props.pushSidePanel({
                          slug: "view_route"
                        });
                      }
                    }}
                    subtext={renderDate(item.start_time, true)}
                  >
                    {title}
                  </SelectItem>
                );
              }}
              canRefresh={true}
              onRefresh={() => {
                this.getData("refresh");
              }}
              is_refreshing={this.props.route_refreshing}
              canLoadMore={
                !this.props.route_loaded_all &&
                !this.props.route_loading &&
                !this.props.route_refreshing &&
                this.props.routes.length > 0
              }
              isLoadingMore={
                this.props.route_loading &&
                !this.props.route_refreshing &&
                this.props.routes.length > 0
              }
              onLoadMore={() => {
                if (
                  !this.props.route_loaded_all &&
                  this.props.route_refreshing != true &&
                  this.props.route_loading != true
                ) {
                  this.getData("load_more");
                }
              }}
            />
            <BottomNavBar>
              <Row style={{ justifyContent: "space-between" }}>
                <Wrapper style={{ flex: 1, padding: 15 }}>
                  <Copy style={{ fontSize: 12 }}>Total Hours:</Copy>
                  <Copy style={{}}>
                    <Bold>{this.props.route_totals?.total_time || "--"}</Bold>
                  </Copy>
                </Wrapper>
                <Wrapper style={{ flex: 1, padding: 15 }}>
                  <Copy style={{ fontSize: 12 }}>Total Miles:</Copy>
                  <Copy style={{}}>
                    <Bold>
                      {numberWithCommas(
                        parseInt(this.props.route_totals?.total_miles)
                      ) || "--"}
                    </Bold>
                  </Copy>
                </Wrapper>
                <Wrapper style={{ flex: 1, padding: 15 }}>
                  <Copy style={{ fontSize: 12 }}>Total Leads:</Copy>
                  <Copy style={{}}>
                    <Bold>
                      {numberWithCommas(
                        this.props.route_totals?.total_properties_added
                      ) || "--"}
                    </Bold>
                  </Copy>
                </Wrapper>
              </Row>
            </BottomNavBar>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = ({
  auth,
  native,
  settings,
  team,
  route,
  property_map
}) => {
  const { token, user } = auth;
  const { device, window_height, isMobile } = native;
  const { colors, dark_mode } = settings;

  const { team_members_loading, my_team } = team;

  const {
    routes,
    route_loading,
    route_error,
    route_refreshing,
    route_loaded_all,
    route_limit,
    route_begin,
    active_route,
    track_route,
    current_route,
    track_route_type,
    route_totals
  } = route;
  const { street_view_coords } = property_map;
  return {
    token,
    user,
    device,
    isMobile,
    window_height,
    colors,
    dark_mode,
    team_members_loading,
    my_team,
    track_route,
    current_route,
    track_route_type,
    routes,
    route_loading,
    route_error,
    route_refreshing,
    route_loaded_all,
    route_limit,
    route_begin,
    active_route,
    street_view_coords,
    route_totals
  };
};

export default connect(mapStateToProps, {
  getTeam,
  getRoutes,
  selectActiveRoute,
  selectActiveProperty,
  selectActiveCoordinates,
  popSidePanel,
  pushSidePanel
})(Routes);
