import {
  UPDATE_MARKETING_PARTNER_FROM_PROMO,
  GET_VISITOR,
  GET_VISITOR_ERROR,
  GET_VISITOR_SUCCESS,
  UPDATE_MARKETING_USER,
  UPDATE_MARKETING_PARTNER,
  TOGGLE_CHURN_BUSTER
} from "app/DealMachineCore/types";

import { getData, saveData } from "app/NativeActions";

import MarketingAPI from "app/DealMachineCore/MarketingOnboardingAndFunnel/apis/MarketingAPI";
import { store } from "app/store";
const dm_marketing_api = MarketingAPI.create();

const saveMarketingData = async ({ prop, value }) => {
  try {
    window.localStorage.setItem(prop, value);
  } catch (error) {}
};

export const updateMarketingUser = ({ prop, value }) => {
  //saveMarketingData({ prop, value });

  return {
    type: UPDATE_MARKETING_USER,
    payload: { prop, value }
  };
};

export const updateMarketingPartner = ({ prop, value }) => {
  //saveMarketingData({ prop, value });

  return {
    type: UPDATE_MARKETING_PARTNER,
    payload: { prop, value }
  };
};

export const logMarketingEvent = ({ slug, event_data = {} }) => {
  const token = store.getState().auth?.token;
  const device = store.getState().native?.device || "desktop";
  const platform =
    store.getState().native?.device == "desktop"
      ? store.getState().native?.desktopMobile
        ? "mobile_web"
        : "desktop"
      : store.getState().native?.platform;

  dm_marketing_api
    .logMarketingEvent({
      slug,
      token,
      device,
      platform,
      event_data: JSON.stringify(event_data)
    })
    .then(response => {});
};

export const logMarketingVisit = ({
  utm_campaign,
  utm_content,
  utm_medium,
  utm_source,
  utm_source_platform,
  utm_term,
  utm_referrer,
  referrer,
  visit_data = {},
  hubspot_cookie = null,
  google_analytics_cookie = null,
  first_promoter_cookie = null,
  app_version,
  experiments = null
}) => {
  return async dispatch => {
    dispatch({
      type: GET_VISITOR
    });
    const visitor_token = await getData("visitor_token");

    const device = store.getState().native?.device || "desktop";
    const platform =
      store.getState().native?.device == "desktop"
        ? store.getState().native?.desktopMobile
          ? "mobile_web"
          : "desktop"
        : store.getState().native?.platform;

    dm_marketing_api
      .logMarketingVisit({
        visitor_token,
        device,
        platform,
        utm_campaign,
        utm_content,
        utm_medium,
        utm_source,
        utm_source_platform,
        utm_term,
        utm_referrer,
        referrer,
        visit_data: JSON.stringify(visit_data),
        hubspot_cookie,
        google_analytics_cookie,
        first_promoter_cookie,
        app_version,
        experiments
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_VISITOR_ERROR
          });
        } else if (response.data.error != false) {
          dispatch({
            type: GET_VISITOR_ERROR
          });
        } else {
          if (!!response.data.results?.visitor?.visitor_token) {
            saveData({
              prop: "visitor_token",
              value: response.data.results?.visitor?.visitor_token
            });
          }

          dispatch({
            type: GET_VISITOR_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const submitMarketingForm = ({
  token,
  type,
  property_slug,
  property_value,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_marketing_api
      .submitMarketingForm({
        token,
        type,
        property_slug,
        property_value
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const getMobileRedirectSessionLink = ({
  token,
  type = "branch",
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_marketing_api
      .getMobileRedirectSessionLink({
        token,
        type
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const getMarketingOnboardingData = ({
  token,
  type,
  filter,
  location,
  firstname,
  lastname,
  phone,
  address,
  address2,
  city,
  state,
  zip,
  password,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_marketing_api
      .getMarketingOnboardingData({
        token,
        type,
        filter,
        location,
        firstname,
        lastname,
        phone,
        address,
        address2,
        city,
        state,
        zip,
        password
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const getPartnerFromPromo = promo => {
  return dispatch => {
    dm_marketing_api.getPartnerFromPromo({ promo }).then(response => {
      if (response.problem != null) {
      } else if (response.data.error != false) {
      } else if (response.data) {
        dispatch({
          type: UPDATE_MARKETING_PARTNER_FROM_PROMO,
          payload: response.data.results
        });
      }
    });
  };
};

export const toggleChurnBuster = toggle => {
  //saveMarketingData({ prop, value });

  return {
    type: TOGGLE_CHURN_BUSTER,
    payload: toggle
  };
};

export const getDataFromHubspot = ({
  type,
  hubspot_cookie,
  onLoading = () => {},
  onSuccess = () => {},
  onError = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_marketing_api
      .getDataFromHubspot({ type, hubspot_cookie })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
        } else if (response.data) {
          onSuccess(response.data?.results);
        }
      });
  };
};

export const getRoutesForLiveEvent = ({
  token,
  onLoading = () => {},
  onSuccess = () => {},
  onError = () => {}
}) => {
  return dispatch => {
    onLoading();
    dm_marketing_api
      .getRoutesForLiveEvent({ token, type: "routes" })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
        } else if (response.data) {
          onSuccess(response.data?.results);
        }
      });
  };
};
