import React, { Component } from "react";
import {
  Wrapper,
  CardBody,
  Title,
  Copy,
  InnerCopy,
  Bold,
  Row
} from "app/NativeComponents/common";
import {
  GhostButton,
  InformationItem,
  IconButton
} from "app/NativeComponents/snippets";
import moment from "moment";

class PropertyInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: ""
    };
  }

  updateHouseTab(tab) {
    this.setState({ tab: tab === this.state.tab ? "" : tab });
  }

  render() {
    const { property, search = "" } = this.props;

    if (!property?.id < 0) {
      return (
        <Wrapper style={this.props.style}>
          {!search ? (
            <Wrapper
              style={{
                padding: 25,
                paddingTop: 15,
                paddingBottom: 15
              }}
            >
              <Row>
                <Wrapper style={{ flex: 1 }}>
                  <Copy>
                    <Bold>County Records</Bold>

                    {!!this.props.property?.last_updated_from_data_provider ? (
                      <InnerCopy
                        style={{
                          fontSize: 10,
                          color: this.props.colors.light_text_color
                        }}
                      >
                        {"  Updated "}
                        {moment(
                          this.props.property?.last_updated_from_data_provider
                        ).format("MMM Do, YYYY")}
                      </InnerCopy>
                    ) : null}
                  </Copy>
                </Wrapper>
              </Row>
            </Wrapper>
          ) : null}
          <Wrapper>
            <InformationItem
              item={property.saledate}
              label={"Sale date (county records):"}
              format={"date"}
              search={search}
              property={property}
              slug="saledate"
              include_data_update_flag={true}
              propertyUpdated={this.props.propertyUpdated}
            />
            <InformationItem
              item={property.saleprice}
              label={"Sale price (county records):"}
              format={"money"}
              search={search}
              property={property}
              slug="saleprice"
              include_data_update_flag={true}
              propertyUpdated={this.props.propertyUpdated}
            />
            <InformationItem
              item={this.props.property.calculated_equity_percent}
              label={"Equity percent:"}
              format={"percent"}
              search={search}
              property={property}
              slug="equity_percent"
              include_data_update_flag={true}
              propertyUpdated={this.props.propertyUpdated}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={this.props.property.calculated_total_value}
              label={"Total Assessed Value:"}
              format={"money"}
              search={search}
              property={property}
              slug="calculated_total_value"
              include_data_update_flag={true}
              propertyUpdated={this.props.propertyUpdated}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={this.props.property.calculated_improvement_value}
              label={"Improvement Value:"}
              format={"money"}
              search={search}
              property={property}
              slug="calculated_improvement_value"
              include_data_update_flag={true}
              propertyUpdated={this.props.propertyUpdated}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={this.props.property.calculated_land_value}
              label={"Land Value:"}
              format={"money"}
              search={search}
              property={property}
              slug="calculated_land_value"
              include_data_update_flag={true}
              propertyUpdated={this.props.propertyUpdated}
              hiddenFromNonSubs={true}
            />

            <InformationItem
              item={this.props.property.mortgage_amount}
              label={"Mortgage amount:"}
              format={"money"}
              search={search}
              property={property}
              slug="mortgage_amount"
              include_data_update_flag={true}
              propertyUpdated={this.props.propertyUpdated}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={this.props.property.mortgage_term}
              label={"Mortgage term:"}
              format={"term"}
              search={search}
              property={property}
              slug="mortgage_term"
              include_data_update_flag={true}
              propertyUpdated={this.props.propertyUpdated}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={this.props.property.mortgage_financing_type}
              label={"Financing type:"}
              format={""}
              search={search}
              property={property}
              slug="mortgage_financing_type"
              include_data_update_flag={true}
              propertyUpdated={this.props.propertyUpdated}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={this.props.property.mortgage_loan_type}
              label={"Loan type:"}
              format={""}
              search={search}
              property={property}
              slug="mortgage_loan_type"
              include_data_update_flag={true}
              propertyUpdated={this.props.propertyUpdated}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={this.props.property.mortgage_interest_rate}
              label={"Interest rate (%):"}
              format={"interest_rate"}
              search={search}
              property={property}
              slug="mortgage_interest_rate"
              include_data_update_flag={true}
              propertyUpdated={this.props.propertyUpdated}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={this.props.property.lender_name}
              label={"Lender name:"}
              format={"company_name"}
              search={search}
              property={property}
              slug="lender_name"
              include_data_update_flag={true}
              propertyUpdated={this.props.propertyUpdated}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={this.props.property.second_mortgage_amount}
              label={"2nd mortgage amount:"}
              format={"money"}
              search={search}
              property={property}
              slug="second_mortgage_amount"
              include_data_update_flag={true}
              propertyUpdated={this.props.propertyUpdated}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={this.props.property.second_mortgage_financing_type}
              label={"2nd financing type:"}
              format={""}
              search={search}
              property={property}
              slug="second_mortgage_financing_type"
              include_data_update_flag={true}
              propertyUpdated={this.props.propertyUpdated}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={this.props.property.second_mortgage_loan_type}
              label={"2nd loan type:"}
              format={""}
              search={search}
              property={property}
              slug="second_mortgage_loan_type"
              include_data_update_flag={true}
              propertyUpdated={this.props.propertyUpdated}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={this.props.property.second_mortgage_interest_rate}
              label={"2nd interest rate (%):"}
              format={"interest_rate"}
              search={search}
              property={property}
              slug="second_mortgage_interest_rate"
              include_data_update_flag={true}
              propertyUpdated={this.props.propertyUpdated}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={this.props.property.year_built}
              label={"Year built:"}
              search={search}
            />
            <InformationItem
              item={this.props.property.total_bedrooms}
              label={"Bedrooms:"}
              format={"number"}
              search={search}
            />
            <InformationItem
              item={
                this.props.property.total_baths &&
                Number(this.props.property.total_baths).toFixed(1)
              }
              label={"Baths:"}
              format={"number"}
              search={search}
            />
            <InformationItem
              item={this.props.property.units_count}
              label={"Units count:"}
              format={"number"}
              search={search}
            />
            <InformationItem
              item={this.props.property.building_square_feet}
              label={"Square footage:"}
              format={"number"}
              search={search}
            />

            <InformationItem
              item={this.props.property.lot_acreage}
              label={"Acreage:"}
              format={"acre"}
              search={search}
            />
            <InformationItem
              item={this.props.property.construction_type}
              label={"Construction type:"}
              search={search}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={this.props.property.property_use}
              label={"Property Use Standardized:"}
              format={"text"}
              search={search}
              hiddenFromNonSubs={true}
            />

            <InformationItem
              item={this.props.property.apn_parcel_id}
              label={"APN (Parcel ID):"}
              format={"text"}
              search={search}
              hiddenFromNonSubs={true}
            />

            <InformationItem
              item={this.props.property.county_link}
              label={"County Link:"}
              format={"url"}
              search={search}
              hiddenFromNonSubs={true}
            />

            <InformationItem
              item={this.props.property.school_district_name}
              label={"School district:"}
              search={search}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={this.props.property.geographic_features_desc_csv}
              label={"Geographic features:"}
              search={search}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={this.props.property.flood_zone}
              label={"Flood zone:"}
              search={search}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={this.props.property.zoning}
              label={"Zoning:"}
              search={search}
              hiddenFromNonSubs={true}
            />
          </Wrapper>
        </Wrapper>
      );
    }
    return <Wrapper />;
  }
}

export default PropertyInfo;
