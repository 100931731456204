import moment from "moment";
import axios from "axios";
import * as turf from "@turf/turf";
if (typeof Intl === "undefined") {
  require("intl");
  require("intl/locale-data/jsonp/en");
}

export const numberWithCommas = x => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return 0;
};

export const checkIfTrust = ({ owner }) => {
  var word_array = ["trust"];
  var word_found = false;
  for (var i = 0; i < word_array.length; i++) {
    var og_word = word_array[i];
    var word = " " + og_word;
    const lower_owner = owner.owner_name.toLowerCase();
    if (lower_owner.endsWith(word)) {
      word_found = true;
    }
    word = " " + og_word + " ";
    if (lower_owner.indexOf(word) != -1) {
      word_found = true;
    }
    word = og_word + " ";
    if (lower_owner.startsWith(word)) {
      word_found = true;
    }
  }
  if (word_found == true) {
    return true;
  } else {
    return false;
  }
};

export const isLocationInUSA = (lat, long) => {
  const pt = turf.point([long, lat]);
  let usaShape = {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [-123.123779, 48.227039], // contig. u.s.
          [-123.318787, 49.000042],
          [-121.742592, 49.000267],
          [-95.157394, 49.000493],
          [-95.157394, 49.390418],
          [-94.795532, 49.357334],
          [-94.482422, 48.857487],
          [-88.36853, 48.314255],
          [-84.126389, 46.531937],
          [-81.331787, 45.344424],
          [-83.034668, 41.910453],
          [-79.013672, 42.867912],
          [-79.299316, 43.590338],
          [-77.305298, 43.761176],
          [-74.849854, 45.058001],
          [-71.586914, 45.1123],
          [-69.213867, 47.480088],
          [-67.758179, 47.271775],
          [-67.719727, 45.813486],
          [-66.780396, 44.785734],
          [-80.628662, 24.417142],
          [-97.058716, 25.730633],
          [-99.283447, 26.382028],
          [-101.480713, 29.678508],
          [-102.612305, 29.716681],
          [-103.117676, 28.88316],
          [-104.699707, 29.649869],
          [-106.44104, 31.737511],
          [-108.187866, 31.760867],
          [-108.193359, 31.325487],
          [-111.08551, 31.325487],
          [-114.930725, 32.521342],
          [-114.724731, 32.711044],
          [-124.892578, 31.952453],
          [-129.067383, 49.047486],
          [-123.123779, 48.227039]
        ]
      ],
      [
        [
          [-163.916016, 71.992578], //alaska
          [-140.888672, 70.641769],
          [-140.976562, 60.326948],
          [-135.175781, 60.326948],
          [-129.550781, 55.553495],
          [-131.286621, 54.239551],
          [-179.736328, 51.069017],
          [-172.089844, 63.626745],
          [-163.916016, 71.992578]
        ]
      ],
      [
        [
          [-161.323242, 22.512557], //hawaii
          [-152.446289, 22.065278],
          [-156.09375, 17.811456],
          [-161.323242, 22.512557]
        ]
      ]
    ]
  };
  const mp = turf.multiPolygon(usaShape.coordinates);
  return turf.booleanPointInPolygon(pt, mp);
};

export const formatAddress = ({ address }) => {
  var address_line1 = " ";
  var address_line2 = " ";

  if (!!address.address2 && !address?.address.includes(address?.address2)) {
    if (!address.address) {
      if (address.latitude && address.longitude) {
        address_line1 = address.latitude + ", " + address.longitude;
        if (
          !!address.address_city &&
          !!address.address_state &&
          !!address.address_zip
        ) {
          address_line2 =
            toTitleCase(address.address_city) +
            ", " +
            address.address_state +
            " " +
            address.address_zip;
        } else {
          address_line2 = "";
        }
      } else {
        if (
          !!address.address_city &&
          !!address.address_state &&
          !!address.address_zip
        ) {
          address_line1 =
            toTitleCase(address.address_city) +
            ", " +
            address.address_state +
            " " +
            address.address_zip;
        } else {
          address_line1 = "Unknown Address";
          address_line2 = "";
        }
      }
    } else {
      address_line1 =
        toTitleCase(address.address) + " " + toTitleCase(address.address2);
      if (
        !!address.address_city &&
        !!address.address_state &&
        !!address.address_zip
      ) {
        address_line2 =
          toTitleCase(address.address_city) +
          ", " +
          address.address_state +
          " " +
          address.address_zip;
      } else {
        address_line2 = "";
      }
    }
  } else {
    if (!address.address) {
      if (address.latitude && address.longitude) {
        address_line1 = address.latitude + ", " + address.longitude;
        if (
          !!address.address_city &&
          !!address.address_state &&
          !!address.address_zip
        ) {
          address_line2 =
            toTitleCase(address.address_city) +
            ", " +
            address.address_state +
            " " +
            address.address_zip;
        } else {
          address_line2 = "";
        }
      } else {
        address_line1 =
          toTitleCase(address.address_city) +
          ", " +
          address.address_state +
          " " +
          address.address_zip;
        if (
          !!address.address_city &&
          !!address.address_state &&
          !!address.address_zip
        ) {
          address_line2 =
            toTitleCase(address.address_city) +
            ", " +
            address.address_state +
            " " +
            address.address_zip;
        } else {
          address_line2 = "";
        }
      }
    } else {
      address_line1 = toTitleCase(address.address);
      if (
        !!address.address_city &&
        !!address.address_state &&
        !!address.address_zip
      ) {
        address_line2 =
          toTitleCase(address.address_city) +
          ", " +
          address.address_state +
          " " +
          address.address_zip;
      } else {
        address_line2 = "";
      }
    }
  }

  if (
    address_line1 == undefined ||
    address_line1 == "undefined" ||
    address_line1 == "undefined," ||
    address_line1 == "undefined, " ||
    address_line1 == "undefined,  " ||
    address_line1 == null
  ) {
    address_line1 = "";
  } else {
    address_line1 = address_line1.trim();
  }

  if (
    address_line2 == undefined ||
    address_line2 == "undefined" ||
    address_line2 == "undefined," ||
    address_line2 == "undefined, " ||
    address_line2 == "undefined,  " ||
    address_line2 == null
  ) {
    address_line2 = "";
  } else {
    address_line2 = address_line2.trim();
  }
  return { line1: address_line1, line2: address_line2 };
};

export const toTitleCase = str => {
  if (!!str) {
    str = str.toString().replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    return str.toString();
  }
};

export const numberFormat = (number, decimals, dec_point, thousands_sep) => {
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep == "undefined" ? "," : thousands_sep,
    dec = typeof dec_point == "undefined" ? "." : dec_point,
    toFixedFix = function (n, prec) {
      // Fix for IE parseFloat(0.55).toFixed(0) = 0;
      var k = Math.pow(10, prec);
      return Math.round(n * k) / k;
    },
    s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
};

const isUpperCase = str => {
  if (str) {
    return str == str.toUpperCase();
  }
  return "";
};

const formatSuffix = str => {
  if (str == undefined) {
    return "";
  } else if (str.includes("Iii")) {
    return str.replace("Iii", "");
  } else if (str.includes("Llc")) {
    return str.toUpperCase();
  } else if (str.includes("Inc")) {
    return str.toUpperCase();
  } else {
    return str;
  }
};

export const ownerNameFormat = (str, info) => {
  return str;
};

export const renderPrice = (price, format = null) => {
  if (parseInt(price) < 100) {
    if (format === "negative") {
      if (parseInt(price) == 0) {
        return "$0.00";
      } else if (parseInt(price) > -100) {
        return price + "¢";
      } else {
        return (
          "- $" + numberWithCommas(parseFloat((price * -1) / 100).toFixed(2))
        );
      }
    } else {
      if (parseInt(price) <= 0) {
        return "$0.00";
      } else {
        return price + "¢";
      }
    }
  } else {
    if (format === "no_decimal") {
      return "$" + numberWithCommas(parseInt(price / 100));
    }
    return "$" + numberWithCommas(parseFloat(price / 100).toFixed(2));
  }
};

export const checkIfAllAddressesAreToggled = (addresses, owner) => {
  var count = 0;
  if (owner.use_owner_address == 1) {
    count++;
  }

  if (addresses) {
    if (addresses.length > 0) {
      for (var i = 0; i < addresses.length; i++) {
        if (addresses[i].address_send_mail == 1) {
          count++;
        }
      }
      if (count < addresses.length + 1) {
        var leftovers = addresses.length + 1 - count;
        return leftovers;
      } else {
        return false;
      }
    }
  }

  return false;
};

export const formatUsPhone = phone => {
  if (phone) {
    var phoneTest = new RegExp(
      /^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/
    );
    phone = phone.trim();
    var results = phoneTest.exec(phone);
    if (results != null && results.length > 8) {
      return (
        "(" +
        results[3] +
        ") " +
        results[4] +
        "-" +
        results[5] +
        (typeof results[8] != "undefined" ? " x" + results[8] : "")
      );
    } else {
      return phone;
    }
  }

  return phone;
};

export const skipTrace = ({
  props,
  properties = [],
  use_passed_filters = false,
  passed_filters = {
    lead_ids: null,
    filters: null,
    filter_lists: null
  },
  total_property_count = 1,
  onSuccess = () => {}
}) => {
  const skip_trace_price = props.pricing.skip_trace_price;
  const pricing_tier_name = props.pricing.pricing_tier_name;
  const corporate_skip_trace_price = props.pricing.corporate_skip_trace_price;

  if (props.user.can_enhanced_search == 1) {
    if (total_property_count > 1) {
      //TODO make sure to check dealcredits and prompt for card if there is none

      checkForDealCredits({
        props: props,
        deal_credits: props.deal_credits,
        amount_to_spend: skip_trace_price * total_property_count,
        type: "bulk_edit",
        callback: () => {
          const new_list_name = `Skip Trace ${moment().format(
            "MMM Do, h:mm a"
          )}`;

          props.setModal({
            title:
              "Are you sure you want to skip trace " +
              total_property_count +
              " leads?",
            description:
              "By skip tracing these leads, you'll be using " +
              renderPrice(skip_trace_price * total_property_count) +
              " of your Marketing Credits. You will be charged for more credits if you don't have enough. You cannot undo this.",
            icon: "check-circle",
            submit: "Skip Trace Leads",
            onPress: () => {
              props.updateLead({
                token: props.token,
                type: "skip_trace_leads_v2",
                select_all: props.selected_all_in_account ? 1 : 0,
                total_count: total_property_count,
                filters:
                  use_passed_filters && passed_filters
                    ? passed_filters.filters
                    : props.applied_filters,
                filter_lists:
                  use_passed_filters && passed_filters
                    ? passed_filters.filter_lists
                    : props.filter_lists,
                deal_ids:
                  use_passed_filters && passed_filters
                    ? passed_filters.lead_ids
                    : properties
                        .map(property => {
                          return property.deal.id;
                        })
                        .join(),
                new_list_name,
                onSuccess: onSuccess
              });
            },
            cancel: "Nevermind. Cancel Action.",
            onCancel: () => {}
          });
          props.toggleModal({ show: true, type: "normal" });
        }
      });
    } else {
      if (
        use_passed_filters ||
        parseInt(properties[0].deal.did_skip_trace) !== 1
      ) {
        if (parseInt(props.user.has_skip_traced) === 0) {
          props.setModal({
            title: "Ready to search?",
            description:
              "Skip tracing helps you connect with owners faster by showing phone numbers, emails, and additional address locations where the owner receives mail. \n \nEach skip trace will cost " +
              renderPrice(skip_trace_price),
            icon: "search",
            submit: "Use Skip Trace",
            onPress: () => {
              checkForDealCredits({
                props: props,
                deal_credits: props.deal_credits,
                amount_to_spend: use_passed_filters
                  ? skip_trace_price
                  : properties[0].owner_status_info.corp_owner == 1
                  ? corporate_skip_trace_price
                  : skip_trace_price,
                type: use_passed_filters
                  ? "skip_trace"
                  : properties[0].owner_status_info.corp_owner == 1
                  ? "corporate_skip_trace"
                  : "skip_trace",
                callback: () => {
                  props.updateOwnerInfo({
                    token: props.token,
                    deal_id:
                      use_passed_filters && passed_filters
                        ? passed_filters.lead_ids
                        : properties[0].deal.id,
                    type: "skip_trace_v2",
                    onSuccess: onSuccess
                  });
                }
              });
            },
            cancel: "Not right now.",
            onCancel: () => {}
          });
          props.toggleModal({ show: true, type: "normal" });
        } else {
          checkForDealCredits({
            props: props,
            deal_credits: props.deal_credits,
            amount_to_spend: use_passed_filters
              ? skip_trace_price
              : properties[0].owner_status_info.corp_owner == 1
              ? corporate_skip_trace_price
              : skip_trace_price,
            type: use_passed_filters
              ? "skip_trace"
              : properties[0].owner_status_info.corp_owner == 1
              ? "corporate_skip_trace"
              : "skip_trace",
            callback: () => {
              props.updateOwnerInfo({
                token: props.token,
                deal_id:
                  use_passed_filters && passed_filters
                    ? passed_filters.lead_ids
                    : properties[0].deal.id,
                type: "skip_trace_v2",
                onSuccess: onSuccess
              });
            }
          });
        }
      }
    }
  } else {
    props.setModal({
      title: "You do not have permissions to use skip tracing.",
      description: "Talk to your team leader to get the permissions you need.",
      icon: "error",
      submit: "Dismiss",
      onPress: () => {},
      cancel: "",
      onCancel: () => {}
    });
    props.toggleModal({ show: true, type: "normal" });
  }
};

export const getStateName = abbr => {
  const states = [
    {
      name: "Alabama",
      abbr: "AL"
    },
    {
      name: "Alaska",
      abbr: "AK"
    },
    {
      name: "American Samoa",
      abbr: "AS"
    },
    {
      name: "Arizona",
      abbr: "AZ"
    },
    {
      name: "Arkansas",
      abbr: "AR"
    },
    {
      name: "California",
      abbr: "CA"
    },
    {
      name: "Colorado",
      abbr: "CO"
    },
    {
      name: "Connecticut",
      abbr: "CT"
    },
    {
      name: "Delaware",
      abbr: "DE"
    },
    {
      name: "District Of Columbia",
      abbr: "DC"
    },
    {
      name: "Florida",
      abbr: "FL"
    },
    {
      name: "Georgia",
      abbr: "GA"
    },
    {
      name: "Guam",
      abbr: "GU"
    },
    {
      name: "Hawaii",
      abbr: "HI"
    },
    {
      name: "Idaho",
      abbr: "ID"
    },
    {
      name: "Illinois",
      abbr: "IL"
    },
    {
      name: "Indiana",
      abbr: "IN"
    },
    {
      name: "Iowa",
      abbr: "IA"
    },
    {
      name: "Kansas",
      abbr: "KS"
    },
    {
      name: "Kentucky",
      abbr: "KY"
    },
    {
      name: "Louisiana",
      abbr: "LA"
    },
    {
      name: "Maine",
      abbr: "ME"
    },
    {
      name: "Maryland",
      abbr: "MD"
    },
    {
      name: "Massachusetts",
      abbr: "MA"
    },
    {
      name: "Michigan",
      abbr: "MI"
    },
    {
      name: "Minnesota",
      abbr: "MN"
    },
    {
      name: "Mississippi",
      abbr: "MS"
    },
    {
      name: "Missouri",
      abbr: "MO"
    },
    {
      name: "Montana",
      abbr: "MT"
    },
    {
      name: "Nebraska",
      abbr: "NE"
    },
    {
      name: "Nevada",
      abbr: "NV"
    },
    {
      name: "New Hampshire",
      abbr: "NH"
    },
    {
      name: "New Jersey",
      abbr: "NJ"
    },
    {
      name: "New Mexico",
      abbr: "NM"
    },
    {
      name: "New York",
      abbr: "NY"
    },
    {
      name: "North Carolina",
      abbr: "NC"
    },
    {
      name: "North Dakota",
      abbr: "ND"
    },
    {
      name: "Ohio",
      abbr: "OH"
    },
    {
      name: "Oklahoma",
      abbr: "OK"
    },
    {
      name: "Oregon",
      abbr: "OR"
    },
    {
      name: "Pennsylvania",
      abbr: "PA"
    },
    {
      name: "Puerto Rico",
      abbr: "PR"
    },
    {
      name: "Rhode Island",
      abbr: "RI"
    },
    {
      name: "South Carolina",
      abbr: "SC"
    },
    {
      name: "South Dakota",
      abbr: "SD"
    },
    {
      name: "Tennessee",
      abbr: "TN"
    },
    {
      name: "Texas",
      abbr: "TX"
    },
    {
      name: "Utah",
      abbr: "UT"
    },
    {
      name: "Vermont",
      abbr: "VT"
    },
    {
      name: "Virgin Islands",
      abbr: "VI"
    },
    {
      name: "Virginia",
      abbr: "VA"
    },
    {
      name: "Washington",
      abbr: "WA"
    },
    {
      name: "West Virginia",
      abbr: "WV"
    },
    {
      name: "Wisconsin",
      abbr: "WI"
    },
    {
      name: "Wyoming",
      abbr: "WY"
    },
    {
      name: "Armed Forces",
      abbr: "AE"
    },
    {
      name: "Armed Forces America",
      abbr: "AA"
    },
    {
      name: "Armed Forces Pacific",
      abbr: "AP"
    },

    {
      name: "Alberta",
      abbr: "AB"
    },

    {
      name: "British Columbia",
      abbr: "BC"
    },
    {
      name: "Manitoba",
      abbr: "MB"
    },
    {
      name: "New Brunswick",
      abbr: "NB"
    },
    {
      name: "Newfoundland and Labrador",
      abbr: "NL"
    },
    {
      name: "Northwest Territories",
      abbr: "NT"
    },
    {
      name: "Nova Scotia",
      abbr: "NS"
    },
    {
      name: "Nunavut",
      abbr: "NU"
    },
    {
      name: "Ontario",
      abbr: "ON"
    },
    {
      name: "Prince Edward Island",
      abbr: "PE"
    },
    {
      name: "Quebec",
      abbr: "QC"
    },
    {
      name: "Saskatchewan",
      abbr: "SK"
    },
    {
      name: "Yukon",
      abbr: "YT"
    }
  ];

  for (var i = 0; i < states.length; i++) {
    if (states[i].abbr == abbr) {
      return states[i].name;
    }
  }
  return "Not Selected";
};

export const renderTeamStatus = member => {
  if (member.team_owner == 1) {
    return "Team Leader";
  }
  return "Team Member";
};

export const renderDate = (date_created, just_date, format) => {
  if (date_created) {
    var date = new Date();
    var offsetInHours = date.getTimezoneOffset() / 60;

    if (just_date) {
      if (format == "day_of_week") {
        return moment(date_created)
          .subtract(offsetInHours, "hours")
          .format("dddd");
      }
      if (format == "day_of_week_2_char") {
        return moment(date_created)
          .subtract(offsetInHours, "hours")
          .format("dd");
      }
      if (format == "day_of_week_3_char") {
        return moment(date_created)
          .subtract(offsetInHours, "hours")
          .format("ddd");
      }
      if (format == "no_timezone_offset") {
        return moment(date_created).format("MMM Do, YYYY");
      }
      return moment(date_created)
        .subtract(offsetInHours, "hours")
        .format("MMM Do, YYYY");
    } else {
      if (format == "from_now") {
        return moment(date_created).subtract(offsetInHours, "hours").fromNow();
      }
      if (format == "time") {
        return moment(date_created)
          .subtract(offsetInHours, "hours")
          .format("h:mm a");
      }
      return moment(date_created)
        .subtract(offsetInHours, "hours")
        .format("MMM Do, YYYY h:mma");
    }
  } else {
    return "";
  }
};

const renderSelectedLeadMailPrice = (selected_leads, props) => {
  let postcard_mailer_cost = 0;
  let handwritten_mailer_cost = 0;
  let total = 0;
  for (var i = 0; i < selected_leads.length; i++) {
    if (selected_leads[i].deal.mail_template_type == "postcard") {
      postcard_mailer_cost += props.pricing.mailer_price;
    } else {
      handwritten_mailer_cost += props.pricing.ballpoint_price;
    }
  }
  total = postcard_mailer_cost + handwritten_mailer_cost;
  return renderPrice(total);
};

export const startMailers = ({
  props,
  properties = [],
  total_property_count = 1,
  onSuccess = () => {}
}) => {
  //get actual mailer price later
  const mailer_price = props.pricing.mailer_price;
  const ballpoint_price = props.pricing.ballpoint_price;
  const pricing_tier_name = props.pricing.pricing_tier_name;

  if (properties.length > 0 || total_property_count > 1) {
    if (props.user.can_approve_mail == 1) {
      if (parseInt(props.user.team_set_signature) !== 1) {
        //trigger signature onboarding
        props.toggleOnboarding(true);
        props.appRedirect({
          redirect: "goForward",
          payload: { add: "onboarding-signature" }
        });
      } else {
        if (total_property_count > 1) {
          //TODO make sure to check dealcredits and prompt for card if there is none
          checkForDealCredits({
            props: props,
            deal_credits: props.deal_credits,
            amount_to_spend: mailer_price * total_property_count,
            type: "bulk_edit",
            callback: () => {
              const new_list_name = `Started mail on ${moment().format(
                "M/DD/YYYY [@] h:mm a"
              )}`;

              props.setModal({
                title:
                  "Are you sure you want to start mail sequences for " +
                  total_property_count +
                  " leads?",
                description:
                  "By starting mail sequences, you'll be starting or resuming the mailing sequence for all " +
                  total_property_count +
                  " leads. You will be charged for more credit if you don't have enough. You cannot undo this. Mail piece will be sent to the queue within the hour.",
                icon: "check-circle",
                submit: "Start Mail",
                onPress: () => {
                  //trigger add to list
                  props.updateLead({
                    token: props.token,
                    type: "start_mailers",
                    select_all: props.selected_all_in_account ? 1 : 0,
                    filters: props.applied_filters,
                    filter_lists: props.filter_lists,
                    total_count: properties.length,
                    deal_ids: properties
                      .map(property => {
                        return property.deal.id;
                      })
                      .join(),
                    new_list_name,
                    onSuccess: onSuccess
                  });
                },
                cancel: "Nevermind. Cancel Action.",
                onCancel: () => {}
              });
              props.toggleModal({ show: true, type: "normal" });
            }
          });
        } else if (properties.length == 1) {
          if (parseInt(props.user.has_sent_mail) === 0) {
            props.setModal({
              title: "Start Mail?",
              description:
                "Build trust and connect with this owner by sending a mail piece. Stand out and make it personal -- your mail will feature the picture you added of the property, as well as your headshot and signature. \n\n Each piece of mail will cost " +
                renderPrice(mailer_price),
              icon: "mail",
              submit: "Start Mail.",
              onPress: () => {
                checkForDealCredits({
                  props: props,
                  deal_credits: props.deal_credits,
                  amount_to_spend:
                    properties[0].deal.mail_template_type === "handwritten"
                      ? ballpoint_price
                      : mailer_price,
                  type: "mailers",
                  callback: () => {
                    props.updateLead({
                      token: props.token,
                      type: "start_mailers",
                      deal_ids: properties[0].deal.id
                    });
                  }
                });
              },
              cancel: "Not right now.",
              onCancel: () => {}
            });
            props.toggleModal({ show: true, type: "normal" });
          } else {
            checkForDealCredits({
              props: props,
              deal_credits: props.deal_credits,
              amount_to_spend:
                properties[0].deal.mail_template_type === "handwritten"
                  ? ballpoint_price
                  : mailer_price,
              type: "mailers",
              callback: () => {
                props.updateLead({
                  token: props.token,
                  type: "start_mailers",
                  deal_ids: properties[0].deal.id
                });
              }
            });
          }
        }
      }
    } else {
      props.setModal({
        title: "You do not have permissions to start mail.",
        description:
          "Talk to your team leader to get the permissions you need.",
        icon: "error",
        submit: "Dismiss",
        onPress: () => {},
        cancel: "",
        onCancel: () => {}
      });
      props.toggleModal({ show: true, type: "normal" });
    }
  }
};

export const checkForDealCredits = ({
  props,
  deal_credits,
  amount_to_spend = 0,
  type,
  total_purchase_amount,
  callback
}) => {
  var show_prompt = false;
  var prompt_title = "";
  var prompt_message = "";
  var submit_title = "Continue";
  var cancel_title = "Cancel Purchase";
  var reload_amount = renderPrice(deal_credits.default_credit_reload.cents);

  switch (type) {
    case "mailers":
    default:
      if (
        parseInt(deal_credits?.deal_credits_remaining.cents) -
          parseInt(deal_credits.total_deal_credits_queued.cents) >=
          0 &&
        parseInt(deal_credits?.deal_credits_remaining.cents) -
          parseInt(amount_to_spend) -
          parseInt(deal_credits.total_deal_credits_queued.cents) <=
          0
      ) {
        //check if there is a card on file:
        //if not redirect to card enter page

        show_prompt = true;
        prompt_title = "Reloading Marketing Credits";
        prompt_message =
          "When mail pieces are sent, your account will automatically be reloaded " +
          reload_amount +
          " and " +
          reload_amount +
          " will be charged to your card.";
      }

      break;

    case "bulk_edit":
      show_prompt = false;
      checkIfTeamHasCard({
        props,
        onSuccess: () => {
          callback();
        }
      });
      break;

    case "skip_trace":
    case "skip_trace_v2":
    case "corporate_skip_trace":
      if (
        parseInt(deal_credits?.deal_credits_remaining.cents) -
          parseInt(amount_to_spend) <=
        0
      ) {
        //check if there is a card on file:
        //if not redirect to card enter page

        show_prompt = true;
        prompt_title = "Reloading Marketing Credits";
        prompt_message =
          "By pressing continute, your account will automatically be reloaded " +
          reload_amount +
          " and " +
          reload_amount +
          " will be charged to your card.";
      }

      break;
  }

  if (show_prompt) {
    checkIfTeamHasCard({
      props,
      onSuccess: () => {
        props.setModal({
          title: prompt_title,
          description: prompt_message,
          icon: "fiber-smart-record",
          submit: submit_title,
          onPress: () => {
            callback();
          },
          cancel: cancel_title,
          onCancel: () => {}
        });
        props.toggleModal({ show: true, type: "normal" });
      }
    });
    return;
  } else {
    callback();
    return;
  }
};

export const getCampaignInfo = ({ campaign_id, campaigns }) => {
  for (var i = 0; i < campaigns.length; i++) {
    if (campaigns[i].id == campaign_id) {
      return campaigns[i];
    }
  }

  return null;
};

export const getTemplateInfo = ({ template_id, templates }) => {
  for (var i = 0; i < templates.length; i++) {
    if (templates[i].id == template_id) {
      return templates[i];
    }
  }
  return null;
};

const renderRepeatingMailText = deal => {
  if (
    parseInt(deal.times_mailed) >= parseInt(deal.resend_limit) &&
    deal.resend_limit != 0 &&
    deal.approved == 1
  ) {
    return deal.deal_status_title + " - Cycle Complete";
  } else if (deal.resend_freq > 0 && deal.status == 1 && deal.approved == 1) {
    if (deal.times_mailed == 1) {
      return (
        deal.deal_status_title +
        " - Sent 1 time\nLast sent " +
        moment(deal.sent_date).format("MMMM Do")
      );
    } else {
      return (
        deal.deal_status_title +
        " - Sent " +
        deal.times_mailed +
        " times\nLast sent " +
        moment(deal.sent_date).format("MMMM Do")
      );
    }
  } else if (deal.resend_freq > 0 && deal.status == 0 && deal.approved == 1) {
    return deal.deal_status_title + " - In Mail Queue";
  }
};

const renderCampaignText = deal => {
  if (deal.campaign_complete == 1) {
    return (
      deal.deal_status_title +
      "\n" +
      deal.campaign_title +
      " - Mail Sequence Complete"
    );
  } else if (deal.status == 1) {
    return (
      deal.deal_status_title +
      "\n" +
      deal.campaign_title +
      " - Step " +
      deal.campaign_step +
      " of " +
      deal.number_of_steps +
      "\nLast sent " +
      moment(deal.sent_date).format("MMMM Do")
    );
  } else if (deal.status == 0 && deal.approved == 1) {
    return (
      deal.deal_status_title +
      "\n" +
      deal.campaign_title +
      " - Step " +
      deal.campaign_step +
      " of " +
      deal.number_of_steps +
      "\nIn Mail Queue"
    );
  } else if (deal.status == 0 && deal.approved == 0) {
    return (
      deal.deal_status_title +
      "\n" +
      deal.campaign_title +
      " - Mail Sequence Not Started"
    );
  }
};

export const getStatus = (deal, type = "string") => {
  var status_string = "";
  var icon;
  var color = "#31cce5";

  if (deal.approved == 1 && deal.archived != 1 && deal.closed != 1) {
    if (deal.campaign_id != 0) {
      icon = "flag";
      status_string = renderCampaignText(deal);
    } else if (deal.resend_freq > 0) {
      icon = deal.deal_status_icon;
      status_string = renderRepeatingMailText(deal);
    } else if (deal.status == 1) {
      icon = deal.deal_status_icon;

      if (deal.sent_date) {
        status_string =
          deal.deal_status_title +
          "\nMail Sent " +
          moment(deal.sent_date).format("MMMM Do");
      } else {
        status_string = deal.deal_status_title + "\nMail Sent";
      }
    } else {
      icon = deal.deal_status_icon;

      if (deal.resend_freq == 0) {
        status_string = deal.deal_status_title;
      }

      if (deal.times_mailed == 1) {
        status_string += "\nSent " + deal.times_mailed + " time";
      } else if (deal.times_mailed > 0) {
        status_string += "\nSent " + deal.times_mailed + " times";
      } else {
        icon = deal.deal_status_icon;

        status_string = deal.deal_status_title + "\nIn Mail Queue";
      }
    }
  }
  if (deal.paused == 1 && deal.archived != 1 && deal.closed != 1) {
    icon = "pause";
    if (deal.campaign_id != 0) {
      if (deal.deal_status_slug == "marketing_complete") {
        status_string = deal.deal_status_title;
      } else {
        status_string = deal.deal_status_title + "\nCampaign Paused";
      }
    } else {
      status_string = deal.deal_status_title + "\nMailers Paused";
    }
  } else if (
    deal.approved == 1 &&
    deal.archived != 1 &&
    deal.closed != 1 &&
    deal.status == 0
  ) {
    icon = deal.deal_status_icon;

    if (deal.resend_freq == 0) {
      status_string = deal.deal_status_title;
    }

    if (deal.times_mailed == 1) {
      status_string += "\nSent " + deal.times_mailed + " time";
    } else if (deal.times_mailed > 0) {
      status_string += "\nSent " + deal.times_mailed + " times";
    } else {
      icon = deal.deal_status_icon;
      status_string = deal.deal_status_title + "\nIn Mail Queue";
    }
  } else if (
    deal.approved != 1 &&
    deal.archived != 1 &&
    deal.closed != 1 &&
    deal.status == 0
  ) {
    icon = deal.deal_status_icon;
    color = "#fff";
    if (deal.campaign_id == 0) {
      status_string = deal.deal_status_title + "\nMailers not started";
    } else {
      status_string = deal.deal_status_title + "\nCampaign not started";
    }
  } else if (deal.archived != 1 && deal.closed == 1) {
    icon = deal.deal_status_icon;

    color = "#28F29E";

    status_string = deal.deal_status_title;
  } else if (deal.status == 2 && deal.archived != 1) {
    icon = "error";
    color = "#B24C63";
    status_string = "Failed to find owner";
  } else if (deal.archived == 1) {
    status_string = deal.deal_status_icon;

    color = "#B24C63";

    status_string = deal.deal_status_title;
  } else if (deal.status == 1) {
    status_string = deal.deal_status_icon;
    status_string = deal.deal_status_title;

    if (deal.sent_date) {
      status_string +=
        "\nMail Sent " + moment(deal.sent_date).format("MMMM Do");
    } else {
      status_string += "\nMail Sent";
    }
  }

  // if(deal.deal_status_title){
  //   status_string = deal.deal_status_title;
  //   icon = "insert-chart"
  // }

  if (type == "icon") {
    return icon;
  } else if (type == "color") {
    return color;
  }
  return status_string;
};

// export const getDealStatusIcon = (status_id) => {
//
//   var icon;
//
//   if(status_id == 1){
//     icon = "snooze";
//   }else if(status_id == 2){
//     icon = "contact_mail";
//   }else if(status_id == 3){
//     icon = "today";
//   }else if(status_id == 4){
//     icon = "money_off";
//   }else if(status_id == 5){
//     icon = "attach_money";
//   }else if(status_id == 6){
//     icon = "contact_phone";
//   }else if(status_id == 7){
//     icon = "mood";
//   }else if(status_id == 8){
//     icon = "mood_bad";
//   }else if(status_id == 9){
//     icon = "sentiment_dissatisfied";
//   }else if(status_id == 10){
//     icon = "not_interested";
//   }else if(status_id == 11){
//     icon = "domain_disabled";
//   }else if(status_id == 12){
//     icon = "phone_missed";
//   }else if(status_id == 13){
//     icon = "delete_outline";
//   }else{
//     icon = "";
//   }
//   return icon;
// }

export const textStyleFromContentful = contentfulStyle => {
  const textStyle = {};

  if (!contentfulStyle || !contentfulStyle.fields) {
    return textStyle;
  }

  if (contentfulStyle.fields.fontColor) {
    textStyle["color"] = contentfulStyle.fields.fontColor;
  }
  if (contentfulStyle.fields.backgroundColor) {
    textStyle["backgroundColor"] = contentfulStyle.fields.backgroundColor;
  }

  const lineStyleArray = [];
  if (contentfulStyle.fields.underline) {
    lineStyleArray.push("underline");
  }
  if (contentfulStyle.fields.lineThrough) {
    lineStyleArray.push("line-through");
  }
  if (lineStyleArray.length > 0) {
    textStyle["textDecorationLine"] = lineStyleArray.join(" ");
  }

  if (contentfulStyle.fields.fontWeight) {
    textStyle["fontWeight"] = contentfulStyle.fields.fontWeight;
  }
  if (contentfulStyle.fields.italic) {
    textStyle["fontStyle"] = "italic";
  }
  if (contentfulStyle.fields.fontSize) {
    textStyle["fontSize"] = contentfulStyle.fields.fontSize;
  }
  if (contentfulStyle.fields.textAlign) {
    textStyle["textAlign"] = contentfulStyle.fields.textAlign;
  }

  return textStyle;
};

export const spaceFormat = (text, formatting) => {
  if (!!formatting["no_break"])
    return text.replace(" ", "\u00A0").replace("&nbsp;", "\u00A0");
  else return text.replace("\u00A0", " ").replace("&nbsp;", " ");
};

export const formatDateTimeToLocal = dateTime => {
  return moment.parseZone(dateTime).local().format("dddd, MMMM Do @ h:mma");
};

const checkIfTeamHasCard = ({ props, onSuccess = () => {} }) => {
  if (parseInt(props.card_info.has_card) === 0) {
    props.toggleOnboarding(true);
    props.appRedirect({ redirect: "goForward", payload: { add: "add-card" } });
  } else if (parseInt(props.card_info.bad_card) === 1) {
    props.appRedirect({
      redirect: "goForward",
      payload: { add: "update-card" }
    });
  } else {
    onSuccess();
  }
};

export const formatDate = (dateString, modifyDate) => {
  const formatter = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "short",
    day: "2-digit"
  });
  let date = new Date(dateString);
  if (modifyDate) date = modifyDate(date);
  return formatter.format(date);
};
export const dateToYYYYMMDD = date => {
  if (typeof date == "string") return date;
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0];
};

export const formatCurrency = (num, currency = "USD") =>
  new Intl.NumberFormat("en-US", { style: "currency", currency }).format(num);

export const formatStringUCW = (str = "") => {
  if (typeof str == "string") {
    return str.toLowerCase().replace(/\b[a-z]/g, l => l.toUpperCase());
  } else {
    return str;
  }
};

export const range = (start, end, step = 1) => {
  if (start >= end) return [start];
  return [start, ...range(start + step, end, step)];
};

export const rangeNonRecursive = (start, end, step = 1) => {
  let result = [];
  for (let i = start; i <= end; i += step) {
    result.push(i);
  }
  return result;
};

export const formatPhoneNumber = phoneNumberString => {
  //remove +1 if it exists at the beginning of the string
  if (phoneNumberString.startsWith("+1")) {
    phoneNumberString = phoneNumberString.slice(2);
  }

  if (phoneNumberString && phoneNumberString.match(/^\d{3}\*{7}$/)) {
    return `(${phoneNumberString.slice(0, 3)}) ***-****`;
  }

  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export const checkIfHasGoogleStreetPic = ({
  address,
  coordinates = null,
  onSuccess = () => {}
}) => {
  const google_key = "AIzaSyAAUxGs5Nh7syO8BRzpIf3Wv9a2GvIEqEo";
  let google_url;
  if (!!address) {
    google_url =
      "https://maps.googleapis.com/maps/api/streetview/metadata?location=" +
      encodeURIComponent(address) +
      "&key=" +
      google_key;
  } else if (coordinates?.latitude && coordinates?.longitude) {
    google_url =
      "https://maps.googleapis.com/maps/api/streetview/metadata?location=" +
      encodeURIComponent(coordinates?.latitude) +
      "," +
      encodeURIComponent(coordinates?.longitude) +
      "&key=" +
      google_key;
  }
  axios
    .get(google_url)
    .then(response => {
      if (response?.data?.status == "OK") {
        onSuccess(response?.data);
      } else {
        onSuccess(response?.data);
      }
    })
    .catch(error => {
      onSuccess({
        status: false
      });
    });
};

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export const isJson = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getHelperDescription = helper_type => {
  switch (helper_type) {
    default:
      return "";
    case "off_market":
      return "Properties that are not currently listed for sale on a multiple listing service (MLS).";
    case "mls_active":
      return "Properties that are currently listed for sale on the MLS.";
    case "mls_pending":
      return "Properties that have a signed contract and are in the process of closing. The sale is not yet complete, but it is expected to be finalized.";
    case "mls_contingent":
      return "Listed properties that have an accepted offer, but the final sale is contingent upon certain conditions being met. These conditions could include inspections, mortgage approvals, or the buyer selling their current home.";
    case "recently_sold":
      return "Properties that have been sold in the last year.";
    case "expired_listing":
      return "Properties that were listed for sale, but the listing agreement has expired without the property being sold.";
    case "adjustable_loan":
      return "A mortgage loan where the interest rate adjusts periodically based on a pre-determined index and a margin.";
    case "bank_owned":
      return "Properties that are owned by a bank or financial institution, usually as a result of foreclosure.";
    case "cash_buyer":
      return "Properties whose owners may be able to purchase properties outright with cash, without the need for a mortgage loan.";
    case "free_and_clear":
      return "Properties that are completely paid off, with no mortgage lien or any other liens against them.";
    case "high_equity":
      return "Properties where the owners hold 60% or greater equity, meaning they owe significantly less than the property's market value.";
    case "low_equity":
      return "Properties where the owners hold less than or equal to 20% equity, meaning they owe nearly as much, if not more, as the property's market value.";
    case "tax_delinquent":
      return "This means the owner of the property has not paid the required property taxes and is late on payment. The length of delinquency can lead to a tax lien or even tax foreclosure. Note - this field is updated once a year and includes the last 2 years worth of data.";
    case "preforeclosure":
      return "Properties whose owner is behind on mortgage payments OR HOA payments and either a Judgment of Default or HOA Lien has been placed against the property. These properties are at risk of foreclosure but no auction date has been set.";
    case "foreclosure":
      return "Properties where the owners have defaulted on their mortgage payments and the bank has initiated the legal process to take ownership of the property.";
    case "upcoming_auction":
      return "Properties which are in pre-foreclosure and are to be included in an upcoming foreclosure auction.";
    case "pre_probate":
      return "Properties where an owner has passed away. Included in this filter are properties whose most recent change in title indicate that a/the titleholder is deceased. These properties may or may not have officially entered probate. Tip - combine with sale date filter to find leads with recent activity.";
    case "intra_family_transfer":
      return "Properties which the last transaction or transfer of ownership took place between two members of the same family. This most commonly occurs during an inheritance and can be a sign of a motivated seller.";
    case "senior_owner":
      return "Properties whose owners are typically aged 65 or older.";
    case "tired_landlord":
      return "Properties in which the owner doesn't reside and has owned their property for 15+ years. These owners may be interested in selling their rental properties.";
    case "out_of_state_owner":
      return "Property owners who reside in a different state than the property location.";
    case "owner_occupied":
      return "Properties in which the owner does reside.";
    case "absentee_owner":
      return "Properties in which the owner does not reside.";
    case "corporate_owner":
      return "Properties owned by a corporation or a business entity rather than an individual. These properties are often held for investment purposes.";
    case "vacant_home":
      return "Residential properties that are currently unoccupied.";
    case "vacant_lot":
      return "Parcels of land that are currently unoccupied and undeveloped excluding government owned, intstituional owner and private reserve land.";
    case "zombie_property":
      return "Properties that are vacant because the foreclosure process has started, but it has not been completed, leaving the property in a state of limbo.";
    case "equity_amount":
      return "The estimated amount of equity a property owner has.";
    case "equity_percent":
      return "The estimated percent of equity a property owner has.";
    case "original_loan_amount":
      return "The amount of the loan originally taken out on the property.";
    case "estimated_loan_balance":
      return "The estimated remaining loan balance.";
    case "estimated_loan_payment":
      return "The estimated monthly payment (excluding taxes, insurance and PMI).";
    case "estimated_interest_rate":
      return "The estimated current interest rate.";
    case "last_recording_date":
      return "The date on which the loan was recorded.";
    case "loan_type":
      return "The type of first loan taken out against the property.";
    case "loan_term":
      return "The term (in months) of the loan.";
    case "interest_rate_type":
      return "The type of interest rate (adjustable or fixed)";
    case "loan_maturity_date":
      return "The date on which the loan should be payed off.";
    case "lender_name":
      return "The name of the lender who wrote the loan.";

    case "lien_doc_category":
      return "The type of document that establishes the lien on a property.";
    case "date_of_death":
      return "The date when a listed property owner passes away listed on their Affadavit of Death. This is important in cases where the property is part of an estate that needs to be distributed to heirs or beneficiaries.";
    case "date_of_divorce":
      return "The date when a listed property owner passes away as noted on an Affadavit of Death.";
    case "unpaid_balance":
      return "The remaining amount of money that is still owed by a borrower to a lender for the lien on record.";
    case "lien_amount":
      return "This refers to the total amount of money that is owed by a property owner to a creditor or lender. If a property has a lien, it means there is a legal claim against the property as collateral for the debt owed.";
    case "lien_doc_number":
      return "A unique identifier assigned to a lien document.";
    case "lien_filing_date":
      return "The date when a lien document is officially filed with the appropriate local office or authority.";
    case "lien_doc_title":
      return "The title or name given to a lien document, usually indicating the type of lien or purpose of the document.";

    case "building_square_feet":
      return "The total finished area of the property in square feet (this commonly excludes garage space, attic space and unfinished basements).";
    case "year_built":
      return "The year the property was built.";
    case "total_bedrooms":
      return "The number of finished bedrooms.";
    case "total_baths":
      return "The number of finished bathrooms.";
    case "property_type":
      return "The type of property as determined by its use.";
    case "construction_type":
      return "Construction type refers to the type of construction material used when building a property.";
    case "building_style":
      return "This typically refers to the architectual style of the property.";
    case "effective_year_built":
      return "The year when updates and renovations, if any, were made to a property or the original build year if no updates have been made.";
    case "number_of_units":
      return "The quantity of individual living spaces in a multi-family property like apartment complexes. Each unit usually has a separate kitchen, bathroom(s), and bedrooms.";
    case "number_of_buildings":
      return "Refers to how many separate physical structures are on the property. This could include the primary residence, guest house, detached garage, barn, etc.";
    case "number_of_commercial_units":
      return "The count of separate commercial spaces in a property. These units are usually rented or leased to businesses in the case of commercial properties.";
    case "stories":
      return "The number of floors or levels that exist in the building. A single-story building has one floor, a two-story building has two floors, and so on.";
    case "garage_area":
      return "The square footage of the space designated as a garage.";
    case "heating_type":
      return "The system that is installed in the property for providing heat. Types could include central heating, forced air, radiators, etc.";
    case "heating_fuel":
      return "The type of fuel used to run the heating system. It could be electricity, gas, oil, propane, or something else.";
    case "air_conditioning":
      return "The system used in the property to maintain and control indoor temperature during hot periods. May include central air, window units, or none.";
    case "basement":
      return "This refers to an area that is either partially or fully below ground level. It can be finished (livable) or unfinished (used for storage or can be renovated for further use).";
    case "deck":
      return "An outdoor area that is usually made of wood or composite material and is connected to the house.";
    case "pool":
      return "A built-in feature of a property, outdoor or indoor. May be underground, above ground, and come in various sizes and shapes.";
    case "exterior_walls":
      return "The outer coverings of a building which protect it from the elements. Can be constructed from several materials such as brick, siding, stucco, or stone.";
    case "interior_walls":
      return "The walls within the building that partition rooms and sections. These can be made of various materials including drywall, plaster, or concrete.";
    case "number_of_fireplaces":
      return "The count of fireplaces in a property. They can be wood-burning, gas, etc.";
    case "floor_cover":
      return "The type of flooring installed throughout the property. This can include hardwood, carpet, tile, laminate, etc.";
    case "garage":
      return "The type of sheltered space usually used to park cars. This can be attached or detached from the main house.";
    case "driveway":
      return "The private road that leads to the garage or parking area of the property. Can be made of different materials like asphalt, gravel, concrete, etc.";
    case "amenities":
      return "Features of a property that provide comfort, convenience, or pleasure. This can include things like a pool, gym, clubhouse, ceiling fans, built-in appliances, etc.";
    case "other_rooms":
      return "This refers to rooms in the property other than typical ones like bedrooms, bathrooms and kitchen. It could include home office, playroom, sunroom, storage room etc.";
    case "patio":
      return "An outdoor space generally used for dining or recreation. It typically paved and is often adjacent to the house.";
    case "porch":
      return "A covered area attached to the exterior of a home, often enclosed by screens or windows.";
    case "roof_cover":
      return "The outermost layer of the roof which protects the property from weather, can be made of asphalt shingles, slate, metal, etc.";
    case "roof_type":
      return "Describes the design of a roof, this can include flat, gable, hip, mansard, etc.";
    case "sewer":
      return "Refers to the system by which wastewater is disposed of from the property. Can be connected to city sewer, a septic tank, or other systems.";
    case "water":
      return "Refers to the source of water supply for the property. This can either be a public water supply or a private well.";
    case "topography":
      return "The physical features of the property including its elevation, slope, orientation, and layout.";
    case "geographic_features":
      return "Factors that affect a property value due to its geographic location. This may include proximity to amenities, quality of local schools, crime rates, and economic trends.";

    case "parcel_id":
      return "A unique number that identifies a specific parcel of land for tax and legal purposes.";
    case "lot_acreage":
      return "The measurement of a property's total land area in acres.";
    case "legal_description":
      return "A detailed method of describing a parcel of land that is recognized by law. It includes references to lot numbers, block numbers, tract numbers, and recorded maps.";
    case "subdivision_name":
      return "The name given to an area of land that has been divided into individual lots for building purposes, typically residential.";
    case "property_class":
      return "The category a property is designated under for tax purposes. Examples are residential, commercial, agricultural, etc.";
    case "county_land_use_code":
      return "A classification used by local governments to refer to the specific use of a property, often for zoning and tax purposes.";
    case "county_name":
      return "The name of the county where the property is located.";
    case "census_tract":
      return "A geographic region defined for the purpose of taking a census. It usually comprises approximately 4,000 inhabitants.";
    case "lot_square_feet":
      return "The total area of a property measured in square feet.";
    case "lot_number":
      return "A number assigned to a specific plot of land in a subdivision. It's used for the legal description and identification of the property.";
    case "school_district_name":
      return "The name of the school district which serves this property";
    case "zoning":
      return "A set of regulations established by local government regulating what types of development and use may occur on a property typically written as a combination of letters and numbers.";
    case "flood_zone":
      return "An area designated by the Federal Emergency Management Agency (FEMA) that indicates the level of flood risk for a property.";
    case "tax_delinquent_info":
      return "This means the owner of the property has not paid the required property taxes and is late on payment. The length of delinquency can lead to a tax lien or even tax foreclosure. Note - this field is updated once a year and includes the last 2 years worth of data.";
    case "tax_delinquent_year":
      return "This refers to the year in which the property owner became delinquent in paying property taxes.";
    case "tax_year":
      return "The most recent year for which the property taxes were calculated andbilled.";
    case "tax_amount":
      return "This is the sum of money owed by the property owner to the government for property taxes.";

    case "assessment_year":
      return "The year in which a property was last appraised for its value by the local tax jurisdiction for taxation purposes.";
    case "total_assessed_value":
      return "This is the value given to a property by a public tax assessor for purposes of taxation.";
    case "assessed_land_value":
      return "The value of the land component of a given property as determined by the public tax assessor.";
    case "assessed_improvement_value":
      return "The assessed value of any improvements made on the property, such as buildings or other structures, as determined by the public tax assessor.";
    case "total_market_value":
      return "The estimated amount for which a property should exchange on the date of valuation between a willing buyer and a willing seller. This is typically caluclated by adding the market land value to the market improvement value.";
    case "market_land_value":
      return "This is the perceived value of the land component of a property if it were to be sold on the open market.";
    case "market_improvement_value":
      return "This is the perceived value of the improvements (i.e., the buildings or other structures) on the property if they were to be sold on the open market.";

    case "sale_price_estimated":
      return "This is an estimated sales price. Once the county discloses the actual sales price we will update this information.";
    case "sale_price_non_disclosure_state":
      return "This is an estimated sales price. This property is located in a non-disclosure state and all sales price information is estimated.";

    case "estimated_repair_cost":
      return "The approximated cost to repair or renovate a property to a sellable or rentable condition. This can vary greatly depending on local labor costs, the extent of damages or outdated elements, and desired quality of improvements.";
    case "building_condition":
      return "An assessment of the property's current state, including the structural integrity and the condition of major systems like plumbing, heating, and the roof. It's usually categorized as poor, fair, good, or excellent.";
    case "repair_cost_per_sqft":
      return "A calculation often used by real estate investors or builders to estimate the total repair cost. It's calculated by dividing the total estimated repair cost by the property's total square footage.";
    case "building_quality":
      return "This refers to the general standard of a building and its features. Factors can include the craftsmanship, materials used in construction, level of maintenance, appliance quality, etc. It's typically categorized on a scale A-E where A is the highest quality.";
    case "hoa_name":
      return "The official name of the Homeowners' Association that manages the community where the property is located.";
    case "hoa_type":
      return "The type or structure of the HOA. This could include types like PUD (Planned Unit Development), Condominium, Co-op, etc.";
    case "hoa_fee":
      return "The regular charge that homeowners must pay to the HOA for services provided. These services can include maintenance of common areas, trash removal, and others.";
    case "hoa_fee_frequency":
      return "The regularity at which the HOA fee is collected. This could be monthly, quarterly, or annually.";
    case "second_hoa_name":
      return "If a property is part of two HOAs, this would be the name of the second HOA.";
    case "second_hoa_type":
      return "The type or structure of the second HOA. This follows similar types as the primary HOA.";
    case "second_hoa_fee":
      return "The regular fee that homeowners must pay to the second HOA, if applicable.";
    case "second_hoa_fee_frequency":
      return "The frequency at which the second HOA fee is collected. Again, it could be monthly, quarterly, or annually.";

    //people data
    case "property_owner":
      return "This household owns 1 or more properties.";
    case "renter":
      return "This household currently rents a home.";
    case "business_owner":
      return "This household owns 1 or more businesses.";
    case "home_business":
      return "This household owns a business that is run from their home.";
    case "high_earner":
      return "This household earns a high income.";
    case "low_earner":
      return "This household earns a low income.";
    case "high_net_worth":
      return "This household has a high net worth.";
    case "house_flipper":
      return "This household has flipped 2 or more properties in the past 2 years.";
    case "senior":
      return "This person is 65 or older.";
    case "new_mover":
      return "Someone who has moved recently.";
    case "empty_nester":
      return "Properties whose owner(s) are 50 years or older and have no children currently living in the household. These individuals could be in the 'empty nester' stage of life, often considering downsizing or lifestyle changes.";
    case "likely_to_move":
      return "Properties whose owners or occupants are likely to move within the next 90 days.";

    case "primary_Decision_maker":
      return "The primary decision maker for this household.";
    case "elderly_parent":
      return "The contact is an elderly parent living in the household.";
    case "young_adult":
      return "The contact is a young adult living in the household.";

    case "potential_first_time_home_buyer":
      return "Indicates a potential first time homebuyer and the likelihood to open a new mortgage within the next three months.";
    case "debt_stressed":
    case "credit_risk":
      return "Properties whose owner(s) are likely to have sub prime credit.";
    case "marital_status":
      return "The likelihood of this person being married.";
    case "language_preference":
      return "The language this person prefers to speak.";
    case "mailing_address":
      return "The address where this person receives mail.";
    case "previous_address":
      return "The address where this person lived previously.";
    case "education":
      return "The highest level of education this person has completed.";
    case "occupation_group":
      return "A group that represents the type of occupation this person has.";
    case "occupation_code":
      return "The type of occupation this person has.";
    case "net_asset_value":
      return "The estimated total value of this household's assets minus their liabilities.";
    case "est_household_income":
      return "The estimated total income of this household.";

    case "real_estate_investor":
      return "This contact is an active real estate investor.";
    case "high_spender":
      return "An individual who is a higher than average spender.";

    case "default_date":
      return "The date when the property owner first missed a mortgage payment.";
    case "foreclosure_unpaid_balance":
      return "Unpaid principal amount of the loan as stated on the foreclosure document.";
    case "past_due_amount":
      return "Total amount of missed payments on the mortgage, including any late fees.";
    case "due_date":
      return "The date on which the missed payment was due.";
    case "foreclosure_lender_name":
      return "The name of the company that owns the loan.";
    case "notice_of_default_recording_date":
      return "The date on which the foreclosure document was officially recorded.";
    case "foreclosure_doc_type":
      return "The type of document filed, showing what step the foreclosure process is at.";
    case "auction_date":
      return "The date the property is set to be sold at foreclosure auction.";
    case "auction_time":
      return "What time the foreclosure auction begins.";
    case "auction_location":
      return "The address at which the property is set to be sold at foreclosure auction.";
    case "auction_city":
      return "The city where the foreclsoure auction is held.";
    case "auction_min_bid_amount":
      return "The lowest bid amount allowed at the property auction.";
    case "trustee_name":
      return "The person or company in charge of selling the property.";
    case "trustee_address":
      return "The mailing address for the person or company handling the sale.";
    case "trustee_phone":
      return "The phone number for the person or company handling the sale.";
    case "trustee_case_number":
      return "A special case number for the property's sale. You can use this number to help identify the foreclosure when communicating with the trustee.";
    case "position_president":
      return `The highest-ranking executive in a company, typically responsible for making major corporate decisions, managing overall operations, and acting as the main point of communication between the board of directors and corporate operations. This role is usually established in the company's bylaws and is a key part of the corporate structure.`;
    case "position_organizer":
      return `An individual or entity responsible for the initial formation of a company. They file the necessary documents (such as Articles of Organization for LLCs) with the state, establish the company's structure, and often serve as the initial registered agent. This role is crucial in the legal process of setting up a new business entity.`;
    case "position_secretary":
      return `A corporate officer responsible for maintaining the company's official records, managing corporate documents, and ensuring the company complies with relevant regulations. In the legal setup of a company, the secretary is often named in the Articles of Incorporation and plays a vital role in maintaining the company's good standing with state authorities.`;
    case "position_agent":
      return `Also known as a registered agent or statutory agent, this individual or business entity is designated to receive official papers and legal documents on behalf of the company. Having a registered agent is a legal requirement in most states for corporations and LLCs, serving as the official point of contact between the company and the state government.`;

    case "consumer_type":
      return "This field indicates at what level a household's financial history has been established.";
    case "spender_type":
      return "This field indicates the type of spenders live in this household.";
    case "card_balance":
      return "This field indicates at what balance level a household carries credit card balances.";
    case "investment_type":
      return "This field indicates what type of investments the household owns.";
    case "buying_power":
      return "This field indicates a household's ability to access their financial resources (cash, loans, etc.)";
    case "agg_credit_tier":
      return "This field indicates a household's estimated aggregate credit.";
    case "length_of_residence":
      return "The number of years a family has been residing at their current address (contact's mailing address).";
    case "household_member_type":
      return "This field indicates a person's role within the household. It is helpful in determining who best to contact about the property.";
    case "number_of_children":
      return "The number of children in the household.";
    case "household_size":
      return "The number of people in the household.";
    case "veteran":
      return "This field indicates there is a veteran living in the household.";
    case "active_military":
      return "This field indicates there is a person in the household who is currently serving in the military.";
    case "veteran_military":
      return "This field indicates there is a veteran or active military person in the household.";
    case "pet_owner":
      return "This field indicates whether or not a contact is likely to own a pet.";

    case "hoa_auction_date":
      return "Date of the aution for the foreclosure of the property due to the HOA/COA action.";
    case "hoa_lien_name":
      return "Name of the homeowner as seen on the lien document.";
    case "hoa_transaction_title":
      return "Type of document filed by the HOA, indicating the nature of the lien or foreclosure action (e.g., Notice of Default, Lis Pendens, Lien Notice).";
    case "hoa_lien_agency":
      return "Name of the HOA imposing the lien.";
    case "hoa_contact_company":
      return "Name of the company filing the lien.";
    case "hoa_contact_name":
      return "Name of the person at the company filing the lien.";
    case "hoa_address_mailing":
      return "Address of the company filing the lien.";
    case "hoa_contact_phone":
      return "Phone number of the company filing the lien.";
  }
};

export const getTransactionHelperDescription = helper_type => {
  switch (helper_type) {
    case "1":
      return `Transfers the seller's rights under a sales agreement to a new seller.`;
    case "2":
      return `Transfers a lessee's rights under a sublease to another party, typically used in rental agreements.`;
    case "3":
      return `Allows a current commercial tenant to transfer lease obligations to a new tenant.`;
    case "4":
      return `Issued by the estate administrator to transfer property ownership from an estate to the buyer.`;
    case "5":
      return `A sworn written statement made under oath, used to verify or attest to various facts in real estate transactions.`;
    case "6":
      return `A legal document outlining the terms of a property sale between a buyer and seller.`;
    case "7":
      return `Allows the original buyer in a sales agreement to transfer their rights and obligations to a new buyer.`;
    case "8":
      return `Transfers title or interest in real property; if for a condo, specifies the land use as condominium.`;
    case "9":
      return `Documents establishing the terms of a trust or certifying the trust's existence, specific to Los Angeles.`;
    case "10":
      return `Transfers a sublessee’s interest in a commercial lease to another party.`;
    case "11":
      return `Allows property to be automatically transferred to a named beneficiary upon the owner’s death, without probate.`;
    case "12":
      return `Transfers property ownership without warranties; often used in foreclosures or tax sales.`;
    case "13":
      return `A court-appointed commissioner transfers lease rights, typically in foreclosure or legal disputes.`;
    case "14":
      return `Transfers ownership of a condominium unit.`;
    case "15":
      return `Document used in transactions where the property is purchased outright with cash, without financing.`;
    case "16":
      return `A contract for the rental of commercial property.`;
    case "17":
      return `Used in probate-related transfers in North Carolina and foreclosure-related transfers in Hawaii.`;
    case "18":
      return `Terminates a sales agreement between a buyer and seller.`;
    case "19":
      return `Used to transfer property by a conservator on behalf of someone legally incapable of managing their own affairs.`;
    case "20":
      return `Transfers real estate owned by a corporation.`;
    case "21":
      return `Corrects errors in a previously recorded deed.`;
    case "22":
      return `A binding agreement between buyer and seller outlining the conditions for the sale of property.`;
    case "23":
      return `Documents the transfer of property ownership, often related to estate or trust administration.`;
    case "24":
      return `Used by an estate executor to distribute property to heirs or beneficiaries.`;
    case "25":
      return `A statement outlining specific rules, conditions, or instructions regarding property use.`;
    case "26":
      return `Allows property owners to name beneficiaries to receive property upon the owner's death, bypassing probate.`;
    case "27":
      return `The official document transferring property ownership from seller to buyer.`;
    case "28":
      return `Used by a legal guardian to transfer property on behalf of a ward.`;
    case "29":
      return `Certifies the death of a joint tenant, facilitating the transfer of property to the surviving tenant(s).`;
    case "30":
      return `Allows a borrower to transfer property ownership back to the lender instead of going through foreclosure.`;
    case "31":
      return `The sale of property under urgent conditions, typically at a reduced price.`;
    case "32":
      return `A transaction in which properties are traded between parties, often facilitated by a deferred tax agreement.`;
    case "33":
      return `Used by the executor of an estate to transfer property as directed by a will or probate court.`;
    case "34":
      return `The legal process by which a lender takes control of a property due to the borrower's failure to make loan payments.`;
    case "35":
      return `A deed issued by a trustee, executor, or other fiduciary transferring property to a beneficiary.`;
    case "36":
      return `Transfers property ownership with warranties against prior conveyances or encumbrances.`;
    case "37":
      return `Transfers property ownership without consideration, typically between family members.`;
    case "38":
      return `A long-term lease of land, allowing the tenant to develop the property.`;
    case "39":
      return `Transfers property owned by an individual.`;
    case "40":
      return `Transfers property between family members or dissolves a joint ownership arrangement.`;
    case "41":
      return `Establishes joint ownership with the right of survivorship, meaning property passes to surviving owners upon death.`;
    case "42":
      return `A document or decree by a court directing or affecting the ownership or use of property.`;
    case "43":
      return `Converts a leasehold interest to fee simple ownership in Hawaii, often through a sales agreement.`;
    case "44":
      return `A contract for the sale of land where the seller provides financing to the buyer.`;
    case "45":
      return `A contract for the rental of property in Hawaii.`;
    case "46":
      return `Transfers a lessee's rights under a lease to a new lessee.`;
    case "47":
      return `Involves converting a leasehold property into fee simple ownership through a sales agreement.`;
    case "48":
      return `A court system dealing with matters related to land ownership and disputes in Massachusetts.`;
    case "49":
      return `Offers limited protection against claims on property, typically covering only the period the seller owned the property.`;
    case "50":
      return `Issued under court order, often in divorce settlements or complex real estate distributions.`;
    case "51":
      return `A generic term for documents not specifically categorized elsewhere.`;
    case "52":
      return `Documents the sale of property to recover unpaid taxes, commonly used in Florida.`;
    case "53":
      return `Outlines the rights and responsibilities of partners in the ownership and operation of real estate.`;
    case "54":
      return `Transfers property from an estate to heirs or buyers, executed by the estate's personal representative.`;
    case "55":
      return `Transfers the seller's interest in property without warranties regarding the title.`;
    case "56":
      return `Documents the fulfillment of a land contract's terms in Wisconsin or the release from a sales agreement in Hawaii for fee simple property.`;
    case "57":
      return `Issued by a court-appointed receiver to transfer property as directed by the court.`;
    case "58":
      return `Allows property owners to reclaim ownership after a foreclosure sale by paying the sale amount plus interest and fees.`;
    case "59":
      return `Transfers property pursuant to a foreclosure sale, typically issued by a court-appointed referee in New York.`;
    case "60":
      return `Documents the fulfillment or termination of a sales agreement for leasehold property.`;
    case "61":
      return `A previously recorded document that is recorded again to correct an error or update information.`;
    case "62":
      return `A secondary agreement related to the sale of property, subordinate to the primary sales agreement.`;
    case "63":
      return `A lease agreement for commercial property in Hawaii, subordinate to the primary lease.`;
    case "64":
      return `Transfers property sold at a sheriff's auction, often due to foreclosure or to satisfy a court judgment.`;
    case "65":
      return `An agreement where the original tenant has the right to lease part or all of the property to another tenant, under the original lease terms.`;
    case "66":
      return `A document filed to declare the death of a trustee and identify the successor trustee.`;
    case "67":
      return `A deed where property ownership automatically transfers to the surviving owner(s) upon the death of another owner, avoiding probate.`;
    case "68":
      return `Offers guarantees against any property claims and liens only during the period the grantor owned the property.`;
    case "69":
      return `A deed issued by a trustee, often during a foreclosure sale, that transfers property ownership to the highest bidder.`;
    case "70":
      return `A lien on the property held by the seller for the unpaid purchase price, especially when the seller finances the purchase.`;
    case "71":
      return `Provides the highest level of buyer protection, guaranteeing the property is free from liens and encumbrances.`;
    case "72":
      return `A record of all transactions associated with a property, including sales, mortgages, and liens.`;
    case "73":
      return `Notification that a scheduled foreclosure process has been cancelled, stopping the sale of the property.`;
    case "74":
      return `Document issued after a foreclosure sale, indicating the buyer’s right to obtain the property title after redemption period.`;
    case "75":
      return `Legal order to change the date of a foreclosure sale to a new date.`;
    case "76":
      return `A court order authorizing the sale of the debtor's property to satisfy the outstanding debt.`;
    case "77":
      return `Official notification to a borrower that they have failed to meet their loan obligations, potentially leading to foreclosure.`;
    case "78":
      return `The initial legal filing by a lender to begin the foreclosure process on a property.`;
    case "79":
      return `Legal notice that a lawsuit involving a claim on the property has been filed, affecting the property’s title.`;
    case "80":
      return `Official withdrawal of a previously filed notice, such as a Notice of Default or Notice of Foreclosure.`;
    case "81":
      return `Public announcement detailing the foreclosure sale of a property, including time, date, and location.`;
    case "82":
      return `Notification of a public auction sale by a trustee, typically related to a deed of trust foreclosure.`;
    case "83":
      return `Legal document that removes a Lis Pendens notice, clearing the title.`;
    case "84":
      return `A corrected or amended Notice of Default that has been officially re-filed.`;
    case "85":
      return `A Final Judgment of Foreclosure that is re-recorded to correct or amend the original document.`;
    case "86":
      return `A Notice of Lis Pendens that has been officially re-filed to correct or update information.`;
    case "87":
      return `An Order of Dismissal that is re-recorded, often to correct or clarify the original dismissal.`;
    case "88":
      return `A Notice of Rescission that has been re-filed to officially cancel a previous notice.`;
    case "89":
      return `Foreclosure or trustee sale notifications that have been updated with new or rescheduled sale information.`;
    case "90":
      return `A Notice of Trustee's Sale that has been re-recorded to correct or update the original notice.`;
    case "91":
      return `Indicates a property in preforeclosure was sold before reaching the foreclosure auction stage.`;
    case "92":
      return `A Notice of Sale that has been officially re-recorded to correct or amend the original notice.`;
    case "93":
      return `A single, independent mortgage on a property, not associated with any other loans.`;
    case "94":
      return `The transfer of mortgage from one party to another, often when a loan is sold to a new lender.`;
    case "95":
      return `A sworn statement declaring that the original mortgage assignment document is lost or missing.`;
    case "96":
      return `An assignment document issued to correct errors in a previous mortgage assignment.`;
    case "97":
      return `Assigns a partial interest in a mortgage, often used in complex financial arrangements.`;
    case "98":
      return `A single document transferring several mortgages from one party to another.`;
    case "99":
      return `An assignment of mortgage that has been re-recorded to correct or clarify the original document.`;
    case "A":
      return `A document that changes the trustee under a deed of trust and assigns the deed to a new party.`;
    case "B":
      return `A document releasing the entire mortgage obligation, including a detailed description of the property.`;
    case "C":
      return `A document that releases several mortgages under a single filing.`;
    case "D":
      return `Releases a specific part of the property from the mortgage, with detailed property description.`;
    case "E":
      return `Releases a portion of the property from the mortgage without specifying property details.`;
    case "F":
      return `Fully releases the mortgage without detailing the property.`;
    case "G":
      return `Replaces the trustee under a deed of trust and conveys the property back to the borrower, clearing the lien.`;
    case "H":
      return `A legal document by which a property is used as security for the repayment of a loan.`;
    case "K":
      return `A record of all property sales, maintained by the local tax assessor’s office, used for valuation purposes.`;
    case "DT":
      return `A legal document declaring the death of a life tenant, affecting life estate arrangements.`;
    case "L":
      return `Transfers property ownership as part of the division of assets in a divorce or dissolution of marriage.`;
    case "M":
      return `A legal document declaring the death of a life tenant, affecting life estate arrangements.`;
    case "N":
      return `The sale of a property owned by a lender, typically after an unsuccessful foreclosure auction, known as a Real Estate Owned (REO) sale.`;
  }
};

export const encodeStringToInt = str => {
  let hexString = "";
  for (let i = 0; i < str.length; i++) {
    hexString += str.charCodeAt(i).toString(16).padStart(2, "0");
  }
  return hexString;
};

export const decodeIntToString = hexString => {
  let str = "";
  for (let i = 0; i < hexString.length; i += 2) {
    str += String.fromCharCode(parseInt(hexString.substr(i, 2), 16));
  }
  return str;
};
