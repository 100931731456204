import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  Wrapper,
  Row,
  Copy,
  InnerCopy,
  RangeSlider
} from "app/NativeComponents/common";
import {
  NewHeader,
  InputBox,
  GhostButton,
  SmallLabel,
  DateBox,
  InlineTabs,
  BottomNavBar,
  InlineButton,
  ConfirmLabel,
  CheckBoxInput
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  pushSidePanel,
  numberWithCommas,
  getDefaultCompsArray
} from "app/NativeActions";
import moment from "moment";
class FilterComps extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;
    this.state = {
      panel_data,
      sale_date_min: panel_data?.sale_date_min,
      sale_date_max: panel_data?.sale_date_max,
      sqft_min: panel_data?.sqft_min.toString(),
      sqft_max: panel_data?.sqft_max.toString(),
      bedroom_min: panel_data?.bedroom_min.toString(),
      bedroom_max: panel_data?.bedroom_max.toString(),
      bathroom_min: panel_data?.bathroom_min.toString(),
      bathroom_max: panel_data?.bathroom_max.toString(),
      year_built_min: panel_data?.year_built_min.toString(),
      year_built_max: panel_data?.year_built_max.toString(),
      range_miles: panel_data?.range_miles.toString(),
      sort_by: panel_data?.sort_by,
      source_type: panel_data?.source_type,
      dominant_source:
        panel_data?.source_type != "mls+county_records"
          ? panel_data?.source_type
          : panel_data?.dominant_source
    };

    this._sale_date_min = React.createRef();
    this._sale_date_max = React.createRef();

    this._sqft_min = React.createRef();
    this._sqft_max = React.createRef();

    this._bedroom_min = React.createRef();
    this._bedroom_max = React.createRef();
    this._bathroom_min = React.createRef();
    this._bathroom_max = React.createRef();
    this._year_built_min = React.createRef();
    this._year_built_max = React.createRef();

    this._range_miles = React.createRef();

    this.restoreToDefaults = this.restoreToDefaults.bind(this);
  }
  handleBack() {
    if (this.state.panel_data.handleBack) {
      this.state.panel_data.handleBack();
    }
    this.props.popSidePanel();
  }

  restoreToDefaults() {
    const default_comps_array = getDefaultCompsArray({
      property: this.state.panel_data?.property,
      comps_settings: this.props.comps_settings
    });

    this.setState({
      ...default_comps_array
    });
  }

  checkIfNeedToRestore() {
    const {
      sale_date_min,
      sale_date_max,
      sqft_min,
      sqft_max,
      bedroom_min,
      bedroom_max,
      bathroom_min,
      bathroom_max,
      year_built_min,
      year_built_max,
      range_miles,
      source_type,
      dominant_source
    } = this.state;
    const default_comps_array = getDefaultCompsArray({
      property: this.state.panel_data?.property,
      comps_settings: this.props.comps_settings
    });

    if (
      sale_date_min != default_comps_array.sale_date_min ||
      sale_date_max != default_comps_array.sale_date_max ||
      sqft_min != default_comps_array.sqft_min ||
      sqft_max != default_comps_array.sqft_max ||
      bedroom_min != default_comps_array.bedroom_min ||
      bedroom_max != default_comps_array.bedroom_max ||
      bathroom_min != default_comps_array.bathroom_min ||
      bathroom_max != default_comps_array.bathroom_max ||
      year_built_min != default_comps_array.year_built_min ||
      year_built_max != default_comps_array.year_built_max ||
      range_miles != default_comps_array.range_miles ||
      dominant_source != default_comps_array.dominant_source
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.checkIfNeedsToSave(this.state, prevState) &&
      !this.props.isMobile
    ) {
      this.updateFilters();
    }
  }

  updateFilters() {
    const { updateMainState } = this.state.panel_data;
    const {
      sale_date_min,
      sale_date_max,
      sqft_min,
      sqft_max,
      bedroom_min,
      bedroom_max,
      bathroom_min,
      bathroom_max,
      year_built_min,
      year_built_max,
      range_miles,
      source_type,
      dominant_source
    } = this.state;
    updateMainState({
      sale_date_min,
      sale_date_max,
      sqft_min,
      sqft_max,
      bedroom_min,
      bedroom_max,
      bathroom_min,
      bathroom_max,
      year_built_min,
      year_built_max,
      range_miles,
      source_type,
      dominant_source
    });
  }

  checkIfNeedsToSave(newState, prevState) {
    const {
      sale_date_min,
      sale_date_max,
      sqft_min,
      sqft_max,
      bedroom_min,
      bedroom_max,
      bathroom_min,
      bathroom_max,
      year_built_min,
      year_built_max,
      range_miles,
      source_type,
      dominant_source
    } = newState;
    if (
      sale_date_min != prevState.sale_date_min ||
      sale_date_max != prevState.sale_date_max ||
      sqft_min != prevState.sqft_min ||
      sqft_max != prevState.sqft_max ||
      bedroom_min != prevState.bedroom_min ||
      bedroom_max != prevState.bedroom_max ||
      bathroom_min != prevState.bathroom_min ||
      bathroom_max != prevState.bathroom_max ||
      year_built_min != prevState.year_built_min ||
      year_built_max != prevState.year_built_max ||
      range_miles != prevState.range_miles ||
      source_type != prevState.source_type ||
      dominant_source != prevState.dominant_source
    ) {
      return true;
    }
    return false;
  }

  handleSourceTypeChange = source_type => {
    if (this.state.source_type.includes(source_type)) {
      const newSourceType = this.state.source_type
        .split("+")
        .filter(x => x != source_type)[0];
      this.setState({
        source_type: newSourceType,
        dominant_source: newSourceType
      });
    } else {
      this.setState({
        source_type: "mls+county_records"
      });
    }
  };

  render() {
    if (this.state.panel_data) {
      const { property, updateMainState } = this.state?.panel_data;

      const {
        sale_date_min,
        sale_date_max,
        sqft_min,
        sqft_max,
        bedroom_min,
        bedroom_max,
        bathroom_min,
        bathroom_max,
        year_built_min,
        year_built_max,
        range_miles,
        source_type,
        dominant_source
      } = this.state;

      const { isMobile, colors, user, platform } = this.props;
      const isNative = platform != "desktop";
      return (
        <Container>
          {isMobile ? (
            <NewHeader
              title="Filter Comps"
              subtitle={"Filter comps for " + property.property_address}
              leftButton={{
                icon: "arrow-back",
                onPress: () => {
                  this.handleBack();
                }
              }}
              rightButton={{
                icon: "settings",
                tooltip: "Comps Default Settings",
                tooltipPlacement: "top",
                onPress: () => {
                  this.props.pushSidePanel({
                    slug: "comps_default_settings",
                    overlay: true,
                    data: {
                      onSave: this.restoreToDefaults
                    }
                  });
                }
              }}
            />
          ) : (
            <NewHeader
              title="Filter Comps"
              subtitle={"Filter comps for " + property.property_address}
              leftButton={{
                icon: "close",
                onPress: () => {
                  this.handleBack();
                },
                color: colors.text_color,
                hover_color: colors.hover_color
              }}
              rightButton={{
                icon: "settings",
                tooltip: "Comps Default Settings",
                tooltipPlacement: "top",
                onPress: () => {
                  this.props.pushSidePanel({
                    slug: "comps_default_settings",
                    overlay: true,
                    data: {
                      onSave: this.restoreToDefaults
                    }
                  });
                },
                color: colors.text_color,
                hover_color: colors.hover_color
              }}
              backgroundColor={colors.card_color}
              backgroundColor2={colors.card_color}
              title_color={colors.text_color}
            />
          )}

          {this.checkIfNeedsToSave(this.state, this.state.panel_data) &&
          this.props.isMobile ? (
            <ConfirmLabel
              style={{
                alignSelf: "stretch"
              }}
              icon="tune"
              label={"Filters Changed"}
              primary={true}
              confirmButton={"Confirm Changes"}
              onPress={() => {
                this.updateFilters();
                this.props.popSidePanel();
              }}
            />
          ) : null}
          <KeyboardView style={{ flex: 1 }}>
            <Wrapper
              style={{
                marginBottom: 0,
                alignSelf: "stretch",
                alignItems: "flex-start",
                justifyContent: "flex-start"
              }}
            >
              <Wrapper>
                <SmallLabel
                  style={{
                    padding: 15,
                    paddingTop: 10,
                    paddingBottom: 0
                  }}
                >
                  Data Source Included:
                </SmallLabel>
              </Wrapper>
              <Row>
                <CheckBoxInput
                  onPress={() => this.handleSourceTypeChange("mls")}
                  title={"MLS"}
                  value={
                    source_type == "mls" || source_type == "mls+county_records"
                  }
                  disabled={source_type == "mls"}
                  titleStyle={{ fontSize: 12 }}
                  innerStyle={
                    isNative ? { flex: 1, padding: 20 } : { padding: 20 }
                  }
                  style={isNative ? { flex: 1 } : {}}
                />
                <CheckBoxInput
                  onPress={() => this.handleSourceTypeChange("county_records")}
                  title={"County Records"}
                  value={
                    source_type == "county_records" ||
                    source_type == "mls+county_records"
                  }
                  disabled={source_type == "county_records"}
                  titleStyle={{ fontSize: 12 }}
                  innerStyle={
                    isNative ? { flex: 1, padding: 20 } : { padding: 20 }
                  }
                  style={isNative ? { flex: 1 } : {}}
                />
              </Row>
              <Wrapper>
                <SmallLabel
                  style={{
                    padding: 15,
                    paddingTop: 10,
                    paddingBottom: 0
                  }}
                >
                  When data is present from both MLS and County, which one do
                  you want to display in the list?
                </SmallLabel>
              </Wrapper>
              <Wrapper style={{ padding: 20 }}>
                <InlineTabs
                  selected_tab={dominant_source}
                  tabs={[
                    {
                      onPress: () => {
                        this.setState({
                          dominant_source: "mls"
                        });
                      },
                      title: "MLS",
                      slug: "mls",
                      disabled: source_type == "county_records"
                    },
                    {
                      onPress: () => {
                        this.setState({
                          dominant_source: "county_records"
                        });
                      },
                      title: "County Records",
                      slug: "county_records",
                      disabled: source_type == "mls"
                    }
                  ]}
                />
              </Wrapper>
            </Wrapper>
            <Row>
              <SmallLabel
                style={{
                  padding: 15,
                  paddingTop: 10,
                  paddingBottom: 0
                }}
              >
                Date of sale:
              </SmallLabel>
            </Row>
            <Row>
              <Wrapper style={{ flex: 1 }}>
                <DateBox
                  input_ref={this._sale_date_min}
                  name="sale_date_min"
                  placeholder="Min Date"
                  onFocus={() => {}}
                  onChange={value =>
                    this.setState({
                      sale_date_min: moment(value).format("YYYY-MM-DD")
                    })
                  }
                  value={
                    !!sale_date_min
                      ? moment(sale_date_min).format("MMM Do, YYYY")
                      : "--"
                  }
                  date_value={moment(sale_date_min).format("YYYY-MM-DD")}
                  style={{
                    borderRadius: 5,
                    margin: 5,
                    flex: 1
                  }}
                />
              </Wrapper>
              <Wrapper
                style={{
                  padding: 10,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Copy style={{ fontSize: 12 }}>to</Copy>
              </Wrapper>
              <Wrapper style={{ flex: 1 }}>
                <DateBox
                  input_ref={this._sale_date_max}
                  name="sale_date_max"
                  placeholder="Max Date"
                  onFocus={() => {}}
                  onChange={value =>
                    this.setState({
                      sale_date_max: moment(value).format("YYYY-MM-DD")
                    })
                  }
                  value={
                    !!sale_date_max
                      ? moment(sale_date_max).format("MMM Do, YYYY")
                      : "--"
                  }
                  date_value={moment(sale_date_max).format("YYYY-MM-DD")}
                  style={{
                    borderRadius: 5,
                    margin: 5,
                    flex: 1
                  }}
                />
              </Wrapper>
            </Row>

            <SmallLabel
              style={{
                padding: 15,
                paddingTop: 10,
                paddingBottom: 0
              }}
            >
              Square Feet
              {property.building_square_feet > 0 ? (
                <>
                  {"  "}
                  <InnerCopy
                    style={{
                      fontStyle: "italic",
                      fontWeight: "normal",
                      marginLeft: 5
                    }}
                  >
                    {numberWithCommas(property.building_square_feet)} sqft
                  </InnerCopy>
                </>
              ) : null}
            </SmallLabel>
            <Row>
              <InputBox
                input_ref={this._sqft_min}
                name="_sqft_min"
                returnKeyType="done"
                blurOnSubmit={true}
                keyboardType="numeric"
                placeholder="Sqft Min"
                onChange={value => {
                  this.setState({
                    sqft_min: value
                  });
                }}
                onSubmitEditing={() => {}}
                value={sqft_min}
                type="number"
                input_type="number"
                style={{
                  borderRadius: 5,
                  margin: 5,
                  flex: 1
                }}
                innerStyle={{ padding: 15 }}
              />
              <Wrapper
                style={{
                  padding: 10,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Copy style={{ fontSize: 12 }}>to</Copy>
              </Wrapper>
              <InputBox
                input_ref={this._sqft_max}
                name="_sqft_max"
                returnKeyType="done"
                blurOnSubmit={true}
                keyboardType="numeric"
                placeholder="Sqft Max"
                onChange={value => {
                  this.setState({
                    sqft_max: value
                  });
                }}
                onSubmitEditing={() => {}}
                value={sqft_max}
                type="number"
                input_type="number"
                style={{
                  borderRadius: 5,
                  margin: 5,
                  flex: 1
                }}
                innerStyle={{ padding: 15 }}
              />
            </Row>
            <SmallLabel
              style={{
                padding: 15,
                paddingTop: 10,
                paddingBottom: 0
              }}
            >
              Year Built:
              {!!property.year_built ? (
                <>
                  {"  "}
                  <InnerCopy
                    style={{
                      fontStyle: "italic",
                      fontWeight: "normal"
                    }}
                  >
                    {property.year_built}
                  </InnerCopy>
                </>
              ) : null}
            </SmallLabel>
            <Row>
              <InputBox
                input_ref={this._year_built_min}
                name="_year_built_min"
                returnKeyType="done"
                blurOnSubmit={true}
                keyboardType="numeric"
                placeholder="Year Built Min"
                onChange={value => {
                  this.setState({
                    year_built_min: value
                  });
                }}
                onSubmitEditing={() => {}}
                value={year_built_min}
                type="number"
                input_type="number"
                style={{
                  borderRadius: 5,
                  margin: 5,
                  flex: 1
                }}
                innerStyle={{ padding: 15 }}
              />
              <Wrapper
                style={{
                  padding: 10,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Copy style={{ fontSize: 12 }}>to</Copy>
              </Wrapper>
              <InputBox
                input_ref={this._year_built_max}
                name="_year_built_max"
                returnKeyType="done"
                blurOnSubmit={true}
                keyboardType="numeric"
                placeholder="Year Built Max"
                onChange={value => {
                  this.setState({
                    year_built_max: value
                  });
                }}
                onSubmitEditing={() => {}}
                value={year_built_max}
                type="number"
                input_type="number"
                style={{
                  borderRadius: 5,
                  margin: 5,
                  flex: 1
                }}
                innerStyle={{ padding: 15 }}
              />
            </Row>

            <SmallLabel
              style={{
                padding: 15,
                paddingTop: 10,
                paddingBottom: 0
              }}
            >
              Bedrooms:
              {property.total_bedrooms > 0 ? (
                <InnerCopy
                  style={{
                    fontStyle: "italic",
                    fontWeight: "normal"
                  }}
                >
                  {"  "}
                  {property.total_bedrooms == 1
                    ? "1 bedroom"
                    : property.total_bedrooms + " bedrooms"}
                </InnerCopy>
              ) : null}
            </SmallLabel>
            <Row>
              <InputBox
                input_ref={this._bedroom_min}
                name="_bedroom_min"
                returnKeyType="done"
                blurOnSubmit={true}
                keyboardType="numeric"
                placeholder="Bedroom Min"
                onChange={value => {
                  this.setState({
                    bedroom_min: value
                  });
                }}
                onSubmitEditing={() => {}}
                value={bedroom_min}
                type="number"
                input_type="number"
                style={{
                  borderRadius: 5,
                  margin: 5,
                  flex: 1
                }}
                innerStyle={{ padding: 15 }}
              />
              <Wrapper
                style={{
                  padding: 10,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Copy style={{ fontSize: 12 }}>to</Copy>
              </Wrapper>
              <InputBox
                input_ref={this._bedroom_max}
                name="_bedroom_max"
                returnKeyType="done"
                blurOnSubmit={true}
                keyboardType="numeric"
                placeholder="Bedroom Max"
                onChange={value => {
                  this.setState({
                    bedroom_max: value
                  });
                }}
                onSubmitEditing={() => {}}
                value={bedroom_max}
                type="number"
                input_type="number"
                style={{
                  borderRadius: 5,
                  margin: 5,
                  flex: 1
                }}
                innerStyle={{ padding: 15 }}
              />
            </Row>

            <SmallLabel
              style={{
                padding: 15,
                paddingTop: 10,
                paddingBottom: 0
              }}
            >
              Bathrooms:
              {property.total_baths > 0 ? (
                <>
                  {"  "}
                  <InnerCopy
                    style={{
                      fontStyle: "italic",
                      fontWeight: "normal"
                    }}
                  >
                    {Math.round(property?.total_baths * 100) / 100 == 1
                      ? "1 bathroom"
                      : Math.round(property?.total_baths * 100) / 100 +
                        " bathrooms"}
                  </InnerCopy>
                </>
              ) : null}
            </SmallLabel>
            <Row>
              <InputBox
                input_ref={this._bathroom_min}
                name="_bathroom_min"
                returnKeyType="done"
                blurOnSubmit={true}
                keyboardType="numeric"
                placeholder="Bathroom Min"
                onChange={value => {
                  this.setState({
                    bathroom_min: value
                  });
                }}
                onSubmitEditing={() => {}}
                value={bathroom_min}
                type="number"
                input_type="number"
                style={{
                  borderRadius: 5,
                  margin: 5,
                  flex: 1
                }}
                innerStyle={{ padding: 15 }}
              />
              <Wrapper
                style={{
                  padding: 10,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Copy style={{ fontSize: 12 }}>to</Copy>
              </Wrapper>
              <InputBox
                input_ref={this._bathroom_max}
                name="_bathroom_max"
                returnKeyType="done"
                blurOnSubmit={true}
                keyboardType="numeric"
                placeholder="Bathroom Max"
                onChange={value => {
                  this.setState({
                    bathroom_max: value
                  });
                }}
                onSubmitEditing={() => {}}
                value={bathroom_max}
                type="number"
                input_type="number"
                style={{
                  borderRadius: 5,
                  margin: 5,
                  flex: 1
                }}
                innerStyle={{ padding: 15 }}
              />
            </Row>

            <SmallLabel
              style={{
                padding: 15,
                paddingTop: 10,
                paddingBottom: 0
              }}
            >
              Range (miles):
            </SmallLabel>
            <InputBox
              input_ref={this._range_miles}
              name="_range_miles"
              returnKeyType="done"
              blurOnSubmit={true}
              keyboardType="numeric"
              placeholder="Miles"
              onChange={value => {
                if (parseInt(value) > 5) {
                  this.setState({
                    range_miles: 5
                  });
                } else {
                  this.setState({
                    range_miles: value
                  });
                }
              }}
              onSubmitEditing={() => {}}
              value={range_miles}
              type="number"
              input_type="number"
              style={{
                borderRadius: 5,
                margin: 5,
                flex: 1
              }}
              innerStyle={{ padding: 15 }}
            />
          </KeyboardView>
          {this.checkIfNeedToRestore() ? (
            <BottomNavBar>
              <InlineButton
                button_type="full"
                onPress={() => this.restoreToDefaults()}
              >
                Restore To Defaults
              </InlineButton>
            </BottomNavBar>
          ) : null}
        </Container>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, native, settings, billing, lead }) => {
  const { token, user } = auth;
  const { device, isMobile, platform } = native;
  const { colors } = settings;
  const { comps_settings } = lead;

  return { token, user, device, isMobile, colors, comps_settings, platform };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel
})(FilterComps);
