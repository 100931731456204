import React, { Component } from "react";
import { connect } from "react-redux";
import { Spinner } from "app/NativeComponents/common";

import { logout, startRedirect, updateUser } from "app/NativeActions";

import MoreInfoStep from "app/Workspaces/components/WorkspaceOnboarding/MoreInfoStep";
import CreditCardStep from "app/Workspaces/components/WorkspaceOnboarding/CreditCardStep";
import FinalStep from "app/Workspaces/components/WorkspaceOnboarding/FinalStep";
import CreatePasswordStep from "app/Workspaces/components/WorkspaceOnboarding/CreatePasswordStep";

class WorkspaceOnboarding extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { user, source_of_truth } = this.props;
    if (this.props.init && user) {
    } else if (this.props.loading) {
      return <Spinner />;
    }

    switch (user?.onboarding_stepthrough_step) {
      case "create_password_step":
      case "qualifying_questions":
      default:
        return <CreatePasswordStep />;

      case "more_info_step":
        return <MoreInfoStep />;

      case "credit_card_step":
        if (
          this.props.user?.workspace_settings?.should_team_handle_funds &&
          !source_of_truth?.has_stripe_card_on_file
        ) {
          return <CreditCardStep />;
        } else {
          return <FinalStep />;
        }

      case "final_step":
        return <FinalStep />;
    }

    return null;
  }
}

const mapStateToProps = ({ auth, billing, settings, native, marketing }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { source_of_truth } = billing;
  const { isMobile, device, initialRedirect } = native;
  const { visitor } = marketing;
  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect,
    source_of_truth,
    visitor
  };
};

export default connect(mapStateToProps, { logout, startRedirect, updateUser })(
  WorkspaceOnboarding
);
