import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  WebContainer,
  Wrapper,
  Title,
  Copy,
  Bold,
  Header1,
  Header3,
  Spinner,
  Page
} from "app/NativeComponents/common";
import {
  NewHeader,
  PillButton,
  InlineButton,
  GhostButton,
  OnboardingWrapper
} from "app/NativeComponents/snippets";
import {
  /*mobile*/
  toggleCanPop,
  appRedirect,
  updateUser,
  saveCard,
  logout,
  billingFieldChanged,
  authFieldChanged,
  updatePlan,
  resetSelectedPlan,
  resetBilling,
  selectPlan,
  togglePlanFrequency,
  dismissMobileKeyboard,
  displayIntercom,
  openUrl,
  AppConfig,
  loadOnboardingText,
  tabNavigation,
  manageSubscription
} from "app/NativeActions";

class WorkspaceCanceled extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_plans: false,
      contentful_content: null,
      contentful_loading: false,
      plan_interval: "month"
    };

    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {}

  handleBack() {
    this.props.logout({ token: this.props.token });
  }

  render() {
    const { contentful_content, plan_interval } = this.state;
    const { colors } = this.props;

    return (
      <Page
        metaTitle={
          "Your account has been canceled - DealMachine for Real Estate"
        }
        responsive_design={true}
      >
        <OnboardingWrapper>
          <Wrapper style={{ padding: 25 }}>
            <Header1 style={{ textAlign: "center" }}>
              <Bold>Your account has been canceled.</Bold>
            </Header1>

            <Header3 style={{ textAlign: "center" }}>
              Contact your customer success manager or sales rep to reactivate
              your account.
            </Header3>
          </Wrapper>
          <Wrapper
            style={{
              padding: 25,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <InlineButton
              id="intercom_button"
              onPress={() => displayIntercom()}
            >
              Talk To Support
            </InlineButton>
          </Wrapper>
        </OnboardingWrapper>
      </Page>
    );
  }
}

const mapStateToProps = ({ auth, native, billing, settings }) => {
  const { token, user, accepted_terms } = auth;
  const { colors } = settings;
  const { device, platform } = native;
  const { card, price, selected_plan, frequency, loading, source_of_truth } =
    billing;

  return {
    token,
    user,
    device,
    platform,
    card,
    price,
    selected_plan,
    frequency,
    loading,
    accepted_terms,
    source_of_truth,
    colors
  };
};

export default connect(mapStateToProps, {
  /*mobile*/
  toggleCanPop,

  appRedirect,

  updatePlan,
  updateUser,
  saveCard,
  logout,
  billingFieldChanged,
  authFieldChanged,
  resetSelectedPlan,
  resetBilling,
  selectPlan,
  tabNavigation,

  togglePlanFrequency,
  manageSubscription
})(WorkspaceCanceled);
