import {
  LOGOUT,
  GET_MAILER_CAMPAIGNS,
  GET_MAILER_CAMPAIGNS_SUCCESS,
  GET_MAILER_CAMPAIGNS_FAIL,
  REFRESH_MAILER_CAMPAIGNS,
  LOAD_MORE_MAILER_CAMPAIGNS,
  LOAD_MORE_MAILER_FAIL,
  LOAD_MORE_MAILER_CAMPAIGNS_SUCCESS,
  SET_ACTIVE_MAILER_CAMPAIGN,
  SET_ACTIVE_MAILER_CAMPAIGN_FAIL,
  SET_ACTIVE_MAILER_CAMPAIGN_SUCCESS,
  UPDATE_MAILER_CAMPAIGN,
  UPDATE_MAILER_CAMPAIGN_FAIL,
  UPDATE_MAILER_CAMPAIGN_SUCCESS,
  CREATE_MAILER_CAMPAIGN_SUCCESS,
  SET_START_MAILER_CAMPAIGN_PAGE,
  GET_LEAD_MAILER_CAMPAIGN,
  GET_LEAD_MAILER_CAMPAIGN_SUCCESS,
  GET_LEAD_MAILER_CAMPAIGN_FAIL,
  REMOVE_MAILER_CAMPIGN_SUCCESS,
  SET_DEFAULT_MAILER_CAMPAIGN_SUCCESS,
  GET_MAILER_TEMPLATES,
  GET_MAILER_TEMPLATES_FAIL,
  GET_MAILER_TEMPLATES_SUCCESS,
  REFRESH_MAILER_TEMPLATES,
  LOAD_MORE_MAILER_TEMPLATES,
  LOAD_MORE_MAILER_TEMPLATES_SUCCESS,
  SET_ACTIVE_MAILER_TEMPLATE,
  SET_ACTIVE_MAILER_TEMPLATE_SUCCESS,
  SET_ACTIVE_MAILER_TEMPLATE_FAIL,
  TOGGLE_MAILER_DESIGNER,
  TOGGLE_MAILER_SELECTOR,
  UPDATE_MAILER_TEMPLATE,
  UPDATE_MAILER_TEMPLATE_SUCCESS,
  UPDATE_MAILER_TEMPLATE_FAIL,
  CREATE_MAILER_TEMPLATE_SUCCESS,
  REMOVE_MAILER_TEMPLATE_SUCCESS,
  GET_MAILER_SIGNATURES,
  GET_MAILER_SIGNATURES_FAIL,
  GET_MAILER_SIGNATURES_SUCCESS,
  REFRESH_MAILER_SIGNATURES,
  LOAD_MORE_MAILER_SIGNATURES,
  LOAD_MORE_MAILER_SIGNATURES_SUCCESS,
  SET_ACTIVE_MAILER_SIGNATURE,
  SET_ACTIVE_MAILER_SIGNATURE_SUCCESS,
  SET_ACTIVE_MAILER_SIGNATURE_FAIL,
  UPDATE_MAILER_SIGNATURE,
  UPDATE_MAILER_SIGNATURE_SUCCESS,
  UPDATE_MAILER_SIGNATURE_FAIL,
  CREATE_MAILER_SIGNATURE_SUCCESS,
  REMOVE_MAILER_SIGNATURE_SUCCESS,
  GET_MAILER_DESIGN_TEMPLATES,
  GET_MAILER_DESIGN_TEMPLATES_FAIL,
  GET_MAILER_DESIGN_TEMPLATES_SUCCESS,
  SET_DEFAULT_MAILER_TEMPLATE_SUCCESS,
  SET_DEFAULT_MAILER_SIGNATURE_SUCCESS,
  SET_MAILER_VIEW,
  SET_MAILER_SELECTOR,
  GET_SENDING_QUEUE,
  GET_SENDING_QUEUE_FAIL,
  GET_SENDING_QUEUE_SUCCESS,
  LOAD_MORE_SENDING_QUEUE,
  LOAD_MORE_SENDING_QUEUE_SUCCESS,
  GET_SENDING_QUEUE_COUNTS_SUCCESS,
  UPDATE_LEAD_SUCCESS,
  SWITCH_MARKETING_TAB,
  SET_NEW_MAILER_CAMPAIGN,
} from "app/DealMachineCore/types";

const INITIAL_STATE = {
  mailer_campaigns: [],
  mailer_campaign_count: 0,

  mailer_campaigns_loading: false,
  mailer_campaigns_refreshing: false,
  mailer_campaigns_begin: 0,
  mailer_campaigns_limit: 25,
  mailer_campaigns_loaded_all: false,
  mailer_campaigns_error: "",

  active_mailer_campaign: null,
  active_mailer_campaign_loading: false,

  show_start_mailer_campaign_page: false,
  start_mailer_campaign_page: null,

  lead_mailer_campaign: null,
  lead_mailer_campaign_loading: false,

  mailer_templates: [],
  mailer_template_count: 0,
  default_bracket_variables: [],
  mailer_templates_loading: false,
  mailer_templates_refreshing: false,
  mailer_templates_begin: 0,
  mailer_templates_limit: 25,
  mailer_templates_loaded_all: false,
  mailer_templates_error: "",

  active_mailer_template: null,
  active_mailer_template_loading: false,
  toggle_mailer_designer: false,
  toggle_mailer_selector: false,
  mailer_signatures: [],
  mailer_signature_count: 0,
  mailer_signatures_loading: false,
  mailer_signatures_refreshing: false,
  mailer_signatures_begin: 0,
  mailer_signatures_limit: 25,
  mailer_signatures_loaded_all: false,
  mailer_signatures_error: "",
  active_mailer_signature: null,
  active_mailer_signature_loading: false,

  mailer_design_templates: [],
  mailer_design_templates_loading: false,

  active_mailer_view: null,
  active_mailer_view_flipped: false,

  mailer_selector: null,

  sending_queue: [],
  sending_queue_loading: false,
  sending_queue_begin: 0,
  sending_queue_limit: 25,
  sending_queue_loaded_all: false,
  sending_queue_error: "",

  sending_queue_counts: {
    sending_queue: 0,
    mailers_stuck: 0,
    scheduled_today: 0
  },
  marketing_tab: "campaigns",

  new_mailer_campaign_settings: {
    overlay: false,
    onSuccess: null,
    onBack: null
  },
  template_type_info: null
};

const updateMailerCampaign = (
  current_mailer_campaign,
  new_mailer_campaigns,
  reset_default = false
) => {
  for (let i = 0; i < new_mailer_campaigns.length; i++) {
    if (new_mailer_campaigns[i].id == current_mailer_campaign.id) {
      return new_mailer_campaigns[i];
    }
  }
  if (reset_default) {
    return {
      ...current_mailer_campaign,
      is_default_campaign: false
    };
  }
  return current_mailer_campaign;
};
const updateMailerTemplate = (
  current_mailer_template,
  new_mailer_templates,
  reset_default = false
) => {
  for (let i = 0; i < new_mailer_templates.length; i++) {
    if (new_mailer_templates[i].id == current_mailer_template.id) {
      return new_mailer_templates[i];
    }
  }

  if (reset_default) {
    return {
      ...current_mailer_template,
      is_default_template: false
    };
  }

  return current_mailer_template;
};

const updateMailerSignature = (
  current_mailer_signature,
  new_mailer_signatures,
  reset_default = false
) => {
  for (let i = 0; i < new_mailer_signatures.length; i++) {
    if (new_mailer_signatures[i].id == current_mailer_signature.id) {
      return new_mailer_signatures[i];
    }
  }

  if (reset_default) {
    return {
      ...current_mailer_signature,
      is_default_signature: false
    };
  }

  return current_mailer_signature;
};

const updateProperty = (originalProperty, newProperties) => {
  for (var i = 0; i < newProperties.length; i++) {
    if (originalProperty.property_id == newProperties[i].property_id) {
      return {
        ...newProperties[i]
      };
    }
  }
  return originalProperty;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE
      };
    case GET_MAILER_CAMPAIGNS:
      return {
        ...state,
        mailer_campaigns: [],
        mailer_campaigns_begin: 0,
        mailer_campaigns_error: "",
        mailer_campaigns_loading: true
      };

    case LOAD_MORE_MAILER_CAMPAIGNS:
      return {
        ...state,
        mailer_campaigns_error: "",
        mailer_campaigns_loading: true
      };
    case REFRESH_MAILER_CAMPAIGNS:
      return {
        ...state,
        mailer_campaigns_error: "",
        mailer_campaigns_loading: true,
        mailer_campaigns_refreshing: true,
        mailer_campaigns_begin: 0,
        mailer_campaigns: []
      };

    case GET_MAILER_CAMPAIGNS_FAIL:
      return {
        ...state,
        mailer_campaigns_error: action.payload,
        mailer_campaigns_loading: false,
        mailer_campaigns_refreshing: false
      };

    case GET_MAILER_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        mailer_campaigns_loading: false,
        mailer_campaigns_refreshing: false,
        mailer_campaigns: action.payload.mailer_campaigns,
        mailer_campaign_count: action.payload.mailer_campaign_count
          ? action.payload.mailer_campaign_count
          : state.mailer_campaign_count,
        mailer_campaigns_begin:
          state.mailer_campaigns_begin + action.payload.mailer_campaigns.length,
        mailer_campaigns_loaded_all:
          action.payload.mailer_campaigns.length < state.mailer_campaigns_limit
      };

    case LOAD_MORE_MAILER_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        mailer_campaigns_loading: false,
        mailer_campaigns_refreshing: false,
        mailer_campaigns: state.mailer_campaigns.concat(
          action.payload.mailer_campaigns
        ),
        mailer_campaign_count: action.payload.mailer_campaign_count
          ? action.payload.mailer_campaign_count
          : state.mailer_campaign_count,
        mailer_campaigns_begin:
          state.mailer_campaigns_begin + action.payload.mailer_campaigns.length,
        mailer_campaigns_loaded_all:
          action.payload.mailer_campaigns.length < state.mailer_campaigns_limit
      };
    case SET_ACTIVE_MAILER_CAMPAIGN:
      return {
        ...state,
        active_mailer_campaign: action.payload,
        active_mailer_campaign_loading: action.payload ? true : false
      };
    case SET_ACTIVE_MAILER_CAMPAIGN_FAIL:
      return {
        ...state,
        active_mailer_campaign_loading: false
      };
    case SET_ACTIVE_MAILER_CAMPAIGN_SUCCESS:
      return {
        ...state,
        active_mailer_campaign:
          !state.active_mailer_campaign ||
          action.payload?.mailer_campaign?.id ==
            state.active_mailer_campaign?.id
            ? action.payload.mailer_campaign
            : state.active_mailer_campaign,
        active_mailer_campaign_loading: false
      };

    case UPDATE_MAILER_CAMPAIGN_SUCCESS:
      return {
        ...state,
        active_mailer_campaign:
          state.active_mailer_campaign && action.payload.mailer_campaigns
            ? updateMailerCampaign(
                state.active_mailer_campaign,
                action.payload.mailer_campaigns
              )
            : state.active_mailer_campaign,
        mailer_campaigns: action.payload.mailer_campaigns
          ? state.mailer_campaigns.map(campaign => {
              return updateMailerCampaign(
                campaign,
                action.payload.mailer_campaigns
              );
            })
          : state.mailer_campaigns
      };
    case SET_DEFAULT_MAILER_CAMPAIGN_SUCCESS:
      return {
        ...state,
        active_mailer_campaign:
          state.active_mailer_campaign && action.payload.mailer_campaigns
            ? updateMailerCampaign(
                state.active_mailer_campaign,
                action.payload.mailer_campaigns,
                true
              )
            : state.active_mailer_campaign,
        mailer_campaigns: action.payload.mailer_campaigns
          ? state.mailer_campaigns.map(campaign => {
              return updateMailerCampaign(
                campaign,
                action.payload.mailer_campaigns,
                true
              );
            })
          : state.mailer_campaigns
      };

    case CREATE_MAILER_CAMPAIGN_SUCCESS:
      return {
        ...state,
        active_mailer_campaign: action.payload.mailer_campaigns
          ? action.payload.mailer_campaigns[0]
          : state.active_mailer_campaign,
        mailer_campaigns: [
          action.payload.mailer_campaigns[0],
          ...state.mailer_campaigns
        ],
        mailer_campaign_count: action.payload.mailer_campaign_count
          ? action.payload.mailer_campaign_count
          : state.mailer_campaign_count
      };
    case REMOVE_MAILER_CAMPIGN_SUCCESS:
      return {
        ...state,
        active_mailer_campaign:
          state.active_mailer_campaign?.id == action.payload.mailer_campaign_id
            ? null
            : state.active_mailer_campaign,

        mailer_campaigns: action.payload.mailer_campaign_id
          ? state.mailer_campaigns.filter(
              ({ id }) => id != action.payload.mailer_campaign_id
            )
          : state.mailer_campaigns,
        mailer_campaign_count: action.payload.mailer_campaign_count
          ? action.payload.mailer_campaign_count
          : state.mailer_campaign_count
      };
    case SET_START_MAILER_CAMPAIGN_PAGE:
      return {
        ...state,
        start_mailer_campaign_page: action.payload
      };

    case GET_LEAD_MAILER_CAMPAIGN:
      return {
        ...state,
        lead_mailer_campaign: null,
        lead_mailer_campaign_loading: true
      };
    case GET_LEAD_MAILER_CAMPAIGN_FAIL:
      return {
        ...state,
        lead_mailer_campaign_loading: false
      };
    case GET_LEAD_MAILER_CAMPAIGN_SUCCESS:
      return {
        ...state,
        lead_mailer_campaign: action.payload,
        lead_mailer_campaign_loading: false
      };

    case GET_MAILER_TEMPLATES:
      return {
        ...state,
        mailer_templates: [],
        mailer_templates_begin: 0,
        mailer_templates_error: "",
        mailer_templates_loading: true
      };

    case LOAD_MORE_MAILER_TEMPLATES:
      return {
        ...state,
        mailer_templates_error: "",
        mailer_templates_loading: true
      };
    case REFRESH_MAILER_TEMPLATES:
      return {
        ...state,
        mailer_templates_error: "",
        mailer_templates_loading: true,
        mailer_templates_refreshing: true,
        mailer_templates_begin: 0,
        mailer_templates: []
      };

    case GET_MAILER_TEMPLATES_FAIL:
      return {
        ...state,
        mailer_templates_error: action.payload,
        mailer_templates_loading: false,
        mailer_templates_refreshing: false
      };

    case GET_MAILER_TEMPLATES_SUCCESS:
      return {
        ...state,
        mailer_templates_loading: false,
        mailer_templates_refreshing: false,
        mailer_templates: action.payload.mailer_templates,
        mailer_templates_begin:
          state.mailer_templates_begin + action.payload.mailer_templates.length,
        mailer_templates_loaded_all:
          action.payload.mailer_templates.length < state.mailer_templates_limit,
        mailer_template_count: action.payload.mailer_template_count
          ? action.payload.mailer_template_count
          : state.mailer_template_count,
        default_bracket_variables: action.payload.default_bracket_variables
          ? action.payload.default_bracket_variables
          : state.default_bracket_variables
      };

    case LOAD_MORE_MAILER_TEMPLATES_SUCCESS:
      return {
        ...state,
        mailer_templates_loading: false,
        mailer_templates_refreshing: false,
        mailer_templates: state.mailer_templates.concat(
          action.payload.mailer_templates
        ),
        mailer_templates_begin:
          state.mailer_templates_begin + action.payload.mailer_templates.length,
        mailer_templates_loaded_all:
          action.payload.mailer_templates.length < state.mailer_templates_limit,
        mailer_template_count: action.payload.mailer_template_count
          ? action.payload.mailer_template_count
          : state.mailer_template_count,
        default_bracket_variables: action.payload.default_bracket_variables
          ? action.payload.default_bracket_variables
          : state.default_bracket_variables
      };

    case SET_ACTIVE_MAILER_TEMPLATE:
      return {
        ...state,
        active_mailer_template: action.payload,
        active_mailer_template_loading: action.payload ? true : false
      };
    case SET_ACTIVE_MAILER_TEMPLATE_FAIL:
      return {
        ...state,
        active_mailer_template_loading: false
      };
    case SET_ACTIVE_MAILER_TEMPLATE_SUCCESS:
      return {
        ...state,
        active_mailer_template:
          !state.active_mailer_template ||
          action.payload?.mailer_template?.id ==
            state.active_mailer_template?.id
            ? action.payload.mailer_template
            : state.active_mailer_template,
        active_mailer_template_loading: false
      };

    case UPDATE_MAILER_TEMPLATE_SUCCESS:
      return {
        ...state,
        active_mailer_template:
          state.active_mailer_template && action.payload.mailer_templates
            ? updateMailerTemplate(
                state.active_mailer_template,
                action.payload.mailer_templates
              )
            : state.active_mailer_template,
        mailer_templates: action.payload.mailer_templates
          ? state.mailer_templates.map(template => {
              return updateMailerTemplate(
                template,
                action.payload.mailer_templates
              );
            })
          : state.mailer_templates
      };

    case CREATE_MAILER_TEMPLATE_SUCCESS:
      return {
        ...state,
        active_mailer_template: action.payload.mailer_templates
          ? action.payload.mailer_templates[0]
          : state.active_mailer_template,
        mailer_templates: [
          action.payload.mailer_templates[0],
          ...state.mailer_templates
        ],
        mailer_template_count: action.payload.mailer_template_count
          ? action.payload.mailer_template_count
          : state.mailer_template_count,
        default_bracket_variables: action.payload.default_bracket_variables
          ? action.payload.default_bracket_variables
          : state.default_bracket_variables
      };
    case REMOVE_MAILER_TEMPLATE_SUCCESS:
      return {
        ...state,
        active_mailer_template:
          state.active_mailer_template?.id == action.payload.mailer_template_id
            ? null
            : state.active_mailer_template,
        mailer_templates: action.payload.mailer_template_id
          ? state.mailer_templates.filter(
              ({ id }) => id != action.payload.mailer_template_id
            )
          : state.mailer_templates,
        mailer_template_count: action.payload.mailer_template_count
          ? action.payload.mailer_template_count
          : state.mailer_template_count,
        default_bracket_variables: action.payload.default_bracket_variables
          ? action.payload.default_bracket_variables
          : state.default_bracket_variables
      };

    case TOGGLE_MAILER_DESIGNER:
      return {
        ...state,
        toggle_mailer_designer: action.payload
      };
    case TOGGLE_MAILER_SELECTOR:
      return {
        ...state,
        toggle_mailer_selector: action.payload
      };
    case GET_MAILER_SIGNATURES:
      return {
        ...state,
        mailer_signatures: [],
        mailer_signatures_begin: 0,
        mailer_signatures_error: "",
        mailer_signatures_loading: true
      };

    case LOAD_MORE_MAILER_SIGNATURES:
      return {
        ...state,
        mailer_signatures_error: "",
        mailer_signatures_loading: true,
        mailer_signature_count: action.payload.mailer_signature_count
          ? action.payload.mailer_signature_count
          : state.mailer_signature_count
      };
    case REFRESH_MAILER_SIGNATURES:
      return {
        ...state,
        mailer_signatures_error: "",
        mailer_signatures_loading: true,
        mailer_signatures_refreshing: true,
        mailer_signatures_begin: 0,
        mailer_signatures: []
      };

    case GET_MAILER_SIGNATURES_FAIL:
      return {
        ...state,
        mailer_signatures_error: action.payload,
        mailer_signatures_loading: false,
        mailer_signatures_refreshing: false
      };

    case GET_MAILER_SIGNATURES_SUCCESS:
      return {
        ...state,
        mailer_signatures_loading: false,
        mailer_signatures_refreshing: false,
        mailer_signatures: action.payload.mailer_signatures,
        mailer_signatures_begin:
          state.mailer_signatures_begin +
          action.payload.mailer_signatures?.length,
        mailer_signatures_loaded_all:
          action.payload.mailer_signatures?.length <
          state.mailer_signatures_limit,
        mailer_signature_count: action.payload.mailer_signature_count
          ? action.payload.mailer_signature_count
          : state.mailer_signature_count
      };

    case LOAD_MORE_MAILER_SIGNATURES_SUCCESS:
      return {
        ...state,
        mailer_signatures_loading: false,
        mailer_signatures_refreshing: false,
        mailer_signatures: state.mailer_signatures.concat(
          action.payload.mailer_signatures
        ),
        mailer_signatures_begin:
          state.mailer_signatures_begin +
          action.payload.mailer_signatures?.length,
        mailer_signatures_loaded_all:
          action.payload.mailer_signatures?.length <
          state.mailer_signatures_limit,
        mailer_signature_count: action.payload.mailer_signature_count
          ? action.payload.mailer_signature_count
          : state.mailer_signature_count
      };

    case SET_ACTIVE_MAILER_SIGNATURE:
      return {
        ...state,
        active_mailer_signature: action.payload,
        active_mailer_signature_loading: action.payload ? true : false
      };
    case SET_ACTIVE_MAILER_SIGNATURE_FAIL:
      return {
        ...state,
        active_mailer_signature_loading: false
      };
    case SET_ACTIVE_MAILER_SIGNATURE_SUCCESS:
      return {
        ...state,
        active_mailer_signature:
          !state.active_mailer_signature ||
          action.payload?.mailer_signature?.id ==
            state.active_mailer_signature?.id
            ? action.payload.mailer_signature
            : state.active_mailer_signature,
        active_mailer_signature_loading: false
      };

    case UPDATE_MAILER_SIGNATURE_SUCCESS:
      return {
        ...state,
        active_mailer_signature:
          state.active_mailer_signature && action.payload.mailer_signatures
            ? updateMailerSignature(
                state.active_mailer_signature,
                action.payload.mailer_signatures
              )
            : state.active_mailer_signature,
        mailer_signatures: action.payload.mailer_signatures
          ? state.mailer_signatures.map(signature => {
              return updateMailerSignature(
                signature,
                action.payload.mailer_signatures
              );
            })
          : state.mailer_signatures
      };

    case CREATE_MAILER_SIGNATURE_SUCCESS:
      return {
        ...state,

        active_mailer_signature: action.payload.mailer_signatures
          ? action.payload.mailer_signatures[0]
          : state.active_mailer_signature,
        mailer_signatures: [
          action.payload.mailer_signatures[0],
          ...state.mailer_signatures
        ],
        mailer_signature_count: action.payload.mailer_signature_count
          ? action.payload.mailer_signature_count
          : state.mailer_signature_count
      };
    case REMOVE_MAILER_SIGNATURE_SUCCESS:
      return {
        ...state,
        active_mailer_signature:
          state.active_mailer_signature?.id ==
          action.payload.mailer_signature_id
            ? null
            : state.active_mailer_signature,
        mailer_signatures: action.payload.mailer_signature_id
          ? state.mailer_signatures.filter(
              ({ id }) => id != action.payload.mailer_signature_id
            )
          : state.mailer_signatures,
        mailer_signature_count: action.payload.mailer_signature_count
          ? action.payload.mailer_signature_count
          : state.mailer_signature_count
      };
    case GET_MAILER_DESIGN_TEMPLATES:
      return {
        ...state,
        mailer_design_templates: [],
        mailer_design_templates_loading: true,
        template_type_info: null
      };

    case GET_MAILER_DESIGN_TEMPLATES_FAIL:
      return {
        ...state,
        mailer_design_templates_loading: false
      };

    case GET_MAILER_DESIGN_TEMPLATES_SUCCESS:
      return {
        ...state,
        mailer_design_templates: action.payload.mailer_design_templates,
        template_type_info: action.payload.template_type_info,
        mailer_design_templates_loading: false
      };

    case SET_DEFAULT_MAILER_TEMPLATE_SUCCESS:
      return {
        ...state,
        active_mailer_template:
          state.active_mailer_template && action.payload.mailer_templates
            ? updateMailerTemplate(
                state.active_mailer_template,
                action.payload.mailer_templates,
                true
              )
            : state.active_mailer_template,
        mailer_templates: action.payload.mailer_templates
          ? state.mailer_templates.map(template => {
              return updateMailerTemplate(
                template,
                action.payload.mailer_templates,
                true
              );
            })
          : state.mailer_templates
      };

    case SET_DEFAULT_MAILER_SIGNATURE_SUCCESS:
      return {
        ...state,
        active_mailer_signature:
          state.active_mailer_signature && action.payload.mailer_signatures
            ? updateMailerSignature(
                state.active_mailer_signature,
                action.payload.mailer_signatures,
                true
              )
            : state.active_mailer_signature,
        mailer_signatures: action.payload.mailer_signatures
          ? state.mailer_signatures.map(signature => {
              return updateMailerSignature(
                signature,
                action.payload.mailer_signatures,
                true
              );
            })
          : state.mailer_signatures
      };
    case SET_MAILER_VIEW:
      return {
        ...state,
        active_mailer_view: action.payload.mailer,
        active_mailer_view_flipped: action.payload.flipped
      };
    case SET_MAILER_SELECTOR:
      return {
        ...state,
        mailer_selector: action.payload
      };

    case GET_SENDING_QUEUE:
      return {
        ...state,
        sending_queue: [],
        sending_queue_error: "",
        sending_queue_loading: true
      };

    case LOAD_MORE_SENDING_QUEUE:
      return {
        ...state,
        sending_queue_error: "",
        sending_queue_loading: true
      };

    case GET_SENDING_QUEUE_FAIL:
      return {
        ...state,
        sending_queue_error: action.payload,
        sending_queue_loading: false
      };

    case GET_SENDING_QUEUE_SUCCESS:
      return {
        ...state,
        sending_queue_loading: false,
        sending_queue: action.payload.sending_queue,
        sending_queue_begin:
          state.sending_queue_begin + action.payload.sending_queue.length,
        sending_queue_loaded_all:
          action.payload.sending_queue.length < state.sending_queue_limit
      };

    case LOAD_MORE_SENDING_QUEUE_SUCCESS:
      return {
        ...state,
        sending_queue_loading: false,
        sending_queue: state.sending_queue.concat(action.payload.sending_queue),
        sending_queue_begin:
          state.sending_queue_begin + action.payload.sending_queue.length,
        sending_queue_loaded_all:
          action.payload.sending_queue.length < state.sending_queue_limit
      };

    case GET_SENDING_QUEUE_COUNTS_SUCCESS:
      return {
        ...state,
        sending_queue_counts: action.payload.sending_queue_counts
      };

    case UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        sending_queue: action.payload.properties
          ? state.sending_queue.map((property, i) =>
              updateProperty(property, action.payload.properties)
            )
          : state.sending_queue
      };
    case SWITCH_MARKETING_TAB:
      return {
        ...state,
        marketing_tab: action.payload
      };
    case SET_NEW_MAILER_CAMPAIGN:
      return {
        ...state,
        new_mailer_campaign_settings: action.payload
      };

    default:
      return state;
  }
};
