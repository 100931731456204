import React, { Component } from "react";

import { Card, Wrapper } from "app/NativeComponents/common";
import { ToggleSwitch, SelectItem } from "app/NativeComponents/snippets";
import { checkIfUserHasMetadata } from "app/NativeActions";
class PermissionSettings extends Component {
  render() {
    return (
      <>
        <SelectItem
          select_type="toggle"
          selected={
            this.props.edit_team_member.can_see_dashboard == 1 ? true : false
          }
          description={"This user will be able to view the dashboard."}
          onPress={() => {
            this.props.editTeamMember({
              prop: "can_see_dashboard",
              value: this.props.edit_team_member.can_see_dashboard == 1 ? 0 : 1
            });
          }}
        >
          Can see dashboard?
        </SelectItem>
        <SelectItem
          select_type="toggle"
          selected={
            this.props.edit_team_member.can_see_all_deals == 1 ? true : false
          }
          onPress={() => {
            this.props.editTeamMember({
              prop: "can_see_all_deals",
              value: this.props.edit_team_member.can_see_all_deals == 1 ? 0 : 1
            });
          }}
          description={
            "This user will be able to view and edit all properties from your team."
          }
        >
          Can see other team members' properties?
        </SelectItem>

        <SelectItem
          select_type="toggle"
          selected={
            this.props.edit_team_member.can_build_lists == 1 ? true : false
          }
          onPress={() => {
            this.props.editTeamMember({
              prop: "can_build_lists",
              value: this.props.edit_team_member.can_build_lists == 1 ? 0 : 1
            });
          }}
          description={
            "This user will be able to build lists, including using AI Vision Builder."
          }
        >
          Can build lists?
        </SelectItem>

        <SelectItem
          select_type="toggle"
          selected={
            this.props.edit_team_member.can_approve_mail == 1 ? true : false
          }
          onPress={() => {
            this.props.editTeamMember({
              prop: "can_approve_mail",
              value: this.props.edit_team_member.can_approve_mail == 1 ? 0 : 1
            });
          }}
          description={"This user will be able to start mail."}
        >
          Can start mail?
        </SelectItem>

        <SelectItem
          select_type="toggle"
          selected={
            this.props.edit_team_member.can_send_message == 1 ? true : false
          }
          onPress={() => {
            this.props.editTeamMember({
              prop: "can_send_message",
              value: this.props.edit_team_member.can_send_message == 1 ? 0 : 1
            });
          }}
          description={
            "This user will be able use the Dialer to call prospects."
          }
        >
          Can use dialer?
        </SelectItem>

        <SelectItem
          select_type="toggle"
          selected={
            this.props.edit_team_member.can_edit_templates == 1 ? true : false
          }
          onPress={() => {
            this.props.editTeamMember({
              prop: "can_edit_templates",
              value: this.props.edit_team_member.can_edit_templates == 1 ? 0 : 1
            });
          }}
          description={
            "The user will be able to edit existing templates/mail sequences  and create new ones."
          }
        >
          Can edit and create mail sequences and mail designs?
        </SelectItem>

        <SelectItem
          select_type="toggle"
          selected={
            this.props.edit_team_member.can_export_data == 1 ? true : false
          }
          onPress={() => {
            this.props.editTeamMember({
              prop: "can_export_data",
              value: this.props.edit_team_member.can_export_data == 1 ? 0 : 1
            });
          }}
          description={
            "This user will be export any data collected through DealMachine via the Web Application"
          }
        >
          Can export data?
        </SelectItem>

        <SelectItem
          select_type="toggle"
          selected={
            this.props.edit_team_member.can_edit_custom_fields == 1
              ? true
              : false
          }
          onPress={() => {
            this.props.editTeamMember({
              prop: "can_edit_custom_fields",
              value:
                this.props.edit_team_member.can_edit_custom_fields == 1 ? 0 : 1
            });
          }}
          description={
            "This user will be able to edit custom fields and statuses for leads."
          }
        >
          Can edit custom fields & statuses?
        </SelectItem>

        <SelectItem
          select_type="toggle"
          selected={
            this.props.edit_team_member.can_mimic_team_owner == 1 ? true : false
          }
          onPress={() => {
            this.props.editTeamMember({
              prop: "can_mimic_team_owner",
              value:
                this.props.edit_team_member.can_mimic_team_owner == 1 ? 0 : 1
            });
          }}
          description={
            "Team member can access credit card, invoices and add/remove team members."
          }
        >
          Can edit team information?
        </SelectItem>
      </>
    );
  }
}

export default PermissionSettings;
