import React, { Component } from "react";
import { Wrapper, Icon, Copy, Bold } from "app/NativeComponents/common";
import { CloseButton } from "app/NativeComponents/snippets";
import { store } from "app/store";

class AttentionBox extends Component {
  render() {
    const {
      style,
      primary = false,
      icon,
      fa_icon,
      includeCloseButton = false,
      onClose = () => {},
      title,
      description,
      renderDescription = null,
      backgroundColor = null,
      textColor = null,
      row = false,
      closeButtonStyle = {}
    } = this.props;
    const colors = store.getState().settings.colors;

    const background_color = backgroundColor
      ? backgroundColor
      : primary
      ? colors.orange_color_muted
      : colors.active_color_muted;
    const text_color = textColor
      ? textColor
      : primary
      ? colors.orange_color
      : colors.actionable_text_color;

    return (
      <Wrapper
        style={{
          borderRadius: 5,
          padding: 25,
          margin: 5,
          backgroundColor: background_color,
          position: "relative",
          flexDirection: row ? "row" : "column",
          ...style
        }}
      >
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {!!icon || !!fa_icon ? (
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 10,
                width: 20,
                height: 20,
                backgroundColor: text_color,
                margin: 5
              }}
            >
              <Icon
                icon={icon}
                fa_icon={fa_icon}
                color={background_color}
                size={14}
              />
            </Wrapper>
          ) : null}
        </Wrapper>
        <Wrapper style={row ? { flex: 1 } : {}}>
          {!!title ? (
            <Copy style={{ color: text_color }}>
              <Bold>{title}</Bold>
            </Copy>
          ) : null}
          {renderDescription ? (
            renderDescription()
          ) : !!description ? (
            <Copy>{description}</Copy>
          ) : null}
        </Wrapper>
        {includeCloseButton ? (
          <CloseButton
            style={{ padding: 0, margin: 0 }}
            onPress={onClose}
            iconStyle={closeButtonStyle}
          />
        ) : null}
      </Wrapper>
    );
  }
}

export { AttentionBox };
