import React, { PureComponent } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Copy,
  Bold,
  Row,
  ExternalImage,
  Button,
  Icon,
  Spin
} from "app/NativeComponents/common";

import {
  GhostButton,
  IconButton,
  UpsellButton
} from "app/NativeComponents/snippets";
import { store } from "app/store";
import {
  toggleModal,
  setModal,
  updateLead,
  setStartMailerCampaignPage,
  formatAddress,
  determineDisplayProperty,
  pushSidePanel,
  setPhotoGallery,
  togglePhotoGallery,
  checkIfUserHasBillingAddon,
  checkIfUserHasMetadata
} from "app/NativeActions";
import LeadMainPhoto from "app/DealMachineCore/reuseable/LeadMainPhoto";

class MainColumnItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  getPropertyImageUrl() {
    const { property, billing_addons, user } = this.props;

    if (
      property &&
      ((property?.deal && property?.deal?.removed != 1) ||
        (!!property?.image && !property?.lead_removed))
    ) {
      if (!!property?.image || !!property?.deal?.image) {
        if (
          (property?.deal?.from_google_street_view == 1 &&
            !this.state.failedGoogleStreetPic &&
            !this.props.property?.deal?.failed_street_pic) ||
          property?.deal?.from_google_street_view != 1
        ) {
          return property?.deal?.image;
        } else if (!!property?.image) {
          return property?.image;
        }
      }
    }

    if (property) {
      if (
        (user?.user_version < 6 &&
          checkIfUserHasBillingAddon({
            billing_addons: billing_addons,
            slug: "street_view"
          })) ||
        (user?.user_version >= 6 &&
          checkIfUserHasMetadata("street_pic") &&
          !this.state.failedGoogleStreetPic &&
          !!property.streetview_image)
      ) {
        return property.streetview_image;
      }

      return property.satellite_image;
    }
    return "";
  }

  renderLeadImage() {
    const { toggle_lead_images, property, row_height, colors } = this.props;
    const image = this.getPropertyImageUrl();

    if (toggle_lead_images == "large") {
      if (!image || image == "") {
        return (
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              width: row_height,
              height: row_height,
              marginLeft: 5,
              marginRight: 15,
              borderRadius: 5,
              backgroundColor: colors.gray_color
            }}
          >
            <Icon size={26} icon="home" />
          </Wrapper>
        );
      }

      return (
        <Button
          onPress={() => {
            this.props.setPhotoGallery({
              lead_id: property.deal.id,
              property: property,
              images: property.deal.additional_images
            });
            this.props.pushSidePanel({
              slug: "photo_gallery",
              overlay: true,
              focus_modal: true
            });
          }}
          style={{
            marginLeft: 5,
            marginRight: 15,
            alignItems: "center",
            justifyContent: "center",
            width: row_height,
            height: row_height,
            borderRadius: 5,
            overflow: "hidden"
          }}
        >
          <LeadMainPhoto
            property={property}
            height={parseInt(row_height)}
            style={{ width: parseInt(row_height) }}
          />
        </Button>
      );
    }
    if (toggle_lead_images == "small") {
      return (
        <Button
          onPress={() => {
            this.props.setPhotoGallery({
              lead_id: property.deal.id,
              property: property,
              images: property.deal.additional_images
            });
            this.props.pushSidePanel({
              slug: "photo_gallery",
              overlay: true,
              focus_modal: true
            });
          }}
          style={{
            alignItems: "center",
            justifyContent: "center",
            width: 72,
            height: 36,
            marginRight: 10
          }}
        >
          <LeadMainPhoto
            property={property}
            height={parseInt(36)}
            style={{ width: parseInt(72) }}
          />
        </Button>
      );
    }
  }

  renderPreviewButtons() {
    const {
      property,
      row_height,
      dark_mode,
      colors,
      toggle_lead_images,
      user
    } = this.props;
    const { loading } = this.state;
    return (
      <Wrapper
        className="deal-preview"
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          bottom: 0,
          /*backgroundColor: toggle_lead_imafges
            ? dark_mode == "dark_mode"
              ? "rgba(0,0,0, 0.25)"
              : "rgba(255, 255, 255, 0.75)"
            : colors.card_color,*/
          margin: toggle_lead_images == "large" ? 0 : 10,
          height: toggle_lead_images == "large" ? "100%" : row_height,
          alignItems: "flex-end",
          justifyContent: "center"
        }}
      >
        <Row>
          <GhostButton
            onPress={() => {
              this.props.pressProperty(property);
            }}
            style={{
              margin: 0,
              marginLeft: 5
            }}
            innerStyle={{
              padding: 5,
              paddingRight: 10,
              paddingLeft: 10
            }}
          >
            View Lead
          </GhostButton>
          {(!property?.deal.updating ||
            !property?.deal?.updating.is_updating) &&
          property?.deal?.campaign_complete != 1 &&
          property?.deal?.archived != 1 &&
          property?.deal?.closed != 1 &&
          property?.deal?.deal_status_type !== "inactive_status" &&
          property?.deal?.deal_status_type !== "marketing_complete_status" &&
          user.can_send_mail == 1 ? (
            <UpsellButton
              primary={true}
              meta_slug="mail"
              onPress={() => {
                if (property.deal) {
                  if (user.can_send_mail == 1) {
                    if (property.deal.approved == 1) {
                      this.setState({ loading: true });
                      this.props.updateLead({
                        token: this.props.token,
                        type: "pause_mailers",
                        deal_ids: property.deal.id,
                        no_loading: true,
                        onLoading: () => {
                          this.setState({ loading: true });
                        },
                        onError: () => {
                          this.setState({ loading: false });
                        },
                        onSuccess: () => {
                          this.setState({ loading: false });
                        }
                      });
                    } else {
                      if (property.deal.paused == 1) {
                        this.setState({ loading: true });
                        this.props.updateLead({
                          token: this.props.token,
                          deal_ids: property.deal.id,
                          campaign_id:
                            property.deal.current_default_campaign_id,
                          type: "start_mailers",
                          no_loading: true,
                          onLoading: () => {
                            this.setState({ loading: true });
                          },
                          onError: () => {
                            this.setState({ loading: false });
                          },
                          onSuccess: () => {
                            this.setState({ loading: false });
                          }
                        });
                      } else {
                        this.setState({ loading: true });
                        this.props.updateLead({
                          token: this.props.token,
                          select_all: false,
                          total_count: 1,
                          deal_ids: property.deal.id,
                          campaign_id:
                            property.deal.current_default_campaign_id,
                          type: "start_mailers",
                          no_loading: true,
                          onLoading: () => {
                            this.setState({ loading: true });
                          },
                          onError: () => {
                            this.setState({ loading: false });
                          },
                          onSuccess: () => {
                            this.setState({ loading: false });
                          }
                        });
                      }
                    }
                  } else {
                    this.props.setModal({
                      title:
                        "You do not have permissions to do start campaigns.",
                      description:
                        "Talk to your team leader to get the permissions you need.",
                      icon: "error",
                      submit: "Dismiss",
                      onPress: () => {},
                      cancel: "",
                      onCancel: () => {}
                    });
                    this.props.toggleModal({ show: true, type: "normal" });
                  }
                }
              }}
              renderLockedChildren={({ hovering }) => {
                return (
                  <>
                    <GhostButton
                      primary={property.deal?.approved == 1 ? false : true}
                      loading={loading}
                      noPress={true}
                      pressedIn={hovering}
                      style={{
                        margin: 0,
                        marginLeft: 5
                      }}
                      innerStyle={{
                        padding: 5,
                        paddingRight: 10,
                        paddingLeft: 10
                      }}
                    >
                      {property.deal?.approved == 1
                        ? "Pause Mail"
                        : property.deal.paused == 1
                        ? "Resume Mail"
                        : "Start Mail"}
                    </GhostButton>
                  </>
                );
              }}
              renderChildren={({ hovering }) => {
                return (
                  <>
                    <GhostButton
                      primary={property.deal?.approved == 1 ? false : true}
                      loading={loading}
                      noPress={true}
                      pressedIn={hovering}
                      style={{
                        margin: 0,
                        marginLeft: 5
                      }}
                      innerStyle={{
                        padding: 5,
                        paddingRight: 10,
                        paddingLeft: 10
                      }}
                    >
                      {property.deal?.approved == 1
                        ? "Pause Mail"
                        : property.deal.paused == 1
                        ? "Resume Mail"
                        : "Start Mail"}
                    </GhostButton>
                  </>
                );
              }}
            />
          ) : null}
        </Row>
      </Wrapper>
    );
  }
  render() {
    const {
      property,
      active_property,
      colors,
      section,
      row_height,
      toggle_lead_images
    } = this.props;
    const display_property = determineDisplayProperty(property);

    const property_address = formatAddress({
      address: {
        address: display_property.property_address,
        address2: display_property.property_address2,
        address_city: display_property.property_address_city,
        address_state: display_property.property_address_state,
        address_zip: display_property.property_address_zip,
        latitude: property?.location?.latitude,
        longitude: property?.location?.longitude
      }
    });

    return (
      <Wrapper
        className={"deal-hover-preview"}
        style={{
          padding: 10,
          height: row_height,
          position: "relative",
          borderRightWidth: 1,
          borderRightColor: colors.border_color,
          borderRightStyle: "solid"
        }}
      >
        <Wrapper
          style={{
            flex: 1,
            alignItems: "flex-start",
            justifyContent: "center"
          }}
        >
          <Row>
            {this.renderLeadImage()}
            <Wrapper>
              {active_property?.deal?.id == property.deal.id ? (
                <Copy
                  style={{
                    whiteSpace: "nowrap",
                    color: colors.actionable_text_color
                  }}
                >
                  <Bold>
                    {this.props.getHighlightedText(property_address.line1)}
                  </Bold>
                </Copy>
              ) : (
                <Copy
                  style={{
                    whiteSpace: "nowrap"
                  }}
                >
                  {this.props.getHighlightedText(property_address.line1)}
                </Copy>
              )}

              <Copy style={{ fontSize: 10, whiteSpace: "nowrap" }}>
                {this.props.getHighlightedText(property_address.line2)}
              </Copy>
            </Wrapper>

            {property?.id < 0 ? (
              <IconButton
                noPress={true}
                primary={true}
                icon="sync-disabled"
                style={{ margin: 0 }}
              />
            ) : null}
          </Row>
        </Wrapper>
        {this.renderPreviewButtons()}
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ auth, settings, billing }) => {
  const { token, user } = auth;
  const { dark_mode } = settings;
  const { billing_addons } = billing;

  return {
    token,
    user,
    dark_mode,
    billing_addons
  };
};

export default connect(mapStateToProps, {
  toggleModal,
  setModal,
  updateLead,
  setStartMailerCampaignPage,
  pushSidePanel,
  setPhotoGallery,
  togglePhotoGallery
})(MainColumnItem);
