import React, { Component } from "react";
import { connect } from "react-redux";

import { FocusModal } from "app/NativeComponents/snippets";
import { popSidePanel } from "app/NativeActions";

import LegacyMailerDesigner from "./LegacyMailerDesigner";
import MailerDesigner from "./MailerDesigner";
class PostcardDesignerWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;
    if (panel_data) {
      if (panel_data?.template_system_type == "legacy") {
        return (
          <LegacyMailerDesigner
            mailer={panel_data?.mailer}
            onSuccess={panel_data?.onSuccess}
            onDeleteSuccess={panel_data?.onDeleteSuccess}
            global_templates={this.props.global_templates}
          />
        );
      }

      return (
        <MailerDesigner
          mailer={panel_data?.mailer}
          select_design={panel_data?.select_design}
          onSuccess={panel_data?.onSuccess}
          onDeleteSuccess={panel_data?.onDeleteSuccess}
          hide_options={panel_data?.hide_options}
          global_templates={panel_data?.global_templates}
        />
      );
    }
    return (
      <FocusModal
        title="Postcard Designer"
        onClose={() => {
          this.props.popSidePanel();
        }}
      />
    );
  }
}

const mapStateToProps = ({ native }) => {
  const { device } = native;
  return {
    device
  };
};

export default connect(mapStateToProps, {
  popSidePanel
})(PostcardDesignerWidget);
