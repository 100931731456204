import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  ContentfulRichText,
  Copy,
  Wrapper,
  KeyboardView
} from "app/NativeComponents/common";
import {
  BottomNavBar,
  ConfirmInlineButton,
  ConfirmLabel,
  InlineButton,
  NewHeader,
  SelectBox,
  SpinWrapper
} from "app/NativeComponents/snippets";
import {
  loadUpsellModal,
  popSidePanel,
  manageSubscription,
  getTeam,
  editWorkspaceTeamLicenses
} from "app/NativeActions";

class EditDialerLicense extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;
    this.state = {
      contentful_data: null,
      contentful_loading: false,
      assign_to_focus: false,
      assign_to: panel_data.assigned_user,
      seat_type: panel_data.seat_type,
      license_id: panel_data.license_id,
      previous_data: {
        assign_to: panel_data.assigned_user,
        seat_type: panel_data.seat_type
      },
      team_members: panel_data.team_members || [],
      workspace_id: panel_data.workspace_id || null,
      workspace_team_id: panel_data.workspace_team_id || null,
      deleting: false,
      loading: false,
      onEditComplete: panel_data.onEditComplete || null
    };

    this._assign_to_select = React.createRef();

    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
  }

  componentDidMount() {
    if (
      !this.props.team_loading &&
      this.props.my_team?.length === 0 &&
      !!this.state.workspace_id
    ) {
      this.props.getTeam({ token: this.props.token, type: "load" });
    }
  }

  componentDidUpdate(prevProps) {}

  checkIfNeedsToSave() {
    if (this.state.assign_to != this.state.previous_data?.assign_to) {
      return true;
    }

    return false;
  }

  render() {
    const { colors } = this.props;
    return (
      <Container>
        <NewHeader
          title={
            this.state.seat_type === "voicemail"
              ? "Edit AI Voicemail License"
              : "Edit Dialer License"
          }
          leftButton={{
            icon: "arrow-back",
            onPress: this.props.popSidePanel
          }}
        />

        {this.props.team_loading ? (
          <SpinWrapper text={"Loading"} />
        ) : (
          <>
            {this.checkIfNeedsToSave() ? (
              <ConfirmLabel
                primary={true}
                icon="tune"
                label={"License changed"}
                loading={this.state.loading}
                confirmButton={"Save Changes"}
                onPress={() => {
                  if (!!this.state.workspace_id) {
                    this.props.editWorkspaceTeamLicenses({
                      token: this.props.token,
                      type: "edit_dialer_license",
                      license_id: this.state.license_id,
                      team_id: this.state.workspace_team_id,
                      assign_to: this.state.assign_to,
                      old_assigned_user: this.state.previous_data?.assign_to,
                      seat_type: this.state.seat_type,
                      onLoading: () => {
                        this.setState({
                          loading: true
                        });
                      },
                      onError: () => {
                        this.setState({
                          loading: false
                        });
                      },
                      onSuccess: results => {
                        this.setState(
                          {
                            loading: false
                          },
                          () => {
                            if (this.state.onEditComplete) {
                              this.state.onEditComplete(results);
                            }
                            this.props.popSidePanel();
                          }
                        );
                      }
                    });
                  } else {
                    this.props.manageSubscription({
                      token: this.props.token,
                      type: "edit_dialer_license",
                      assign_to: this.state.assign_to,
                      old_assigned_user: this.state.previous_data?.assign_to,
                      seat_type: this.state.seat_type,
                      license_id: this.state.license_id,
                      onLoading: () => {
                        this.setState({
                          loading: true
                        });
                      },
                      onError: () => {
                        this.setState({
                          loading: false
                        });
                      },
                      onSuccess: () => {
                        this.setState(
                          {
                            loading: false
                          },
                          () => {
                            this.props.popSidePanel();
                          }
                        );
                      }
                    });
                  }
                }}
              />
            ) : null}
            <KeyboardView style={{ flex: 1 }}>
              <SelectBox
                select_ref={this._assign_to_select}
                items={
                  !!this.state.workspace_id
                    ? this.state.team_members
                    : this.props.my_team
                }
                disabled={this.props.team_loading}
                loading={this.props.team_loading}
                value={this.state.assign_to}
                placeholder="License assigned to:"
                show={this.state.assign_to_focus}
                onFocus={() => {
                  this.setState({ assign_to_focus: true });
                }}
                onBlur={() => {
                  this.setState({ assign_to_focus: false });
                }}
                onSelect={item => {
                  this.setState({
                    assign_to: item
                  });
                }}
                onSubmitEditing={() => {}}
              />
            </KeyboardView>

            {!this.checkIfNeedsToSave() && !this.props.user?.workspace_id ? (
              <BottomNavBar>
                <ConfirmInlineButton
                  require_confirm={true}
                  button_type="full"
                  confirm_text={
                    this.state.workspace_id
                      ? "Really Unassign?"
                      : "Really Remove?"
                  }
                  confirm_icon="delete"
                  loading_text="Removing..."
                  loading={this.state.deleting}
                  disabled={false}
                  onPress={() => {
                    if (!!this.state.workspace_id) {
                      this.props.editWorkspaceTeamLicenses({
                        token: this.props.token,
                        type: "unassign_license_to_team",
                        license_id: this.state.license_id,
                        team_id: this.state.workspace_team_id,
                        seat_type: this.state.seat_type,
                        onLoading: () => {
                          this.setState({
                            deleting: true
                          });
                        },
                        onError: () => {
                          this.setState({
                            deleting: false
                          });
                        },
                        onSuccess: results => {
                          this.setState(
                            {
                              deleting: false
                            },
                            () => {
                              if (this.state.onEditComplete) {
                                this.state.onEditComplete(results);
                              }
                              this.props.popSidePanel();
                            }
                          );
                        }
                      });
                    } else {
                      this.props.manageSubscription({
                        token: this.props.token,
                        type: "remove_dialer_license",
                        license_id: this.state.license_id,
                        workspace_id: this.state.workspace_id,
                        seat_type: this.state.seat_type,
                        onLoading: () => {
                          this.setState({
                            deleting: true
                          });
                        },
                        onError: () => {
                          this.setState({
                            deleting: false
                          });
                        },
                        onSuccess: () => {
                          this.setState(
                            {
                              deleting: false
                            },
                            () => {
                              this.props.popSidePanel();
                            }
                          );
                        }
                      });
                    }
                  }}
                >
                  {this.state.workspace_id
                    ? "Unassign License"
                    : "Remove License"}
                </ConfirmInlineButton>
              </BottomNavBar>
            ) : null}
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, billing, team }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile, device } = native;
  const { source_of_truth } = billing;
  const { my_team, team_loading } = team;
  return {
    token,
    user,
    isMobile,
    colors,
    device,
    source_of_truth,
    my_team,
    team_loading
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  manageSubscription,
  getTeam,
  editWorkspaceTeamLicenses
})(EditDialerLicense);
