import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, Copy, Bold, Row } from "app/NativeComponents/common";
import {
  SelectItem,
  PopoverMenu,
  CloseButton
} from "app/NativeComponents/snippets";

import PersonItem from "./OwnerInfo/PersonItem";

import {
  checkIfUserHasMetadata,
  toTitleCase,
  pushSidePanel
} from "app/NativeActions";

class LinkedContact extends Component {
  constructor(props) {
    super(props);

    this.state = { show_tooltip: false };
  }
  componentWillUnmount() {
    clearInterval(this._interval);
  }
  render() {
    const { property, search, colors, contacts } = this.props;

    if (contacts?.length === 1 || this.props.device !== "desktop") {
      const contact = contacts[0];

      return (
        <Row>
          <SelectItem
            select_type={"none"}
            onPress={() => {
              this.setState(
                {
                  show_tooltip: false
                },
                () => {
                  if (!checkIfUserHasMetadata("paid_plan")) {
                    this.props.pushSidePanel({
                      slug: "purchase_plan_prompt",
                      overlay_modal: true,
                      data: {
                        title:
                          "Get Unlimited Contact Info With DealMachine Pro.",
                        dismiss_button:
                          "No thanks. Let me continue without contact info.",
                        no_free: true,
                        event_slug: "in_app_reveal_contact_info"
                      },
                      locked: true
                    });
                  } else {
                    this.props.pushSidePanel({
                      slug: "contact",
                      expanded_side_panel: this.props.expanded,
                      overlay: true,
                      id: contact?.individual_key,
                      data: {
                        person: contact,
                        property: this.props.property,
                        expanded: this.props.expanded,
                        propertyUpdated: this.props.propertyUpdated
                      }
                    });
                  }
                }
              );
            }}
            style={{
              marginRight: 0,
              marginLeft: 0,

              justifyContent: "center"
            }}
            innerStyle={{
              padding: 0
            }}
            selected={true}
          >
            {toTitleCase(contact?.full_name)}
          </SelectItem>
        </Row>
      );
    }
    if (contacts?.length > 0) {
      return (
        <Row>
          <PopoverMenu
            show={this.state.show_tooltip}
            no_swipe={true}
            no_cancel={false}
            popover_dismiss={"Dismiss"}
            onShow={s => {
              this.setState({
                show_tooltip: s
              });
            }}
            popover_width={450}
            popoverPlacement={"bottom"}
            popover_title={`Possible matches for ${toTitleCase(
              contacts[0]?.full_name
            )} (${contacts?.length})`}
            includeCloseButton={true}
            renderComponent={options => {
              return (
                <SelectItem
                  select_type={"none"}
                  pressedIn={options.hovering || options.pressedIn}
                  noPress={true}
                  style={{
                    marginRight: 0,
                    marginLeft: 0,
                    justifyContent: "center"
                  }}
                  innerStyle={{
                    padding: 0
                  }}
                  selected={true}
                >
                  {toTitleCase(contacts[0]?.full_name)} ({contacts?.length})
                </SelectItem>
              );
            }}
            renderMenu={() => {
              return (
                <Wrapper style={{ alignSelf: "stretch" }}>
                  {this.props.device === "desktop" ? (
                    <Wrapper
                      style={{
                        padding: 25,
                        borderBottomWidth: 1,
                        borderBottomStyle: "solid",
                        borderBottomColor: colors.border_color
                      }}
                    >
                      <Copy>
                        <Bold>
                          Possible matches for{" "}
                          {toTitleCase(contacts[0]?.full_name)} (
                          {contacts?.length})
                        </Bold>
                      </Copy>
                    </Wrapper>
                  ) : null}

                  {contacts.map((contact, index) => {
                    return (
                      <PersonItem
                        key={"person_" + index}
                        person={contact}
                        no_options={false}
                        use_person_flags={false}
                        include_search_information={true}
                        expanded={this.props.expanded}
                        no_border={true}
                        property={this.props.property}
                        propertyUpdated={this.props.propertyUpdated}
                        onPress={() => {
                          this.setState({
                            show_tooltip: false
                          });
                        }}
                      />
                    );
                  })}

                  {this.props.device === "desktop" ? (
                    <CloseButton
                      style={{ marginTop: 10 }}
                      onPress={() => {
                        this.setState({
                          show_tooltip: false
                        });
                      }}
                    />
                  ) : null}
                </Wrapper>
              );
            }}
          />
        </Row>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;

  const { colors } = settings;
  const { platform, device, isMobile } = native;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform
  };
};

export default connect(mapStateToProps, { pushSidePanel })(LinkedContact);
