import React, { Component } from "react";
import { connect } from "react-redux";

import {
  KeyboardView,
  Wrapper,
  WebContainer,
  Container,
  Row
} from "app/NativeComponents/common";
import { Header, Tabs, PillButton } from "app/NativeComponents/snippets";

import {
  appRedirect,
  changeTab,
  toggleDrawer,
  toggleWorkflowDesigner,
  switchWorkflowTab,
  setActiveWorkflow,
  numberWithCommas
} from "app/NativeActions";
import WorkflowList from "./WorkflowList";
import WorkflowHistory from "./WorkflowHistory";
import MainHeader from "app/DealMachineCore/reuseable/MainHeader";

class Workflows extends Component {
  componentDidMount() {
    const { user } = this.props;
  }

  componentDidUpdate(prevProps, prevState) {}

  render() {
    const { colors, isMobile, workflow_tab, workflow_count, user } = this.props;
    return (
      <Container
        fill={true}
        style={{
          display: "flex",
          overflow: "hidden",
          backgroundColor: colors.card_color
        }}
        title={"Leads"}
      >
        <MainHeader title={"Workflows"} />

        <Tabs
          selected_tab={workflow_tab}
          onTabSelect={tab => {
            this.props.switchWorkflowTab(tab);
          }}
          componentStyle={{
            backgroundColor: colors.card_color
          }}
          tabs={[
            {
              slug: "workflows",
              title: "Workflows",
              mobile_title: "Workflows",
              renderComponent: () => {
                return <WorkflowList />;
              }
            },
            {
              slug: "workflow_history",
              title: "Workflow History",
              mobile_title: "History",
              renderComponent: () => {
                return <WorkflowHistory />;
              }
            }
          ]}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, workflow }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile, device } = native;
  const { workflow_tab, workflow_count } = workflow;
  return {
    token,
    user,
    isMobile,
    colors,
    device,
    workflow_tab,
    workflow_count
  };
};

export default connect(mapStateToProps, {
  appRedirect,
  changeTab,
  toggleDrawer,
  toggleWorkflowDesigner,
  switchWorkflowTab,
  setActiveWorkflow
})(Workflows);
