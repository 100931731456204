import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Wrapper,
  Card,
  Row,
  Copy,
  Spin,
  Bold
} from "app/NativeComponents/common";
import {
  PopoverMenu,
  SearchBar,
  InlineButton,
  IconButton,
  CircleButton,
  List,
  SpinWrapper,
  ConfirmLabel,
  BottomNavBar,
  GhostButton
} from "app/NativeComponents/snippets";

import {
  appRedirect,
  setAppliedFilter,
  pushSidePanel,
  getCustom,
  getCustomFilterText,
  changeTab,
  tabNavigation,
  setActiveList,
  numberWithCommas
} from "app/NativeActions";

import LeadItem from "app/DealMachineCore/reuseable/LeadItem";
import OpenListsButton from "app/DealMachineCore/reuseable/OpenListsButton";
import ShowcaseFilters from "app/DealMachineCore/reuseable/AdvancedFilters/ShowcaseFilters";
import LeadActionsButton from "../SearchAndFilter/ActionButtons";
import SelectAllText from "../SelectAllText";
import BulkLoading from "./BulkLoading";
import ListBuilding from "../ListBuilding";
class MobileLeadsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: ""
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.active_list_tab !== this.props.active_list_tab &&
      this.props.isMobile
    ) {
      this.setState({
        search: ""
      });
    }
  }

  isChecked(property) {
    const { selected_leads, selected_all } = this.props;
    for (let i = 0; i < selected_leads.length; i++) {
      if (
        selected_leads[i].property_id === property.property_id ||
        selected_all
      ) {
        return true;
      }
    }

    return false;
  }

  render() {
    const {
      user,
      colors,
      active_list_tab,

      list_properties,
      list_properties_loading,
      list_properties_page,
      list_properties_loaded_all,
      list_properties_refreshing,

      applied_filter,
      filtered_count,
      filtered_count_loading,
      is_selecting
    } = this.props;

    return (
      <>
        <Row>
          <Wrapper style={{ flex: 1 }}>
            <SearchBar
              style={{ margin: 5, borderWidth: 0, borderBottomWidth: 0 }}
              containerStyle={{ overflow: "hidden" }}
              value={this.state.search}
              title={
                active_list_tab == "all_leads"
                  ? this.props.isMobile
                    ? "Search"
                    : "Search All Leads"
                  : active_list_tab == "leads_in_trash"
                  ? this.props.isMobile
                    ? "Search"
                    : 'Search "In Trash"'
                  : this.props.isMobile
                  ? "Search"
                  : "Search " + active_list_tab.title
              }
              onChange={value => {
                this.setState({
                  search: value
                });
              }}
              disabledButton={
                this.props.search_loading ||
                (this.state.search == this.props.search &&
                  !this.props.search_loading)
              }
              /*
showButton={true}

              buttonText={
                this.state.search !== this.props.search &&
                this.state.search.length == 0
                  ? "Clear"
                  : "Search"
              }

              buttonLoading={this.props.search_loading}
              */

              renderRight={
                !!this.state.search && !this.props.search_loading
                  ? () => {
                      return (
                        <IconButton
                          icon={"close"}
                          button_type="small"
                          onPress={() => {
                            this.setState({
                              search: ""
                            });
                            this.props.updateSearch("");
                          }}
                        />
                      );
                    }
                  : null
              }
              loading={this.props.search_loading}
              onPress={() => {
                this.props.updateSearch(
                  this.state.search.replace(/[^A-Z0-9]+/gi, " ")
                );
              }}
            />
          </Wrapper>
          {!is_selecting ? (
            <IconButton
              icon="tune"
              tooltip="Filters"
              tooltipPlacement="top"
              icon_color={colors.actionable_text_color}
              style={{ marginLeft: 0, marginRight: 0 }}
              onPress={() => {
                this.props.pushSidePanel({
                  slug: "advanced_filters",
                  overlay: true
                });
              }}
            />
          ) : null}

          {!is_selecting ? (
            <OpenListsButton
              popoverPlacement="bottom"
              menu_type="open_tabs"
              renderComponent={({ pressedIn, hovering }) => {
                return (
                  <InlineButton
                    onPress={() => {}}
                    pressedIn={pressedIn || hovering}
                    noPress={true}
                    style={{ marginLeft: 0, marginRight: 0 }}
                    icon="menu-open"
                  >
                    Lists
                  </InlineButton>
                );
              }}
            />
          ) : null}
          <InlineButton
            onPress={() => {
              this.props.toggleSelecting(!is_selecting);
            }}
            style={{ marginLeft: 0, marginRight: is_selecting ? 5 : 0 }}
          >
            {is_selecting ? "Cancel Selection" : "Select"}
          </InlineButton>

          {!is_selecting ? (
            <IconButton
              tooltip="Add Leads"
              tooltipPlacement="top"
              onPress={() => {
                this.props.pushSidePanel({
                  slug: "manually_add_lead",
                  overlay: true
                });
              }}
              style={{ marginLeft: 0 }}
              icon="add"
              primary={true}
            />
          ) : null}
        </Row>

        {active_list_tab !== "all_leads" || applied_filter ? (
          <Wrapper
            style={{
              paddingRight: 20,
              paddingLeft: 20,
              justifyContentCenter: "center"
            }}
          >
            {!filtered_count_loading ? (
              filtered_count == 1 ? (
                <Copy style={{ fontSize: 10 }}>
                  Showing 1 lead
                  {active_list_tab?.id ? " in " + active_list_tab.title : ""}
                </Copy>
              ) : (
                <Copy style={{ fontSize: 10 }}>
                  Showing {numberWithCommas(filtered_count)} leads
                  {active_list_tab?.id ? " in " + active_list_tab.title : ""}
                </Copy>
              )
            ) : !list_properties_loading &&
              !list_properties_refreshing &&
              !this.props.search_loading ? (
              <Spin style={{ fontSize: 10 }} text="Loading filtered count..." />
            ) : null}
          </Wrapper>
        ) : null}

        {applied_filter ? (
          <ConfirmLabel
            style={{
              alignSelf: "stretch"
            }}
            renderLabel={() => {
              if (applied_filter.old_filters) {
                const custom_filter_text = getCustomFilterText(
                  applied_filter.old_filters
                );
                return (
                  <Wrapper>
                    <Copy>
                      <Bold>Legacy filters: </Bold>
                      {custom_filter_text}
                    </Copy>
                  </Wrapper>
                );
              } else {
                return (
                  <Wrapper>
                    <ShowcaseFilters
                      filters={applied_filter.filters?.data}
                      andor_type={applied_filter.filters?.andor_type}
                      editable={false}
                      text_only={true}
                    />
                  </Wrapper>
                );
              }
            }}
            secondaryButton={!applied_filter.old_filters ? "Edit" : ""}
            onSecondaryPress={() => {
              this.props.pushSidePanel({
                slug: "advanced_filters",
                overlay: true
              });
            }}
            confirmButton={"Clear All"}
            confirmButtonIcon={"clear"}
            onPress={() => {
              this.props.setAppliedFilter({
                applied_filter: null
              });
            }}
          />
        ) : null}

        {active_list_tab?.id && active_list_tab.building == 1 ? (
          <Wrapper style={{ flex: 1 }}>
            <ListBuilding />
          </Wrapper>
        ) : (
          <>
            {((list_properties_loading && list_properties.length == 0) ||
              list_properties_refreshing) &&
            !this.props.search_loading ? (
              <Wrapper style={{ flex: 1 }}>
                <SpinWrapper text="Loading leads..." />
              </Wrapper>
            ) : (
              <List
                rowNumber={1}
                items={list_properties}
                infiniteScroll={true}
                itemStructure={({ item, index }) => {
                  return (
                    <LeadItem
                      key={"lead_" + item.property_id}
                      property={item}
                      is_selecting={is_selecting}
                      selected={this.props.selected_all || this.isChecked(item)}
                      onPress={() => {
                        if (is_selecting) {
                          this.props.checkLead(item);
                        } else {
                          this.props.pressProperty(item);
                        }
                      }}
                      onLongPress={() => {
                        this.props.toggleSelecting(true);
                        this.props.checkLead(item);
                      }}
                    />
                  );
                }}
                onRefresh={() => {
                  this.props.getProperties({ load_type: "refresh" });
                }}
                is_refreshing={list_properties_refreshing}
                canLoadMore={
                  !list_properties_loaded_all &&
                  !list_properties_loading &&
                  !list_properties_refreshing &&
                  list_properties.length > 0
                }
                isLoadingMore={
                  list_properties_loading &&
                  !list_properties_refreshing &&
                  list_properties.length > 0
                }
                onLoadMore={() => {
                  this.props.getProperties({ load_type: "load_more" });
                }}
              />
            )}

            {is_selecting && active_list_tab.building != 1 ? (
              <BottomNavBar
                style={{
                  paddingBottom: 0
                }}
              >
                <SelectAllText {...this.props} />
                <LeadActionsButton {...this.props} />
                <InlineButton
                  onPress={() => {
                    this.props.clearAll();
                  }}
                  button_type="full"
                >
                  Cancel Selection
                </InlineButton>
              </BottomNavBar>
            ) : null}
            <BulkLoading />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, list, lead, filter, feature_toggle }) => {
  const { token, user } = auth;
  const { active_list_tab } = list;
  const {
    list_properties,
    list_properties_begin,
    list_properties_page,
    list_properties_limit,
    list_properties_loading,
    list_properties_refreshing,
    list_properties_loaded_all
  } = lead;
  const { applied_filter } = filter;

  return {
    token,
    user,
    active_list_tab,
    list_properties,
    list_properties_begin,
    list_properties_page,
    list_properties_limit,
    list_properties_loading,
    list_properties_refreshing,
    list_properties_loaded_all,
    applied_filter
  };
};

export default connect(mapStateToProps, {
  appRedirect,
  setAppliedFilter,
  changeTab,
  tabNavigation,
  setActiveList,
  pushSidePanel
})(MobileLeadsView);
