import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Row,
  Title,
  Copy,
  Bold,
  Icon,
  Button
} from "app/NativeComponents/common";
import {
  PillButton,
  InlineButton,
  GhostButton,
  IconButton
} from "app/NativeComponents/snippets";
class Cta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view_step: 0,
      loading: false
    };
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render() {
    const { colors, lead_mailer_campaign, user } = this.props;
    const {
      property,
      property: { deal }
    } = lead_mailer_campaign;
    if (deal.campaign_complete) {
      //Mail Sequence complete
      return (
        <Wrapper
          style={{
            margin: 5,
            borderRadius: 5,
            backgroundColor: colors.active_color_muted
          }}
        >
          <Row>
            <Wrapper style={{ flex: 1, padding: 25 }}>
              <Row>
                <Wrapper style={{ flex: 1 }}>
                  <Copy>
                    <Bold>
                      {deal.times_mailed == deal.number_of_mailers
                        ? "Mail Complete"
                        : "Mail Ended"}
                    </Bold>
                  </Copy>
                  <Copy style={{ fontSize: 12 }}>
                    {deal.times_mailed == deal.number_of_mailers
                      ? "Mail for this lead has completed. To send more mail to this lead, choose a new sequence."
                      : "Mail for this lead has ended. To send more mail to this lead, choose a new sequence."}
                  </Copy>
                </Wrapper>
              </Row>
            </Wrapper>
            <InlineButton
              style={{ marginLeft: 0 }}
              disabled={this.props.user?.can_send_mail != 1}
              onPress={() => {
                this.props.setStartMailerCampaignPage({
                  title: "Select Mail",
                  subtitle: property.property_address,
                  lead_ids: property?.deal?.id,
                  number_of_leads: 1,
                  onSuccess: results => {
                    if (
                      this.props.propertyUpdated &&
                      results?.properties?.length == 1
                    ) {
                      this.props.propertyUpdated(results.properties[0]);
                    }
                  }
                });
                this.props.pushSidePanel({
                  slug: "start_mail_campaign",
                  overlay: true
                });
              }}
            >
              Start New Mail
            </InlineButton>
          </Row>
        </Wrapper>
      );
    }
    if (user.pause_sending == 1) {
      //all mailers are paused
      return (
        <Wrapper
          style={{
            backgroundColor: colors.orange_color_muted,
            margin: 5,
            borderRadius: 5
          }}
        >
          <Row>
            <Wrapper style={{ flex: 1, padding: 25 }}>
              <Row>
                <Wrapper style={{ flex: 1 }}>
                  <Copy>
                    <Bold>The mail queue is paused</Bold>
                  </Copy>
                  <Copy style={{ fontSize: 12 }}>
                    Mail will not be sent out until you resume the sending
                    queue.
                  </Copy>
                </Wrapper>
              </Row>
            </Wrapper>
            {user?.can_see_all_deals == 1 && user?.can_send_mail == 1 ? (
              <InlineButton
                icon_right="keyboard-arrow-right"
                primary={true}
                onPress={() => {
                  this.props.pushSidePanel({
                    slug: "mail_queue",
                    overlay: true
                  });
                }}
              >
                Mail Queue
              </InlineButton>
            ) : null}
          </Row>
        </Wrapper>
      );
    } else if (deal.paused == 1) {
      //lead is paused
      return (
        <Wrapper
          style={{
            margin: 5,
            borderRadius: 5,
            backgroundColor: colors.orange_color_muted
          }}
        >
          <Row>
            <Wrapper style={{ flex: 1, padding: 25 }}>
              <Row>
                <Wrapper style={{ flex: 1 }}>
                  <Copy>
                    <Bold>Mail paused</Bold>
                  </Copy>
                  <Copy style={{ fontSize: 12 }}>
                    You've paused mail for this lead. When you resume, mail
                    pieces will re-calculate their send dates based on the last
                    time a mail piece when out.
                  </Copy>
                </Wrapper>
              </Row>
            </Wrapper>
            {/*
              <IconButton
                primary={false}
                tooltip="Change Sequence"
                tooltipPlacement="top"
                icon="swap-horiz"
                style={{ marginRight: 0, marginLeft: 0 }}
                onPress={() => {
                  this.props.setStartMailerCampaignPage({
                    title: "Select Mail Options",
                    subtitle: property.property_address,
                    lead_ids: property?.deal?.id,
                    number_of_leads: 1,
                    onSuccess: results => {
                      if (
                        this.props.propertyUpdated &&
                        results?.properties?.length == 1
                      ) {
                        this.props.propertyUpdated(results.properties[0]);
                      }
                    }
                  });
                  this.props.pushSidePanel({
                    slug: "start_mail_campaign",
                    overlay: true
                  });
                }}
              />
              */}

            <InlineButton
              primary={true}
              loading={this.state.loading}
              style={{ marginLeft: 0 }}
              disabled={this.props.user?.can_send_mail != 1}
              onPress={() => {
                this.props.updateLead({
                  token: this.props.token,
                  deal_ids: deal.id,
                  campaign_id: deal.campaign_id,
                  type: "start_mailers",
                  onLoading: () => {
                    this.setState({
                      loading: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      loading: false
                    });
                  },
                  onSuccess: results => {
                    this.setState({
                      loading: false
                    });
                    if (
                      this.props.propertyUpdated &&
                      results?.properties?.length == 1
                    ) {
                      this.props.propertyUpdated(results.properties[0]);
                    }
                  }
                });
              }}
            >
              Resume
            </InlineButton>
          </Row>
        </Wrapper>
      );
    } else if (deal.mail_failed_info && deal.mail_failed_info?.length > 0) {
      //mail stuck

      return (
        <Wrapper
          style={{
            margin: 5,
            padding: 25,
            borderRadius: 5,
            backgroundColor: colors.orange_color_muted
          }}
        >
          <Copy>
            <Bold>Mail(s) stuck</Bold>
          </Copy>
          {deal.mail_failed_info?.map((info, i) => {
            return (
              <>
                <Copy>
                  Reason: {this.capitalizeFirstLetter(info.short_message)}
                </Copy>
                <Copy style={{ marginBottom: 5, fontSize: 12 }}>
                  Resolution: {this.capitalizeFirstLetter(info.long_message)}
                </Copy>
              </>
            );
          })}
        </Wrapper>
      );
    } else if (deal.mailer_status == 2 || deal.approved == 1 || true) {
      //in queue
      return (
        <Wrapper
          style={{
            backgroundColor: colors.active_color_muted,
            margin: 5,
            borderRadius: 5
          }}
        >
          <Row>
            <Wrapper style={{ flex: 1, padding: 25 }}>
              <Row>
                {deal.mailer_status == 2 ? (
                  <Wrapper style={{ flex: 1 }}>
                    <Copy>
                      <Bold>Mail pieces are in the mail queue</Bold>
                    </Copy>
                    <Copy style={{ fontSize: 12 }}>
                      Your mail is in the mail queue. Mail should send within
                      the next hour or so.
                    </Copy>
                  </Wrapper>
                ) : (
                  <Wrapper style={{ flex: 1 }}>
                    <Copy>
                      <Bold>Mail in progress.</Bold>
                    </Copy>
                  </Wrapper>
                )}
              </Row>
            </Wrapper>
            {/*
              <IconButton
                primary={false}
                tooltip="Change Sequence"
                tooltipPlacement="top"
                icon="swap-horiz"
                style={{ marginRight: 0, marginLeft: 0 }}
                onPress={() => {
                  this.props.setStartMailerCampaignPage({
                    title: "Select Mail Options",
                    subtitle: property.property_address,
                    lead_ids: property?.deal?.id,
                    number_of_leads: 1,
                    onSuccess: results => {
                      if (
                        this.props.propertyUpdated &&
                        results?.properties?.length == 1
                      ) {
                        this.props.propertyUpdated(results.properties[0]);
                      }
                    }
                  });
                  this.props.pushSidePanel({
                    slug: "start_mail_campaign",
                    overlay: true
                  });
                }}
              />
              */}

            <InlineButton
              loading={this.state.loading}
              style={{ marginLeft: 0 }}
              disabled={this.props.user?.can_send_mail != 1}
              onPress={() => {
                this.props.updateLead({
                  token: this.props.token,
                  deal_ids: deal.id,
                  type: "pause_mailers",
                  onLoading: () => {
                    this.setState({
                      loading: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      loading: false
                    });
                  },
                  onSuccess: results => {
                    this.setState({
                      loading: false
                    });
                    if (
                      this.props.propertyUpdated &&
                      results?.properties?.length == 1
                    ) {
                      this.props.propertyUpdated(results.properties[0]);
                    }
                  }
                });
              }}
            >
              Pause
            </InlineButton>
          </Row>
        </Wrapper>
      );
    }

    return null;
  }
}

export default Cta;
