import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  KeyboardView,
  Wrapper,
  Title,
  Copy,
  Row
} from "app/NativeComponents/common";
import {
  NewHeader,
  BottomNavBar,
  InlineButton,
  ConfirmInlineButton
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  updateTeamMembers,
  selectActiveTeamMember,
  toggleModal,
  setModal
} from "app/NativeActions";

import UserProfile from "./UserProfile";
import TabbedItems from "./TabbedItems";

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      resending: false
    };
    this.handleBack = this.handleBack.bind(this);
  }
  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  componentWillUnmount() {}

  /*native functions*/
  handleBack() {
    this.props.selectActiveTeamMember(null);
    this.props.popSidePanel();
  }

  renderTitle() {
    if (this.props.active_team_member?.member_type == "invite") {
      return "Invited Team Member";
    }

    if (this.props.active_team_member?.member_type == "request") {
      return "Requested Team Member";
    }
    return "Team Member";
  }

  render() {
    if (
      this.props.active_team_member &&
      this.props.active_team_member != "invite" &&
      this.props.active_team_member != "collect_dealfinders"
    ) {
      return (
        <Container>
          <NewHeader
            title={this.renderTitle()}
            leftButton={{
              icon: "arrow-back",
              onPress: this.handleBack
            }}
          />
          <UserProfile {...this.props} />
          <TabbedItems {...this.props} />
          {this.props.active_team_member.member_type == "invite" &&
          this.props.user?.can_mimic_team_owner == 1 ? (
            <>
              <Wrapper style={{ flex: 1 }} />
              <BottomNavBar>
                <Row style={{ justifyContent: "space-between" }}>
                  <ConfirmInlineButton
                    confirm_text="Really Resend?"
                    loading_text="Resending"
                    loading={this.state.resending}
                    onPress={() => {
                      this.props.updateTeamMembers({
                        token: this.props.token,
                        type: "resend_invite",
                        team_member_id: this.props.active_team_member.id,
                        member_type: "invite",
                        onLoading: () => {
                          this.setState({
                            resending: true
                          });
                        },
                        onError: () => {
                          this.setState({
                            resending: false
                          });
                        },
                        onSuccess: () => {
                          this.setState({
                            resending: false
                          });
                        }
                      });
                    }}
                  >
                    Resend Invitation
                  </ConfirmInlineButton>

                  <ConfirmInlineButton
                    confirm_text="Really Delete?"
                    confirm_icon="delete"
                    loading_text="Deleting"
                    loading={this.state.deleting}
                    onPress={() => {
                      this.props.updateTeamMembers({
                        token: this.props.token,
                        type: "remove_invite",
                        team_member_id: this.props.active_team_member.id,
                        member_type: "invite",
                        onLoading: () => {
                          this.setState({
                            deleting: true
                          });
                        },
                        onError: () => {
                          this.setState({
                            deleting: false
                          });
                        },
                        onSuccess: () => {
                          this.setState({
                            deleting: false
                          });
                          this.props.popSidePanel();
                        }
                      });
                    }}
                  >
                    Delete Invitation
                  </ConfirmInlineButton>
                </Row>
              </BottomNavBar>
            </>
          ) : this.props.active_team_member.member_type == "request" ? (
            <Wrapper style={{ flex: 1 }} />
          ) : this.props.user?.can_mimic_team_owner == 1 &&
            this.props.active_team_member?.id !== this.props.user?.id &&
            this.props.active_team_member?.team_owner != 1 ? (
            <BottomNavBar>
              <ConfirmInlineButton
                button_type="full"
                confirm_text="Really Delete?"
                confirm_icon="delete"
                loading_text="Deleting"
                loading={this.state.deleting}
                onPress={() => {
                  this.props.updateTeamMembers({
                    token: this.props.token,
                    type: "remove_member",
                    team_member_id: this.props.active_team_member.id,
                    member_type: "team_member",
                    onLoading: () => {
                      this.setState({
                        deleting: true
                      });
                    },
                    onError: () => {
                      this.setState({
                        deleting: false
                      });
                    },
                    onSuccess: () => {
                      this.setState({
                        deleting: false
                      });
                      this.props.popSidePanel();
                    }
                  });
                }}
              >
                Delete Team Member
              </ConfirmInlineButton>
            </BottomNavBar>
          ) : null}
        </Container>
      );
    }
    if (!this.props.isMobile) {
      /*
      return(
        <Container fill>
          <Wrapper style={{flex:1, alignItems: "center", justifyContent: "center"}}>
            <Title style={{textAlign: "center"}}>Select a team member</Title>
            <Copy>Select a team member on the left panel to display them here.</Copy>
          </Wrapper>
        </Container>
      )
      */
    }

    return <Wrapper />;
  }
}

const mapStateToProps = ({ auth, settings, native, team }) => {
  const { token, user } = auth;
  const { colors } = settings;

  const { device, isMobile, platform } = native;
  const { active_team_member } = team;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform,
    active_team_member
  };
};

export default connect(mapStateToProps, {
  popSidePanel,

  updateTeamMembers,
  selectActiveTeamMember,
  toggleModal,
  setModal
})(User);
