import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Wrapper, Row, Copy, KeyboardView } from "app/NativeComponents/common";
import {
  InlineButton,
  PopoverMenu,
  SelectItem,
  SearchBar,
  BottomNavBar,
  SpinWrapper
} from "app/NativeComponents/snippets";

import {
  tabNavigation,
  setModal,
  toggleModal,
  changeTab,
  pushSidePanel,
  searchDealMachine,
  selectActiveProperty,
  setActiveList,
  nextOnboardingStep,
  getDashboardStats,
  getWorkspaceTeams,
  setActiveWorkspaceTeam,
  accessWorkspaceTeam,
  getUser,
  toggleUserRefresh,
  getSourceOfTruth
} from "app/NativeActions";

import Logo from "app/NativeComponents/components/NavBar/Logo";

class TeamAccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_team_selector: false,
      team_search: "",
      access_team_loading: false
    };

    this.getTeamsData = this.getTeamsData.bind(this);
  }

  componentDidMount() {
    this.getTeamsData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token && this.props.token) {
      this.props.toggleUserRefresh(true);
      this.props.getUser({
        token: this.props.token,
        device: this.props.device,
        redirect: true
      });
      this.props.getSourceOfTruth({ token: this.props.token });
      this.props.tabNavigation({ slug: "dashboard" });
    }
  }

  getTeamsData() {
    const { token } = this.props;
    this.props.getWorkspaceTeams({
      token,
      onLoading: () => {
        this.setState({ teams_loading: true });
      },
      onError: () => {
        this.setState({ teams_loading: false });
      },
      onSuccess: results => {
        this.setState({
          teams_loading: false
        });
      }
    });
  }

  render() {
    const { colors, device, tab, source_of_truth, user, local_tab } =
      this.props;

    return (
      <PopoverMenu
        show={this.state.show_team_selector}
        no_swipe={true}
        no_cancel={true}
        onShow={s => {
          this.setState({
            show_team_selector: s
          });
        }}
        popover_width={400}
        popoverSheetTop={"80%"}
        popoverPlacement={"bottom"}
        popover_title={"Filter by Team"}
        includeCloseButton={true}
        disabled={false}
        renderComponent={options => {
          return (
            <Row>
              <InlineButton
                textColor={colors.white_text_color}
                noPress={true}
                pressedIn={options.hovering}
                style={{
                  borderRadius: 0,
                  margin: 0,

                  height: 60,
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: 0
                }}
                icon_right={
                  this.state.show_team_selector
                    ? "keyboard-arrow-up"
                    : "keyboard-arrow-down"
                }
              >
                <Row>
                  <Logo
                    {...this.props}
                    local_tab={local_tab}
                    workspace_image={
                      this.props.user?.workspace_settings?.workspace_image
                    }
                  />
                  <Copy style={{ color: colors.white_text_color }}>
                    {this.props.active_workspace_team?.team_name || "All Teams"}
                  </Copy>
                </Row>
              </InlineButton>
            </Row>
          );
        }}
        renderMenu={() => {
          return (
            <Wrapper style={{ alignSelf: "stretch" }}>
              <SearchBar
                title={"Search Teams"}
                onChange={value => {
                  this.setState({
                    team_search: value
                  });
                }}
                value={this.state.team_search}
                style={{
                  margin: 10
                }}
              />
              <KeyboardView
                style={
                  this.props.device == "desktop"
                    ? {
                        maxHeight: 300,
                        alignSelf: "stretch"
                      }
                    : {
                        alignSelf: "stretch"
                      }
                }
              >
                {this.state.teams_loading ? (
                  <SpinWrapper text={"Loading Teams"} />
                ) : (
                  <>
                    {this.props.workspace_teams.map(team => {
                      if (
                        this.state.team_search &&
                        !team.team_name
                          .toLowerCase()
                          .includes(this.state.team_search.toLowerCase())
                      ) {
                        return null;
                      }

                      return (
                        <SelectItem
                          key={team.id}
                          select_type="radio"
                          selected={
                            this.props.active_workspace_team?.id === team?.id
                          }
                          loading={this.state.access_team_loading === team.id}
                          subtext={
                            !team?.is_active
                              ? "Archived"
                              : !team?.finished_onboarding
                              ? "Invitation Pending"
                              : ""
                          }
                          onPress={() => {
                            if (
                              this.props.active_workspace_team?.id === team.id
                            ) {
                              this.setState(
                                {
                                  show_team_selector: false
                                },
                                () => {
                                  this.props.tabNavigation({
                                    slug: "dashboard"
                                  });
                                }
                              );
                            } else {
                              this.props.accessWorkspaceTeam({
                                token: this.props.token,
                                team_id: team.id,
                                device_type: this.props.device,
                                onLoading: () => {
                                  this.setState({
                                    access_team_loading: team.id
                                  });
                                },
                                onError: () => {
                                  this.setState({
                                    access_team_loading: false
                                  });
                                },
                                onSuccess: () => {
                                  this.setState(
                                    {
                                      show_team_selector: false,
                                      access_team_loading: false
                                    },
                                    () => {
                                      this.props.setActiveWorkspaceTeam(team);
                                    }
                                  );
                                }
                              });
                            }
                          }}
                        >
                          {team.team_name}
                        </SelectItem>
                      );
                    })}
                  </>
                )}
              </KeyboardView>

              <BottomNavBar>
                <InlineButton
                  button_type="full"
                  loading={
                    this.state.access_team_loading ===
                    this.props.main_workspace_user?.id
                  }
                  onPress={() => {
                    if (this.props.active_workspace_team?.id) {
                      this.props.accessWorkspaceTeam({
                        token: this.props.token,
                        team_id: this.props.main_workspace_user?.id,
                        device_type: this.props.device,
                        onLoading: () => {
                          this.setState({
                            access_team_loading:
                              this.props.main_workspace_user?.id
                          });
                        },
                        onError: () => {
                          this.setState({
                            access_team_loading: false
                          });
                        },
                        onSuccess: () => {
                          this.setState(
                            {
                              show_team_selector: false,
                              access_team_loading: false
                            },
                            () => {
                              this.props.setActiveWorkspaceTeam(null);
                            }
                          );
                        }
                      });
                    } else {
                      this.setState(
                        {
                          show_team_selector: false,
                          access_team_loading: false
                        },
                        () => {
                          this.props.setActiveWorkspaceTeam(null);
                          this.props.tabNavigation({
                            slug: "dashboard"
                          });
                        }
                      );
                    }
                  }}
                >
                  All Teams
                </InlineButton>
              </BottomNavBar>
            </Wrapper>
          );
        }}
        menu_items={null}
      />
    );
  }
}

const mapStateToProps = ({
  auth,
  native,
  settings,
  route,
  drawer,
  partner,
  tasks,
  feature_toggle,
  onboarding,
  billing,
  dialer,
  workspaces
}) => {
  const { token, user, user_partner_info } = auth;
  const { device, isIphoneX, isMobile, platform, desktopMobile } = native;
  const { colors } = settings;
  const { track_route } = route;
  const { tab } = drawer;
  const { is_partner } = partner;
  const { active_task_count } = tasks;
  const { source_of_truth, all_feature_metadata } = billing;
  const { mailCenter, listBuilderMap } = feature_toggle;
  const { current_call_session } = dialer;
  const { workspace_teams, active_workspace_team, main_workspace_user } =
    workspaces;
  return {
    user,
    user_partner_info,
    token,
    device,
    isIphoneX,
    isMobile,
    platform,
    desktopMobile,
    colors,
    track_route,
    tab,
    is_partner,
    active_task_count,
    onboarding,
    source_of_truth,
    mailCenter,
    listBuilderMap,
    all_feature_metadata,
    current_call_session,
    workspace_teams,
    active_workspace_team,
    main_workspace_user
  };
};

export default withRouter(
  connect(mapStateToProps, {
    tabNavigation,
    setModal,
    changeTab,
    pushSidePanel,
    searchDealMachine,
    selectActiveProperty,

    toggleModal,
    setActiveList,
    nextOnboardingStep,
    getDashboardStats,
    getWorkspaceTeams,
    setActiveWorkspaceTeam,
    accessWorkspaceTeam,
    getUser,
    getSourceOfTruth,
    toggleUserRefresh
  })(TeamAccess)
);
