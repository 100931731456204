import React, { Component } from "react";

import { CircleButton } from "app/NativeComponents/snippets";
import AssignLeadButton from "app/DealMachineCore/reuseable/AssignLeadButton";

class AssignButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      temp_value: null
    };
  }

  render() {
    const {
      colors,
      user,
      property,
      property: { deal },
      device
    } = this.props;
    const { temp_value } = this.state;
    return (
      <AssignLeadButton
        popoverPlacement="bottom"
        propertyUpdated={this.props.propertyUpdated}
        onLoading={({ loading, temp_value }) => {
          this.setState({
            loading,
            temp_value
          });
        }}
        renderComponent={({ pressedIn, hovering }) => {
          return (
            <CircleButton
              noPress={true}
              pressedIn={pressedIn || hovering}
              style={{ marginLeft: 0 }}
              tooltip={
                temp_value && temp_value.id != 0
                  ? "Assigned to " + temp_value.name
                  : deal.assigned_user && !temp_value
                  ? "Assigned to " + deal.assigned_user.name
                  : "Assign Lead Owner"
              }
              tooltipPlacement={"top"}
              icon="person"
              button_type="profile_pic"
              user={temp_value ? temp_value : deal.assigned_user}
              show_notification={true}
              notification_color={
                (deal.assigned_user || temp_value) && temp_value?.id != 0
                  ? colors.active_color
                  : colors.success_color
              }
              notification_icon={
                (deal.assigned_user || temp_value) && temp_value?.id != 0
                  ? "check"
                  : "add"
              }
            />
          );
        }}
        property={property}
      />
    );
  }
}

export default AssignButton;
