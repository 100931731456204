import React, { Component } from "react";
import { Wrapper, Copy, ProgressBar } from "app/NativeComponents/common";
import {
  InputBox,
  InlineTabs,
  AttentionBox,
  SmallLabel,
  SpinWrapper
} from "app/NativeComponents/snippets";

import { numberWithCommas, getTeamMembers } from "app/NativeActions";
import { store } from "app/store";
import { connect } from "react-redux";

const colors = store.getState().settings.colors;

class Body extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.loadTeamMembers();
  }

  loadTeamMembers() {
    if (!this.props?.team_members) {
      this.props.getTeamMembers({
        token: this.props.token,
        load_type: "load",
        type: "all",
        search: "",
        begin: 0
      });
    }
  }

  renderUpsellInfo() {
    let show = false;
    switch (this.props.invite_email_options.invite_type) {
      case "dealfinder":
        show = this.user_count_drivers_current >= this.user_count_drivers_limit;
        break;

      case "partner":
        show =
          this.user_count_partners_current >= this.user_count_partners_limit;
        break;
    }

    if (show)
      return (
        <>
          <Wrapper
            style={{
              paddingRight: 25,
              paddingLeft: 25,
              marginTop: 15,
              paddingBottom: 0
            }}
          >
            <Copy>
              Each additional team member over your plans limit will add an
              additional $19 to your recurring subscription.
            </Copy>
          </Wrapper>
        </>
      );
    else return null;
  }

  componentDidUpdate() {
    if (!!this.props.team_members || this.props?.team_members.length > 0) {
      clearInterval(this._load_interval);
    } else {
      this._load_interval = setTimeout(() => {
        this.loadTeamMembers();
        this.updateTeamCounts();
      }, 500);
    }
  }

  renderDriverCounts() {
    if (this.props.invite_email_options.invite_type !== "dealfinder")
      return null;

    if (this.user_count_drivers_current === -1) {
      this.loadTeamMembers();
      this.updateTeamCounts();
      return <SpinWrapper text="Loading Drivers..." />;
    } else if (this.user_count_drivers_current > this.user_count_drivers_limit)
      return <>{this.renderUpsellInfo()}</>;
    return (
      <>
        <Wrapper
          style={{
            paddingRight: 0,
            paddingLeft: 0,
            marginTop: 15,
            paddingBottom: 5
          }}
        >
          <SmallLabel>Drivers:</SmallLabel>
          <Wrapper
            style={{
              paddingRight: 25,
              paddingLeft: 25,
              marginTop: 0,
              paddingBottom: 5
            }}
          >
            <ProgressBar
              color={colors.success_color}
              style={{ marginTop: 10 }}
              backgroundColor={colors.white_color}
              width={200}
              progress={
                this.user_count_drivers_current / this.user_count_drivers_limit
              }
            />
            <Copy style={{ fontSize: 10 }}>
              {"Currently using "}
              {numberWithCommas(this.user_count_drivers_current)}
              {" / "}
              {numberWithCommas(this.user_count_drivers_limit)}
            </Copy>
          </Wrapper>
        </Wrapper>
        {this.renderUpsellInfo()}
      </>
    );
  }

  renderPartnerCounts() {
    if (this.props.invite_email_options.invite_type !== "partner") return null;
    if (this.user_count_partners_current === -1) {
      this.loadTeamMembers();
      this.updateTeamCounts();
      return <SpinWrapper text="Loading Partners..." />;
    } else if (
      this.user_count_partners_current > this.user_count_partners_limit
    )
      return <>{this.renderUpsellInfo()}</>;

    return (
      <>
        <Wrapper
          style={{
            paddingRight: 0,
            paddingLeft: 0,
            marginTop: 15,
            paddingBottom: 5
          }}
        >
          <SmallLabel>Partners:</SmallLabel>
          <Wrapper
            style={{
              paddingRight: 25,
              paddingLeft: 25,
              marginTop: 0,
              paddingBottom: 5
            }}
          >
            <ProgressBar
              color={colors.success_color}
              style={{ marginTop: 10 }}
              backgroundColor={colors.white_color}
              width={200}
              progress={
                this.user_count_partners_current /
                this.user_count_partners_limit
              }
            />
            <Copy style={{ fontSize: 10 }}>
              {"Currently using "}
              {numberWithCommas(this.user_count_partners_current)}
              {" / "}
              {numberWithCommas(this.user_count_partners_limit)}
            </Copy>
          </Wrapper>
        </Wrapper>
        {this.renderUpsellInfo()}
      </>
    );
  }

  componentWillUnmount() {
    clearInterval(this._load_interval);
  }

  render() {
    return (
      <>
        <InputBox
          input_ref={this._email_input}
          autoFocus={true}
          name="email_address"
          returnKeyType="done"
          placeholder={"Email Address"}
          onFocus={() => {}}
          onChange={value => {
            this.props.editInviteEmailOptions({ prop: "email", value });
          }}
          blurOnSubmit={true}
          value={this.props.invite_email_options?.email}
          input_type="text"
          require_confirm={true}
          has_changed={!!this.props.invite_email_options?.email}
          submit_button_primary={true}
          submit_button_title={"Invite"}
          onSubmit={this.props.sendInvite}
          onSubmitEditing={this.props.sendInvite}
          loading={this.props.loading}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth, team }) => {
  const { token, user } = auth;

  const { team_members, team_members_loading } = team;

  return {
    token,
    user,
    team_members,
    team_members_loading
  };
};

export default connect(mapStateToProps, {
  getTeamMembers
})(Body);
