import React, { Component } from "react";
import { connect } from "react-redux";

import { Device } from "@twilio/voice-sdk";
import {
  getTelnyxToken,
  getPhoneCall,
  phoneCall,
  setActiveDialerContact,
  resetActiveCall,
  updateActiveCall,
  setDialerConversation,
  setRejectedCall,
  showErrorMessage,
  playAudio,
  killAudio,
  callControl,
  killAllAudio
} from "app/NativeActions";
import TelnyxDialerInner from "app/DealMachineCore/components/DialerWidget/TelnyxDialerInner";
import { TelnyxRTC } from "@telnyx/webrtc";
import moment from "moment";

class TelnyxDeviceHandler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      incoming_call_id: null,
      incoming_call: null,
      loading_call: false,
      loading_active_call: false,
      telnyx_token_request_time: null,
      hide_purchase_upsell: false,
      mute: false,
      reset_connection: false
    };

    this.getIncomingCall = this.getIncomingCall.bind(this);
    this.clearIncomingCall = this.clearIncomingCall.bind(this);
    this.rejectIncomingCall = this.rejectIncomingCall.bind(this);
    this.createCallEvents = this.createCallEvents.bind(this);
    this.refreshTelnyxToken = this.refreshTelnyxToken.bind(this);
    this.hidePurchaseUpsell = this.hidePurchaseUpsell.bind(this);

    this.muteCall = this.muteCall.bind(this);
    this.endCall = this.endCall.bind(this);

    this._localstream = null;
    this._audio = null;

    this.startRinging = this.startRinging.bind(this);
    this.resetTelnyxDevice = this.resetTelnyxDevice.bind(this);
  }

  componentDidMount() {
    this.refreshTelnyxToken({ onSuccess: null });
    this.getActiveIncomingPhoneCall();
  }

  componentWillUnmount() {
    clearTimeout(this._kill_audio_timeout);
    killAudio({ audio: this._audio });
  }

  muteCall(mute, call = null) {
    this.setState(
      {
        mute: mute
      },
      () => {
        if (call) {
          if (this.state.mute) {
            call.muteAudio();
          } else {
            call.unmuteAudio();
          }
        }
      }
    );
  }

  endCall() {
    killAudio({ audio: this._audio });

    if (
      this.props.active_call?.call_id &&
      (this.props.active_call.call_status === "loading" ||
        this.props.active_call.call_status === "connected" ||
        this.props.active_call.call_status === "calling")
    ) {
      this.props.phoneCall({
        token: this.props.token,
        type: "end_call",
        call_id: this.props.active_call?.call_id
      });
    }

    if (
      !!this.props.active_call.call_status &&
      this.props.active_call.call_status !== "loading" &&
      this.props.active_call.call_status !== "call_ended"
    ) {
      playAudio({
        web_file: "/assets/sounds/disconnect.mp3",
        volume: 0.1,
        onPrepared: audio => {
          this._audio = audio;
        },
        onEnd: () => {
          killAllAudio();
        }
      });

      this._localstream = null;
      this.props.updateActiveCall({
        ...this.props.active_call,
        call_status: "call_ended"
      });
    }
  }

  startRinging() {
    if (this.props.active_call?.call_status !== "call_ended") {
      playAudio({
        web_file: "/assets/sounds/ringing.mp3",
        volume: 0.5,
        loop: true,
        onPrepared: audio => {
          this._audio = audio;
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.active_dialer_contact?.answered_on_another_device &&
      this.props.active_dialer_contact?.answered_on_another_device !==
        prevProps.active_dialer_contact?.answered_on_another_device &&
      this.state.incoming_call
    ) {
      try {
        this.state.incoming_call.disconnect();
      } catch (error) {
        console.error("Error in ending a call:", error);
      }
      this.clearIncomingCall();
    }
  }

  hidePurchaseUpsell() {
    this.setState({ hide_purchase_upsell: true });
  }

  resetTelnyxDevice() {
    this.setState({ reset_connection: true }, () => {
      if (this._device) {
        this._device.disconnect();
        this._device.off("telnyx.ready");
        this._device.off("telnyx.notification");
        this._device.off("telnyx.error");
        this._device.off("telnyx.socket.open");
        this._device.off("telnyx.socket.close");
      }
    });
  }

  refreshTelnyxToken({ onSuccess = null }) {
    if (
      !this.state.telnyx_token ||
      !this.state.telnyx_token_request_time ||
      moment().diff(moment(this.state.telnyx_token_request_time), "minutes") >
        5 ||
      !this._device ||
      this.state.reset_connection
    ) {
      this.props.getTelnyxToken({
        token: this.props.token,
        force_new:
          !this.state.telnyx_token || !this.state.telnyx_token_request_time,
        onLoading: () => {},
        onError: error => {
          console.error("Error in getting Telnyx token:", error);

          this.props.phoneCall({
            token: this.props.token,
            type: "log_error",
            error_message: error,
            to_phone_number: this.props.active_call?.to_phone_number,
            from_phone_number: this.props.active_call?.from_phone_number,
            dialer_provider: "telnyx"
          });
        },
        onSuccess: results => {
          if (!!results?.credential_id) {
            this._device = new TelnyxRTC({
              password: results?.password,
              login: results?.user_name,
              ringtoneFile: "/assets/sounds/incoming.mp3"
            });

            this.setState(
              {
                telnyx_token: results?.credential_id,
                telnyx_token_request_time: new Date()
              },
              () => {
                if (this._device) {
                  this._device.on("telnyx.ready", () => {
                    console.log("Telnyx client is ready.");
                  });

                  this._device.on("telnyx.error", d => {
                    console.error("Telnyx.Client Error: ", d);
                    if (!!d?.error?.message) {
                      this.props.phoneCall({
                        token: this.props.token,
                        type: "log_error",
                        error_message: d?.error?.message,
                        to_phone_number:
                          this.props.active_call?.to_phone_number,
                        from_phone_number:
                          this.props.active_call?.from_phone_number,
                        dialer_provider: "telnyx"
                      });
                    }
                  });

                  this._device.on("telnyx.socket.open", () => {
                    console.log("Telnyx socket connected.");
                  });

                  this._device.on("telnyx.socket.close", () => {
                    console.log("Telnyx socket disconnected.");
                    killAudio({ audio: this._audio });
                  });

                  this._device.on("telnyx.notification", notification => {
                    const call = notification?.call;

                    if (notification?.type === "callUpdate") {
                      switch (call.state) {
                        case "new":
                          this.muteCall(this.state.mute, call);

                          break;

                        case "early":
                          break;
                        case "trying":
                          if (
                            !this.props.active_dialer_contact.incoming_call &&
                            this.props.active_call?.call_status !==
                              "connected" &&
                            this.props.active_call?.call_status !==
                              "answered" &&
                            this.props.active_call?.call_status !== "call_ended"
                          ) {
                            this.props.updateActiveCall({
                              ...this.props.active_call,
                              call_status: "connected"
                            });
                          }

                          playAudio({
                            web_file: "/assets/sounds/outgoing.mp3",
                            volume: 0.1,
                            onPrepared: audio => {
                              this._audio = audio;
                            },
                            onEnd: this.startRinging
                          });

                          this._localstream = call.localStream;
                          //this._remoteStream = call.remoteStream;

                          break;

                        case "hangup":
                          if (
                            this.props.active_call?.call_status !== "call_ended"
                          ) {
                            this.endCall();
                          }

                          this._kill_audio_timeout = setTimeout(() => {
                            killAllAudio();
                          }, 1000);
                          break;

                        case "destroy":
                          //killAudio({ audio: this._audio });
                          this._kill_audio_timeout = setTimeout(() => {
                            killAllAudio();
                          }, 1000);

                          break;

                        case "active":
                          //if the call is active, we need to make sure we are not muted
                          killAudio({ audio: this._audio });

                          if (
                            this.props.active_call?.call_status !==
                              "answered" &&
                            this.props.active_call?.call_status !== "call_ended"
                          ) {
                            this.props.updateActiveCall({
                              ...this.props.active_call,
                              call_status: "answered"
                            });
                          }

                          this.muteCall(this.state.mute, call);

                          break;
                      }
                    }
                  });

                  this._device.remoteElement = "telnyxRemoteMedia";
                  this._device.enableMicrophone();

                  this._device.connect();

                  if (onSuccess) {
                    onSuccess();
                  }
                }
              }
            );
          }
        }
      });
    } else {
      if (onSuccess) {
        onSuccess();
      }
    }
  }

  getActiveIncomingPhoneCall() {
    this.props.getPhoneCall({
      token: this.props.token,
      type: "get_active_incoming_call",
      onLoading: () => {
        this.setState({
          loading_active_call: true
        });
      },
      onError: () => {
        this.setState(
          {
            loading_active_call: false
          },
          () => {}
        );
      },
      onSuccess: results => {
        const phone_call = results?.phone_call;

        if (phone_call && phone_call.answered) {
          this.setState(
            {
              loading_active_call: false,
              loading_call: false
            },
            () => {
              this.props.setActiveDialerContact({
                contact: {
                  ...phone_call?.contact,
                  selected_phone: {
                    number: phone_call?.to_phone_number
                  },
                  associated_lead: phone_call?.property,
                  one_off_call: true,
                  incoming_call: true,
                  answered_on_another_device: true
                },
                current_conversation: phone_call?.transcription,
                active_call: {
                  call_id: phone_call.call_id,
                  call_status: "connected"
                }
              });

              this.props.setDialerConversation({
                call_id: phone_call?.call_id,
                current_conversation: phone_call?.transcription,
                call_results: phone_call?.call_results,
                call_note: phone_call?.note
              });
            }
          );
        } else {
          this.setState(
            {
              loading_active_call: false
            },
            () => {}
          );
        }
      }
    });
  }

  createCallEvents(call) {
    call.on("disconnect", () => {
      console.log("disconnect");
      this.clearIncomingCall();
    });
    call.on("cancel", () => {
      console.log("cancel");

      this.clearIncomingCall();
    });

    call.on("error", error => {
      console.log("error");

      this.props.showErrorMessage(
        "There was an error with our phone provider. Please try again later",
        "Error"
      );

      this.props.phoneCall({
        token: this.props.token,
        type: "log_error",
        error_message: error,
        to_phone_number: this.props.active_call?.to_phone_number,
        from_phone_number: this.props.active_call?.from_phone_number,
        dialer_provider: "telnyx"
      });

      this.clearIncomingCall();
    });

    call.on("reject", () => {
      this.rejectIncomingCall();
    });
  }

  getIncomingCall(incoming_call_id, call) {
    if (!this.state.loading_call) {
      this.props.getPhoneCall({
        token: this.props.token,
        type: "get_phone_call",
        call_id: incoming_call_id,
        onLoading: () => {
          this.setState({
            loading_call: true
          });
        },
        onError: () => {
          this.setState({
            loading_call: false
          });
        },
        onSuccess: results => {
          const phone_call = results?.phone_call;
          if (phone_call) {
            if (!this.props.active_dialer_contact) {
              this.setState(
                {
                  loading_call: false,
                  incoming_call_id: incoming_call_id,
                  incoming_call_info: results?.phone_call,
                  incoming_call: call
                },
                () => {
                  this.props.resetActiveCall();

                  this.props.setActiveDialerContact({
                    contact: {
                      ...results?.phone_call?.contact,
                      selected_phone: {
                        number: phone_call?.to_phone_number
                      },
                      associated_lead: results?.phone_call?.property,
                      one_off_call: true,
                      incoming_call: true
                    },
                    active_call: {
                      call_id: phone_call.call_id,
                      call_status: "connected"
                    }
                  });
                }
              );
            } else {
              this.setState({
                loading_call: false,
                incoming_call_id: incoming_call_id,
                incoming_call_info: results?.phone_call,
                incoming_call: call
              });
            }
          }
        }
      });
    }
  }

  rejectIncomingCall(rejected = false) {
    this.props.setRejectedCall(this.state.incoming_call_id);

    this.setState({
      incoming_call_id: null,
      incoming_call: null,
      incoming_call_info: null
    });
  }
  clearIncomingCall() {
    this.setState({
      incoming_call_id: null,
      incoming_call: null,
      incoming_call_info: null
    });
  }

  render() {
    if (this._device) {
      return (
        <>
          {this.props.active_dialer_contact ? (
            <>
              <TelnyxDialerInner
                telnyx_client={this._device}
                clearIncomingCall={this.clearIncomingCall}
                rejectIncomingCall={this.rejectIncomingCall}
                active_dialer_contact={this.props.active_dialer_contact}
                onboarding={this.props.onboarding}
                incoming_call={this.state.incoming_call}
                incoming_call_info={this.state.incoming_call_info}
                call_id={this.state.incoming_call_id}
                refreshTelnyxToken={this.refreshTelnyxToken}
                hide_purchase_upsell={this.state.hide_purchase_upsell}
                hidePurchaseUpsell={this.hidePurchaseUpsell}
                mute={this.state.mute}
                muteCall={this.muteCall}
                localStream={this._localstream}
                resetTelnyxDevice={this.resetTelnyxDevice}
              />
            </>
          ) : null}
        </>
      );
    }

    return null;
  }
}

const mapStateToProps = ({ auth, settings, dialer }) => {
  const { token, user, init } = auth;
  const { dark_mode, colors } = settings;
  const { active_dialer_contact, active_call } = dialer;
  return {
    token,
    user,
    dark_mode,
    colors,
    active_dialer_contact,
    active_call
  };
};

export default connect(mapStateToProps, {
  getTelnyxToken,
  getPhoneCall,
  setActiveDialerContact,
  resetActiveCall,
  updateActiveCall,
  setDialerConversation,
  setRejectedCall,
  showErrorMessage,
  phoneCall,
  callControl
})(TelnyxDeviceHandler);
