import {
  AUTH_FIELD_CHANGED,
  AUTH_UNLOAD,
  COMPLETE_TUTORIAL,
  COMPLETE_TUTORIAL_SUCCESS,
  ERROR_MESSAGE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  GET_DEAL_CREDITS,
  SET_OAUTH_INFO,
  GET_DEAL_CREDITS_SUCCESS,
  GET_MAIN_PLANS,
  GET_MAIN_PLANS_FAIL,
  GET_MAIN_PLANS_SUCCESS,
  GET_TERMS,
  GET_TERMS_FAIL,
  GET_TERMS_SUCCESS,
  GET_TUTORIAL_SUCCESS,
  GET_USER,
  INIT_APP,
  IS_LOADING,
  LOGIN_USER,
  LOGIN_USER_FAIL,
  LOGIN_USER_SUCCESS,
  REGISTER_USER,
  REGISTER_USER_FAIL,
  REGISTER_USER_SUCCESS,
  SET_TRACKING_EVENT,
  SET_LOGINS,
  SUCCESS_MESSAGE,
  TOGGLE_ACCOUNT_ACTION_SHEET,
  TOGGLE_COPY,
  TOGGLE_ONBOARDING,
  TRIGGER_LOGOUT,
  UPDATE_USER_SETTINGS,
  UPDATE_USER_SETTINGS_FAIL,
  UPDATE_USER_SETTINGS_SUCCESS,
  TOGGLE_USER_REFRESH,
  START_REDIRECT
} from "app/DealMachineCore/types";

import {
  appRedirect,
  saveData,
  setModal,
  toggleModal,
  openUrl,
  executeSourceOfTruth,
  logout,
  getData
} from "app/NativeActions";
import { store } from "app/store";
import API from "app/DealMachineCore/apis/DealMachineAPI";
import APIV2 from "app/DealMachineCore/apis/DealMachineAPIV2";

const api = API.create();
const apiv2 = APIV2.create();

export const toggleOnboarding = toggle => {
  return {
    type: TOGGLE_ONBOARDING,
    payload: toggle
  };
};

export const initApp = start => {
  return {
    type: INIT_APP,
    payload: start
  };
};
export const toggleCopy = toggle => {
  return {
    type: TOGGLE_COPY,
    payload: toggle
  };
};
export const setOAuthInfo = oauthInfo => {
  return {
    type: SET_OAUTH_INFO,
    payload: oauthInfo
  };
};

export const toggleUserRefresh = toggle => {
  return {
    type: TOGGLE_USER_REFRESH,
    payload: toggle
  };
};

export const getMainPlans = () => {
  return dispatch => {
    dispatch({ type: GET_MAIN_PLANS });
    api.plans().then(response => {
      if (response.problem != null) {
        getMainPlansFail(dispatch, response.problem);
      } else if (response.data.error != false) {
        getMainPlansFail(dispatch, response.data.error);
      } else {
        getAnalyticsSuccess(dispatch, response.data.results);
      }
    });
  };
};

const getMainPlansFail = (dispatch, error) => {
  dispatch({ type: GET_MAIN_PLANS_FAIL, payload: error });
};

const getAnalyticsSuccess = (dispatch, plans) => {
  dispatch({
    type: GET_MAIN_PLANS_SUCCESS,
    payload: { plans }
  });
};

export const authFieldChanged = ({ prop, value }) => {
  return {
    type: AUTH_FIELD_CHANGED,
    payload: { prop, value }
  };
};
export const authUnload = () => {
  return {
    type: AUTH_UNLOAD
  };
};

const getParamFromURL = parameterName => {
  let result = null,
    tmp = [];
  const device = store.getState().native.device;

  if (device == "desktop") {
    window.location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
  }
  return result;
};

export const registerUser = ({
  email,
  password,
  phone,
  check_phone = 1,
  firstname,
  lastname,
  company,
  image,
  city,
  accepted_terms,
  affiliate_partner = 0,
  promo,
  from_campaign,
  from_source,
  branch_id,
  interested_in,
  signupKey = "dealmachine123",
  team_id = null,
  oAuthToken,
  oAuthIdToken,
  apple_id,
  deals_last_12_mo_signup,
  first_promoter_ref,
  first_promoter_cookie,
  hubspot_cookie,
  hubspot_page_url,
  hubspot_page_title,
  ip_address,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    const visitor_token = await getData("visitor_token");

    dispatch({ type: REGISTER_USER });

    onLoading();

    //get seo variables on web
    let google_analytics_client_id = "";
    let referring_domain = "";
    let utm_referrer = "";

    const device = store.getState().native.device;
    let platform = store.getState().native.platform;
    const desktopMobile = store.getState().native.desktopMobile;
    if (desktopMobile && device == "desktop") {
      platform = "mobile_web";
    }

    if (device == "desktop") {
      if (document) {
        referring_domain = document.referrer;
      }
      if (window.ga) {
        google_analytics_client_id = window.ga.getAll()[0].get("clientId");
      }
      utm_referrer = getParamFromURL("utm_referrer");
    }
    apiv2
      .signup({
        email,
        password,
        phone,
        check_phone,
        firstname,
        lastname,
        company,
        image,
        city,
        accepted_terms,
        affiliate_partner,
        team_id,
        promo,
        from_campaign,
        from_source,
        branch_id,
        device,
        platform,
        interested_in,
        google_analytics_client_id,
        referring_domain,
        utm_referrer,
        signupKey,
        oAuthToken,
        oAuthIdToken,
        apple_id,
        deals_last_12_mo_signup,
        first_promoter_ref:
          !!promo && !first_promoter_ref
            ? promo.toLowerCase()
            : first_promoter_ref,
        first_promoter_cookie,
        hubspot_cookie,
        hubspot_page_url,
        hubspot_page_title,
        ip_address,
        visitor_token
      })
      .then(response => {
        if (response.problem != null) {
          registerUserFail(dispatch, response.problem);
          onError();
        } else if (response.data.error != false) {
          registerUserFail(dispatch, response.data.error);
          onError();
        } else {
          saveData({ prop: "token", value: response.data.results.token });

          registerUserSuccess({
            dispatch: dispatch,
            user: response.data.results.user,
            billing: response.data.results.billing,
            plans: [],
            team: [],
            notifications: response.data.results.notifications,
            partner: response.data.results.partner,
            token: response.data.results.token,
            is_partner: response.data.results.is_partner,
            accepted_affiliate_latest_terms:
              response.data.results.accepted_affiliate_latest_terms,
            partner_info: response.data.results.partner_info,
            dealfinder_page: response.data.results.dealfinder_page,
            team_link_defaults: response.data.results.team_link_defaults,
            default_preset: response.data.results.default_preset,
            affiliate_partner: affiliate_partner
          });
          executeSourceOfTruth({
            token: response.data.results.token,
            dispatch
          });

          onSuccess(response.data.results);

          if (!!promo && !first_promoter_ref && device == "desktop") {
            let url = new URL(window.location.href);
            url.searchParams.append("fpr", promo?.toLowerCase());
            window.location.href = url;
          }
        }
      });
  };
};
export const setLogins = value => {
  return dispatch => {
    dispatch({ type: SET_LOGINS, payload: value });
  };
};
export const loginUser = ({
  email,
  password,
  device,
  initialRedirect,
  oAuthToken,
  oAuthIdToken,
  platform,
  apple_id,
  branch_recovery_token,
  onSuccess = null,
  onError = null,
  onLoading = null,
  no_error_message = false
}) => {
  return async dispatch => {
    const visitor_token = await getData("visitor_token");

    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }
    dispatch({ type: LOGIN_USER });

    apiv2
      .login(
        email,
        password,
        device,
        oAuthToken,
        oAuthIdToken,
        apple_id,
        branch_recovery_token,
        visitor_token
      )
      .then(response => {
        if (response.problem != null) {
          if (onError) {
            onError();
          }
          if (!no_error_message) {
            loginUserFail(dispatch, response.problem);
          }
        } else if (response.data.error != false) {
          if (onError) {
            onError();
          }
          if (!no_error_message) {
            loginUserFail(dispatch, response.data.error);
          }
        } else {
          if (
            response.data.results.apple_id &&
            response.data.results.apple_email
          ) {
            dispatch({
              type: SET_OAUTH_INFO,
              payload: {
                profileObj: {
                  email: response.data.results.apple_email,
                  apple_id: response.data.results.apple_id
                }
              }
            });
          }
          saveData({ prop: "token", value: response.data.results.token });
          saveData({ prop: "access_type", value: response.data.results.user.access_type ? response.data.results.user.access_type : "" });
          loginUserSuccess({
            dispatch: dispatch,
            token: response.data.results.token,
            user: response.data.results.user,
            billing: response.data.results.billing,
            plans: [],
            team: [],
            notifications: response.data.results.notifications,
            partner: response.data.results.partner,
            is_partner: response.data.results.is_partner,
            accepted_affiliate_latest_terms:
              response.data.results.accepted_affiliate_latest_terms,
            partner_info: response.data.results.partner_info,
            dealfinder_page: response.data.results.dealfinder_page,
            team_link_defaults: response.data.results.team_link_defaults,
            default_preset: response.data.results.default_preset,
            type: "login",
            initialRedirect
          });
          executeSourceOfTruth({
            token: response.data.results.token,
            dispatch
          });

          if (device == "desktop") {
            if (window.gtag) {
              window.gtag("config", "G-QBYS9G6QDB", {
                user_id: response.data.results?.user?.id
              });
            }
          }

          if (onSuccess) {
            onSuccess(response.data.results);
          }
        }
      });
  };
};
export const triggerLogout = trigger => {
  return {
    type: TRIGGER_LOGOUT,
    payload: trigger
  };
};

const loginUserFail = (dispatch, error) => {
  dispatch({ type: LOGIN_USER_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const loginUserSuccess = ({
  dispatch,
  user,
  billing = null,
  plans = [],
  team = [],
  partner,
  notifications,
  token,
  is_partner,
  accepted_affiliate_latest_terms,
  partner_info,
  type,
  dealfinder_page,
  team_link_defaults,
  default_preset,
  initialRedirect
}) => {
  dispatch({ type: IS_LOADING, payload: false });
  dispatch({
    type: LOGIN_USER_SUCCESS,
    payload: {
      user,
      billing,
      plans,
      team,
      notifications,
      is_partner,
      accepted_affiliate_latest_terms,
      partner_info,
      partner,
      token,
      dealfinder_page,
      team_link_defaults,
      default_preset
    }
  });
};

const registerUserFail = (dispatch, error) => {
  dispatch({ type: REGISTER_USER_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const registerUserSuccess = ({
  dispatch,
  user,
  billing,
  plans,
  team,
  partner,
  dealfinder_page,
  team_link_defaults,
  token,
  affiliate_partner
}) => {
  dispatch({ type: IS_LOADING, payload: false });

  dispatch({
    type: REGISTER_USER_SUCCESS,
    payload: {
      user,
      billing,
      plans,
      team,
      partner,
      token,
      dealfinder_page,
      team_link_defaults
    }
  });
};

export const toggleAccountActionSheet = sheet => {
  return {
    type: TOGGLE_ACCOUNT_ACTION_SHEET,
    payload: sheet
  };
};

export const getUser = ({
  token,
  device,
  redirect,
  initialRedirect,
  loading,
  include_deal_credits,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return async dispatch => {
    const visitor_token = await getData("visitor_token");

    if (onLoading) {
      onLoading();
    } else {
      if (loading) {
        dispatch({ type: IS_LOADING, payload: true });
      }
      dispatch({ type: GET_USER });
    }

    apiv2
      .getUser(token, device, include_deal_credits, visitor_token)
      .then(response => {
        if (response.problem != null) {
          dispatch({ type: INIT_APP, payload: true });
          loginUserFail(dispatch, response.problem);
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
          dispatch({ type: IS_LOADING, payload: false });
          onError();
        } else if (response.data.error != false) {
          dispatch({ type: INIT_APP, payload: true });
          loginUserFail(dispatch, response.data.error);
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
          dispatch({ type: IS_LOADING, payload: false });
          onError();
        } else {
          loginUserSuccess({
            dispatch: dispatch,
            token: token,
            user: response.data.results.user,
            billing: response.data.results.billing,
            plans: [],
            team: [],
            notifications: response.data.results?.notifications,
            partner: response.data.results?.partner,
            is_partner: response.data.results?.is_partner,
            accepted_affiliate_latest_terms:
              response.data.results.accepted_affiliate_latest_terms,
            partner_info: response.data.results?.partner_info,
            dealfinder_page: response.data.results?.dealfinder_page,
            team_link_defaults: response.data.results?.team_link_defaults,
            default_preset: response.data.results?.default_preset,
            type: redirect || loading ? "login" : "load",
            initialRedirect
          });
          executeSourceOfTruth({
            token: response.data.results.token,
            dispatch
          });

          if (onSuccess) {
            onSuccess(response.data.results);
          }
        }
      });

    dispatch({ type: GET_DEAL_CREDITS });
    apiv2.getBilling({ token, type: "deal_credits" }).then(response => {
      if (!response.error && response.data) {
        dispatch({
          type: GET_DEAL_CREDITS_SUCCESS,
          payload: response.data.results
        });
      }
    });
  };
};

export const forgotPassword = ({
  email,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    dispatch({ type: FORGOT_PASSWORD });

    api.forgot(email).then(response => {
      if (response.problem != null) {
        forgotPasswordFail(dispatch, response.problem);
        onError();
      } else if (response.data.error != false) {
        forgotPasswordFail(dispatch, response.data.error);
        onError();
      } else {
        forgotPasswordSuccess(dispatch);
        onSuccess();
      }
    });
  };
};

export const resetPasswordCheck = ({ email, token, type }) => {
  return dispatch => {
    dispatch({ type: IS_LOADING, payload: true });
    api.resetpasswordcheck(email, token, type).then(response => {
      if (response.problem != null) {
        forgotPasswordFail(dispatch, response.problem);
      } else if (response.data.error != false) {
        forgotPasswordFail(dispatch, response.data.error);
      } else {
        dispatch({ type: IS_LOADING, payload: false });
      }
    });
  };
};

const forgotPasswordFail = (dispatch, error) => {
  dispatch({ type: FORGOT_PASSWORD_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
  appRedirect({ dispatch, redirect: "goBack", payload: { type: "login" } });
};

const forgotPasswordSuccess = dispatch => {
  dispatch({
    type: FORGOT_PASSWORD_SUCCESS
  });
  dispatch({
    type: SUCCESS_MESSAGE,
    payload: {
      message: "A link has been sent to your email address.",
      title: "Success!"
    }
  });

  appRedirect({ dispatch, redirect: "goBack", payload: { type: "login" } });
};

export const resetPassword = ({
  type,
  payload,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    api.resetpassword(type, payload).then(response => {
      if (response.problem != null) {
        resetPasswordFail(dispatch, response.problem);
        onError();
      } else if (response.data.error != false) {
        resetPasswordFail(dispatch, response.data.error);
        onError();
      } else {
        saveData({ prop: "token", value: response.data.results.token });

        loginUserSuccess({
          dispatch: dispatch,
          token: response.data.results.token,
          user: response.data.results.user,
          billing: response.data.results.billing,
          plans: [],
          team: [],
          notifications: response.data.results.notifications,
          partner: response.data.results.partner,
          is_partner: response.data.results.is_partner,
          accepted_affiliate_latest_terms:
            response.data.results.accepted_affiliate_latest_terms,
          partner_info: response.data.results.partner_info,
          dealfinder_page: response.data.results.dealfinder_page,
          team_link_defaults: response.data.results.team_link_defaults,
          default_preset: response.data.results.default_preset,
          type: "login"
        });
        executeSourceOfTruth({
          token: response.data.results.token,
          dispatch
        });
        onSuccess(response.data.results);
      }
    });
  };
};

const resetPasswordFail = (dispatch, error) => {
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

export const getTermsOfService = ({ type }) => {
  return dispatch => {
    dispatch({ type: GET_TERMS });

    api.termsOfService(type).then(response => {
      if (response.data.error != false) {
        dispatch({ type: GET_TERMS_FAIL, payload: response.data.error });
      } else {
        dispatch({ type: GET_TERMS_SUCCESS, payload: response.data.results });
      }
    });
  };
};

export const getTutorial = ({ token, tutorial_slug }) => {
  return dispatch => {
    apiv2.getTutorial({ token, tutorial_slug }).then(response => {
      if (response.problem != null) {
      } else if (response.data.error != false) {
      } else {
        dispatch({
          type: GET_TUTORIAL_SUCCESS,
          payload: response.data.results
        });
      }
    });
  };
};

export const setFcmToken = (token, fcm_token) => {
  return dispatch => {
    apiv2.setFcmToken(token, fcm_token).then(response => {});
  };
};

export const updateUserSettings = ({
  token,
  type,
  value,
  show_loading = false,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    if (show_loading && !onLoading) {
      dispatch({
        type: IS_LOADING,
        payload: true
      });
    } else if (onLoading) {
      onLoading();
    }

    dispatch({
      type: UPDATE_USER_SETTINGS
    });

    apiv2
      .editUser(token, "user_settings", {
        user_settings_type: type,
        user_settings_value: value
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: UPDATE_USER_SETTINGS_FAIL,
            payload: response.problem
          });

          if (onError) {
            onError();
          } else if (show_loading) {
            dispatch({
              type: IS_LOADING,
              payload: false
            });
          }
        } else if (response.data.error != false) {
          dispatch({
            type: UPDATE_USER_SETTINGS_FAIL,
            payload: response.data.error
          });
          if (onError) {
            onError();
          } else if (show_loading) {
            dispatch({
              type: IS_LOADING,
              payload: false
            });
          }
        } else {
          if (onSuccess) {
            onSuccess(response.data.results);
          }

          dispatch({
            type: UPDATE_USER_SETTINGS_SUCCESS,
            payload: response.data.results
          });

          if (type == "starting_point") {
            dispatch({
              type: SET_TRACKING_EVENT,
              payload: "starting_point_changed"
            });
          }
          if (show_loading) {
            dispatch({
              type: IS_LOADING,
              payload: false
            });
          }
        }
      });
  };
};

export const getUserFromEmailToken = ({
  token,
  device,
  offer_slug,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {},
  redirect = null
}) => {
  return dispatch => {
    onLoading();

    apiv2
      .getUserFromEmailToken({ token, offer_slug, device })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          dispatch({ type: INIT_APP, payload: true });
          saveData({
            prop: "token",
            value: response.data.results.token,
            onSuccess: () => {
              loginUserSuccess({
                dispatch: dispatch,
                token: response.data.results.token,
                user: response.data.results.user,
                notifications: response.data.results.notifications,
                partner: response.data.results.partner,
                is_partner: response.data.results.is_partner,
                accepted_affiliate_latest_terms:
                  response.data.results.accepted_affiliate_latest_terms,
                partner_info: response.data.results.partner_info,
                dealfinder_page: response.data.results.dealfinder_page,
                team_link_defaults: response.data.results.team_link_defaults,
                default_preset: response.data.results.default_preset
              });
              executeSourceOfTruth({
                token: response.data.results.token,
                dispatch
              });
              onSuccess(response.data.results);
            }
          });
        }
      });
  };
};

export const getUserFromRecoveryToken = ({
  token,
  device,
  type,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {},
  redirect_url = null
}) => {
  return dispatch => {
    onLoading();

    apiv2.getUserFromRecoveryToken({ token, type, device }).then(response => {
      if (response.problem != null) {
        dispatch({ type: INIT_APP, payload: true });
        loginUserFail(dispatch, response.problem);
        onError(response.problem);
        dispatch({ type: TRIGGER_LOGOUT, payload: true });
      } else if (response.data.error != false) {
      } else {
        dispatch({ type: INIT_APP, payload: true });
        saveData({
          prop: "token",
          value: response.data.results.token,
          onSuccess: () => {
            loginUserSuccess({
              dispatch: dispatch,
              token: response.data.results.token,
              user: response.data.results.user,
              notifications: response.data.results.notifications,
              partner: response.data.results.partner,
              is_partner: response.data.results.is_partner,
              accepted_affiliate_latest_terms:
                response.data.results.accepted_affiliate_latest_terms,
              partner_info: response.data.results.partner_info,
              dealfinder_page: response.data.results.dealfinder_page,
              team_link_defaults: response.data.results.team_link_defaults,
              default_preset: response.data.results.default_preset
            });
            executeSourceOfTruth({
              token: response.data.results.token,
              dispatch
            });

            onSuccess(response.data.results);
            if (!!redirect_url) {
              dispatch({ type: START_REDIRECT, payload: redirect_url });
            }
          }
        });
      }
    });
  };
};

export const getTeamReferralInfo = ({
  token,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    apiv2.getTeamReferralInfo({ token }).then(response => {
      if (response.problem != null) {
        onError(response.problem);
      } else if (response.data.error != false) {
        onError(response.data.error);
      } else {
        onSuccess(response.data.results);
      }
    });
  };
};

export const getUserFromAppleAuth = ({
  appleToken,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    apiv2.getUserFromAppleAuth({ appleToken }).then(response => {
      if (response.problem != null) {
        onError(response.problem);
      } else if (response.data.error != false) {
        onError(response.data.error);
      } else {
        onSuccess(response.data.results);
      }
    });
  };
};
