import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, Wrapper, Copy, Bold } from "app/NativeComponents/common";
import {
  getContactsCount,
  getContacts,
  pushSidePanel,
  replaceSidePanel,
  changeTab
} from "app/NativeActions";

import MainHeader from "app/DealMachineCore/reuseable/MainHeader";
import SearchAndFilter from "./SearchAndFilter";

class Contacts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_contacts: [],
      selected_all: false,
      is_selecting: false,
      filtered_count: 0,
      filtered_count_loading: false,
      search: "",
      search_loading: false,
      selected_pages: [],
      updated_data_count: 0,
      updated_data_count_loading: false,
      accepted_all_changes: false,

      contacts: [],
      contacts_sort_by: "contact_name_asc",
      contacts_page: 1,
      contacts_limit: 25,
      contacts_refreshing: false,
      contacts_loading: false
    };

    this.getContacts = this.getContacts.bind(this);
    this.checkContact = this.checkContact.bind(this);
    this.checkAllContacts = this.checkAllContacts.bind(this);
    this.checkAllContactsOnPage = this.checkAllContactsOnPage.bind(this);

    this.updateSearch = this.updateSearch.bind(this);
    this.toggleSelecting = this.toggleSelecting.bind(this);
  }

  checkAllContacts() {
    const { selected_contacts, selected_all } = this.state;

    if (selected_all) {
      this.setState({
        selected_all: false,
        selected_contacts: [],
        selected_pages: []
      });
    } else {
      this.setState({
        selected_all: true,
        selected_contacts: [],
        selected_pages: []
      });
    }
  }

  checkAllContactsOnPage(page) {
    const { selected_all, selected_contacts, selected_pages, contacts } =
      this.state;

    let sa = false;

    if (!selected_all) {
      let found_page;

      for (let i = 0; i < selected_pages.length; i++) {
        if (selected_pages[i] == page) {
          found_page = true;
        }
      }

      if (found_page) {
        this.setState({
          selected_pages: selected_pages.filter(p => p !== page)
        });
      } else {
        this.setState({
          selected_pages: [...selected_pages, page]
        });
      }

      let sl = JSON.parse(JSON.stringify(selected_contacts));
      for (let i = 0; i < contacts.length; i++) {
        let found_contact = false;

        for (let j = 0; j < selected_contacts.length; j++) {
          if (selected_contacts[j]?.id == contacts[i]?.id) {
            found_contact = true;
          }
        }

        if (found_page) {
          //remove all contacts on page
          if (found_contact) {
            sl = sl.filter(({ id }) => id != contacts[i]?.id);
          }
        } else {
          //add all contacts on page
          if (!found_contact) {
            sl.push(contacts[i]);
          }
        }
      }
      this.setState({
        selected_contacts: sl
      });
    } else {
      this.setState({
        selected_all: false,
        selected_pages: [],
        selected_contacts: []
      });
    }
  }

  toggleSelecting(toggle) {
    this.setState({
      is_selecting: toggle,
      selected_all: false,
      selected_contacts: []
    });
  }

  checkContact(contact) {
    const { selected_contacts, selected_all } = this.state;
    if (!selected_all) {
      let found_contact = false;
      for (let i = 0; i < selected_contacts.length; i++) {
        if (selected_contacts[i]?.deal?.id == contact?.deal?.id) {
          found_contact = true;
        }
      }
      if (found_contact) {
        this.setState({
          is_selecting: true,
          selected_contacts: selected_contacts.filter(
            ({ deal }) => deal.id !== contact?.deal?.id
          )
        });
      } else {
        this.setState({
          is_selecting: true,
          selected_contacts: [...selected_contacts, contact]
        });
      }
    }
  }

  clearAll() {
    this.setState({
      is_selecting: false,
      selected_all: false,
      selected_contacts: [],
      selected_pages: [],
      selecting: false
    });
  }

  componentDidMount() {
    if (this.props.device == "mobile") {
      this.props.changeTab("connect");
    }
    if (this.props.token) {
      this.getContacts({ load_type: "load" });
      this.getFilteredContactCount();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { token, trigger_contacts_count_reload, trigger_contacts_reload } =
      this.props;
    const {
      search,
      contacts_sort_by,
      contacts,
      contacts_page,
      contacts_limit,
      contacts_refreshing,
      contacts_loading
    } = this.state;

    if (token !== prevProps.token) {
      this.getContacts({ load_type: "load" });
      this.getFilteredContactCount();
    }

    if (
      prevState.contacts_sort_by !== contacts_sort_by ||
      prevState.contacts_limit !== contacts_limit ||
      token !== prevProps.token
    ) {
      this.getContacts({ load_type: "load", page: 1 });
    } else if (prevState.search !== search) {
      this.clearAll();
      this.getContacts({
        load_type: "refresh",
        search: search,
        page: 1
      });
      this.getFilteredContactCount();
    } else if (
      prevState.contacts_page !== contacts_page ||
      prevState.contacts_limit !== contacts_limit
    ) {
      this.getContacts({
        load_type: "load",
        page: contacts_page == 0 ? 1 : contacts_page
      });
    } else if (
      prevProps.trigger_contacts_reload !== trigger_contacts_reload &&
      trigger_contacts_reload &&
      !contacts_refreshing &&
      !contacts_loading
    ) {
      this.getContacts({ load_type: "refresh" });
      this.getFilteredContactCount();
    } else if (
      prevProps.trigger_contacts_count_reload !==
        trigger_contacts_count_reload &&
      trigger_contacts_count_reload
    ) {
      this.getContactCount();
      this.getFilteredContactCount();
    }
  }

  getFilteredContactCount() {
    const { search } = this.state;
    this.setState({
      filtered_count_loading: true
    });
    this.props.getContactsCount({
      token: this.props.token,
      search,
      onLoading: () => {
        this.setState({
          filtered_count_loading: true
        });
      },
      onError: () => {
        this.setState({
          filtered_count_loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          filtered_count: results,
          filtered_count_loading: false
        });
      }
    });
  }

  getContacts({ load_type = "load", page = 1 }) {
    const { token } = this.props;

    const {
      search_loading,
      search,
      contacts_refreshing,
      contacts_loading,
      contacts_begin,
      contacts_limit,
      contacts_page,
      contacts_sort_by
    } = this.state;

    if (!contacts_refreshing && !contacts_loading) {
      this.props.getContacts({
        token,
        load_type: load_type,
        sort_by: contacts_sort_by
          ? contacts_sort_by.slug + "_" + contacts_sort_by.type
          : "date_created_desc",
        //filter_lists: this.props.filter_lists,
        search,
        begin:
          load_type === "load_more"
            ? contacts_begin
            : page
            ? contacts_limit * (page - 1)
            : contacts_limit * (contacts_page - 1),
        limit: contacts_limit,
        page: page,
        onLoading: () => {
          this.setState({
            search_loading: load_type === "search" ? true : search_loading
          });
        },
        onError: () => {
          this.setState({
            search_loading: false
          });
        },
        onSuccess: () => {
          this.setState({
            search_loading: false
          });
        }
      });
    }
  }

  updateSearch(search) {
    this.setState({
      search,
      search_loading: search !== this.state.search ? true : false
    });
  }

  renderInner() {
    const { selected_contacts, selected_all, search, search_loading } =
      this.state;
    const { user, colors, applied_filter, isMobile, device, active_list_tab } =
      this.props;

    if (!isMobile && device === "desktop") {
      return (
        <>
          <SearchAndFilter
            {...this.props}
            selected_contacts={selected_contacts}
            selected_all={selected_all}
            clearAll={this.clearAll}
            search={search}
            search_loading={search_loading}
            updateSearch={this.updateSearch}
            filtered_count={this.state.filtered_count}
          />

          <Wrapper
            style={{
              position: "relative",
              overflow: "hidden",
              flex: 1
            }}
          ></Wrapper>
        </>
      );
    }

    return null;
  }

  render() {
    const { all_contacts_count, all_contacts_count_loading } = this.state;
    const { colors, isMobile, user } = this.props;
    return (
      <Container
        fill={true}
        style={{
          display: "flex",
          overflow: "hidden",
          backgroundColor: colors.card_color
        }}
        title={"Contacts"}
      >
        <MainHeader title={"Connect"} />

        {this.renderInner()}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { device, window_height, isMobile } = native;
  const { colors, dark_mode } = settings;

  return {
    token,
    user,
    device,
    isMobile,
    window_height,
    colors,
    dark_mode
  };
};

export default connect(mapStateToProps, {
  getContactsCount,
  getContacts,
  pushSidePanel,
  replaceSidePanel,

  changeTab
})(Contacts);
