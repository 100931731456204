import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Spin,
  Icon,
  Copy,
  Bold,
  Row,
  Scroll,
  Card
} from "app/NativeComponents/common";

import {
  InlineButton,
  IconButton,
  ConfirmInlineButton
} from "app/NativeComponents/snippets";

import {
  dismissAiListBuilder,
  addListToListTabs,
  tabNavigation,
  getSourceOfTruth,
  updateList,
  cancelAiListBuild
} from "app/NativeActions";

import StatBlock from "./StatBlock";
import ScannedProperty from "./ScannedProperty";
class BuildingList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      avg_times: [],
      last_time: null,
      cancel_building: false
    };

    this.scroll_ref = React.createRef();

    this.getEstimatedTime = this.getEstimatedTime.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.ai_list_building?.list?.building !==
      prevProps.ai_list_building?.list?.building
    ) {
      this.props.refreshMap();
      this.props.getSourceOfTruth({
        token: this.props.token,
        no_loading: true
      });
    }

    if (
      this.props.ai_list_building?.total_counter !==
      prevProps.ai_list_building?.total_counter
    ) {
      this.calculateAverageTimePerProperty();
    }
  }

  componentWillUnmount() {
    clearTimeout(this._average_time_per_property_timeout);
  }

  calculateAverageTimePerProperty() {
    const time_diff = this.state.last_time
      ? Date.now() - this.state.last_time
      : null;

    this.setState(prevState => {
      let newAvgTimes = time_diff
        ? [...prevState.avg_times, time_diff]
        : prevState.avg_times;

      if (newAvgTimes.length > 200) {
        newAvgTimes = newAvgTimes.slice(-200);
      }

      return {
        last_time: Date.now(),
        avg_times: newAvgTimes
      };
    });
  }

  getEstimatedTime() {
    //if there are no avg_times then return --
    if (!this.state.avg_times || this.state.avg_times.length == 0) {
      return "--";
    }

    //get the average time per property in milliseconds
    let avg_time_per_chunk = 1000; // Default to 1000 milliseconds (1 second)
    if (this.state.avg_times && this.state.avg_times.length > 0) {
      avg_time_per_chunk =
        this.state.avg_times.reduce((a, b) => a + b, 0) /
        this.state.avg_times.length;
    }

    const list = this.props.ai_list_building?.list;

    const remaining_properties =
      list?.estimated_count - this.props.ai_list_building?.total_counter;

    let chunk_size = this.props.ai_list_building?.chunk_size
      ? this.props.ai_list_building?.chunk_size
      : 50;

    const estimatedMilliseconds =
      (remaining_properties / chunk_size) * avg_time_per_chunk;

    // Convert milliseconds to hours and minutes
    const hours = Math.floor(estimatedMilliseconds / 3600000);
    const minutes = Math.floor((estimatedMilliseconds % 3600000) / 60000);

    if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ${minutes} minute${
        minutes !== 1 ? "s" : ""
      }`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""}`;
    } else {
      return "Less than a minute";
    }
  }

  render() {
    const { isMobile, colors, ai_list_building, ai_list_scan_animations } =
      this.props;

    const list = ai_list_building?.list;

    let progress = Math.ceil(
      (parseInt(ai_list_building?.total_counter) /
        parseInt(list?.estimated_count)) *
        100
    );
    if (progress > 100) {
      progress = 100;
    }

    return (
      <>
        <Wrapper>
          <Row
            style={{
              justifyContent: "center",
              height: 150,
              alignItems: "center"
            }}
          >
            {ai_list_scan_animations.map((scan, index) => {
              if (!!scan.street_view_image || !!scan.satellite_image) {
                return (
                  <ScannedProperty
                    key={"scan_" + scan.property_id + "_" + index}
                    scan={scan}
                  />
                );
              }
              return null;
            })}
          </Row>
        </Wrapper>
        <Card
          style={{
            backgroundColor: colors.card_color,
            width: "100%",
            margin: 0,
            marginBottom: 7
          }}
        >
          <Wrapper style={{ height: 85, position: "relative" }}>
            <Wrapper
              style={{
                backgroundColor: colors.card_color,
                width: "100%"
              }}
            >
              <Wrapper
                className={"list-builder-progress-bar"}
                style={{
                  backgroundColor: ai_list_building?.cancel_building
                    ? colors.error_color_muted
                    : colors.success_color_muted,
                  width: list?.building == 1 ? progress + "%" : "100%",
                  height: 85
                }}
              />
            </Wrapper>
            <Wrapper
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                justifyContent: "center"
              }}
            >
              <Row>
                <Wrapper style={{ flex: 1 }}>
                  <Row>
                    {list?.building == 1 ? (
                      <Wrapper style={{ paddingLeft: 25 }}>
                        <Spin size={"small"} />
                      </Wrapper>
                    ) : (
                      <Wrapper style={{ paddingLeft: 25 }}>
                        <Icon
                          icon={
                            ai_list_building?.cancel_building
                              ? "error"
                              : "check-circle"
                          }
                          color={
                            ai_list_building?.cancel_building
                              ? colors.error_color
                              : colors.success_color
                          }
                        />
                      </Wrapper>
                    )}
                    <Wrapper
                      style={{ paddingRight: 25, paddingLeft: 25, flex: 1 }}
                    >
                      <Copy style={{ color: colors.actionable_text_color }}>
                        <Bold>{list?.title}</Bold>
                      </Copy>
                      {ai_list_building?.cancel_building ? (
                        <></>
                      ) : list?.building == 1 &&
                        list?.estimated_count > 1000 ? (
                        <Copy
                          style={{
                            color: colors.light_text_color,
                            fontSize: 12
                          }}
                        >
                          This can take a while. We'll send you an email when
                          it's done. Estimated time: {this.getEstimatedTime()}
                        </Copy>
                      ) : null}
                    </Wrapper>
                  </Row>
                </Wrapper>
                <Row>
                  <StatBlock
                    title={"Matched"}
                    number={ai_list_building?.passed_counter}
                  />
                  <StatBlock
                    title={"Not Matched"}
                    number={ai_list_building?.failed_counter}
                  />
                </Row>

                {list?.lead_count > 0 || list?.building == 1 ? (
                  <InlineButton
                    onPress={() => {
                      this.props.addListToListTabs(list);
                      this.props.tabNavigation({ slug: "leads" });
                    }}
                    disabled={
                      (ai_list_building?.passed_counter === 0 &&
                        list?.building == 1) ||
                      (list?.lead_count == 0 && list?.building != 1)
                    }
                  >
                    {list?.building == 1 ? "View List So Far" : "View List"}
                  </InlineButton>
                ) : null}

                {list?.building == 1 && list?.estimated_count > 1000 ? (
                  <ConfirmInlineButton
                    require_confirm={true}
                    button_type="full"
                    confirm_text={"Really Cancel?"}
                    loading_text={"Cancelling"}
                    loading={this.state.cancel_building}
                    disabled={false}
                    tooltip={
                      "Canceling your list will stop the list from building beyond what has already been matched or is currently being scanned. You will still be charged for items currently being scanned."
                    }
                    tooltipPlacement={"top"}
                    onPress={() => {
                      this.props.updateList({
                        token: this.props.token,
                        type: "cancel_build",
                        list_id: list?.id,
                        onLoading: () => {
                          this.setState({
                            cancel_building: true
                          });
                        },
                        onError: () => {
                          this.setState({
                            cancel_building: false
                          });
                        },
                        onSuccess: results => {
                          if (results?.lists && results?.lists.length > 0) {
                            this.setState(
                              {
                                cancel_building: false
                              },
                              () => {
                                this.props.cancelAiListBuild(results.lists[0]);
                              }
                            );
                          } else {
                            this.setState({
                              cancel_building: false
                            });
                          }
                        }
                      });
                    }}
                  >
                    Cancel
                  </ConfirmInlineButton>
                ) : (
                  <IconButton
                    onPress={() => {
                      this.props.dismissAiListBuilder();
                    }}
                    disabled={list?.building == 1}
                    tooltip={
                      list?.building == 1
                        ? "You can only use AI Vision Builder to build one list at a time"
                        : "Dismiss"
                    }
                    tooltipPlacement={"top"}
                    icon={"close"}
                  />
                )}
              </Row>
            </Wrapper>
          </Wrapper>
        </Card>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, list }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { device, isMobile } = native;
  const { ai_list_building, ai_list_scan_animations } = list;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    ai_list_building,
    ai_list_scan_animations
  };
};

export default connect(mapStateToProps, {
  dismissAiListBuilder,
  addListToListTabs,
  tabNavigation,
  getSourceOfTruth,
  updateList,
  cancelAiListBuild
})(BuildingList);
