import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  Row,
  Wrapper,
  ProgressBar,
  Copy,
  Bold,
  Card
} from "app/NativeComponents/common";
import {
  NewHeader,
  SelectItem,
  InlineButton,
  SmallLabel,
  BottomNavBar,
  AttentionBox,
  ConfirmInlineButton
} from "app/NativeComponents/snippets";

import {
  getSourceOfTruth,
  popSidePanel,
  pushSidePanel,
  numberWithCommas,
  openUrl,
  AppConfig,
  displayIntercom,
  manageSubscription,
  toggleChurnBuster,
  popAllSidePanels,
  showSuccess,
  renderDate,
  checkIfUserHasMetadata,
  getMobileRedirectSessionLink,
  submitMarketingForm
} from "app/NativeActions";

class PlanSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reactivateLoading: false,
      loading_sales_form: false,
      submitted_sales_form: false
    };

    this.resetCustomerInfo = this.resetCustomerInfo.bind(this);
  }
  componentDidMount() {
    this.props.getSourceOfTruth({
      token: this.props.token,
      no_loading: true,
      redirect_url: false
    });

    //if (this.props.device !== "desktop") {
    this.props.getMobileRedirectSessionLink({
      token: this.props.token,
      type: "churnkey",
      onLoading: () => {},
      onError: () => {},
      onSuccess: results => {
        this.setState({
          redirect_url: results?.url
        });
      }
    });
    //}
  }

  handleBack() {
    this.props.popSidePanel();
  }

  resetCustomerInfo() {
    this.props.popAllSidePanels();
    this.props.getSourceOfTruth({
      token: this.props.token,
      no_loading: true
    });
  }

  renderSubText(meta_slug) {
    const { source_of_truth } = this.props;
    if (source_of_truth && source_of_truth?.subscription?.products) {
      for (let i = 0; i < source_of_truth?.subscription?.products.length; i++) {
        if (
          source_of_truth?.subscription?.products[i]?.product_slug == meta_slug
        ) {
          if (
            source_of_truth?.subscription?.products[i]?.payment_type == "iap"
          ) {
            return "Purchased Through App Store";
          } else {
            return "Purchased";
          }
        }
      }
    }

    return "";
  }

  renderBuyButton(meta_slug, upgrade = false) {
    const { source_of_truth, all_feature_metadata, all_meta } = this.props;

    if (
      source_of_truth?.subscription?.metadata &&
      !source_of_truth?.subscription?.metadata[meta_slug] &&
      this.props.platform !== "ios"
    ) {
      return (
        <InlineButton
          primary={true}
          onPress={() => {
            let feature_upsell_info = null;
            for (let i = 0; i < all_feature_metadata?.length; i++) {
              if (all_feature_metadata[i].slug == meta_slug) {
                feature_upsell_info = all_feature_metadata[i];
              }
            }
            this.props.pushSidePanel({
              slug: "unlock_feature_modal",
              overlay: true,
              data: {
                feature_upsell_info
              }
            });
          }}
        >
          {upgrade ? "Upgrade" : "Add To Subscription"}
        </InlineButton>
      );
    }

    return null;
  }

  render() {
    const { source_of_truth, all_feature_metadata, user, platform, isMobile } =
      this.props;

    const phone_plan = checkIfUserHasMetadata("dialer");

    if (source_of_truth?.subscription) {
      return (
        <Container>
          <NewHeader
            title={"Plans & Licenses"}
            leftButton={{
              icon: "arrow-back",
              onPress: () => {
                this.handleBack();
              }
            }}
          />
          {source_of_truth?.subscription?.metadata_group ==
            "all_together_package" ||
          source_of_truth?.subscription?.metadata_group ==
            "dealmachine_unlimited" ? (
            <>
              <KeyboardView style={{ flex: 1 }}>
                <SmallLabel>Plan:</SmallLabel>

                <SelectItem
                  select_type="none"
                  noPress={true}
                  selected={true}
                  renderBottom={
                    source_of_truth?.subscription?.metadata &&
                    source_of_truth?.subscription?.metadata
                      ?.total_number_of_leads != "unlimited"
                      ? () => {
                          const current_limit = parseInt(
                            source_of_truth?.subscription?.metadata
                              ?.total_number_of_leads
                          );
                          let count = parseInt(
                            source_of_truth?.current_limits
                              ?.total_number_of_leads?.amount
                          );
                          if (count > current_limit) {
                            count = current_limit;
                          }

                          const list_builder_current_limit =
                            source_of_truth?.subscription?.metadata
                              ?.leads_added_from_list_builder;

                          let list_builder_count = parseInt(
                            source_of_truth?.current_limits
                              ?.number_of_leads_added_from_list_builder?.amount
                          );
                          if (
                            list_builder_count >
                              parseInt(list_builder_current_limit) &&
                            list_builder_current_limit !== "unlimited"
                          ) {
                            list_builder_count = parseInt(
                              list_builder_current_limit
                            );
                          }

                          const export_current_limit = parseInt(
                            source_of_truth?.subscription?.metadata
                              ?.export_limit
                          );

                          let export_count = parseInt(
                            source_of_truth?.current_limits
                              ?.number_of_leads_exported?.amount
                          );
                          if (export_count > export_current_limit) {
                            export_count = export_current_limit;
                          }

                          return (
                            <>
                              <Wrapper
                                style={{
                                  paddingRight: 25,
                                  paddingLeft: 25,
                                  marginTop: -15,
                                  paddingBottom: 25
                                }}
                              >
                                <ProgressBar
                                  color={this.props.colors.success_color}
                                  width={200}
                                  progress={
                                    parseInt(count) > 0
                                      ? parseInt(count) /
                                        parseInt(current_limit)
                                      : 0
                                  }
                                />
                                <Copy style={{ fontSize: 10 }}>
                                  {numberWithCommas(count)} /{" "}
                                  {numberWithCommas(current_limit)}{" "}
                                  {"Total managed leads with live updates"}
                                </Copy>
                              </Wrapper>

                              {list_builder_current_limit !== "unlimited" ? (
                                <Wrapper
                                  style={{
                                    paddingRight: 25,
                                    paddingLeft: 25,
                                    marginTop: -15,
                                    paddingBottom: 25
                                  }}
                                >
                                  <ProgressBar
                                    color={this.props.colors.success_color}
                                    width={200}
                                    progress={
                                      parseInt(list_builder_count) > 0
                                        ? parseInt(list_builder_count) /
                                          parseInt(list_builder_current_limit)
                                        : 0
                                    }
                                  />
                                  <Copy style={{ fontSize: 10 }}>
                                    {numberWithCommas(list_builder_count)} /{" "}
                                    {numberWithCommas(
                                      list_builder_current_limit
                                    )}{" "}
                                    {"Leads added from List Builder. Resets on " +
                                      renderDate(
                                        source_of_truth?.subscription
                                          ?.monthly_limit_end_date
                                      ) +
                                      "."}
                                  </Copy>
                                </Wrapper>
                              ) : null}

                              <Wrapper
                                style={{
                                  paddingRight: 25,
                                  paddingLeft: 25,
                                  marginTop: -15,
                                  paddingBottom: 25
                                }}
                              >
                                <ProgressBar
                                  color={this.props.colors.success_color}
                                  width={200}
                                  progress={
                                    parseInt(export_count) > 0
                                      ? parseInt(export_count) /
                                        parseInt(export_current_limit)
                                      : 0
                                  }
                                />
                                <Copy style={{ fontSize: 10 }}>
                                  {numberWithCommas(export_count) +
                                    " / " +
                                    numberWithCommas(export_current_limit) +
                                    " exported leads. Resets on " +
                                    renderDate(
                                      source_of_truth?.subscription
                                        ?.monthly_limit_end_date
                                    ) +
                                    "."}
                                </Copy>
                              </Wrapper>
                            </>
                          );
                        }
                      : null
                  }
                  renderRight={() => {
                    if (!checkIfUserHasMetadata("paid_plan")) {
                      return (
                        <Row>
                          <InlineButton
                            primary={true}
                            onPress={() => {
                              this.props.pushSidePanel({
                                slug: "purchase_plan_prompt",
                                overlay_modal: true,
                                locked: true,
                                data: {
                                  title:
                                    "Close More Deals With DealMachine Pro.",
                                  dismiss_button:
                                    "No thanks. Let me continue without Pro.",
                                  no_free: true,
                                  event_slug: "in_app_plan_settings"
                                },
                                locked: true
                              });
                            }}
                          >
                            Get Pro
                          </InlineButton>
                        </Row>
                      );
                    } else if (!this.props.isMobile) {
                      return (
                        <Row>
                          <InlineButton
                            primary={true}
                            onPress={() => {
                              this.props.pushSidePanel({
                                slug: "switch_plan_modal",
                                overlay: true,
                                focus_side_panel: true,
                                data: {
                                  title: "Switch Your Plan.",
                                  dismiss_button:
                                    "No thanks. Let me continue with my current plan",
                                  no_free: true,
                                  switch_plan: true
                                }
                              });
                            }}
                          >
                            Switch Plan
                          </InlineButton>
                        </Row>
                      );
                    } else if (
                      source_of_truth?.subscription?.metadata &&
                      source_of_truth?.subscription?.metadata
                        ?.total_number_of_leads != "unlimited"
                    ) {
                      let feature_upsell_info = null;
                      for (let i = 0; i < all_feature_metadata?.length; i++) {
                        if (
                          all_feature_metadata[i].slug ==
                            "total_number_of_leads" &&
                          all_feature_metadata[i].unlock_limit ==
                            source_of_truth?.subscription?.metadata
                              ?.total_number_of_leads
                        ) {
                          feature_upsell_info = all_feature_metadata[i];
                        }
                      }

                      if (feature_upsell_info && !user?.workspace_id) {
                        return (
                          <Row>
                            <InlineButton
                              primary={true}
                              onPress={() => {
                                this.props.pushSidePanel({
                                  slug: "unlock_feature_modal",
                                  overlay: true,
                                  data: {
                                    feature_upsell_info
                                  }
                                });
                              }}
                            >
                              Upgrade
                            </InlineButton>
                          </Row>
                        );
                      }
                    }
                  }}
                  subtext={this.renderSubText("dealmachine")}
                >
                  {!!source_of_truth?.subscription?.metadata?.main_plan_name
                    ? source_of_truth?.subscription?.metadata.main_plan_name
                    : "DealMachine Plan"}
                </SelectItem>

                {phone_plan ? (
                  <SelectItem
                    select_type="icon"
                    select_icon={"call"}
                    icon="keyboard-arrow-right"
                    onPress={() => {
                      this.props.pushSidePanel({
                        slug: "dialer_licenses",
                        overlay: true
                      });
                    }}
                    description={"Save money when you buy a dialer license."}
                  >
                    Dialer Licenses
                  </SelectItem>
                ) : null}

                {this.props.user?.can_mimic_team_owner == 1 &&
                !this.props.user?.workspace_id ? (
                  <Card>
                    {this.state.submitted_sales_form ? (
                      <Wrapper style={{ padding: 25 }}>
                        <Copy style={{ textAlign: "center" }}>
                          Thanks for reaching out! We'll be in touch soon!
                        </Copy>
                      </Wrapper>
                    ) : (
                      <SelectItem
                        noPress={true}
                        select_type="none"
                        renderRight={() => {
                          return (
                            <InlineButton
                              loading={this.state.loading_sales_form}
                              primary={true}
                              onPress={() => {
                                this.props.submitMarketingForm({
                                  token: this.props.token,
                                  type: "contact_sales",
                                  onLoading: () => {
                                    this.setState({
                                      loading_sales_form: true
                                    });
                                  },
                                  onError: () => {},
                                  onSuccess: () => {
                                    this.setState({
                                      loading_sales_form: false,
                                      submitted_sales_form: true
                                    });
                                  }
                                });
                              }}
                            >
                              Contact Sales
                            </InlineButton>
                          );
                        }}
                        description="Contact us for custom pricing for larger scale operations."
                      >
                        Need more data? Have a larger team?
                      </SelectItem>
                    )}
                  </Card>
                ) : null}
              </KeyboardView>
            </>
          ) : source_of_truth?.subscription?.metadata_group ==
            "new_funnel_plan" ? (
            <>
              <KeyboardView style={{ flex: 1 }}>
                <SmallLabel>Plan:</SmallLabel>

                <SelectItem
                  select_type="none"
                  noPress={true}
                  selected={true}
                  renderBottom={
                    source_of_truth?.subscription?.metadata &&
                    source_of_truth?.subscription?.metadata
                      ?.total_number_of_leads != "unlimited"
                      ? () => {
                          const current_limit = parseInt(
                            source_of_truth?.subscription?.metadata
                              ?.total_number_of_leads
                          );
                          let count = parseInt(
                            source_of_truth?.current_limits
                              ?.total_number_of_leads?.amount
                          );
                          if (count > current_limit) {
                            count = current_limit;
                          }

                          return (
                            <>
                              <Wrapper
                                style={{
                                  paddingRight: 25,
                                  paddingLeft: 25,
                                  marginTop: -15,
                                  paddingBottom: 25
                                }}
                              >
                                <ProgressBar
                                  color={this.props.colors.success_color}
                                  width={200}
                                  progress={
                                    parseInt(count) > 0
                                      ? parseInt(count) /
                                        parseInt(current_limit)
                                      : 0
                                  }
                                />
                                <Copy style={{ fontSize: 10 }}>
                                  {numberWithCommas(count)} /{" "}
                                  {numberWithCommas(current_limit)}{" "}
                                  {"Total managed leads with live updates"}
                                </Copy>
                              </Wrapper>
                            </>
                          );
                        }
                      : null
                  }
                  renderRight={
                    !source_of_truth?.uses_in_app_purchases
                      ? () => {
                          return (
                            <Row>
                              <InlineButton
                                primary={true}
                                onPress={() => {
                                  this.props.pushSidePanel({
                                    slug: "plan_details",
                                    overlay: true,
                                    data: {
                                      source_of_truth
                                    }
                                  });
                                }}
                              >
                                Details
                              </InlineButton>
                            </Row>
                          );
                        }
                      : null
                  }
                  subtext={this.renderSubText("dealmachine")}
                >
                  {!!source_of_truth?.subscription?.metadata?.main_plan_name
                    ? source_of_truth?.subscription?.metadata.main_plan_name
                    : "DealMachine Plan"}
                </SelectItem>

                <SmallLabel>Add-ons:</SmallLabel>

                <SelectItem
                  select_type="none"
                  noPress={true}
                  subtext={this.renderSubText("lists")}
                  selected={true}
                  renderBottom={
                    source_of_truth?.subscription?.metadata &&
                    source_of_truth?.subscription?.list_builder &&
                    source_of_truth?.subscription?.metadata
                      ?.leads_added_from_list_builder != "unlimited"
                      ? () => {
                          const current_limit = parseInt(
                            source_of_truth?.subscription?.metadata
                              ?.leads_added_from_list_builder
                          );

                          let count = parseInt(
                            source_of_truth?.current_limits
                              ?.number_of_leads_added_from_list_builder?.amount
                          );
                          if (count > current_limit) {
                            count = current_limit;
                          }

                          return (
                            <>
                              <Wrapper
                                style={{
                                  paddingRight: 25,
                                  paddingLeft: 25,
                                  marginTop: -15,
                                  paddingBottom: 25
                                }}
                              >
                                <ProgressBar
                                  color={this.props.colors.success_color}
                                  width={200}
                                  progress={
                                    parseInt(count) > 0
                                      ? parseInt(count) /
                                        parseInt(current_limit)
                                      : 0
                                  }
                                />
                                <Copy style={{ fontSize: 10 }}>
                                  {numberWithCommas(count)} /{" "}
                                  {numberWithCommas(current_limit)}{" "}
                                  {"Leads added from List Builder"}
                                </Copy>
                              </Wrapper>
                            </>
                          );
                        }
                      : null
                  }
                  renderRight={() => {
                    if (
                      source_of_truth?.subscription?.metadata &&
                      !source_of_truth?.subscription?.metadata?.list_builder &&
                      !source_of_truth?.uses_in_app_purchases
                    ) {
                      return (
                        <Row>
                          <InlineButton
                            primary={true}
                            onPress={() => {
                              let feature_upsell_info = null;
                              for (
                                let i = 0;
                                i < all_feature_metadata?.length;
                                i++
                              ) {
                                if (
                                  all_feature_metadata[i].slug == "list_builder"
                                ) {
                                  feature_upsell_info = all_feature_metadata[i];
                                }
                              }
                              this.props.pushSidePanel({
                                slug: "unlock_feature_modal",
                                overlay: true,
                                data: {
                                  feature_upsell_info
                                }
                              });
                            }}
                          >
                            Add To Subscription
                          </InlineButton>
                        </Row>
                      );
                    } else if (
                      source_of_truth?.subscription?.metadata &&
                      source_of_truth?.subscription?.metadata
                        ?.leads_added_from_list_builder != "unlimited"
                    ) {
                      let feature_upsell_info = null;
                      for (let i = 0; i < all_feature_metadata?.length; i++) {
                        if (
                          all_feature_metadata[i].slug ==
                            "leads_added_from_list_builder" &&
                          all_feature_metadata[i].unlock_limit ==
                            source_of_truth?.subscription?.metadata
                              ?.leads_added_from_list_builder
                        ) {
                          feature_upsell_info = all_feature_metadata[i];
                        }
                      }
                      if (feature_upsell_info && !user?.workspace_id) {
                        return (
                          <Row>
                            <InlineButton
                              primary={true}
                              onPress={() => {
                                this.props.pushSidePanel({
                                  slug: "unlock_feature_modal",
                                  overlay: true,
                                  data: {
                                    feature_upsell_info
                                  }
                                });
                              }}
                            >
                              Upgrade
                            </InlineButton>
                          </Row>
                        );
                      }
                    }
                    return null;
                  }}
                >
                  {source_of_truth?.subscription?.metadata.list_builder_plus
                    ? "List Builder Pro"
                    : "List Builder"}
                </SelectItem>
              </KeyboardView>
            </>
          ) : source_of_truth?.subscription?.metadata_group == "daves_plan" ? (
            <>
              <KeyboardView style={{ flex: 1 }}>
                <SmallLabel>Plan:</SmallLabel>

                <SelectItem
                  select_type="none"
                  noPress={true}
                  selected={true}
                  renderBottom={
                    source_of_truth?.subscription?.metadata &&
                    source_of_truth?.subscription?.metadata
                      ?.total_number_of_leads != "unlimited"
                      ? () => {
                          const current_limit = parseInt(
                            source_of_truth?.subscription?.metadata
                              ?.total_number_of_leads
                          );
                          let count = parseInt(
                            source_of_truth?.current_limits
                              ?.total_number_of_leads?.amount
                          );
                          if (count > current_limit) {
                            count = current_limit;
                          }

                          return (
                            <>
                              <Wrapper
                                style={{
                                  paddingRight: 25,
                                  paddingLeft: 25,
                                  marginTop: -15,
                                  paddingBottom: 25
                                }}
                              >
                                <ProgressBar
                                  color={this.props.colors.success_color}
                                  width={200}
                                  progress={
                                    parseInt(count) > 0
                                      ? parseInt(count) /
                                        parseInt(current_limit)
                                      : 0
                                  }
                                />
                                <Copy style={{ fontSize: 10 }}>
                                  {numberWithCommas(count)} /{" "}
                                  {numberWithCommas(current_limit)}{" "}
                                  {"Total managed leads with live updates"}
                                </Copy>
                              </Wrapper>
                            </>
                          );
                        }
                      : null
                  }
                  renderRight={
                    !source_of_truth?.uses_in_app_purchases
                      ? () => {
                          return (
                            <Row>
                              <InlineButton
                                primary={true}
                                onPress={() => {
                                  this.props.pushSidePanel({
                                    slug: "plan_details",
                                    overlay: true,
                                    data: {
                                      source_of_truth
                                    }
                                  });
                                }}
                              >
                                Details
                              </InlineButton>
                            </Row>
                          );
                        }
                      : null
                  }
                  subtext={this.renderSubText("dealmachine")}
                >
                  {!!source_of_truth?.subscription?.metadata?.main_plan_name
                    ? source_of_truth?.subscription?.metadata.main_plan_name
                    : "DealMachine Plan"}
                </SelectItem>

                <SmallLabel>Upgrades:</SmallLabel>

                <SelectItem
                  select_type="none"
                  noPress={true}
                  subtext={this.renderSubText("driving")}
                  selected={true}
                  renderBottom={
                    source_of_truth?.subscription?.metadata &&
                    source_of_truth?.subscription?.metadata
                      ?.leads_added_from_map != "unlimited"
                      ? () => {
                          const current_limit = parseInt(
                            source_of_truth?.subscription?.metadata
                              ?.leads_added_from_map
                          );
                          let count = parseInt(
                            source_of_truth?.current_limits
                              ?.number_of_leads_added_from_map?.amount
                          );
                          if (count > current_limit) {
                            count = current_limit;
                          }

                          return (
                            <>
                              <Wrapper
                                style={{
                                  paddingRight: 25,
                                  paddingLeft: 25,
                                  marginTop: -15,
                                  paddingBottom: 25
                                }}
                              >
                                <ProgressBar
                                  color={this.props.colors.success_color}
                                  width={200}
                                  progress={
                                    parseInt(count) > 0
                                      ? parseInt(count) /
                                        parseInt(current_limit)
                                      : 0
                                  }
                                />
                                <Copy style={{ fontSize: 10 }}>
                                  {numberWithCommas(count)} /{" "}
                                  {numberWithCommas(current_limit)}{" "}
                                  {"Leads added from the map"}
                                </Copy>
                              </Wrapper>
                            </>
                          );
                        }
                      : null
                  }
                  renderRight={
                    !source_of_truth?.uses_in_app_purchases
                      ? () => {
                          if (
                            source_of_truth?.subscription?.metadata &&
                            !source_of_truth?.subscription?.metadata
                              ?.route_tracking
                          ) {
                            return (
                              <Row>
                                <InlineButton
                                  primary={true}
                                  onPress={() => {
                                    let feature_upsell_info = null;
                                    for (
                                      let i = 0;
                                      i < all_feature_metadata?.length;
                                      i++
                                    ) {
                                      if (
                                        all_feature_metadata[i].slug ==
                                        "route_tools"
                                      ) {
                                        feature_upsell_info =
                                          all_feature_metadata[i];
                                      }
                                    }
                                    this.props.pushSidePanel({
                                      slug: "unlock_feature_modal",
                                      overlay: true,
                                      data: {
                                        feature_upsell_info
                                      }
                                    });
                                  }}
                                >
                                  Add To Subscription
                                </InlineButton>
                              </Row>
                            );
                          } else if (
                            source_of_truth?.subscription?.metadata &&
                            !source_of_truth?.subscription?.metadata
                              ?.driver_recruitment
                          ) {
                            let feature_upsell_info = null;
                            for (
                              let i = 0;
                              i < all_feature_metadata?.length;
                              i++
                            ) {
                              if (
                                all_feature_metadata[i].slug ==
                                "driver_recruitment"
                              ) {
                                feature_upsell_info = all_feature_metadata[i];
                              }
                            }
                            if (feature_upsell_info && !user?.workspace_id) {
                              return (
                                <Row>
                                  <InlineButton
                                    primary={true}
                                    onPress={() => {
                                      this.props.pushSidePanel({
                                        slug: "unlock_feature_modal",
                                        overlay: true,
                                        data: {
                                          feature_upsell_info
                                        }
                                      });
                                    }}
                                  >
                                    Upgrade
                                  </InlineButton>
                                </Row>
                              );
                            }
                          }
                          return null;
                        }
                      : null
                  }
                >
                  {source_of_truth?.subscription?.metadata.driver_recruitment
                    ? "Drive Teams"
                    : "Route Tools"}
                </SelectItem>

                <SelectItem
                  select_type="none"
                  noPress={true}
                  subtext={this.renderSubText("lists")}
                  selected={true}
                  renderBottom={
                    source_of_truth?.subscription?.metadata &&
                    source_of_truth?.subscription?.list_builder &&
                    source_of_truth?.subscription?.metadata
                      ?.leads_added_from_list_builder != "unlimited"
                      ? () => {
                          const current_limit = parseInt(
                            source_of_truth?.subscription?.metadata
                              ?.leads_added_from_list_builder
                          );

                          let count = parseInt(
                            source_of_truth?.current_limits
                              ?.number_of_leads_added_from_list_builder?.amount
                          );
                          if (count > current_limit) {
                            count = current_limit;
                          }

                          return (
                            <>
                              <Wrapper
                                style={{
                                  paddingRight: 25,
                                  paddingLeft: 25,
                                  marginTop: -15,
                                  paddingBottom: 25
                                }}
                              >
                                <ProgressBar
                                  color={this.props.colors.success_color}
                                  width={200}
                                  progress={
                                    parseInt(count) > 0
                                      ? parseInt(count) /
                                        parseInt(current_limit)
                                      : 0
                                  }
                                />
                                <Copy style={{ fontSize: 10 }}>
                                  {numberWithCommas(count)} /{" "}
                                  {numberWithCommas(current_limit)}{" "}
                                  {"Leads added from List Builder"}
                                </Copy>
                              </Wrapper>
                            </>
                          );
                        }
                      : null
                  }
                  renderRight={() => {
                    if (
                      source_of_truth?.subscription?.metadata &&
                      !source_of_truth?.subscription?.metadata?.list_builder &&
                      !source_of_truth?.uses_in_app_purchases
                    ) {
                      return (
                        <Row>
                          <InlineButton
                            primary={true}
                            onPress={() => {
                              let feature_upsell_info = null;
                              for (
                                let i = 0;
                                i < all_feature_metadata?.length;
                                i++
                              ) {
                                if (
                                  all_feature_metadata[i].slug == "list_builder"
                                ) {
                                  feature_upsell_info = all_feature_metadata[i];
                                }
                              }
                              this.props.pushSidePanel({
                                slug: "unlock_feature_modal",
                                overlay: true,
                                data: {
                                  feature_upsell_info
                                }
                              });
                            }}
                          >
                            Add To Subscription
                          </InlineButton>
                        </Row>
                      );
                    } else if (
                      source_of_truth?.subscription?.metadata &&
                      source_of_truth?.subscription?.metadata
                        ?.leads_added_from_list_builder != "unlimited"
                    ) {
                      let feature_upsell_info = null;
                      for (let i = 0; i < all_feature_metadata?.length; i++) {
                        if (
                          all_feature_metadata[i].slug ==
                            "leads_added_from_list_builder" &&
                          all_feature_metadata[i].unlock_limit ==
                            source_of_truth?.subscription?.metadata
                              ?.leads_added_from_list_builder
                        ) {
                          feature_upsell_info = all_feature_metadata[i];
                        }
                      }
                      if (feature_upsell_info && !user?.workspace_id) {
                        return (
                          <Row>
                            <InlineButton
                              primary={true}
                              onPress={() => {
                                this.props.pushSidePanel({
                                  slug: "unlock_feature_modal",
                                  overlay: true,
                                  data: {
                                    feature_upsell_info
                                  }
                                });
                              }}
                            >
                              Upgrade
                            </InlineButton>
                          </Row>
                        );
                      }
                    }
                    return null;
                  }}
                >
                  {source_of_truth?.subscription?.metadata.list_builder_plus
                    ? "List Builder+"
                    : "List Builder"}
                </SelectItem>

                <SelectItem
                  select_type="none"
                  noPress={true}
                  subtext={this.renderSubText("mail")}
                  selected={true}
                  renderRight={() => {
                    if (
                      source_of_truth?.subscription?.metadata &&
                      !source_of_truth?.subscription?.metadata?.mail_designer &&
                      !source_of_truth?.uses_in_app_purchases
                    ) {
                      return (
                        <Row>
                          <InlineButton
                            primary={true}
                            onPress={() => {
                              let feature_upsell_info = null;
                              for (
                                let i = 0;
                                i < all_feature_metadata?.length;
                                i++
                              ) {
                                if (
                                  all_feature_metadata[i].slug ==
                                  "mail_designer"
                                ) {
                                  feature_upsell_info = all_feature_metadata[i];
                                }
                              }
                              this.props.pushSidePanel({
                                slug: "unlock_feature_modal",
                                overlay: true,
                                data: {
                                  feature_upsell_info
                                }
                              });
                            }}
                          >
                            Add To Subscription
                          </InlineButton>
                        </Row>
                      );
                    }
                    return null;
                  }}
                >
                  Mail+
                </SelectItem>

                <SelectItem
                  select_type="none"
                  noPress={true}
                  subtext={this.renderSubText("masterclass")}
                  selected={true}
                  renderRight={() => {
                    return (
                      <Row>{this.renderBuyButton("elite_master_class")}</Row>
                    );
                  }}
                >
                  Elite Masterclass
                </SelectItem>
                {this.props.user?.can_mimic_team_owner == 1 ? (
                  <SelectItem
                    select_type="none"
                    icon="keyboard-arrow-right"
                    onPress={() => {
                      this.props.pushSidePanel({
                        slug: "enter_promo_code",
                        overlay: true
                      });
                    }}
                  >
                    Enter Referral Code
                  </SelectItem>
                ) : null}
              </KeyboardView>
            </>
          ) : (
            <>
              <KeyboardView style={{ flex: 1 }}>
                <SelectItem
                  select_type="none"
                  noPress={true}
                  selected={true}
                  renderBottom={
                    source_of_truth?.subscription?.metadata &&
                    source_of_truth?.subscription?.metadata
                      ?.total_number_of_leads != "unlimited"
                      ? () => {
                          const current_limit = parseInt(
                            source_of_truth?.subscription?.metadata
                              ?.total_number_of_leads
                          );
                          let count = parseInt(
                            source_of_truth?.current_limits
                              ?.total_number_of_leads?.amount
                          );
                          if (count > current_limit) {
                            count = current_limit;
                          }

                          return (
                            <>
                              <Wrapper
                                style={{
                                  paddingRight: 25,
                                  paddingLeft: 25,
                                  marginTop: -15,
                                  paddingBottom: 25
                                }}
                              >
                                <ProgressBar
                                  color={this.props.colors.success_color}
                                  width={200}
                                  progress={
                                    parseInt(count) > 0
                                      ? parseInt(count) /
                                        parseInt(current_limit)
                                      : 0
                                  }
                                />
                                <Copy style={{ fontSize: 10 }}>
                                  {numberWithCommas(count)} /{" "}
                                  {numberWithCommas(current_limit)}{" "}
                                  {"Total managed leads with live updates"}
                                </Copy>
                              </Wrapper>
                            </>
                          );
                        }
                      : null
                  }
                >
                  Managed Leads
                </SelectItem>

                <SmallLabel>My Plan:</SmallLabel>

                <SelectItem
                  select_type="none"
                  noPress={true}
                  subtext={this.renderSubText("driving")}
                  selected={true}
                  renderBottom={
                    source_of_truth?.subscription?.metadata &&
                    source_of_truth?.subscription?.metadata
                      ?.leads_added_from_map != "unlimited"
                      ? () => {
                          const current_limit = parseInt(
                            source_of_truth?.subscription?.metadata
                              ?.leads_added_from_map
                          );
                          let count = parseInt(
                            source_of_truth?.current_limits
                              ?.number_of_leads_added_from_map?.amount
                          );
                          if (count > current_limit) {
                            count = current_limit;
                          }

                          return (
                            <>
                              <Wrapper
                                style={{
                                  paddingRight: 25,
                                  paddingLeft: 25,
                                  marginTop: -15,
                                  paddingBottom: 25
                                }}
                              >
                                <ProgressBar
                                  color={this.props.colors.success_color}
                                  width={200}
                                  progress={
                                    parseInt(count) > 0
                                      ? parseInt(count) /
                                        parseInt(current_limit)
                                      : 0
                                  }
                                />
                                <Copy style={{ fontSize: 10 }}>
                                  {numberWithCommas(count)} /{" "}
                                  {numberWithCommas(current_limit)}{" "}
                                  {"Leads added from the map"}
                                </Copy>
                              </Wrapper>
                            </>
                          );
                        }
                      : null
                  }
                  renderRight={() => {
                    if (
                      source_of_truth?.subscription?.metadata &&
                      !source_of_truth?.subscription?.metadata
                        ?.driving_for_dollars
                    ) {
                      return (
                        <Row>
                          <InlineButton
                            primary={true}
                            onPress={() => {
                              let feature_upsell_info = null;
                              for (
                                let i = 0;
                                i < all_feature_metadata?.length;
                                i++
                              ) {
                                if (
                                  all_feature_metadata[i].slug ==
                                  "driving_for_dollars"
                                ) {
                                  feature_upsell_info = all_feature_metadata[i];
                                }
                              }
                              this.props.pushSidePanel({
                                slug: "unlock_feature_modal",
                                overlay: true,
                                data: {
                                  feature_upsell_info
                                }
                              });
                            }}
                          >
                            Add To Subscription
                          </InlineButton>
                        </Row>
                      );
                    } else if (
                      source_of_truth?.subscription?.metadata &&
                      source_of_truth?.subscription?.metadata
                        ?.leads_added_from_map != "unlimited"
                    ) {
                      let feature_upsell_info = null;
                      for (let i = 0; i < all_feature_metadata?.length; i++) {
                        if (
                          all_feature_metadata[i].slug ==
                            "leads_added_from_map" &&
                          all_feature_metadata[i].unlock_limit ==
                            source_of_truth?.subscription?.metadata
                              ?.leads_added_from_map
                        ) {
                          feature_upsell_info = all_feature_metadata[i];
                        }
                      }
                      if (feature_upsell_info) {
                        return (
                          <Row>
                            <InlineButton
                              primary={true}
                              onPress={() => {
                                this.props.pushSidePanel({
                                  slug: "unlock_feature_modal",
                                  overlay: true,
                                  data: {
                                    feature_upsell_info
                                  }
                                });
                              }}
                            >
                              Upgrade
                            </InlineButton>
                          </Row>
                        );
                      }
                    }
                    return null;
                  }}
                >
                  Driving For Dollars
                </SelectItem>

                <SelectItem
                  select_type="none"
                  noPress={true}
                  subtext={this.renderSubText("lists")}
                  selected={true}
                  renderBottom={
                    source_of_truth?.subscription?.metadata &&
                    source_of_truth?.subscription?.metadata
                      ?.leads_added_from_list_builder != "unlimited"
                      ? () => {
                          const current_limit = parseInt(
                            source_of_truth?.subscription?.metadata
                              ?.leads_added_from_list_builder
                          );
                          let count = parseInt(
                            source_of_truth?.current_limits
                              ?.number_of_leads_added_from_list_builder?.amount
                          );
                          if (count > current_limit) {
                            count = current_limit;
                          }

                          return (
                            <>
                              <Wrapper
                                style={{
                                  paddingRight: 25,
                                  paddingLeft: 25,
                                  marginTop: -15,
                                  paddingBottom: 25
                                }}
                              >
                                <ProgressBar
                                  color={this.props.colors.success_color}
                                  width={200}
                                  progress={
                                    parseInt(count) > 0
                                      ? parseInt(count) /
                                        parseInt(current_limit)
                                      : 0
                                  }
                                />
                                <Copy style={{ fontSize: 10 }}>
                                  {numberWithCommas(count)} /{" "}
                                  {numberWithCommas(current_limit)}{" "}
                                  {"Leads added from List Builder"}
                                </Copy>
                              </Wrapper>
                            </>
                          );
                        }
                      : null
                  }
                  renderRight={() => {
                    if (
                      source_of_truth?.subscription?.metadata &&
                      !source_of_truth?.subscription?.metadata?.list_builder
                    ) {
                      return (
                        <Row>
                          <InlineButton
                            primary={true}
                            onPress={() => {
                              let feature_upsell_info = null;
                              for (
                                let i = 0;
                                i < all_feature_metadata?.length;
                                i++
                              ) {
                                if (
                                  all_feature_metadata[i].slug == "list_builder"
                                ) {
                                  feature_upsell_info = all_feature_metadata[i];
                                }
                              }
                              this.props.pushSidePanel({
                                slug: "unlock_feature_modal",
                                overlay: true,
                                data: {
                                  feature_upsell_info
                                }
                              });
                            }}
                          >
                            Add To Subscription
                          </InlineButton>
                        </Row>
                      );
                    } else if (
                      source_of_truth?.subscription?.metadata &&
                      source_of_truth?.subscription?.metadata
                        ?.leads_added_from_list_builder != "unlimited"
                    ) {
                      let feature_upsell_info = null;
                      for (let i = 0; i < all_feature_metadata?.length; i++) {
                        if (
                          all_feature_metadata[i].slug ==
                            "leads_added_from_list_builder" &&
                          all_feature_metadata[i].unlock_limit ==
                            source_of_truth?.subscription?.metadata
                              ?.leads_added_from_list_builder
                        ) {
                          feature_upsell_info = all_feature_metadata[i];
                        }
                      }
                      if (feature_upsell_info) {
                        return (
                          <Row>
                            <InlineButton
                              primary={true}
                              onPress={() => {
                                this.props.pushSidePanel({
                                  slug: "unlock_feature_modal",
                                  overlay: true,
                                  data: {
                                    feature_upsell_info
                                  }
                                });
                              }}
                            >
                              Upgrade
                            </InlineButton>
                          </Row>
                        );
                      }
                    }
                    return null;
                  }}
                >
                  List Builder
                </SelectItem>

                <SmallLabel>Add-ons:</SmallLabel>
                <SelectItem
                  select_type="none"
                  noPress={true}
                  subtext={this.renderSubText("comps")}
                  selected={true}
                  renderBottom={() => {
                    return (
                      <>
                        <Wrapper
                          style={{
                            paddingRight: 25,
                            paddingLeft: 25,
                            marginTop: -15,
                            paddingBottom: 25
                          }}
                        >
                          <Row></Row>
                        </Wrapper>
                      </>
                    );
                  }}
                  renderRight={() => {
                    return <Row>{this.renderBuyButton("comps")}</Row>;
                  }}
                >
                  Comps
                </SelectItem>
                <SelectItem
                  select_type="none"
                  noPress={true}
                  subtext={this.renderSubText("route_tracking")}
                  selected={true}
                  renderRight={() => {
                    return <Row>{this.renderBuyButton("route_tracking")}</Row>;
                  }}
                >
                  Route Tracking
                </SelectItem>
                <SelectItem
                  select_type="none"
                  noPress={true}
                  subtext={this.renderSubText("street_pic")}
                  selected={true}
                  renderRight={() => {
                    return <Row>{this.renderBuyButton("street_pic")}</Row>;
                  }}
                >
                  Street Pic
                </SelectItem>
                <SelectItem
                  select_type="none"
                  noPress={true}
                  subtext={this.renderSubText("advanced_mail_sequences")}
                  selected={true}
                  renderRight={() => {
                    return (
                      <Row>
                        {this.renderBuyButton("advanced_mail_sequences")}
                      </Row>
                    );
                  }}
                >
                  Multi-Step Mail Sequences
                </SelectItem>
                <SelectItem
                  select_type="none"
                  noPress={true}
                  subtext={this.renderSubText("mail_tracking")}
                  selected={true}
                  renderRight={() => {
                    return <Row>{this.renderBuyButton("mail_tracking")}</Row>;
                  }}
                >
                  Mail Tracking
                </SelectItem>
                <SelectItem
                  select_type="none"
                  noPress={true}
                  subtext={this.renderSubText("masterclass")}
                  selected={true}
                  renderRight={() => {
                    return (
                      <Row>{this.renderBuyButton("elite_master_class")}</Row>
                    );
                  }}
                >
                  Elite Masterclass
                </SelectItem>
                {this.props.user?.can_mimic_team_owner == 1 ? (
                  <SelectItem
                    select_type="none"
                    icon="keyboard-arrow-right"
                    onPress={() => {
                      this.props.pushSidePanel({
                        slug: "enter_promo_code",
                        overlay: true
                      });
                    }}
                  >
                    Enter Promo Code
                  </SelectItem>
                ) : null}
              </KeyboardView>
            </>
          )}

          {!user?.workspace_id ? (
            <>
              {source_of_truth?.subscription?.metadata.enterprise &&
              this.props.device === "desktop" ? (
                <BottomNavBar>
                  <InlineButton
                    textStyle={{ whiteSpace: "wrap" }}
                    button_type={"full"}
                    onPress={() => displayIntercom()}
                    id="intercom_button"
                  >
                    To change or cancel your account, reach out to our support
                    team.
                  </InlineButton>
                </BottomNavBar>
              ) : source_of_truth?.subscription?.cancel_at_period_end &&
                source_of_truth?.subscription?.billing_source !== "iap" &&
                this.props.platform === "desktop" ? (
                <BottomNavBar>
                  <InlineButton
                    textStyle={{ whiteSpace: "wrap" }}
                    button_type={"full"}
                    primary={true}
                    loading={this.state.reactivateLoading}
                    onPress={() => {
                      this.props.manageSubscription({
                        token: this.props.token,
                        type: "reactivate_all_plans",
                        onLoading: () => {
                          this.setState({
                            reactivateLoading: true
                          });
                        },
                        onError: () => {
                          this.setState({
                            reactivateLoading: false
                          });
                        },
                        onSuccess: () => {
                          this.setState(
                            {
                              reactivateLoading: false
                            },
                            () => {
                              this.props.showSuccess(
                                "You've reactivated your subscription.",
                                "Welcome back!"
                              );
                              this.handleBack();
                            }
                          );
                        }
                      });
                    }}
                  >
                    Reactivate Plan
                  </InlineButton>
                </BottomNavBar>
              ) : !source_of_truth?.subscription?.cancel_at_period_end &&
                source_of_truth?.subscription?.billing_source !== "iap" &&
                checkIfUserHasMetadata("paid_plan") &&
                this.props.user?.can_mimic_team_owner == 1 ? (
                <BottomNavBar>
                  <ConfirmInlineButton
                    disabled={
                      !this.state.redirect_url &&
                      this.props.device !== "desktop"
                    }
                    textStyle={{ whiteSpace: "wrap" }}
                    button_type="full"
                    confirm_text="Continue with cancelation?"
                    marketing_slug={"started_canceling_button"}
                    loading={this.state.cancelLoading}
                    loading_text="Loading..."
                    onPress={() => {
                      if (this.props.device === "desktop") {
                        window.churnkey.init("show", {
                          appId: "rivg5b42h",
                          authHash: this.props.source_of_truth?.churn_key_hash,
                          customerId:
                            this.props.source_of_truth?.stripe_customer_id,
                          subscriptionId:
                            this.props.source_of_truth?.subscription?.stripe_id,
                          billingProvider: "stripe", // or chargebee, braintree, paddle, paddling-billing
                          mode: "live", // or 'test',
                          record: true, // session playback recording
                          preview: false, // disables any billing actions if true
                          report: true, // enable/disable including this sessions results in analytics dashboard

                          // listener callbacks
                          onPause: () => {
                            this.props.manageSubscription({
                              token: this.props.token,
                              type: "pause_subscription",
                              stripe_sub_id:
                                this.props.source_of_truth?.subscription
                                  ?.stripe_id,
                              no_messaging: false,
                              onLoading: () => {
                                this.setState({
                                  cancelLoading: true
                                });
                              },
                              onError: () => {
                                this.setState({
                                  cancelLoading: false
                                });
                              },
                              onSuccess: () => {
                                this.setState(
                                  {
                                    cancelLoading: false
                                  },
                                  () => {
                                    this.resetCustomerInfo();
                                  }
                                );
                              }
                            });
                          },
                          onCancel: () => {
                            this.props.manageSubscription({
                              token: this.props.token,
                              type: "cancel_all_plans",
                              stripe_sub_id:
                                this.props.source_of_truth?.subscription
                                  ?.stripe_id,
                              no_messaging: false,
                              onLoading: () => {
                                this.setState({
                                  cancelLoading: true
                                });
                              },
                              onError: () => {
                                this.setState({
                                  cancelLoading: false
                                });
                              },
                              onSuccess: () => {
                                this.setState(
                                  {
                                    cancelLoading: false
                                  },
                                  () => {
                                    this.resetCustomerInfo();
                                  }
                                );
                              }
                            });
                          },
                          onDiscount: this.resetCustomerInfo,
                          onTrialExtension: this.resetCustomerInfo,
                          onPlanChange: this.resetCustomerInfo,
                          onClose: () => {}
                        });
                      } else if (this.state.redirect_url) {
                        openUrl(this.state.redirect_url);
                        this.props.popAllSidePanels();
                      }
                    }}
                  >
                    Cancel Plan
                  </ConfirmInlineButton>
                </BottomNavBar>
              ) : source_of_truth?.subscription?.billing_source === "iap" ? (
                <BottomNavBar>
                  <Wrapper style={{ textAlign: "center", padding: 20 }}>
                    <Copy style={{ textAlign: "center" }}>
                      Navigate to your phone's settings to manage your account.
                    </Copy>
                  </Wrapper>
                </BottomNavBar>
              ) : null}
            </>
          ) : null}
        </Container>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, native, settings, billing, marketing }) => {
  const { token, user } = auth;
  const { device, platform, isMobile } = native;
  const { colors } = settings;
  const { source_of_truth, all_feature_metadata } = billing;
  const { visitor } = marketing;
  return {
    token,
    user,
    device,
    platform,
    colors,
    source_of_truth,
    all_feature_metadata,
    isMobile,
    visitor
  };
};

export default connect(mapStateToProps, {
  getSourceOfTruth,
  popSidePanel,
  pushSidePanel,
  manageSubscription,
  toggleChurnBuster,
  popAllSidePanels,
  showSuccess,
  getMobileRedirectSessionLink,
  submitMarketingForm
})(PlanSettings);
