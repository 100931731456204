import React, { Component } from "react";
import { connect } from "react-redux";
import NavigationService from "app/Router/NavigationService";
import { Container, Copy, Wrapper, Title } from "app/NativeComponents/common";
import { NewHeader, SpinWrapper } from "app/NativeComponents/snippets";
import ListBody from "./ListBody";
import {
  getLists,
  setActiveList,
  setEditListInfo,
  popSidePanel
} from "app/NativeActions";

class EditList extends Component {
  componentDidMount() {
    if (this.props.device == "mobile") {
      NavigationService.setTopLevelNavigator(this.props.navigation);
    }
    const panel_id =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_id ||
          this.props.route?.params?.panel_data?.panel_id
        : this.props.panel_id;
    if (
      !this.props.loading_single_list_item &&
      this.props?.user?.can_see_all_deals
    ) {
      this.props.getLists({
        token: this.props.token,
        load_type: "single_list",
        list_id: panel_id,
        onSuccess: results => {
          const item =
            results.lists.length > 0 && results.lists[0]
              ? results.lists[0]
              : null;

          if (item) {
            this.props.setActiveList(item);
            this.props.setEditListInfo({
              id: item.id,
              title: item.title,
              date_created: item.date_created,
              ...item
            });
          }
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    const panel_id =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_id ||
          this.props.route?.parmas?.panel_data?.panel_id
        : this.props.panel_id;
    const prev_panel_id =
      prevProps.device == "mobile"
        ? prevProps?.route?.params?.panel_id ||
          prevProps.route?.params?.panel_data?.panel_id
        : prevProps.panel_id;
    if (
      (!this.props?.edit_list_info?.id ||
        panel_id !== this.props.edit_list_info.id) &&
      panel_id &&
      panel_id !== prev_panel_id &&
      !this.props.loading_single_list_item &&
      this.props?.user?.can_see_all_deals
    ) {
      this.props.getLists({
        token: this.props.token,
        load_type: "single_list",
        list_id: panel_id,
        onSuccess: results => {
          const item =
            results.lists.length > 0 && results.lists[0]
              ? results.lists[0]
              : null;

          if (item) {
            this.props.setActiveList(item);
            this.props.setEditListInfo({
              id: item.id,
              title: item.title,
              date_created: item.date_created,
              ...item
            });
          }
        }
      });
    }
  }
  render() {
    const { edit_list_info, loading_single_list_item, device, colors } =
      this.props;

    if (edit_list_info && !this.props.loading_single_list_item) {
      return (
        <Container
          style={{
            display: "flex",
            alignSelf: "stretch",
            backgroundColor: colors.card_color
          }}
        >
          <ListBody />
        </Container>
      );
    }
    if (
      (!edit_list_info && !this.props.loading_single_list_item)
    ) {
      return (
        <Container
          style={{
            display: "flex",
            alignSelf: "stretch",
            backgroundColor: colors.card_color
          }}
        >
          <NewHeader
            title={"View List"}
            subtitle={""}
            leftButton={{
              icon:
                this.props.device == "mobile" ||
                this.props.side_panel_views.length > 1
                  ? "arrow-back"
                  : "close",
              onPress: () => {
                this.props.popSidePanel();
              }
            }}
          />
          <Wrapper
            style={{
              alignSelf: "center",
              justifyContent: "center",
              padding: 20
            }}
          >
            <Title
              style={{
                alignSelf: "center",
                justifyContent: "center",
                padding: 20
              }}
            >
              This list cannot be viewed
            </Title>
            <Copy
              style={{
                alignSelf: "center",
                justifyContent: "center",
                padding: 20
              }}
            >
              You either do not have permission to view this list, this list has
              been deleted, or this list does not exist.
            </Copy>
          </Wrapper>
        </Container>
      );
    }

    return (
      <Container
        style={{
          display: "flex",
          alignSelf: "stretch",
          backgroundColor: colors.card_color
        }}
      >
        <NewHeader
          title={"View List"}
          subtitle={""}
          leftButton={{
            icon:
              this.props.device == "mobile" ||
              this.props.side_panel_views.length > 1
                ? "arrow-back"
                : "close",
            onPress: () => {
              this.props.popSidePanel();
            }
          }}
        />
        <Wrapper style={{ justifyContent: "center" }}>
          <SpinWrapper text="Loading" />
        </Wrapper>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, list, modal }) => {
  const { colors } = settings;
  const { platform, device, isMobile } = native;
  const { edit_list_info, loading_single_list_item } = list;
  const { token, user } = auth;
  const { side_panel_views } = modal;

  return {
    colors,
    device,
    isMobile,
    user,
    platform,
    edit_list_info,
    token,
    loading_single_list_item,
    side_panel_views
  };
};

export default connect(mapStateToProps, {
  getLists,
  setActiveList,
  setEditListInfo,
  popSidePanel
})(EditList);
