import React, { Component } from "react";
import { connect } from "react-redux";

import { Bold, Container, KeyboardView } from "app/NativeComponents/common";
import {
  pushSidePanel,
  replaceSidePanel,
  changeTab,
  getTeam,
  getDashboardStats,
  updateUserSettings,
  getMailerTemplates,
  getMailerCampaigns
} from "app/NativeActions";

import WorkspaceManageTeams from "app/Workspaces/components/WorkspacesDashboard/WorkspaceManageTeams";
import Dashboard from "app/Workspaces/components/WorkspacesDashboard/Dashboard";

class WorkspacesDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.device == "mobile") {
      this.props.changeTab("dashboard");
    }
  }

  updateMainState({ prop, value }) {
    this.setState({ [prop]: value });
  }

  render() {
    const { colors, isMobile, user } = this.props;
    const { active_leads, dashboard_activity_stats, stat_tab } = this.state;
    if (!user) {
      return null;
    }

    return (
      <>
        <Container>
          <KeyboardView style={{ flex: 1 }}>
            <Dashboard />
            <WorkspaceManageTeams />
          </KeyboardView>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({
  auth,
  native,
  settings,
  team,
  mailer,
  workspaces
}) => {
  const { token, user } = auth;
  const { device, window_height, isMobile } = native;
  const { colors, dark_mode } = settings;
  const { team_members_loading, my_team } = team;
  const { active_workspace_team } = workspaces;

  const {
    mailer_templates,
    mailer_templates_loading,
    mailer_campaigns,
    mailer_campaigns_loading
  } = mailer;

  return {
    token,
    user,
    device,
    isMobile,
    window_height,
    colors,
    dark_mode,
    team_members_loading,
    my_team,
    mailer_templates,
    mailer_templates_loading,
    mailer_campaigns,
    mailer_campaigns_loading,
    active_workspace_team
  };
};

export default connect(mapStateToProps, {
  pushSidePanel,
  replaceSidePanel,

  changeTab,
  getTeam,
  getDashboardStats,
  updateUserSettings,

  getMailerTemplates,
  getMailerCampaigns
})(WorkspacesDashboard);
