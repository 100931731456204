import React, { PureComponent } from "react";

import { connect } from "react-redux";
import {
  initApp,
  logout,
  loginUser,
  getUser,
  toggleUserRefresh,
  getSourceOfTruth,
  startRedirect,
  manageSubscription,
  openUrl
} from "app/NativeActions";

import {
  Page,
  Copy,
  Wrapper,
  Header1,
  Header3,
  Bold
} from "app/NativeComponents/common";
import {
  GhostButton,
  OnboardingWrapper,
  InlineButton
} from "app/NativeComponents/snippets";

const queryString = require("query-string");

class ChurnKeyPrompt extends PureComponent {
  constructor() {
    super();
    this.state = {
      loading: true
    };

    this.startChurnKey = this.startChurnKey.bind(this);
    this.resetCustomerInfo = this.resetCustomerInfo.bind(this);
  }

  resetCustomerInfo() {
    openUrl("https://dealmachine.app.link/refresh-account/");
  }

  startChurnKey(churn_key_hash, customer_id, subscription_id, churn_key_token) {
    window.churnkey.init("show", {
      appId: "rivg5b42h",
      authHash: churn_key_hash,
      customerId: customer_id,
      subscriptionId: subscription_id,
      billingProvider: "stripe", // or chargebee, braintree, paddle, paddling-billing
      mode: "live", // or 'test',
      record: true, // session playback recording
      preview: false, // disables any billing actions if true
      report: true, // enable/disable including this sessions results in analytics dashboard

      // listener callbacks
      onPause: () => {
        this.props.manageSubscription({
          token: churn_key_token,
          type: "pause_subscription",
          stripe_sub_id: subscription_id,
          no_messaging: false,
          onLoading: () => {
            this.setState({
              cancelLoading: true
            });
          },
          onError: () => {
            this.setState({
              cancelLoading: false
            });
          },
          onSuccess: () => {
            this.setState(
              {
                cancelLoading: false
              },
              () => {
                this.resetCustomerInfo();
              }
            );
          }
        });
      },
      onCancel: () => {
        this.props.manageSubscription({
          token: churn_key_token,
          type: "cancel_all_plans",
          stripe_sub_id: subscription_id,
          no_messaging: false,
          onLoading: () => {
            this.setState({
              cancelLoading: true
            });
          },
          onError: () => {
            this.setState({
              cancelLoading: false
            });
          },
          onSuccess: () => {
            this.setState(
              {
                cancelLoading: false
              },
              () => {
                this.resetCustomerInfo();
              }
            );
          }
        });
      },
      onDiscount: this.resetCustomerInfo,
      onTrialExtension: this.resetCustomerInfo,
      onPlanChange: this.resetCustomerInfo,
      onClose: () => {}
    });
  }

  componentDidMount() {
    const parsed = queryString.parse(this.props.location?.search, {
      parseNumbers: true,
      arrayFormat: "bracket",
      decode: true
    });

    if (
      !!parsed?.churn_key_hash &&
      !!parsed?.customer_id &&
      !!parsed?.subscription_id &&
      !!parsed?.churn_key_token
    ) {
    } else {
      this.props.startRedirect("/");
    }
  }

  render() {
    const parsed = queryString.parse(this.props.location?.search, {
      parseNumbers: true,
      arrayFormat: "bracket",
      decode: true
    });
    return (
      <Page
        metaTitle={"We're sorry to see you go - DealMachine for Real Estate"}
        responsive_design={true}
      >
        <OnboardingWrapper
          renderInner={() => {
            return <></>;
          }}
        >
          <Wrapper
            style={{
              padding: 25,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Header1 style={{ textAlign: "center" }}>
              <Bold>We're sorry to see you go.</Bold>
            </Header1>
            <Header3 style={{ textAlign: "center" }}>
              Please let us know how we can improve your experience.
            </Header3>
          </Wrapper>
          <Wrapper
            style={{
              padding: 25,
              paddingTop: 0,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <GhostButton
              button_type={"full"}
              loading={this.state.completing}
              onPress={() => {
                this.resetCustomerInfo();
              }}
            >
              Take Me Back To The App
            </GhostButton>
            <InlineButton
              button_type={"full"}
              onPress={() => {
                this.startChurnKey(
                  parsed?.churn_key_hash,
                  parsed?.customer_id,
                  parsed?.subscription_id,
                  parsed?.churn_key_token
                );
              }}
            >
              Continue with cancelation
            </InlineButton>
          </Wrapper>
        </OnboardingWrapper>
      </Page>
    );
  }
}
const mapStateToProps = ({ auth, native }) => {
  const { user } = auth;

  const { device, platform } = native;

  return {
    user,
    device,
    platform
  };
};

export default connect(mapStateToProps, {
  initApp,
  logout,
  loginUser,
  getUser,
  toggleUserRefresh,
  getSourceOfTruth,
  startRedirect,
  manageSubscription
})(ChurnKeyPrompt);
