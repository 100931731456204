import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getDashboardStats,
  numberWithCommas,
  pushSidePanel
} from "app/NativeActions";

import {
  SpinWrapper,
  LineGraph,
  SmallLabel
} from "app/NativeComponents/snippets";
import { Wrapper, Copy, Row } from "app/NativeComponents/common";

import IncludeOption from "./IncludeOption";
import StatBlock from "./StatBlock";

class PropertiesAdded extends Component {
  constructor(props) {
    super(props);

    this.state = {
      properties_added: null,
      total_properties_added: 0,
      properties_from_driving: 0,
      pulled_properties: 0,
      imported_properties: 0,
      other_properties: 0,
      total_previous_properties_added: 0,
      previous_properties_from_driving: 0,
      previous_pulled_properties: 0,
      previous_imported_properties: 0,
      previous_other_properties: 0
    };
  }

  handleBack() {
    this.props.popSidePanel();
  }

  componentDidMount() {
    this.getData({ load_type: "load" });
  }

  getData({ load_type = "load" }) {
    this.props.getDashboardStats({
      token: this.props.token,
      type: "get_properties_added",
      filter_date: this.props.filter_date,
      filter_start_date: this.props.filter_start_date,
      filter_end_date: this.props.filter_end_date,
      filter_user: this.props.filter_user ? this.props.filter_user?.id : null,
      filter_team: this.props.filter_team ? this.props.filter_team?.id : null,
      workspace_id: this.props.workspace_id,
      include_properties: this.props.include_properties?.join(","),
      onLoading: () => {
        this.props.updateMainState({
          prop: "properties_added_loading",
          value: true
        });

        this.setState({
          properties_added: null,
          total_properties_added: 0,
          properties_from_driving: 0,
          pulled_properties: 0,
          imported_properties: 0,
          other_properties: 0,
          total_previous_properties_added: 0,
          previous_properties_from_driving: 0,
          previous_pulled_properties: 0,
          previous_imported_properties: 0
        });
      },
      onError: () => {
        this.props.updateMainState({
          prop: "properties_added_loading",
          value: false
        });
      },
      onSuccess: results => {
        this.props.updateMainState({
          prop: "properties_added_loading",
          value: false
        });

        this.setState({
          total_properties_added: results?.total_properties_added,
          properties_from_driving: results?.properties_from_driving,
          pulled_properties: results?.pulled_properties,
          properties_added: results?.properties_added
            ? results.properties_added
            : null,
          imported_properties: results?.imported_properties,
          other_properties: results?.other_properties,
          total_previous_properties_added:
            results?.total_previous_properties_added,
          previous_properties_from_driving:
            results?.previous_properties_from_driving,
          previous_pulled_properties: results?.previous_pulled_properties,
          previous_imported_properties: results?.previous_imported_properties,
          previous_other_properties: results?.previous_other_properties
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.filter_user?.id != prevProps.filter_user?.id ||
      this.props.filter_team?.id != prevProps.filter_team?.id ||
      this.props.filter_date != prevProps.filter_date ||
      this.props.filter_start_date != prevProps.filter_start_date ||
      this.props.filter_end_date != prevProps.filter_end_date ||
      JSON.stringify(this.props.include_properties) !==
        JSON.stringify(prevProps.include_properties)
    ) {
      this.getData({ load_type: "refresh" });
    }
  }

  componentWillUnmount() {}

  handleIncludePropertiesToggle(slug) {
    //if slug is in include_properties, remove it...if not add it then send it to updateMainState
    let new_include_properties = this.props.include_properties.includes(slug)
      ? this.props.include_properties.filter(item => item !== slug)
      : [...this.props.include_properties, slug];

    this.props.updateMainState({
      prop: "include_properties",
      value: new_include_properties
    });
  }

  render() {
    const { colors, properties_added_loading, filter_date } = this.props;

    const {
      properties_added,
      total_properties_added,
      pulled_properties,
      properties_from_driving,
      imported_properties,
      other_properties,
      total_previous_properties_added,
      previous_properties_from_driving,
      previous_pulled_properties,
      previous_imported_properties,
      previous_other_properties
    } = this.state;

    return (
      <>
        {this.props.device === "desktop" ? (
          <Row>
            <StatBlock
              title={"Added From Driving"}
              number={properties_from_driving}
              prev_number={previous_properties_from_driving}
              prev_title={this.props.renderPreviousTitle()}
            />
            <StatBlock
              title={"Pulled From List Builder"}
              number={pulled_properties}
              prev_number={previous_pulled_properties}
              prev_title={this.props.renderPreviousTitle()}
            />

            <StatBlock
              title={"Total Properties Added"}
              selected={true}
              number={total_properties_added}
              prev_number={total_previous_properties_added}
              prev_title={this.props.renderPreviousTitle()}
            />
          </Row>
        ) : (
          <>
            <Row>
              <StatBlock
                title={"Added From Driving"}
                number={properties_from_driving}
                prev_number={previous_properties_from_driving}
                prev_title={this.props.renderPreviousTitle()}
              />
              <StatBlock
                title={"Pulled From List Builder"}
                number={pulled_properties}
                prev_number={previous_pulled_properties}
                prev_title={this.props.renderPreviousTitle()}
              />
            </Row>
            <Row>
              <StatBlock
                title={"Total Properties Added"}
                selected={true}
                number={total_properties_added}
                prev_number={total_previous_properties_added}
                prev_title={this.props.renderPreviousTitle()}
              />
            </Row>
          </>
        )}

        {this.props.properties_added_loading ? (
          <Wrapper style={{ height: 300, justifyContent: "center" }}>
            <SpinWrapper />
          </Wrapper>
        ) : properties_added?.length > 0 &&
          filter_date !== "yesterday" &&
          filter_date !== "today" ? (
          <>
            <Wrapper
              style={
                this.props.device !== "desktop"
                  ? { paddingTop: 25, paddingBottom: 25 }
                  : {}
              }
            >
              <LineGraph
                title="Properties Added"
                data1={properties_added}
                x_title={"Date"}
                y_title={"Properties Added"}
                x_key1={"date"}
                y_key1={"properties_added"}
                x_key2={"prev_date"}
                y_key2={"prev_properties_added"}
              />
            </Wrapper>
          </>
        ) : filter_date === "yesterday" ||
          filter_date === "today" ||
          properties_added?.length === 0 ? (
          <>
            <Wrapper
              style={{
                borderRadius: 5,
                borderWidth: 1,
                borderColor: colors.border_color,
                borderStyle: "solid",
                margin: 5,
                padding: 25,
                alignItems: "center",
                justifyContent: "center",
                height: 300
              }}
            >
              <Copy style={{ textAlign: "center" }}>
                Not enough data to display graph.
              </Copy>
            </Wrapper>
          </>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, dialer }) => {
  const { token, user } = auth;

  const { colors } = settings;
  const { platform, device, isMobile } = native;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform
  };
};

export default connect(mapStateToProps, {
  getDashboardStats,
  pushSidePanel
})(PropertiesAdded);
