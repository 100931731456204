import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Row,
  Spin,
  Wrapper,
  KeyboardView,
  Copy,
  Bold,
  Button,
  ContentfulRichText
} from "app/NativeComponents/common";
import {
  GhostButton,
  InlineButton,
  List,
  SelectItem,
  IconButton,
  UpsellButton,
  PopoverMenu
} from "app/NativeComponents/snippets";

import MainHeader from "app/DealMachineCore/reuseable/MainHeader";
import MoreInfoButton from "app/DealMachineCore/reuseable/MoreInfoButton";

import Header from "./Header";
import MailSequence from "./MailSequence";

import {
  pushSidePanel,
  getMailerCampaigns,
  updateMailerCampaign,
  formatAddress,
  numberWithCommas,
  loadOnboardingText,
  changeTab
} from "app/NativeActions";
import MailerFlipCard from "app/DealMachineCore/reuseable/MailerFlipCard";
import moment from "moment";
class MailSequences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort_by: {
        slug: "date_created",
        type: "desc"
      },
      mail_sequences_refreshing: false,
      mail_sequences_loading: false,
      mail_sequences: [],
      mail_sequences_loaded_all: false,
      begin: 0,
      limit: 25,
      mail_sequence_count: 0,
      contentful_content: null,
      contentful_loading: false
    };
    this.onSort = this.onSort.bind(this);
    this.newMailSequenceSuccess = this.newMailSequenceSuccess.bind(this);
    this.editMailSequenceSuccess = this.editMailSequenceSuccess.bind(this);
    this.removeFirstChoiceFromAll = this.removeFirstChoiceFromAll.bind(this);
    this.removeMailSequenceSuccess = this.removeMailSequenceSuccess.bind(this);
  }
  onSort(sort_by) {
    this.setState({ sort_by });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevState.sort_by) !== JSON.stringify(this.state.sort_by)
    ) {
      this.getData("refresh");
    }
  }

  loadData(slug) {
    loadOnboardingText(slug)
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              contentful_content: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              loading: false
            });
          }
        }
        this.setState(() => ({ contentful_loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ contentful_loading: false }));
      });
  }

  componentDidMount() {
    if (this.props.device == "mobile") {
      this.props.changeTab("mail_sequences");
    }
    this.getData("refresh");

    this.loadData("mail_sequences");
  }

  getData(load_type = "load") {
    const { mailer_campaigns_begin, mailer_campaigns_limit } = this.props;
    const { sort_by, begin, limit } = this.state;
    this.props.getMailerCampaigns({
      token: this.props.token,
      load_type: load_type,
      begin: load_type !== "load_more" ? 0 : begin,
      limit,
      sort_by: sort_by.slug + "_" + sort_by.type,
      exclude_repeating_mail: true,
      onLoading: () => {
        this.setState({
          mail_sequences_loading: load_type == "refresh" ? false : true,
          mail_sequences_refreshing: load_type == "refresh" ? true : false,
          mail_sequences_loaded_all: false
        });
      },
      onError: () => {
        this.setState({
          mail_sequences_loading: false,
          mail_sequences_refreshing: false
        });
      },
      onSuccess: results => {
        this.setState({
          mail_sequences:
            load_type == "refresh"
              ? results?.mailer_campaigns || []
              : [...this.state.mail_sequences, ...results?.mailer_campaigns],
          mail_sequences_loading: false,
          mail_sequences_refreshing: false,
          begin:
            load_type == "refresh"
              ? results?.mailer_campaigns?.length
              : begin + results?.mailer_campaigns?.length,
          mail_sequences_loaded_all: results?.mailer_campaigns
            ? results?.mailer_campaigns?.length < limit
              ? true
              : false
            : true,
          mail_sequence_count:
            results.mailer_campaign_count || this.state.mail_sequence_count
        });
      }
    });
  }

  newMailSequenceSuccess(mail_sequence) {
    const { mail_sequences, mail_sequence_count } = this.state;
    this.setState({
      mail_sequences: [mail_sequence, ...mail_sequences],
      mail_sequence_count: mail_sequence_count + 1
    });
  }

  removeFirstChoiceFromAll() {
    const { mail_sequences } = this.state;
    let new_mail_sequences = JSON.parse(JSON.stringify(mail_sequences));

    for (let i = 0; i < new_mail_sequences.length; i++) {
      new_mail_sequences[i] = {
        ...new_mail_sequences[i],
        is_default_campaign: false
      };
    }

    this.setState({
      mail_sequences: new_mail_sequences
    });
  }

  editMailSequenceSuccess(mail_sequence) {
    const { mail_sequences } = this.state;
    let new_mail_sequences = JSON.parse(JSON.stringify(mail_sequences));

    for (let i = 0; i < new_mail_sequences.length; i++) {
      if (new_mail_sequences[i].id == mail_sequence.id) {
        new_mail_sequences[i] = mail_sequence;
      }
    }

    this.setState({
      mail_sequences: new_mail_sequences
    });
  }

  removeMailSequenceSuccess(mail_sequence) {
    const { mail_sequences } = this.state;
    this.setState({
      mail_sequences: mail_sequences.filter(({ id }) => id !== mail_sequence.id)
    });
  }

  render() {
    const { colors, device, isMobile, dark_mode, user } = this.props;
    const {
      sort_by,
      mail_sequences_loading,
      mail_sequences_refreshing,
      mail_sequences_loaded_all,
      mail_sequences,
      contentful_content
    } = this.state;

    if (user?.can_edit_templates == 1) {
      return (
        <Container
          fill={true}
          title={"Mail Sequences"}
          style={{
            display: "flex",
            overflow: "hidden",
            backgroundColor: colors.card_color
          }}
        >
          <MainHeader
            title={"Mail Sequences"}
            renderRight={
              this.props.user?.can_edit_templates == 1
                ? () => {
                    return (
                      <UpsellButton
                        meta_slug={"advanced_mail_sequences"}
                        onLockPress={null}
                        style={{ marginRight: isMobile ? 0 : 10 }}
                        onPress={() => {
                          this.props.pushSidePanel({
                            slug: "new_mail_sequence",
                            overlay: true,
                            data: {
                              mail_sequence_count:
                                this.state.mail_sequence_count,
                              onSuccess: mail_sequence => {
                                this.newMailSequenceSuccess(mail_sequence);
                              }
                            }
                          });
                        }}
                        renderLockedChildren={({ hovering }) => {
                          if (isMobile) {
                            return (
                              <InlineButton
                                primary={true}
                                pressedIn={hovering}
                                noPress={true}
                                icon={"lock"}
                              >
                                New
                              </InlineButton>
                            );
                          }
                          return (
                            <GhostButton
                              primary={true}
                              pressedIn={hovering}
                              noPress={true}
                              icon={"lock"}
                            >
                              New Mail Sequence
                            </GhostButton>
                          );
                        }}
                        renderChildren={({ hovering }) => {
                          if (isMobile) {
                            return (
                              <InlineButton
                                icon={"add"}
                                primary={true}
                                pressedIn={hovering}
                                noPress={true}
                              >
                                New
                              </InlineButton>
                            );
                          }
                          return (
                            <GhostButton
                              icon={"add"}
                              primary={true}
                              pressedIn={hovering}
                              noPress={true}
                            >
                              New Mail Sequence
                            </GhostButton>
                          );
                        }}
                      />
                    );
                  }
                : null
            }
          />

          {isMobile ? (
            <List
              rowNumber={1}
              items={mail_sequences}
              infiniteScroll={true}
              itemStructure={({ item, index }) => {
                return (
                  <SelectItem
                    key={"mail_sequence_" + index}
                    select_type={item.is_default_campaign ? "icon" : "none"}
                    select_icon={item.is_default_campaign ? "star" : ""}
                    selected={item.is_default_campaign}
                    onPress={() => {
                      this.props.pushSidePanel({
                        slug: "view_mail_sequence",
                        overlay: true,
                        data: {
                          mail_sequence: item,
                          onSuccess: ms => {
                            this.editMailSequenceSuccess(ms);
                          }
                        }
                      });
                    }}
                    renderBottom={() => {
                      return (
                        <>
                          <Row style={{ paddingLeft: 25, paddingRight: 25 }}>
                            <Button
                              onPress={() => {
                                this.props.pushSidePanel({
                                  slug: "view_leads",
                                  overlay: true,
                                  data: {
                                    subtitle:
                                      "Showing active leads using the " +
                                      item.campaign_title +
                                      " mail sequence.",
                                    filters: {
                                      data: [
                                        {
                                          data: [
                                            {
                                              label: "Mail Status",
                                              key: "campaign_status",
                                              equal_type: "is_equal_to_any_of",
                                              type: "object",
                                              values: [
                                                {
                                                  value:
                                                    "Mail Sequence In Progress",
                                                  label: "Mail In Progress"
                                                }
                                              ]
                                            },
                                            {
                                              label: "Assigned Mail Sequence",
                                              key: "mail_sequence",
                                              equal_type: "is_equal_to_any_of",
                                              type: "object",
                                              values: [
                                                {
                                                  value: item.id,
                                                  label: item.campaign_title
                                                }
                                              ]
                                            }
                                          ],
                                          andor_type: "and"
                                        }
                                      ],
                                      andor_type: "or"
                                    }
                                  }
                                });
                              }}
                              style={{
                                backgroundColor: colors.background_color,
                                margin: 5,
                                marginLeft: 0,
                                marginTop: 0,
                                padding: 10,
                                borderRadius: 5
                              }}
                            >
                              <Copy
                                style={{
                                  fontSize: 12,
                                  color: colors.light_text_color
                                }}
                              >
                                Active Leads
                              </Copy>
                              <Copy
                                style={{
                                  whiteSpace: "nowrap",
                                  color: colors.text_color
                                }}
                              >
                                {numberWithCommas(item.active_leads)}
                              </Copy>
                            </Button>

                            <Button
                              onPress={() => {
                                this.props.pushSidePanel({
                                  slug: "view_leads",
                                  overlay: true,
                                  data: {
                                    subtitle:
                                      "Showing paused leads using the " +
                                      item.campaign_title +
                                      " mail sequence.",
                                    filters: {
                                      data: [
                                        {
                                          data: [
                                            {
                                              label: "Mail Status",
                                              key: "campaign_status",
                                              equal_type: "is_equal_to_any_of",
                                              type: "object",
                                              values: [
                                                {
                                                  value: "Mail Sequence Paused",
                                                  label: "Mail Paused"
                                                }
                                              ]
                                            },
                                            {
                                              label: "Assigned Mail Sequence",
                                              key: "mail_sequence",
                                              equal_type: "is_equal_to_any_of",
                                              type: "object",
                                              values: [
                                                {
                                                  value: item.id,
                                                  label: item.campaign_title
                                                }
                                              ]
                                            }
                                          ],
                                          andor_type: "and"
                                        }
                                      ],
                                      andor_type: "or"
                                    }
                                  }
                                });
                              }}
                              style={{
                                backgroundColor: colors.background_color,
                                margin: 5,
                                marginTop: 0,
                                padding: 10,
                                borderRadius: 5
                              }}
                            >
                              <Copy
                                style={{
                                  fontSize: 12,
                                  color: colors.light_text_color
                                }}
                              >
                                Paused Leads
                              </Copy>
                              <Copy
                                style={{
                                  whiteSpace: "nowrap",
                                  color: colors.text_color
                                }}
                              >
                                {numberWithCommas(item.paused_leads)}
                              </Copy>
                            </Button>

                            <Button
                              onPress={() => {
                                this.props.pushSidePanel({
                                  slug: "view_leads",
                                  overlay: true,
                                  data: {
                                    subtitle:
                                      "Showing completed leads using the " +
                                      item.campaign_title +
                                      " mail sequence.",
                                    filters: {
                                      data: [
                                        {
                                          data: [
                                            {
                                              label: "Mail Status",
                                              key: "campaign_status",
                                              equal_type: "is_equal_to_any_of",
                                              type: "object",
                                              values: [
                                                {
                                                  value: "Mail Sequence Ended",
                                                  label: "Mail Ended"
                                                }
                                              ]
                                            },
                                            {
                                              label: "Assigned Mail Sequence",
                                              key: "mail_sequence",
                                              equal_type: "is_equal_to_any_of",
                                              type: "object",
                                              values: [
                                                {
                                                  value: item.id,
                                                  label: item.campaign_title
                                                }
                                              ]
                                            }
                                          ],
                                          andor_type: "and"
                                        }
                                      ],
                                      andor_type: "or"
                                    }
                                  }
                                });
                              }}
                              style={{
                                backgroundColor: colors.background_color,
                                margin: 5,
                                marginTop: 0,
                                padding: 10,
                                borderRadius: 5
                              }}
                            >
                              <Copy
                                style={{
                                  fontSize: 12,
                                  color: colors.light_text_color
                                }}
                              >
                                Completed Leads
                              </Copy>
                              <Copy
                                style={{
                                  whiteSpace: "nowrap",
                                  color: colors.text_color
                                }}
                              >
                                {numberWithCommas(item.completed_leads)}
                              </Copy>
                            </Button>
                          </Row>
                        </>
                      );
                    }}
                    renderRight={() => {
                      return (
                        <Row>
                          <PopoverMenu
                            renderComponent={({ pressedIn, hovering }) => {
                              return (
                                <IconButton
                                  icon_color={colors.actionable_text_color}
                                  icon="more-vert"
                                  pressedIn={pressedIn || hovering}
                                  noPress={true}
                                  style={{ margin: 0 }}
                                />
                              );
                            }}
                            menu_items={[
                              {
                                title: item.is_default_campaign
                                  ? "Remove First Choice"
                                  : "Set First Choice",
                                icon: "star",
                                type: "normal",
                                select_type: "icon",
                                onPress: () => {
                                  this.props.updateMailerCampaign({
                                    token: this.props.token,
                                    type: "set_default_mailer_campaign",
                                    mailer_campaign_id: item.is_default_campaign
                                      ? 0
                                      : item.id,
                                    onLoading: () => {
                                      this.removeFirstChoiceFromAll();
                                      this.setState({
                                        first_choice_loading: true
                                      });
                                    },
                                    onError: () => {
                                      this.setState({
                                        first_choice_loading: false
                                      });
                                    },
                                    onSuccess: results => {
                                      this.setState({
                                        first_choice_loading: false
                                      });

                                      if (
                                        results?.mailer_campaigns &&
                                        results?.mailer_campaigns[0]
                                      ) {
                                        this.editMailSequenceSuccess(
                                          results?.mailer_campaigns[0]
                                        );
                                      }
                                    }
                                  });
                                }
                              },
                              {
                                title: "Clone This Sequence",
                                icon: "content-copy",
                                type: "normal",
                                select_type: "icon",
                                onPress: () => {
                                  this.props.pushSidePanel({
                                    slug: "new_mail_sequence",
                                    overlay: true,
                                    data: {
                                      mail_sequence: item,
                                      onSuccess: new_mail_sequence => {
                                        this.newMailSequenceSuccess(
                                          new_mail_sequence
                                        );
                                      }
                                    }
                                  });
                                }
                              },
                              /*
                                              {
                                                title: "Edit Sequence",
                                                icon: "edit",
                                                type: "normal",
                                                select_type: "icon",
                                                onPress: () => {
                                                  this.props.pushSidePanel({
                                                    slug: "view_mail_sequence",
                                                    overlay: true,
                                                    data: {
                                                      mail_sequence: item,
                                                      onSuccess: new_mail_sequence => {
                                                        this.props.editMailSequenceSuccess(
                                                          new_mail_sequence
                                                        );
                                                      }
                                                    }
                                                  });
                                                }
                                              },
                                              */
                              !item.is_default_campaign
                                ? {
                                    title: "Delete Sequence",
                                    icon: "delete",
                                    type: "normal",
                                    select_type: "icon",
                                    onPress: () => {
                                      this.props.updateMailerCampaign({
                                        token: this.props.token,
                                        type: "remove_mailer_campaign",
                                        mailer_campaign_id: item.id,
                                        onLoading: () => {
                                          this.setState({ removing: true });
                                        },
                                        onError: () => {
                                          this.setState({ removing: false });
                                        },
                                        onSuccess: results => {
                                          this.setState({ removing: false });
                                          this.removeMailSequenceSuccess(item);
                                        }
                                      });
                                    }
                                  }
                                : null
                            ]}
                          />
                          <IconButton
                            noPress={true}
                            icon={"keyboard-arrow-right"}
                          />
                        </Row>
                      );
                    }}
                    subtext={
                      item?.number_of_steps == 1
                        ? "1 Step"
                        : item?.number_of_steps + " Steps"
                    }
                  >
                    {item?.campaign_title}
                  </SelectItem>
                );
              }}
              onRefresh={() => {
                this.getData("refresh");
              }}
              is_refreshing={mail_sequences_refreshing}
              canLoadMore={!mail_sequences_loaded_all}
              isLoadingMore={mail_sequences_loading}
              onLoadMore={() => {
                this.getData("load_more");
              }}
            />
          ) : (
            <Row style={{ flex: 1, overflow: "hidden" }}>
              <Wrapper
                style={{
                  flex: 1,
                  alignSelf: "stretch",
                  position: "relative",
                  overflow: "hidden",
                  minWidth: 1200
                }}
              >
                <Row
                  style={{
                    borderBottomWidth: 1,
                    borderBottomColor: colors.border_color,
                    borderBottomStyle: "solid",
                    paddingRight: 50
                  }}
                >
                  <Header
                    title={"Name"}
                    can_sort_by={"true"}
                    slug={"name"}
                    sort_by={sort_by}
                    onSort={this.onSort}
                    main_column={true}
                  />
                  <Header
                    title={"Steps"}
                    can_sort_by={"true"}
                    slug={"steps"}
                    sort_by={sort_by}
                    onSort={this.onSort}
                  />

                  <Header
                    title={"Active Leads"}
                    can_sort_by={"true"}
                    slug={"active_leads"}
                    sort_by={sort_by}
                    onSort={this.onSort}
                  />
                  <Header
                    title={"Paused Leads"}
                    can_sort_by={"true"}
                    slug={"paused_leads"}
                    sort_by={sort_by}
                    onSort={this.onSort}
                  />
                  <Header
                    title={"Completed Leads"}
                    can_sort_by={"true"}
                    slug={"completed_leads"}
                    sort_by={sort_by}
                    onSort={this.onSort}
                  />

                  <Header
                    title={"Date Created"}
                    can_sort_by={"true"}
                    slug={"date_created"}
                    sort_by={sort_by}
                    onSort={this.onSort}
                  />
                </Row>
                <KeyboardView style={{ flex: 1 }}>
                  {mail_sequences &&
                    mail_sequences.map((item, index) => {
                      return (
                        <MailSequence
                          {...this.props}
                          key={"mail_sequence_" + index}
                          mail_sequence={item}
                          newMailSequenceSuccess={this.newMailSequenceSuccess}
                          editMailSequenceSuccess={this.editMailSequenceSuccess}
                          removeFirstChoiceFromAll={
                            this.removeFirstChoiceFromAll
                          }
                          removeMailSequenceSuccess={
                            this.removeMailSequenceSuccess
                          }
                        />
                      );
                    })}

                  {!mail_sequences_loaded_all &&
                  !mail_sequences_loading &&
                  !mail_sequences_refreshing ? (
                    <InlineButton
                      button_type={"full"}
                      onPress={() => {
                        this.getData("load_more");
                      }}
                    >
                      Load More
                    </InlineButton>
                  ) : null}
                  {mail_sequences_refreshing ? (
                    <Wrapper
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor:
                          dark_mode == "dark_mode"
                            ? "rgba(0,0,0, 0.4)"
                            : "rgba(255, 255, 255, 0.75)"
                      }}
                    >
                      <Spin text="Loading..." />
                    </Wrapper>
                  ) : null}
                </KeyboardView>
              </Wrapper>

              {/*
              <Wrapper
                style={{
                  width: isMobile ? "100%" : 400,
                  alignSelf: "stretch",
                  backgroundColor: colors.card_color,
                  borderLeftWidth: 1,
                  borderLeftStyle: "solid",
                  borderLeftColor: colors.border_color
                }}
              >
                <KeyboardView style={{ flex: 1 }}>
                  {contentful_content ? (
                    <Wrapper style={{ padding: 25 }}>
                      <Copy>
                        <Bold>{contentful_content?.title}</Bold>
                      </Copy>

                      <ContentfulRichText
                        style={{}}
                        richText={contentful_content?.richContent}
                      />
                    </Wrapper>
                  ) : null}
                </KeyboardView>
              </Wrapper>
              */}
            </Row>
          )}
        </Container>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;
  const { colors, dark_mode } = settings;
  const { isMobile, device } = native;
  return {
    token,
    user,
    isMobile,
    colors,
    dark_mode,
    device
  };
};

export default connect(mapStateToProps, {
  pushSidePanel,
  getMailerCampaigns,
  updateMailerCampaign,
  changeTab
})(MailSequences);
