import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, InternalImage } from "app/NativeComponents/common";
import { PopoverMenu, CircleButton } from "app/NativeComponents/snippets";

import { logout, displayIntercom } from "app/NativeActions";

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      show_options: false
    };
  }

  render() {
    return (
      <Row
        style={{
          padding: this.props.desktopMobile ? 15 : 25,
          justifyContent: "space-between"
        }}
      >
        <a
          className={"deal-button"}
          href={"https://dealmachine.com/"}
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex"
          }}
        >
          <InternalImage
            style={{
              width: 40,
              objectFit: "contain",
              alignItems: "center",
              justifyContent: "flex-start"
            }}
            contain
            image={"/assets/images/Icon-blue.png"}
          />
        </a>

        {!!this.props.user && !!this.props.token ? (
          <PopoverMenu
            show={this.state.show_options}
            no_swipe={true}
            no_cancel={true}
            onShow={s => {
              this.setState({
                show_options: s
              });
            }}
            popover_width={300}
            popoverPlacement={"bottom"}
            popover_title={"Options"}
            includeCloseButton={true}
            renderComponent={options => {
              return (
                <CircleButton
                  icon="person"
                  button_type="profile_pic"
                  user={this.props.user}
                  show_notification={false}
                  noPress={true}
                />
              );
            }}
            menu_items={[
              {
                title: "Need help? Talk to a human.",
                select_type: "none",
                id: "intercom_button",
                onPress: () => {
                  displayIntercom();
                }
              },
              {
                title: "Logout",
                select_type: "none",
                onPress: this.props.logout
              }
            ]}
          />
        ) : null}
      </Row>
    );
  }
}

const mapStateToProps = ({ auth, native }) => {
  const { token, user } = auth;
  const { desktopMobile } = native;

  return {
    token,
    user,
    desktopMobile
  };
};

export default connect(mapStateToProps, { logout })(Navbar);
