import React, { Component } from "react";

import { Wrapper, Row, KeyboardView } from "app/NativeComponents/common";
import {
  InlineButton,
  InlineTabs,
  InputBox,
  PopoverMenu,
  BottomNavBar,
  IconButton,
  SelectItem
} from "app/NativeComponents/snippets";
import { store } from "app/store";
import { connect } from "react-redux";

import {
  pushSidePanel,
  updateSavedFilters,
  showSuccess
} from "app/NativeActions";

class SavedFiltersContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_more_options: props.show_more_options,
      saving_search: false
    };
  }

  componentDidMount() {}

  render() {
    const { colors } = this.props;
    return (
      <>
        <PopoverMenu
          show={this.props.show_more_options}
          no_swipe={true}
          no_cancel={true}
          onShow={s => {
            this.props.updateMainState({
              show_more_options: s
            });
          }}
          popover_width={400}
          popoverSheetTop={290}
          popoverPlacement={"bottom"}
          popover_title={"More Options"}
          includeCloseButton={true}
          hideWithOutsideClick={true}
          renderComponent={options => {
            return (
              <IconButton
                icon={"more-vert"}
                noPress={true}
                pressedIn={
                  options.hovering ||
                  options.pressedIn ||
                  this.props.show_more_options
                }
              />
            );
          }}
          renderMenu={() => {
            return (
              <Wrapper style={{ alignSelf: "stretch" }}>
                <SelectItem
                  select_type={"none"}
                  icon={"keyboard-arrow-right"}
                  onPress={() => {
                    this.props.updateMainState({
                      show_more_options: false
                    });
                    this.props.pushSidePanel({
                      slug: "saved_searches",
                      overlay: true
                    });
                  }}
                >
                  Saved Searches
                </SelectItem>
                {/*
                <SelectItem
                  select_type={"none"}
                  icon={"keyboard-arrow-right"}
                  onPress={() => {
                    this.props.updateMainState({
                      show_more_options: false
                    });
                  }}
                >
                  Recent Searches
                </SelectItem>
                */}
                <BottomNavBar>
                  <InlineButton
                    button_type={"full"}
                    selected={this.props.ableToSaveSearch() ? true : false}
                    disabled={!this.props.ableToSaveSearch()}
                    icon={"check"}
                    loading={this.state.saving_search}
                    onPress={() => {
                      this.props.updateSavedFilters({
                        token: this.props.token,
                        filter_type: "highlights",
                        filters: this.props.applied_highlight,
                        property_flags: this.props.map_filters?.property_flags
                          .map(({ value }) => value)
                          .join(","),
                        property_types: this.props.map_filters?.property_types
                          .map(({ value }) => value)
                          .join(","),
                        property_flags_and_or:
                          this.props.map_filters?.property_flags_and_or,
                        value_range_min: !!this.props.map_filters
                          ?.value_range_min
                          ? this.props.map_filters?.value_range_min
                          : null,
                        value_range_max: !!this.props.map_filters
                          ?.value_range_max
                          ? this.props.map_filters?.value_range_max
                          : null,
                        price_type: this.props.map_filters?.price_type,
                        beds_min: this.props.map_filters?.beds_min,
                        baths_min: this.props.map_filters?.baths_min,
                        use_beds_exact: this.props.map_filters?.use_beds_exact,
                        type: "create_saved_filter_with_title",
                        default_filter: 0,
                        advanced_filter_id: null,
                        onLoading: () => {
                          this.setState({
                            saving_search: true
                          });
                        },
                        onError: () => {
                          this.setState({
                            saving_search: false
                          });
                        },
                        onSuccess: results => {
                          this.setState(
                            {
                              saving_search: false
                            },
                            () => {
                              if (results?.saved_filters) {
                                this.props.showSuccess(
                                  'Your search was saved successfully! You can find it in the "Saved Searches" section.',
                                  "Success"
                                );
                                this.props.updateMainState({
                                  show_more_options: false
                                });
                              }
                            }
                          );
                        }
                      });
                    }}
                  >
                    Save Search
                  </InlineButton>
                </BottomNavBar>
              </Wrapper>
            );
          }}
          menu_items={null}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, property_map }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors } = settings;
  const { applied_highlight } = property_map;

  return {
    token,
    user,
    device,
    colors,
    applied_highlight
  };
};

export default connect(mapStateToProps, {
  pushSidePanel,
  updateSavedFilters,
  showSuccess
})(SavedFiltersContainer);
