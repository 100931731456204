import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  KeyboardView,
  Row,
  Wrapper,
  Bold,
  Copy
} from "app/NativeComponents/common";
import {
  NewHeader,
  SmallLabel,
  InputBox,
  SelectItem,
  GhostButton,
  BottomNavBar,
  ConfirmInlineButton
} from "app/NativeComponents/snippets";

import {
  generateWorkspaceTeamName,
  popSidePanel,
  editWorkspaceTeam,
  showErrorMessage,
  numberWithCommas
} from "app/NativeActions";

class EditWorkspaceTeam extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    this.state = {
      team_name: panel_data?.team?.team_name,
      team_email: panel_data?.team?.email,
      team_id: panel_data?.team?.id
    };

    this._team_name = React.createRef();
    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
    this.editTeam = this.editTeam.bind(this);
  }

  componentDidMount() {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    if (!panel_data?.team) {
      this.props.popSidePanel();
    }
  }

  checkIfNeedsToSave() {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    if (
      (panel_data?.team?.team_name !== this.state.team_name &&
        !!this.state.team_name) ||
      (panel_data?.team?.email !== this.state.team_email &&
        !!this.state.team_email)
    ) {
      return true;
    }

    return false;
  }

  editTeam() {
    if (this.checkIfNeedsToSave()) {
      this.props.editWorkspaceTeam({
        token: this.props.token,
        type: "edit_team",
        team_name: this.state.team_name,
        team_email: this.state.team_email,
        team_id: this.state.team_id,
        onLoading: () => {
          this.setState({
            loading_edit: true
          });
        },
        onError: error => {
          this.setState({
            loading_edit: false
          });

          this.props.showErrorMessage(error, "Error");
        },
        onSuccess: () => {
          this.setState(
            {
              loading_edit: false
            },
            () => {
              this.props.popSidePanel();
            }
          );
        }
      });
    }
  }

  render() {
    return (
      <Container>
        <NewHeader
          title={"Edit Team"}
          subtitle={""}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.props.popSidePanel();
            }
          }}
        />

        <KeyboardView style={{ flex: 1 }}>
          <InputBox
            input_ref={this._team_name}
            name="team_name"
            returnKeyType="next"
            blurOnSubmit={false}
            autoCapitalize="words"
            keyboardType="default"
            placeholder="Team Name"
            validationRequired={true}
            onChange={value => {
              this.setState({
                team_name: value
              });
            }}
            value={this.state.team_name}
            onSubmitEditing={() => {
              this._team_email.focus();
            }}
            type="text"
          />

          <InputBox
            input_ref={this._team_email}
            name="team_email"
            returnKeyType="next"
            blurOnSubmit={false}
            autoCapitalize="words"
            keyboardType="default"
            placeholder="Team Leader Email"
            validationRequired={true}
            onChange={value => {
              this.setState({
                team_email: value
              });
            }}
            value={this.state.team_email}
            onSubmitEditing={this.editTeam}
            type="text"
          />

          <GhostButton
            button_type="full"
            loading={this.state.loading_edit}
            disabled={!this.checkIfNeedsToSave()}
            onPress={this.editTeam}
            primary={true}
          >
            Save Team
          </GhostButton>
        </KeyboardView>
        <BottomNavBar>
          <Row style={{ justifyContent: "space-between" }}>
            <ConfirmInlineButton
              button_type="full"
              confirm_text="Really Archive?"
              confirm_icon="delete"
              loading_text="Archiving"
              loading={this.state.loading_archive}
              onPress={() => {
                this.props.editWorkspaceTeam({
                  token: this.props.token,
                  type: "archive_team",
                  team_id: this.state.team_id,
                  onLoading: () => {
                    this.setState({
                      loading_archive: true
                    });
                  },
                  onError: error => {
                    this.setState({
                      loading_archive: false
                    });

                    this.props.showErrorMessage(error, "Error");
                  },
                  onSuccess: () => {
                    this.setState(
                      {
                        loading_archive: false
                      },
                      () => {
                        this.props.popSidePanel();
                      }
                    );
                  }
                });
              }}
            >
              Archive Team
            </ConfirmInlineButton>

            <ConfirmInlineButton
              button_type="full"
              confirm_text="Really Reset?"
              loading_text="Reseting"
              loading={this.state.loading_reset}
              onPress={() => {
                this.props.editWorkspaceTeam({
                  token: this.props.token,
                  type: "reset_team_password",
                  team_id: this.state.team_id,
                  onLoading: () => {
                    this.setState({
                      loading_reset: true
                    });
                  },
                  onError: error => {
                    this.setState({
                      loading_reset: false
                    });

                    this.props.showErrorMessage(error, "Error");
                  },
                  onSuccess: () => {
                    this.setState(
                      {
                        loading_reset: false
                      },
                      () => {
                        this.props.popSidePanel();
                      }
                    );
                  }
                });
              }}
            >
              Reset Password
            </ConfirmInlineButton>
          </Row>
        </BottomNavBar>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user, user_partner_info } = auth;
  const { device, isIphoneX, isMobile, platform, desktopMobile } = native;
  const { colors } = settings;
  return {
    user,
    user_partner_info,
    token,
    device,
    isIphoneX,
    isMobile,
    platform,
    desktopMobile,
    colors
  };
};

export default connect(mapStateToProps, {
  generateWorkspaceTeamName,
  editWorkspaceTeam,
  showErrorMessage,
  popSidePanel
})(EditWorkspaceTeam);
