import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  KeyboardView,
  Spinner,
  Wrapper,
  Title,
  Copy,
  Bold,
  Button,
  Row,
  GoogleOAuth,
  AppleLogin
} from "app/NativeComponents/common";
import { NewHeader, InlineButton } from "app/NativeComponents/snippets";

import {
  /*mobile*/
  toggleCanPop,
  authFieldChanged,
  registerUser,
  appRedirect,
  getUser,
  getStats,
  updateMarketingUser,
  setModal,
  toggleModal,
  /*common functions*/
  loadSignUpOnboarding,
  dismissMobileKeyboard,
  setOAuthInfo,
  showErrorMessage,
  loginUser,
  getUserFromAppleAuth,
  openUrl
} from "app/NativeActions";

import Body from "./Body";
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      promoText: "",
      errors: {},
      defaultPromoText: this.props.user_info.promoText,
      onboarding_content: null,
      onboarding_content_loading: true,
      successfulValidations: {},

      loading: false
    };
    this.login = this.login.bind(this);
    this.appleLogin = this.appleLogin.bind(this);
    this.renderAuth = this.renderAuth.bind(this);

    this.handleBack = this.handleBack.bind(this);
  }

  confirmValidation = () => {
    let valid = true;
    let validate = ["firstname", "lastname", "email", "password", "phone"];
    validate.forEach(name => {
      let check = this.handleValidation(name, this.props[name], true);
      if (!check) {
        valid = false;
      }
    });
    return valid;
  };

  handleValidation = (name, value = "") => {
    let { errors, successfulValidations } = this.state;
    if (!value) {
      value = "";
    }
    switch (name) {
      case "firstname":
        errors.firstname =
          value.trim().length < 1 ? "*First Name is required" : "";
        successfulValidations.firstname = !errors.firstname;
        break;
      case "lastname":
        errors.lastname =
          value.trim().length < 1 ? "*Last Name is required" : "";
        successfulValidations.lastname = !errors.lastname;
        break;
      case "email":
        errors.email = validEmailRegex.test(
          value && value != "" ? value : this.props.user_info.email
        )
          ? ""
          : "*Email is not valid";
        successfulValidations.email = !errors.email;
        break;
      case "phone":
        errors.phone = !value.match(
          /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}/
        )
          ? "*Phone number is not valid"
          : "";
        successfulValidations.phone = !errors.phone;
        break;
      case "password":
        errors.password =
          value.trim().length < 5
            ? "*Password must be at least 5 characters long"
            : "";
        successfulValidations.password = !errors.password;
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value, successfulValidations });
    return !errors[name];
  };

  handleChange(value) {
    this.setState({
      promoText: value,
      defaultPromoText: ""
    });
  }

  getUrlParameter(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }
  componentDidMount() {
    let oauthed = false;
    if (this.props.device == "desktop") {
      const urlEmail = this.getUrlParameter("email");
      const lt = this.getUrlParameter("link_type");
      const t = this.getUrlParameter("team");

      if (urlEmail) {
        this.props.authFieldChanged({
          prop: "email",
          value: urlEmail
        });
      }

      if (
        !this.props.dealfinder_team_id &&
        this.props.user_info?.signup_type !== "team_invite" &&
        lt !== "team_invite" &&
        !t
      ) {
        const promo = this.props.user_info?.promoText;
        const p = this.getUrlParameter("promo");

        if (!!promo) {
          openUrl(
            "https://start.dealmachine.com/m/sign-up?promo=" + promo,
            true
          );
        } else if (!!p) {
          openUrl("https://start.dealmachine.com/m/sign-up?promo=" + p, true);
        } else {
          openUrl("https://start.dealmachine.com/m/sign-up", true);
        }
      }
    }

    this.setSignUpFields(oauthed);
    this.getOnboardingData();
  }

  setSignUpFields = oauthed => {
    if (this.props.oauth_info && !oauthed) {
      if (this.props.oauth_info.profileObj) {
        if (!this.props.oauth_info.apple_id) {
          this.props.authFieldChanged({
            prop: "firstname",
            value: this.props.oauth_info.profileObj.givenName
          });
          this.props.authFieldChanged({
            prop: "lastname",
            value: this.props.oauth_info.profileObj.familyName
          });
          this.props.authFieldChanged({
            prop: "oauth_token",
            value: this.props.oauth_info.accessToken
          });
        } else {
          this.props.authFieldChanged({
            prop: "apple_id",
            value: this.props.oauth_info.apple_id
          });
        }
        this.props.authFieldChanged({
          prop: "email",
          value: this.props.oauth_info.profileObj.email
        });
      }
    }
  };

  getOnboardingData() {
    loadSignUpOnboarding("new_onboarding")
      .then(data => {
        if (data && data.items && data.items.length > 0) {
          this.setState(() => ({
            onboarding_content: data.items[0]
              ? data.items[0].fields
                ? data.items[0].fields
                : null
              : null,
            onboarding_content_loading: false
          }));
        }
        this.setState(() => ({ onboarding_content_loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ onboarding_content_loading: false }));
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user_info && this.props.user_info) {
      if (prevProps.user_info.promoText != this.props.user_info.promoText) {
        this.setState({
          promoText: "",
          defaultPromoText: this.props.user_info.promoText
        });
      }
    }
  }

  handleBack() {
    /* mobile */
    dismissMobileKeyboard();
    this.props.appRedirect({
      redirect: "goBack",
      payload: { type: "getStarted" }
    });
  }

  register() {
    let valid = this.confirmValidation();
    /* mobile */
    dismissMobileKeyboard();

    if (valid) {
      var check_phone = 1;

      const {
        email,
        password,
        phone,
        city,
        firstname,
        lastname,
        company,
        promoText,
        user_info,
        accepted_terms,
        device,
        dealfinder_team_id = null,
        oauth_token,
        apple_id
      } = this.props;

      this.props.registerUser({
        email:
          this.props.email && this.props.email != ""
            ? this.props.email
            : this.props.user_info.email,
        password,
        phone,
        city,
        check_phone,
        firstname,
        lastname,
        company,
        accepted_terms,
        promo:
          this.state.promoText && this.state.promoText != ""
            ? this.state.promoText
            : this.state.defaultPromoText,
        from_campaign: user_info.from_campaign,
        from_source: user_info.from_source,
        branch_id: user_info.branch_id,
        team_id: dealfinder_team_id,
        affiliate_partner: this.props.affiliate_partner ? 1 : 0,
        device,
        oAuthToken: oauth_token,
        apple_id: apple_id,
        deals_last_12_mo_signup: user_info.deals_last_12_mo_signup,
        onLoading: () => {
          this.setState({ loading: true });
        },
        onError: () => {
          this.setState({ loading: false });
        },
        onSuccess: () => {
          this.setState({ loading: false });
        }
      });
    }
  }

  checkIfNeedsToSave() {
    if (
      (this.props.email && this.props.email != "") ||
      (!this.props.email &&
        this.props.email == "" &&
        this.props.user_info.email &&
        this.props.user_info.email != "") ||
      (this.props.firstname && this.props.firstname != "") ||
      (this.props.lastname && this.props.lastname != "") ||
      (this.props.company && this.props.company != "") ||
      (this.props.city && this.props.city != "") ||
      (this.state.defaultPromoText && this.state.defaultPromoText != "") ||
      (this.props.password && this.props.password != "")
    ) {
      return true;
    }

    return false;
  }
  getWindowDimensions() {
    if (window) {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height
      };
    } else {
      return {
        width: 900,
        height: 900
      };
    }
  }
  renderSpinner() {
    if (this.props.loading) {
      return <Spinner />;
    }
  }
  appleLogin(appleresponse = "") {
    /* mobile */

    dismissMobileKeyboard();
    const {
      password,
      device,
      initialRedirect,
      platform,
      user_info,
      dealfinder_team_id,
      getUserFromAppleAuth
    } = this.props;
    if (appleresponse) {
      //this.props.setOAuthInfo(oAuth);
      getUserFromAppleAuth({
        appleToken: appleresponse?.authorization.id_token,
        onSuccess: results => {
          if (results?.user_exists) {
            this.props.loginUser({
              device,
              initialRedirect,
              apple_id: appleresponse?.authorization.id_token,
              onLoading: () => {
                this.setState({
                  loading: true
                });
              },
              onError: () => {
                this.setState({
                  loading: false
                });
              },
              onSuccess: () => {
                this.setState({
                  loading: false
                });
              }
            });
          } else {
            this.props.registerUser({
              email: appleresponse?.user?.email || results?.user_email,
              firstname: appleresponse?.user?.name?.firstName,
              lastname: appleresponse?.user?.name?.lastName,
              accepted_terms: 1,
              check_phone: 0,
              initialRedirect,
              from_campaign: user_info.from_campaign,
              from_source: user_info.from_source,
              branch_id: user_info.branch_id,
              team_id: dealfinder_team_id,
              affiliate_partner: this.props.affiliate_partner ? 1 : 0,
              device,
              apple_id: appleresponse?.authorization.id_token,
              onLoading: () => {
                this.setState({ loading: true });
              },
              onError: () => {
                this.setState({ loading: false });
              },
              onSuccess: () => {
                this.setState({ loading: false });
                this.setSignUpFields(false);
              }
            });
          }
        }
      });
    } else {
      this.props.showErrorMessage("Apple authentication failed.", "Error");
    }
  }

  login(oAuth = null, oAuthProfile = null) {
    /* mobile */
    dismissMobileKeyboard();
    const {
      password,
      device,
      initialRedirect,
      platform,
      user_info,
      dealfinder_team_id
    } = this.props;

    if (oAuth) {
      const { email, familyName, givenName, imageUrl } = oAuth?.profileObj;
      this.props.setOAuthInfo(oAuth);
      this.props.registerUser({
        email: email,
        device,
        firstname: givenName,
        lastname: familyName,
        image: imageUrl,
        accepted_terms: 1,
        check_phone: 0,
        initialRedirect,
        oAuthToken: oAuth?.accessToken,
        oAuthIdToken: oAuth?.idToken,
        from_campaign: user_info.from_campaign,
        from_source: user_info.from_source,
        branch_id: user_info.branch_id,
        team_id: dealfinder_team_id,
        affiliate_partner: this.props.affiliate_partner ? 1 : 0,
        onLoading: () => {
          this.setState({ loading: true });
        },
        onError: () => {
          this.setState({ loading: false });
        },
        onSuccess: () => {
          this.setState({ loading: false });
          this.setSignUpFields(false);
        }
      });
    } else {
      this.props.showErrorMessage("Google authentication failed.", "Error");
    }
  }
  renderHeader() {
    if (this.props.device == "desktop") {
      return (
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 25
          }}
        >
          <Wrapper style={{ marginBottom: 25 }}>
            <InlineButton
              buton_type="full_button"
              onPress={() => {
                this.props.appRedirect({ redirect: "login" });
              }}
            >
              Already have an account? <Bold>Sign in</Bold>
            </InlineButton>
          </Wrapper>
          {this.props.affiliate_partner ? (
            <>
              <Title style={{ textAlign: "center" }}>
                Join our affiliate partner program
              </Title>
            </>
          ) : !this.props.dealfinder_team_id &&
            this.props.user_info?.signup_type != "team_invite" ? (
            <>
              <Title style={{ textAlign: "center" }}>
                {this.state.onboarding_content
                  ? this.state.onboarding_content.signUpTitle
                  : ""}
              </Title>
              <Copy style={{ textAlign: "center" }}>
                {this.state.onboarding_content
                  ? this.state.onboarding_content.signUpDescription
                  : ""}
              </Copy>
            </>
          ) : (
            <Title style={{ textAlign: "center" }}>
              Join {this.props.user_info?.team_name}'s team
            </Title>
          )}
          {!this.props.dealfinder_team_id &&
          this.props.user_info?.signup_type != "team_invite" ? (
            <Wrapper style={{ flex: 1, alignSelf: "stretch" }}>
              <Row
                style={{
                  marginTop: 25,
                  alignSelf: "stretch",
                  justifyContent: "center"
                }}
              >
                <Wrapper
                  style={{
                    flex: 1,
                    margin: 5
                  }}
                >
                  <GoogleOAuth
                    buttonText={"Sign up with Google"}
                    onSuccess={this.login}
                  />
                </Wrapper>
                {this.props.platform !== "android" ? (
                  <Wrapper
                    style={{
                      flex: 1,
                      margin: 5
                    }}
                  >
                    <AppleLogin
                      title={"Sign up with Apple"}
                      onSucc={this.appleLogin}
                      redirectPath={"sign-up"}
                    />
                  </Wrapper>
                ) : null}
              </Row>
              <Row
                style={{
                  padding: 25,
                  alignSelf: "stretch"
                }}
              >
                <Wrapper
                  style={{ flex: 1, height: 1, backgroundColor: "#CCC" }}
                />
                <Copy
                  style={{
                    width: "10%",
                    textAlign: "center",
                    marginLeft: 5,
                    marginRight: 5
                  }}
                >
                  Or
                </Copy>
                <Wrapper
                  style={{ flex: 1, height: 1, backgroundColor: "#CCC" }}
                />
              </Row>
            </Wrapper>
          ) : null}
        </Wrapper>
      );
    }

    return (
      <NewHeader
        title={
          this.props.user_info.signup_type == "team_invite"
            ? "Join " + this.props.user_info.team_name + "'s Team"
            : "Create Your Account"
        }
        leftButton={{
          icon: "arrow-back",
          onPress: this.handleBack
        }}
      />
    );
  }

  renderAuth() {
    if (!this.props.email) {
      return (
        <Wrapper style={{ marginTop: this.props.device == "mobile" ? 25 : 0 }}>
          <Wrapper
            style={{ flex: 1, margin: this.props.device == "mobile" ? 5 : 0 }}
          >
            <GoogleOAuth
              buttonText={"Sign up with Google"}
              onSuccess={this.login}
            />
          </Wrapper>
          {this.props.platform !== "android" ? (
            <Wrapper style={{ width: "100%", marginLeft: 5 }}>
              <AppleLogin
                title={"Sign up with Apple"}
                onSucc={this.appleLogin}
                redirectPath={"sign-up"}
              />
            </Wrapper>
          ) : null}
          <Row
            style={{
              maxWidth: "100%",
              margin: this.props.device == "mobile" ? 20 : 0,
              marginTop: 20,
              marginBottom: 20,
              justifyContent: "center"
            }}
          >
            <Wrapper style={{ flex: 1, height: 1, backgroundColor: "#CCC" }} />
            <Copy
              style={{
                width: "10%",
                textAlign: "center",
                marginLeft: 5,
                marginRight: 5
              }}
            >
              Or
            </Copy>
            <Wrapper style={{ flex: 1, height: 1, backgroundColor: "#CCC" }} />
          </Row>
        </Wrapper>
      );
    }
  }

  render() {
    if (
      this.props.device != "desktop" ||
      this.props.dealfinder_team_id ||
      this.props.user_info?.signup_type == "team_invite"
    ) {
      if (this.props.no_container) {
        return (
          <Body
            {...this.props}
            errors={this.state.errors}
            successfulValidations={this.state.successfulValidations}
            handleValidation={this.handleValidation.bind(this)}
            register={this.register.bind(this)}
            checkIfNeedsToSave={this.checkIfNeedsToSave.bind(this)}
            handleBack={this.handleBack.bind(this)}
            handleChange={this.handleChange.bind(this)}
            promoText={this.state.promoText}
            defaultPromoText={this.state.defaultPromoText}
            onboarding_content={this.state.onboarding_content}
          />
        );
      }
      return (
        <Container>
          {this.renderHeader()}
          <KeyboardView>
            {this.props.device === "mobile" ? this.renderAuth() : null}
            <Body
              {...this.props}
              errors={this.state.errors}
              handleValidation={this.handleValidation.bind(this)}
              successfulValidations={this.state.successfulValidations}
              register={this.register.bind(this)}
              checkIfNeedsToSave={this.checkIfNeedsToSave.bind(this)}
              handleBack={this.handleBack.bind(this)}
              handleChange={this.handleChange.bind(this)}
              promoText={this.state.promoText}
              defaultPromoText={this.state.defaultPromoText}
              onboarding_content={this.state.onboarding_content}
              loading={this.state.loading}
            />
          </KeyboardView>
          {this.renderSpinner()}
        </Container>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, marketing, native }) => {
  const {
    signup_type,
    signup_team_name,
    signup_team_company,

    email,
    phone,
    city,
    password,
    firstname,
    lastname,
    company,
    loading,
    accepted_terms,
    from_campaign,
    from_source,
    oauth_info,
    oauth_token,
    apple_id
  } = auth;

  const { user_info, partner_info } = marketing;
  const { device, platform, isMobile } = native;
  return {
    email,
    phone,
    city,
    password,
    firstname,
    lastname,
    company,
    accepted_terms,
    loading,
    device,
    user_info,
    partner_info,
    oauth_info,
    oauth_token,
    apple_id,
    platform,
    isMobile
  };
};

export default connect(mapStateToProps, {
  /*mobile*/
  toggleCanPop,
  appRedirect,
  loginUser,
  setOAuthInfo,
  getUser,
  getStats,
  setModal,
  toggleModal,
  authFieldChanged,
  updateMarketingUser,
  showErrorMessage,
  registerUser,
  getUserFromAppleAuth
})(Register);
