import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  Gradient,
  Wrapper,
  Row,
  Scroll,
  Copy,
  Bold
} from "app/NativeComponents/common";
import {
  UpsellButton,
  InlineButton,
  PopoverMenu,
  CircleButton,
  IconButton,
  SelectItem
} from "app/NativeComponents/snippets";

import {
  tabNavigation,
  setModal,
  toggleModal,
  changeTab,
  pushSidePanel,
  searchDealMachine,
  selectActiveProperty,
  setActiveList,
  openUrl,
  displayIntercom,
  nextOnboardingStep,
  checkIfUserHasMetadata,
  loadNewsAndUpdates,
  getDashboardStats,
  getUpsellButton
} from "app/NativeActions";
import Logo from "./Logo";
import Search from "./Search";
import PartnerLogo from "./PartnerLogo";
import CurrentCallSession from "./CurrentCallSession";
import TeamAccess from "app/Workspaces/components/WorkspaceNavBar/TeamAccess";

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      intercom_opened: false,
      local_tab: "",
      show_mail_menu: false,
      loading_seen_slugs: true,
      news_slugs: [],
      seen_news_slugs: [],
      loading_upsell_button: true,
      upsell_button: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tab !== this.props.tab) {
      this.setState({
        local_tab: this.props.tab
      });
    }

    if (this.props.location?.pathname !== prevProps.location?.pathname) {
      this.setTabFromURLPath();
    }

    if (
      JSON.stringify(this.state.news_slugs) !==
        JSON.stringify(prevState.news_slugs) ||
      prevProps.tab !== this.props.tab
    ) {
      this.getNotifications();
    }

    if (
      JSON.stringify(this.state.source_of_truth) !==
      JSON.stringify(prevState.source_of_truth)
    ) {
      this.getUpsellButton();
    }
  }

  setTabFromURLPath() {
    //
    const main_path = window?.location.pathname || "";

    if (main_path.startsWith("/dashboard")) {
      this.setState({
        local_tab: "dashboard"
      });
    } else if (main_path.startsWith("/map") || main_path.startsWith("/drive")) {
      this.setState({
        local_tab: "map"
      });
    } else if (main_path.startsWith("/leads")) {
      this.setState({
        local_tab: "leads"
      });
    } else if (main_path.startsWith("/selling")) {
      this.setState({
        local_tab: "selling"
      });
    } else if (main_path.startsWith("/mail-center")) {
      this.setState({
        local_tab: "mail_center"
      });
    } else if (main_path.startsWith("/postcards")) {
      this.setState({
        local_tab: "postcard_designer"
      });
    } else if (main_path.startsWith("/mail-sequences")) {
      this.setState({
        local_tab: "mail_sequences"
      });
    } else if (main_path.startsWith("/partner")) {
      this.setState({
        local_tab: "partner"
      });
    } else if (main_path.startsWith("/connect")) {
      this.setState({
        local_tab: "connect"
      });
    } else if (main_path.startsWith("/dialer")) {
      this.setState({
        local_tab: "dialer"
      });
    } else {
      this.setState({
        local_tab: "map"
      });
    }
    if (!main_path.startsWith("/dashboard")) {
      this.loadDashboardNotifications();
    }
  }

  componentDidMount() {
    this.setTabFromURLPath();

    this.getUpsellButton();
  }

  getUpsellButton() {
    this.props.getUpsellButton({
      token: this.props.token,
      onLoading: () => {},
      onSuccess: results => {
        this.setState({
          loading_upsell_button: false,
          upsell_button: results?.upsell_button ? results?.upsell_button : null
        });
      }
    });
  }

  loadDashboardNotifications() {
    this.setState(
      {
        loading_seen_slugs: true
      },
      () => {
        const { data } = loadNewsAndUpdates();

        loadNewsAndUpdates()
          .then(data => {
            let slugs = [];
            if (data && data.items) {
              //for all of the data items..get the slug_id from each one and put them in slugs
              data.items.map(item => {
                if (
                  item.fields &&
                  item.fields.slug_id &&
                  item.fields.show_notification
                ) {
                  slugs.push(item.fields.slug_id);
                }
              });

              this.setState({
                news_slugs: slugs
              });
            }
            this.setState(() => ({ contentful_loading: false }));
          })
          .catch(err => {
            this.setState(() => ({ contentful_loading: false }));
          });
      }
    );
  }

  getNotifications() {
    this.setState(
      {
        loading_seen_slugs: true
      },
      () => {
        this.props.getDashboardStats({
          token: this.props.token,
          type: "get_news_and_updates_notifications",
          slugs: this.state.news_slugs.join(","),
          onLoading: () => {},
          onError: error => {},
          onSuccess: results => {
            this.setState({
              loading_seen_slugs: false,
              seen_news_slugs: results?.seen_news_slugs
            });
          }
        });
      }
    );
  }

  getDaysLeftOnTrial = endDateString => {
    const endDate = new Date(endDateString);
    const today = new Date();
    const millisecondsInADay = 1000 * 60 * 60 * 24;
    const differenceInMiliSeconds = endDate.getTime() - today.getTime();
    const differenceInDays = Math.round(
      differenceInMiliSeconds / millisecondsInADay
    );
    return differenceInDays;
  };

  render() {
    const { colors, device, tab, source_of_truth, user } = this.props;
    const { local_tab } = this.state;
    const daysLeftOfTrial =
      user?.user_version >= 6 &&
      source_of_truth?.subscription?.status == "trialing" &&
      this.getDaysLeftOnTrial(source_of_truth?.subscription?.trial_end);

    const phone_plan = checkIfUserHasMetadata("dialer");
    const call_tracking = checkIfUserHasMetadata("call_tracking");

    return (
      <>
        <Gradient
          style={{
            alignSelf: "stretch",
            height: 60,
            justifyContent: "center",
            minWidth: 950
          }}
          color1={colors.side_gradient_color_1}
          color2={colors.side_gradient_color_2}
        >
          <Wrapper
            style={{
              height: 60
            }}
          >
            <Row>
              <Row>
                {this.props.main_workspace_user ? (
                  <Row>
                    <TeamAccess local_tab={local_tab} />
                    <InlineButton
                      textColor={colors.white_text_color}
                      pressedIn={local_tab == "dashboard"}
                      style={{
                        borderRadius: 0,
                        margin: 0,

                        height: 60,
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: 0,
                        paddingBottom: 0
                      }}
                      onPress={() => {
                        this.props.changeTab("dashboard", tab);
                        this.props.tabNavigation({ slug: "dashboard" });
                      }}
                    >
                      Dashboard
                    </InlineButton>
                  </Row>
                ) : this.props.user?.can_see_dashboard == 1 ? (
                  <InlineButton
                    textColor={colors.white_text_color}
                    pressedIn={local_tab === "dashboard"}
                    style={{
                      borderRadius: 0,
                      margin: 0,
                      height: 60,
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: 0,
                      paddingBottom: 0,
                      paddingLeft: 0
                    }}
                    onPress={() => {
                      this.props.changeTab("dashboard", tab);
                      this.props.tabNavigation({ slug: "dashboard" });
                    }}
                    renderRight={
                      parseInt(this.state.news_slugs?.length) -
                        parseInt(this.state.seen_news_slugs?.length) >
                        0 &&
                      !this.state.loading_seen_slugs &&
                      local_tab !== "dashboard"
                        ? () => {
                            return (
                              <Wrapper
                                style={{
                                  borderRadius: 10,
                                  width: 20,
                                  height: 20,
                                  backgroundColor: colors.orange_color,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginLeft: 10
                                }}
                              >
                                <Copy
                                  style={{
                                    color: colors.white_text_color,
                                    fontSize: 14
                                  }}
                                >
                                  {parseInt(this.state.news_slugs.length) -
                                    parseInt(this.state.seen_news_slugs.length)}
                                </Copy>
                              </Wrapper>
                            );
                          }
                        : null
                    }
                    noCopy={true}
                  >
                    <Row>
                      <Logo
                        {...this.props}
                        local_tab={local_tab}
                        workspace_image={
                          this.props.user?.workspace_settings?.workspace_image
                        }
                      />
                      <PartnerLogo {...this.props} local_tab={local_tab} />
                      <Copy style={{ color: colors.white_text_color }}>
                        Dashboard
                      </Copy>
                    </Row>
                  </InlineButton>
                ) : (
                  <Row>
                    <Logo
                      {...this.props}
                      local_tab={local_tab}
                      workspace_image={
                        this.props.user?.workspace_settings?.workspace_image
                      }
                    />
                  </Row>
                )}

                <UpsellButton
                  type="plan"
                  slug="driving"
                  tier={0}
                  contentful_slug="unlock_driving"
                  meta_slug="driving_for_dollars"
                  onLockPressOverride={() => {
                    this.props.changeTab("map", tab);
                    this.props.tabNavigation({ slug: "map" });
                  }}
                  onPress={() => {
                    this.props.changeTab("map", tab);
                    this.props.tabNavigation({ slug: "map" });
                  }}
                  renderLockedChildren={({ hovering }) => {
                    return (
                      <InlineButton
                        icon="lock"
                        noPress={true}
                        pressedIn={hovering || local_tab == "map"}
                        textColor={colors.white_text_color}
                        onPress={() => {}}
                        style={{
                          borderRadius: 0,
                          margin: 0,

                          height: 60,
                          alignItems: "center",
                          justifyContent: "center",
                          paddingTop: 0,
                          paddingBottom: 0
                        }}
                      >
                        Map
                      </InlineButton>
                    );
                  }}
                  renderChildren={({ hovering }) => {
                    return (
                      <InlineButton
                        noPress={true}
                        pressedIn={hovering || local_tab == "map"}
                        textColor={colors.white_text_color}
                        onPress={() => {}}
                        style={{
                          borderRadius: 0,
                          margin: 0,

                          height: 60,
                          alignItems: "center",
                          justifyContent: "center",
                          paddingTop: 0,
                          paddingBottom: 0
                        }}
                      >
                        Map
                      </InlineButton>
                    );
                  }}
                />

                <InlineButton
                  textColor={colors.white_text_color}
                  pressedIn={local_tab == "leads"}
                  style={{
                    borderRadius: 0,
                    margin: 0,

                    height: 60,
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: 0,
                    paddingBottom: 0
                  }}
                  onPress={() => {
                    this.props.changeTab("leads", tab);
                    this.props.tabNavigation({ slug: "leads" });
                  }}
                >
                  Leads
                </InlineButton>

                {this.props.user.can_send_message == 1 ||
                (!checkIfUserHasMetadata("paid_plan") &&
                  !checkIfUserHasMetadata("dialer")) ? (
                  <InlineButton
                    textColor={colors.white_text_color}
                    pressedIn={local_tab === "dialer"}
                    icon={!checkIfUserHasMetadata("dialer") ? "lock" : ""}
                    style={{
                      borderRadius: 0,
                      margin: 0,

                      height: 60,
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: 0,
                      paddingBottom: 0
                    }}
                    onPress={() => {
                      if (!checkIfUserHasMetadata("dialer")) {
                        this.props.pushSidePanel({
                          slug: "purchase_plan_prompt",
                          overlay_modal: true,
                          locked: true,
                          data: {
                            title:
                              "Get an AI-Powered Dialer With DealMachine Pro.",
                            dismiss_button:
                              "No thanks. Let me continue without an AI-Powered Dialer.",
                            no_free: true,
                            event_slug: "in_app_dialer"
                          },
                          locked: true
                        });
                      } else {
                        this.props.changeTab("dialer", tab);
                        this.props.tabNavigation({
                          slug: "dialer"
                        });
                      }
                    }}
                  >
                    Dialer
                  </InlineButton>
                ) : null}

                {this.props.user?.can_edit_templates == 1 ||
                this.props.user?.can_send_mail == 1 ? (
                  <>
                    {this.props.user?.can_edit_templates == 0 &&
                    this.props.user?.can_send_mail == 1 ? (
                      <InlineButton
                        textColor={colors.white_text_color}
                        pressedIn={
                          local_tab == "mail_center" ||
                          local_tab == "mail_sequences" ||
                          local_tab == "postcard_designer"
                        }
                        onPress={() => {
                          this.props.changeTab("mail_center", tab);
                          this.props.tabNavigation({
                            slug: "mail_center"
                          });
                        }}
                        style={{
                          borderRadius: 0,
                          margin: 0,

                          height: 60,
                          alignItems: "center",
                          justifyContent: "center",
                          paddingTop: 0,
                          paddingBottom: 0
                        }}
                      >
                        Mail
                      </InlineButton>
                    ) : (
                      <PopoverMenu
                        show={this.state.show_mail_menu}
                        onShow={s => {
                          this.setState({ show_mail_menu: s });
                        }}
                        popover_width={350}
                        popoverSheetTop={300}
                        popoverPlacement={"bottom"}
                        renderComponent={options => {
                          return (
                            <InlineButton
                              textColor={colors.white_text_color}
                              pressedIn={
                                local_tab == "mail_center" ||
                                local_tab == "mail_sequences" ||
                                local_tab == "postcard_designer" ||
                                options.hovering ||
                                this.state.show_mail_menu
                              }
                              noPress={true}
                              style={{
                                borderRadius: 0,
                                margin: 0,

                                height: 60,
                                alignItems: "center",
                                justifyContent: "center",
                                paddingTop: 0,
                                paddingBottom: 0
                              }}
                            >
                              Mail
                            </InlineButton>
                          );
                        }}
                        renderMenu={() => {
                          return (
                            <Wrapper style={{ alignSelf: "stretch" }}>
                              {this.props.user?.can_send_mail == 1 ? (
                                <SelectItem
                                  select_type="none"
                                  icon={"keyboard-arrow-right"}
                                  onPress={() => {
                                    this.setState(
                                      {
                                        show_mail_menu: false
                                      },
                                      () => {
                                        this.props.changeTab(
                                          "mail_center",
                                          tab
                                        );
                                        this.props.tabNavigation({
                                          slug: "mail_center"
                                        });
                                      }
                                    );
                                  }}
                                >
                                  Mail Center
                                </SelectItem>
                              ) : null}
                              {this.props.user?.can_edit_templates == 1 ? (
                                <SelectItem
                                  style={{ flex: 1 }}
                                  select_type="none"
                                  icon={"keyboard-arrow-right"}
                                  onPress={() => {
                                    this.setState(
                                      {
                                        show_mail_menu: false
                                      },
                                      () => {
                                        this.props.changeTab(
                                          "postcard_designer",
                                          tab
                                        );
                                        this.props.tabNavigation({
                                          slug: "postcard_designer"
                                        });
                                      }
                                    );
                                  }}
                                >
                                  Postcards
                                </SelectItem>
                              ) : null}

                              {this.props.user?.can_edit_templates == 1 &&
                              !checkIfUserHasMetadata("paid_plan") &&
                              !checkIfUserHasMetadata(
                                "advanced_mail_sequences"
                              ) ? (
                                <SelectItem
                                  primary={true}
                                  onPress={() => {
                                    if (
                                      !checkIfUserHasMetadata("paid_plan") &&
                                      !checkIfUserHasMetadata(
                                        "advanced_mail_sequences"
                                      )
                                    ) {
                                      this.props.pushSidePanel({
                                        slug: "purchase_plan_prompt",
                                        overlay_modal: true,
                                        locked: true,
                                        data: {
                                          title:
                                            "Close More Deals With DealMachine Pro.",
                                          dismiss_button:
                                            "No thanks. Let me continue without Pro.",
                                          no_free: true,
                                          event_slug: "in_app_mail_sequences"
                                        },
                                        locked: true
                                      });
                                    } else {
                                      this.setState(
                                        {
                                          show_mail_menu: false
                                        },
                                        () => {
                                          this.props.changeTab(
                                            "mail_sequences",
                                            tab
                                          );
                                          this.props.tabNavigation({
                                            slug: "mail_sequences"
                                          });
                                        }
                                      );
                                    }
                                  }}
                                  select_type="icon"
                                  select_icon="lock"
                                  icon={"keyboard-arrow-right"}
                                >
                                  Mail Sequences
                                </SelectItem>
                              ) : this.props.user?.can_edit_templates == 1 ? (
                                <UpsellButton
                                  meta_slug={"advanced_mail_sequences"}
                                  onPress={() => {
                                    this.setState(
                                      {
                                        show_mail_menu: false
                                      },
                                      () => {
                                        this.props.changeTab(
                                          "mail_sequences",
                                          tab
                                        );
                                        this.props.tabNavigation({
                                          slug: "mail_sequences"
                                        });
                                      }
                                    );
                                  }}
                                  onLockPress={() => {
                                    this.setState({ show_mail_menu: false });
                                  }}
                                  renderLockedChildren={({ hovering }) => {
                                    return (
                                      <>
                                        <SelectItem
                                          primary={true}
                                          noPress={true}
                                          pressedIn={hovering}
                                          select_type="icon"
                                          select_icon="lock"
                                          icon={"keyboard-arrow-right"}
                                        >
                                          Mail Sequences
                                        </SelectItem>
                                      </>
                                    );
                                  }}
                                  renderChildren={({ hovering }) => {
                                    return (
                                      <>
                                        <SelectItem
                                          select_type="none"
                                          noPress={true}
                                          pressedIn={hovering}
                                          icon={"keyboard-arrow-right"}
                                        >
                                          Mail Sequences
                                        </SelectItem>
                                      </>
                                    );
                                  }}
                                />
                              ) : null}
                            </Wrapper>
                          );
                        }}
                      />
                    )}
                  </>
                ) : null}
              </Row>
              <Wrapper style={{ flex: 1 }}>
                <Row style={{ marginRight: 10, justifyContent: "flex-end" }}>
                  {!this.state.loading_upsell_button ? (
                    <>
                      {this.state.upsell_button ? (
                        <Wrapper
                          className="sparkle-effect"
                          style={{
                            margin: 10,
                            marginRight: 5,
                            marginLeft: 5,
                            borderRadius: 30
                          }}
                        >
                          <InlineButton
                            style={{
                              borderRadius: 30,
                              margin: 0,
                              height: 40,
                              alignItems: "center",
                              justifyContent: "center",
                              paddingTop: 0,
                              paddingBottom: 0,
                              backgroundColor: colors.active_color_muted
                            }}
                            onPress={() => {
                              switch (this.state.upsell_button.button_type) {
                                case "plan_prompt":
                                default:
                                  this.props.pushSidePanel({
                                    slug: this.state.upsell_button?.button_slug,
                                    overlay_modal: true,
                                    locked: true
                                  });
                                  break;
                              }
                            }}
                            selected={true}
                            textStyle={{ fontSize: 13 }}
                            description={this.state.upsell_button?.description}
                            right_icon="keyboard-arrow-right"
                          >
                            {this.state.upsell_button?.title}
                          </InlineButton>
                        </Wrapper>
                      ) : this.props.user?.can_mimic_team_owner == 1 &&
                        !checkIfUserHasMetadata("people_data") &&
                        !checkIfUserHasMetadata("paid_plan") ? (
                        <Wrapper
                          className="sparkle-effect"
                          style={{
                            margin: 10,
                            marginRight: 5,
                            marginLeft: 5,
                            borderRadius: 30
                          }}
                        >
                          <InlineButton
                            style={{
                              borderRadius: 30,
                              margin: 0,
                              height: 40,
                              alignItems: "center",
                              justifyContent: "center",
                              paddingTop: 0,
                              paddingBottom: 0,
                              backgroundColor: colors.active_color_muted
                            }}
                            onPress={() => {
                              this.props.pushSidePanel({
                                slug: "purchase_plan_prompt",
                                overlay_modal: true,
                                locked: true,
                                data: {
                                  title:
                                    "Close More Deals With DealMachine Pro.",
                                  dismiss_button:
                                    "No thanks. Let me continue without Pro.",
                                  no_free: true,
                                  event_slug: "in_app_purchase_pro_nav"
                                },
                                locked: true
                              });
                            }}
                            selected={true}
                            textStyle={{ fontSize: 13 }}
                            description="Close more deals with DealMachine Pro"
                            right_icon="keyboard-arrow-right"
                          >
                            Purchase Pro
                          </InlineButton>
                        </Wrapper>
                      ) : this.props.user?.can_mimic_team_owner == 1 &&
                        daysLeftOfTrial ? (
                        <>
                          <Wrapper
                            className="sparkle-effect"
                            style={{
                              margin: 10,
                              marginRight: 5,
                              marginLeft: 5,
                              borderRadius: 30
                            }}
                          >
                            <InlineButton
                              style={{
                                borderRadius: 30,
                                margin: 0,
                                height: 40,
                                alignItems: "center",
                                justifyContent: "center",
                                paddingTop: 0,
                                paddingBottom: 0,
                                backgroundColor: colors.active_color_muted
                              }}
                              onPress={() => {
                                this.props.pushSidePanel({
                                  slug: "end_trial",
                                  overlay: true
                                });
                              }}
                              // icon="star"

                              textStyle={{ fontSize: 13 }}
                              description="Start
                        your subscription now"
                            >
                              {daysLeftOfTrial} Days Left
                            </InlineButton>
                          </Wrapper>
                        </>
                      ) : null}
                    </>
                  ) : null}

                  <CurrentCallSession />

                  {this.props.main_workspace_user &&
                  this.props.active_workspace_team ? (
                    <>
                      <IconButton
                        tooltip="Help & Support"
                        icon_color={colors.white_text_color}
                        icon={"contact-support"}
                        borderColor={colors.white_text_color}
                        image_size={36}
                        no_border={true}
                        onPress={() => {
                          this.props.pushSidePanel({
                            slug: "help_center",
                            overlay: true
                          });
                          //displayIntercom();
                        }}
                        size={22}
                        style={{
                          borderRadius: 0,
                          margin: 0,
                          height: 60,
                          alignItems: "center",
                          justifyContent: "center",
                          paddingTop: 0,
                          paddingBottom: 0
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <IconButton
                        tooltip="Help & Support"
                        icon_color={colors.white_text_color}
                        icon={"contact-support"}
                        borderColor={colors.white_text_color}
                        image_size={36}
                        no_border={true}
                        onPress={() => {
                          this.props.pushSidePanel({
                            slug: "help_center",
                            overlay: true
                          });
                          //displayIntercom();
                        }}
                        size={22}
                        style={{
                          borderRadius: 0,
                          margin: 0,
                          height: 60,
                          alignItems: "center",
                          justifyContent: "center",
                          paddingTop: 0,
                          paddingBottom: 0
                        }}
                      />

                      <IconButton
                        tooltip="Tasks"
                        pressedIn={local_tab == "tasks"}
                        icon_color={colors.white_text_color}
                        icon={"assignment"}
                        show_notification={
                          this.props.user?.active_task_count &&
                          this.props.user?.active_task_count > 0
                            ? true
                            : false
                        }
                        notification_color={colors.orange_color}
                        notification_number={
                          this.props.user?.active_task_count
                            ? this.props.user.active_task_count
                            : null
                        }
                        notification_size={16}
                        notification_font_size={10}
                        notification_offset_y={10}
                        borderColor={colors.white_text_color}
                        image_size={36}
                        no_border={true}
                        onPress={() => {
                          this.props.pushSidePanel({
                            slug: "tasks",
                            overlay: true
                          });
                        }}
                        style={{
                          borderRadius: 0,
                          margin: 0,
                          marginRight: 5,
                          height: 60,
                          alignItems: "center",
                          justifyContent: "center",
                          paddingTop: 0,
                          paddingBottom: 0
                        }}
                        size={22}
                      />
                      {this.props.user.can_see_dashboard == 1 && (
                        <IconButton
                          tooltip="Notifications"
                          icon={"notifications"}
                          onPress={() =>
                            this.props.pushSidePanel({
                              slug: "notifications",
                              overlay: true
                            })
                          }
                          borderColor={colors.white_text_color}
                          image_size={36}
                          no_border={true}
                          icon_color={colors.white_text_color}
                          style={{
                            borderRadius: 0,
                            margin: 0,
                            marginRight: 5,
                            height: 60,
                            alignItems: "center",
                            justifyContent: "center",
                            paddingTop: 0,
                            paddingBottom: 0
                          }}
                          size={23}
                        />
                      )}
                    </>
                  )}

                  {this.props.user.can_see_all_deals == 1 ? (
                    <Search {...this.props} />
                  ) : null}

                  <CircleButton
                    icon="person"
                    button_type="profile_pic"
                    user={this.props.user}
                    show_notification={false}
                    notification_color={colors.active_color}
                    notification_icon={""}
                    borderColor={colors.white_text_color}
                    image_size={36}
                    no_border={true}
                    onPress={() => {
                      this.props.pushSidePanel({
                        slug: "settings",
                        overlay: true
                      });
                    }}
                  />
                </Row>
              </Wrapper>
            </Row>
          </Wrapper>
        </Gradient>
      </>
    );
  }
}

const mapStateToProps = ({
  auth,
  native,
  settings,
  route,
  drawer,
  partner,
  tasks,
  feature_toggle,
  onboarding,
  billing,
  dialer,
  workspaces
}) => {
  const { token, user, user_partner_info } = auth;
  const { device, isIphoneX, isMobile, platform, desktopMobile } = native;
  const { colors } = settings;
  const { track_route } = route;
  const { tab } = drawer;
  const { is_partner } = partner;
  const { active_task_count } = tasks;
  const { source_of_truth, all_feature_metadata } = billing;
  const { mailCenter, listBuilderMap } = feature_toggle;
  const { current_call_session } = dialer;
  const { main_workspace_user, active_workspace_team } = workspaces;
  return {
    user,
    user_partner_info,
    token,
    device,
    isIphoneX,
    isMobile,
    platform,
    desktopMobile,
    colors,
    track_route,
    tab,
    is_partner,
    active_task_count,
    onboarding,
    source_of_truth,
    mailCenter,
    listBuilderMap,
    all_feature_metadata,
    current_call_session,
    main_workspace_user,
    active_workspace_team
  };
};

export default withRouter(
  connect(mapStateToProps, {
    tabNavigation,
    setModal,
    changeTab,
    pushSidePanel,
    searchDealMachine,
    selectActiveProperty,

    toggleModal,
    setActiveList,
    nextOnboardingStep,
    getDashboardStats,
    getUpsellButton
  })(NavBar)
);
