import React, { PureComponent } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  ModalOverlay,
  Modal,
  Card,
  Form,
  Row,
  CardBody,
  Title,
  Copy,
  Bold,
  Button,
  ExternalLink
} from "app/NativeComponents/common";

import { PopoverMenu } from "app/NativeComponents/snippets";

import { toggleModal, startFadeOut } from "app/NativeActions";

import ModalIcon from "./ModalIcon";
import SubmitButton from "./SubmitButton";
import SecondButton from "./SecondButton";
import CancelButton from "./CancelButton";

import ProgressModal from "./ProgressModal";

class ModalView extends PureComponent {
  render() {
    if (
      this.props.show &&
      (this.props.type == "alert" || this.props.type == "normal")
    ) {
      if (this.props.device == "desktop") {
        return (
          <ModalOverlay
            isVisible={this.props.show}
            style={{ zIndex: 5 }}
            onPress={() => {
              this.props.toggleModal({ show: false, type: null });
            }}
          >
            <Modal>
              <Card
                style={{
                  minWidth: 200
                }}
              >
                <Wrapper style={{ padding: 25 }}>
                  <Copy>
                    <Bold>{this.props.title}</Bold>
                  </Copy>
                  <Copy>
                    {this.props.description}
                    {this.props.buttonPress && this.props.buttonText ? (
                      <ExternalLink to={this.props.buttonPress} target="_blank">
                        {this.props.buttonText}
                      </ExternalLink>
                    ) : null}
                  </Copy>
                </Wrapper>

                <SubmitButton {...this.props} />

                <SecondButton {...this.props} />
                <CancelButton {...this.props} />
              </Card>
            </Modal>
          </ModalOverlay>
        );
      }

      let popover_height = 330;
      if (!!this.props.secondary) {
        popover_height = 375;
      }

      return (
        <PopoverMenu
          show={true}
          no_swipe={true}
          no_cancel={true}
          onShow={s => {
            if (!s) {
              this.props.toggleModal({ show: false, type: null });
            }
          }}
          popover_title={this.props.title}
          popover_description={this.props.description}
          popoverSheetTop={popover_height}
          includeCloseButton={true}
          renderComponent={options => {
            return null;
          }}
          renderMenu={() => {
            return (
              <>
                <SubmitButton {...this.props} />
                <CancelButton {...this.props} />
              </>
            );
          }}
          menu_items={null}
        />
      );
    } else if (this.props.show && this.props.type == "progress") {
      return <ProgressModal {...this.props} />;
    }
    return <Wrapper />;
  }
}

const mapStateToProps = ({ native, settings, modal, photo }) => {
  const { device } = native;
  const { colors } = settings;

  const {
    show,
    type,
    startFade,
    title,
    description,
    icon,
    image,
    onPress,
    onCancel,
    cancel,
    submit,
    secondary,
    secondaryPress,
    buttonType,
    buttonText,
    buttonPress,
    submit_loading,
    require_complete_function
  } = modal;
  const { uploaded, uploading, progress } = photo;
  return {
    device,
    colors,
    show,
    type,
    startFade,
    title,
    description,
    icon,
    image,
    onPress,
    onCancel,
    cancel,
    submit,
    secondary,
    secondaryPress,
    buttonType,
    buttonText,
    buttonPress,
    uploaded,
    uploading,
    progress,
    submit_loading,
    require_complete_function
  };
};

export default connect(mapStateToProps, {
  toggleModal,
  startFadeOut
})(ModalView);
