import React, { Component } from "react";
import { connect } from "react-redux";
import { getDashboardStats, pushSidePanel } from "app/NativeActions";

import { SpinWrapper, LineGraph } from "app/NativeComponents/snippets";
import { Wrapper, Copy, Row } from "app/NativeComponents/common";
import StatBlock from "./StatBlock";

class MailSent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mail_sent: null,
      total_previous_mail_sent: 0,
      previous_leads_mailed: 0,
      previous_call_tracking_responses: 0,
      previous_response_rate: 0,
      total_mail_sent: 0,
      leads_mailed: 0,
      call_tracking_responses: 0,
      response_rate: 0
    };
  }

  handleBack() {
    this.props.popSidePanel();
  }

  componentDidMount() {
    this.getData({ load_type: "load" });
  }

  getData({ load_type = "load" }) {
    this.props.getDashboardStats({
      token: this.props.token,
      type: "get_mail_sent",
      filter_date: this.props.filter_date,
      filter_start_date: this.props.filter_start_date,
      filter_end_date: this.props.filter_end_date,
      filter_user: this.props.filter_user ? this.props.filter_user?.id : null,
      filter_team: this.props.filter_team ? this.props.filter_team?.id : null,
      workspace_id: this.props.workspace_id,
      filter_design:
        this.props.design_or_mail_sequence === "designs"
          ? this.props.filter_design
          : null,
      filter_mail_sequence:
        this.props.design_or_mail_sequence === "mail_sequences"
          ? this.props.filter_mail_sequence
          : null,
      onLoading: () => {
        this.props.updateMainState({
          prop: "mail_sent_loading",
          value: true
        });

        this.setState({
          mail_sent: null,
          total_previous_mail_sent: 0,
          previous_leads_mailed: 0,
          previous_call_tracking_responses: 0,
          previous_response_rate: 0,
          total_mail_sent: 0,
          leads_mailed: 0,
          call_tracking_responses: 0,
          response_rate: 0
        });
      },
      onError: () => {
        this.props.updateMainState({
          prop: "mail_sent_loading",
          value: false
        });
      },
      onSuccess: results => {
        this.props.updateMainState({
          prop: "mail_sent_loading",
          value: false
        });

        this.setState({
          mail_sent: results?.mail_sent ? results.mail_sent : null,
          total_previous_mail_sent: results?.total_previous_mail_sent
            ? results.total_previous_mail_sent
            : 0,
          previous_leads_mailed: results?.previous_leads_mailed
            ? results.previous_leads_mailed
            : 0,
          previous_call_tracking_responses:
            results?.previous_call_tracking_responses
              ? results.previous_call_tracking_responses
              : 0,
          previous_response_rate: results?.previous_response_rate
            ? results.previous_response_rate
            : 0,
          total_mail_sent: results?.total_mail_sent
            ? results.total_mail_sent
            : 0,
          leads_mailed: results?.leads_mailed ? results.leads_mailed : 0,
          call_tracking_responses: results?.call_tracking_responses
            ? results.call_tracking_responses
            : 0,

          response_rate: results?.response_rate ? results.response_rate : 0
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.filter_user?.id != prevProps.filter_user?.id ||
      this.props.filter_team?.id != prevProps.filter_team?.id ||
      this.props.filter_date != prevProps.filter_date ||
      this.props.filter_start_date != prevProps.filter_start_date ||
      this.props.filter_end_date != prevProps.filter_end_date ||
      this.props.filter_design != prevProps.filter_design ||
      this.props.filter_mail_sequence != prevProps.filter_mail_sequence
    ) {
      this.getData({ load_type: "refresh" });
    }
  }

  componentWillUnmount() {}

  render() {
    const { colors, mail_sent_loading, filter_date } = this.props;

    const {
      mail_sent,
      total_previous_mail_sent,
      previous_leads_mailed,
      previous_call_tracking_responses,
      previous_response_rate,
      total_mail_sent,
      leads_mailed,
      call_tracking_responses,
      response_rate
    } = this.state;

    return (
      <>
        {this.props.device === "desktop" ? (
          <Row>
            <StatBlock
              title={"Total Mail Sent"}
              number={total_mail_sent}
              prev_number={total_previous_mail_sent}
              prev_title={this.props.renderPreviousTitle()}
            />
            <StatBlock
              title={"Total Responses"}
              number={call_tracking_responses}
              prev_number={previous_call_tracking_responses}
              prev_title={this.props.renderPreviousTitle()}
            />

            <StatBlock
              title={"Response Rate"}
              selected={true}
              number={response_rate}
              prev_number={previous_response_rate}
              format={"percent"}
              prev_title={this.props.renderPreviousTitle()}
              tooltip={
                'The response rate is the number of calls and/or text that were received from "Call Tracking Numbers" divided by the total number of mailed properties.'
              }
            />
          </Row>
        ) : (
          <>
            <Row>
              <StatBlock
                title={"Total Mail Sent"}
                number={total_mail_sent}
                prev_number={total_previous_mail_sent}
                prev_title={this.props.renderPreviousTitle()}
              />
              <StatBlock
                title={"Total Responses"}
                number={call_tracking_responses}
                prev_number={previous_call_tracking_responses}
                prev_title={this.props.renderPreviousTitle()}
              />
            </Row>
            <Row>
              <StatBlock
                title={"Response Rate"}
                selected={true}
                number={response_rate}
                prev_number={previous_response_rate}
                format={"percent"}
                prev_title={this.props.renderPreviousTitle()}
                tooltip={
                  'The response rate is the number of calls and/or text that were received from "Call Tracking Numbers" divided by the total number of mailed properties.'
                }
              />
            </Row>
          </>
        )}
        {this.props.calls_made_loading ? (
          <Wrapper style={{ height: 300, justifyContent: "center" }}>
            <SpinWrapper />
          </Wrapper>
        ) : mail_sent &&
          filter_date !== "yesterday" &&
          filter_date !== "today" ? (
          <Wrapper
            style={
              this.props.device !== "desktop"
                ? { paddingTop: 25, paddingBottom: 25 }
                : {}
            }
          >
            <LineGraph
              title="Mail Sent"
              data1={mail_sent}
              x_title={"Date"}
              y_title={"Mail Sent"}
              x_key1={"date"}
              y_key1={"mail_sent"}
              x_key2={"prev_date"}
              y_key2={"prev_mail_sent"}
            />
          </Wrapper>
        ) : filter_date === "yesterday" ||
          filter_date === "today" ||
          mail_sent?.length === 0 ? (
          <>
            <Wrapper
              style={{
                borderRadius: 5,
                borderWidth: 1,
                borderColor: colors.border_color,
                borderStyle: "solid",
                margin: 5,
                padding: 25,
                alignItems: "center",
                justifyContent: "center",
                height: 300
              }}
            >
              <Copy style={{ textAlign: "center" }}>
                Not enough data to display graph.
              </Copy>
            </Wrapper>
          </>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, dialer }) => {
  const { token, user } = auth;

  const { colors } = settings;
  const { platform, device, isMobile } = native;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform
  };
};

export default connect(mapStateToProps, {
  getDashboardStats,
  pushSidePanel
})(MailSent);
