import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Wrapper, Copy } from "app/NativeComponents/common";
import {
  decryptData
} from "app/NativeActions";
class EmailAddressColumnItem extends PureComponent {

  decryptEmails = (emails = []) => {
    return emails.map(emailObj => ({
      ...emailObj,
      email: decryptData(emailObj.label)
    }));
  };


  render() {
    const { property, colors, section, row_height, type } = this.props;

    let email_addresses = "--";

    // Decrypt emails before use
    const decryptedEmails = this.decryptEmails(property?.emails || []);

    const tracingEmailAddresses = decryptedEmails.map(x => x.email);
    const combineEmailAddresses = [
      ...tracingEmailAddresses,
      ...(property?.deal?.email_addresses || [])
    ];

    email_addresses =
      combineEmailAddresses.length > 0
        ? combineEmailAddresses.join(", ")
        : "--";
    return (
      <Wrapper
        className={"deal-hover-preview"}
        style={{
          padding: 10,
          height: row_height,
          position: "relative"
        }}
      >
        <Wrapper
          style={{
            flex: 1,
            alignItems: "flex-start",
            justifyContent: "center"
          }}
        >
          <Copy style={{ whiteSpace: "nowrap" }}>
            {this.props.getHighlightedText(email_addresses)}
          </Copy>
        </Wrapper>
      </Wrapper>
    );
  }
}

export default EmailAddressColumnItem;
