import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Card,
  CardBody,
  Title,
  Copy,
  Bold,
  ProgressBar
} from "app/NativeComponents/common";

import { getBulkEdits, numberWithCommas } from "app/NativeActions";

class BulkLoading extends Component {
  componentDidMount() {
    this.props.getBulkEdits({ token: this.props.token });
  }
  render() {
    const { bulk_edits, user, colors } = this.props;

    if (bulk_edits && bulk_edits.length > 0) {
      return (
        <>
          {bulk_edits.map((bulk_edit, i) => {
            if (parseInt(bulk_edit.percent_complete) / 100 < 1 && i == 0) {
              return (
                <Wrapper key={"bulk_edit_" + i + "_" + bulk_edit.id}>
                  <Wrapper>
                    <Copy style={{ fontSize: 10 }}>{bulk_edit.text}</Copy>
                  </Wrapper>
                  <ProgressBar
                    style={{ marginTop: 0 }}
                    color={colors.success_color}
                    backgroundColor={colors.white_color}
                    width={150}
                    progress={parseInt(bulk_edit.percent_complete) / 100}
                  />
                </Wrapper>
              );
            }
            return null;
          })}
        </>
      );
    }

    return null;
  }
}

const mapStateToProps = ({ auth, settings, native, lead }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile } = native;
  const { bulk_edits } = lead;

  return {
    token,
    user,
    colors,
    isMobile,
    bulk_edits
  };
};

export default connect(mapStateToProps, {
  getBulkEdits
})(BulkLoading);
