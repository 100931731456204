import React, { Component } from "react";
import { connect } from "react-redux";

import {
  getCallSession,
  numberWithCommas,
  updateCallSession,
  getDialerStats,
  pushSidePanel,
  replaceSidePanel,
  setActiveDialerContact,
  triggerFollowUpQueueRefresh
} from "app/NativeActions";

import {
  Wrapper,
  Row,
  Copy,
  Bold,
  Button,
  Icon
} from "app/NativeComponents/common";
import { ConfirmInlineButton } from "app/NativeComponents/snippets";
import moment from "moment";

class CurrentCallSession extends Component {
  constructor(props) {
    super(props);

    this.state = {
      intercom_opened: false,
      local_tab: "",
      show_mail_menu: false,
      call_time: null,
      isTimerActive: false,

      follow_up_queue_count_loading: false,
      call_session_loading: false
    };

    this._call_time_interval = null;

    this.setCallTime = this.setCallTime.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.getFollowUpQueueCount = this.getFollowUpQueueCount.bind(this);
  }

  componentDidMount() {
    this.props.getCallSession({
      token: this.props.token,
      type: "get_current_call_session",
      onLoading: () => {
        this.setState({
          call_session_loading: true
        });
      },
      onError: () => {
        this.setState({
          call_session_loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          call_session_loading: false
        });
      }
    });

    this.getFollowUpQueueCount();
  }

  getFollowUpQueueCount() {
    this.props.getDialerStats({
      token: this.props.token,
      filter_user: this.props.user?.id,
      type: "my_follow_up_queue_count",
      onLoading: () => {
        this.setState({
          follow_up_queue_count_loading: true
        });
      },
      onError: () => {
        this.setState({
          follow_up_queue_count_loading: false
        });
      },
      onSuccess: results => {
        if (results?.follow_up_queue_count) {
          this.setState({
            follow_up_queue_count_loading: false
          });
        }
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.current_call_session?.id !== this.props.current_call_session?.id
    ) {
      if (this.props.current_call_session) {
        this.setState(
          {
            isTimerActive: true
          },
          () => {
            this.setCallTime();
          }
        );
      }
    }
    if (
      prevProps.current_call_session?.id !==
        this.props.current_call_session?.id ||
      prevProps.current_call_session?.completed !==
        this.props.current_call_session?.completed
    ) {
      this.getFollowUpQueueCount();
    }

    if (
      this.props.follow_up_queue_refresh &&
      this.props.follow_up_queue_refresh !==
        prevProps.follow_up_queue_refresh &&
      !this.props.current_call_session
    ) {
      this.getFollowUpQueueCount();
      this.props.triggerFollowUpQueueRefresh(false);
    }
  }

  componentWillUnmount() {
    clearTimeout(this._call_time_interval);
  }

  stopTimer() {
    this.setState({ isTimerActive: false }, () => {
      clearTimeout(this._call_time_interval);
    });
  }

  setCallTime() {
    if (this.state.isTimerActive) {
      var utc_time = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      var ms = moment(utc_time, "YYYY-MM-DD HH:mm:ss").diff(
        moment(
          this.props.current_call_session?.date_created,
          "YYYY-MM-DD HH:mm:ss"
        )
      );
      var d = moment.duration(ms);
      var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

      this.setState(
        {
          call_time: s
        },
        () => {
          clearTimeout(this._call_time_interval);
          this._call_time_interval = setTimeout(() => {
            this.setCallTime();
          }, 1000);
        }
      );
    }
  }

  render() {
    const { colors, current_call_session } = this.props;
    if (
      this.props.user.team_owner == 1 ||
      this.props.user.can_send_message == 1
    ) {
      if (current_call_session && !current_call_session?.completed) {
        return (
          <>
            <Wrapper>
              <Button
                onPress={() => {
                  if (!this.props.active_dialer_contact) {
                    this.props.getCallSession({
                      token: this.props.token,
                      type: "get_current_call_session",
                      onLoading: () => {},
                      onError: () => {},
                      onSuccess: results => {}
                    });
                  }

                  this.props.pushSidePanel({
                    slug: "call_session",
                    overlay: true,
                    data: {
                      call_session: current_call_session
                    }
                  });
                }}
                style={{
                  margin: 5,
                  height: this.props.device === "mobile" ? 50 : 40,
                  borderRadius: 30,
                  padding: 0,
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: colors.success_color_muted
                }}
              >
                <Row>
                  <Wrapper
                    style={
                      this.props.device === "mobile"
                        ? {
                            padding: 15,
                            paddingTop: 0,
                            paddingBottom: 0,
                            flex: 1
                          }
                        : {
                            padding: 15,
                            paddingTop: 0,
                            paddingBottom: 0
                          }
                    }
                  >
                    <Row>
                      <Icon
                        icon={"phone"}
                        style={{ marginRight: 15 }}
                        color={colors.actionable_text_color}
                        size={14}
                      />

                      <Wrapper>
                        <Copy style={{ color: colors.actionable_text_color }}>
                          <Bold>Dialer Session</Bold>
                        </Copy>
                        <Row>
                          <Copy
                            style={{
                              fontSize: 12,
                              marginRight: 5
                            }}
                          >
                            {this.state.call_time}
                          </Copy>
                          {
                            <Copy style={{ fontSize: 12 }}>
                              {numberWithCommas(
                                current_call_session?.completed_count
                              )}
                              /
                              {numberWithCommas(
                                current_call_session?.total_items
                              )}{" "}
                              Called
                            </Copy>
                          }
                        </Row>
                      </Wrapper>
                    </Row>
                  </Wrapper>

                  <ConfirmInlineButton
                    confirm_text="Really End?"
                    confirm_icon="stop"
                    icon="stop"
                    primary={false}
                    disabled={
                      this.props.active_call.call_status === "calling" ||
                      this.props.active_call.call_status === "connected" ||
                      this.props.active_call.call_status === "answered"
                    }
                    style={{ margin: 0 }}
                    loading_text="Ending..."
                    loading={this.state.stop_loading}
                    onPress={() => {
                      this.props.updateCallSession({
                        token: this.props.token,
                        type: "end_current_call_session",
                        call_session_id: current_call_session?.id,
                        onLoading: () => {
                          this.setState({ stop_loading: true });
                        },
                        onError: error => {
                          this.setState({ stop_loading: false });
                        },
                        onSuccess: results => {
                          this.setState({ stop_loading: false }, () => {
                            this.stopTimer();

                            this.props.setActiveDialerContact({
                              contact: null
                            });

                            if (results.call_session?.completed) {
                              this.props.replaceSidePanel({
                                slug: "call_session",
                                overlay: true,
                                data: {
                                  call_session: results.call_session
                                }
                              });
                            }
                          });
                        }
                      });
                    }}
                  >
                    End
                  </ConfirmInlineButton>
                </Row>
              </Button>
            </Wrapper>
          </>
        );
      } else if (
        this.props.follow_up_queue_count > 0 &&
        !this.state.call_session_loading &&
        !this.props.active_workspace_team
      ) {
        return (
          <>
            <Wrapper>
              <Button
                onPress={() => {
                  this.props.pushSidePanel({
                    slug: "follow_up_queue",
                    overlay: true,
                    data: {
                      filter_user: {
                        id: this.props.user?.id,
                        firstname: this.props.user?.firstname,
                        lastname: this.props.user?.lastname
                      }
                    }
                  });
                }}
                style={{
                  margin: 5,
                  height: this.props.device === "mobile" ? 50 : 40,
                  borderRadius: 30,
                  padding: 0,
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: colors.orange_color_muted
                }}
              >
                <Row>
                  <Wrapper
                    style={{ padding: 15, paddingTop: 0, paddingBottom: 0 }}
                  >
                    <Row>
                      <Icon
                        icon={"phone"}
                        style={{ marginRight: 5 }}
                        color={colors.actionable_text_color}
                        size={14}
                      />

                      <Wrapper>
                        <Copy style={{ color: colors.actionable_text_color }}>
                          <Bold>
                            {this.props.follow_up_queue_count == 1
                              ? "1 Follow Up"
                              : numberWithCommas(
                                  this.props.follow_up_queue_count
                                ) + " Follow Ups"}
                          </Bold>
                        </Copy>
                      </Wrapper>
                    </Row>
                  </Wrapper>
                </Row>
              </Button>
            </Wrapper>
          </>
        );
      }
    }

    return null;
  }
}

const mapStateToProps = ({ auth, native, settings, dialer, workspaces }) => {
  const { token, user, user_partner_info } = auth;
  const { device, isIphoneX, isMobile, platform, desktopMobile } = native;
  const { colors } = settings;
  const {
    current_call_session,
    follow_up_queue_count,
    active_call,
    follow_up_queue_refresh
  } = dialer;
  const { active_workspace_team } = workspaces;
  return {
    user,
    user_partner_info,
    token,
    device,
    isIphoneX,
    isMobile,
    platform,
    desktopMobile,
    colors,
    current_call_session,
    follow_up_queue_count,
    active_call,
    follow_up_queue_refresh,
    active_workspace_team
  };
};

export default connect(mapStateToProps, {
  getCallSession,
  updateCallSession,
  setActiveDialerContact,
  getDialerStats,
  pushSidePanel,
  replaceSidePanel,
  triggerFollowUpQueueRefresh
})(CurrentCallSession);
