import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Page,
  Spinner,
  Wrapper,
  Card,
  Copy,
  Bold,
  Row
} from "app/NativeComponents/common";
import {
  InputBox,
  GhostButton,
  InlineButton,
  OnboardingWrapper,
  OnboardingNavbar,
  SmallLabel,
  SelectBox
} from "app/NativeComponents/snippets";

import { Header1, Header3 } from "app/NativeComponents/common";

import {
  logout,
  startRedirect,
  pushSidePanel,
  updateUser,
  showSuccess,
  showErrorMessage,
  getMarketingOnboardingData,
  dismissMobileKeyboard
} from "app/NativeActions";
class MoreInfoStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: props.user?.firstname,
      lastname: props.user?.lastname,
      phone: props.user?.phone,
      address: props.user?.address,
      address2: props.user?.address2,
      city: props.user?.city,
      state: props.user?.state,
      zip: props.user?.zip,
      state_focus: false,
      loading_onboarding: false
    };

    this._firstname = React.createRef();
    this._lastname = React.createRef();
    this._phone_input = React.createRef();
    this._address_input = React.createRef();
    this._address2_input = React.createRef();
    this._city_input = React.createRef();
    this._state_input = React.createRef();
    this._zip_input = React.createRef();

    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
    this.continueToNextStep = this.continueToNextStep.bind(this);
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/onboarding/more-info");
    }
  }

  checkIfNeedsToSave() {
    if (
      !!this.state.firstname &&
      !!this.state.lastname &&
      !!this.state.phone &&
      !!this.state.address &&
      !!this.state.city &&
      !!this.state.state &&
      !!this.state.zip
    ) {
      return true;
    }
    return false;
  }

  continueToNextStep() {
    if (this.checkIfNeedsToSave()) {
      this.props.getMarketingOnboardingData({
        token: this.props.token,
        type: "workspace_more_info",
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        phone: this.state.phone,
        address: this.state.address,
        address2: this.state.address2,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip,
        onLoading: () => {
          this.setState({
            loading_onboarding: true
          });
        },
        onError: error => {
          this.setState({
            loading_onboarding: false
          });

          this.props.showErrorMessage(error, "Error");
        },
        onSuccess: ({ filters, title }) => {
          this.props.updateUser({
            token: this.props.token,
            type: "onboarding_stepthrough_step",
            payload: {
              onboarding_stepthrough_current_step: "credit_card_step"
            },
            onLoading: () => {},
            onError: () => {
              this.setState({
                loading_onboarding: false
              });
            },
            onSuccess: () => {
              this.setState({
                loading_onboarding: false
              });
            }
          });
        }
      });
    }
  }

  render() {
    const { colors, user } = this.props;
    if (this.props.init && this.props.user) {
    } else if (this.props.loading) {
      return <Spinner />;
    }

    let state_data = [];
    for (let i = 0; i < this.props.states.length; i++) {
      state_data.push({
        key: i,
        label: this.props.states[i].name,
        value: this.props.states[i].abbr
      });
    }

    return (
      <Page
        metaTitle={"👋 Welcome to DealMachine! - DealMachine for Real Estate"}
        responsive_design={true}
      >
        <OnboardingWrapper>
          <>
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: 25,
                maxWidth: 500,
                margin: this.props.device == "desktop" ? "0px auto" : 0
              }}
            >
              <Header1
                style={{
                  textAlign: "center"
                }}
              >
                <Bold>You're almost done!</Bold>
              </Header1>
              <Wrapper style={{ padding: 25, paddingBottom: 0 }}>
                <Header3
                  style={{
                    textAlign: "center"
                  }}
                >
                  Please add more information to access your account.
                </Header3>
              </Wrapper>

              <Card label="Basic Information" style={{ marginBottom: 25 }}>
                <Row>
                  <Wrapper style={{ flex: 1 }}>
                    <InputBox
                      input_ref={this._firstname}
                      name="firstname"
                      returnKeyType="next"
                      blurOnSubmit={false}
                      autoCapitalize="words"
                      keyboardType="default"
                      placeholder="First Name"
                      onChange={value => {
                        this.setState({
                          firstname: value
                        });
                      }}
                      value={this.state.firstname}
                      onSubmitEditing={() => {
                        if (this._lastname?.current) {
                          this._lastname.current.focus();
                        }
                      }}
                      type="text"
                    />
                  </Wrapper>

                  <Wrapper style={{ flex: 1 }}>
                    <InputBox
                      input_ref={this._lastname}
                      name="lastname"
                      returnKeyType="next"
                      blurOnSubmit={false}
                      autoCapitalize="words"
                      keyboardType="default"
                      placeholder="Last Name"
                      type="text"
                      onChange={value => {
                        this.setState({
                          lastname: value
                        });
                      }}
                      value={this.state.lastname}
                      onSubmitEditing={() => {
                        if (this.props.device == "desktop") {
                          this._phone_input.current.getInputDOMNode().focus();
                        } else {
                          this._phone_input.current.focus();
                        }
                      }}
                    />
                  </Wrapper>
                </Row>

                <InputBox
                  input_ref={this._phone_input}
                  name="phone"
                  autoCompleteType={"tel"}
                  mask_type={"cel-phone"}
                  returnKeyType="next"
                  blurOnSubmit={true}
                  autoCapitalize="none"
                  keyboardType="numeric"
                  placeholder="Phone Number"
                  onChange={value => {
                    this.setState({
                      phone: value
                    });
                  }}
                  onBlur={ev => {}}
                  value={this.state.phone ? this.state.phone : ""}
                  type="text"
                  mask={"(999) 999-9999"}
                  onSubmitEditing={() => {
                    if (this._address_input?.current) {
                      this._address_input.current.focus();
                    }
                  }}
                />
              </Card>

              <Card label="Mailing Address" style={{ marginBottom: 25 }}>
                <InputBox
                  input_ref={this._address_input}
                  name="address"
                  returnKeyType="next"
                  placeholder="Address"
                  onChange={value => {
                    this.setState({
                      address: value
                    });
                  }}
                  onSubmitEditing={() => {
                    this._address2_input.current.focus();
                  }}
                  blurOnSubmit={false}
                  value={this.state.address}
                  type="text"
                />

                <InputBox
                  input_ref={this._address2_input}
                  name="address2"
                  returnKeyType="next"
                  placeholder="Address Line 2"
                  onChange={value => {
                    this.setState({
                      address2: value
                    });
                  }}
                  onSubmitEditing={() => {
                    this._city_input.current.focus();
                  }}
                  blurOnSubmit={false}
                  value={this.state.address2}
                  type="text"
                />

                <InputBox
                  input_ref={this._city_input}
                  name="city"
                  returnKeyType="next"
                  autoCapitalize="words"
                  keyboardType="default"
                  placeholder="City"
                  onChange={value => {
                    this.setState({
                      city: value
                    });
                  }}
                  blurOnSubmit={true}
                  onSubmitEditing={() => {
                    this.setState({ state_focus: true });
                  }}
                  value={this.state.city}
                  type="text"
                />
                <SelectBox
                  select_ref={this._state_input}
                  items={state_data}
                  disabled={this.props.loading}
                  value={this.state.state}
                  placeholder="State"
                  show={this.state.state_focus}
                  onFocus={() => {
                    this.setState({ state_focus: true });
                  }}
                  onBlur={() => {
                    this.setState({ state_focus: false });
                  }}
                  onSelect={item => {
                    this.setState({
                      state: item
                    });
                  }}
                  onSubmitEditing={() => {
                    this._zip_input.current.focus();
                  }}
                />

                <InputBox
                  input_ref={this._zip_input}
                  name="zip"
                  returnKeyType="done"
                  keyboardType="numeric"
                  placeholder="Zip Code"
                  onChange={value => {
                    this.setState({
                      zip: value
                    });
                  }}
                  onSubmitEditing={() => {
                    this.continueToNextStep();
                  }}
                  blurOnSubmit={false}
                  value={this.state.zip}
                  type="number"
                  maxLength={"5"}
                />
              </Card>
              <Wrapper style={{ alignSelf: "stretch" }}>
                <GhostButton
                  loading={this.state.loading_onboarding}
                  button_type="full"
                  disabled={!this.checkIfNeedsToSave()}
                  onPress={() => {
                    this.continueToNextStep();
                  }}
                  primary={true}
                  icon_right="arrow-forward"
                >
                  Continue
                </GhostButton>
              </Wrapper>
            </Wrapper>

            <Wrapper
              style={{
                maxWidth: 500,
                width: "100%",
                margin: this.props.device == "desktop" ? "5px auto" : 0
              }}
            ></Wrapper>
          </>
        </OnboardingWrapper>
      </Page>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user, init, loading } = auth;

  const { colors, states } = settings;
  const { isMobile, device, initialRedirect } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect,
    states
  };
};

export default connect(mapStateToProps, {
  logout,
  startRedirect,
  pushSidePanel,
  updateUser,
  showSuccess,
  showErrorMessage,
  getMarketingOnboardingData
})(MoreInfoStep);
