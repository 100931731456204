import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { popSidePanel } from "app/NativeActions";
import PayloadBody from "app/DealMachineCore/MarketingOnboardingAndFunnel/components/PreTrialOnboarding/SelectTrialOnboarding/PayloadBody";
import {
  loadOnboardingText,
  loadSpecialPlanSelect,
  getCorrectSpecial,
  showSuccess,
  checkIfUserHasMetadata,
  openUrl,
  renderPrice
} from "app/NativeActions";

import { Wrapper, Container } from "app/NativeComponents/common";
import { NewHeader } from "app/NativeComponents/snippets";
const SwitchPlan = ({ ...props }) => {
  const [planInfo, setPlanInfo] = useState({});
  const handleBack = () => {
    props.popSidePanel();
  };

  const loadPlanData = () => {
    loadOnboardingText("select_a_plan").then(data => {
      if (data && data.items && data.items.length > 0) {
        setPlanInfo(data.items[0]?.fields);
      }
    });
  };
  return (
    <Container>
      <NewHeader
        title={"Plans & Licenses"}
        leftButton={{
          icon: "arrow-back",
          onPress: () => {
            handleBack();
          }
        }}
      />

      <PayloadBody
        {...props}
        token={props.token}
        colors={props.colors}
        platform={props.platform}
        device={props.device}
        signup_funnel={props.signup_funnel}
        qualified_lead={props.qualified_lead}
        needs_lists={props.needs_lists}
        needs_list={props.needs_list}
        desktopMobile={props.desktopMobile}
        startRedirect={props.startRedirect}
        visitor={props.visitor}
        user={props.user}
        switchPlan={true}
        title={"Switch Plans"}
        manageSubscription={props.manageSubscription}
        prompt={true}
        event_slug={
          props.visitor?.marketing_experiments?.dealmachine_free ===
          "dealmachine_free"
            ? "onboarding_select_plan"
            : ""
        }
      />
    </Container>
  );
};

const mapStateToProps = ({ auth, settings, native, marketing }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { device, platform, initialRedirect, desktopMobile } = native;
  const { visitor, signup_funnel, user_info } = marketing;

  return {
    token,
    user,
    init,
    loading,
    colors,
    device,
    platform,
    visitor,
    initialRedirect,
    signup_funnel,
    desktopMobile,
    user_info
  };
};

export default connect(mapStateToProps, { popSidePanel })(SwitchPlan);
