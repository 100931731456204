import React, { PureComponent } from "react";

import { connect } from "react-redux";
import {
  initApp,
  logout,
  loginUser,
  getUser,
  toggleUserRefresh,
  getSourceOfTruth,
  startRedirect
} from "app/NativeActions";

const queryString = require("query-string");

class TeamInvite extends PureComponent {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    const parsed = queryString.parse(this.props.location?.search, {
      parseNumbers: true,
      arrayFormat: "bracket",
      decode: true
    });

    if (!!parsed?.invite_token) {
      this.props.toggleUserRefresh(true);
      this.props.initApp(false);
      this.props.loginUser({
        device: this.props.device,
        branch_recovery_token: parsed.invite_token,
        no_error_message: true,
        onLoading: () => {
          this.props.toggleUserRefresh(true);
        },
        onError: () => {
          this.props.toggleUserRefresh(false);
        },
        onSuccess: () => {
          this.props.toggleUserRefresh(false);
        }
      });
    } else {
      this.props.startRedirect("/");
    }
  }

  render() {
    return null;
  }
}
const mapStateToProps = ({ auth, native }) => {
  const { user } = auth;

  const { device, platform } = native;

  return {
    user,
    device,
    platform
  };
};

export default connect(mapStateToProps, {
  initApp,
  logout,
  loginUser,
  getUser,
  toggleUserRefresh,
  getSourceOfTruth,
  startRedirect
})(TeamInvite);
