import {
  LOGOUT,
  GET_WORKSPACE_TEAMS,
  SET_ACTIVE_WORKSPACE_TEAM,
  LOGIN_USER_SUCCESS,
  GET_WORKSPACE_SETTINGS,
  UPDATE_WORKSPACE_TEAM
} from "app/DealMachineCore/types";

const INITIAL_STATE = {
  main_workspace_user: null,
  workspace_teams: [],
  active_workspace_team: null,
  workspace_settings: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE
      };

    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        main_workspace_user:
          action.payload?.user?.team_type === "workspace"
            ? action.payload?.user
            : state.main_workspace_user
      };

    case GET_WORKSPACE_TEAMS:
      return {
        ...state,
        workspace_teams: action.payload
      };
    case UPDATE_WORKSPACE_TEAM:
      return {
        ...state,
        workspace_teams: state.workspace_teams.map(team =>
          team.id === action.payload.id ? { ...team, ...action.payload } : team
        )
      };

    case SET_ACTIVE_WORKSPACE_TEAM:
      return {
        ...state,
        active_workspace_team: action.payload
      };

    case GET_WORKSPACE_SETTINGS:
      return {
        ...state,
        workspace_settings: action.payload
      };

    default:
      return state;
  }
};
