import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, RangeSlider, Row } from "app/NativeComponents/common";
import {
  IconButton,
  PopoverMenu,
  AttentionBox,
  SmallLabel,
  InlineButton
} from "app/NativeComponents/snippets";

class AISettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_settings: false
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    clearInterval(this._search_interval);
  }

  render() {
    const { isMobile, colors, source_of_truth } = this.props;

    return (
      <>
        <PopoverMenu
          show={this.state.show_settings}
          no_swipe={true}
          no_cancel={true}
          onShow={s => {
            this.setState({
              show_settings: s,
              property_search: ""
            });
          }}
          includeCloseButton={true}
          popover_title={"AI Vision Builder Settings"}
          popover_width={450}
          popoverSheetTop={"75%"}
          disabled={this.props.disabled}
          renderComponent={({ hovering }) => {
            return (
              <IconButton
                noPress={true}
                pressedIn={this.state.show_settings || hovering}
                icon={"settings"}
                tooltip="AI Vision Builder Settings"
                tooltipPlacement={"top"}
                style={{ marginLeft: 0 }}
              />
            );
          }}
          renderMenu={() => {
            return (
              <>
                <Wrapper
                  style={{
                    alignSelf: "stretch"
                  }}
                >
                  <AttentionBox
                    title="Update the required matching score"
                    description="Use the slider to set the minimum score a property must meet to be added to your list. Properties are scored based on criteria you set in your prompt. The higher the score, the more strict the filter. Higher scores will result in fewer properties being added to your list."
                  />
                  <SmallLabel>
                    Required matching score: ({this.props.variance})
                  </SmallLabel>
                  <Wrapper style={{ paddingRight: 25, paddingLeft: 25 }}>
                    <RangeSlider
                      min={50}
                      max={90}
                      value={this.props.variance}
                      step={5}
                      onChange={value => {
                        this.props.setAISettings("variance", value);
                      }}
                    />
                  </Wrapper>
                </Wrapper>
              </>
            );
          }}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { device, isMobile } = native;

  return {
    token,
    user,
    colors,
    device,
    isMobile
  };
};

export default connect(mapStateToProps, {})(AISettings);
