import {
  SELECT_ACTIVE_PROPERTY_FAIL,
  SELECT_ACTIVE_PROPERTY_SUCCESS,
  SELECT_ACTIVE_PROPERTY_NO_SIDE_EFFECTS,
  ADD_DEAL,
  ADD_DEAL_FAIL,
  ADD_DEAL_SUCCESS,
  CLEAR_AUTOCOMPLETE,
  CLEAR_NEXT_PLANNED_PROPERTY,
  SET_COMPLETED_ROUTE,
  DELETE_DEAL,
  FOCUS_SEARCH_BAR,
  GENERATE_PREVIEW_IMAGES,
  GENERATE_PREVIEW_IMAGES_SUCCESS,
  GET_AUTOCOMPLETE,
  GET_AUTOCOMPLETE_FAIL,
  GET_AUTOCOMPLETE_SUCCESS,
  GET_MAP_PROPERTIES,
  GET_MAP_PROPERTIES_FAIL,
  GET_MAP_PROPERTIES_SUCCESS,
  GET_MAP_REVERSE_GEOCODE,
  GET_MAP_REVERSE_GEOCODE_FAIL,
  GET_MAP_REVERSE_GEOCODE_SUCCESS,
  GET_NEXT_PLANNED_PROPERTY,
  GET_NEXT_PLANNED_PROPERTY_FAIL,
  GET_NEXT_PLANNED_PROPERTY_SUCCESS,
  COMPLETE_PLANNED_PROPERTY,
  COMPLETE_PLANNED_PROPERTY_FAIL,
  COMPLETE_PLANNED_PROPERTY_SUCCESS,
  GET_PROPERTY,
  GET_PROPERTY_FAIL,
  GET_PROPERTY_SUCCESS,
  GET_PROPERTY_WITH_ROUTE_INFO_SUCCESS,
  GOOGLE_STREET_VIEW_SUCCESS,
  HIDE_CTA,
  HIDE_PROPERTY_LIST,
  LOGOUT,
  REFRESH_MAP_PROPERTIES,
  SELECT_ACTIVE_PROPERTY,
  SELECT_MULTIPLE_PROPERTIES,
  SELECT_EDIT_PHOTO,
  SET_ACTIVITY_PROPERTIES_TYPE,
  SET_LAST_LOCATION,
  SET_PERMISSION,
  SET_USER_LOCATION,
  SOFT_REFRESH_PROPERTY,
  SWITCH_MAP_MODE,
  TOGGLE_TAP_TO_ADD,
  TRIGGER_LEADS_RELOAD,
  READ_TEXT_MESSAGE,
  UPDATE_HOUSE_SUCCESS,
  UPDATE_LEAD_SUCCESS,
  UPDATE_MAP_LOCATION,
  UPDATE_OWNER_INFO_SUCCESS,
  UPLOAD_EDIT_PHOTO_SUCCESS,
  UPLOAD_EDIT_PROGRESS,
  RELOAD_LEADS_SUCCESS,
  RELOAD_LEADS,
  UPDATE_HOUSE_IMAGE,
  UPDATE_HOUSE_ADDITIONAL_IMAGES,
  SET_HOUSE_ADDITIONAL_IMAGE_LOADING,
  UPDATE_HOUSE_MARKETING_IMAGE,
  DELETE_HOUSE_IMAGE,
  GET_OWNER_INFO,
  GET_OWNER_INFO_FAIL,
  GET_OWNER_INFO_SUCCESS,
  TOGGLE_ACTIVE_PROPERTY,
  GET_PROPERTY_IMAGES,
  GET_PROPERTY_IMAGES_FAIL,
  GET_PROPERTY_IMAGES_SUCCESS,
  UPDATE_LEAD_PHOTO_COMPLETE,
  UPDATE_PROPERTY_IMAGE_SUCCESS,
  REMOVE_LEADS_SUCCESS,
  SET_APPLIED_HIGHLIGHT,
  GET_HIGHLIGHT_TEMPLATES_SUCCESS,
  SET_STREET_VIEW_COORDS,
  SET_STREET_VIEW_LINE,
  TOGGLE_MAP_MOVING,
  UPDATE_MAIN_MAP_LOCATION,
  SET_ALL_PERMISSIONS,
  SELECT_ACTIVE_COORDINATES,
  SELECT_ACTIVE_ROUTE,
  LOGIN_USER_SUCCESS,
  SAVE_CARD_SUCCESS,
  TOGGLE_DRIVING_MENU,
  CHANGE_TAB,
  TRIGGER_NEXT_PLANNED_PROPERTY,
  CREATE_SAVED_FILTER_SUCCESS,
  UPDATE_SAVED_FILTER_SUCCESS,
  REMOVE_SAVED_FILTER_SUCCESS,
  UPDATE_MAP_FILTERS
} from "app/DealMachineCore/types";

import { combinePropertyArrays } from "app/NativeActions";
import store from "app/store";

const checkDrivingBillingAddon = billing => {
  const source_of_truth = store.getState().billing?.source_of_truth;
  if (source_of_truth) {
    const subscription = source_of_truth.subscription;
    const metadata = subscription?.metadata;
    if (metadata?.route_tracking == true) {
      return true;
    }
  } else {
    if (billing?.plan_modules) {
      const plan_modules = billing.plan_modules;
      for (var i = 0; i < plan_modules.length; i++) {
        if (plan_modules[i].module_type == "driving") {
          if (plan_modules[i].tier > 1) {
            return true;
          }
        }
      }
    }
  }

  return false;
};

const updateProperty = (originalProperty, newProperties) => {
  for (var i = 0; i < newProperties.length; i++) {
    if (originalProperty?.property_id == newProperties[i]?.property_id) {
      return {
        ...newProperties[i],
        highlighted: originalProperty.highlighted,
        deal: {
          ...newProperties[i].deal
        }
      };
    }
  }
  return originalProperty;
};

const updateOrAddToWorkflowHistory = (
  current_workflow_events = [],
  new_workflow_event
) => {
  let workflow_events = current_workflow_events;
  let found_event = false;
  for (let i = 0; i < current_workflow_events.length; i++) {
    if (current_workflow_events[i].id == new_workflow_event.id) {
      current_workflow_events[i] = new_workflow_event;
      found_event = true;
    }
  }
  if (!found_event) {
    workflow_events = [new_workflow_event, ...workflow_events];
  }

  return workflow_events;
};

const default_lat_and_lngs = [
  {
    lat: 39.97411,
    lng: -86.11756,
    zoom: 17
  },
  {
    lat: 38.580421,
    lng: -90.288353,
    zoom: 16
  },
  { lat: 30.0453405, lng: -95.5080381, zoom: 17 },
  { lat: 38.6260441, lng: -77.0248935, zoom: 17 },
  { lat: 47.1317291, lng: -122.8843564, zoom: 17 },
  { lat: 30.2938796, lng: -89.8416527, zoom: 17 },
  { lat: 37.524543, lng: -77.5039472, zoom: 17 },
  { lat: 32.8239007, lng: -96.7865394, zoom: 17 },
  { lat: 29.9481017, lng: -89.9848977, zoom: 17 },
  { lat: 28.5179702, lng: -82.5229595, zoom: 17 },
  { lat: 33.6500286, lng: -117.5966549, zoom: 17 },
  { lat: 39.9721263, lng: -74.840821, zoom: 17 },
  { lat: 35.6586083, lng: -88.4728394, zoom: 17 },
  { lat: 36.1496397, lng: -80.2806299, zoom: 17 },
  { lat: 41.6506061, lng: -86.2257278, zoom: 17 },
  { lat: 26.2018612, lng: -98.0111316, zoom: 17 },
  { lat: 37.5915547, lng: -77.361581, zoom: 17 },
  { lat: 39.9729062, lng: -85.9970273, zoom: 17 },
  { lat: 41.1612226, lng: -104.7786443, zoom: 17 },
  { lat: 35.8149468, lng: -84.0420807, zoom: 17 },
  { lat: 29.8421868, lng: -95.4998125, zoom: 17 },
  { lat: 40.7657446, lng: -72.8656481, zoom: 17 },
  { lat: 42.271356, lng: -83.383898, zoom: 17 },
  { lat: 32.9638851, lng: -80.060919, zoom: 17 },
  { lat: 42.8325106, lng: -83.5627928, zoom: 17 },
  { lat: 39.8177005, lng: -86.3533194, zoom: 17 },
  { lat: 39.9729062, lng: -85.9970273, zoom: 17 },
  { lat: 39.9151434, lng: -86.1015884, zoom: 17 },
  { lat: 27.8325155, lng: -82.6381132, zoom: 17 },
  { lat: 39.9151434, lng: -86.1015884, zoom: 17 },
  { lat: 39.8177005, lng: -86.3533194, zoom: 17 },
  { lat: 39.8645247, lng: -86.1360704, zoom: 17 },
  { lat: 27.9733597, lng: -82.3716114, zoom: 17 },
  { lat: 39.8645247, lng: -86.1360704, zoom: 17 },
  { lat: 39.8645247, lng: -86.1360704, zoom: 17 },
  { lat: 41.7978382, lng: -87.6966107, zoom: 17 },
  { lat: 38.8831372, lng: -76.9902276, zoom: 17 },
  { lat: 42.7529043, lng: -73.6464924, zoom: 17 },
  { lat: 42.7529043, lng: -73.6464924, zoom: 17 },
  { lat: 26.1332338, lng: -80.2596812, zoom: 17 },
  { lat: 39.3013709, lng: -76.6418796, zoom: 17 },
  { lat: 41.7367849, lng: -73.568536, zoom: 17 },
  { lat: 35.0677702, lng: -106.6176408, zoom: 17 },
  { lat: 38.8471282, lng: -76.9701794, zoom: 17 },
  { lat: 35.8361853, lng: -78.5503582, zoom: 17 },
  { lat: 32.554113, lng: -97.0623856, zoom: 17 },
  { lat: 25.8350172, lng: -80.2383961, zoom: 17 },
  { lat: 27.081539, lng: -82.2099397, zoom: 17 },
  { lat: 37.632344, lng: -97.3204985, zoom: 17 },
  { lat: 39.2682668, lng: -94.5695959, zoom: 17 },
  { lat: 31.9069463, lng: -95.8484484, zoom: 17 },
  { lat: 29.4094607, lng: -95.0332007, zoom: 17 },
  { lat: 40.8957339, lng: -72.8139511, zoom: 17 },
  { lat: 30.2302791, lng: -81.602714, zoom: 17 },
  { lat: 28.0014709, lng: -82.730099, zoom: 17 },
  { lat: 32.8647769, lng: -105.9528442, zoom: 17 },
  { lat: 25.8596543, lng: -80.2358563, zoom: 17 },
  { lat: 33.8137352, lng: -83.8799975, zoom: 17 },
  { lat: 37.4231459, lng: -77.5884705, zoom: 17 },
  { lat: 32.8729728, lng: -83.757614, zoom: 17 },
  { lat: 38.6627745, lng: -90.3177175, zoom: 17 },
  { lat: 39.2855183, lng: -76.6919244, zoom: 17 },
  { lat: 38.6627745, lng: -90.3177175, zoom: 17 },
  { lat: 34.5830902, lng: -112.3597387, zoom: 17 },
  { lat: 42.4998841, lng: -89.0311706, zoom: 17 },
  { lat: 26.5095425, lng: -80.1249819, zoom: 17 },
  { lat: 39.8177005, lng: -86.3533194, zoom: 17 },
  { lat: 34.6716894, lng: -83.0977212, zoom: 17 },
  { lat: 39.3674388, lng: -74.5583886, zoom: 17 },
  { lat: 29.8421868, lng: -95.4998125, zoom: 17 },
  { lat: 44.9686992, lng: -93.298367, zoom: 17 },
  { lat: 29.780163, lng: -95.3813565, zoom: 17 },
  { lat: 34.6106288, lng: -98.4625773, zoom: 17 },
  { lat: 44.328834, lng: -88.1528111, zoom: 17 },
  { lat: 35.9265502, lng: -86.8758139, zoom: 17 },
  { lat: 42.8396823, lng: -78.747005, zoom: 17 },
  { lat: 39.8645247, lng: -86.1360704, zoom: 17 },
  { lat: 37.834398, lng: -122.2807668, zoom: 17 },
  { lat: 39.7669707, lng: -84.1398653, zoom: 17 },
  { lat: 35.0615023, lng: -91.9070807, zoom: 17 },
  { lat: 39.7995298, lng: -82.7151, zoom: 17 },
  { lat: 31.481324, lng: -110.2792657, zoom: 17 },
  { lat: 34.6106288, lng: -98.4625773, zoom: 17 },
  { lat: 26.1324791, lng: -80.1562683, zoom: 17 },
  { lat: 29.5488757, lng: -95.4233705, zoom: 17 },
  { lat: 33.9260982, lng: -84.4839438, zoom: 17 },
  { lat: 34.1128054, lng: -117.5010807, zoom: 17 },
  { lat: 42.9173718, lng: -85.4879261, zoom: 17 },
  { lat: 43.6265566, lng: -116.6334587, zoom: 17 },
  { lat: 39.6870007, lng: -104.8546057, zoom: 17 },
  { lat: 31.8403674, lng: -81.4050707, zoom: 17 },
  { lat: 41.5081618, lng: -81.5517796, zoom: 17 },
  { lat: 29.5599294, lng: -95.1430894, zoom: 17 },
  { lat: 39.3735927, lng: -76.548627, zoom: 17 },
  { lat: 43.1441472, lng: -93.3970505, zoom: 17 },
  { lat: 35.4497479, lng: -79.1089181, zoom: 17 },
  { lat: 33.8876346, lng: -84.6905051, zoom: 17 },
  { lat: 33.7972391, lng: -84.0273168, zoom: 17 },
  { lat: 40.7053557, lng: -73.3427379, zoom: 17 },
  { lat: 14.5884464, lng: 121.0605897, zoom: 17 },
  { lat: 37.3063139, lng: -77.3090411, zoom: 17 },
  { lat: 39.8177005, lng: -86.3533194, zoom: 17 }
];
const random_option =
  default_lat_and_lngs[Math.floor(Math.random() * default_lat_and_lngs.length)];
const default_lat = random_option.lat;
const default_lng = random_option.lng;
const default_zoom = random_option.zoom;

const INITIAL_STATE = {
  map_properties_loading: false,
  map_properties_error: "",
  map_properties: [],
  next_planned_property_loading: false,
  trigger_next_planned_property_id: null,
  route_plan_completed: false,
  next_planned_property_error: "",
  next_planned_property: null,
  complete_planned_property_loading: false,
  complete_planned_property_error: "",
  property_count: 0,
  update_map_location: null,
  last_location: null,
  user_location: {
    longitude: default_lng,
    latitude: default_lat,
    heading: 0
  },
  devicePermissions: {
    is_background_set: false,
    is_location_on: false,
    is_location_blocked: false,
    is_background_blocked: false,
    is_microphone_on: false,
    is_microphone_blocked: false,
    checked_locations: false,
    asked_for_notification: false,
    enabled_notifications: false
  },
  is_search_bar_focused: false,

  active_property: null,
  active_property_loading: false,
  show_active_property: false,
  selected_active_properties: null,

  active_address: null,

  reverse_geocode_loading: false,
  reverse_geocode_address: null,

  add_deal_loading: [],
  add_deal_error: "",

  autocomplete_loading: false,
  autocomplete_error: "",
  autocomplete_items: [],

  tap_to_add: false,
  map_mode: "pin",

  all_property_info_options: [
    {
      label: "Sale Price",
      value: "saleprice",
      type: "price"
    },
    {
      label: "Sale Date",
      value: "saledate",
      type: "date"
    },
    {
      label: "Mortgage Amount",
      value: "mortgage_amount",
      type: "price"
    },
    {
      label: "Financing Type",
      value: "mortgage_financing_type",
      type: "text"
    },
    {
      label: "Loan Type",
      value: "mortgage_loan_type",
      type: "text"
    },
    {
      label: "Interest Rate",
      value: "mortgage_interest_rate",
      type: "interest_rate"
    },
    {
      label: "Lender Name",
      value: "mortgage_amount",
      type: "text"
    },
    {
      label: "Mortgage Date",
      value: "mortgage_date",
      type: "date"
    },
    {
      label: "Mortgage Term",
      value: "mortgage_term",
      type: "term"
    },
    {
      label: "Mortgage Due Date",
      value: "mortgage_due_date",
      type: "date"
    },
    {
      label: "2nd Mortgage Amount",
      value: "second_mortgage_amount",
      type: "price"
    },
    {
      label: "2nd Financing Type",
      value: "second_mortgage_financing_type",
      type: "text"
    },
    {
      label: "2nd Loan Type",
      value: "second_mortgage_loan_type",
      type: "text"
    },
    {
      label: "2nd Interest Rate",
      value: "second_mortgage_interest_rate",
      type: "interest_rate"
    },
    {
      label: "Assessed Value",
      value: "calculated_total_value",
      type: "price"
    },
    {
      label: "Improvement Value",
      value: "calculated_improvement_value",
      type: "price"
    },
    {
      label: "Land Value",
      value: "calculated_land_value",
      type: "price"
    },
    {
      label: "Equity %",
      value: "calculated_equity_percent",
      type: "percent"
    },
    {
      label: "Year Built",
      value: "year_built",
      type: "number"
    },
    {
      label: "Square Feet",
      value: "building_square_feet",
      type: "feet"
    },
    {
      label: "Lot Acreage",
      value: "lot_acreage",
      type: "acres"
    },
    {
      label: "Bathrooms",
      value: "total_baths",
      type: "number"
    },
    {
      label: "Bedrooms",
      value: "total_baths",
      type: "number"
    },
    {
      label: "School District",
      value: "school_district",
      type: "text"
    }
  ],
  selected_property_info: [
    "saleprice",
    "saledate",
    "calculated_total_value",
    "calculated_equity_percent"
  ],
  hide_property_list: false,
  owner_loading: false,

  property_images_loading: false,
  applied_highlight: null,
  map_filters: {
    page: 0,
    sort_by: "saledate_desc",
    property_flags: [],
    property_flags_and_or: "or",
    property_types: [],
    value_range_min: "",
    value_range_max: "",
    price_type: "estimated_value",
    beds_min: null,
    baths_min: null,
    use_beds_exact: false,
    search_locations: [],
    drawing_created: false,
    drawing: false,
    drawing_coordinates: null,
    include_owners: true,
    include_residents: false,
    list_view_type: "properties"
  },

  highlight_templates: [],
  saved_highlights: [],
  street_view_coords: {
    longitude: 0,
    latitude: 0,
    heading: 0,
    pitch: 0
  },
  street_view_line: null,
  mapIsMoving: false,
  main_map_location: {
    latitude: default_lat,
    longitude: default_lng,
    zoom: default_zoom,
    pitch: 0,
    heading: 0,
    show_routes_on_map: true,
    show_mls_on_map: true,
    property_label_type: "property_address_range",
    map_type: "standard"
  },
  active_coordinates: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE
      };

    case GET_MAP_PROPERTIES:
      return {
        ...state,
        map_properties_error: "",
        map_properties_loading: true
      };

    case REFRESH_MAP_PROPERTIES:
      return {
        ...state,
        map_properties_error: "",
        map_properties_loading: true,
        map_properties: []
      };

    case GET_MAP_PROPERTIES_FAIL:
      return {
        ...state,
        map_properties_error: action.payload,
        map_properties_loading: false
      };

    case GET_MAP_PROPERTIES_SUCCESS:
      return {
        ...state,
        map_properties_loading: false,
        map_properties: action.payload.properties
          ? action.payload.properties
          : [],
        property_count: action.payload.property_count
      };
    case SET_COMPLETED_ROUTE:
      return {
        ...state,
        route_plan_completed: action.payload
      };
    case TRIGGER_NEXT_PLANNED_PROPERTY:
      return {
        ...state,
        next_planned_property_loading: true,
        trigger_next_planned_property_id: action.payload
      };

    case CLEAR_NEXT_PLANNED_PROPERTY:
      return {
        ...state,
        next_planned_property: null,
        next_planned_property_error: "",
        next_planned_property_loading: false,
        trigger_next_planned_property_id: null
      };
    case GET_NEXT_PLANNED_PROPERTY:
      return {
        ...state,
        next_planned_property_error: "",
        next_planned_property_loading: true,
        trigger_next_planned_property_id: null
      };
    case GET_NEXT_PLANNED_PROPERTY_FAIL:
      return {
        ...state,
        next_planned_property: null,
        route_plan_completed: true,
        next_planned_property_error: action.payload,
        next_planned_property_loading: false,
        trigger_next_planned_property_id: null
      };

    case GET_NEXT_PLANNED_PROPERTY_SUCCESS:
      return {
        ...state,
        route_plan_completed:
          action.payload?.properties?.length == 0 ? true : false,
        next_planned_property_loading: false,
        next_planned_property: action.payload.next_property,
        trigger_next_planned_property_id: null
      };

    case COMPLETE_PLANNED_PROPERTY:
      return {
        ...state,
        complete_planned_property_error: "",
        complete_planned_property_loading: true
      };
    case COMPLETE_PLANNED_PROPERTY_FAIL:
      return {
        ...state,
        complete_planned_property_error: action.payload,
        complete_planned_property_loading: false
      };

    case COMPLETE_PLANNED_PROPERTY_SUCCESS:
      return {
        ...state,
        complete_planned_property_loading: false
      };
    case UPDATE_MAP_LOCATION:
      return {
        ...state,
        update_map_location: action.payload.coordinates
          ? {
              ...action.payload.coordinates,
              refresh: action.payload.refresh
            }
          : state.update_map_location,

        main_map_location: action.payload.coordinates
          ? {
              ...state.main_map_location,
              latitude: action.payload.coordinates?.latitude,
              longitude: action.payload.coordinates?.longitude,
              zoom: action.payload?.zoom
                ? action.payload.zoom
                : state.main_map_location?.zoom,
              heading: action.payload?.heading
                ? action.payload.heading
                : state.main_map_location?.heading
            }
          : state.main_map_location,
        active_property: action.payload.active_property
          ? action.payload.active_property
          : state.active_property
      };
    case SET_LAST_LOCATION:
      return {
        ...state,
        last_location: action.payload
      };

    case SET_USER_LOCATION:
      return {
        ...state,
        user_location: {
          ...state.user_location,
          latitude:
            action.payload?.latitude && !isNaN(action.payload?.latitude)
              ? action.payload.latitude
              : state.user_location.latitude,
          longitude:
            action.payload?.longitude && !isNaN(action.payload?.longitude)
              ? action.payload.longitude
              : state.user_location.longitude,
          heading:
            action.payload?.heading && !isNaN(action.payload?.heading)
              ? action.payload.heading
              : state.user_location.heading,
          ...action.payload
        }
      };
    case SET_ALL_PERMISSIONS:
      return {
        ...state,
        devicePermissions: action.payload
      };
    case SET_PERMISSION:
      return {
        ...state,
        devicePermissions: {
          ...state.devicePermissions,
          [action.payload.prop]: action.payload.value
        }
      };
    case FOCUS_SEARCH_BAR:
      return {
        ...state,
        is_search_bar_focused: action.payload
      };
    case TOGGLE_ACTIVE_PROPERTY:
      return {
        ...state,
        show_active_property: action.payload
      };
    case SELECT_ACTIVE_PROPERTY_NO_SIDE_EFFECTS:
      return {
        ...state,
        active_property: action.payload
      };

    case CHANGE_TAB:
      return {
        ...state,
        active_coordinates: null,
        active_property: null,
        selected_active_properties: null
      };
    case SELECT_ACTIVE_PROPERTY:
      return {
        ...state,
        active_coordinates: null,
        active_property: action.payload,
        active_property_loading: true,
        active_address: null,
        reverse_geocode_address: null,
        selected_active_properties: null
      };

    case SELECT_MULTIPLE_PROPERTIES:
      return {
        ...state,
        active_coordinates: null,
        active_property: null,
        active_property_loading: true,
        active_address: null,
        reverse_geocode_address: null,
        selected_active_properties: action.payload
      };

    case TOGGLE_DRIVING_MENU:
    case SELECT_ACTIVE_ROUTE:
      return {
        ...state,
        active_coordinates: null,
        active_property: null,
        active_property_loading: false,
        active_address: null,
        reverse_geocode_address: null,
        selected_active_properties: null
      };
      break;
    case SELECT_ACTIVE_COORDINATES:
      return {
        ...state,
        active_coordinates: action.payload,
        active_property: null,
        active_property_loading: false,
        active_address: null,
        reverse_geocode_address: null,
        selected_active_properties: null
      };
    case SELECT_ACTIVE_PROPERTY_SUCCESS:
      return {
        ...state,
        active_property:
          (state.active_property &&
            action.payload?.property_id ==
              state.active_property?.property_id) ||
          action.payload?.deal?.id == state.active_property?.lead_id
            ? action.payload
            : state.active_property,
        active_property_loading: false,
        active_address: null,
        reverse_geocode_address: null,
        selected_active_properties: null
      };
    case SELECT_ACTIVE_PROPERTY_FAIL:
      return {
        ...state,
        active_property: null,
        active_property_loading: false,
        active_address: null,
        reverse_geocode_address: null,
        selected_active_properties: null
      };

    case GET_MAP_REVERSE_GEOCODE:
      return {
        ...state,
        active_property: null,
        active_address: null,
        reverse_geocode_loading: true,
        reverse_geocode_address: null,
        selected_active_properties: null
      };
    case GET_MAP_REVERSE_GEOCODE_FAIL:
      return {
        ...state,
        reverse_geocode_loading: false
      };
    case GET_MAP_REVERSE_GEOCODE_SUCCESS:
      return {
        ...state,
        reverse_geocode_loading: false,
        map_properties: action.payload.properties
          ? action.payload.properties.length > 0
            ? combinePropertyArrays(
                state.map_properties,
                action.payload.properties,
                0
              )
            : state.map_properties
          : state.map_properties,
        active_property: action.payload.properties
          ? action.payload.properties.length > 0
            ? action.payload.properties[0]
            : null
          : null,
        reverse_geocode_address: action.payload.properties
          ? action.payload.properties.length == 0
            ? action.payload.parsed_address
            : null
          : null,
        active_address: action.payload.parsed_address
      };

    case GET_AUTOCOMPLETE:
      return {
        ...state,
        autocomplete_items: [],
        autocomplete_loading: true,
        autocomplete_error: ""
      };
    case CLEAR_AUTOCOMPLETE:
      return {
        ...state,
        autocomplete_items: [],
        autocomplete_error: ""
      };
    case GET_AUTOCOMPLETE_FAIL:
      return {
        ...state,
        autocomplete_loading: false,
        autocomplete_error: action.payload
      };
    case GET_AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        autocomplete_items: action.payload.properties,
        autocomplete_loading: false
      };

    case ADD_DEAL:
      return {
        ...state,
        add_deal_loading: state.add_deal_loading.concat([
          action.payload.property
        ]),
        add_deal_error: ""
      };
    case ADD_DEAL_FAIL:
      return {
        ...state,
        add_deal_loading: action.payload.property
          ? state.add_deal_loading.filter(
              property =>
                property?.property_id != action.payload.property.property_id ||
                property != action.payload.property
            )
          : state.add_deal_loading,
        error: action.payload.error
      };
    case ADD_DEAL_SUCCESS:
      return {
        ...state,
        add_deal_loading: state.add_deal_loading.filter(
          property =>
            property?.property_id != action.payload.property.property_id ||
            property != action.payload.property
        ),
        error: "",
        map_properties: combinePropertyArrays(
          state.map_properties,
          [action.payload.added_property],
          0
        ),
        update_map_location:
          action.payload.add_type === "manual"
            ? {
                ...state.update_map_location,
                latitude: parseFloat(
                  action.payload.added_property?.location?.latitude
                ),
                longitude: parseFloat(
                  action.payload.added_property?.location?.longitude
                )
              }
            : state.update_map_location,

        active_property:
          action.payload.add_type !== "no_active"
            ? action.payload.add_type === "manual"
              ? action.payload.added_property
              : state.active_property
              ? state.active_property?.property_id ==
                action.payload.added_property?.property_id
                ? {
                    ...action.payload.added_property,
                    highlighted: state.active_property?.highlighted
                  }
                : state.active_property
              : state.active_property
            : state.active_property,
        active_address: null,
        active_coordinates: null,
        selected_active_properties:
          action.payload.add_type !== "no_active"
            ? null
            : state.selected_active_properties
      };

    case READ_TEXT_MESSAGE:
      return {
        ...state,
        active_property: state.active_property
          ? state.active_property.deal
            ? action.payload.lead_id == state.active_property.deal.id
              ? {
                  ...state.active_property,
                  deal: {
                    ...state.active_property.deal,
                    message_notifications:
                      parseInt(
                        state.active_property.deal.message_notifications
                      ) -
                        1 >
                      0
                        ? parseInt(
                            state.active_property.deal.message_notifications
                          ) - 1
                        : 0
                  }
                }
              : state.active_property
            : state.active_property
          : null,
        map_properties: state.map_properties.map((property, i) =>
          property.deal
            ? property.deal.id == action.payload.lead_id
              ? {
                  ...property,
                  deal: {
                    ...property.deal,
                    message_notifications:
                      parseInt(property.deal.message_notifications) - 1 > 0
                        ? parseInt(property.deal.message_notifications) - 1
                        : 0
                  }
                }
              : property
            : property
        )
      };
    /* temp reducer items */
    case UPDATE_HOUSE_SUCCESS:
      return {
        ...state,
        active_property: state.active_property
          ? state.active_property.deal
            ? action.payload.deal.id == state.active_property.deal.id
              ? {
                  ...state.active_property,
                  deal: {
                    ...action.payload.deal,
                    image: state.active_property.deal.image
                  }
                }
              : state.active_property
            : state.active_property
          : null,
        map_properties: state.map_properties.map((property, i) =>
          property.deal
            ? property.deal.id == action.payload.deal.id
              ? {
                  ...property,
                  deal: {
                    ...property.deal,
                    ...action.payload.deal,
                    phone_numbers: property.deal.phone_numbers,
                    email_addresses: property.deal.email_addresses,
                    image: property.deal.image
                  }
                }
              : property
            : property
        )
      };
    case GOOGLE_STREET_VIEW_SUCCESS:
      return {
        ...state,
        active_property: state.active_property
          ? state.active_property.deal
            ? action.payload.deal_id == state.active_property.deal.id
              ? {
                  ...state.active_property,
                  deal: {
                    ...state.active_property.deal,
                    image: action.payload.image,
                    from_google_street_view: 1
                  }
                }
              : state.active_property
            : state.active_property
          : null,
        map_properties: state.map_properties.map((property, i) =>
          property.deal
            ? property.deal.id == action.payload.deal_id
              ? {
                  ...property,
                  deal: {
                    ...property.deal,
                    image: action.payload.image,
                    from_google_street_view: 1
                  }
                }
              : property
            : property
        )
      };

    case DELETE_DEAL:
      return {
        ...state,
        active_property: state.active_property
          ? state.active_property.deal
            ? action.payload.deal_id == state.active_property.deal.id
              ? null
              : state.active_property
            : state.active_property
          : null,
        map_properties: state.map_properties.map((property, i) =>
          property.deal
            ? property.deal.id == action.payload.deal_id
              ? {
                  ...property,
                  deal: null
                }
              : property
            : property
        )
      };

    case REMOVE_LEADS_SUCCESS:
      return {
        ...state,
        show_active_property: state.active_property
          ? state.active_property.deal
            ? action.payload.leads.includes(state.active_property?.deal?.id)
              ? false
              : state.show_active_property
            : state.show_active_property
          : state.show_active_property,
        active_property: state.active_property
          ? state.active_property.deal
            ? action.payload.leads.includes(state.active_property?.deal?.id)
              ? null
              : state.active_property
            : state.active_property
          : null,

        map_properties: state.map_properties.map(property => {
          for (let i = 0; i < action.payload.leads.length; i++) {
            if (action.payload.leads[i] == property.deal?.id) {
              return {
                ...property,
                deal: null
              };
            }
          }
          return property;
        })
      };

    case GET_OWNER_INFO:
      return {
        ...state,
        owner_loading: true
      };

    case GET_OWNER_INFO_FAIL:
      return {
        ...state,
        owner_loading: false
      };
    case GET_OWNER_INFO_SUCCESS:
      let did_skip_trace = state.active_property?.deal?.did_skip_trace;
      let skip_trace_successful =
        state.active_property?.deal?.skip_trace_successful;
      let skip_trace_mailing_addresses_count = 0;
      let skip_trace_phone_numbers_count = 0;
      let skip_trace_email_addresses_count = 0;

      if (action.payload?.owners?.length > 0) {
        for (
          let i = 0;
          i < action.payload.owners[0].mailing_addresses?.length;
          i++
        ) {
          if (action.payload.owners[0].mailing_addresses[i].manual == 0) {
            did_skip_trace = true;
            skip_trace_successful = true;
            skip_trace_mailing_addresses_count++;
          }
        }
        for (
          let i = 0;
          i < action.payload.owners[0].phone_numbers?.length;
          i++
        ) {
          if (action.payload.owners[0].phone_numbers[i].manual == 0) {
            did_skip_trace = true;
            skip_trace_successful = true;
            skip_trace_phone_numbers_count++;
          }
        }
        for (
          let i = 0;
          i < action.payload.owners[0].email_addresses?.length;
          i++
        ) {
          if (action.payload.owners[0].email_addresses[i].manual == 0) {
            did_skip_trace = true;
            skip_trace_successful = true;
            skip_trace_email_addresses_count++;
          }
        }
      }

      return {
        ...state,
        owner_loading: false,
        active_property:
          state.active_property &&
          action.payload?.owners?.length > 0 &&
          state.active_property?.deal?.id == action.payload.owners[0].lead_id
            ? {
                ...state.active_property,
                deal: {
                  ...state.active_property.deal,
                  did_skip_trace,
                  skip_trace_successful,
                  skip_trace_mailing_addresses_count,
                  skip_trace_phone_numbers_count,
                  skip_trace_email_addresses_count,
                  additional_mailing_addresses:
                    action.payload.owners[0].mailing_addresses,
                  phone_numbers: action.payload.owners[0].phone_numbers,
                  email_addresses: action.payload.owners[0].email_addresses,

                  additional_mailing_addresses_count: action.payload.owners[0]
                    .mailing_addresses
                    ? action.payload.owners[0].mailing_addresses.length
                    : null,
                  phone_numbers_count: action.payload.owners[0].phone_numbers
                    ? action.payload.owners[0].phone_numbers.length
                    : null,
                  email_addresses_count: action.payload.owners[0]
                    .email_addresses
                    ? action.payload.owners[0].email_addresses.length
                    : null,

                  owner: action.payload.owners[0]
                }
              }
            : state.active_property
      };
    case RELOAD_LEADS:
      return {
        ...state,
        active_property: state.active_property
          ? state.active_property.deal
            ? action.payload.leads && action.payload.leads.length > 0
              ? action.payload.leads.includes(state.active_property.deal.id)
                ? {
                    ...state.active_property,
                    deal: {
                      ...state.active_property.deal,
                      did_skip_trace: 1,
                      updating: {
                        is_updating: true,
                        updating_title: "Skip Tracing...",
                        updating_type: "skip_trace_lead"
                      }
                    }
                  }
                : state.active_property
              : state.active_property
            : state.active_property
          : null,
        map_properties: state.map_properties.map((property, i) =>
          property.deal
            ? action.payload.leads && action.payload.leads.length > 0
              ? action.payload.leads.includes(property.deal.id)
                ? {
                    ...property,
                    deal: {
                      ...property.deal,
                      did_skip_trace: 1,
                      updating: {
                        is_updating: true,
                        updating_title: "Skip Tracing...",
                        updating_type: "skip_trace_lead"
                      }
                    }
                  }
                : property
              : property
            : property
        )
      };
    case SELECT_EDIT_PHOTO:
      return {
        ...state,
        active_property: state.active_property
          ? state.active_property.deal
            ? action.payload.deal_id == state.active_property.deal.id
              ? {
                  ...state.active_property,
                  deal: {
                    ...state.active_property.deal,
                    image: action.payload.uri
                  }
                }
              : state.active_property
            : state.active_property
          : null,
        map_properties: state.map_properties.map((property, i) =>
          property.deal
            ? property.deal.id == action.payload.deal_id
              ? {
                  ...property,
                  deal: {
                    ...property.deal,
                    image: action.payload.uri
                  }
                }
              : property
            : property
        )
      };

    case UPLOAD_EDIT_PROGRESS:
      return {
        ...state,
        active_property: state.active_property
          ? state.active_property.deal
            ? action.payload.deal_id == state.active_property.deal.id
              ? {
                  ...state.active_property,
                  deal: {
                    ...state.active_property.deal,
                    progress: action.payload.progress
                  }
                }
              : state.active_property
            : state.active_property
          : null,
        map_properties: state.map_properties.map((property, i) =>
          property.deal
            ? property.deal.id == action.payload.deal_id
              ? {
                  ...property,
                  deal: {
                    ...property.deal,
                    progress: action.payload.progress
                  }
                }
              : property
            : property
        )
      };

    case UPLOAD_EDIT_PHOTO_SUCCESS:
      return {
        ...state,
        active_property: state.active_property
          ? state.active_property.deal
            ? action.payload.deal_id == state.active_property.deal.id
              ? {
                  ...state.active_property,
                  deal: {
                    ...state.active_property.deal,
                    image: action.payload.image,
                    just_uploaded: true
                  }
                }
              : state.active_property
            : state.active_property
          : null,
        map_properties: state.map_properties.map((property, i) =>
          property.deal
            ? property.deal.id == action.payload.deal_id
              ? {
                  ...property,
                  deal: {
                    ...property.deal,
                    image: action.payload.image
                  }
                }
              : property
            : property
        )
      };

    case TOGGLE_TAP_TO_ADD:
      return {
        ...state,
        tap_to_add: action.payload
      };
    case SWITCH_MAP_MODE:
      return {
        ...state,
        map_mode: action.payload
      };

    case GET_PROPERTY:
      return {
        ...state,
        active_property: null,
        active_property_loading: true
      };

    case SOFT_REFRESH_PROPERTY:
      return {
        ...state,
        active_property_loading: true
      };
    case GET_PROPERTY_FAIL:
      return {
        ...state,
        active_property_loading: false
      };

    case GET_PROPERTY_SUCCESS:
    case GET_PROPERTY_WITH_ROUTE_INFO_SUCCESS:
      return {
        ...state,
        active_property:
          !state.active_property ||
          (state.active_property &&
            action.payload.property &&
            action.payload.property.property_id ==
              state.active_property.property_id)
            ? action.payload.property
            : state.active_property,

        active_property_loading: false
      };

    case RELOAD_LEADS_SUCCESS:
    case UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        active_property: action.payload.properties
          ? state.active_property
            ? updateProperty(state.active_property, action.payload.properties)
            : state.active_property
          : state.active_property,
        map_properties: action.payload.properties
          ? state.map_properties.map((property, i) =>
              updateProperty(property, action.payload.properties)
            )
          : state.map_properties
      };

    case UPDATE_OWNER_INFO_SUCCESS:
      return {
        ...state,
        active_property: updateProperty(
          state.active_property,
          action.payload.properties
        ),
        map_properties: action.payload.properties
          ? state.map_properties.map((property, i) =>
              updateProperty(property, action.payload.properties)
            )
          : state.map_properties
      };

    case HIDE_CTA:
      return {
        ...state,
        active_property: {
          ...state.active_property,
          deal: {
            ...state.active_property.deal,
            hide_cta: 1
          }
        }
      };

    case GENERATE_PREVIEW_IMAGES:
      return {
        ...state,
        active_property: state.active_property
          ? {
              ...state.active_property,
              deal: {
                ...state.active_property.deal,
                mailer_preview: null,
                mailer_preview_back: null
              }
            }
          : state.active_property
      };

    case GENERATE_PREVIEW_IMAGES_SUCCESS:
      return {
        ...state,
        active_property: state.active_property
          ? state.active_property.deal
            ? action.payload.deal_id == state.active_property.deal.id
              ? {
                  ...state.active_property,
                  deal: {
                    ...state.active_property.deal,
                    mailer_preview: action.payload.image,
                    mailer_preview_back: action.payload.image_back
                  }
                }
              : state.active_property
            : state.active_property
          : null,
        map_properties: state.map_properties.map((property, i) =>
          property.deal
            ? property.deal.id == action.payload.deal_id
              ? {
                  ...property,
                  deal: {
                    ...property.deal,
                    mailer_preview: action.payload.image,
                    mailer_preview_back: action.payload.image_back
                  }
                }
              : property
            : property
        )
      };

    case HIDE_PROPERTY_LIST:
      return {
        ...state,
        hide_property_list: action.payload
      };

    case TRIGGER_LEADS_RELOAD:
    case SET_ACTIVITY_PROPERTIES_TYPE:
      return {
        ...state,
        active_property: null
      };

    case UPDATE_HOUSE_IMAGE: {
      // payload: additional_image.image: string (url)
      if (!state.active_property) return state;
      const { active_property } = state;
      const { deal } = active_property;
      const updatedProperty = {
        ...active_property,
        deal: {
          ...deal,
          image: action.payload
        }
      };
      return {
        ...state,
        active_property: updatedProperty
      };
    }

    case UPDATE_HOUSE_ADDITIONAL_IMAGES: {
      /* payload:
      {
        id: string,
        image: string (url),
        use_for_marketing: boolean,
        loading: boolean,
        unqique_string: string
      }
      */
      if (!state.active_property) return state;
      const { active_property } = state;
      const { deal } = active_property;

      const additionalImages = [
        ...(deal.additional_images || []),
        action.payload
      ];

      return {
        ...state,
        active_property: {
          ...active_property,
          deal: {
            ...deal,
            additional_images: additionalImages
          }
        }
      };
    }
    case SET_HOUSE_ADDITIONAL_IMAGE_LOADING: {
      // payload: { unqique_string: string, loading: boolean, id: string }
      if (!state.active_property) return state;
      const { active_property } = state;
      const { deal } = active_property;
      const additional_images = deal.additional_images;
      const existingImageIndex = additional_images.findIndex(
        ({ unique_string }) => unique_string == action.payload.unique_string
      );
      const updatedImage = {
        ...additional_images[existingImageIndex],
        loading: action.payload.loading,
        id: action.payload.id,
        image: action.payload.image
      };
      const updatedImages = additional_images.filter(
        ({ unique_string }) => unique_string != action.payload.unique_string
      );
      updatedImages.splice(existingImageIndex, 0, updatedImage);
      return {
        ...state,
        active_property: {
          ...active_property,
          deal: {
            ...deal,
            additional_images: updatedImages
          }
        }
      };
    }
    case DELETE_HOUSE_IMAGE: {
      // payload: additional_image.id: string
      if (!state.active_property) return state;
      const { active_property } = state;
      const { deal } = active_property;
      return {
        ...state,
        active_property: {
          ...active_property,
          deal: {
            ...deal,
            additional_images: deal.additional_images.filter(
              ({ id }) => id != action.payload
            )
          }
        }
      };
    }
    case UPDATE_HOUSE_MARKETING_IMAGE: {
      // payload: additional_image.id: string
      if (!state.active_property) return state;
      const { active_property } = state;
      const { deal } = active_property;
      const existingImageIndex = deal.additional_images.findIndex(
        ({ id }) => id == action.payload
      );
      const updatedImage = {
        ...deal.additional_images[existingImageIndex],
        use_for_marketing:
          !!!deal.additional_images[existingImageIndex].use_for_marketing
      };
      const updatedImages = deal.additional_images.filter(
        ({ id }) => id != action.payload
      );
      updatedImages.splice(existingImageIndex, 0, updatedImage);
      return {
        ...state,
        active_property: {
          ...active_property,
          deal: {
            ...deal,
            additional_images: updatedImages
          }
        }
      };
    }

    case GET_PROPERTY_IMAGES:
      return {
        ...state,
        property_images_loading: true
      };

    case GET_PROPERTY_IMAGES_FAIL:
      return {
        ...state,
        property_images_loading: false
      };

    case UPDATE_LEAD_PHOTO_COMPLETE:
      return {
        ...state,
        active_property:
          action.payload.property &&
          state.active_property &&
          state.active_property?.deal?.id == action.payload.lead_id
            ? action.payload.property
            : state.active_property,
        map_properties: action.payload.property
          ? state.map_properties.map((property, i) => {
              if (property.deal?.id == action.payload.lead_id) {
                return action.payload.property;
              }
              return property;
            })
          : state.map_properties
      };
    case GET_PROPERTY_IMAGES_SUCCESS:
    case UPDATE_PROPERTY_IMAGE_SUCCESS:
      return {
        ...state,
        property_images_loading: false,
        active_property:
          state.active_property && action.payload.property
            ? updateProperty(state.active_property, [action.payload.property])
            : state.active_property &&
              state.active_property?.deal?.id == action.payload.lead_id
            ? {
                ...state.active_property,
                deal: {
                  ...state.active_property.deal,
                  additional_images: action.payload.additional_images
                    ? action.payload.additional_images
                    : state.active_property.deal.additional_images
                }
              }
            : state.active_property,
        map_properties: action.payload.additional_images
          ? state.map_properties.map((property, i) => {
              if (action.payload.property) {
                updateProperty(property, [action.payload.property]);
              } else if (property.deal?.id == action.payload.lead_id) {
                return {
                  ...property,
                  deal: {
                    ...property.deal,
                    additional_images: action.payload.additional_images
                      ? action.payload.additional_images
                      : state.active_property.deal.additional_images
                  }
                };
              }
              return property;
            })
          : state.map_properties
      };

    case SET_APPLIED_HIGHLIGHT:
      return {
        ...state,
        applied_highlight: action.payload.applied_highlight
      };

    case UPDATE_MAP_FILTERS:
      return {
        ...state,
        map_filters: {
          ...state.map_filters,
          ...action.payload
        }
      };

    case GET_HIGHLIGHT_TEMPLATES_SUCCESS:
      return {
        ...state,
        highlight_templates: action.payload.highlight_templates
          ? action.payload.highlight_templates
          : state.highlight_templates,
        saved_highlights: action.payload.saved_highlights
          ? action.payload.saved_highlights
          : state.saved_highlights
      };

    case SET_STREET_VIEW_COORDS:
      return {
        ...state,
        street_view_coords: action.payload
      };
    case SET_STREET_VIEW_LINE:
      return {
        ...state,
        street_view_line: action.payload
      };
    case TOGGLE_MAP_MOVING:
      return {
        ...state,
        mapIsMoving: action.payload
      };
    case LOGIN_USER_SUCCESS:
    case SAVE_CARD_SUCCESS:
      return {
        ...state,
        main_map_location: action.payload?.user?.user_settings
          ?.main_map_location
          ? {
              ...state.main_map_location,
              ...action.payload?.user?.user_settings?.main_map_location,
              user_latlng_loaded: true
            }
          : action.payload?.user?.default_lat &&
            action.payload?.user?.default_lng
          ? {
              ...state.main_map_location,
              latitude: action.payload?.user?.default_lat,
              longitude: action.payload?.user?.default_lng,
              user_latlng_loaded: true
            }
          : state.main_map_location
      };

    case UPDATE_MAIN_MAP_LOCATION:
      return {
        ...state,
        main_map_location: {
          ...state.main_map_location,
          ...action.payload
        }
      };

    case CREATE_SAVED_FILTER_SUCCESS:
      return {
        ...state,
        saved_filters:
          action.payload.saved_filters &&
          action.payload.filter_type == "highlights" &&
          action.payload.saved_filters.length > 0
            ? [action.payload.saved_filters[0], ...state.saved_highlights]
            : state.saved_highlights
      };
    case UPDATE_SAVED_FILTER_SUCCESS:
      return {
        ...state,
        saved_highlights:
          action.payload.saved_filters &&
          action.payload.filter_type == "highlights" &&
          action.payload.saved_filters.length > 0
            ? action.payload.saved_filters
            : state.saved_highlights
      };

    case REMOVE_SAVED_FILTER_SUCCESS:
      return {
        ...state,
        saved_highlights:
          action.payload.filter_type == "highlights"
            ? state.saved_highlights.filter(
                ({ id, system_type }) => id != action.payload.advanced_filter_id
              )
            : state.saved_highlights
      };
    default:
      return state;
  }
};
