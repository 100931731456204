import React, { PureComponent } from "react";

import { CircleButton } from "app/NativeComponents/snippets";

import { getListStackColor } from "app/NativeActions";
import ManageListsButton from "app/DealMachineCore/reuseable/ManageListsButton";
class ListsButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      number_of_lists: props.property?.deal?.lists?.length,
      number_of_stacked_lists: props.property?.deal?.stacked_lists?.length
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { property } = this.props;
    if (JSON.stringify(property) !== JSON.stringify(prevProps.property)) {
      this.setState({
        number_of_lists:
          property && property.deal && property.deal.lists
            ? property.deal.lists.length
            : 0,
        number_of_stacked_lists:
          property && property.deal && property.deal.stacked_lists
            ? property.deal.stacked_lists?.length
            : 0
      });
    }
  }

  render() {
    const { colors, user, property, device } = this.props;
    const { loading, number_of_lists, number_of_stacked_lists } = this.state;

    const nsl = loading
      ? number_of_stacked_lists
      : property?.deal?.stacked_lists?.length;

    return (
      <ManageListsButton
        popoverPlacement="bottom"
        propertyUpdated={this.props.propertyUpdated}
        onLoading={({ loading, number_of_lists, number_of_stacked_lists }) => {
          this.setState({
            loading,
            number_of_stacked_lists,
            number_of_lists
          });
        }}
        renderComponent={({ pressedIn, hovering }) => {
          return (
            <CircleButton
              noPress={true}
              pressedIn={pressedIn || hovering}
              style={{ marginLeft: 0 }}
              tooltip={nsl == 0 ? "Add To List" : "Manage Lists"}
              tooltipPlacement={"top"}
              icon="format-list-numbered"
              show_notification={nsl > 0 ? true : false}
              notification_color={
                nsl == 0 ? colors.success_color : getListStackColor(nsl, false)
              }
              notification_icon={nsl == 0 ? "add" : ""}
              notification_number={nsl}
            />
          );
        }}
        property={property}
      />
    );
  }
}

export default ListsButton;
