import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  AttentionBox,
  NewHeader,
  SelectItem,
  SpinWrapper
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  pushSidePanel,
  getLimitsForAllPlan,
  setPhotoToCameraroll,
  updateUserSettings,
  getWorkspaceSettings,
  editWorkspaceSettings
} from "app/NativeActions";

import PostcardDesigner from "app/DealMachineCore/components/Mail/PostcardDesigner";

class GlobalPostcards extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  handleBack() {
    this.props.popSidePanel();
  }

  render() {
    const { source_of_truth } = this.props;

    if (!source_of_truth) {
      return null;
    }

    return (
      <Container>
        <NewHeader
          title="Global Postcards"
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <AttentionBox description="These postcard designs will be available for all teams to use." />
          <PostcardDesigner global_templates={true} />
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing, workspaces }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors, photoCameraRoll } = settings;
  const { plan_modules, source_of_truth } = billing;
  const { workspace_settings } = workspaces;

  return {
    token,
    user,
    device,
    colors,
    photoCameraRoll,
    plan_modules,
    source_of_truth,
    workspace_settings
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  getLimitsForAllPlan,
  setPhotoToCameraroll,
  updateUserSettings,
  getWorkspaceSettings,
  editWorkspaceSettings
})(GlobalPostcards);
