import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  KeyboardView,
  Row,
  Wrapper,
  Bold,
  Copy
} from "app/NativeComponents/common";
import {
  NewHeader,
  InlineTabs,
  SmallLabel,
  InputBox,
  SelectItem,
  GhostButton,
  BottomNavBar,
  ConfirmInlineButton,
  SpinWrapper,
  AttentionBox,
  InlineButton,
  List
} from "app/NativeComponents/snippets";

import {
  generateWorkspaceTeamName,
  popSidePanel,
  editWorkspaceTeam,
  showErrorMessage,
  numberWithCommas,
  getWorkspaceTeamLicenses,
  editWorkspaceTeamLicenses,
  pushSidePanel,
  renderDate
} from "app/NativeActions";

class ManageWorkspaceTeamLicenses extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    this.state = {
      team: panel_data?.team,
      loading_licenses: false,
      loading_assign_license: false,
      tab: "dialer",
      total_assigned_dialer_licenses_for_workspace: 0,
      total_assigned_voicemail_licenses_for_workspace: 0,
      team_members: []
    };

    this._team_name = React.createRef();
    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
  }

  componentDidMount() {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    if (!panel_data?.team) {
      this.props.popSidePanel();
    }

    this.props.getWorkspaceTeamLicenses({
      token: this.props.token,
      type: "get_team_licenses",
      team_id: this.state.team?.id,
      onLoading: () => {
        this.setState({ loading_licenses: true });
      },
      onError: error => {
        this.setState({ loading_licenses: false });
      },
      onSuccess: results => {
        this.setState({
          loading_licenses: false,
          dialer_seats: results?.licenses?.filter(
            license => license.seat_type == "dialer"
          ),
          voicemail_seats: results?.licenses?.filter(
            license => license.seat_type == "voicemail"
          ),
          team_members: results?.team_members,
          total_assigned_dialer_licenses_for_workspace:
            results?.total_assigned_dialer_licenses_for_workspace,
          total_assigned_voicemail_licenses_for_workspace:
            results?.total_assigned_voicemail_licenses_for_workspace
        });
      }
    });
  }

  checkIfNeedsToSave() {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    if (
      panel_data?.team?.team_name !== this.state.team_name &&
      !!this.state.team_name
    ) {
      return true;
    }

    return false;
  }

  render() {
    const number_of_dialer_licenses =
      this.props.source_of_truth?.subscription?.metadata
        ?.number_of_dialer_seats || 0;
    const number_of_voicemail_licenses =
      this.props.source_of_truth?.subscription?.metadata
        ?.number_of_voicemail_seats || 0;

    const additional_dialer_licenses =
      this.props.source_of_truth?.subscription?.metadata
        ?.additional_dialer_seats || 0;
    const additional_voicemail_licenses =
      this.props.source_of_truth?.subscription?.metadata
        ?.additional_voicemail_seats || 0;

    const total_number_of_dialer_licenses =
      number_of_dialer_licenses + additional_dialer_licenses;

    const total_number_of_voicemail_licenses =
      number_of_voicemail_licenses + additional_voicemail_licenses;

    const { dialer_seats, voicemail_seats } = this.state;

    return (
      <Container>
        <NewHeader
          title={"Manage Licenses"}
          subtitle={""}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.props.popSidePanel();
            }
          }}
        />

        {this.state.loading_licenses ? (
          <SpinWrapper text="Loading" />
        ) : (
          <>
            <InlineTabs
              tab_type="underline"
              selected_tab={this.state.tab}
              tabs={[
                {
                  onPress: () => {
                    this.setState({
                      tab: "dialer"
                    });
                  },
                  slug: "dialer",
                  title: "Dialer Licenses"
                },

                {
                  onPress: () => {
                    this.setState({
                      tab: "voicemail"
                    });
                  },
                  slug: "voicemail",
                  title: "AI Voicemails Licenses"
                }
              ]}
            />

            {this.state.tab === "dialer" ? (
              <>
                {total_number_of_dialer_licenses == 0 ? (
                  <AttentionBox
                    primary={true}
                    title={
                      total_number_of_dialer_licenses == 0
                        ? "You don't have any dialer licenses for your account."
                        : ""
                    }
                    description={
                      total_number_of_dialer_licenses == 0
                        ? "Talk to your account representative to get dialer licenses."
                        : ""
                    }
                  />
                ) : (
                  <>
                    <AttentionBox
                      title={
                        "You've assigned " +
                        this.state
                          .total_assigned_dialer_licenses_for_workspace +
                        " dialer licenses of the " +
                        total_number_of_dialer_licenses +
                        " available for this account."
                      }
                      description={
                        "Talk to your account representative to get more dialer licenses if you need them."
                      }
                    />

                    <List
                      rowNumber={1}
                      style={{ flex: 1 }}
                      items={dialer_seats}
                      infiniteScroll={true}
                      itemStructure={({ item, index }) => {
                        return (
                          <SelectItem
                            key={"dialer_seat" + index}
                            select_type={"none"}
                            subtext={renderDate(item.date_created)}
                            description={"Dialer License"}
                            onPress={() => {
                              this.props.pushSidePanel({
                                slug: "edit_dialer_license",
                                overlay: true,
                                data: {
                                  assigned_user: item?.assigned_user,
                                  seat_type: "dialer",
                                  license_id: item?.id,
                                  team_members: this.state.team_members,
                                  workspace_id: this.props.user?.id,
                                  workspace_team_id: this.state.team?.id,
                                  onEditComplete: results => {
                                    this.setState({
                                      dialer_seats: results?.licenses?.filter(
                                        license => license.seat_type == "dialer"
                                      ),
                                      voicemail_seats:
                                        results?.licenses?.filter(
                                          license =>
                                            license.seat_type == "voicemail"
                                        ),
                                      total_assigned_dialer_licenses_for_workspace:
                                        results?.total_assigned_dialer_licenses_for_workspace,
                                      total_assigned_voicemail_licenses_for_workspace:
                                        results?.total_assigned_voicemail_licenses_for_workspace
                                    });
                                  }
                                }
                              });
                            }}
                            icon={"keyboard-arrow-right"}
                          >
                            {!!item?.assigned_user && item?.assigned_user != 0
                              ? !!item.firstname
                                ? item.firstname + " " + item.lastname
                                : item.email
                              : "Unassigned Team Member"}
                          </SelectItem>
                        );
                      }}
                      canRefresh={false}
                      onRefresh={() => {}}
                      is_refreshing={false}
                      canLoadMore={false}
                      isLoadingMore={false}
                      onLoadMore={() => {}}
                    />

                    <BottomNavBar>
                      <InlineButton
                        primary={true}
                        icon={"add"}
                        disabled={
                          this.state
                            .total_assigned_dialer_licenses_for_workspace >=
                          total_number_of_dialer_licenses
                        }
                        tooltip={
                          this.state
                            .total_assigned_dialer_licenses_for_workspace >=
                          total_number_of_dialer_licenses
                            ? "You've reached the maximum number of dialer licenses for this account."
                            : ""
                        }
                        tooltipPlacement={"top"}
                        button_type={"full"}
                        loading={this.state.loading_assign_license}
                        onPress={() => {
                          this.props.editWorkspaceTeamLicenses({
                            token: this.props.token,
                            type: "assign_license_to_team",
                            team_id: this.state.team?.id,
                            seat_type: "dialer",
                            onLoading: () => {
                              this.setState({
                                loading_assign_license: true
                              });
                            },
                            onError: error => {
                              this.setState({ loading_assign_license: false });
                            },
                            onSuccess: results => {
                              this.setState({
                                loading_assign_license: false,
                                dialer_seats: results?.licenses?.filter(
                                  license => license.seat_type == "dialer"
                                ),
                                voicemail_seats: results?.licenses?.filter(
                                  license => license.seat_type == "voicemail"
                                ),
                                total_assigned_dialer_licenses_for_workspace:
                                  results?.total_assigned_dialer_licenses_for_workspace,
                                total_assigned_voicemail_licenses_for_workspace:
                                  results?.total_assigned_voicemail_licenses_for_workspace
                              });
                            }
                          });
                        }}
                      >
                        Assign License To Team
                      </InlineButton>
                    </BottomNavBar>
                  </>
                )}
              </>
            ) : (
              <>
                {total_number_of_voicemail_licenses == 0 ? (
                  <AttentionBox
                    primary={true}
                    title={
                      total_number_of_voicemail_licenses == 0
                        ? "You don't have any AI Voicemail licenses for your account."
                        : ""
                    }
                    description={
                      total_number_of_voicemail_licenses == 0
                        ? "Talk to your account representative to get AI Voicemail licenses."
                        : ""
                    }
                  />
                ) : (
                  <>
                    <AttentionBox
                      title={
                        "You've assigned " +
                        this.state
                          .total_assigned_voicemail_licenses_for_workspace +
                        " AI Voicemail licenses of the " +
                        total_number_of_voicemail_licenses +
                        " available for this account."
                      }
                      description={
                        "Talk to your account representative to get more AI Voicemail licenses if you need them."
                      }
                    />

                    <List
                      rowNumber={1}
                      style={{ flex: 1 }}
                      items={voicemail_seats}
                      infiniteScroll={true}
                      itemStructure={({ item, index }) => {
                        return (
                          <SelectItem
                            key={"voicemail_seat" + index}
                            select_type={"none"}
                            subtext={renderDate(item.date_created)}
                            description={"AI Voicemail License"}
                            onPress={() => {
                              this.props.pushSidePanel({
                                slug: "edit_dialer_license",
                                overlay: true,
                                data: {
                                  assigned_user: item?.assigned_user,
                                  seat_type: "dialer",
                                  license_id: item?.id,
                                  team_members: this.state.team_members,
                                  workspace_id: this.props.user?.id,
                                  workspace_team_id: this.state.team?.id,
                                  onEditComplete: results => {
                                    this.setState({
                                      dialer_seats: results?.licenses?.filter(
                                        license => license.seat_type == "dialer"
                                      ),
                                      voicemail_seats:
                                        results?.licenses?.filter(
                                          license =>
                                            license.seat_type == "voicemail"
                                        ),
                                      total_assigned_dialer_licenses_for_workspace:
                                        results?.total_assigned_dialer_licenses_for_workspace,
                                      total_assigned_voicemail_licenses_for_workspace:
                                        results?.total_assigned_voicemail_licenses_for_workspace
                                    });
                                  }
                                }
                              });
                            }}
                            icon={"keyboard-arrow-right"}
                          >
                            {!!item?.assigned_user && item?.assigned_user != 0
                              ? !!item.firstname
                                ? item.firstname + " " + item.lastname
                                : item.email
                              : "Unassigned Team Member"}
                          </SelectItem>
                        );
                      }}
                      canRefresh={false}
                      onRefresh={() => {}}
                      is_refreshing={false}
                      canLoadMore={false}
                      isLoadingMore={false}
                      onLoadMore={() => {}}
                    />

                    <BottomNavBar>
                      <InlineButton
                        primary={true}
                        icon={"add"}
                        disabled={
                          this.state
                            .total_assigned_voicemail_licenses_for_workspace >=
                          total_number_of_voicemail_licenses
                        }
                        tooltip={
                          this.state
                            .total_assigned_voicemail_licenses_for_workspace >=
                          total_number_of_voicemail_licenses
                            ? "You've reached the maximum number of AI Voicemail licenses for this account."
                            : ""
                        }
                        tooltipPlacement={"top"}
                        button_type={"full"}
                        loading={this.state.loading_assign_license}
                        onPress={() => {
                          this.props.editWorkspaceTeamLicenses({
                            token: this.props.token,
                            type: "assign_license_to_team",
                            team_id: this.state.team?.id,
                            seat_type: "voicemail",
                            onLoading: () => {
                              this.setState({
                                loading_assign_license: true
                              });
                            },
                            onError: error => {
                              this.setState({ loading_assign_license: false });
                            },
                            onSuccess: results => {
                              this.setState({
                                loading_assign_license: false,
                                dialer_seats: results?.licenses?.filter(
                                  license => license.seat_type == "dialer"
                                ),
                                voicemail_seats: results?.licenses?.filter(
                                  license => license.seat_type == "voicemail"
                                ),
                                total_assigned_dialer_licenses_for_workspace:
                                  results?.total_assigned_dialer_licenses_for_workspace,
                                total_assigned_voicemail_licenses_for_workspace:
                                  results?.total_assigned_voicemail_licenses_for_workspace
                              });
                            }
                          });
                        }}
                      >
                        Assign License To Team
                      </InlineButton>
                    </BottomNavBar>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user, user_partner_info } = auth;
  const { device, isIphoneX, isMobile, platform, desktopMobile } = native;
  const { colors } = settings;
  const { source_of_truth } = billing;
  return {
    user,
    user_partner_info,
    token,
    device,
    isIphoneX,
    isMobile,
    platform,
    desktopMobile,
    colors,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  generateWorkspaceTeamName,
  editWorkspaceTeam,
  showErrorMessage,
  popSidePanel,
  getWorkspaceTeamLicenses,
  editWorkspaceTeamLicenses,
  pushSidePanel
})(ManageWorkspaceTeamLicenses);
