import React, { Component } from "react";
import { Row, Wrapper, Copy, Bold, Spin } from "app/NativeComponents/common";
import { PopoverMenu, IconButton } from "app/NativeComponents/snippets";

import { numberWithCommas } from "app/NativeActions";
import { CircularProgressbar } from "react-circular-progressbar";

class WorkspaceTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_team_options: false,
      editing_team: false
    };
  }

  render() {
    const { colors, team, source_of_truth } = this.props;

    const number_of_team_members_limit =
      source_of_truth?.subscription?.metadata?.number_of_team_members;
    const export_limit = source_of_truth?.subscription?.metadata?.export_limit;
    const total_number_of_leads_limit =
      source_of_truth?.subscription?.metadata?.total_number_of_leads;

    return (
      <Wrapper
        style={{
          padding: 25,
          borderBottomWidth: 1,
          borderBottomColor: colors.border_color,
          borderBottomStyle: "solid"
        }}
      >
        <Row>
          <Wrapper style={{ flex: 1 }}>
            <Row>
              {this.state.editing_team ? (
                <Wrapper style={{ marginRight: 25 }}>
                  <Spin size="small" />
                </Wrapper>
              ) : null}
              <Wrapper style={{ marginRight: 15, flex: 1 }}>
                <Row>
                  <Copy>
                    <Bold>{team?.team_name}</Bold>
                  </Copy>
                  {!team?.is_active ? (
                    <Copy
                      style={{
                        fontSize: 12,
                        color: colors.light_text_color,
                        marginLeft: 10
                      }}
                    >
                      Archived
                    </Copy>
                  ) : !team?.finished_onboarding ? (
                    <Copy
                      style={{
                        fontSize: 12,
                        color: colors.light_text_color,
                        marginLeft: 10
                      }}
                    >
                      Invitation Pending
                    </Copy>
                  ) : null}
                </Row>
                <Copy style={{ fontSize: 12 }}>
                  Team Leader: {team?.firstname + " " + team?.lastname}
                </Copy>
              </Wrapper>

              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 15,
                  width: 125,
                  height: 125
                }}
              >
                <Wrapper
                  style={{ width: 100, height: 100, position: "relative" }}
                >
                  <CircularProgressbar
                    value={
                      (team?.number_of_team_members /
                        number_of_team_members_limit) *
                      100
                    }
                    strokeWidth={5}
                    background={false}
                    styles={{
                      path: {
                        stroke: colors.success_color
                      },
                      trail: {
                        stroke: colors.background_color
                      }
                    }}
                  />
                  <Wrapper
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 5
                    }}
                  >
                    <Copy style={{ fontSize: 12, textAlign: "center" }}>
                      {numberWithCommas(team?.number_of_team_members)} of{" "}
                      {numberWithCommas(
                        number_of_team_members_limit +
                          team?.number_of_additional_seats
                      )}
                    </Copy>
                  </Wrapper>
                </Wrapper>
                <Copy
                  style={{ fontSize: 12, textAlign: "center", marginTop: 10 }}
                >
                  Team Members
                </Copy>
              </Wrapper>

              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 15,
                  width: 125,
                  height: 125
                }}
              >
                <Wrapper
                  style={{ width: 100, height: 100, position: "relative" }}
                >
                  <CircularProgressbar
                    value={(team?.export_count / export_limit) * 100}
                    strokeWidth={5}
                    background={false}
                    styles={{
                      path: {
                        stroke: colors.success_color
                      },
                      trail: {
                        stroke: colors.background_color
                      }
                    }}
                  />
                  <Wrapper
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 5
                    }}
                  >
                    <Copy style={{ fontSize: 12, textAlign: "center" }}>
                      {numberWithCommas(team?.export_count)} of{" "}
                      {numberWithCommas(export_limit)}
                    </Copy>
                  </Wrapper>
                </Wrapper>
                <Copy
                  style={{ fontSize: 12, textAlign: "center", marginTop: 10 }}
                >
                  Exports This Month
                </Copy>
              </Wrapper>

              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 15,
                  width: 125,
                  height: 125
                }}
              >
                <Wrapper
                  style={{ width: 100, height: 100, position: "relative" }}
                >
                  <CircularProgressbar
                    value={
                      (team?.total_number_of_leads /
                        total_number_of_leads_limit) *
                      100
                    }
                    strokeWidth={5}
                    background={false}
                    styles={{
                      path: {
                        stroke: colors.success_color
                      },
                      trail: {
                        stroke: colors.background_color
                      }
                    }}
                  />
                  <Wrapper
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 5
                    }}
                  >
                    <Copy style={{ fontSize: 12, textAlign: "center" }}>
                      {numberWithCommas(team?.total_number_of_leads)} of{" "}
                      {numberWithCommas(total_number_of_leads_limit)}
                    </Copy>
                  </Wrapper>
                </Wrapper>
                <Copy
                  style={{ fontSize: 12, textAlign: "center", marginTop: 10 }}
                >
                  Managed Properties
                </Copy>
              </Wrapper>
            </Row>
          </Wrapper>

          <PopoverMenu
            disabled={this.state.editing_team}
            renderComponent={({ pressedIn, hovering }) => {
              return (
                <IconButton
                  icon_color={colors.actionable_text_color}
                  icon="more-vert"
                  pressedIn={pressedIn || hovering}
                  noPress={true}
                  style={{ margin: 0 }}
                  disabled={this.state.editing_team}
                />
              );
            }}
            popover_width={350}
            menu_items={
              team?.is_active
                ? [
                    {
                      title: "Edit Team",
                      icon: "keyboard-arrow-right",
                      type: "normal",
                      select_type: "none",
                      onPress: () => {
                        this.props.pushSidePanel({
                          slug: "edit_team",
                          overlay: true,
                          data: {
                            team: team
                          }
                        });
                      }
                    },
                    {
                      title: "Manage Licenses",
                      icon: "keyboard-arrow-right",
                      type: "normal",
                      select_type: "none",
                      onPress: () => {
                        this.props.pushSidePanel({
                          slug: "manage_licenses",
                          overlay: true,
                          data: {
                            team: team
                          }
                        });
                      }
                    },
                    {
                      title: "Manage Additional Team Member Seats",
                      icon: "keyboard-arrow-right",
                      type: "normal",
                      select_type: "none",
                      onPress: () => {
                        this.props.pushSidePanel({
                          slug: "manage_additional_team_member_seats",
                          overlay: true,
                          data: { team: team }
                        });
                      }
                    },
                    {
                      title: "Manage Budget",
                      icon: "keyboard-arrow-right",
                      type: "normal",
                      select_type: "none",
                      onPress: () => {
                        this.props.pushSidePanel({
                          slug: "manage_budget",
                          overlay: true,
                          data: {
                            team: team
                          }
                        });
                      }
                    },
                    team?.finished_onboarding
                      ? null
                      : {
                          title: "Resend Invitation",
                          icon: "keyboard-arrow-right",
                          type: "normal",
                          select_type: "none",
                          onPress: () => {
                            this.props.resendWorkspaceTeamInvitation({
                              token: this.props.token,
                              team_id: team?.id,
                              onLoading: () => {
                                this.props.showSuccess(
                                  "Invitation resent to " + team?.email,
                                  "Success!"
                                );
                              },
                              onError: () => {},
                              onSuccess: () => {}
                            });
                          }
                        }
                  ]
                : [
                    {
                      title: "Reactivate Team",
                      icon: "keyboard-arrow-right",
                      type: "normal",
                      select_type: "none",
                      onPress: () => {
                        this.props.setModal({
                          title: "Reactivate Team",
                          description:
                            "Are you sure you want to reactivate this team?",
                          icon: "delete",
                          submit: "Reactivate",
                          require_complete_function: true,
                          onPress: complete => {
                            this.props.editWorkspaceTeam({
                              token: this.props.token,
                              type: "reactivate_team",
                              team_id: team?.id,
                              onLoading: () => {
                                this.setState({
                                  editing_team: true
                                });
                                this.props.toggleModal({ show: false });
                              },
                              onError: error => {
                                this.setState({
                                  editing_team: false
                                });

                                this.props.showErrorMessage(error, "Error");
                                this.props.toggleModal({ show: false });
                              },
                              onSuccess: () => {
                                this.setState({
                                  editing_team: false
                                });
                              }
                            });
                          },
                          cancel: "Cancel",
                          onCancel: () => {}
                        });
                        this.props.toggleModal({ show: true, type: "normal" });
                      }
                    },
                    {
                      title: "Permanently Delete Team",
                      icon: "keyboard-arrow-right",
                      type: "normal",
                      select_type: "none",
                      onPress: () => {
                        this.props.setModal({
                          title: "Permanently Delete Team",
                          description:
                            "Are you sure you want to permanently delete this team?",
                          icon: "delete",
                          submit: "Permanently Delete",
                          buttonType: "destroy",
                          require_complete_function: true,
                          onPress: complete => {
                            this.props.editWorkspaceTeam({
                              token: this.props.token,
                              type: "delete_team",
                              team_id: team?.id,
                              onLoading: () => {
                                this.setState({
                                  editing_team: true
                                });
                                this.props.toggleModal({ show: false });
                              },
                              onError: error => {
                                this.setState({
                                  editing_team: false
                                });

                                this.props.showErrorMessage(error, "Error");
                                this.props.toggleModal({ show: false });
                              },
                              onSuccess: () => {
                                this.setState({
                                  editing_team: false
                                });
                              }
                            });
                          },
                          cancel: "Cancel",
                          onCancel: () => {}
                        });
                        this.props.toggleModal({ show: true, type: "normal" });
                      }
                    }
                  ]
            }
          />
        </Row>
      </Wrapper>
    );
  }
}

export default WorkspaceTeam;
