import React, { Component } from "react";
import {
  Row,
  Wrapper,
  Button,
  DropzoneButton
} from "app/NativeComponents/common";
import { InputBox, CircleButton } from "app/NativeComponents/snippets";

class Inputs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false
    };

    this._firstname_input = React.createRef();
    this._lastname_input = React.createRef();
    this._username_input = React.createRef();
    this._company_input = React.createRef();
    //this._phone_number_input = React.createRef();
  }

  render() {
    const { editUser, loading } = this.props;
    return (
      <>
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 25
          }}
        >
          <DropzoneButton
            accept="image/jpeg, image/png"
            maxSize={5242880}
            onDrop={acceptedFiles => {
              this.props.uploadFile({
                acceptedFiles: acceptedFiles[0],
                token: this.props.token,
                onLoading: () => {
                  this.setState({
                    uploading: true
                  });
                },
                onSuccess: results => {
                  this.props.updateUserFieldChange({
                    prop: "image",
                    value: results.file
                  });
                  this.setState({
                    uploading: false
                  });
                },
                onError: error => {
                  this.setState({
                    uploading: false
                  });
                }
              });
            }}
            renderComponent={({ hovering }) => {
              return (
                <CircleButton
                  noPress={true}
                  loading={this.state.uploading}
                  pressedIn={hovering}
                  style={{ marginLeft: 0 }}
                  icon="person"
                  button_type="profile_pic"
                  user={this.props.editUser}
                  show_notification={true}
                  notification_color={this.props.colors.success_color}
                  notification_icon={"file-upload"}
                />
              );
            }}
          />
        </Wrapper>
        <InputBox
          input_ref={this._firstname_input}
          name="firstname"
          disabled={loading}
          returnKeyType="next"
          placeholder="First Name"
          onChange={value =>
            this.props.updateUserFieldChange({ prop: "firstname", value })
          }
          onSubmitEditing={() => {
            this._lastname_input.current.focus();
          }}
          blurOnSubmit={false}
          value={editUser.firstname}
          type="text"
        />
        <InputBox
          input_ref={this._lastname_input}
          name="lastname"
          disabled={loading}
          returnKeyType="next"
          placeholder="Last Name"
          onChange={value =>
            this.props.updateUserFieldChange({ prop: "lastname", value })
          }
          onSubmitEditing={() => {
            if (this.props.user.can_mimic_team_owner == 1) {
              this._company_input.current.focus();
            } else {
              this.props.saveUserInfo();
            }
          }}
          blurOnSubmit={false}
          value={editUser.lastname}
          type="text"
        />
        {this.props.user.can_mimic_team_owner == 1 ? (
          <InputBox
            input_ref={this._company_input}
            name="company"
            disabled={loading}
            returnKeyType="next"
            placeholder="Company"
            onChange={value =>
              this.props.updateUserFieldChange({ prop: "company", value })
            }
            onSubmitEditing={() => {
              /*
              if (this.props.device == "desktop") {
                this._phone_number_input.current.getInputDOMNode().focus();
              } else {
                this._phone_number_input.current.focus();
              }
              */
              this.props.saveUserInfo();
            }}
            blurOnSubmit={false}
            value={editUser.company}
            type="text"
          />
        ) : null}
        {/*
        <InputBox
          input_ref={this._phone_number_input}
          name="phone_number"
          disabled={loading}
          returnKeyType="next"
          placeholder="Phone Number"
          onChange={value =>
            this.props.updateUserFieldChange({ prop: "user_phone", value })
          }
          onSubmitEditing={() => {
            this.props.saveUserInfo();
          }}
          blurOnSubmit={false}
          value={editUser.user_phone}
          type="text"
          mask_type={"cel-phone"}
          mask={"(999) 999-9999"}
        />*/}
      </>
    );
  }
}

export default Inputs;
