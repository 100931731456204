import MobileDetect from "mobile-detect";
import React, { Component } from "react";
import { openUrl, loadUrlRedirect } from "app/NativeActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
const queryString = require("query-string");

class PromoRedirect extends Component {
  componentDidMount() {
    const redirect_slug = this.props.match?.params?.redirect_slug;

    const parsed = queryString.parse(this.props.location.search);

    loadUrlRedirect(redirect_slug)
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            const contentful_content = data.items[0]
              ? data.items[0].fields
                ? data.items[0].fields
                : null
              : null;
            if (!!contentful_content.url) {
              let url =
                !!this.props.token && !!contentful_content?.user_url
                  ? contentful_content.user_url
                  : contentful_content.url;

              //go through the parsed data and add all params to the end of the url
              if (parsed) {
                Object.keys(parsed).map(key => {
                  if (url.includes("?")) {
                    url += "&" + key + "=" + parsed[key];
                  } else {
                    url += "?" + key + "=" + parsed[key];
                  }
                });
              }

              openUrl(url, true);
            } else {
              openUrl("https://dealmachine.com", true);
            }
          } else {
            openUrl("https://dealmachine.com", true);
          }
        } else {
          openUrl("https://dealmachine.com", true);
        }
      })
      .catch(err => {
        openUrl("https://dealmachine.com", true);
      });
  }

  render() {
    return null;
  }
}
const mapStateToProps = ({ auth, marketing }) => {
  const { init, user, token } = auth;
  const { user_info } = marketing;

  return { init, user, token, user_info };
};

export default withRouter(connect(mapStateToProps, {})(PromoRedirect));
