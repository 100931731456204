import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Bold,
  Container,
  Copy,
  Icon,
  KeyboardView,
  Row,
  Title,
  Wrapper,
  Spin
} from "app/NativeComponents/common";
import {
  pushSidePanel,
  replaceSidePanel,
  changeTab,
  numberWithCommas,
  getTeam,
  getDialerStats,
  updateUserSettings
} from "app/NativeActions";

import MainHeader from "app/DealMachineCore/reuseable/MainHeader";
import {
  DateRangePicker,
  InlineButton,
  InlineTabs,
  PopoverMenu,
  SearchBar,
  SelectItem,
  SpinWrapper
} from "app/NativeComponents/snippets";

import OutgoingActivity from "./OutgoingActivity";
import IncomingActivity from "./IncomingActivity";

import CallSessions from "./CallSessions";
import FollowUpQueue from "./FollowUpQueue";

import moment from "moment";
import CurrentCallSession from "./CurrentCallSession";

class Dialer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stats_loading: false,
      activity_loading: false,
      call_sessions_loading: false,
      activity_tab: "outgoing_log",
      team_search: "",
      filter_user: props.user?.user_settings?.dialer_stats_settings
        ? props.user?.user_settings?.dialer_stats_settings.filter_user
        : null,
      filter_date: props.user?.user_settings?.dialer_stats_settings
        ? props.user?.user_settings?.dialer_stats_settings.filter_date
        : "this_month",
      filter_start_date: props.user?.user_settings?.dialer_stats_settings
        ? props.user?.user_settings?.dialer_stats_settings.filter_start_date
        : null,
      filter_end_date: props.user?.user_settings?.dialer_stats_settings
        ? props.user?.user_settings?.dialer_stats_settings.filter_end_date
        : null,
      show_team_filter: false,
      show_date_filter: false,
      dialer_stats: {
        total_calls: 0,
        total_leads: 0,
        total_seconds: 0,
        contact_rate: 0,
        connected_calls: 0,
        warm_leads: 0,
        hot_leads: 0,
        appointment_set: 0,
        left_voicemail: 0,
        not_interested: 0,
        disconnected: 0,
        wrong_number: 0,
        wrong_contact: 0,
        no_answer: 0,
        skipped_calls: 0,
        unqualified: 0,
        call_time: null
      },
      date_options: {
        today: {
          label: "Today",
          value: "today"
        },
        yesterday: {
          label: "Yesterday",
          value: "yesterday"
        },
        this_week: {
          label: "This Week",
          value: "this_week"
        },
        last_week: {
          label: "Last Week",
          value: "last_week"
        },
        this_month: {
          label: "This Month",
          value: "this_month"
        },
        last_month: {
          label: "Last Month",
          value: "last_month"
        },
        last_30_days: {
          label: "Last 30 Days",
          value: "last_30_days"
        },
        this_quarter: {
          label: "This Quarter",
          value: "this_quarter"
        },
        last_quarter: {
          label: "Last Quarter",
          value: "last_quarter"
        },
        this_year: {
          label: "This Year",
          value: "this_year"
        },

        last_year: {
          label: "Last Year",
          value: "last_year"
        },

        custom: {
          label: "Select Date Range",
          value: "custom"
        }
      },
      isCalendarOpen: false
    };

    this.updateMainState = this.updateMainState.bind(this);
    this.toggleCalendar = this.toggleCalendar.bind(this);
  }

  componentDidMount() {
    if (this.props.device == "mobile") {
      this.props.changeTab("dialer");
    }

    if (!this.props.team_loading) {
      this.props.getTeam({ token: this.props.token, type: "load" });
    }

    this.getDialerStats();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.filter_date !== this.state.filter_date ||
      prevState.filter_start_date !== this.state.filter_start_date ||
      prevState.filter_end_date !== this.state.filter_end_date ||
      prevState.filter_user !== this.state.filter_user
    ) {
      this.getDialerStats();

      this.props.updateUserSettings({
        token: this.props.token,
        type: "dialer_stats_settings",
        value: {
          filter_date: this.state.filter_date,
          filter_start_date: this.state.filter_start_date,
          filter_end_date: this.state.filter_end_date,
          filter_user: this.state.filter_user
            ? {
                id: this.state.filter_user?.id,
                firstname: this.state.filter_user?.firstname,
                lastname: this.state.filter_user?.lastname,
                email: this.state.filter_user?.email
              }
            : null
        }
      });
    }
  }

  toggleCalendar() {
    this.setState(prevState => {
      return { isCalendarOpen: !prevState.isCalendarOpen };
    });
  }

  getDialerStats() {
    if (
      !this.state.stats_loading &&
      !this.state.activity_loading &&
      !this.state.call_sessions_loading
    ) {
      this.props.getDialerStats({
        token: this.props.token,
        type: "get_dialer_stats",
        filter_date: this.state.filter_date,
        filter_start_date: this.state.filter_start_date,
        filter_end_date: this.state.filter_end_date,
        filter_user: this.state.filter_user ? this.state.filter_user?.id : null,
        utc_offset: moment().utcOffset(),
        timezone: moment.tz.guess(),
        onLoading: () => {
          this.setState({
            stats_loading: true,
            dialer_stats: {
              total_calls: 0,
              total_leads: 0,
              total_seconds: 0,
              contact_rate: 0,
              connected_calls: 0,
              warm_leads: 0,
              hot_leads: 0,
              appointment_set: 0,
              left_voicemail: 0,
              not_interested: 0,
              disconnected: 0,
              wrong_number: 0,
              wrong_contact: 0,
              no_answer: 0,
              skipped_calls: 0,
              unqualified: 0,
              call_time: null
            }
          });
        },
        onError: () => {
          this.setState({ stats_loading: false });
        },
        onSuccess: results => {
          this.setState({
            stats_loading: false,
            dialer_stats: {
              total_calls: parseInt(results.total_calls),
              total_leads: parseInt(results.total_leads),
              total_seconds: parseInt(results.total_seconds),
              contact_rate: parseFloat(results.contact_rate),
              connected_calls: parseInt(results.connected_calls),
              warm_leads: parseInt(results.warm_leads),
              hot_leads: parseInt(results.hot_leads),
              appointment_set: parseInt(results.appointment_set),
              left_voicemail: parseInt(results.left_voicemail),
              not_interested: parseInt(results.not_interested),
              disconnected: parseInt(results.disconnected),
              wrong_number: parseInt(results.wrong_number),
              wrong_contact: parseInt(results.wrong_contact),
              skipped_calls: parseInt(results.skipped_calls),
              unqualified: parseInt(results.unqualified),
              no_answer: parseInt(results.no_answer)
            }
          });
        }
      });
    }
  }

  updateMainState({ prop, value }) {
    this.setState({ [prop]: value });
  }

  render() {
    const { colors, isMobile, user } = this.props;
    const { dialer_stats } = this.state;
    if (!user) {
      return null;
    }

    return (
      <Container
        fill={true}
        style={{
          display: "flex",
          overflow: "hidden",
          backgroundColor: colors.card_color
        }}
        title={"Dialer"}
      >
        <Row style={{ flex: 1, overflow: "hidden" }}>
          <Wrapper
            style={{ flex: 1, alignSelf: "stretch", position: "relative" }}
          >
            <MainHeader title={"Dialer"} />

            {this.props.device === "mobile" ? <CurrentCallSession /> : null}

            <Row style={{ flexWrap: "wrap" }}>
              <PopoverMenu
                show={this.state.show_team_filter}
                no_swipe={true}
                no_cancel={true}
                onShow={s => {
                  this.setState({
                    show_team_filter: s
                  });
                }}
                popover_width={400}
                popoverSheetTop={"80%"}
                popoverPlacement={"bottom"}
                popover_title={"Filter by Team"}
                includeCloseButton={true}
                disabled={
                  this.state.stats_loading ||
                  this.props.team_loading ||
                  this.state.activity_loading ||
                  this.state.call_sessions_loading
                }
                renderComponent={options => {
                  return (
                    <InlineButton
                      icon_right={
                        this.state.show_team_filter
                          ? "keyboard-arrow-up"
                          : "keyboard-arrow-down"
                      }
                      noPress={true}
                      pressedIn={
                        options.hovering ||
                        options.pressedIn ||
                        this.state.show_team_filter
                      }
                      disabled={
                        this.state.stats_loading ||
                        this.props.team_loading ||
                        this.state.activity_loading ||
                        this.state.call_sessions_loading
                      }
                    >
                      {!this.state.filter_user
                        ? "Showing All Calls"
                        : this.state.filter_user?.id == this.props.user.id
                        ? "Showing My Calls"
                        : "Showing Calls By " +
                          (this.state.filter_user?.firstname &&
                          this.state.filter_user?.lastname
                            ? this.state.filter_user?.firstname +
                              " " +
                              this.state.filter_user?.lastname
                            : this.state.filter_user?.email)}
                    </InlineButton>
                  );
                }}
                renderMenu={() => {
                  return (
                    <Wrapper style={{ alignSelf: "stretch" }}>
                      <SearchBar
                        title={"Search Team"}
                        onChange={value => {
                          this.setState({
                            team_search: value
                          });
                        }}
                        value={this.state.team_search}
                        style={{
                          margin: 10
                        }}
                      />
                      <KeyboardView
                        style={
                          this.props.device == "desktop"
                            ? {
                                maxHeight: 300,
                                alignSelf: "stretch"
                              }
                            : {
                                alignSelf: "stretch"
                              }
                        }
                      >
                        {!this.state.team_search ||
                        "My Calls"
                          .toLowerCase()
                          .includes(this.state.team_search.toLowerCase()) ? (
                          <SelectItem
                            select_type="radio"
                            selected={
                              this.state.filter_user?.id == this.props.user?.id
                            }
                            onPress={() => {
                              this.setState({
                                filter_user: this.props.user,
                                show_team_filter: false
                              });
                            }}
                          >
                            My Calls
                          </SelectItem>
                        ) : null}
                        {!this.state.team_search ||
                        "All Team Calls"
                          .toLowerCase()
                          .includes(this.state.team_search.toLowerCase()) ? (
                          <SelectItem
                            select_type="radio"
                            selected={!this.state.filter_user}
                            onPress={() => {
                              this.setState({
                                filter_user: null,
                                show_team_filter: false
                              });
                            }}
                          >
                            All Team Calls
                          </SelectItem>
                        ) : null}

                        {this.props.my_team &&
                          this.props.my_team.map((team_member, i) => {
                            const name =
                              team_member.firstname && team_member.lastname
                                ? team_member.firstname +
                                  " " +
                                  team_member.lastname
                                : team_member?.email;
                            if (
                              team_member.id != this.props.user.id &&
                              (!this.state.team_search ||
                                name
                                  .toLowerCase()
                                  .includes(
                                    this.state.team_search.toLowerCase()
                                  ))
                            ) {
                              return (
                                <SelectItem
                                  select_type="radio"
                                  selected={
                                    this.state.filter_user?.id == team_member.id
                                  }
                                  onPress={() => {
                                    this.setState({
                                      filter_user: team_member,
                                      show_team_filter: false
                                    });
                                  }}
                                >
                                  {name}
                                </SelectItem>
                              );
                            }

                            return null;
                          })}
                      </KeyboardView>
                    </Wrapper>
                  );
                }}
                menu_items={null}
              />
              <Wrapper style={{ margin: 5 }}>
                {
                  //determine text based on the last time frame
                  this.state.date_options[this.state.filter_date].label ==
                    "Today" ||
                  this.state.date_options[this.state.filter_date].label ==
                    "Yesterday" ||
                  this.state.date_options[this.state.filter_date].label ==
                    "This Week" ||
                  this.state.date_options[this.state.filter_date].label ==
                    "Last Week" ||
                  this.state.date_options[this.state.filter_date].label ==
                    "This Month" ||
                  this.state.date_options[this.state.filter_date].label ==
                    "This Quarter" ||
                  this.state.date_options[this.state.filter_date].label ==
                    "This Year" ||
                  this.state.date_options[this.state.filter_date].label ==
                    "Last Year" ||
                  this.state.date_options[this.state.filter_date].label ==
                    "Last Month" ||
                  this.state.date_options[this.state.filter_date].label ==
                    "Last Quarter" ||
                  this.state.date_options[this.state.filter_date].label ==
                    "Select Date Range" ? (
                    <Copy>made</Copy>
                  ) : (
                    <Copy>made in the</Copy>
                  )
                }
              </Wrapper>
              <PopoverMenu
                show={this.state.show_date_filter}
                disabled={
                  this.state.stats_loading ||
                  this.state.activity_loading ||
                  this.state.call_sessions_loading
                }
                no_swipe={true}
                no_cancel={true}
                onShow={s => {
                  this.setState({
                    show_date_filter: s
                  });
                }}
                popover_width={400}
                popoverSheetTop={"80%"}
                popoverPlacement={"bottom"}
                popover_title={"Filter by Date"}
                includeCloseButton={true}
                renderComponent={options => {
                  return (
                    <InlineButton
                      icon_right={
                        this.state.show_date_filter
                          ? "keyboard-arrow-up"
                          : "keyboard-arrow-down"
                      }
                      noPress={true}
                      disabled={
                        this.state.stats_loading ||
                        this.state.activity_loading ||
                        this.state.call_sessions_loading
                      }
                      pressedIn={
                        options.hovering ||
                        options.pressedIn ||
                        this.state.show_date_filter
                      }
                    >
                      {this.state.filter_date === "custom"
                        ? moment(this.state.filter_start_date).format(
                            "MMM Do"
                          ) +
                          " - " +
                          moment(this.state.filter_end_date).format("MMM Do")
                        : this.state.date_options[this.state.filter_date].label}
                    </InlineButton>
                  );
                }}
                renderMenu={() => {
                  return (
                    <Wrapper style={{ alignSelf: "stretch" }}>
                      <KeyboardView
                        style={
                          this.props.device == "desktop"
                            ? {
                                maxHeight: 300,
                                alignSelf: "stretch"
                              }
                            : {
                                alignSelf: "stretch"
                              }
                        }
                      >
                        {Object.keys(this.state.date_options).map(
                          (option, i) => {
                            return (
                              <SelectItem
                                key={"date_filter" + i}
                                select_type={
                                  option === "custom" ? "icon" : "radio"
                                }
                                select_icon="date-range"
                                selected={this.state.filter_date == option}
                                onPress={() => {
                                  if (option === "custom") {
                                    this.setState(
                                      {
                                        show_date_filter: false
                                      },
                                      () => {
                                        this.toggleCalendar();
                                      }
                                    );
                                  } else {
                                    this.setState({
                                      filter_date: option,
                                      show_date_filter: false
                                    });
                                  }
                                }}
                                description={
                                  option === "custom" &&
                                  this.state.filter_date == option
                                    ? moment(
                                        this.state.filter_start_date
                                      ).format("MMM Do") +
                                      " - " +
                                      moment(this.state.filter_end_date).format(
                                        "MMM Do"
                                      )
                                    : null
                                }
                              >
                                {this.state.date_options[option].label}
                              </SelectItem>
                            );
                          }
                        )}
                      </KeyboardView>
                    </Wrapper>
                  );
                }}
                menu_items={null}
              />
              {this.state.stats_loading ||
              this.state.activity_loading ||
              this.state.call_sessions_loading ? (
                <Wrapper>
                  <Spin size={"small"} />
                </Wrapper>
              ) : null}
            </Row>

            <KeyboardView style={{ flex: 1, maxWidth: "100%" }}>
              <Wrapper
                style={
                  this.props.device === "desktop"
                    ? { marginBottom: 40, paddingRight: 25 }
                    : {}
                }
              >
                <Wrapper style={{ maxWidth: 900 }}>
                  <>
                    {this.props.device === "desktop" ? (
                      <>
                        <Row>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Total Calls Made:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.total_calls
                                  ? numberWithCommas(dialer_stats.total_calls)
                                  : "--"}
                              </Bold>
                            </Title>

                            {!!dialer_stats.total_leads ? (
                              <Copy
                                style={{
                                  fontSize: 12,
                                  color: colors.light_text_color,
                                  marginTop: 5
                                }}
                              >
                                Prospected{" "}
                                {numberWithCommas(dialer_stats.total_leads)}{" "}
                                properties
                              </Copy>
                            ) : (
                              <Copy
                                style={{
                                  fontSize: 12,
                                  color: colors.light_text_color,
                                  marginTop: 5
                                }}
                              ></Copy>
                            )}
                          </Wrapper>

                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Total Connections:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.connected_calls
                                  ? numberWithCommas(
                                      dialer_stats.connected_calls
                                    )
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>

                          <Wrapper
                            style={{
                              flex: 1,
                              padding: 25,
                              backgroundColor: colors.active_color_muted,
                              borderRadius: 5
                            }}
                          >
                            <Row>
                              <Copy>Contact Rate:</Copy>

                              <Wrapper
                                tooltipPlacement={"top"}
                                tooltip={
                                  "The contact rate is the number of calls that were answered by a person divided by the total number of prospected properties."
                                }
                                style={{ marginLeft: 5 }}
                              >
                                <Icon
                                  icon={"help"}
                                  color={colors.light_text_color}
                                  size={12}
                                />
                              </Wrapper>
                            </Row>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.contact_rate
                                  ? dialer_stats.contact_rate + "%"
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                        </Row>

                        <Row>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Appointment Set:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.appointment_set
                                  ? numberWithCommas(
                                      dialer_stats.appointment_set
                                    )
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Hot Leads:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.hot_leads
                                  ? numberWithCommas(dialer_stats.hot_leads)
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>

                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Warm Leads:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.warm_leads
                                  ? numberWithCommas(dialer_stats.warm_leads)
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                        </Row>

                        <Row>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Left Voicemail:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.left_voicemail
                                  ? numberWithCommas(
                                      dialer_stats.left_voicemail
                                    )
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>

                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>No Answer:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.no_answer
                                  ? numberWithCommas(dialer_stats.no_answer)
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Not Interested:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.not_interested
                                  ? numberWithCommas(
                                      dialer_stats.not_interested
                                    )
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                        </Row>

                        <Row>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Unqualified:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.unqualified
                                  ? numberWithCommas(dialer_stats.unqualified)
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Disconnected/Wrong Number/Contact:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.wrong_number ||
                                !!dialer_stats.wrong_contact ||
                                !!dialer_stats.disconnected
                                  ? numberWithCommas(
                                      dialer_stats.wrong_number +
                                        dialer_stats.wrong_contact +
                                        dialer_stats.disconnected
                                    )
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Skipped:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.skipped_calls
                                  ? numberWithCommas(dialer_stats.skipped_calls)
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                        </Row>
                      </>
                    ) : (
                      <>
                        <Row>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Total Calls Made:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.total_calls
                                  ? numberWithCommas(dialer_stats.total_calls)
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Properties Prospected:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.total_leads
                                  ? numberWithCommas(dialer_stats.total_leads)
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                        </Row>
                        <Row>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Total Connections:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.connected_calls
                                  ? numberWithCommas(
                                      dialer_stats.connected_calls
                                    )
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                          <Wrapper
                            style={{
                              flex: 1,
                              padding: 25,
                              backgroundColor: colors.active_color_muted,
                              borderRadius: 5,
                              marginRight: 5
                            }}
                          >
                            <Row>
                              <Copy>Contact Rate:</Copy>

                              {/*
                              <Wrapper
                                tooltipPlacement={"top"}
                                tooltip={
                                  "The contact rate is the number of calls that were answered by a person divided by the total number of prospected properties."
                                }
                                style={{ marginLeft: 5 }}
                              >
                                <Icon
                                  icon={"help"}
                                  color={colors.light_text_color}
                                  size={12}
                                />
                              </Wrapper>
                              */}
                            </Row>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.contact_rate
                                  ? dialer_stats.contact_rate + "%"
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                        </Row>

                        <Row>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Appointment Set:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.appointment_set
                                  ? numberWithCommas(
                                      dialer_stats.appointment_set
                                    )
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Hot Leads:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.hot_leads
                                  ? numberWithCommas(dialer_stats.hot_leads)
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                        </Row>

                        <Row>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Warm Leads:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.warm_leads
                                  ? numberWithCommas(dialer_stats.warm_leads)
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Left Voicemail:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.left_voicemail
                                  ? numberWithCommas(
                                      dialer_stats.left_voicemail
                                    )
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                        </Row>
                        <Row>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>No Answer:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.no_answer
                                  ? numberWithCommas(dialer_stats.no_answer)
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Not Interested:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.not_interested
                                  ? numberWithCommas(
                                      dialer_stats.not_interested
                                    )
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                        </Row>

                        <Row>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Unqualified:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.unqualified
                                  ? numberWithCommas(dialer_stats.unqualified)
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Wrong Number/Contact:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.wrong_number ||
                                !!dialer_stats.wrong_contact
                                  ? numberWithCommas(
                                      dialer_stats.wrong_number +
                                        dialer_stats.wrong_contact
                                    )
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                        </Row>

                        <Row>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Disconnected:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.disconnected
                                  ? numberWithCommas(dialer_stats.disconnected)
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                          <Wrapper style={{ flex: 1, padding: 25 }}>
                            <Copy>Skipped:</Copy>
                            <Title
                              style={{
                                color: colors.active_color,
                                fontSize: 28,
                                marginTop: 10
                              }}
                            >
                              <Bold>
                                {!!dialer_stats.skipped_calls
                                  ? numberWithCommas(dialer_stats.skipped_calls)
                                  : "--"}
                              </Bold>
                            </Title>
                          </Wrapper>
                        </Row>
                      </>
                    )}
                  </>
                </Wrapper>
              </Wrapper>
              <InlineTabs
                tab_type="underline"
                selected_tab={this.state.activity_tab}
                tabs={[
                  {
                    onPress: () => {
                      this.setState({
                        activity_tab: "outgoing_log",
                        activity_loading: false,
                        call_sessions_loading: false
                      });
                    },
                    slug: "outgoing_log",
                    title:
                      this.props.device === "desktop"
                        ? "Outgoing Activity"
                        : "Outgoing"
                  },
                  {
                    onPress: () => {
                      this.setState({
                        activity_tab: "incoming_log",
                        activity_loading: false,
                        call_sessions_loading: false
                      });
                    },
                    slug: "incoming_log",
                    title:
                      this.props.device === "desktop"
                        ? "Incoming Activity"
                        : "Incoming"
                  },
                  {
                    onPress: () => {
                      this.setState({
                        activity_tab: "call_sessions",
                        activity_loading: false,
                        call_sessions_loading: false
                      });
                    },
                    slug: "call_sessions",
                    title:
                      this.props.device === "desktop"
                        ? "Dialer Sessions"
                        : "Sessions"
                  }
                ]}
              />
              {this.state.activity_tab == "outgoing_log" ? (
                <OutgoingActivity
                  {...this.props}
                  filter_date={this.state.filter_date}
                  filter_start_date={this.state.filter_start_date}
                  filter_end_date={this.state.filter_end_date}
                  filter_user={this.state.filter_user}
                  activity_loading={this.state.activity_loading}
                  updateMainState={this.updateMainState}
                />
              ) : null}

              {this.state.activity_tab == "incoming_log" ? (
                <IncomingActivity
                  {...this.props}
                  filter_date={this.state.filter_date}
                  filter_start_date={this.state.filter_start_date}
                  filter_end_date={this.state.filter_end_date}
                  filter_user={this.state.filter_user}
                  activity_loading={this.state.activity_loading}
                  updateMainState={this.updateMainState}
                />
              ) : null}

              {this.state.activity_tab == "call_sessions" ? (
                <CallSessions
                  {...this.props}
                  filter_date={this.state.filter_date}
                  filter_start_date={this.state.filter_start_date}
                  filter_end_date={this.state.filter_end_date}
                  filter_user={this.state.filter_user}
                  call_sessions_loading={this.state.call_sessions_loading}
                  updateMainState={this.updateMainState}
                />
              ) : null}
            </KeyboardView>
          </Wrapper>
          {this.props.device === "desktop" ? (
            <Wrapper
              style={{
                width: 500,
                alignSelf: "stretch",
                borderLeftWidth: 1,
                borderLeftStyle: "solid",
                borderLeftColor: colors.border_color,
                position: "relative"
              }}
            >
              <FollowUpQueue
                {...this.props}
                side_panel={true}
                updateMainState={this.updateMainState}
              />
            </Wrapper>
          ) : null}
        </Row>

        <DateRangePicker
          {...this.props}
          isCalendarOpen={this.state.isCalendarOpen}
          toggleCalendar={this.toggleCalendar}
          selectDates={({ start_date, end_date }) => {
            if (start_date != null && end_date != null) {
              this.setState({
                filter_date: "custom",
                filter_start_date: start_date,
                filter_end_date: end_date
              });
            }
          }}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, team }) => {
  const { token, user } = auth;
  const { device, window_height, isMobile } = native;
  const { colors, dark_mode } = settings;
  const { team_members_loading, my_team } = team;

  return {
    token,
    user,
    device,
    isMobile,
    window_height,
    colors,
    dark_mode,
    team_members_loading,
    my_team
  };
};

export default connect(mapStateToProps, {
  pushSidePanel,
  replaceSidePanel,

  changeTab,
  getTeam,
  getDialerStats,
  updateUserSettings
})(Dialer);
