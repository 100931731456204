import React, { PureComponent } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Row,
  Copy,
  Bold,
  Spin,
  Gradient,
  AlmaOrb,
  Scroll,
  Button,
  KeyboardInputHelper,
  Animation,
  KeyboardView
} from "app/NativeComponents/common";
import {
  PullUpSheet,
  InputBox,
  IconButton,
  InlineButton,
  CloseButton,
  PopoverMenu,
  SpinWrapper,
  UpsellButton,
  ConfirmInlineButton
} from "app/NativeComponents/snippets";
import {
  getChatSystemMessages,
  getDefaultCompsArray,
  sendMessageToOpenAI,
  getComps,
  getChatPrompts,
  getAlmaChat,
  updateAlmaChat,
  showErrorMessage,
  dismissAlma,
  dismissMobileKeyboard,
  renderPrice,
  triggerHapticFeedback,
  determineDisplayProperty,
  openUrl,
  setModal,
  toggleModal,
  showSuccess,
  stopOpenAI,
  updateLead,
  addDeal,
  pushSidePanel,
  checkIfUserHasMetadata
} from "app/NativeActions";

import Message from "./Message";
import SuggestedPrompts from "./SuggestedPrompts";
import HeaderButton from "app/DealMachineCore/native/GlobalSnippets/NewHeader/HeaderButton";

class Alma extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      prompt_message: "",
      first_message: "",
      loading: false,
      loaded_prompts: false,
      loaded_system: false,
      chat_rendering: false,
      newest_result: "",
      messages: [],
      openai_messages: [],
      system_messages: [],
      property_data_for_openai: props.property_data_for_openai
        ? props.property_data_for_openai
        : {},
      prompts: [],
      current_parent: null,
      current_parent_children_prompts: [],
      chat_id: null,
      pressed_one_prompt: false,
      clearing: false,
      slideup_alma_handle_height: 1,
      programmic_scroll: false,
      user_dragged_scroll: false,
      loaded_system_messages: false
    };

    this.getActiveChat = this.getActiveChat.bind(this);
    this.saveAlmaChat = this.saveAlmaChat.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.onSelectSuggestedPrompt = this.onSelectSuggestedPrompt.bind(this);
    this.cancelPromptParent = this.cancelPromptParent.bind(this);
    this.clearChat = this.clearChat.bind(this);

    this._bottomSheetRef = React.createRef();
    this._message_ref = React.createRef();

    this._message_scroll_view = React.createRef();

    this.getSystemMessages = this.getSystemMessages.bind(this);

    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.renderTopButtons = this.renderTopButtons.bind(this);
    this.updateCurrentChat = this.updateCurrentChat.bind(this);
    this.regenerateLastResponse = this.regenerateLastResponse.bind(this);

    this.getLastMessageFollowUpSlugs =
      this.getLastMessageFollowUpSlugs.bind(this);
  }

  componentDidMount() {
    //get system messages
    this.getSystemMessages();
    this.getChatPrompts();

    if (this.props.onboarding) {
      this.clearChat();
    } else {
      this.getActiveChat();
    }
  }

  scrollToBottom() {
    if (!this.state.user_dragged_scroll) {
      const { device } = this.props;
      if (
        device === "desktop" &&
        this._message_scroll_view &&
        this._message_scroll_view.current
      ) {
        this._message_scroll_view.current.scrollTop =
          this._message_scroll_view.current.scrollHeight + 20;
      } else if (
        device === "mobile" &&
        this._message_scroll_view &&
        this._message_scroll_view.current &&
        this._message_scroll_view.current.scrollToEnd
      ) {
        this._message_scroll_view.current.scrollToEnd({ animated: true });
      }
    }

    if (this.props.onScrollToBottom) {
      this.props.onScrollToBottom();
    }
  }

  estimateOpenAITokens(text) {
    // Define a regex pattern to match whitespace, punctuation, and word characters
    if (!!text) {
      const tokenPattern = /(\s+|\p{P}|\w+)/gu;

      // Split the input text using the regex pattern
      const tokens = text.match(tokenPattern);

      // Return the length of the tokens array as an estimate of the OpenAI token count

      return tokens ? tokens.length : 0;
    }
    return 0;
  }

  clearChat() {
    if (!this.state.clearing && !this.state.chat_rendering) {
      const display_property = determineDisplayProperty(this.props.property);

      this.props.updateAlmaChat({
        token: this.props.token,
        type: "clear_chat",
        property_id: this.props.property?.property_id,
        property_address: display_property?.property_address,
        chat_type: "property",
        chat_id: this.state.chat_id,
        onLoading: () => {
          this.setState({
            clearing: true
          });
        },
        onError: () => {
          this.setState({
            clearing: false
          });
        },
        onSuccess: results => {
          this.setState(
            {
              clearing: false,
              chat_id: results?.chat?.id,
              first_message: results?.chat.first_message,
              openai_messages: [],
              messages: [],
              pressed_one_prompt: false
            },
            () => {}
          );
        }
      });
    }
  }

  saveAlmaChat({
    role,
    display_content,
    content,
    messages_json,
    prompt_slug,
    answer_group,
    answer_slug,
    follow_up_slug,
    onSuccess = null,
    rerender = true
  }) {
    this.props.updateAlmaChat({
      token: this.props.token,
      type: "save_chat_message",
      role,
      display_content,
      content,
      messages_json: messages_json ? JSON.stringify(messages_json) : null,
      prompt_slug,
      property_id: this.props.property?.property_id,
      chat_type: "property",
      chat_id: this.state.chat_id,
      answer_group,
      answer_slug,
      follow_up_slug,
      onLoading: () => {},
      onError: () => {},
      onSuccess: results => {
        if (onSuccess) {
          onSuccess();
        }
        if (rerender) {
          this.updateCurrentChat(results?.chat, results?.messages);
        }
      }
    });
  }

  updateCurrentChat(chat, messages) {
    if (chat) {
      let om = [];

      if (messages) {
        for (let i = 0; i < messages.length; i++) {
          if (messages[i].role === "assistant" || messages[i].role === "user") {
            if (messages[i].messages && messages[i].messages?.length > 0) {
              for (let j = 0; j < messages[i].messages?.length; j++) {
                om.push({
                  role: messages[i].messages[j].role,
                  content: messages[i].messages[j].content
                });
              }
            } else {
              om.push({
                role: messages[i].role,
                content: messages[i].content
              });
            }
          }
        }
      }
      this.setState({
        chat_id: chat?.id,
        first_message: chat?.first_message,
        messages: messages || [],
        openai_messages: [...this.state.openai_messages, ...om]
      });
    }
  }

  getActiveChat() {
    const display_property = determineDisplayProperty(this.props.property);

    this.setState(
      {
        chat_id: null,
        first_message: "",
        messages: [],
        openai_messages: []
      },
      () => {
        this.props.getAlmaChat({
          token: this.props.token,
          chat_type: "property",
          property_id: this.props.property?.property_id,
          property_address: display_property?.property_address,
          onLoading: () => {},
          onError: () => {},
          onSuccess: results => {
            this.updateCurrentChat(results?.chat, results?.messages);
          }
        });
      }
    );
  }

  getChatPrompts() {
    this.setState({
      prompts: [],
      pressed_one_prompt: false
    });
    this.props.getChatPrompts({
      token: this.props.token,
      chat_type: "property",
      property_id: this.props.property?.property_id,
      onLoading: () => {},
      onError: () => {},
      onSuccess: results => {
        this.setState({
          prompts: [...this.state.prompts, ...results.prompts]
        });
      }
    });
  }

  getSystemMessages() {
    //welcome message
    this.props.getChatSystemMessages({
      token: this.props.token,
      chat_type: "property",
      message_type: this.props.onboarding ? "property_system_only" : "property",
      property_id: this.props.property?.property_id,
      onLoading: () => {},
      onError: () => {
        this.setState({
          loaded_system_messages: true
        });
      },
      onSuccess: results => {
        this.setState(
          {
            system_messages: results?.messages
              ? [...this.state.system_messages, ...results.messages]
              : this.state.system_messages,
            property_data_for_openai: results?.property_data
              ? {
                  ...this.state.property_data_for_openai,
                  ...results.property_data
                }
              : this.props.property_data_for_openai
              ? this.props.property_data_for_openai
              : this.state.property_data_for_openai,
            loaded_system_messages: true
          },
          () => {
            this.props.getChatSystemMessages({
              token: this.props.token,
              chat_type: "property",
              message_type: "estimated_rent_cost",
              property_id: this.props.property?.property_id,

              onLoading: () => {},
              onError: () => {},
              onSuccess: results => {
                this.setState({
                  system_messages: results?.messages
                    ? [...this.state.system_messages, ...results.messages]
                    : this.state.system_messages,
                  property_data_for_openai: results?.property_data
                    ? {
                        ...this.state.property_data_for_openai,
                        ...results.property_data
                      }
                    : this.props.property_data_for_openai
                    ? this.props.property_data_for_openai
                    : this.state.property_data_for_openai,
                  loaded_system_messages: true
                });
              }
            });
          }
        );
      }
    });

    //system lead state
    //system comps
    //system owner info
  }
  componentWillUnmount() {
    if (this.state.chat_rendering) {
      this.props.stopOpenAI({ onSuccess: () => {} });
    }
    clearTimeout(this._scrolltimeout);
  }

  componentDidUpdate(prevProps, prevState) {
    //when a property updates or it's a new property get new system data
    if (prevProps.keyboard_showing !== this.props.keyboard_showing) {
      this.setState(
        {
          user_dragged_scroll: false
        },
        () => {
          this.scrollToBottom();
        }
      );
    }

    if (
      this.state.loaded_system_messages &&
      this.state.loaded_system_messages !== prevState.loaded_system_messages &&
      this.props.onSystemMessageLoad
    ) {
      this.props.onSystemMessageLoad();
    }
    if (
      prevProps?.property?.property_id !== this.props?.property?.property_id
    ) {
      if (this.props.show_alma_panel === "show") {
        this.props.updateMainState({
          show_alma_panel: "hide",
          pressed_alma: false
        });
      }

      this.getSystemMessages();
      this.getChatPrompts();
      this.getActiveChat();
    } else if (
      prevProps?.property?.deal?.deal_status_slug !==
      this.props?.property?.deal?.deal_status_slug
    ) {
      this.getChatPrompts();
    }

    if (
      this.props.onboarding_prompt &&
      this.props.onboarding_prompt !== prevProps.onboarding_prompt
    ) {
      this.onSelectSuggestedPrompt({
        content: this.props.onboarding_prompt?.content,
        messages: this.props.onboarding_prompt?.messages,
        display_content: this.props.onboarding_prompt?.display_content,
        openai_model: this.props.onboarding_prompt?.openai_model,
        prompt_slug: this.props.onboarding_prompt?.prompt_slug,
        prompt_type: this.props.onboarding_prompt?.prompt_type,
        assistant_response: this.props.onboarding_prompt?.assistant_response,
        prompt_children: this.props.onboarding_prompt?.children,
        answer_slug: this.props.onboarding_prompt?.answer_slug,
        answer_group: this.props.onboarding_prompt?.answer_group,
        follow_up_slug: this.props.onboarding_prompt?.follow_up_slug
      });
    }

    if (
      (prevProps.show_alma_panel !== this.props.show_alma_panel &&
        this.props.show_alma_panel) ||
      prevState.chat_rendering !== this.state.chat_rendering
    ) {
      this.setState(
        {
          user_dragged_scroll: false
        },
        () => {
          this.scrollToBottom();
        }
      );
    }

    if (this.state.messages?.length !== prevState.messages?.length) {
      clearTimeout(this._scrolltimeout);
      this._scrolltimeout = setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    }
  }
  cancelPromptParent(prompt_slug) {
    this.setState({
      messages: this.state.messages.map(item => ({
        ...item,
        prompt_slug: item.prompt_slug == prompt_slug ? null : item.prompt_slug
      })),
      current_parent: null,
      current_parent_children_prompts: []
    });
  }
  onSelectSuggestedPrompt({
    content,
    messages,
    display_content,
    openai_model,
    prompt_slug,
    prompt_type,
    assistant_response,
    prompt_children,
    answer_slug,
    answer_group,
    follow_up_slug
  }) {
    dismissMobileKeyboard();

    this.props.updateMainState({
      show_alma_panel: "show",
      pressed_alma: true
    });

    this.setState({
      pressed_one_prompt: true
    });

    switch (prompt_type) {
      case "parent":
        const all_messages_inactive = this.state.messages.map(item => ({
          ...item,
          active: false
        }));
        let answer_slug_to_use = null;
        let child_to_use = null;
        if (prompt_children?.length > 0) {
          const current_answer_group = prompt_children[0].answer_group;

          for (let i = 0; i < this.state.messages?.length; i++) {
            if (this.state.messages[i]?.answer_group == current_answer_group) {
              answer_slug_to_use = this.state.messages[i].answer_slug;
            }
          }
        }
        if (!!answer_slug_to_use) {
          for (let i = 0; i < prompt_children?.length; i++) {
            if (prompt_children[i]?.answer_slug == answer_slug_to_use) {
              child_to_use = prompt_children[i];
            }
          }
        }

        if (child_to_use) {
          this.onSelectSuggestedPrompt({
            content: child_to_use?.content,
            display_content: display_content,
            messages: child_to_use?.messages,
            openai_model: child_to_use?.openai_model,
            prompt_slug: child_to_use?.prompt_slug,
            prompt_type: child_to_use?.prompt_type,
            assistant_response: child_to_use?.assistant_response,
            prompt_children: child_to_use?.prompt_children,
            answer_slug: child_to_use?.answer_slug,
            answer_group: child_to_use?.answer_group,
            follow_up_slug: child_to_use?.follow_up_slug
          });
        } else {
          this.setState({
            current_parent: prompt_slug,
            current_parent_children_prompts: prompt_children,
            messages: [
              ...all_messages_inactive,
              {
                role: "user_no_chat",
                display_content,
                content: "",
                prompt_slug,
                user: this.props.user,
                answer_slug,
                answer_group,
                follow_up_slug
              },
              {
                role: "assistant_no_chat",
                display_content: assistant_response,
                content: "",
                active: true,
                typewriter: true
              }
            ],
            prompt_message: "",
            newest_result: ""
          });

          this.saveAlmaChat({
            role: "user_no_chat",
            display_content: display_content,
            content: "",
            prompt_slug,
            answer_slug,
            answer_group,
            follow_up_slug,
            rerender: false,
            onSuccess: () => {
              this.saveAlmaChat({
                role: "assistant_no_chat",
                display_content: assistant_response,
                content: "",
                rerender: false
              });
            }
          });
        }

        //save chats

        break;

      default:
      case "text":
        this.sendMessage({
          content,
          messages,
          display_content,
          openai_model,
          prompt_slug,
          answer_slug,
          answer_group,
          follow_up_slug
        });
        break;
    }
  }

  getLastMessageFollowUpSlugs() {
    //get the last item in messages and get the value of follow_up_slug
    const { messages } = this.state;
    const last_message = messages[messages.length - 1];
    if (
      last_message?.role === "assistant" ||
      last_message?.role === "assistant_no_chat"
    ) {
      return last_message?.follow_up_slug;
    }

    return null;
  }

  regenerateLastResponse() {
    //pop the item from messages and openai_messages
    //use the id from the last item in messages to call updateAlmaChat with the type of delete message.
    //then re-run the openai call
    const { messages, openai_messages } = this.state;
    const last_message = messages[messages.length - 1];
    const new_messages = messages.slice(0, messages.length - 1);
    const new_openai_messages = openai_messages.slice(
      0,
      openai_messages.length - 1
    );
    this.setState({
      messages: new_messages,
      openai_messages: new_openai_messages
    });
    if (last_message?.role === "assistant") {
      this.props.updateAlmaChat({
        token: this.props.token,
        type: "delete_chat_message",
        message_id: last_message?.id,
        onLoading: () => {
          this.setState({
            chat_rendering: true
          });
        },
        onError: () => {},
        onSuccess: results => {
          this.sendMessage({});
        }
      });
    } else {
      this.setState(
        {
          chat_rendering: true
        },
        () => {
          this.sendMessage({});
        }
      );
    }
  }

  renderTopButtons(show_message) {
    const { messages } = this.state;
    const { colors } = this.props;
    return (
      <Wrapper
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0
        }}
      >
        <Row style={{ justifyContent: "space-between" }}>
          <Wrapper style={{ flex: 1 }} />
          <Row>
            {this.props.show_alma_panel === "show" &&
            messages.filter(({ role }) => role !== "system")?.length > 0 ? (
              <PopoverMenu
                style={{ zIndex: 1 }}
                onPress={() => {
                  dismissMobileKeyboard();
                }}
                disabled={this.state.clearing || this.state.chat_rendering}
                renderComponent={({ pressedIn, hovering }) => {
                  return (
                    <HeaderButton
                      buttonContents={{
                        noPress: true,
                        pressedIn,
                        hovering,
                        icon: "more-vert",
                        color: colors.light_text_color,
                        hover_color: colors.hover_color
                      }}
                    />
                  );
                }}
                menu_items={[
                  {
                    title: "Clear Chat",
                    icon: "clear",
                    type: "normal",
                    select_type: "icon",
                    onPress: this.clearChat
                  }
                ]}
              />
            ) : null}
            <HeaderButton
              style={{ zIndex: 1 }}
              buttonContents={{
                onPress: () => {
                  this.props.dismissAlma();
                  this.props.updateMainState({
                    show_alma_panel: "hide",
                    pressed_alma: true
                  });
                  dismissMobileKeyboard();
                },
                icon: "expand-more",
                color: colors.light_text_color,
                hover_color: colors.hover_color
              }}
            />
          </Row>
        </Row>
      </Wrapper>
    );
  }
  sendMessage({
    content = "",
    messages = [],
    display_content = "",
    openai_model = "o3-mini",
    prompt_slug,
    answer_slug,
    answer_group,
    follow_up_slug
  }) {
    const { system_messages, openai_messages } = this.state;
    dismissMobileKeyboard();

    //create for front end (same format as backend output)

    let token_count = 0;
    let first_openai_system_message = {
      role: "system",
      content:
        "You are here to answer questions about the property " +
        this.state.property_data_for_openai["Property Address"] +
        " for a real estate investment. Here is some info about the property: " +
        JSON.stringify(this.state.property_data_for_openai) +
        " You only answer questions about this address. You do not answer questions about other addresses and instead tell the user to navigate to that address."
    };

    token_count += this.estimateOpenAITokens(
      first_openai_system_message?.content
    );
    for (let i = 0; i < system_messages.length; i++) {
      token_count += this.estimateOpenAITokens(system_messages[i]?.content);
    }
    if (messages && messages?.length > 0) {
      for (let i = 0; i < messages.length; i++) {
        token_count += this.estimateOpenAITokens(messages[i]?.content);
      }
    } else if (!!content) {
      token_count += this.estimateOpenAITokens(content);
    }

    //loop through the this.state.openai_messages and add up the tokens for each message and add them to the top of the new_openai_messages array
    // if the tokens are greater than 8000 then stop adding tem to the new_openai_messages array
    let new_openai_messages = [];
    for (let i = 0; i < openai_messages.length; i++) {
      token_count += this.estimateOpenAITokens(openai_messages[i]?.content);
      if (token_count > 6000) {
        break;
      } else {
        new_openai_messages = [...new_openai_messages, openai_messages[i]];
      }
    }

    new_openai_messages =
      messages && messages?.length > 0
        ? [...new_openai_messages, ...messages]
        : !!content
        ? [
            ...new_openai_messages,
            {
              role: "user",
              content: content
            }
          ]
        : new_openai_messages;

    const all_messages_inactive = this.state.messages.map(item => ({
      ...item,
      active: false
    }));
    if (!!content || messages?.length > 0) {
      this.setState({
        messages: [
          ...all_messages_inactive,
          {
            role: "user",
            display_content: !!display_content ? display_content : content,
            content: content,
            messages: messages,
            prompt_slug: !!prompt_slug ? prompt_slug : null,
            user: this.props.user,
            answer_slug,
            answer_group,
            follow_up_slug
          }
        ],
        prompt_message: "",
        newest_result: "",
        chat_rendering: true,
        current_parent: null,
        current_parent_children_prompts: [],
        openai_messages: new_openai_messages,
        pressed_one_prompt: true
      });

      //save message on database
      this.saveAlmaChat({
        role: "user",
        display_content: !!display_content ? display_content : content,
        content: content,
        messages_json: messages && messages?.length > 0 ? messages : null,
        prompt_slug: !!prompt_slug ? prompt_slug : null,
        rerender: false
      });
    }

    this.setState(
      {
        user_dragged_scroll: false
      },
      () => {
        this.scrollToBottom();
      }
    );

    this.props.sendMessageToOpenAI({
      messages: [
        ...system_messages,
        first_openai_system_message,
        ...new_openai_messages
      ],
      model: openai_model,
      onLoading: () => {},
      onUpdate: text => {
        this.setState(prevState => ({
          newest_result: prevState.newest_result + text
        }));
        this.scrollToBottom();
      },
      onError: error => {
        this.setState({
          chat_rendering: false
        });
        if (!!error) {
          this.props.showErrorMessage(error, "Error");
        }
      },
      onSuccess: results => {
        this.setState({
          messages: [
            ...this.state.messages,
            {
              role: "assistant",
              display_content: results,
              content: results,
              active: true,
              follow_up_slug
            }
          ],
          openai_messages: [
            ...this.state.openai_messages,
            {
              role: "assistant",
              content: results
            }
          ],
          newest_result: "",
          chat_rendering: false
        });

        this.saveAlmaChat({
          role: "assistant",
          display_content: results,
          content: results,
          follow_up_slug
        });
      },
      onReadyStateChange: () => {
        this.setState({
          chat_rendering: false
        });
      }
    });
  }

  renderChatMessages({ show_message }) {
    const { colors } = this.props;
    const { first_message, messages, prompt_message, newest_result, chat_id } =
      this.state;

    return (
      <>
        {this.state.clearing ? (
          <SpinWrapper text={"Loading new chat..."} />
        ) : (
          <>
            <Wrapper
              style={
                show_message && this.props.show_alma_panel !== "show"
                  ? {
                      marginTop: this.props.device === "desktop" ? 25 : 15,
                      paddingBottom: this.props.device === "desktop" ? 0 : 25
                    }
                  : {
                      justifyContent: "space-between",
                      flex: 1,
                      marginTop: this.props.device === "desktop" ? 25 : 15,
                      overflow: "hidden",
                      paddingBottom: this.props.device === "desktop" ? 0 : 25
                    }
              }
            >
              <Scroll
                scroll_ref={this._message_scroll_view}
                style={
                  this.props.show_alma_panel === "show"
                    ? {
                        flex: 1
                      }
                    : this.props.device === "desktop"
                    ? { flex: 1 }
                    : {}
                }
                onScroll={() => {}}
                onScrollBeginDrag={() => {
                  dismissMobileKeyboard();
                  this.setState({ user_dragged_scroll: true });
                }}
                onScrollEndDrag={ev => {
                  const {
                    layoutMeasurement, // The height of the visible ScrollView
                    contentOffset, // The current scroll position
                    contentSize // The total height of the content inside the ScrollView
                  } = ev.nativeEvent;

                  // Check if the scroll event has reached the bottom
                  const isAtBottom =
                    layoutMeasurement.height + contentOffset.y >=
                    contentSize.height;

                  if (isAtBottom) {
                    this.setState({ user_dragged_scroll: false });
                  }
                }}
                onLayout={event => {
                  const { x, y, width, height } = event?.nativeEvent?.layout;
                  this.setState({
                    slideup_alma_handle_height: height
                  });
                }}
              >
                <Wrapper
                  style={{
                    flex: 1,
                    marginBottom: this.state.chat_rendering ? 100 : 0
                  }}
                >
                  {!!first_message ? (
                    <Message
                      content={first_message}
                      chat_id={chat_id}
                      typewriter={
                        messages.filter(({ role }) => role !== "system")
                          ?.length == 0
                          ? true
                          : false
                      }
                      role={"assistant_no_chat"}
                      active={
                        messages.filter(({ role }) => role !== "system")
                          ?.length == 0
                      }
                      chat_rendering={this.state.chat_rendering}
                      showSuccess={this.props.showSuccess}
                      updateAlmaChat={this.props.updateAlmaChat}
                    />
                  ) : null}

                  {messages?.length > 0 &&
                    messages.map((message, i) => {
                      if (
                        message.role === "assistant" ||
                        message.role === "user" ||
                        message.role === "assistant_no_chat" ||
                        message.role === "user_no_chat"
                      ) {
                        return (
                          <Message
                            key={"message_" + i}
                            content={message.display_content}
                            typewriter={message?.typewriter}
                            role={message.role}
                            active={
                              (message.role === "assistant" ||
                                message.role === "assistant_no_chat") &&
                              message.active === true
                            }
                            user={message?.user}
                            chat_rendering={this.state.chat_rendering}
                            feedback={message?.feedback}
                            showSuccess={this.props.showSuccess}
                            updateAlmaChat={this.props.updateAlmaChat}
                            message_id={message?.id}
                            token={this.props.token}
                          />
                        );
                      }
                    })}

                  {!!newest_result || this.state.chat_rendering ? (
                    <Message
                      content={newest_result}
                      role={"assistant"}
                      active={true}
                      loading={true}
                      chat_rendering={this.state.chat_rendering}
                      showSuccess={this.props.showSuccess}
                      updateAlmaChat={this.props.updateAlmaChat}
                    />
                  ) : null}
                </Wrapper>
                {this.state.prompts?.length > 0 &&
                (!this.props.onboarding || this.state.current_parent) ? (
                  <Wrapper
                    style={{
                      marginBottom: this.state.current_parent ? 100 : 0
                    }}
                  >
                    <SuggestedPrompts
                      source_of_truth={this.props.source_of_truth}
                      prompts={this.state.prompts}
                      pressed_one_prompt={this.state.pressed_one_prompt}
                      clearChat={this.clearChat}
                      clearing={this.state.clearing}
                      disabled={!this.state.loaded_system_messages}
                      sent_messages={
                        messages.filter(({ role }) => role !== "system")
                          ?.length > 0
                      }
                      current_parent={this.state.current_parent}
                      current_parent_children_prompts={
                        this.state.current_parent_children_prompts
                      }
                      last_message_follow_up_slugs={this.getLastMessageFollowUpSlugs()}
                      property={this.props.property}
                      chat_rendering={this.state.chat_rendering}
                      messages={this.state.messages}
                      onSelectSuggestedPrompt={this.onSelectSuggestedPrompt}
                      cancelPromptParent={this.cancelPromptParent}
                      onDisplay={() => {
                        this.scrollToBottom();
                      }}
                      onboarding={this.props.onboarding}
                      onboardingComplete={this.props.onboardingComplete || null}
                      regenerateLastResponse={this.regenerateLastResponse}
                      addDeal={this.props.addDeal}
                      token={this.props.token}
                      updateLead={this.props.updateLead}
                      current_route={this.props.current_route}
                      device={this.props.device}
                      add_deal_loading={this.props.add_deal_loading}
                      updateMainState={this.props.updateMainState}
                      propertyUpdated={this.props.propertyUpdated}
                      pushSidePanel={this.props.pushSidePanel}
                    />
                  </Wrapper>
                ) : null}
              </Scroll>
            </Wrapper>

            <Wrapper>
              {!this.state.current_parent &&
              (!this.props.onboarding || this.props.device == "desktop") ? (
                <Row
                  style={{
                    alignItems: "flex-start"
                  }}
                >
                  <KeyboardView style={{ flex: 1 }}>
                    <InputBox
                      input_ref={this._message_ref}
                      name="expMonth"
                      returnKeyType="default"
                      placeholder="Send a message"
                      onChange={value => {
                        this.setState({
                          prompt_message: value
                        });
                      }}
                      onFocus={() => {
                        this.setState(
                          {
                            user_dragged_scroll: false
                          },
                          () => {
                            this.scrollToBottom();
                          }
                        );
                        this.props.updateMainState({
                          show_alma_panel: "show"
                        });
                      }}
                      selected_background_color={"transparent"}
                      hover_background_color={"transparent"}
                      onSubmitEditing={
                        this.props.device == "desktop" &&
                        !this.state.chat_rendering &&
                        this.props.source_of_truth?.subscription?.metadata
                          ?.alma &&
                        (!this.props.onboarding ||
                          messages.filter(({ role }) => role !== "system")
                            ?.length > 0)
                          ? () => {
                              this.sendMessage({
                                content: prompt_message
                              });
                            }
                          : () => {}
                      }
                      blurOnSubmit={
                        this.props.device == "desktop" ? true : false
                      }
                      value={prompt_message}
                      input_type="text"
                      type={"multiline"}
                      numberOfLines={2}
                      autogrow={true}
                    />
                  </KeyboardView>

                  {this.state.chat_rendering ? (
                    <ConfirmInlineButton
                      style={{
                        marginTop: this.props.device == "desktop" ? 12 : 20
                      }}
                      confirm_text="Really Stop?"
                      icon={"stop"}
                      onPress={() => {
                        this.props.stopOpenAI({
                          onSuccess: () => {
                            const stopped_message = this.state.newest_result;
                            this.setState({
                              messages: [
                                ...this.state.messages,
                                {
                                  role: "assistant",
                                  display_content: stopped_message,
                                  content: stopped_message,
                                  active: true
                                }
                              ],
                              openai_messages: [
                                ...this.state.openai_messages,
                                {
                                  role: "assistant",
                                  content: stopped_message
                                }
                              ],
                              newest_result: "",
                              chat_rendering: false
                            });

                            this.saveAlmaChat({
                              role: "assistant",
                              display_content: stopped_message,
                              content: stopped_message
                            });
                          }
                        });
                      }}
                    >
                      Stop Responding
                    </ConfirmInlineButton>
                  ) : (
                    <>
                      {!checkIfUserHasMetadata("paid_plan") &&
                      !checkIfUserHasMetadata("alma") &&
                      !this.props.onboarding ? (
                        <>
                          <IconButton
                            style={{
                              marginTop:
                                this.props.device == "desktop" ? 12 : 20
                            }}
                            icon={"send"}
                            icon_color={colors.actionable_text_color}
                            tooltip={
                              this.props.onboarding &&
                              messages.filter(({ role }) => role !== "system")
                                ?.length == 0
                                ? "Let's analyze this property first before chating."
                                : "Send Message"
                            }
                            disabled={
                              !this.state.loaded_system_messages ||
                              !prompt_message ||
                              this.state.chat_rendering ||
                              (this.props.onboarding &&
                                messages.filter(({ role }) => role !== "system")
                                  ?.length == 0)
                            }
                            onPress={() => {
                              this.props.pushSidePanel({
                                slug: "purchase_plan_prompt",
                                overlay_modal: true,
                                locked: true,
                                data: {
                                  title:
                                    "Get Alma the AI Assistant With DealMachine Pro.",
                                  dismiss_button:
                                    "No thanks. Let me continue without Alma the AI Assistant.",
                                  no_free: true,
                                  event_slug: "in_app_alma"
                                },
                                locked: true
                              });
                            }}
                          />
                        </>
                      ) : (
                        <UpsellButton
                          meta_slug="alma"
                          onPress={() => {
                            this.sendMessage({ content: prompt_message });
                          }}
                          force_unlock={this.props.onboarding}
                          renderLockedChildren={({ hovering }) => {
                            return (
                              <IconButton
                                noPress={true}
                                pressedIn={hovering}
                                style={{
                                  marginTop:
                                    this.props.device == "desktop" ? 12 : 20
                                }}
                                icon={"send"}
                                icon_color={colors.actionable_text_color}
                                tooltip={
                                  this.props.onboarding &&
                                  messages.filter(
                                    ({ role }) => role !== "system"
                                  )?.length == 0
                                    ? "Let's analyze this property first before chating."
                                    : "Send Message"
                                }
                                disabled={
                                  !this.state.loaded_system_messages ||
                                  !prompt_message ||
                                  this.state.chat_rendering ||
                                  (this.props.onboarding &&
                                    messages.filter(
                                      ({ role }) => role !== "system"
                                    )?.length == 0)
                                }
                              />
                            );
                          }}
                          renderChildren={({ hovering }) => {
                            return (
                              <IconButton
                                noPress={true}
                                pressedIn={hovering}
                                style={{
                                  marginTop:
                                    this.props.device == "desktop" ? 12 : 20
                                }}
                                icon={"send"}
                                icon_color={colors.actionable_text_color}
                                tooltip={
                                  this.props.onboarding &&
                                  messages.filter(
                                    ({ role }) => role !== "system"
                                  )?.length == 0
                                    ? "Let's analyze this property first before chating."
                                    : "Send Message"
                                }
                                disabled={
                                  !this.state.loaded_system_messages ||
                                  !prompt_message ||
                                  this.state.chat_rendering ||
                                  (this.props.onboarding &&
                                    messages.filter(
                                      ({ role }) => role !== "system"
                                    )?.length == 0)
                                }
                              />
                            );
                          }}
                        />
                      )}
                    </>
                  )}
                </Row>
              ) : null}
            </Wrapper>
          </>
        )}
      </>
    );
  }

  render() {
    const { colors, isMobile, device, property, height } = this.props;
    let top = 0;
    if (device == "desktop") {
      const slideup_alma_handle = document.getElementById(
        "slideup_alma_handle"
      );
      top = this.props.window_height - 150;
    } else {
      top =
        this.props.window_height - this.state.slideup_alma_handle_height - 400;
    }

    const { prompt_message, messages, first_message, newest_result, chat_id } =
      this.state;

    if (chat_id) {
      const hide =
        this.props.show_alma_panel === "hide" ||
        (this.props.show_alma_panel === "default" &&
          messages.filter(({ role }) => role !== "system")?.length > 0);
      const show_message =
        this.props.show_alma_panel === "default" &&
        messages.filter(({ role }) => role !== "system")?.length == 0 &&
        !this.props.dismissed_alma;

      if (this.props.onboarding) {
        return <>{this.renderChatMessages({ show_message: false })}</>;
      }

      return (
        <>
          {hide && !this.props.keyboard_showing ? (
            <Wrapper
              style={
                this.props.expanded
                  ? {
                      padding: this.props.device == "mobile" ? 25 : 15,
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      zIndex: 2
                    }
                  : {
                      padding: this.props.device == "mobile" ? 25 : 15,
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      zIndex: 2
                    }
              }
            >
              <Button
                onPress={() => {
                  triggerHapticFeedback({ type: "impactMedium" });

                  this.props.updateMainState({
                    show_alma_panel: "show"
                  });
                }}
              >
                <AlmaOrb
                  big_orb={true}
                  size={this.props.device == "mobile" ? 50 : 40}
                  active={true}
                />
              </Button>
            </Wrapper>
          ) : null}

          {this.props.show_alma_panel === "show" ? (
            <Animation
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                backgroundColor: "rgba(0,0,0,0.5)"
              }}
              type={"fadeIn"}
              duration={250}
            ></Animation>
          ) : null}
          <PullUpSheet
            sheet_ref={this._bottomSheetRef}
            show={
              this.props.show_alma_panel === "show"
                ? true
                : show_message &&
                  !this.props.pressed_alma &&
                  this.props.device === "mobile"
                ? true
                : false
            }
            comps={false}
            onShow={() => {
              this.props.updateMainState({
                show_alma_panel: "show",
                pressed_alma: true
              });
            }}
            onHide={e => {
              if (this.props.show_alma_panel === "default") {
                this.props.dismissAlma();
              }
              this.props.updateMainState({
                show_alma_panel: "hide",
                pressed_alma: true
              });
            }}
            increase_height_on_keyboard={false}
            scroll_to_hide={false}
            desktop_bottom={show_message ? 360 : 0}
            bottom={1}
            middle={
              show_message ? this.state.slideup_alma_handle_height + 160 : null
            }
            containerStyle={{
              opacity: this.props.show_alma_panel !== "show" ? 0 : 1
            }}
            top={"85%"}
            desktop_top={top}
            noHandle={false}
            enablePanDownToClose={false}
            renderMobileHandle={() => {
              return (
                <Wrapper style={{ position: "relative" }}>
                  <Wrapper
                    style={{
                      borderWidth: 1,
                      backgroundColor: colors.popover_color,
                      borderColor: colors.border_color,
                      borderStyle: "solid",
                      borderTopLeftRadius: 15,
                      borderTopRightRadius: 15,
                      borderBottomWidth: 0,
                      paddingBottom: 0
                    }}
                  >
                    <Wrapper
                      style={{
                        padding: 15,
                        paddingLeft: 200,
                        paddingRight: 200,
                        height: 20,
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <Wrapper
                        style={{
                          height: 8,
                          width: 60,
                          borderRadius: 4,
                          backgroundColor:
                            this.props.dark_mode == "dark_mode"
                              ? colors.card_color
                              : colors.gray_color
                        }}
                      />
                    </Wrapper>
                  </Wrapper>
                  {this.renderTopButtons(show_message)}
                </Wrapper>
              );
            }}
            renderHandle={
              !hide
                ? () => {
                    return (
                      <Wrapper
                        id="slideup_alma_handle"
                        style={{
                          backgroundColor: colors.popover_color,
                          borderTopLeftRadius:
                            this.props.device == "mobile" ? 0 : 15,
                          borderTopRightRadius:
                            this.props.device == "mobile" ? 0 : 15
                        }}
                      >
                        {this.props.device == "desktop" ? (
                          <Wrapper
                            style={{
                              padding: 10,
                              alignItems: "center",
                              justifyContent: "center",
                              borderTopLeftRadius: 30,
                              borderTopRightRadius: 30,
                              backgroundColor: colors.popover_color,
                              marginBottom: -1
                            }}
                          >
                            <Wrapper
                              style={{
                                height: 8,
                                width: 60,
                                borderRadius: 4,
                                backgroundColor:
                                  this.props.dark_mode == "dark_mode"
                                    ? colors.card_color
                                    : colors.gray_color
                              }}
                            />
                          </Wrapper>
                        ) : null}

                        {this.renderTopButtons(show_message)}
                      </Wrapper>
                    );
                  }
                : null
            }
          >
            {this.props.device === "mobile" ? (
              <KeyboardView style={{ flex: 1, position: "relative" }}>
                {this.renderChatMessages({ show_message })}
              </KeyboardView>
            ) : (
              <>{this.renderChatMessages({ show_message })}</>
            )}
          </PullUpSheet>
        </>
      );
    }
  }
}
const mapStateToProps = ({
  auth,
  native,
  settings,
  lead,
  alma,
  billing,
  route,
  property_map
}) => {
  const { token, user } = auth;
  const {
    isMobile,
    device,
    platform,
    isIphoneX,
    window_height,
    keyboard_showing
  } = native;
  const { colors, dark_mode } = settings;
  const { comps_settings } = lead;
  const { dismissed_alma } = alma;
  const { source_of_truth } = billing;
  const { current_route } = route;
  const { add_deal_loading } = property_map;

  return {
    token,
    user,
    isMobile,
    device,
    platform,
    colors,
    dark_mode,
    isIphoneX,
    window_height,
    keyboard_showing,
    comps_settings,
    dismissed_alma,
    source_of_truth,
    current_route,
    add_deal_loading
  };
};

export default connect(mapStateToProps, {
  getChatSystemMessages,
  sendMessageToOpenAI,
  getComps,
  getChatPrompts,
  getAlmaChat,
  updateAlmaChat,
  showErrorMessage,
  dismissAlma,
  setModal,
  toggleModal,
  showSuccess,
  stopOpenAI,
  updateLead,
  addDeal,
  pushSidePanel
})(Alma);
