import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Row,
  Container,
  Scroll,
  KeyboardView,
  Copy
} from "app/NativeComponents/common";
import {
  NewHeader,
  List,
  PillButton,
  InlineButton,
  IconButton,
  GhostButton,
  Select,
  AttentionBox,
  SearchBar,
  SpinWrapper,
  SelectItem,
  PopoverMenu,
  InlineTabs
} from "app/NativeComponents/snippets";
import MainHeader from "app/DealMachineCore/reuseable/MainHeader";

import {
  getTeam,
  getTasks,
  completeTask,
  selectActiveTask,
  popSidePanel,
  pushSidePanel,
  clearTaskList,
  updateUserSettings,
  dismissMobileKeyboard,
  changeTab
} from "app/NativeActions";
import { store } from "../../../store";
import TaskItem from "./TaskItem";
let task_open = false;
const colors = store.getState().settings.colors;
class Tasks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formatted_items: [],
      assigned_items: [],
      task_hovering: false,
      sort_by:
        props.user?.user_settings?.task_filter_settings?.sort_by ||
        "Most Urgent",
      filter_by:
        props.user?.user_settings?.task_filter_settings?.filter_by ||
        "Active Tasks",
      filter_by_items: [
        { value: "Active Tasks", label: "Active Tasks" },
        { value: "Completed Tasks", label: "Completed Tasks" }
      ],
      sort_by_items: [
        { value: "Most Urgent", label: "Most Urgent" },
        { value: "Least Urgent", label: "Least Urgent" },
        { value: "Added (newest)", label: "Added (newest)" },
        { value: "Added (oldest)", label: "Added (oldest)" }
      ],
      filter_user:
        props.user?.user_settings?.task_filter_settings?.filter_user ||
        this.props.user.id,
      tasks_completed: {},
      search: "",
      show_filters: false
    };
    this.getTasks = this.getTasks.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  filterOptions(options) {
    let filtered_options = [];
    const { search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].title?.toLowerCase().indexOf(search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }

  componentDidMount() {
    if (this.props.device == "mobile") {
      this.props.changeTab("tasks");
    }
    if (this.props.my_team.length === 0) {
      this.getItems();
    }

    if (typeof this.props.tasks_sort_by !== "undefined") {
      this.setState({ sort_by: this.props.tasks_sort_by });
    }

    this.getTasks();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.filter_user !== this.state.filter_user ||
      prevState.filter_by !== this.state.filter_by ||
      prevState.sort_by !== this.state.sort_by
    ) {
      this.getTasks();

      this.props.updateUserSettings({
        token: this.props.token,
        type: "task_filter_settings",
        value: {
          filter_user: this.state.filter_user,
          filter_by: this.state.filter_by,
          sort_by: this.state.sort_by
        },
        show_loading: false,
        onLoading: () => {},
        onError: () => {},
        onSuccess: () => {}
      });
    }
  }

  getTasks() {
    this.props.getTasks({
      token: this.props.token,
      begin: this.props.tasks_begin,
      type: "get",
      limit: this.props.tasks_limit,
      sort_by: this.state.sort_by,
      filter_by: this.state.filter_by,
      filter_user: this.state.filter_user
    });
  }

  getItems() {
    if (!this.props.team_loading) {
      this.props.getTeam({ token: this.props.token, type: "load" });
    }
  }

  handleBack() {
    dismissMobileKeyboard();
    this.props.popSidePanel();
  }
  render() {
    return (
      <Container>
        {this.props.device == "desktop" ? (
          <NewHeader
            title={"Task Navigator"}
            leftButton={{
              icon: "close",
              onPress: () => {
                this.handleBack();
              }
            }}
          />
        ) : (
          <MainHeader title={"Task Navigator"} />
        )}
        <AttentionBox
          title="Tasks help drive your mission!"
          description={
            "Tasks are a tool for reminding yourself or a teammate to take key actions."
          }
        />
        <Row>
          <Wrapper style={{ flex: 1 }}>
            <SearchBar
              title={"Search Tasks"}
              onChange={value => {
                this.setState({
                  search: value
                });
              }}
              value={this.state.search}
              style={{
                margin: 10,
                marginRight: 0,
                borderWidth: 0,
                borderBottomWidth: 0
              }}
            />
          </Wrapper>

          <PopoverMenu
            show={this.state.show_filters}
            no_swipe={true}
            no_cancel={true}
            onShow={s => {
              this.setState({
                show_filters: s
              });
            }}
            popover_width={350}
            popoverSheetTop={450}
            popoverSheetTop={"80%"}
            popoverPlacement={"bottom"}
            popover_title={"Filter Tasks"}
            includeCloseButton={true}
            renderComponent={options => {
              return (
                <IconButton
                  tooltip={"Filter Tasks"}
                  tooltipPlacement={"top"}
                  icon={"tune"}
                  noPress={true}
                  pressedIn={
                    options.hovering ||
                    options.pressedIn ||
                    this.state.show_filters
                  }
                />
              );
            }}
            renderMenu={() => {
              return (
                <Wrapper style={{ alignSelf: "stretch" }}>
                  <Wrapper
                    style={{
                      margin: 10,
                      marginBottom: 0,
                      alignSelf: "stretch",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <InlineTabs
                      selected_tab={this.state.filter_by}
                      tabs={[
                        {
                          onPress: () => {
                            this.setState({
                              filter_by: "Active Tasks"
                            });
                          },
                          title: "Active Tasks",
                          slug: "Active Tasks"
                        },
                        {
                          onPress: () => {
                            this.setState({
                              filter_by: "Completed Tasks"
                            });
                          },
                          title: "Completed Tasks",
                          slug: "Completed Tasks"
                        }
                      ]}
                    />
                  </Wrapper>
                  <SearchBar
                    title={"Search Team"}
                    onChange={value => {
                      this.setState({
                        team_search: value
                      });
                    }}
                    value={this.state.team_search}
                    style={{
                      margin: 10
                    }}
                  />
                  <KeyboardView
                    style={
                      this.props.device == "desktop"
                        ? {
                            maxHeight: 300,
                            alignSelf: "stretch"
                          }
                        : {
                            alignSelf: "stretch"
                          }
                    }
                  >
                    {!this.state.team_search ||
                    "My Tasks"
                      .toLowerCase()
                      .includes(this.state.team_search.toLowerCase()) ? (
                      <SelectItem
                        select_type="radio"
                        selected={this.state.filter_user == this.props.user.id}
                        onPress={() => {
                          this.setState({
                            filter_user: this.props.user.id
                          });
                        }}
                      >
                        My Tasks
                      </SelectItem>
                    ) : null}
                    {!this.state.team_search ||
                    ("All Team Tasks"
                      .toLowerCase()
                      .includes(this.state.team_search.toLowerCase()) &&
                      this.props.user?.can_see_all_deals == 1) ? (
                      <SelectItem
                        select_type="radio"
                        selected={this.state.filter_user == "team_tasks"}
                        onPress={() => {
                          this.setState({
                            filter_user: "team_tasks"
                          });
                        }}
                      >
                        All Team Tasks
                      </SelectItem>
                    ) : null}

                    {this.props.my_team &&
                      this.props.user?.can_see_all_deals == 1 &&
                      this.props.my_team.map((team_member, i) => {
                        const name =
                          team_member.firstname && team_member.lastname
                            ? team_member.firstname + " " + team_member.lastname
                            : team_member.email;
                        if (
                          team_member.id != this.props.user.id &&
                          (!this.state.team_search ||
                            name
                              .toLowerCase()
                              .includes(this.state.team_search.toLowerCase()))
                        ) {
                          return (
                            <SelectItem
                              select_type="radio"
                              selected={
                                this.state.filter_user == team_member.id
                              }
                              onPress={() => {
                                this.setState({
                                  filter_user: team_member.id
                                });
                              }}
                            >
                              {name}
                            </SelectItem>
                          );
                        }

                        return null;
                      })}
                  </KeyboardView>
                </Wrapper>
              );
            }}
            menu_items={null}
          />
          <PopoverMenu
            popover_width={350}
            renderComponent={({ pressedIn, hovering }) => {
              return (
                <InlineButton
                  onPress={() => {}}
                  noPress={true}
                  style={{ marginLeft: 0, marginRight: 0 }}
                  pressedIn={pressedIn || hovering}
                >
                  Sort
                </InlineButton>
              );
            }}
            menu_items={[
              {
                title: "Most Urgent",
                select_type: "radio",
                selected: this.state.sort_by == "Most Urgent",
                onPress: () => {
                  this.setState({
                    sort_by: "Most Urgent"
                  });
                }
              },
              {
                title: "Least Urgent",
                select_type: "radio",
                selected: this.state.sort_by == "Least Urgent",
                onPress: () => {
                  this.setState({
                    sort_by: "Least Urgent"
                  });
                }
              },
              {
                title: "Added (newest)",
                select_type: "radio",
                selected: this.state.sort_by == "Added (newest)",
                onPress: () => {
                  this.setState({
                    sort_by: "Added (newest)"
                  });
                }
              },
              {
                title: "Added (oldest)",
                select_type: "radio",
                selected: this.state.sort_by == "Added (oldest)",
                onPress: () => {
                  this.setState({
                    sort_by: "Added (oldest)"
                  });
                }
              }
            ]}
          />
          <InlineButton
            icon="add"
            primary={true}
            onPress={() => {
              this.props.clearTaskList();
              this.props.pushSidePanel({ slug: "create_task", overlay: true });
            }}
          >
            New
          </InlineButton>
        </Row>
        <>
          {this.props.get_tasks_loading || this.props.team_members_loading ? (
            <SpinWrapper text="Loading Tasks..." />
          ) : this.props.current_tasks.length > 0 ? (
            <KeyboardView style={{ flex: 1 }}>
              <List
                rowNumber={1}
                style={{ flex: 1 }}
                items={this.filterOptions(this.props.current_tasks)}
                itemStructure={({ item, index }) => {
                  return (
                    <TaskItem
                      key={"task_" + item.id}
                      {...this.props}
                      item={item}
                    />
                  );
                }}
                canRefresh={false}
                is_refreshing={this.props.get_tasks_refreshing}
              />
            </KeyboardView>
          ) : (
            <Wrapper style={{ padding: 25 }}>
              <Copy>You have no tasks in this filtered view.</Copy>
            </Wrapper>
          )}
        </>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing, team, tasks }) => {
  const { token, user } = auth;
  const { device, platform, isMobile, window_height } = native;
  const { colors, dark_mode, tasks_sort_by } = settings;
  const { plan_modules, card_info } = billing;
  const {
    active_task,
    current_tasks,
    get_tasks_loading,
    tasks_limit,
    tasks_begin,
    tasks_loaded_all,
    get_tasks_refreshing,
    active_task_count,
    task_panel
  } = tasks;

  const {
    active_team_member,
    team_members,
    team_members_loading,
    team_members_error,
    team_members_refreshing,
    team_members_loaded_all,
    team_members_limit,
    team_members_begin,
    team_members_search,
    my_team
  } = team;

  return {
    token,
    user,
    device,
    platform,
    isMobile,
    colors,
    dark_mode,
    active_task,

    plan_modules,
    card_info,
    current_tasks,

    window_height,
    active_team_member,
    team_members,
    team_members_loading,
    team_members_error,
    team_members_refreshing,
    team_members_loaded_all,
    team_members_limit,
    team_members_begin,
    team_members_search,
    my_team,
    get_tasks_loading,
    tasks_limit,
    tasks_begin,
    tasks_loaded_all,
    get_tasks_refreshing,
    active_task_count,
    tasks_sort_by
  };
};

export default connect(mapStateToProps, {
  getTeam,
  getTasks,
  completeTask,
  selectActiveTask,
  popSidePanel,
  pushSidePanel,
  clearTaskList,
  updateUserSettings,
  changeTab
})(Tasks);
