import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Row,
  Copy,
  Container,
  Wrapper,
  Bold,
  KeyboardView
} from "app/NativeComponents/common";
import {
  NewHeader,
  SelectItem,
  InlineButton,
  BottomNavBar,
  ConfirmInlineButton,
  GhostButton
} from "app/NativeComponents/snippets";
import MainHeader from "app/DealMachineCore/reuseable/MainHeader";
import WorkspaceAdminSettings from "./WorkspaceAdminSettings";

import {
  popSidePanel,
  pushSidePanel,
  changeTab,
  tabNavigation,
  getBilling,
  getSendingQueueCounts,
  numberWithCommas,
  renderPrice,
  setDarkMode,
  updateUserSettings,
  logout,
  checkIfUserHasSubscription,
  popAllSidePanels,
  openUrl
} from "app/NativeActions";

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dark_mode: props.dark_mode
    };
  }

  componentDidMount() {
    this.props.getBilling({ token: this.props.token, type: "deal_credits" });
    this.props.getBilling({ token: this.props.token, type: "get_cards" });
    this.props.getSendingQueueCounts({
      token: this.props.token
    });
  }

  handleBack() {
    this.props.popSidePanel();
  }

  render() {
    const {
      user,
      source_of_truth,
      deal_credits,
      deal_credits_loading,
      device,
      isMobile,
      colors
    } = this.props;

    if (this.props.user?.team_type === "workspace") {
      return <WorkspaceAdminSettings />;
    }

    return (
      <Container
        style={{
          display: "flex",
          alignSelf: "stretch",
          backgroundColor: colors.card_color
        }}
      >
        <NewHeader
          title={"Account & Settings"}
          leftButton={{
            icon: isMobile ? "arrow-back" : "close",
            onPress: () => {
              this.handleBack();
            }
          }}
        />

        <KeyboardView style={{ flex: 1 }}>
          {this.props.is_partner == 0 && this.props.platform !== "ios" ? (
            <InlineButton
              onPress={() => {
                this.props.pushSidePanel({
                  slug: "share_and_earn",
                  overlay: true
                });
              }}
              primary={true}
              icon="star"
              style={{
                borderRadius: 30,
                margin: 10,
                marginBottom: 25,
                marginRight: 5,
                marginLeft: 5,
                height: 40,
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 0,
                paddingBottom: 0,
                backgroundColor: colors.orange_color_muted
              }}
              button_type="full"
            >
              Share and Earn $10
            </InlineButton>
          ) : null}

          <SelectItem
            innerStyle={{ paddingTop: 10, paddingBottom: 10 }}
            select_type="profile_pic"
            user={this.props.user}
            noPress={true}
            description={user?.company}
            renderRight={() => {
              return (
                <InlineButton
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "edit_profile",
                      overlay: true
                    });
                  }}
                  icon_right="keyboard-arrow-right"
                >
                  Edit Profile
                </InlineButton>
              );
            }}
          >
            {user?.name ? user.name : user?.email}
          </SelectItem>
          {checkIfUserHasSubscription(user, source_of_truth) &&
          this.props.user?.can_mimic_team_owner == 1 &&
          this.props.platform !== "ios" ? (
            <SelectItem
              innerStyle={{ paddingTop: 15, paddingBottom: 15 }}
              select_type="none"
              noPress={true}
              subtext={"Marketing Credits"}
              renderRight={() => {
                if (this.props.platform !== "ios") {
                  return (
                    <InlineButton
                      onPress={() => {
                        if (
                          this.props.source_of_truth?.has_stripe_card_on_file
                        ) {
                          this.props.pushSidePanel({
                            slug: "add_marketing_credits",
                            overlay: true
                          });
                        } else {
                          this.props.pushSidePanel({
                            slug: "add_card_prompt",
                            overlay_modal: true,
                            data: {
                              title:
                                "Add a card to add more credit to your account.",
                              dismiss_button:
                                "No thanks. Let me continue without adding credits."
                            },
                            locked: true
                          });
                        }
                      }}
                      icon_right="keyboard-arrow-right"
                    >
                      Add Credits
                    </InlineButton>
                  );
                }
                return null;
              }}
            >
              {deal_credits?.deal_credits_remaining?.cents
                ? renderPrice(deal_credits?.deal_credits_remaining.cents)
                : ""}
            </SelectItem>
          ) : null}
          {/*<SelectItem
            innerStyle={{ paddingTop: 15, paddingBottom: 15 }}
            select_type="none"
            noPress={true}
            subtext={"Leads In Marketing"}
            renderRight={() => {
              return (
                <InlineButton
                  onPress={() => {}}
                  icon_right="keyboard-arrow-right"
                >
                  View Marketing
                </InlineButton>
              );
            }}
          >
            {numberWithCommas(this.props.sending_queue_counts?.sending_queue)}
          </SelectItem>*/}

          {checkIfUserHasSubscription(user, source_of_truth) &&
          this.props.user?.can_approve_mail == 1 &&
          this.props?.user?.can_see_all_deals == 1 ? (
            <>
              <SelectItem
                innerStyle={{ paddingTop: 15, paddingBottom: 15 }}
                select_type="none"
                noPress={true}
                subtext={"Mail In Queue"}
                renderRight={() => {
                  return (
                    <InlineButton
                      onPress={() => {
                        this.props.pushSidePanel({
                          slug: "mail_queue",
                          overlay: true
                        });
                      }}
                      icon_right="keyboard-arrow-right"
                    >
                      Mail Queue
                    </InlineButton>
                  );
                }}
                renderBottom={
                  this.props.user?.pause_sending == 1
                    ? () => {
                        return (
                          <Wrapper
                            style={{
                              paddingLeft: 25,
                              paddingRight: 25,
                              marginTop: -15
                            }}
                          >
                            <Copy
                              style={{
                                color: colors.orange_color,
                                fontSize: 10
                              }}
                            >
                              Mail Paused
                            </Copy>
                          </Wrapper>
                        );
                      }
                    : null
                }
              >
                {numberWithCommas(
                  this.props.sending_queue_counts?.sending_queue
                )}
              </SelectItem>
            </>
          ) : null}

          {checkIfUserHasSubscription(user, source_of_truth) ? (
            <SelectItem
              select_type="none"
              icon="keyboard-arrow-right"
              onPress={() => {
                this.props.pushSidePanel({
                  slug: "team",
                  overlay: true
                });
              }}
            >
              Team
            </SelectItem>
          ) : null}
          {checkIfUserHasSubscription(user, source_of_truth) &&
          this.props.user?.can_mimic_team_owner == 1 &&
          this.props.platform !== "ios" ? (
            <SelectItem
              select_type="none"
              icon="keyboard-arrow-right"
              onPress={() => {
                this.props.pushSidePanel({
                  slug: "plan_settings",
                  overlay: true
                });
              }}
            >
              Plans & Licenses
            </SelectItem>
          ) : null}

          {checkIfUserHasSubscription(user, source_of_truth) &&
          this.props.user?.can_mimic_team_owner == 1 &&
          this.props.platform !== "ios" ? (
            <SelectItem
              select_type="none"
              icon="keyboard-arrow-right"
              onPress={() => {
                this.props.pushSidePanel({
                  slug: "billing_settings",
                  overlay: true
                });
              }}
            >
              Billing
            </SelectItem>
          ) : null}
          {checkIfUserHasSubscription(user, source_of_truth) ? (
            <SelectItem
              select_type="none"
              icon="keyboard-arrow-right"
              onPress={() => {
                this.props.pushSidePanel({
                  slug: "app_settings",
                  overlay: true
                });
              }}
            >
              Application Settings
            </SelectItem>
          ) : null}
        </KeyboardView>
        {this.props.user?.team_owner != 1 &&
        this.props.device == "mobile" &&
        this.props.platform == "ios" ? (
          <Wrapper style={{ padding: 25, alignSelf: "bottom" }}>
            <Copy>
              To make changes to your subscription please log in to{" "}
              dealmachine.com
            </Copy>
          </Wrapper>
        ) : null}

        {this.props.device == "desktop" ? (
          <>
            <Row style={{ justifyContent: "space-between" }}>
              <ConfirmInlineButton
                confirm_text="Really Logout?"
                loading_text="Logging Out..."
                loading={this.state.deleting}
                onPress={() => {
                  this.setState(
                    {
                      deleteing: true
                    },
                    () => {
                      this.props.logout();
                    }
                  );
                }}
              >
                Logout
              </ConfirmInlineButton>
              <InlineButton
                select_type="toggle"
                icon={
                  this.state.dark_mode == "dark_mode"
                    ? "toggle-on"
                    : "toggle-off"
                }
                onPress={() => {
                  this.setState({
                    dark_mode:
                      this.state.dark_mode != "dark_mode"
                        ? "dark_mode"
                        : "dark_mode_off"
                  });
                  this.props.setDarkMode(
                    this.state.dark_mode != "dark_mode"
                      ? "dark_mode"
                      : "dark_mode_off"
                  );
                  this.props.updateUserSettings({
                    token: this.props.token,
                    type: "dark_mode",
                    value:
                      this.state.dark_mode != "dark_mode"
                        ? "dark_mode"
                        : "dark_mode_off"
                  });
                }}
              >
                Dark Mode
              </InlineButton>
            </Row>
            <BottomNavBar>
              <Row>
                <Wrapper style={{ flex: 1 }}>
                  <Row>
                    <InlineButton
                      style={{ marginRight: 0, marginLeft: 0 }}
                      textStyle={{
                        color: colors.light_text_color,
                        textAlign: "center",
                        fontSize: 12
                      }}
                      onPress={() => {
                        openUrl("https://dealmachine.com/privacy-policy");
                      }}
                    >
                      Privacy Policy
                    </InlineButton>

                    <InlineButton
                      style={{ marginRight: 0, marginLeft: 0 }}
                      textStyle={{
                        color: colors.light_text_color,
                        textAlign: "center",
                        fontSize: 12
                      }}
                      onPress={() => {
                        openUrl("https://dealmachine.com/terms-of-service");
                      }}
                    >
                      Terms Of Service
                    </InlineButton>
                  </Row>
                </Wrapper>
                <Copy
                  style={{
                    color: colors.light_text_color,
                    textAlign: "center",
                    fontSize: 12,
                    paddingRight: 10,
                    paddingLeft: 10
                  }}
                >
                  © DealMachine {new Date().getFullYear()}
                </Copy>
              </Row>
            </BottomNavBar>
          </>
        ) : (
          <>
            <BottomNavBar>
              <Row style={{ justifyContent: "space-between" }}>
                <ConfirmInlineButton
                  confirm_text="Really Logout?"
                  loading_text="Logging Out..."
                  loading={this.state.deleting}
                  onPress={() => {
                    this.setState(
                      {
                        deleteing: true
                      },
                      () => {
                        this.props.logout();
                      }
                    );
                  }}
                >
                  Logout
                </ConfirmInlineButton>
                <InlineButton
                  select_type="toggle"
                  icon={
                    this.state.dark_mode == "dark_mode"
                      ? "toggle-on"
                      : "toggle-off"
                  }
                  onPress={() => {
                    this.setState({
                      dark_mode:
                        this.state.dark_mode != "dark_mode"
                          ? "dark_mode"
                          : "dark_mode_off"
                    });
                    this.props.setDarkMode(
                      this.state.dark_mode != "dark_mode"
                        ? "dark_mode"
                        : "dark_mode_off"
                    );
                    this.props.updateUserSettings({
                      token: this.props.token,
                      type: "dark_mode",
                      value:
                        this.state.dark_mode != "dark_mode"
                          ? "dark_mode"
                          : "dark_mode_off"
                    });
                  }}
                >
                  Dark Mode
                </InlineButton>
              </Row>
            </BottomNavBar>
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({
  auth,
  settings,
  native,
  partner,
  billing,
  mailer
}) => {
  const { token, user } = auth;
  const { colors, dark_mode } = settings;
  const { platform, device, isMobile } = native;
  const { is_partner } = partner;
  const { sending_queue_counts } = mailer;

  const { deal_credits, deal_credits_loading, source_of_truth } = billing;

  return {
    token,
    user,
    colors,
    dark_mode,
    device,
    isMobile,
    platform,
    deal_credits,
    is_partner,
    deal_credits_loading,
    sending_queue_counts,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  changeTab,
  tabNavigation,
  getSendingQueueCounts,
  getBilling,
  setDarkMode,
  updateUserSettings,
  logout,
  popAllSidePanels
})(Settings);
