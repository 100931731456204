import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Copy,
  Wrapper,
  Row,
  Spin,
  Title,
  GoogleOAuth,
  AppleLogin,
  Button,
  InternalImage,
  Card,
  Bold,
  Animation,
  ContentfulRichText,
  AlmaOrb
} from "app/NativeComponents/common";
import {
  PopoverMenu,
  InlineTabs,
  CircleButton,
  UpsellButton,
  InlineButton,
  ConfirmInlineButton,
  IconButton,
  SelectItem,
  CloseButton,
  GhostButton,
  SelectMultiple,
  SpinWrapper,
  InputBox,
  SmallLabel,
  SearchBar,
  BottomNavBar,
  List
} from "app/NativeComponents/snippets";

import MainHeader from "app/DealMachineCore/reuseable/MainHeader";
import NativeMainMap from "app/NativeComponents/components/NativeMainMap";
import AutocompleteSearchV2 from "app/DealMachineCore/reuseable/AutocompleteSearchV2";

import MapSidePanel from "./MapSidePanel";
import PropertyPopover from "./PropertyPopover";
import MapOptions from "./MapSidePanel/MapOptions";
import isEqual from "lodash/isEqual";
import Street from "./MapSidePanel/Street";
import PropertyList from "./PropertyList";
import ListBuilder from "./ListBuilder";
import AIListBuilder from "./AIListBuilder";

import {
  getPropertiesOnMap,
  getRoutesOnMap,
  setMapProperties,
  setStreetViewCoords,
  updateMainMapLocation,
  updateUserSettings,
  renderLabelText,
  numberWithCommas,
  getDeviceLocation,
  setPricingPage,
  getBilling,
  pushSidePanel,
  openUrl,
  completePopSidePanel,
  askLocationPermissionsThenContinue,
  popAllSidePanels,
  replaceSidePanel,
  setAppliedHighlight,
  nextOnboardingStep,
  getPropertyNew,
  checkIfUserHasSubscription,
  dismissMobileKeyboard,
  showErrorMessage,
  loginUser,
  registerUser,
  getUserFromAppleAuth,
  toggleVirtualDriveStart,
  getReverseGeocode,
  updateMapFilters,
  getSourceOfTruth,
  checkIfUserHasMetadata
} from "app/NativeActions";
import {
  convertDistance,
  getDistance,
  isPointInPolygon,
  isPointWithinRadius,
  getBounds
} from "geolib";
import BedsAndBathsContainer from "./Filters/BedsAndBathsContainer";
import QuickFilters from "./Filters/QuickFilters";
import PropertyTypes from "./Filters/PropertyTypes";
import PriceRange from "./Filters/PriceRange";
import SavedFiltersContainer from "./Filters/SavedFiltersContainer";
class MainMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      map_side_panel_pages: [],
      start_pop_map_side_panel: false,
      popMapSidePanelSuccess: null,
      search_focused: false,
      show_options: false,

      latitude: props.main_map_location?.latitude
        ? props.main_map_location?.latitude
        : props.user.default_lat
        ? props.user.default_lat
        : 39.97411,
      longitude: props.main_map_location?.longitude
        ? props.main_map_location?.longitude
        : props.user.default_lng
        ? props.user.default_lng
        : -86.11756,
      updated_latitude: props.main_map_location?.latitude
        ? props.main_map_location?.latitude
        : props.user.default_lat
        ? props.user.default_lat
        : 39.97411,
      updated_longitude: props.main_map_location?.longitude
        ? props.main_map_location?.longitude
        : props.user.default_lng
        ? props.user.default_lng
        : -86.11756,
      last_fetch_location: null,
      zoom: props.main_map_location?.zoom ? props.main_map_location?.zoom : 16,
      updated_zoom: props.main_map_location?.zoom
        ? props.main_map_location?.zoom
        : 16,
      maxZoom: 17.9,
      minZoom: 13,
      pitch: props.main_map_location?.pitch
        ? props.main_map_location?.pitch
        : 0,
      updated_pitch: props.main_map_location?.pitch
        ? props.main_map_location?.pitch
        : 0,
      heading: props.main_map_location?.heading
        ? props.main_map_location?.heading
        : 0,
      updated_heading: props.main_map_location?.heading
        ? props.main_map_location?.heading
        : 0,
      bounds: null,
      map_type: props.main_map_location?.map_type
        ? props.main_map_location?.map_type
        : "standard",

      routes_on_map_loading: false,
      routes_on_map: [],
      active_property: null,
      show_popover: false,
      popover_info: null,
      select_drive_start: false,
      saving_last_location: false,
      last_saved_location: null,
      current_location_loading: false,
      expanded: false,
      street_timer: false,

      property_loading_id: null,
      property_count_loading_id: null,
      route_loading_id: null,

      properties_on_map_loading: true,
      properties_on_map: [],
      properties_on_map_count_loading: true,
      properties_on_map_count: 0,
      other_workspace_team_leads_count: 0,
      limit: 250,

      city: null,
      state: null,
      zip: null,
      fips: null,
      fips_name: null,
      search_locations: [],
      start_building: false,

      show_past_drives: false
    };

    this.updateCenter = this.updateCenter.bind(this);
    this.updateBounds = this.updateBounds.bind(this);
    this.updateZoomProperties = this.updateZoomProperties.bind(this);
    this.updateSearchFocus = this.updateSearchFocus.bind(this);
    this.updateLatLng = this.updateLatLng.bind(this);
    this.setActiveProperty = this.setActiveProperty.bind(this);
    this.togglePopover = this.togglePopover.bind(this);
    this.pushMapSidePanel = this.pushMapSidePanel.bind(this);
    this.popMapSidePanel = this.popMapSidePanel.bind(this);
    this.completePopMapSidePanel = this.completePopMapSidePanel.bind(this);
    this.toggleSelectDriveStart = this.toggleSelectDriveStart.bind(this);

    this.checkIfPropertyInBounds = this.checkIfPropertyInBounds.bind(this);
    this.checkIfRouteInBounds = this.checkIfRouteInBounds.bind(this);

    this.toggleExpanded = this.toggleExpanded.bind(this);

    this.onDraw = this.onDraw.bind(this);
    this.drawingCreated = this.drawingCreated.bind(this);
    this.toggleDrawing = this.toggleDrawing.bind(this);

    this.getLoadingId = this.getLoadingId.bind(this);

    this.checkIfThereAreFilters = this.checkIfThereAreFilters.bind(this);

    this.reverseGeocode = this.reverseGeocode.bind(this);

    this.updateMainState = this.updateMainState.bind(this);
    this.refreshMap = this.refreshMap.bind(this);

    this.hideAllPopovers = this.hideAllPopovers.bind(this);
    this.checkAndSaveRecentSearch = this.checkAndSaveRecentSearch.bind(this);
    this.getMoreFiltersCount = this.getMoreFiltersCount.bind(this);
    this.ableToSaveSearch = this.ableToSaveSearch.bind(this);
    this._value_range_min_input = React.createRef();
    this._value_range_max_input = React.createRef();
    this._location_field = React.createRef();
  }

  ableToSaveSearch() {
    let should_save_count = 0;
    const {
      property_types,
      property_flags,
      value_range_max,
      value_range_min,
      beds_min,
      baths_min
    } = this.props.map_filters;
    if (property_flags.length > 0) {
      should_save_count++;
    }

    if (property_types.length > 0) {
      should_save_count++;
    }

    if (!!value_range_min || !!value_range_max) {
      should_save_count++;
    }

    if (!!beds_min || !!baths_min) {
      should_save_count++;
    }

    if (this.getMoreFiltersCount() > 0) {
      should_save_count++;
    }

    if (this.getMoreFiltersCount() > 1) {
      should_save_count++;
    }

    if (should_save_count > 1) {
      //lets save
      return true;
    }

    return false;
  }

  checkAndSaveRecentSearch() {
    clearInterval(this._save_recent_search_interval);
    this._save_recent_search_interval = setTimeout(() => {
      if (this.ableToSaveSearch()) {
      }
    }, 20000);
  }

  getMoreFiltersCount() {
    let count = 0;
    if (this.props.applied_highlight?.data?.length > 0) {
      const data = this.props.applied_highlight?.data;
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].data?.length; j++) {
          count++;
        }
      }
    }
    return count;
  }

  updateMainState(updated_state) {
    this.setState(updated_state);
  }

  checkIfThereAreFilters() {
    const {
      property_types,
      property_flags,
      value_range_max,
      value_range_min,
      beds_min,
      baths_min
    } = this.props.map_filters;
    if (
      property_flags.length > 0 ||
      property_types.length > 0 ||
      !!value_range_min ||
      !!value_range_max ||
      !!beds_min ||
      !!baths_min
    ) {
      return true;
    }
    if (
      this.props.applied_highlight &&
      this.props.applied_highlight?.data &&
      this.props.applied_highlight?.data?.length > 0
    ) {
      return true;
    }

    if (this.props.onboarding_map && !!this.props.onboarding_filter) {
      return true;
    }

    return false;
  }

  hideAllPopovers() {
    this.setState({
      show_popover: false,
      show_quick_filters: false,
      show_property_types: false,
      show_price_range: false,
      show_beds_and_baths: false,
      show_more_options: false
    });
  }

  getLoadingId() {
    //cerate a random id and set it to the state loading_id
    return Math.floor(Math.random() * 100000000000000000);
  }

  onDraw(coordinates) {
    this.props.updateMapFilters({
      drawing_coordinates: coordinates
    });
  }

  drawingCreated(coordinates) {
    this.props.updateMapFilters({
      drawing_created: true,
      search_locations: []
    });
  }

  toggleDrawing(toggle) {
    this.props.updateMapFilters({
      drawing_created: false,
      drawing: toggle,
      drawing_coordinates: null
    });
  }

  componentDidMount() {}

  checkIfPropertyInBounds(property) {
    const bounds = {
      westLng: this.state.bounds?.westLng - 0.001, // westLng - min lng
      southLat: this.state?.bounds?.southLat - 0.001, // southLat - min lat
      eastLng: this.state?.bounds?.eastLng + 0.001, // eastLng - max lng
      northLat: this.state?.bounds?.northLat + 0.001,
      centerLat: this.state.bounds?.centerLat,
      centerLng: this.state.bounds?.centerLng
    };

    if (property?.location) {
      if (
        isPointInPolygon(
          {
            latitude: property.location?.latitude,
            longitude: property.location?.longitude
          },
          [
            {
              latitude: bounds?.southLat,
              longitude: bounds?.westLng
            },
            {
              latitude: bounds?.southLat,
              longitude: bounds?.eastLng
            },
            {
              latitude: bounds?.northLat,
              longitude: bounds?.eastLng
            },
            {
              latitude: bounds?.northLat,
              longitude: bounds?.westLng
            }
          ]
        )
      ) {
        return true;
      }
    }

    return false;
  }

  checkIfRouteInBounds(route) {
    const bounds = {
      westLng: this.state.bounds?.westLng - 0.001,
      southLat: this.state?.bounds?.southLat - 0.001,
      eastLng: this.state?.bounds?.eastLng + 0.001,
      northLat: this.state?.bounds?.northLat + 0.001,
      centerLat: this.state.bounds?.centerLat,
      centerLng: this.state.bounds?.centerLng
    };
    for (let i = 0; i < route.coordinates.length; i++) {
      if (
        isPointInPolygon(
          {
            latitude: route.coordinates[i].latitude,
            longitude: route.coordinates[i].longitude
          },
          [
            {
              latitude: bounds?.southLat,
              longitude: bounds?.westLng
            },
            {
              latitude: bounds?.southLat,
              longitude: bounds?.eastLng
            },
            {
              latitude: bounds?.northLat,
              longitude: bounds?.eastLng
            },
            {
              latitude: bounds?.northLat,
              longitude: bounds?.westLng
            }
          ]
        )
      ) {
        return true;
      }
    }

    return false;
  }
  componentWillUnmount() {
    clearInterval(this._map_refresh_interval);
    clearInterval(this._street_timer_interval);
    clearInterval(this._save_recent_search_interval);
    clearInterval(this._propertyFetchTimer);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.select_virtual_drive_start &&
      prevProps.select_virtual_drive_start !==
        this.props.select_virtual_drive_start
    ) {
      clearInterval(this._street_timer_interval);
      this._street_timer_interval = setTimeout(() => {
        this.setState(
          {
            street_timer: true
          },
          () => {
            this.setState(
              {
                refresh_map: true
              },
              () => {
                clearInterval(this._map_refresh_interval);
                this._map_refresh_interval = setTimeout(() => {
                  this.setState({
                    refresh_map: false
                  });
                }, 1000);
              }
            );
          }
        );
      }, 500);
    } else if (
      !this.props.select_virtual_drive_start &&
      prevProps.select_virtual_drive_start !==
        this.props.select_virtual_drive_start
    ) {
      this.setState(
        {
          refresh_map: true
        },
        () => {
          clearInterval(this._map_refresh_interval);
          this._map_refresh_interval = setTimeout(() => {
            this.setState({
              refresh_map: false,
              street_timer: false,
              routes_on_map: []
            });

            this.getRouteData(this.state.bounds, true);
            this.getPropertyData(this.state.bounds, true);
          }, 1000);
        }
      );
    }

    if (this.props.map_filters?.page != prevProps.map_filters?.page) {
      this.getPropertyData(this.state.bounds, true, true);
    }

    if (
      this.props.map_filters.list_view_type !==
        prevProps.map_filters?.list_view_type ||
      this.props.map_filters.include_owners !==
        prevProps.map_filters?.include_owners ||
      this.props.map_filters.include_residents !==
        prevProps.map_filters?.include_residents
    ) {
      this.getPropertyData(this.state.bounds, true, false, true);
    }

    if (
      this.props.onboarding_map &&
      JSON.stringify(this.props.map_filters?.search_locations) !==
        JSON.stringify(prevProps.map_filters?.search_locations) &&
      this.props.map_filters?.search_locations &&
      this.props.map_filters?.search_locations.length > 0
    ) {
      this.updateLatLng(this.props.map_filters?.search_locations[0]);
    }

    if (
      JSON.stringify(this.props.map_filters?.search_locations) !==
        JSON.stringify(prevProps.map_filters?.search_locations) ||
      this.props.map_filters.sort_by !== prevProps.map_filters?.sort_by ||
      prevProps.track_route !== this.props.track_route ||
      JSON.stringify(prevProps.routes) !== JSON.stringify(this.props.routes) ||
      JSON.stringify(prevProps.active_route) !==
        JSON.stringify(this.props.active_route) ||
      prevProps.main_map_location?.property_label_type !==
        this.props.main_map_location?.property_label_type ||
      JSON.stringify(prevProps.applied_highlight) !==
        JSON.stringify(this.props.applied_highlight) ||
      JSON.stringify(this.props.map_filters?.property_flags) !==
        JSON.stringify(prevProps.map_filters?.property_flags) ||
      (this.props.onboarding_filter !== prevProps.onboarding_filter &&
        this.props.onboarding_map) ||
      this.props.map_filters?.property_flags_and_or !==
        prevProps.map_filters?.property_flags_and_or ||
      JSON.stringify(this.props.map_filters?.property_types) !==
        JSON.stringify(prevProps.map_filters?.property_types) ||
      this.props.map_filters?.value_range_max !==
        prevProps.map_filters?.value_range_max ||
      this.props.map_filters?.value_range_min !==
        prevProps.map_filters?.value_range_min ||
      this.props.map_filters?.price_type !==
        prevProps.map_filters?.price_type ||
      this.props.map_filters?.beds_min !== prevProps.map_filters?.beds_min ||
      this.props.map_filters?.baths_min !== prevProps.map_filters?.baths_min ||
      this.props.map_filters?.use_beds_exact !==
        prevProps.map_filters?.use_beds_exact ||
      this.props.map_filters.sort_by !== prevProps.map_filters?.sort_by ||
      (JSON.stringify(prevProps.map_filters?.drawing_coordinates) !==
        JSON.stringify(this.props.map_filters?.drawing_coordinates) &&
        this.props.map_filters?.drawing_coordinates &&
        this.props.map_filters?.drawing_coordinates?.length > 2) ||
      JSON.stringify(this.props.map_filters?.search_locations) !==
        JSON.stringify(prevProps.map_filters?.search_locations) ||
      (!this.props.map_filters?.drawing &&
        this.props.map_filters?.drawing !== prevProps.map_filters?.drawing)
    ) {
      this.updateBounds(this.state.bounds, true);
    }

    if (
      prevState.updated_latitude !== this.state.updated_latitude ||
      prevState.updated_longitude !== this.state.updated_longitude ||
      prevState.updated_zoom !== this.state.updated_zoom ||
      prevState.updated_pitch !== this.state.updated_pitch ||
      prevState.updated_heading !== this.state.updated_heading ||
      prevProps.main_map_location?.show_routes_on_map !==
        this.props.main_map_location?.show_routes_on_map ||
      prevProps.main_map_location?.property_label_type !==
        this.props.main_map_location?.property_label_type ||
      prevProps.main_map_location?.map_type !==
        this.props.main_map_location?.map_type ||
      prevProps.main_map_location?.user_latlng_loaded !==
        this.props.main_map_location?.user_latlng_loaded
    ) {
      const new_main_map_location = {
        ...this.props.main_map_location,
        longitude: this.state.updated_longitude,
        latitude: this.state.updated_latitude,
        pitch: this.state.updated_pitch,
        heading: this.state.updated_heading,
        zoom: this.state.updated_zoom
      };
      if (!isEqual(this.props.main_map_location, new_main_map_location)) {
        this.props.updateMainMapLocation(new_main_map_location);
      }

      if (
        !this.state.saving_last_location &&
        new_main_map_location?.latitude != 0 &&
        new_main_map_location?.longitude != 0 &&
        (!this.state.last_saved_location ||
          getDistance(
            {
              latitude: this.state.last_saved_location?.latitude,
              longitude: this.state.last_saved_location?.longitude
            },
            {
              latitude: new_main_map_location?.latitude,
              longitude: new_main_map_location?.longitude
            }
          ) > 75)
      ) {
        this.props.updateUserSettings({
          token: this.props.token,
          type: "main_map_location",
          value: new_main_map_location,
          onLoading: () => {
            this.setState({
              saving_last_location: true
            });
          },
          onError: () => {
            this.setState({
              saving_last_location: false
            });
          },
          onSuccess: () => {
            this.setState({
              saving_last_location: false,
              last_saved_location: {
                latitude: new_main_map_location?.latitude,
                longitude: new_main_map_location?.longitude
              }
            });
          }
        });
      }
    }

    if (
      !this.props.street_view_coords?.latitude &&
      !this.props.street_view_coords?.longitude &&
      (prevProps.street_view_coords?.latitude !==
        this.props.street_view_coords?.latitude ||
        prevProps.street_view_coords?.longitude !==
          this.props.street_view_coords?.longitude)
    ) {
      this.setState({
        pitch: 0,
        updated_pitch: 0,
        heading: 0,
        updated_heading: 0
      });
    }

    if (
      this.props.street_view_coords?.latitude &&
      this.props.street_view_coords?.longitude &&
      (prevProps.street_view_coords?.latitude !==
        this.props.street_view_coords?.latitude ||
        prevProps.street_view_coords?.longitude !==
          this.props.street_view_coords?.longitude ||
        prevProps.street_view_coords?.heading !==
          this.props.street_view_coords?.heading ||
        prevProps.street_view_coords?.pitch !==
          this.props.street_view_coords?.pitch ||
        prevProps.street_view_coords?.zoom !==
          this.props.street_view_coords?.zoom)
    ) {
      this.setState({
        pitch: this.props.street_view_coords?.pitch,
        updated_pitch: this.props.street_view_coords?.pitch,
        heading: this.props.street_view_coords?.heading,
        updated_heading: this.props.street_view_coords?.heading,
        latitude: this.props.street_view_coords.latitude,
        longitude: this.props.street_view_coords?.longitude
      });
    }
    if (this.state.last_fetch_location !== prevState.last_fetch_location) {
      this.handleInitPopover();
    }
  }

  handleInitPopover() {
    const { map_properties } = this.props;
    const { steps, currentStep, isOnboardingActive } = this.props.onboarding;

    if (
      this.props.onboarding?.isOnboardingActive &&
      this.props.onboarding?.currentStep?.slug == "send_yourself_mail"
    ) {
      if (
        this.state.last_fetch_location &&
        map_properties &&
        map_properties.length > 0
      ) {
        const property = this.findPropertyByCoords(
          this.state.last_fetch_location
        );

        if (property && property.deal) {
          if (
            property?.deal?.approved == 1 &&
            !property?.deal?.paused &&
            !property?.deal?.campaign_complete
          ) {
            this.props.nextOnboardingStep(
              this.props.token,
              steps,
              steps.find(x => x.slug == "send_yourself_mail")
            );
          } else {
            this.props.replaceSidePanel({
              slug: "property",
              id: property?.property_id,
              focus_side_panel:
                this.props.user?.user_settings?.property_expanded ===
                "not_expanded"
                  ? false
                  : true,

              overlay: true,
              data: {
                property: {
                  ...property
                }
              }
            });
          }
        } else {
          this.props.nextOnboardingStep(
            this.props.token,
            steps,
            steps.find(x => x.slug == "select_home_location")
          );
        }
      }
    }
  }

  findPropertyByCoords(coords) {
    let property;
    const { map_properties } = this.props;
    if (map_properties) {
      for (let i = 0; i < map_properties.length; i++) {
        if (
          map_properties[i].coordinates &&
          map_properties[i].coordinates.length > 0
        ) {
          for (let j = 0; j < map_properties[i].coordinates.length; j++) {
            if (isPointInPolygon(coords, map_properties[i].coordinates[j])) {
              property = map_properties[i];
            }
          }
        } else if (map_properties[i].location) {
          if (isPointWithinRadius(coords, map_properties[i].location, 5)) {
            property = map_properties[i];
          }
        }
      }
    }
    return property;
  }

  setActiveProperty(active_property) {
    const { map_properties } = this.props;
    let new_properties_on_map = [];
    for (let i = 0; i < map_properties?.length; i++) {
      if (active_property && active_property?.id == map_properties[i].id) {
        new_properties_on_map.push({ ...map_properties[i], active: true });
      } else {
        new_properties_on_map.push({ ...map_properties[i], active: false });
      }
    }
    this.props.setMapProperties(new_properties_on_map);
    this.setState({
      active_property
    });
  }

  pushMapSidePanel(side_tab) {
    if (this.props.isMobile) {
      this.props.pushSidePanel({
        slug: side_tab,
        overlay: true
      });
    } else {
      let new_map_side_panel_pages = JSON.parse(
        JSON.stringify(this.state.map_side_panel_pages)
      );
      new_map_side_panel_pages = new_map_side_panel_pages.filter(
        s => s != side_tab
      );

      this.setState({
        map_side_panel_pages: [...new_map_side_panel_pages, side_tab]
      });
    }
  }

  popMapSidePanel(popMapSidePanelSuccess = null) {
    this.setState({
      start_pop_map_side_panel: true,
      popMapSidePanelSuccess
    });
  }

  completePopMapSidePanel(side_tab) {
    if (this.state.popMapSidePanelSuccess) {
      this.state.popMapSidePanelSuccess();
    }

    let new_map_side_panel_pages = JSON.parse(
      JSON.stringify(this.state.map_side_panel_pages)
    );
    new_map_side_panel_pages.splice(-1, 1);
    this.setState({
      start_pop_map_side_panel: false,
      map_side_panel_pages: new_map_side_panel_pages,
      popMapSidePanelSuccess: null
    });
  }

  toggleSelectDriveStart(select_drive_start) {
    this.setState({
      select_drive_start
    });
  }

  togglePopover(show_popover, popover_info = null) {
    this.setState({
      show_popover,
      popover_info
    });

    if (!show_popover && this.state.active_property != null) {
      this.setActiveProperty(null);
    }
  }

  updateSearchFocus(search_focused) {
    this.setState({
      search_focused
    });

    if (search_focused) {
      this.toggleDrawing(false);
    }
  }

  updateCenter(coordinate) {
    this.setState({
      updated_latitude: coordinate.latitude,
      updated_longitude: coordinate.longitude
    });
  }

  updateBounds(bounds, force = false, no_count = false) {
    this.setState(
      {
        bounds
      },
      () => {
        this.props.updateMapFilters({
          page: !force ? 0 : this.props.map_filters?.page
        });

        if (
          !this.state.start_building &&
          (force ||
            !this.state.last_fetch_location ||
            getDistance(this.state.last_fetch_location, {
              latitude: bounds.centerLat,
              longitude: bounds.centerLng
            }) >
              2500 / this.state.updated_zoom ||
            this.state.updated_zoom !== this.state.last_fetch_location?.zoom ||
            this.props.map_properties.length == 0)
        ) {
          if (!this.state.zip) {
            this.reverseGeocode(null, () => {
              clearInterval(this._propertyFetchTimer);
              this._propertyFetchTimer = setTimeout(() => {
                this.getPropertyData(bounds, force, no_count);
                this.getRouteData(bounds, force, no_count);
              }, 100);
            });
          } else {
            clearInterval(this._propertyFetchTimer);
            this._propertyFetchTimer = setTimeout(() => {
              this.getPropertyData(bounds, force, no_count);
              this.getRouteData(bounds, force, no_count);
            }, 100);
          }
        }
      }
    );
  }

  reverseGeocode(property_count_loading_id, onDone = null) {
    this.props.getReverseGeocode({
      token: this.props.token,
      coordinate: {
        longitude: this.state.updated_longitude,
        latitude: this.state.updated_latitude
      },
      no_property: true,
      onLoading: () => {},
      onError: () => {},
      onSuccess: results => {
        if (results.parsed_address) {
          this.setState(
            {
              city: results.parsed_address?.city,
              state: results.parsed_address?.state,
              zip: results.parsed_address?.zip,
              fips: results?.fips ? results.fips : this.state.fips,
              fips_name: results?.fips_name
                ? results.fips_name
                : this.state.fips_name
            },
            () => {
              if (onDone) {
                onDone();
              }
            }
          );
        }
      }
    });
  }

  refreshMap() {
    this.setState(
      {
        properties_on_map_loading: false
      },
      () => {
        this.props.setMapProperties([]);
        this.getRouteData(this.state.bounds, true);
        this.getPropertyData(this.state.bounds, true);
      }
    );
  }

  getPropertyData(bounds, force = false, no_count = false, just_count = false) {
    if (
      (!!this.state.bounds?.westLng &&
        !!this.state.bounds?.southLat &&
        !!this.state.bounds?.eastLng &&
        !!this.state.bounds?.northLat) ||
      (this.state.bounds?.centerLat && this.state.bounds?.centerLng)
    ) {
      const get_bounds = {
        westLng: this.state.bounds?.westLng, // westLng - min lng
        southLat: this.state?.bounds?.southLat, // southLat - min lat
        eastLng: this.state?.bounds?.eastLng, // eastLng - max lng
        northLat: this.state?.bounds?.northLat,
        centerLat: this.state.bounds?.centerLat,
        centerLng: this.state.bounds?.centerLng
      };
      const property_loading_id = this.getLoadingId();
      const property_count_loading_id = this.getLoadingId();
      this.reverseGeocode(property_count_loading_id, () => {
        this.setState(
          {
            property_loading_id
          },
          () => {
            //remove coordinates from each item in search_locations
            const sl = this.props.map_filters?.search_locations?.map(item => {
              return { ...item, coordinates: null };
            });

            let map_params = {
              token: this.props.token,
              bounds: get_bounds,
              route_id: this.props.active_route
                ? this.props.active_route?.id
                : null,
              filters: this.props.applied_highlight,
              highlight: this.checkIfThereAreFilters() ? true : false,
              drawing_coordinates:
                this.props.map_filters?.drawing &&
                this.props.map_filters?.drawing_coordinates &&
                this.props.map_filters?.drawing_coordinates?.length > 0
                  ? this.props.map_filters?.drawing_coordinates
                  : null,
              limit: this.state.limit,
              page: !force ? 0 : this.props.map_filters?.page,
              sort_by: this.props.map_filters?.sort_by,
              load_type:
                this.checkIfThereAreFilters() && this.state.updated_zoom < 16
                  ? "highlights_only"
                  : "all_properties",
              property_flags:
                this.props.onboarding_map && !!this.props.onboarding_filter
                  ? this.props.onboarding_filter
                  : this.props.map_filters?.property_flags
                      .map(({ value }) => value)
                      .join(","),
              property_types:
                this.props.onboarding_map && !!this.props.onboarding_filter
                  ? "single_family"
                  : this.props.map_filters?.property_types
                      .map(({ value }) => value)
                      .join(","),
              property_flags_and_or:
                this.props.map_filters?.property_flags_and_or,
              value_range_min: !!this.props.map_filters?.value_range_min
                ? this.props.map_filters?.value_range_min
                : null,
              value_range_max: !!this.props.map_filters?.value_range_max
                ? this.props.map_filters?.value_range_max
                : null,
              price_type: this.props.map_filters?.price_type,
              beds_min: this.props.map_filters?.beds_min,
              baths_min: this.props.map_filters?.baths_min,
              use_beds_exact: this.props.map_filters?.use_beds_exact,
              viewing_type: this.state.updated_zoom < 13 ? "zip" : "view_area",
              zip: this.state.zip,
              search_locations: sl,
              include_owners:
                this.props.map_filters?.list_view_type === "contacts" &&
                this.props.map_filters?.include_owners,
              include_residents:
                this.props.map_filters?.list_view_type === "contacts" &&
                this.props.map_filters?.include_residents
            };
            //get the count
            if (!no_count || just_count) {
              const property_count_loading_id = this.getLoadingId();

              this.reverseGeocode(property_count_loading_id, null);
              this.setState(
                {
                  property_count_loading_id
                },
                () => {
                  this.props.getPropertiesOnMap({
                    ...map_params,
                    type: "properties_on_map_count",
                    onLoading: () => {
                      this.setState({
                        properties_on_map_count_loading: true
                      });

                      if (
                        this.props.onboarding_map &&
                        this.props.onOnboardingMapLoading
                      ) {
                        this.props.onOnboardingMapLoading(true);
                      }
                    },
                    onError: () => {
                      this.setState({
                        properties_on_map_count_loading: false
                      });

                      if (
                        this.props.onboarding_map &&
                        this.props.onOnboardingMapLoading
                      ) {
                        this.props.onOnboardingMapLoading(false);
                      }
                    },
                    onSuccess: results => {
                      if (
                        property_count_loading_id ===
                        this.state.property_count_loading_id
                      ) {
                        this.setState({
                          properties_on_map_count_loading: false,
                          properties_on_map_count:
                            results?.properties_on_map_count,
                          other_workspace_team_leads_count:
                            results?.other_workspace_team_leads_count
                        });

                        if (
                          this.props.onboarding_map &&
                          this.props.onOnboardingMapChange
                        ) {
                          this.props.onOnboardingMapChange({
                            properties_on_map_count:
                              results?.properties_on_map_count,
                            other_workspace_team_leads_count:
                              results?.other_workspace_team_leads_count
                          });
                        }
                      }
                    }
                  });
                }
              );
            }

            //get the properties
            if (!just_count) {
              this.props.getPropertiesOnMap({
                ...map_params,
                search_locations:
                  this.state.updated_zoom >= 16
                    ? null
                    : map_params.search_locations,
                drawing_coordinates:
                  this.state.updated_zoom >= 16
                    ? null
                    : map_params.drawing_coordinates,
                type: "properties_on_map",

                onLoading: () => {
                  this.setState({
                    properties_on_map_loading: true
                  });

                  if (
                    this.props.onboarding_map &&
                    this.props.onOnboardingMapLoading
                  ) {
                    this.props.onOnboardingMapLoading(true);
                  }
                },
                onError: () => {
                  this.setState({
                    properties_on_map_loading: false
                  });

                  if (
                    this.props.onboarding_map &&
                    this.props.onOnboardingMapLoading
                  ) {
                    this.props.onOnboardingMapLoading(false);
                  }
                },
                onSuccess: results => {
                  if (property_loading_id == this.state.property_loading_id) {
                    let new_properties_on_map = [];

                    for (
                      let i = 0;
                      i < results?.properties_on_map?.length;
                      i++
                    ) {
                      let found_property = false;

                      if (!found_property) {
                        new_properties_on_map.push({
                          ...results.properties_on_map[i],
                          property_label: renderLabelText({
                            property: results.properties_on_map[i],
                            property_label_type:
                              this.props.main_map_location?.property_label_type
                          })
                        });
                      }
                    }

                    this.props.setMapProperties(new_properties_on_map);
                    this.setState({
                      properties_on_map_loading: false,
                      last_fetch_location: {
                        latitude: bounds.centerLat,
                        longitude: bounds.centerLng,
                        zoom: this.state.updated_zoom
                      }
                    });

                    if (
                      this.props.onboarding_map &&
                      this.props.onOnboardingMapChange
                    ) {
                      /* this.props.onOnboardingMapChange({
                          properties_on_map_count:
                            results?.properties_on_map_count
                        }); */
                    }
                  }
                }
              });
            }
          }
        );
      });
    }
  }

  getRouteData(bounds, force = false) {
    const route_loading_id = this.getLoadingId();
    if (this.state.updated_zoom >= 12) {
      this.setState(
        {
          route_loading_id
        },
        () => {
          if (bounds) {
            this.props.getRoutesOnMap({
              token: this.props.token,
              route_id: this.props.active_route
                ? this.props.active_route?.id
                : null,

              bounds,
              dont_include_route_sections: this.props.current_route
                ? this.props.current_route.route_sections
                  ? this.props.current_route.route_sections
                      .map(section => {
                        return section.section_id;
                      })
                      .join()
                  : ""
                : "",
              onLoading: () => {
                this.setState({
                  routes_on_map_loading: true
                });
              },
              onError: () => {
                this.setState({
                  routes_on_map_loading: false
                });
              },
              onSuccess: results => {
                if (route_loading_id == this.state.route_loading_id) {
                  //remove old properties outside of bounds
                  let new_routes_on_map = [];
                  for (let i = 0; i < results?.routes_on_map?.length; i++) {
                    if (this.checkIfRouteInBounds(results?.routes_on_map[i])) {
                      new_routes_on_map.push(results?.routes_on_map[i]);
                    }
                  }

                  this.setState({
                    routes_on_map_loading: false,
                    routes_on_map: new_routes_on_map,
                    last_fetch_location: {
                      latitude: bounds.centerLat,
                      longitude: bounds.centerLng,
                      zoom: this.state.updated_zoom
                    }
                  });
                }
              }
            });
          }
        }
      );
    }
  }

  toggleExpanded(expanded) {
    this.setState(
      {
        expanded,
        refresh_map: true
      },
      () => {
        this.setState({
          refresh_map: false
        });
      }
    );
  }

  updateZoomProperties({ zoom, pitch, heading }) {
    const { map_properties } = this.props;

    //clean up leads and properties if zoomed out too far
    let new_properties_on_map = [];
    for (let i = 0; i < map_properties?.length; i++) {
      if (zoom < 11) {
        if (map_properties[i].deal && !map_properties[i].deal?.removed) {
          new_properties_on_map.push(map_properties[i]);
        }
      } else if (zoom < 12) {
        if (
          (map_properties[i].deal && !map_properties[i].deal?.removed) ||
          map_properties[i].highlighted
        ) {
          new_properties_on_map.push(map_properties[i]);
        }
      } else {
        new_properties_on_map.push(map_properties[i]);
      }
    }
    this.props.setMapProperties(map_properties);

    this.setState({
      updated_zoom: zoom,
      updated_pitch: pitch ? pitch : this.state.updated_pitch,
      updated_heading: heading ? heading : this.state.updated_heading
    });
  }

  updateLatLng({
    latitude,
    longitude,
    heading,
    zoom,
    add_location = null,
    coordinates = null
  }) {
    if (coordinates && coordinates?.length > 0) {
      let all_coords = [];
      for (let i = 0; i < coordinates?.length; i++) {
        const polygon = coordinates[i];
        for (let j = 0; j < polygon?.length; j++) {
          all_coords.push({
            latitude: polygon[j]?.latitude,
            longitude: polygon[j]?.longitude
          });
        }
      }
      if (all_coords?.length > 0) {
        const new_bounds = getBounds(all_coords);
        let map = null;
        if (this._map?.state?.map) {
          map = this._map?.state?.map;
        } else {
          map = this._map;
        }

        if (new_bounds && map) {
          map.fitBounds(
            [
              [
                parseFloat(new_bounds.minLng) - 0.001,
                parseFloat(new_bounds.minLat) - 0.001
              ], // southwestern corner of the bounds
              [
                parseFloat(new_bounds.maxLng) + 0.001,
                parseFloat(new_bounds.maxLat) + 0.001
              ] // northeastern corner of the bounds
            ],
            {
              duration: 1000
            }
          );
        }
      }
    } else {
      this.setState({
        longitude: longitude,
        latitude: latitude,
        updated_longitude: longitude,
        updated_latitude: latitude,
        pitch: 0,
        updated_pitch: 0,
        heading: heading,
        updated_heading: heading,
        zoom: zoom ? zoom : this.state.updated_zoom,
        updated_zoom: zoom ? zoom : this.state.updated_zoom
      });
    }
  }

  render() {
    const { all_leads_count, all_leads_count_loading, refresh_map } =
      this.state;
    const { colors, isMobile, driving_info_banner } = this.props;

    let highlight_count = this.props.map_properties?.filter(
      ({ highlighted }) => highlighted == true
    )?.length;

    let highlight_num = 0;
    for (let i = 0; i < this.props?.applied_highlight?.data?.length; i++) {
      for (
        let j = 0;
        j < this.props?.applied_highlight?.data[i]?.data?.length;
        j++
      ) {
        highlight_num++;
      }
    }

    return (
      <Container
        title={!this.props.onboarding_map ? "Map" : null}
        fill={!this.props.onboarding_map}
        style={
          this.props.select_virtual_drive_start && this.state.street_timer
            ? {
                display: "flex",
                overflow: "hidden",
                backgroundColor: colors.card_color,
                position: "fixed",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
              }
            : {
                display: "flex",
                overflow: "hidden",
                backgroundColor: colors.card_color
              }
        }
      >
        <Row style={{ flex: 1, overflow: "hidden" }}>
          <Wrapper
            style={{ flex: 1, alignSelf: "stretch", position: "relative" }}
          >
            {this.props.select_virtual_drive_start &&
            this.state.street_timer ? (
              <Street onboarding_map={this.props.onboarding_map} />
            ) : (
              <Row
                style={{
                  borderBottomWidth: 1,
                  borderBottomStyle: "solid",
                  borderBottomColor: colors.border_color
                }}
              >
                {!this.props.onboarding_map ? (
                  <Wrapper
                    style={{
                      flex: 1,
                      alignSelf: "stretch",
                      position: "relative"
                    }}
                    id="search-bar"
                  >
                    <AutocompleteSearchV2
                      search_focused={this.state.search_focused}
                      updateSearchFocus={this.updateSearchFocus}
                      toggleDrawing={this.toggleDrawing}
                      updateLatLng={this.updateLatLng}
                      centerLat={this.state.updated_latitude}
                      centerLng={this.state.updated_longitude}
                      placeholder={
                        "Search for an address, zip code, city or county"
                      }
                      borderRadius={5}
                      include_add_lead_button={!this.props.onboarding_map}
                      onboarding_map={this.props.onboarding_map}
                      and_push_property={true}
                      style={
                        isMobile
                          ? {
                              marginRight: 0,
                              padding: 15,
                              paddingRight: 0,
                              paddingLeft: 0
                            }
                          : this.props.onboarding_map
                          ? {}
                          : {
                              marginRight: 0
                            }
                      }
                    />
                  </Wrapper>
                ) : null}
                {!this.props.onboarding_map ? (
                  <>
                    <QuickFilters
                      style={{
                        marginRight: 0
                      }}
                      show_quick_filters={this.state.show_quick_filters}
                      updateMainState={this.updateMainState}
                      map_filters={this.props.map_filters}
                      updateMapFilters={this.props.updateMapFilters}
                      select_drive_start={this.state.select_drive_start}
                    />
                    <PropertyTypes
                      style={{
                        marginRight: 0
                      }}
                      pushSidePanel={this.props.pushSidePanel}
                      show_property_types={this.state.show_property_types}
                      updateMainState={this.updateMainState}
                      map_filters={this.props.map_filters}
                      updateMapFilters={this.props.updateMapFilters}
                      select_drive_start={this.state.select_drive_start}
                    />
                    <PriceRange
                      style={{
                        marginRight: 0
                      }}
                      show_price_range={this.state.show_price_range}
                      updateMainState={this.updateMainState}
                      map_filters={this.props.map_filters}
                      updateMapFilters={this.props.updateMapFilters}
                      select_drive_start={this.state.select_drive_start}
                    />
                    <BedsAndBathsContainer
                      style={{
                        marginRight: 0
                      }}
                      show_beds_and_baths={this.state.show_beds_and_baths}
                      updateMainState={this.updateMainState}
                      map_filters={this.props.map_filters}
                      updateMapFilters={this.props.updateMapFilters}
                      select_drive_start={this.state.select_drive_start}
                    />

                    <InlineButton
                      disabled={
                        this.state.select_drive_start ||
                        (this.props.map_filters?.drawing &&
                          this.props.map_filters?.drawing_coordinates?.length <=
                            2)
                      }
                      style={
                        this.props.applied_highlight?.data?.length > 0
                          ? {
                              borderRadius: 5,
                              borderWidth: 1,
                              borderColor: colors.active_color,
                              borderStyle: "solid",
                              marginRight: 0
                            }
                          : {
                              borderRadius: 5,
                              marginRight: 0
                            }
                      }
                      onPress={() => {
                        this.props.pushSidePanel({
                          slug: "more_filters"
                        });

                        this.hideAllPopovers();
                      }}
                    >
                      More
                      {this.getMoreFiltersCount() > 0
                        ? " [" + this.getMoreFiltersCount() + "]"
                        : null}
                    </InlineButton>
                    <ConfirmInlineButton
                      confirm_text="Really Clear?"
                      confirm_icon="clear"
                      loading_text="Clearing"
                      loading={false}
                      disabled={!this.checkIfThereAreFilters()}
                      style={{
                        borderRadius: 5
                      }}
                      onConfirmDisplay={() => {
                        this.hideAllPopovers();
                      }}
                      onPress={() => {
                        this.hideAllPopovers();

                        this.props.setAppliedHighlight({
                          applied_highlight: {
                            data: [],
                            andor_type: "or"
                          }
                        });
                        this.props.updateMapFilters({
                          property_flags: [],
                          property_types: [],
                          property_flags_and_or: "or",
                          beds_min: null,
                          baths_min: null,
                          use_beds_exact: false,
                          value_range_min: "",
                          value_range_max: ""
                        });
                      }}
                    >
                      Clear All
                    </ConfirmInlineButton>
                    <SavedFiltersContainer
                      ableToSaveSearch={this.ableToSaveSearch}
                      map_filters={this.props.map_filters}
                      updateMainState={this.updateMainState}
                      show_more_options={this.state.show_more_options}
                    />
                  </>
                ) : null}

                {!this.props.onboarding_map ? (
                  <>
                    {/*!this.props.track_route || !this.props.current_route ? (
                      <InlineButton
                        primary={!this.state.select_drive_start}
                        icon={
                          !this.state.select_drive_start ? "drive-eta" : "close"
                        }
                        onPress={() => {
                          this.toggleSelectDriveStart(
                            !this.state.select_drive_start
                          );
                        }}
                      >
                        {!this.state.select_drive_start
                          ? "Start Driving"
                          : "Cancel"}
                      </InlineButton>
                    ) : this.props.track_route &&
                      this.props.current_route &&
                      this.props.track_route_type == "virtual" &&
                      !this.props.select_virtual_drive_start ? (
                      <InlineButton
                        primary={true}
                        onPress={() => {
                          this.toggleSelectDriveStart(true);
                          this.updateLatLng({
                            latitude: this.props.street_view_coords.latitude,
                            longitude: this.props.street_view_coords.longitude
                          });
                        }}
                      >
                        Continue Drive
                      </InlineButton>
                    ) : null*/}

                    {/*!this.state.search_focused && this.props.user ? (
                      <UpsellButton
                        contentful_slug="unlock_feature"
                        meta_slug="driving_for_dollars"
                        onPress={() => {
                          this.props.pushSidePanel({
                            slug: "routes"
                          });
                        }}
                        style={{
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                        renderLockedChildren={({ hovering }) => {
                          return (
                            <>
                              <InlineButton
                                icon={"alt-route"}
                                noPress={true}
                                pressedIn={hovering}
                                style={{ marginLeft: 0 }}
                              >
                                Drives
                              </InlineButton>
                            </>
                          );
                        }}
                        renderChildren={({ hovering }) => {
                          return (
                            <>
                              <InlineButton
                                icon={"alt-route"}
                                noPress={true}
                                pressedIn={hovering}
                                show_notification={highlight_num > 0}
                                notification_color={colors.orange_color}
                                notification_number={highlight_num}
                                style={{ marginLeft: 0 }}
                              >
                                Drives
                              </InlineButton>
                            </>
                          );
                        }}
                      />
                    ) : null*/}
                  </>
                ) : null}
              </Row>
            )}

            <Row
              style={
                this.props.select_virtual_drive_start && this.state.street_timer
                  ? {
                      position: "absolute",
                      width: 300,
                      height: 150,
                      bottom: 5,
                      left: 5,
                      borderRadius: 5,
                      overflow: "hidden",
                      zIndex: 1
                    }
                  : { flex: 1, alignSelf: "stretch", overflow: "hidden" }
              }
            >
              <Wrapper
                style={
                  this.props.select_virtual_drive_start &&
                  this.state.street_timer
                    ? {
                        width: 300,
                        height: 150
                      }
                    : {
                        flex: 1,
                        alignSelf: "stretch",
                        position: "relative",
                        minWidth: this.props.onboarding_map ? 300 : 700
                      }
                }
                id="map-wrapper"
              >
                <NativeMainMap
                  setMapRef={map => {
                    this._map = map;
                  }}
                  refresh_map={refresh_map}
                  latitude={this.state.latitude}
                  longitude={this.state.longitude}
                  zoom={this.state.updated_zoom}
                  minZoom={this.state.minZoom}
                  maxZoom={17.9}
                  pitch={this.state.pitch}
                  heading={this.state.heading}
                  updateCenter={this.updateCenter}
                  updateBounds={this.updateBounds}
                  drawing={this.props.map_filters?.drawing}
                  onDraw={this.onDraw}
                  drawingCreated={this.drawingCreated}
                  drawing_coordinates={this.props.drawing_coordinates}
                  toggleDrawing={this.toggleDrawing}
                  updateZoomProperties={this.updateZoomProperties}
                  map_type={this.props.main_map_location?.map_type}
                  routes_on_map={this.state.routes_on_map}
                  highlight={
                    this.checkIfThereAreFilters() &&
                    this.state.updated_zoom >= 16
                  }
                  renderPropertyPopover={popover_info => {
                    return (
                      <PropertyPopover
                        toggleSelectDriveStart={this.toggleSelectDriveStart}
                        no_action_button={
                          this.props.onboarding_map &&
                          !!this.props.onboarding_filter
                        }
                        select_drive_start={
                          this.state.select_drive_start ||
                          this.props.onboarding_map
                        }
                        highlight={
                          this.checkIfThereAreFilters() &&
                          this.state.updated_zoom >= 16
                        }
                        onboarding_map={this.props.onboarding_map}
                        popover_info={popover_info}
                        zoom={this.state.updated_zoom}
                        pushMapSidePanel={this.pushMapSidePanel}
                        togglePopover={this.togglePopover}
                        updateLatLng={this.updateLatLng}
                        setStreetViewCoords={this.props.setStreetViewCoords}
                        propertyUpdated={property => {
                          this.props.setMapProperties(
                            this.props.map_properties.map(p => {
                              if (property?.property_id == p.property_id) {
                                return {
                                  ...p,
                                  ...property
                                };
                              }
                              return p;
                            })
                          );
                        }}
                      />
                    );
                  }}
                  active_property={
                    this.props.select_virtual_drive_start
                      ? null
                      : this.state.active_property
                  }
                  onMapPress={
                    this.props.select_virtual_drive_start
                      ? () => {}
                      : property => {
                          this.setActiveProperty(property);
                        }
                  }
                  show_popover={this.state.show_popover}
                  popover_info={this.state.popover_info}
                  togglePopover={this.togglePopover}
                  pushMapSidePanel={this.pushMapSidePanel}
                  select_drive_start={this.state.select_drive_start}
                  toggleSelectDriveStart={this.toggleSelectDriveStart}
                  show_routes_on_map={
                    this.props.main_map_location?.show_routes_on_map
                  }
                  show_mls_on_map={
                    this.props.main_map_location?.show_mls_on_map
                  }
                  search_locations={this.props.map_filters?.search_locations}
                />

                {!this.props.select_virtual_drive_start ? (
                  <>
                    <Wrapper
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        left: 0,
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <InlineButton
                        onPress={this.refreshMap}
                        loading={this.state.properties_on_map_loading}
                        icon="refresh"
                        b_color={
                          this.props.dark_mode === "dark_mode"
                            ? "rgba(0,0,0,0.75)"
                            : "rgba(255,255,255,0.9)"
                        }
                      >
                        {this.state.properties_on_map_loading
                          ? "Loading"
                          : "Reload Map"}
                      </InlineButton>
                    </Wrapper>
                    {!this.props.onboarding_map ? (
                      <Wrapper
                        style={{ position: "absolute", top: 0, left: 0 }}
                      >
                        <CircleButton
                          onPress={() => {
                            this.props.updateMapFilters({
                              drawing:
                                this.props.map_filters?.drawing_coordinates
                                  ?.length > 0
                                  ? false
                                  : !this.props.map_filters?.drawing,
                              drawing_created: false,
                              drawing_coordinates: []
                            });

                            this.togglePopover(false);
                            this.hideAllPopovers();
                          }}
                          icon={
                            this.props.map_filters?.drawing_coordinates
                              ?.length > 0
                              ? "delete-forever"
                              : this.props.map_filters?.drawing
                              ? "delete-forever"
                              : this.props.device == "desktop"
                              ? "draw"
                              : "edit"
                          }
                          iconColor={colors.actionable_text_color}
                          disabled={
                            this.state.updated_zoom < this.state.minZoom ||
                            this.state.properties_on_map_count_loading ||
                            this.state.select_drive_start
                          }
                          pressedIn={
                            this.props.map_filters?.drawing ||
                            this.props.map_filters?.drawing_coordinates
                              ?.length > 2
                          }
                          tooltip={
                            this.props.map_filters?.drawing
                              ? this.props.map_filters?.drawing_coordinates
                                  ?.length == 0
                                ? "Cancel Drawing"
                                : "Clear Drawing"
                              : this.state.updated_zoom < this.state.minZoom
                              ? "Zoom in to activate drawing tool"
                              : "Start Drawing"
                          }
                          tooltipPlacement={"top"}
                        />

                        {!this.props.map_filters?.drawing &&
                        (!this.props.map_filters?.drawing_coordinates ||
                          this.props.map_filters?.drawing_coordinates?.length ==
                            0) ? (
                          <>
                            <CircleButton
                              tooltip={"Start Driving"}
                              tooltipPlacement={"top"}
                              icon={"drive-eta"}
                              primary={true}
                              disabled={
                                this.props.map_filters?.drawing ||
                                this.state.properties_on_map_loading
                              }
                              pressedIn={this.state.select_drive_start}
                              onPress={() => {
                                this.toggleSelectDriveStart(
                                  !this.state.select_drive_start
                                );
                                this.hideAllPopovers();
                              }}
                            />

                            <CircleButton
                              tooltip={"Toggle Past Drives"}
                              tooltipPlacement={"top"}
                              icon={"alt-route"}
                              disabled={
                                this.props.map_filters?.drawing ||
                                this.state.properties_on_map_loading
                              }
                              pressedIn={this.state.show_past_drives}
                              onPress={() => {
                                this.setState({
                                  show_past_drives: !this.state.show_past_drives
                                });
                                this.hideAllPopovers();
                              }}
                            />
                          </>
                        ) : null}
                      </Wrapper>
                    ) : null}
                    {!this.props.onboarding_map ? (
                      <Wrapper
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0
                        }}
                      >
                        <PopoverMenu
                          show={this.state.show_options}
                          allow_multiple={true}
                          no_swipe={true}
                          no_cancel={true}
                          onShow={s => {
                            this.setState({
                              show_options: s
                            });
                          }}
                          popover_width={400}
                          popoverSheetTop={"75%"}
                          popoverPlacement={"bottom"}
                          popover_title={"Map Options"}
                          includeCloseButton={true}
                          hideWithOutsideClick={false}
                          renderComponent={options => {
                            return (
                              <CircleButton
                                tooltip={"Map Options"}
                                tooltipPlacement={"top"}
                                icon={"map"}
                                noPress={true}
                                pressedIn={
                                  options.hovering ||
                                  options.pressedIn ||
                                  this.state.show_options
                                }
                              />
                            );
                          }}
                          renderMenu={() => {
                            return (
                              <Wrapper style={{ alignSelf: "stretch" }}>
                                <MapOptions
                                  hideMapOptions={() => {
                                    this.setState({ show_options: false });
                                  }}
                                />
                                {this.props.device == "desktop" ? (
                                  <CloseButton
                                    onPress={() => {
                                      this.setState({
                                        show_options: false
                                      });
                                    }}
                                  />
                                ) : null}
                              </Wrapper>
                            );
                          }}
                          menu_items={null}
                        />

                        <Wrapper
                          style={{
                            backgroundColor: this.props.colors.popover_color,
                            borderRadius: 30,
                            borderColor: this.props.colors.border_color,
                            borderWidth: 1,
                            borderStyle: "solid",
                            margin: 5
                          }}
                        >
                          <IconButton
                            style={{
                              width: 36,
                              height: 36
                            }}
                            onPress={() => {
                              this.setState({
                                updated_zoom:
                                  parseInt(this.state.updated_zoom) + 1
                              });
                            }}
                            size={22}
                            icon_color={this.props.colors.actionable_text_color}
                            icon="add"
                          />
                          <IconButton
                            style={{
                              width: 36,
                              height: 36
                            }}
                            onPress={() => {
                              this.setState({
                                updated_zoom:
                                  parseInt(this.state.updated_zoom) - 1
                              });
                            }}
                            size={22}
                            icon_color={this.props.colors.actionable_text_color}
                            icon="remove"
                          />
                        </Wrapper>
                      </Wrapper>
                    ) : null}
                  </>
                ) : null}
                {(!this.props.select_virtual_drive_start ||
                  !this.state.street_timer) &&
                !this.props.onboarding_map &&
                (!this.state.show_past_drives ||
                  this.state.select_drive_start) ? (
                  <Wrapper
                    style={
                      checkIfUserHasMetadata("ai_list_builder")
                        ? {
                            position: "absolute",
                            bottom: 10,
                            left: 0,
                            right: 0,
                            overflow: "hidden"
                          }
                        : {
                            position: "absolute",
                            bottom: 10,
                            left: 25,
                            right: 25,
                            overflow: "hidden"
                          }
                    }
                  >
                    <Wrapper
                      style={{ margin: 5, marginBottom: 10, marginTop: 15 }}
                    >
                      {this.state.select_drive_start ? (
                        <Card style={{ margin: 0 }}>
                          <SelectItem
                            primary={true}
                            select_type="icon"
                            style={{
                              margin: 0
                            }}
                            innerStyle={{ padding: 0, paddingLeft: 25 }}
                            renderLeft={() => {
                              return (
                                <InternalImage
                                  style={{
                                    height: 30,
                                    paddingLeft: 25,
                                    paddingRight: 0
                                  }}
                                  resizeMode={"contain"}
                                  source={
                                    this.props.device == "mobile"
                                      ? require("app/assets/images/person_pin_orange.png")
                                      : null
                                  }
                                  image={
                                    this.props.device == "desktop"
                                      ? "/assets/images/person_pin_orange.png"
                                      : ""
                                  }
                                />
                              );
                            }}
                            noPress={true}
                            renderRight={() => {
                              return (
                                <InlineButton
                                  button_type={"full"}
                                  onPress={() => {
                                    this.toggleSelectDriveStart(false);
                                  }}
                                >
                                  Cancel
                                </InlineButton>
                              );
                            }}
                            description="Click an area on the map to choose where your drive will start."
                          >
                            Select an area on the map
                          </SelectItem>
                        </Card>
                      ) : (
                        <>
                          {checkIfUserHasMetadata("ai_list_builder") ? (
                            <AIListBuilder
                              applied_highlight={this.props.applied_highlight}
                              properties_on_map_count={
                                this.state.properties_on_map_count
                              }
                              other_workspace_team_leads_count={
                                this.state.other_workspace_team_leads_count
                              }
                              properties_on_map_count_loading={
                                this.state.properties_on_map_count_loading
                              }
                              zoom={this.state.updated_zoom}
                              drawing_coordinates={
                                this.props.map_filters?.drawing_coordinates
                              }
                              checkIfThereAreFilters={
                                this.checkIfThereAreFilters
                              }
                              bounds={this.state.bounds}
                              drawing={this.props.map_filters?.drawing}
                              zip={this.state.zip}
                              city={this.state.city}
                              state={this.state.state}
                              fips={this.state.fips}
                              fips_name={this.state.fips_name}
                              select_drive_start={this.state.select_drive_start}
                              updateMainState={this.updateMainState}
                              updateMapFilters={this.props.updateMapFilters}
                              start_building={this.state.start_building}
                              property_flags={
                                this.props.map_filters?.property_flags
                              }
                              property_types={
                                this.props.map_filters?.property_types
                              }
                              property_flags_and_or={
                                this.props.map_filters?.property_flags_and_or
                              }
                              value_range_min={
                                this.props.map_filters.value_range_min
                              }
                              value_range_max={
                                this.props.map_filters.value_range_max
                              }
                              price_type={this.props.map_filters?.price_type}
                              beds_min={this.props.map_filters?.beds_min}
                              baths_min={this.props.map_filters?.baths_min}
                              use_beds_exact={
                                this.props.map_filters?.use_beds_exact
                              }
                              search_locations={
                                this.props.map_filters?.search_locations
                              }
                              refreshMap={this.refreshMap}
                              updateLatLng={this.updateLatLng}
                              updateBounds={this.updateBounds}
                              list_view_type={
                                this.props.map_filters?.list_view_type
                              }
                            />
                          ) : (
                            <>
                              <ListBuilder
                                applied_highlight={this.props.applied_highlight}
                                properties_on_map_count={
                                  this.state.properties_on_map_count
                                }
                                other_workspace_team_leads_count={
                                  this.state.other_workspace_team_leads_count
                                }
                                properties_on_map_count_loading={
                                  this.state.properties_on_map_count_loading
                                }
                                zoom={this.state.updated_zoom}
                                drawing_coordinates={
                                  this.props.map_filters?.drawing_coordinates
                                }
                                checkIfThereAreFilters={
                                  this.checkIfThereAreFilters
                                }
                                bounds={this.state.bounds}
                                drawing={this.props.map_filters?.drawing}
                                zip={this.state.zip}
                                city={this.state.city}
                                state={this.state.state}
                                fips={this.state.fips}
                                fips_name={this.state.fips_name}
                                select_drive_start={
                                  this.state.select_drive_start
                                }
                                updateMainState={this.updateMainState}
                                updateMapFilters={this.props.updateMapFilters}
                                start_building={this.state.start_building}
                                property_flags={
                                  this.props.map_filters?.property_flags
                                }
                                property_types={
                                  this.props.map_filters?.property_types
                                }
                                property_flags_and_or={
                                  this.props.map_filters?.property_flags_and_or
                                }
                                value_range_min={
                                  this.props.map_filters.value_range_min
                                }
                                value_range_max={
                                  this.props.map_filters.value_range_max
                                }
                                price_type={this.props.map_filters?.price_type}
                                beds_min={this.props.map_filters?.beds_min}
                                baths_min={this.props.map_filters?.baths_min}
                                use_beds_exact={
                                  this.props.map_filters?.use_beds_exact
                                }
                                search_locations={
                                  this.props.map_filters?.search_locations
                                }
                                refreshMap={this.refreshMap}
                                updateLatLng={this.updateLatLng}
                                updateBounds={this.updateBounds}
                                list_view_type={
                                  this.props.map_filters?.list_view_type
                                }
                              />
                            </>
                          )}
                        </>
                      )}
                    </Wrapper>
                  </Wrapper>
                ) : null}
              </Wrapper>
              {(!this.props.select_virtual_drive_start ||
                !this.state.street_timer) &&
              !this.props.onboarding_map ? (
                <Wrapper
                  style={{
                    width: 500,
                    alignSelf: "stretch",
                    borderLeftWidth: 1,
                    borderLeftStyle: "solid",
                    borderLeftColor: colors.border_color,
                    position: "relative"
                  }}
                >
                  <PropertyList
                    properties={this.props.map_properties}
                    properties_on_map_loading={
                      this.state.properties_on_map_loading
                    }
                    sort_by={this.props.map_filters?.sort_by}
                    map_filters={this.props.map_filters}
                    updateMapFilters={this.props.updateMapFilters}
                    properties_on_map_count={this.state.properties_on_map_count}
                    other_workspace_team_leads_count={
                      this.state.other_workspace_team_leads_count
                    }
                    properties_on_map_count_loading={
                      this.state.properties_on_map_count_loading
                    }
                    disable_sort_by={
                      this.state.updated_zoom < 13 ||
                      this.props.map_filters?.search_locations?.length > 1
                    }
                    page={this.props.map_filters?.page}
                    limit={this.state.limit}
                    properties_on_map_length={this.props.map_properties?.length}
                    highlight={
                      this.checkIfThereAreFilters() &&
                      this.state.updated_zoom >= 16
                    }
                    show_past_drives={this.state.show_past_drives}
                    hideAllPopovers={this.hideAllPopovers}
                    updateLatLng={this.updateLatLng}
                  />
                  {this.state.properties_on_map_loading ? (
                    <Wrapper
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        backgroundColor:
                          this.props.dark_mode === "dark_mode"
                            ? "rgba(0, 0, 0, 0.25)"
                            : "rgba(255, 255, 255, 0.5)"
                      }}
                    >
                      <Wrapper
                        style={{
                          width: "100%",
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <SpinWrapper text={"Loading"} />
                      </Wrapper>
                    </Wrapper>
                  ) : null}
                </Wrapper>
              ) : null}
            </Row>
          </Wrapper>

          {!this.props.isMobile && !this.props.select_virtual_drive_start ? (
            <Wrapper
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                left: 0,
                borderRadius: 5
              }}
            ></Wrapper>
          ) : null}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({
  auth,
  native,
  settings,
  property_map,
  route,
  billing,
  onboarding,
  modal
}) => {
  const { token, user } = auth;
  const { device, window_height, isMobile, desktopMobile } = native;
  const { colors, dark_mode } = settings;
  const { driving_info_banner, source_of_truth, all_feature_metadata } =
    billing;
  const { side_panel_views } = modal;
  const {
    applied_highlight,
    street_view_coords,
    map_properties,
    main_map_location,
    map_filters
  } = property_map;
  const {
    current_route,
    track_route,
    routes,
    active_route,
    track_route_type,
    select_virtual_drive_start
  } = route;

  return {
    token,
    user,
    device,
    isMobile,
    window_height,
    colors,
    dark_mode,
    desktopMobile,
    applied_highlight,
    street_view_coords,
    map_properties,
    current_route,
    track_route,
    track_route_type,
    select_virtual_drive_start,
    routes,
    active_route,
    main_map_location,
    driving_info_banner,
    onboarding,
    source_of_truth,
    all_feature_metadata,
    map_filters
  };
};

export default connect(mapStateToProps, {
  getPropertiesOnMap,
  getRoutesOnMap,
  setMapProperties,
  updateMainMapLocation,
  updateUserSettings,
  setStreetViewCoords,
  setPricingPage,
  getBilling,
  pushSidePanel,
  completePopSidePanel,
  askLocationPermissionsThenContinue,
  popAllSidePanels,
  nextOnboardingStep,
  setAppliedHighlight,
  replaceSidePanel,
  getPropertyNew,
  showErrorMessage,
  loginUser,
  registerUser,
  getUserFromAppleAuth,
  toggleVirtualDriveStart,
  getReverseGeocode,
  updateMapFilters,
  getSourceOfTruth
})(MainMap);
