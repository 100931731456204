import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  CardBody,
  Copy,
  KeyboardView
} from "app/NativeComponents/common";
import { AttentionBox } from "app/NativeComponents/snippets";

import PermissionSettings from "./PermissionSettings";
import SaveButton from "./SaveButton";

import { checkIfUserHasModule, popSidePanel } from "app/NativeActions";

class Permissions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      edit_team_member: {
        ...props.active_team_member,
        can_approve_mail: props.active_team_member.can_approve_mail,
        can_enhanced_search: props.active_team_member.can_enhanced_search,
        can_edit_templates: props.active_team_member.can_edit_templates,
        can_see_all_deals: props.active_team_member.can_see_all_deals,
        can_export_data: props.active_team_member.can_export_data,
        can_mimic_team_owner: props.active_team_member.can_mimic_team_owner,
        can_edit_custom_fields: props.active_team_member.can_edit_custom_fields,
        can_build_lists: props.active_team_member.can_build_lists,
        can_see_dashboard: props.active_team_member.can_see_dashboard,
        can_send_message: props.active_team_member.can_send_message,
        can_edit_custom_fields: props.active_team_member.can_edit_custom_fields,
        can_build_lists: props.active_team_member.can_build_lists,
        can_see_dashboard: props.active_team_member.can_see_dashboard
      }
    };
  }

  editTeamMember({ prop, value }) {
    this.setState({
      edit_team_member: {
        ...this.state.edit_team_member,
        [prop]: value
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.active_team_member.id !== prevState.edit_team_member.id) {
      this.setState({
        edit_team_member: {
          ...this.props.active_team_member,
          can_approve_mail: this.props.active_team_member.can_approve_mail,
          can_enhanced_search:
            this.props.active_team_member.can_enhanced_search,
          can_edit_templates: this.props.active_team_member.can_edit_templates,
          can_see_all_deals: this.props.active_team_member.can_see_all_deals,
          can_export_data: this.props.active_team_member.can_export_data,
          can_send_message: this.props.active_team_member.can_send_message,
          can_mimic_team_owner:
            this.props.active_team_member.can_mimic_team_owner,
          can_build_lists: this.props.active_team_member.can_build_lists,
          can_see_dashboard: this.props.active_team_member.can_see_dashboard,
          can_edit_custom_fields:
            this.props.active_team_member.can_edit_custom_fields,
          can_build_lists: this.props.active_team_member.can_build_lists,
          can_see_dashboard: this.props.active_team_member.can_see_dashboard
        }
      });
    }

    if (prevProps.user && prevProps.plan_modules) {
      if (
        this.props.plan_modules !== prevProps.plan_modules ||
        this.props.user.plan_modules !== prevProps.user.plan_modules
      ) {
        const plan_module_info = checkIfUserHasModule({
          plan_modules: this.props.plan_modules,
          user: this.props.user,
          slug: "driving"
        });
        this.setState({ plan_module_info: plan_module_info });
      }
    }
  }

  checkIfNeedsToSave() {
    if (
      this.props.active_team_member.can_approve_mail !=
        this.state.edit_team_member.can_approve_mail ||
      this.props.active_team_member.can_enhanced_search !=
        this.state.edit_team_member.can_enhanced_search ||
      this.props.active_team_member.can_see_all_deals !=
        this.state.edit_team_member.can_see_all_deals ||
      this.props.active_team_member.can_edit_templates !=
        this.state.edit_team_member.can_edit_templates ||
      this.props.active_team_member.can_export_data !=
        this.state.edit_team_member.can_export_data ||
      this.props.active_team_member.can_send_message !=
        this.state.edit_team_member.can_send_message ||
      this.props.active_team_member.can_mimic_team_owner !=
        this.state.edit_team_member.can_mimic_team_owner ||
      this.props.active_team_member.can_edit_custom_fields !=
        this.state.edit_team_member.can_edit_custom_fields ||
      this.props.active_team_member.can_build_lists !=
        this.state.edit_team_member.can_build_lists ||
      this.props.active_team_member.can_see_dashboard !=
        this.state.edit_team_member.can_see_dashboard
    ) {
      return true;
    }

    return false;
  }

  savePermissions() {
    this.props.updateTeamMembers({
      token: this.props.token,
      type: "edit_permissions",
      team_member_id: this.props.active_team_member.id,
      member_type: "team_member",
      can_approve_mail: this.state.edit_team_member.can_approve_mail,
      can_enhanced_search: this.state.edit_team_member.can_enhanced_search,
      can_see_all_deals: this.state.edit_team_member.can_see_all_deals,
      can_edit_templates: this.state.edit_team_member.can_edit_templates,
      can_export_data: this.state.edit_team_member.can_export_data,
      can_send_message: this.state.edit_team_member.can_send_message,
      can_mimic_team_owner: this.state.edit_team_member.can_mimic_team_owner,
      can_build_lists: this.state.edit_team_member.can_build_lists,
      can_see_dashboard: this.state.edit_team_member.can_see_dashboard,
      can_edit_custom_fields:
        this.state.edit_team_member.can_edit_custom_fields,
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: () => {
        this.setState({
          loading: false
        });
        this.props.popSidePanel();
      }
    });
  }

  render() {
    //if (this.props.tab == "permissions") {
    if (this.props.active_team_member.id == this.props.user.id) {
      return (
        <KeyboardView style={{ flex: 1 }}>
          <AttentionBox description="You cannot edit your own permissions." />
        </KeyboardView>
      );
    } else if (this.props.active_team_member.team_owner == 1) {
      return (
        <KeyboardView style={{ flex: 1 }}>
          <AttentionBox description="You cannot edit the team leader's permissions." />
        </KeyboardView>
      );
    }

    if (this.props.user.can_mimic_team_owner == 0) {
      return (
        <KeyboardView style={{ flex: 1 }}>
          <AttentionBox description="You do not have permission to edit user permissions." />
        </KeyboardView>
      );
    }

    return (
      <>
        <SaveButton
          loading={this.state.loading}
          savePermissions={this.savePermissions.bind(this)}
          checkIfNeedsToSave={this.checkIfNeedsToSave.bind(this)}
          {...this.props}
        />
        <KeyboardView style={{ flex: 1 }}>
          <PermissionSettings
            edit_team_member={this.state.edit_team_member}
            editTeamMember={this.editTeamMember.bind(this)}
            {...this.props}
          />
        </KeyboardView>
      </>
    );
    //}

    //return null;
  }
}

const mapStateToProps = ({ auth, billing }) => {
  const { token, user } = auth;
  const { plan_modules, card_info } = billing;
  return {
    token,
    user,
    plan_modules,
    card_info
  };
};

export default connect(mapStateToProps, { popSidePanel })(Permissions);
