import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Bold,
  Container,
  Copy,
  Icon,
  KeyboardView,
  Row,
  Title,
  Wrapper,
  Spin
} from "app/NativeComponents/common";
import {
  pushSidePanel,
  replaceSidePanel,
  changeTab,
  numberWithCommas,
  getTeam,
  getDashboardStats,
  updateUserSettings,
  getMailerTemplates,
  getMailerCampaigns,
  checkIfUserHasMetadata
} from "app/NativeActions";

import MainHeader from "app/DealMachineCore/reuseable/MainHeader";
import {
  DateRangePicker,
  InlineButton,
  InlineTabs,
  PopoverMenu,
  SearchBar,
  SelectItem,
  SpinWrapper
} from "app/NativeComponents/snippets";

import StatBlock from "app/DealMachineCore/components/Dashboard/NewDashboard/StatBlock";
import PropertiesAdded from "app/DealMachineCore/components/Dashboard/NewDashboard/PropertiesAdded";
import CallsMade from "app/DealMachineCore/components/Dashboard/NewDashboard/CallsMade";
import MailSent from "app/DealMachineCore/components/Dashboard/NewDashboard/MailSent";

import moment from "moment";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active_leads_loading: false,
      properties_added_loading: false,
      calls_made_loading: false,
      mail_sent_loading: false,
      activity_tab: "log",
      team_leads_search: "",
      team_activity_search: "",
      stat_tab: props.user?.user_settings?.workspace_dashboard_settings
        ? props.user?.user_settings?.workspace_dashboard_settings.stat_tab
        : "properties_added",
      filter_activity_team: props.user?.user_settings
        ?.workspace_dashboard_settings
        ? props.user?.user_settings?.workspace_dashboard_settings
            .filter_activity_team
        : null,

      filter_activity_date: props.user?.user_settings
        ?.workspace_dashboard_settings
        ? props.user?.user_settings?.workspace_dashboard_settings
            .filter_activity_date
        : "this_month",
      filter_activity_start_date: props.user?.user_settings
        ?.workspace_dashboard_settings
        ? props.user?.user_settings?.workspace_dashboard_settings
            .filter_activity_start_date
        : null,
      filter_activity_end_date: props.user?.user_settings
        ?.workspace_dashboard_settings
        ? props.user?.user_settings?.workspace_dashboard_settings
            .filter_activity_end_date
        : null,

      isCalendarOpen: false,
      include_properties:
        props.user?.user_settings?.workspace_dashboard_settings &&
        props.user?.user_settings?.workspace_dashboard_settings
          ?.include_properties
          ? props.user?.user_settings?.workspace_dashboard_settings
              .include_properties
          : [
              "properties_added_from_driving",
              "pulled_properties",
              "imported_properties",
              "other_properties"
            ],

      include_call_statuses:
        props.user?.user_settings?.workspace_dashboard_settings &&
        props.user?.user_settings?.workspace_dashboard_settings
          ?.include_call_statuses
          ? props.user?.user_settings?.workspace_dashboard_settings
              .include_call_statuses
          : [
              "no_answer",
              "left_voicemail",
              "wrong_contact",
              "wrong_number",
              "not_interested",
              "unqualified",
              "warm_lead",
              "hot_lead",
              "appointment_set"
            ],

      show_leads_team_filter: false,
      show_team_activity_filter: false,
      show_date_filter: false,
      design_search: "",
      sequence_search: "",
      active_leads: {
        total_active_leads: 0,
        new_prospects: 0,
        with_marketing: 0,
        warm_leads: 0,
        hot_leads: 0,
        appointment_set: 0,
        under_contract: 0
      },

      date_options: {
        today: {
          label: "Today",
          value: "today"
        },
        yesterday: {
          label: "Yesterday",
          value: "yesterday"
        },
        this_week: {
          label: "This Week",
          value: "this_week"
        },
        last_week: {
          label: "Last Week",
          value: "last_week"
        },
        this_month: {
          label: "This Month",
          value: "this_month"
        },
        last_month: {
          label: "Last Month",
          value: "last_month"
        },
        last_30_days: {
          label: "Last 30 Days",
          value: "last_30_days"
        },
        this_quarter: {
          label: "This Quarter",
          value: "this_quarter"
        },
        last_quarter: {
          label: "Last Quarter",
          value: "last_quarter"
        },
        this_year: {
          label: "This Year",
          value: "this_year"
        },
        last_year: {
          label: "Last Year",
          value: "last_year"
        },
        custom: {
          label: "Select Date Range",
          value: "custom"
        }
      }
    };

    this.updateMainState = this.updateMainState.bind(this);
    this.renderPreviousTitle = this.renderPreviousTitle.bind(this);
    this.saveDashboardSettings = this.saveDashboardSettings.bind(this);
    this.toggleCalendar = this.toggleCalendar.bind(this);
  }

  componentDidMount() {
    if (!this.props.team_loading) {
      this.props.getTeam({ token: this.props.token, type: "load" });
    }

    if (!this.props.mailer_template_loading) {
      this.props.getMailerTemplates({
        token: this.props.token,
        load_all: true
      });
    }

    if (!this.props.mailer_campaigns_loading) {
      this.props.getMailerCampaigns({
        token: this.props.token,
        load_all: true,
        exclude_repeating_mail: true
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.filter_activity_date !== this.state.filter_activity_date ||
      prevState.filter_activity_start_date !==
        this.state.filter_activity_start_date ||
      prevState.filter_activity_end_date !==
        this.state.filter_activity_end_date ||
      prevState.filter_activity_team !== this.state.filter_activity_team
    ) {
      this.saveDashboardSettings();
    }
    if (prevState.stat_tab !== this.state.stat_tab) {
      this.saveDashboardSettings();
    }

    if (
      JSON.stringify(prevState.include_properties) !==
        JSON.stringify(this.state.include_properties) ||
      JSON.stringify(prevState.include_call_statuses) !==
        JSON.stringify(this.state.include_call_statuses)
    ) {
      this.saveDashboardSettings();
    }
  }

  saveDashboardSettings() {
    this.props.updateUserSettings({
      token: this.props.token,
      type: "workspace_dashboard_settings",
      value: {
        stat_tab: this.state.stat_tab,
        filter_activity_date: this.state.filter_activity_date,
        filter_activity_start_date: this.state.filter_activity_start_date,
        filter_activity_end_date: this.state.filter_activity_end_date,

        filter_activity_team: this.state.filter_activity_team
          ? {
              id: this.state.filter_activity_team?.id,
              team_name: this.state.filter_activity_team?.team_name
            }
          : null,
        include_properties: this.state.include_properties,
        include_call_statuses: this.state.include_call_statuses
      }
    });
  }

  toggleCalendar() {
    this.setState(prevState => {
      return { isCalendarOpen: !prevState.isCalendarOpen };
    });
  }

  renderPreviousTitle() {
    switch (this.props.filter_date) {
      default:
        return "previous period";

      case "today":
        return "yesterday";
      case "yesterday":
        return "2 days ago";
      case "this_week":
        return "last week";
      case "this_month":
        return "last month";
      case "this_year":
        return "last year";
      case "this_quarter":
        return "last quarter";
    }
  }

  updateMainState({ prop, value }) {
    this.setState({ [prop]: value });
  }

  render() {
    const { colors, isMobile, user } = this.props;
    const { active_leads, dashboard_activity_stats, stat_tab } = this.state;
    if (!user) {
      return null;
    }

    return (
      <Container
        fill={true}
        style={{
          display: "flex",
          overflow: "hidden",
          backgroundColor: colors.card_color
        }}
        title={"Dashboard"}
      >
        <Row style={{ flex: 1, overflow: "hidden" }}>
          <Wrapper
            style={{ flex: 1, alignSelf: "stretch", position: "relative" }}
          >
            <MainHeader title={"Dashboard"} />

            <Wrapper
              style={
                this.props.device === "desktop"
                  ? {
                      borderBottomWidth: 1,
                      borderBottomColor: colors.border_color,
                      borderBottomStyle: "solid",
                      marginBottom: 25,
                      marginTop: 25
                    }
                  : {
                      marginBottom: 25,
                      marginTop: 25
                    }
              }
            >
              <InlineTabs
                outer_style={
                  this.props.device === "desktop" ? { margin: 0 } : {}
                }
                tab_type="underline"
                selected_tab={this.state.stat_tab}
                tabs={[
                  {
                    onPress: () => {
                      this.setState({
                        stat_tab: "properties_added"
                      });
                    },
                    slug: "properties_added",
                    title: "Properties Added"
                  },
                  {
                    onPress: () => {
                      if (
                        !checkIfUserHasMetadata("paid_plan") &&
                        !checkIfUserHasMetadata("dialer")
                      ) {
                        this.props.pushSidePanel({
                          slug: "purchase_plan_prompt",
                          overlay_modal: true,
                          data: {
                            title:
                              "Get an AI-Powered Dialer With DealMachine Pro.",
                            dismiss_button:
                              "No thanks. Let me continue without an AI-Powered Dialer.",
                            no_free: true,
                            event_slug: "in_app_dashboard"
                          },
                          locked: true
                        });
                      } else {
                        this.setState({
                          stat_tab: "calls_made"
                        });
                      }
                    },
                    icon: !checkIfUserHasMetadata("dialer") ? "lock" : "",
                    slug: "calls_made",
                    title: "Calls Made"
                  },
                  {
                    onPress: () => {
                      this.setState({
                        stat_tab: "mail_sent"
                      });
                    },
                    slug: "mail_sent",
                    title: "Mail Sent"
                  }
                ]}
              />
            </Wrapper>
            <Row style={{ flexWrap: "wrap" }}>
              <PopoverMenu
                show={this.state.show_team_activity_filter}
                no_swipe={true}
                no_cancel={true}
                onShow={s => {
                  this.setState({
                    show_team_activity_filter: s
                  });
                }}
                popover_width={400}
                popoverSheetTop={"80%"}
                popoverPlacement={"bottom"}
                popover_title={"Filter by Team"}
                includeCloseButton={true}
                disabled={
                  this.state.stats_loading ||
                  this.props.team_loading ||
                  this.state.properties_added_loading ||
                  this.state.calls_made_loading ||
                  this.state.mail_sent_loading
                }
                renderComponent={options => {
                  return (
                    <InlineButton
                      icon_right={
                        this.state.show_team_activity_filter
                          ? "keyboard-arrow-up"
                          : "keyboard-arrow-down"
                      }
                      noPress={true}
                      pressedIn={
                        options.hovering ||
                        options.pressedIn ||
                        this.state.show_team_activity_filter
                      }
                      disabled={
                        this.state.stats_loading ||
                        this.state.properties_added_loading ||
                        this.state.calls_made_loading ||
                        this.state.mail_sent_loading
                      }
                    >
                      {!this.state.filter_activity_team
                        ? "Showing All Activity"
                        : "Showing Activity From " +
                          this.state.filter_activity_team?.team_name}
                    </InlineButton>
                  );
                }}
                renderMenu={() => {
                  return (
                    <Wrapper style={{ alignSelf: "stretch" }}>
                      <SearchBar
                        title={"Search Team"}
                        onChange={value => {
                          this.setState({
                            team_activity_search: value
                          });
                        }}
                        value={this.state.team_activity_search}
                        style={{
                          margin: 10
                        }}
                      />
                      <KeyboardView
                        style={
                          this.props.device == "desktop"
                            ? {
                                maxHeight: 300,
                                alignSelf: "stretch"
                              }
                            : {
                                alignSelf: "stretch"
                              }
                        }
                      >
                        {!this.state.team_activity_search ||
                        "All Activity"
                          .toLowerCase()
                          .includes(
                            this.state.team_activity_search.toLowerCase()
                          ) ? (
                          <SelectItem
                            select_type="radio"
                            selected={!this.state.filter_activity_team}
                            onPress={() => {
                              this.setState({
                                filter_activity_team: null,
                                show_team_activity_filter: false
                              });
                            }}
                          >
                            All Activity
                          </SelectItem>
                        ) : null}

                        {this.props.workspace_teams &&
                          this.props.workspace_teams.map((team, i) => {
                            const name = team?.team_name;
                            if (
                              !this.state.team_activity_search ||
                              name
                                .toLowerCase()
                                .includes(
                                  this.state.team_activity_search.toLowerCase()
                                )
                            ) {
                              return (
                                <SelectItem
                                  select_type="radio"
                                  selected={
                                    this.state.filter_activity_team?.id ==
                                    team.id
                                  }
                                  onPress={() => {
                                    this.setState({
                                      filter_activity_team: {
                                        id: team.id,
                                        team_name: team.team_name
                                      },
                                      show_team_activity_filter: false
                                    });
                                  }}
                                >
                                  {name}
                                </SelectItem>
                              );
                            }

                            return null;
                          })}
                      </KeyboardView>
                    </Wrapper>
                  );
                }}
                menu_items={null}
              />

              <Wrapper>
                {
                  //determine text based on the last time frame
                  this.state.date_options[this.state.filter_activity_date]
                    .label == "Today" ||
                  this.state.date_options[this.state.filter_activity_date]
                    .label == "Yesterday" ||
                  this.state.date_options[this.state.filter_activity_date]
                    .label == "This Week" ||
                  this.state.date_options[this.state.filter_activity_date]
                    .label == "Last Week" ||
                  this.state.date_options[this.state.filter_activity_date]
                    .label == "This Month" ||
                  this.state.date_options[this.state.filter_activity_date]
                    .label == "This Quarter" ||
                  this.state.date_options[this.state.filter_activity_date]
                    .label == "This Year" ||
                  this.state.date_options[this.state.filter_activity_date]
                    .label == "Last Year" ||
                  this.state.date_options[this.state.filter_activity_date]
                    .label == "Last Month" ||
                  this.state.date_options[this.state.filter_activity_date]
                    .label == "Last Quarter" ||
                  this.state.date_options[this.state.filter_activity_date]
                    .label == "Select Date Range" ? (
                    <Copy>from</Copy>
                  ) : (
                    <Copy>from the</Copy>
                  )
                }
              </Wrapper>
              <PopoverMenu
                show={this.state.show_date_filter}
                disabled={
                  this.state.stats_loading ||
                  this.state.properties_added_loading ||
                  this.state.calls_made_loading ||
                  this.state.mail_sent_loading
                }
                no_swipe={true}
                no_cancel={true}
                onShow={s => {
                  this.setState({
                    show_date_filter: s
                  });
                }}
                popover_width={400}
                popoverSheetTop={"80%"}
                popoverPlacement={"bottom"}
                popover_title={"Filter by Date"}
                includeCloseButton={true}
                renderComponent={options => {
                  return (
                    <InlineButton
                      icon_right={
                        this.state.show_date_filter
                          ? "keyboard-arrow-up"
                          : "keyboard-arrow-down"
                      }
                      noPress={true}
                      disabled={
                        this.state.stats_loading ||
                        this.state.properties_added_loading ||
                        this.state.calls_made_loading ||
                        this.state.mail_sent_loading
                      }
                      pressedIn={
                        options.hovering ||
                        options.pressedIn ||
                        this.state.show_date_filter
                      }
                    >
                      {this.state.filter_activity_date === "custom"
                        ? moment(this.state.filter_activity_start_date).format(
                            "MMM Do"
                          ) +
                          " - " +
                          moment(this.state.filter_activity_end_date).format(
                            "MMM Do"
                          )
                        : this.state.date_options[
                            this.state.filter_activity_date
                          ].label}
                    </InlineButton>
                  );
                }}
                renderMenu={() => {
                  return (
                    <Wrapper style={{ alignSelf: "stretch" }}>
                      <KeyboardView
                        style={
                          this.props.device == "desktop"
                            ? {
                                maxHeight: 300,
                                alignSelf: "stretch"
                              }
                            : {
                                alignSelf: "stretch"
                              }
                        }
                      >
                        {Object.keys(this.state.date_options).map(
                          (option, i) => {
                            return (
                              <SelectItem
                                key={"date_filter" + i}
                                select_type={
                                  option === "custom" ? "icon" : "radio"
                                }
                                select_icon="date-range"
                                selected={
                                  this.state.filter_activity_date == option
                                }
                                onPress={() => {
                                  if (option === "custom") {
                                    this.setState(
                                      {
                                        show_date_filter: false
                                      },
                                      () => {
                                        this.toggleCalendar();
                                      }
                                    );
                                  } else {
                                    this.setState({
                                      filter_activity_date: option,
                                      show_date_filter: false
                                    });
                                  }
                                }}
                                description={
                                  option === "custom" &&
                                  this.state.filter_activity_date == option
                                    ? moment(
                                        this.state.filter_activity_start_date
                                      ).format("MMM Do") +
                                      " - " +
                                      moment(
                                        this.state.filter_activity_end_date
                                      ).format("MMM Do")
                                    : null
                                }
                              >
                                {this.state.date_options[option].label}
                              </SelectItem>
                            );
                          }
                        )}
                      </KeyboardView>
                    </Wrapper>
                  );
                }}
                menu_items={null}
              />

              {this.state.stats_loading ||
              this.state.properties_added_loading ||
              this.state.calls_made_loading ||
              this.state.mail_sent_loading ? (
                <Wrapper>
                  <Spin size={"small"} />
                </Wrapper>
              ) : null}
            </Row>

            <Wrapper
              style={
                this.props.device === "desktop"
                  ? { marginBottom: 40, paddingRight: 25 }
                  : {}
              }
            >
              <Wrapper style={{ maxWidth: 900 }}>
                {stat_tab === "properties_added" ? (
                  <>
                    <PropertiesAdded
                      properties_added_loading={
                        this.state.properties_added_loading
                      }
                      workspace_id={this.props.user?.team_id}
                      filter_team={this.state.filter_activity_team}
                      filter_date={this.state.filter_activity_date}
                      filter_start_date={this.state.filter_activity_start_date}
                      filter_end_date={this.state.filter_activity_end_date}
                      updateMainState={this.updateMainState}
                      renderPreviousTitle={this.renderPreviousTitle}
                      include_properties={this.state.include_properties}
                    />
                  </>
                ) : null}
                {stat_tab === "calls_made" ? (
                  <>
                    <CallsMade
                      calls_made_loading={this.state.calls_made_loading}
                      workspace_id={this.props.user?.team_id}
                      filter_team={this.state.filter_activity_team}
                      filter_date={this.state.filter_activity_date}
                      filter_start_date={this.state.filter_activity_start_date}
                      filter_end_date={this.state.filter_activity_end_date}
                      updateMainState={this.updateMainState}
                      renderPreviousTitle={this.renderPreviousTitle}
                      include_call_statuses={this.state.include_call_statuses}
                    />
                  </>
                ) : null}
                {stat_tab === "mail_sent" ? (
                  <>
                    <MailSent
                      mail_sent_loading={this.state.mail_sent_loading}
                      workspace_id={this.props.user?.team_id}
                      filter_team={this.state.filter_activity_team}
                      filter_date={this.state.filter_activity_date}
                      filter_start_date={this.state.filter_activity_start_date}
                      filter_end_date={this.state.filter_activity_end_date}
                      renderPreviousTitle={this.renderPreviousTitle}
                      updateMainState={this.updateMainState}
                    />
                  </>
                ) : null}
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Row>

        <DateRangePicker
          {...this.props}
          isCalendarOpen={this.state.isCalendarOpen}
          toggleCalendar={this.toggleCalendar}
          selectDates={({ start_date, end_date }) => {
            if (start_date != null && end_date != null) {
              this.setState({
                filter_activity_date: "custom",
                filter_activity_start_date: start_date,
                filter_activity_end_date: end_date
              });
            }
          }}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({
  auth,
  native,
  settings,
  team,
  mailer,
  workspaces
}) => {
  const { token, user } = auth;
  const { device, window_height, isMobile } = native;
  const { colors, dark_mode } = settings;
  const { team_members_loading, my_team } = team;

  const {
    mailer_templates,
    mailer_templates_loading,
    mailer_campaigns,
    mailer_campaigns_loading
  } = mailer;
  const { workspace_teams } = workspaces;

  return {
    token,
    user,
    device,
    isMobile,
    window_height,
    colors,
    dark_mode,
    team_members_loading,
    my_team,
    mailer_templates,
    mailer_templates_loading,
    mailer_campaigns,
    mailer_campaigns_loading,
    workspace_teams
  };
};

export default connect(mapStateToProps, {
  pushSidePanel,
  replaceSidePanel,

  changeTab,
  getTeam,
  getDashboardStats,
  updateUserSettings,

  getMailerTemplates,
  getMailerCampaigns
})(Dashboard);
