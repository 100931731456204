import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  Bold,
  Wrapper,
  Header1,
  Header3,
  Copy
} from "app/NativeComponents/common";
import { InlineButton, CloseButton } from "app/NativeComponents/snippets";

import {
  showSuccess,
  showErrorMessage,
  popSidePanel,
  AppConfig,
  getBilling,
  addStripePaymentMethod,
  renderPrice
} from "app/NativeActions";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import PaymentMethodForm from "./PaymentMethodForm";

class AddCardPrompt extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    this.state = {
      description: panel_data?.description,
      title: panel_data?.title,
      dismiss_button: panel_data?.dismiss_button,
      loading: false
    };

    this.renderInner = this.renderInner.bind(this);
    this.triggerLoading = this.triggerLoading.bind(this);
  }

  componentDidMount() {
    this.props.getBilling({ token: this.props.token, type: "deal_credits" });
  }

  triggerLoading(loading) {
    this.setState({ loading });
  }

  renderInner() {
    const { colors, deal_credits } = this.props;

    const stripePromise = loadStripe(AppConfig().stripe_live);

    //rounded to the nearest 100 up
    let rounded_deal_credits = 500;
    if (deal_credits?.deal_credits_remaining?.cents > 0) {
      rounded_deal_credits =
        Math.ceil(deal_credits?.deal_credits_remaining?.cents / 100) * 100;
    }

    return (
      <>
        <Wrapper style={{ margin: "25px auto", maxWidth: 800 }}>
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              padding: 25
            }}
          >
            <Header1
              style={{
                textAlign: "center"
              }}
            >
              <Bold>
                {!!this.state.title
                  ? this.state.title
                  : "Add a card to your account."}
              </Bold>
            </Header1>
            <Header3
              style={{
                textAlign: "center"
              }}
            >
              Unlock <Bold>{renderPrice(rounded_deal_credits)}</Bold> in free
              marketing credits when you add a credit card to your account.
              Marketing credits are used to send mail, make phone calls and
              more!
            </Header3>
          </Wrapper>

          <Elements stripe={stripePromise}>
            <PaymentMethodForm
              colors={colors}
              deal_credits={this.props.deal_credits}
              showErrorMessage={this.props.showErrorMessage}
              showSuccess={this.props.showSuccess}
              triggerLoading={this.triggerLoading}
              loading={this.state.loading}
              popSidePanel={this.props.popSidePanel}
              token={this.props.token}
              addStripePaymentMethod={this.props.addStripePaymentMethod}
            />
          </Elements>
        </Wrapper>

        {!!this.state.dismiss_button ? (
          <Wrapper style={{ alignItems: "center", justifyContent: "center" }}>
            <InlineButton onPress={this.props.popSidePanel}>
              {this.state.dismiss_button}
            </InlineButton>
          </Wrapper>
        ) : null}
        <Wrapper style={{ height: 40 }} />
      </>
    );
  }

  render() {
    return (
      <Container style={{ backgroundColor: "transparent" }}>
        <KeyboardView style={{ flex: 1 }}>{this.renderInner()}</KeyboardView>
        <CloseButton onPress={this.props.popSidePanel} />
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, billing }) => {
  const { token, user, init, loading } = auth;
  const { source_of_truth, deal_credits, deal_credits_loading } = billing;

  const { colors } = settings;

  return {
    token,
    user,
    init,
    colors,
    source_of_truth,
    deal_credits,
    deal_credits_loading
  };
};

export default connect(mapStateToProps, {
  showSuccess,
  showErrorMessage,
  popSidePanel,
  getBilling,
  addStripePaymentMethod
})(AddCardPrompt);
