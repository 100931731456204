import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  KeyboardView,
  Row,
  Wrapper,
  Bold,
  Copy
} from "app/NativeComponents/common";
import {
  NewHeader,
  InlineTabs,
  SmallLabel,
  InputBox,
  SelectItem,
  GhostButton,
  BottomNavBar,
  ConfirmInlineButton,
  SpinWrapper,
  AttentionBox,
  InlineButton,
  IconButton,
  List
} from "app/NativeComponents/snippets";

import {
  generateWorkspaceTeamName,
  popSidePanel,
  editWorkspaceTeam,
  showErrorMessage,
  numberWithCommas,
  getWorkspaceTeamSeats,
  editWorkspaceTeamSeats,
  pushSidePanel,
  renderDate
} from "app/NativeActions";

class ManageWorkspaceTeamSeats extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    this.state = {
      team: panel_data?.team,
      loading_seats: false,
      assigned_seats_for_team: [],
      total_assigned_seats_for_workspace: 0,
      loading_remove_seat: false,
      loading_assign_seat: false
    };

    this._team_name = React.createRef();
    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
  }

  componentDidMount() {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    if (!panel_data?.team) {
      this.props.popSidePanel();
    }

    this.props.getWorkspaceTeamSeats({
      token: this.props.token,
      type: "get_team_seats",
      team_id: this.state.team?.id,
      onLoading: () => {
        this.setState({ loading_seats: true });
      },
      onError: error => {
        this.setState({ loading_seats: false });
      },
      onSuccess: results => {
        this.setState({
          loading_seats: false,
          assigned_seats_for_team: results?.assigned_seats_for_team
        });
      }
    });
  }

  checkIfNeedsToSave() {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    if (
      panel_data?.team?.team_name !== this.state.team_name &&
      !!this.state.team_name
    ) {
      return true;
    }

    return false;
  }

  render() {
    const total_additional_team_member_seats =
      this.props.source_of_truth?.subscription?.metadata
        ?.additional_team_member_seats || 0;

    const { assigned_seats_for_team, total_assigned_seats_for_workspace } =
      this.state;

    return (
      <Container>
        <NewHeader
          title={"Manage Additional Team Member Seats"}
          subtitle={""}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.props.popSidePanel();
            }
          }}
        />

        {this.state.loading_seats ? (
          <SpinWrapper text="Loading" />
        ) : (
          <>
            <>
              {total_additional_team_member_seats == 0 ? (
                <AttentionBox
                  primary={true}
                  title={
                    total_additional_team_member_seats == 0
                      ? "You don't have any additional team member seats for your account."
                      : ""
                  }
                  description={
                    total_additional_team_member_seats == 0
                      ? "Talk to your account representative to get additional team member seats."
                      : ""
                  }
                />
              ) : (
                <>
                  <AttentionBox
                    title={
                      "You've assigned " +
                      this.state.total_assigned_seats_for_workspace +
                      " additional team member seats of the " +
                      total_additional_team_member_seats +
                      " available for this account."
                    }
                    description={
                      "Talk to your account representative to get more additional team member seats if you need them."
                    }
                  />

                  <List
                    rowNumber={1}
                    style={{ flex: 1 }}
                    items={assigned_seats_for_team}
                    infiniteScroll={true}
                    itemStructure={({ item, index }) => {
                      return (
                        <SelectItem
                          key={"additional_team_member_seat" + index}
                          select_type={"none"}
                          description={""}
                          noPress={true}
                          renderRight={() => {
                            return (
                              <IconButton
                                tooltip={"Remove Seat"}
                                tooltipPlacement={"top"}
                                icon={"delete"}
                                loading={
                                  this.state.loading_remove_seat == item.id
                                }
                                onPress={() => {
                                  this.props.editWorkspaceTeamSeats({
                                    token: this.props.token,
                                    type: "unassign_additional_team_member_seat_to_team",
                                    team_id: this.state.team?.id,
                                    seat_id: item.id,
                                    onLoading: () => {
                                      this.setState({
                                        loading_remove_seat: item.id
                                      });
                                    },
                                    onError: error => {
                                      this.setState({
                                        loading_remove_seat: false
                                      });
                                    },
                                    onSuccess: results => {
                                      this.setState({
                                        loading_remove_seat: false,
                                        assigned_seats_for_team:
                                          results?.assigned_seats_for_team,
                                        total_assigned_seats_for_workspace:
                                          results?.total_assigned_seats_for_workspace
                                      });
                                    }
                                  });
                                }}
                              />
                            );
                          }}
                        >
                          1 Additional Team Member Seat
                        </SelectItem>
                      );
                    }}
                    canRefresh={false}
                    onRefresh={() => {}}
                    is_refreshing={false}
                    canLoadMore={false}
                    isLoadingMore={false}
                    onLoadMore={() => {}}
                  />

                  <BottomNavBar>
                    <InlineButton
                      primary={true}
                      icon={"add"}
                      disabled={
                        this.state.total_assigned_seats_for_workspace >=
                        total_additional_team_member_seats
                      }
                      tooltip={
                        this.state.total_assigned_seats_for_workspace >=
                        total_additional_team_member_seats
                          ? "You've reached the maximum number of additional team member seats for this account."
                          : ""
                      }
                      tooltipPlacement={"top"}
                      button_type={"full"}
                      loading={this.state.loading_assign_seat}
                      onPress={() => {
                        this.props.editWorkspaceTeamSeats({
                          token: this.props.token,
                          type: "assign_additional_team_member_seat_to_team",
                          team_id: this.state.team?.id,
                          onLoading: () => {
                            this.setState({
                              loading_assign_seat: true
                            });
                          },
                          onError: error => {
                            this.setState({ loading_assign_seat: false });
                          },
                          onSuccess: results => {
                            this.setState({
                              loading_assign_seat: false,
                              assigned_seats_for_team:
                                results?.assigned_seats_for_team,
                              total_assigned_seats_for_workspace:
                                results?.total_assigned_seats_for_workspace
                            });
                          }
                        });
                      }}
                    >
                      Assign Additional Team Member Seat To Team
                    </InlineButton>
                  </BottomNavBar>
                </>
              )}
            </>
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user, user_partner_info } = auth;
  const { device, isIphoneX, isMobile, platform, desktopMobile } = native;
  const { colors } = settings;
  const { source_of_truth } = billing;
  return {
    user,
    user_partner_info,
    token,
    device,
    isIphoneX,
    isMobile,
    platform,
    desktopMobile,
    colors,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  generateWorkspaceTeamName,
  editWorkspaceTeam,
  showErrorMessage,
  popSidePanel,
  getWorkspaceTeamSeats,
  editWorkspaceTeamSeats,
  pushSidePanel
})(ManageWorkspaceTeamSeats);
