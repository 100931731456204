import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";

import SignUpSuccess from "app/NativeComponents/components/SignUpSuccess";
import RedirectToApp from "app/NativeComponents/components/RedirectToApp";
import Offers from "app/DealMachineCore/MarketingOnboardingAndFunnel/components/Offers";
import SharableProperty from "app/DealMachineCore/components/SharableProperty";

/* app routes */
import ForgotPasswordContainer from "app/NativeComponents/components/ForgotPasswordContainer";
import ResetPassword from "app/NativeComponents/components/ResetPassword";
import DealFinderPage from "app/NativeComponents/components/DealFinderPage";

import App from "./App";
import Logout from "app/DealMachineCore/components/Logout";

import StreetViewiFrame from "app/NativeComponents/components/NativeStreetView/StreetViewiFrame";
import LiveEvent from "app/DealMachineCore/MarketingOnboardingAndFunnel/components/LiveEvent";
import ListBuilderDemo from "app/DealMachineCore/MarketingOnboardingAndFunnel/components/ListBuilderDemo";

import MobileRedirect from "./MobileRedirect";
import PromoRedirect from "./PromoRedirect";
import ChurnKeyPrompt from "app/DealMachineCore/MarketingOnboardingAndFunnel/components/ChurnKeyPrompt";
class RouteBody extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const token = window.localStorage.getItem("token");
    this.props.initApp(true);
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps) {
    if (this.props.location.href !== prevProps.location.href) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <Switch>
        {/* app routes */}
        <Route path="/sign-up-complete" component={SignUpSuccess} />
        <Route path="/churnkey" component={ChurnKeyPrompt} />
        <Route path="/offers/:offer_slug/" component={Offers} />
        <Route path="/offers/:offer_slug/:email_token" component={Offers} />
        <Route path="/payment/:offer_slug/:email_token" component={Offers} />

        <Route
          exact
          path="/forgot-password"
          component={ForgotPasswordContainer}
        />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route path={"/logout"} component={Logout} />

        {/* dealfinder page */}
        <Route exact path="/team/:team_link" component={DealFinderPage} />
        {/*street view mobile iframe*/}
        <Route exact path={"/virtual-driving"} component={StreetViewiFrame} />

        {/*virtual drive presentation*/}
        <Route exact path={"/live-event"} component={LiveEvent} />
        <Route exact path={"/list-builder-demo"} component={ListBuilderDemo} />

        {/*shared link redirects */}
        <Route exact path="/task/:id" component={MobileRedirect} />
        <Route exact path="/property/:id" component={MobileRedirect} />
        <Route exact path="/edit-list/:id" component={MobileRedirect} />
        <Route exact path="/notifications" component={MobileRedirect} />

        {/*promo redirect system */}
        <Route exact path="/r/:redirect_slug" component={PromoRedirect} />

        {/* sharable property */}
        <Route exact path="/p/:property_id" component={SharableProperty} />

        {/* app routes */}
        <Route path="/" component={App} />
      </Switch>
    );
  }
}

export default withRouter(connect(null, {})(RouteBody));
