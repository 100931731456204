import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Scroll,
  Wrapper,
  Spin,
  CenterCenter,
  MultiLineInput,
  Row,
  InputDatePicker,
  Copy,
  KeyboardView,
  Card,
  Input,
  Icon,
  ModalOverlay,
  Bold,
  ExternalImage
} from "app/NativeComponents/common";
import {
  Header,
  Select,
  DateSelector,
  NewHeader,
  SpinWrapper,
  SmallLabel,
  InputBox,
  SelectBox,
  DateBox,
  ConfirmLabel,
  BottomNavBar,
  ConfirmInlineButton,
  SelectItem,
  GhostButton
} from "app/NativeComponents/snippets";
import {
  appRedirect,
  setModal,
  toggleModal,
  getTeam,
  getTasks,
  focusNextField,
  editTask,
  setActiveTaskCount,
  popSidePanel,
  clearTaskList,
  deleteTask,
  setCompletedRoute,
  setPlannedRoute,
  triggerPlannedRoute,
  selectActiveProperty,
  getPropertyNew,
  pushSidePanel,
  tabNavigation,
  getTask,
  completeTask,
  dismissMobileKeyboard
} from "app/NativeActions";
import moment from "moment";
class TaskEdit extends Component {
  constructor(props) {
    super(props);
    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    this.state = {
      panel_data,
      is_loading: true,
      loading: false,
      deleting: false,
      original_task: panel_data?.task,
      task: {
        task_due_date: panel_data?.task?.date_due,
        task_assignee: panel_data?.task?.assigned_user,
        task_title: panel_data?.task?.title,
        task_description: panel_data?.task?.text,
        task_assignee_notes: panel_data?.task?.assignee_notes,
        house: panel_data?.task?.house,
        ...panel_data?.task
      },
      can_edit_task:
        (props?.user?.can_mimic_team_owner == 1 ||
          panel_data?.task?.user == props.user?.id) &&
        panel_data?.task?.status == "Active",
      formatted_items: [],
      task_assignee_text: "Myself",
      assign_to_focus: false,
      completing: false,
      property: null
    };
    this.loadTasks = this.loadTasks.bind(this);
    this.saveTask = this.saveTask.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.setUserSelectText = this.setUserSelectText.bind(this);
    this.formatArray = this.formatArray.bind(this);
    this.formatTaskCompletionTime = this.formatTaskCompletionTime.bind(this);
    this.completeDaTask = this.completeDaTask.bind(this);

    this._title_input = React.createRef();
    this._assign_to_select = React.createRef();
    this._due_date_select = React.createRef();
    this._details_input = React.createRef();
  }

  completeDaTask() {
    this.props.completeTask({
      token: this.props.token,
      type: "complete",
      task_id: this.state.original_task?.id,
      onLoading: () => {
        this.setState({ completing: true });
      },
      onError: () => {
        this.setState({ completing: false });
      },
      onSuccess: results => {
        this.setState({ completing: false }, () => {
          if (this.state.panel_data?.onSuccess) {
            this.state.panel_data.onSuccess(results);
          }
          this.handleBack();
        });
      }
    });
  }

  componentDidMount() {
    if (this.props.my_team.length === 0 && this.props.user) {
      if (!this.props.team_loading && !this.props.team_members_loading) {
        this.props.getTeam({ token: this.props.token, type: "load" });
      }
    }
    if (this.props.task_list.travel_time) {
    }
    if (this.props.task_list) {
      if (this.props.task_list.title) {
        this.editTaskField("task_title", "Drive " + this.props.task_list.title);
      }
    }
    if (this.props.my_team && this.props.my_team.length > 0) {
      this.formatArray();

      this.setState({ is_loading: false });
    }

    const { original_task } = this.state;

    const task_id =
      this.props.device == "mobile"
        ? this.state.original_task?.id
        : this.state.original_task?.id;

    if (task_id) {
      this.props.getTask({
        token: this.props.token,
        task_id,
        onLoading: () => {
          this.setState({ task_loading: true });
        },
        onError: () => {
          this.setState({ task_loading: false });
        },
        onSuccess: results => {
          if (results) {
            this.setState({
              task_loading: false,
              original_task: results,
              task: {
                task_due_date: results?.date_due,
                task_assignee: results?.assigned_user,
                task_title: results?.title,
                task_description: results?.text,
                task_assignee_notes: results?.assignee_notes,
                house: results?.house,
                ...results
              },
              can_edit_task:
                (this.props.user?.can_mimic_team_owner == 1 ||
                  results?.user == this.props.user?.id) &&
                results?.status == "Active"
            });
            this.getPropertyData();
          }
        }
      });
    }
  }

  getPropertyData() {
    const { original_task } = this.state;
    this.props.getPropertyNew({
      token: this.props.token,
      lead_id: original_task?.house,
      onLoading: () => {
        this.setState({
          property_loading: true
        });
      },
      onError: () => {
        this.setState({
          property_loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          property_loading: false,
          property: results.property
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.my_team.length === 0 &&
      this.props.token &&
      this.props.token !== prevProps.token
    ) {
      if (!this.props.team_loading && !this.props.team_members_loading) {
        this.props.getTeam({ token: this.props.token, type: "load" });
      }
    }

    if (
      this.props.task_panel === "new_task" &&
      prevProps.task_panel !== "new_task"
    ) {
      this.setState({
        task: {
          task_due_date: "",
          task_assignee: this.props.user?.id,
          task_title: "",
          task_description: "",
          house: ""
        },
        task_assignee_text: "Myself"
      });
    }

    if (
      (prevProps.my_team !== this.props.my_team || this.state.is_loading) &&
      this.props.my_team.length !== 0
    ) {
      this.formatArray();
      this.setState({ is_loading: false });
    }

    if (prevProps.task_list !== this.props.task_list) {
      this.editTaskField("task_title", "Drive " + this.props.task_list.title);
    }
  }
  loadTasks() {
    this.props.getTasks({
      token: this.props.token,
      begin: this.props.tasks_begin,
      type: "get",
      limit: this.props.tasks_limit,
      sort_by: this.props.sort_by,
      filter_by: this.props.filter_by,
      filter_user: this.props.filter_user
    });
  }

  saveTask() {
    this.setState({
      loading: true
    });
    const { original_task } = this.state;

    this.props.editTask({
      token: this.props.token,
      task_id: original_task?.id,
      type: "update",
      title: this.state.task?.task_title,
      assigned_user: this.state.task?.task_assignee,
      date_due: this.state.task?.task_due_date,
      house: this.state.task?.house,
      notes: this.state.task?.task_description,
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          loading: false
        });
        if (this.state.panel_data?.onSuccess) {
          this.state.panel_data.onSuccess(results);
        }
        this.props.popSidePanel();
      }
    });
  }

  formatArray = () => {
    if (!this.props.user) return;
    let formatted_array = [];
    formatted_array.push({
      value: this.props.user.id,
      label: "Myself"
    });
    if (this.props.user.can_mimic_team_owner == 1) {
      for (let i = 0; i < this.props.my_team.length; i++) {
        if (this.props.my_team[i].id != this.props.user.id) {
          formatted_array.push({
            value: this.props.my_team[i].id,
            label:
              this.props.my_team[i].firstname && this.props.my_team[i].lastname
                ? this.props.my_team[i].firstname +
                  " " +
                  this.props.my_team[i].lastname
                : this.props.my_team[i].email
          });
        }
      }
    }

    this.setState({
      formatted_items: formatted_array
    });
  };

  editTaskField(prop, value) {
    this.setState({
      task: {
        ...this.state.task,
        [prop]: value
      }
    });
  }

  setUserSelectText(key) {
    let $this = this;
    this.state.formatted_items.forEach(function (val) {
      if (key === $this.props.user.id || !key) {
        $this.setState({ task_assignee_text: "Myself" });
      } else if (val.value === key) {
        $this.setState({ task_assignee_text: val.label });
      }
    });
  }
  handleBack() {
    dismissMobileKeyboard();
    this.props.popSidePanel();
  }

  formatTaskCompletionTime(task_time) {
    if (task_time) {
      let split_time = task_time.split(":");
      let hours = split_time[0];
      let minutes = split_time[1];
      let formatted_time = "";

      if (minutes > 0) {
        minutes = minutes + " minutes";
      } else {
        minutes = "";
      }
      if (hours > 1) {
        hours = hours + " hours";
      } else if (hours < 1) {
        hours = "";
      } else {
        hours = hours + " hour";
      }
      formatted_time = hours + " " + minutes;

      return formatted_time;
    }
  }

  checkIfNeedsToSave() {
    const { task, original_task } = this.state;

    if (
      !!task.task_title &&
      this.state.can_edit_task &&
      (task.task_due_date != original_task?.date_due ||
        task.task_assignee != original_task?.assigned_user ||
        task.task_title != original_task?.title ||
        task.task_description != original_task?.text ||
        task.task_assignee_notes != original_task?.assignee_notes)
    ) {
      return true;
    }
    return false;
  }

  renderBody() {
    const { colors } = this.props;
    const { original_task } = this.state;
    return (
      <>
        <NewHeader
          title={"Edit Task"}
          subtitle={
            this.state.panel_data?.property
              ? "Edit task for " +
                this.state.panel_data?.property?.property_address
              : ""
          }
          leftButton={{
            icon:
              this.props.isMobile || this.props.side_panel_views?.length > 0
                ? "arrow-back"
                : "close",
            onPress: this.handleBack
          }}
        />
        {this.state.is_loading ||
        this.props.active_task_loading ||
        !original_task ? (
          <SpinWrapper text="Loading..." />
        ) : (
          <>
            {this.checkIfNeedsToSave() ? (
              <ConfirmLabel
                style={{
                  alignSelf: "stretch"
                }}
                icon="add-task"
                label={"Task changed"}
                confirmButton={"Save Task"}
                loading={this.state.loading}
                primary={true}
                disabled={this.state.loading}
                onPress={() => {
                  this.saveTask();
                }}
              />
            ) : this.state.original_task?.assigned_user ==
              this.props.user?.id ? (
              <ConfirmLabel
                style={{
                  alignSelf: "stretch"
                }}
                icon="check-circle"
                label={"Complete This Task"}
                confirmButton={"Mark As Complete"}
                loading={this.state.completing}
                primary={false}
                disabled={this.state.completing}
                onPress={this.completeDaTask}
              />
            ) : null}
            <KeyboardView
              style={{
                flex: 1
              }}
            >
              {original_task?.list ? (
                <>
                  <Wrapper
                    style={{
                      padding: 25
                    }}
                  >
                    <SmallLabel
                      style={{ padding: 0, paddingTop: 0, paddingBottom: 0 }}
                    >
                      Associated List:
                    </SmallLabel>
                    <Copy>{original_task?.list?.title}</Copy>
                  </Wrapper>

                  <Wrapper
                    style={{ margin: 5, height: 150, position: "relative" }}
                  >
                    <ExternalImage
                      style={{
                        width: "100%",
                        height: 150,
                        borderRadius: 5
                      }}
                      image={original_task.list?.google_map_url}
                    />

                    <Wrapper
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0
                      }}
                    >
                      <Row>
                        <Wrapper
                          style={{
                            padding: 5,
                            margin: 5,
                            borderRadius: 5,
                            backgroundColor: "rgba(0,0,0,0.5)"
                          }}
                        >
                          <Row>
                            <Icon
                              style={{ marginRight: 5 }}
                              color={colors.white_text_color}
                              icon={"house"}
                              size={18}
                            />
                            <Copy style={{ color: colors.white_text_color }}>
                              {original_task?.list?.route_planned_count > 0
                                ? original_task.list?.route_planned_count +
                                  " / " +
                                  original_task.list?.lead_count
                                : original_task.list?.lead_count}
                            </Copy>
                          </Row>
                        </Wrapper>
                        <Wrapper
                          style={{
                            padding: 5,
                            margin: 5,
                            borderRadius: 5,
                            backgroundColor: "rgba(0,0,0,0.5)"
                          }}
                        >
                          <Row>
                            <Icon
                              style={{ marginRight: 5 }}
                              color={colors.white_text_color}
                              icon={"alarm"}
                              size={18}
                            />
                            <Copy style={{ color: colors.white_text_color }}>
                              {original_task.list?.travel_time
                                ? this.formatTaskCompletionTime(
                                    original_task.list?.travel_time
                                  )
                                : "> 4:00"}
                            </Copy>
                          </Row>
                        </Wrapper>
                      </Row>
                    </Wrapper>
                  </Wrapper>

                  {this.props.device == "mobile" ? (
                    <GhostButton
                      primary={true}
                      button_type="full"
                      onPress={() => {

                        this.props.tabNavigation({ slug: "map", onChanged: () => {
                            this.props.setCompletedRoute(false);
                            this.props.setPlannedRoute({
                              list_id: original_task?.list?.id,
                              task_id: original_task?.id,
                              route_plan: true
                            });
                            this.props.triggerPlannedRoute({
                              trigger_planned: true
                            });

                          } });



                      }}
                      icon="drive-eta"
                      style={{ marginTop: 10 }}
                    >
                      Start Driving
                    </GhostButton>
                  ) : null}
                </>
              ) : original_task?.address &&
                (this.state.property || this.state.property_loading) &&
                !this.state.panel_data?.property ? (
                <SelectItem
                  select_type="none"
                  onPress={() => {
                    this.props.selectActiveProperty(
                      this.props.token,
                      this.state.property
                    );
                    this.props.pushSidePanel({
                      slug: "property",
                      id: this.state.property?.property_id,
                      focus_side_panel:
                        this.props.user?.user_settings?.property_expanded ===
                        "not_expanded"
                          ? false
                          : true,
                      overlay: true,
                      data: {
                        property: this.state.property
                      }
                    });
                  }}
                  label={"Associated Lead:"}
                  loading={this.state.property_loading}
                  icon="keyboard-arrow-right"
                >
                  {this.state.property_loading
                    ? "Loading"
                    : original_task.address}
                </SelectItem>
              ) : null}

              <>
                <InputBox
                  input_ref={this._title_input}
                  name="title"
                  disabled={this.state.loading || !this.state.can_edit_task}
                  returnKeyType="next"
                  placeholder="Task Description"
                  onFocus={() => {}}
                  onChange={value => {
                    this.editTaskField("task_title", value);
                  }}
                  onSubmitEditing={() => {
                    this.setState({ assign_to_focus: true });
                  }}
                  blurOnSubmit={true}
                  value={this.state.task.task_title}
                  type="text"
                />

                <SelectBox
                  select_ref={this._assign_to_select}
                  items={this.state.formatted_items}
                  disabled={this.state.loading || !this.state.can_edit_task}
                  value={this.state.task.task_assignee}
                  placeholder="Assign to:"
                  show={this.state.assign_to_focus}
                  onFocus={() => {
                    this.setState({ assign_to_focus: true });
                  }}
                  onBlur={() => {
                    this.setState({ assign_to_focus: false });
                  }}
                  onSelect={item => {
                    this.setUserSelectText(item);
                    this.editTaskField("task_assignee", item);
                  }}
                  onSubmitEditing={() => {}}
                />

                <InputBox
                  numberOfLines={2}
                  input_ref={this._details_input}
                  name="details"
                  disabled={this.state.loading || !this.state.can_edit_task}
                  returnKeyType="done"
                  placeholder="Task Details"
                  onFocus={() => {}}
                  onChange={value => {
                    this.editTaskField("task_description", value);
                  }}
                  onSubmitEditing={() => {}}
                  blurOnSubmit={true}
                  value={this.state.task.task_description}
                  type="multiline"
                />
              </>
              <DateBox
                input_ref={this._due_date_select}
                name="due_date"
                disabled={this.state.loading || !this.state.can_edit_task}
                placeholder="Due date:"
                onFocus={() => {}}
                onChange={value => this.editTaskField("task_due_date", value)}
                value={
                  !!this.state.task?.task_due_date
                    ? moment(this.state.task?.task_due_date).format(
                        "MMM Do, YYYY"
                      )
                    : "--"
                }
                date_value={
                  !!this.state.task?.task_due_date
                    ? this.state.task?.task_due_date
                    : moment().format("YYYY-MM-DD")
                }
              />
            </KeyboardView>

            {this.state.can_edit_task ? (
              <BottomNavBar>
                <ConfirmInlineButton
                  confirm_text="Really Delete?"
                  confirm_icon="delete"
                  loading_text="Deleting"
                  button_type="full"
                  loading={this.state.deleting}
                  onPress={() => {
                    this.props.deleteTask({
                      token: this.props.token,
                      type: "remove",
                      task_id: original_task?.id,
                      onLoading: () => {
                        this.setState({
                          deleting: true
                        });
                      },
                      onError: () => {
                        this.setState({
                          deleting: false
                        });
                      },
                      onSuccess: () => {
                        this.setState({
                          deleting: false
                        });
                        this.props.popSidePanel();
                      }
                    });
                  }}
                >
                  Delete Task
                </ConfirmInlineButton>
              </BottomNavBar>
            ) : null}
          </>
        )}
      </>
    );
  }

  render() {
    const { task_panel, colors } = this.props;
    return <Container>{this.renderBody()}</Container>;
  }
}

const mapStateToProps = ({ auth, settings, native, team, tasks, modal }) => {
  const { token, user } = auth;
  const {
    filter_by,
    filter_user,
    sort_by,
    task_limit,
    tasks_begin,
    active_task_count,
    task_panel,
    task_list,
    active_task_loading
  } = tasks;
  const { colors } = settings;
  const { device, platform, isMobile } = native;

  const { team_members_loading, my_team } = team;
  const { side_panel_views } = modal;
  return {
    token,
    team_members_loading,
    my_team,
    user,
    colors,
    device,
    task_list,
    platform,
    isMobile,
    filter_by,
    filter_user,
    sort_by,
    task_limit,
    tasks_begin,
    active_task_count,
    task_panel,
    active_task_loading,
    side_panel_views
  };
};

export default connect(mapStateToProps, {
  appRedirect,
  setModal,
  toggleModal,
  getTeam,
  editTask,
  getTasks,
  setActiveTaskCount,
  popSidePanel,
  clearTaskList,
  deleteTask,
  setCompletedRoute,
  setPlannedRoute,
  triggerPlannedRoute,
  selectActiveProperty,
  getPropertyNew,
  pushSidePanel,
  tabNavigation,
  getTask,
  completeTask
})(TaskEdit);
