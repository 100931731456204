import React, { Component } from "react";
import { connect } from "react-redux";
import {
  GhostButton,
  SelectItem,
  UpsellButton
} from "app/NativeComponents/snippets";
import {
  createCallSession,
  pushSidePanel,
  showErrorMessage,
  setActiveDialerContact,
  numberWithCommas,
  checkIfUserHasMetadata
} from "app/NativeActions";

class DialerButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submit_loading: false
    };

    this._device = null;
  }
  componentDidMount() {}

  startCallSession() {
    const {
      selected_all,
      selected_leads,
      search,
      search_type,
      filtered_count,
      list_history_id,
      lead_filters,
      applied_filter,
      active_list_tab
    } = this.props;

    this.props.createCallSession({
      token: this.props.token,
      select_all: selected_all ? 1 : 0,
      total_count: selected_all ? filtered_count : selected_leads.length,
      new_filters:
        applied_filter && applied_filter.filters
          ? applied_filter?.filters
          : null,
      filters:
        applied_filter && applied_filter.old_filters
          ? applied_filter?.old_filters
          : null,
      property_flags: lead_filters?.property_flags
        .map(({ value }) => value)
        .join(","),
      property_flags_and_or: lead_filters?.property_flags_and_or,
      using_old_filters:
        applied_filter && applied_filter.old_filters ? true : false,
      search: search,
      search_type: search_type,
      list_id: active_list_tab?.id ? active_list_tab?.id : active_list_tab,
      list_history_id: list_history_id || "",
      lead_ids: selected_all
        ? ""
        : selected_leads
            .map(property => {
              return property.deal.id;
            })
            .join(),
      no_loading: true,
      onLoading: () => {
        this.setState({
          submit_loading: true
        });
      },
      onError: error => {
        this.setState(
          {
            submit_loading: false
          },
          () => {
            this.props.showErrorMessage(error, "Error");
          }
        );
      },
      onSuccess: results => {
        this.setState(
          {
            submit_loading: false
          },
          () => {
            if (results.queued_numbers && results.queued_numbers?.length > 0) {
              let first_contact = results.queued_numbers[0];

              this.props.setActiveDialerContact({
                contact: {
                  ...first_contact.contact,
                  selected_phone: {
                    number: first_contact.number,
                    type: first_contact.type,
                    do_not_call_flag: first_contact.do_not_call_flag,
                    owner: first_contact.owner
                  },
                  associated_lead: first_contact.property
                },
                queued_numbers: results.queued_numbers
              });
            } else {
              this.props.showErrorMessage(
                'There are no numbers to call from the selected leads based on your "Dialer Settings".',
                "Error"
              );
            }

            this.props.clearAll();
          }
        );
      }
    });
  }

  render() {
    const { action_button, is_actionable, user, button_search, title } =
      this.props;

    const has_permission =
      user?.can_send_message == 1 ||
      (!checkIfUserHasMetadata("paid_plan") &&
        !checkIfUserHasMetadata("dialer"));
    if (action_button && has_permission) {
      return (
        <GhostButton
          style={{ margin: 0 }}
          primary={true}
          icon={!checkIfUserHasMetadata("paid_plan") ? "lock" : "phone"}
          onPress={() => {
            if (!checkIfUserHasMetadata("paid_plan")) {
              this.props.pushSidePanel({
                slug: "purchase_plan_prompt",
                overlay_modal: true,
                data: {
                  title: "Get an AI-Powered Dialer With DealMachine Pro.",
                  dismiss_button:
                    "No thanks. Let me continue without an AI-Powered Dialer.",
                  no_free: true,
                  event_slug: "in_app_call_leads"
                },
                locked: true
              });
            } else {
              this.startCallSession();
            }
          }}
          loading={this.state.submit_loading}
          tooltip={
            !has_permission
              ? "You do not have permission to use this function."
              : this.props.current_call_session &&
                !this.props.current_call_session.completed
              ? "You must complete the current call session before starting a new one."
              : ""
          }
          disabled={
            checkIfUserHasMetadata("paid_plan") &&
            (!has_permission ||
              !is_actionable ||
              (this.props.current_call_session &&
                !this.props.current_call_session.completed))
          }
        >
          Call
        </GhostButton>
      );
    }
    if (has_permission) {
      if (
        !button_search ||
        title.toLowerCase().includes(button_search.toLowerCase())
      ) {
        return (
          <UpsellButton
            meta_slug="mail"
            onPress={() => this.startCallSession()}
            disabled={
              this.props.current_call_session &&
              !this.props.current_call_session.completed
            }
            renderLockedChildren={({ hovering }) => {
              return (
                <>
                  <SelectItem
                    select_type="none"
                    icon="keyboard-arrow-right"
                    noPress={true}
                    pressedIn={hovering}
                    loading={this.state.submit_loading}
                    tooltip={
                      this.props.current_call_session &&
                      !this.props.current_call_session.completed
                        ? "You must complete the current call session before starting a new one."
                        : ""
                    }
                    disabled={
                      this.props.current_call_session &&
                      !this.props.current_call_session.completed
                    }
                  >
                    Call Leads
                  </SelectItem>
                </>
              );
            }}
            renderChildren={({ hovering }) => {
              return (
                <>
                  <SelectItem
                    select_type="none"
                    icon="keyboard-arrow-right"
                    noPress={true}
                    pressedIn={hovering}
                    loading={this.state.submit_loading}
                    tooltip={
                      this.props.current_call_session &&
                      !this.props.current_call_session.completed
                        ? "You must complete the current call session before starting a new one."
                        : ""
                    }
                    disabled={
                      this.props.current_call_session &&
                      !this.props.current_call_session.completed
                    }
                  >
                    Call Leads
                  </SelectItem>
                </>
              );
            }}
          />
        );
      }
    }
    return null;
  }
}

const mapStateToProps = ({ auth, dialer }) => {
  const { token, user } = auth;
  const { current_call_session } = dialer;
  return {
    token,
    user,
    current_call_session
  };
};

export default connect(mapStateToProps, {
  pushSidePanel,
  showErrorMessage,
  createCallSession,
  setActiveDialerContact
})(DialerButton);
