import { TRIGGER_LOGOUT } from "app/DealMachineCore/types";

import API from "app/DealMachineCore/apis/DealMachineAPIV2";

const api = API.create();

export const getDashboardStats = ({
  token,
  type,
  filter_user,
  filter_team = null,
  workspace_id = null,
  filter_date,
  filter_start_date,
  filter_end_date,
  filter_design,
  filter_mail_sequence,
  include_properties,
  include_call_statuses,
  slugs,
  onLoading = () => {},
  onSuccess = () => {},
  onError = () => {}
}) => {
  return dispatch => {
    onLoading();
    api
      .getDashboardStats(
        token,
        type,
        filter_user,
        filter_team,
        workspace_id,
        filter_date,
        filter_start_date,
        filter_end_date,
        filter_design,
        filter_mail_sequence,
        include_properties,
        include_call_statuses,
        slugs
      )
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const updateNewDashboard = ({
  token,
  type,
  slug,
  onLoading = () => {},
  onSuccess = () => {},
  onError = () => {}
}) => {
  return dispatch => {
    onLoading();
    api.updateNewDashboard(token, type, slug).then(response => {
      if (response.problem != null) {
        onError(response.problem);
      } else if (response.data.error != false) {
        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
        onError(response.data.error);
      } else {
        onSuccess(response.data.results);
      }
    });
  };
};
