import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, KeyboardView } from "app/NativeComponents/common";
import { NewHeader } from "app/NativeComponents/snippets";

import {
  popSidePanel,
  updateTeamMembers,
  selectActiveTeamMember,
  getSourceOfTruth /* common functions */,
  dismissMobileKeyboard,
  checkIfUserHasModule,
  getTeamMembers,
  manageSubscription
} from "app/NativeActions";

import Body from "./Body";

class InviteByEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invite_email_options: {
        email: "",
        invite_type: "partner"
      },
      loading: false
    };
    this.loadTeamMembers();

    this.handleBack = this.handleBack.bind(this);
  }

  editInviteEmailOptions({ prop, value }) {
    this.setState({
      invite_email_options: {
        ...this.state.invite_email_options,
        [prop]: value
      }
    });
  }

  componentDidMount() {
    this.props.selectActiveTeamMember("invite");
    this.props.getSourceOfTruth({
      token: this.props.token,
      no_loading: true
    });
  }

  loadTeamMembers() {
    if (!this.props.panel_data) {
      this.props.getTeamMembers({
        token: this.props.token,
        load_type: "load",
        type: "all",
        search: "",
        begin: 0
      });
    }
  }

  /* native functions */
  handleBack() {
    dismissMobileKeyboard();
    this.props.selectActiveTeamMember(null);
    this.props.popSidePanel();
  }

  componentWillUnmount() {
    this.props.selectActiveTeamMember(null);
  }

  sendInvite() {
    dismissMobileKeyboard();

    this.props.updateTeamMembers({
      token: this.props.token,
      email: this.state.invite_email_options?.email,
      type: "invite_member",
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: () => {
        this.setState({
          loading: false
        });
        this.handleBack();
      }
    });
  }

  checkIfNeedsToSave() {
    return !!(
      this.state.invite_email_options.email &&
      this.state.invite_email_options.email !== ""
    );
  }

  render() {
    return (
      <Container>
        <NewHeader
          title={"Invite By Email"}
          leftButton={{
            icon: "arrow-back",
            onPress: this.handleBack
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <Body
            {...this.props}
            invite_email_options={this.state.invite_email_options}
            sendInvite={this.sendInvite.bind(this)}
            editInviteEmailOptions={this.editInviteEmailOptions.bind(this)}
            loading={this.state.loading}
          />
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, team, billing }) => {
  const { token, user } = auth;
  const { device } = native;
  const { invite_type } = team;
  const { plan_modules, source_of_truth } = billing;
  return {
    token,
    user,
    device,
    invite_type,
    plan_modules,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  updateTeamMembers,
  selectActiveTeamMember,
  popSidePanel,
  getSourceOfTruth,
  getTeamMembers,
  manageSubscription
})(InviteByEmail);
