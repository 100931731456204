import {
  TRIGGER_LOGOUT,
  GET_WORKSPACE_TEAMS,
  SET_ACTIVE_WORKSPACE_TEAM,
  LOGIN_USER_SUCCESS,
  GET_SOURCE_OF_TRUTH_SUCCESS,
  UPDATE_TOKEN,
  GET_WORKSPACE_SETTINGS,
  UPDATE_USER_SUCCESS,
  UPDATE_WORKSPACE_TEAM
} from "app/DealMachineCore/types";

import API from "app/DealMachineCore/apis/DealMachineAPIV2";
const api = API.create();

export const setActiveWorkspaceTeam = team => {
  return {
    type: SET_ACTIVE_WORKSPACE_TEAM,
    payload: team
  };
};

export const getWorkspaceTeams = ({
  token,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .getWorkspaceTeams({
        token
      })
      .then(response => {
        if (response?.problem != null) {
          onError(response?.problem);
        } else if (response?.data?.error != false) {
          onError(response?.data?.error);
        } else {
          if (!!response?.data?.results?.workspace_teams) {
            dispatch({
              type: GET_WORKSPACE_TEAMS,
              payload: response?.data?.results?.workspace_teams
            });
          }

          onSuccess(response?.data?.results);
        }
      });
  };
};

export const resendWorkspaceTeamInvitation = ({
  token,
  team_id,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .resendWorkspaceTeamInvitation({
        token,
        team_id
      })
      .then(response => {
        if (response?.problem != null) {
          onError(response?.problem);
        } else if (response?.data?.error != false) {
          onError(response?.data?.error);
        } else {
          onSuccess(response?.data?.results);
        }
      });
  };
};

export const editWorkspaceTeam = ({
  token,
  team_id,
  team_name,
  team_email,
  type = "edit_team",
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .editWorkspaceTeam({
        token,
        type,
        team_id,
        team_name,
        team_email
      })
      .then(response => {
        if (response?.problem != null) {
          onError(response?.problem);
        } else if (response?.data?.error != false) {
          onError(response?.data?.error);
        } else {
          onSuccess(response?.data?.results);

          if (!!response?.data?.results?.workspace_teams) {
            dispatch({
              type: GET_WORKSPACE_TEAMS,
              payload: response?.data?.results?.workspace_teams
            });
          }
        }
      });
  };
};

export const addWorkspaceTeam = ({
  token,
  firstname,
  lastname,
  email,
  team_name,
  should_team_handle_funds,
  does_team_have_allowance,
  monthly_allowance_cents,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .addWorkspaceTeam({
        token,
        firstname,
        lastname,
        email,
        team_name,
        should_team_handle_funds,
        does_team_have_allowance,
        monthly_allowance_cents
      })
      .then(response => {
        if (response?.problem != null) {
          onError(response?.problem);
        } else if (response?.data?.error != false) {
          onError(response?.data?.error);
        } else {
          onSuccess(response?.data?.results);

          if (!!response?.data?.results?.workspace_teams) {
            dispatch({
              type: GET_WORKSPACE_TEAMS,
              payload: response?.data?.results?.workspace_teams
            });
          }
        }
      });
  };
};

export const generateWorkspaceTeamName = ({
  token,
  firstname,
  lastname,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .generateWorkspaceTeamName({
        token,
        firstname,
        lastname
      })
      .then(response => {
        if (response?.problem != null) {
          onError(response?.problem);
        } else if (response?.data?.error != false) {
          onError(response?.data?.error);
        } else {
          onSuccess(response?.data?.results);
        }
      });
  };
};

export const accessWorkspaceTeam = ({
  token,
  team_id,
  device_type,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .accessWorkspaceTeam({
        token,
        team_id,
        device_type
      })
      .then(response => {
        if (response?.problem != null) {
          onError(response?.problem);
        } else if (response?.data?.error != false) {
          onError(response?.data?.error);
        } else {
          onSuccess(response?.data?.results);

          if (!!response?.data?.results?.new_token) {
            dispatch({
              type: UPDATE_TOKEN,
              payload: response?.data?.results?.new_token
            });
          }

          if (response?.data?.results?.user) {
            dispatch({
              type: UPDATE_USER_SUCCESS,
              payload: { user: response?.data?.results?.user }
            });
          }
        }
      });
  };
};

export const getWorkspaceSettings = ({
  token,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api.getWorkspaceSettings({ token }).then(response => {
      if (response?.problem != null) {
        onError(response?.problem);
      } else if (response?.data?.error != false) {
        onError(response?.data?.error);
      } else {
        onSuccess(response?.data?.results);

        if (!!response?.data?.results?.workspace_settings) {
          dispatch({
            type: GET_WORKSPACE_SETTINGS,
            payload: response?.data?.results?.workspace_settings
          });
        }
      }
    });
  };
};

export const editWorkspaceSettings = ({
  token,
  type = "edit_workspace_settings",
  team_id,
  setting_key,
  setting_value,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .editWorkspaceSettings({
        token,
        type,
        team_id,
        setting_key,
        setting_value
      })
      .then(response => {
        if (response?.problem != null) {
          onError(response?.problem);
        } else if (response?.data?.error != false) {
          onError(response?.data?.error);
        } else {
          onSuccess(response?.data?.results);

          if (!!response?.data?.results?.workspace_settings) {
            dispatch({
              type: GET_WORKSPACE_SETTINGS,
              payload: response?.data?.results?.workspace_settings
            });
          }

          if (response?.data?.results?.user) {
            dispatch({
              type: UPDATE_USER_SUCCESS,
              payload: { user: response?.data?.results?.user }
            });
          }

          if (!!response?.data?.results?.workspace_team) {
            //update the correct workspace team
            dispatch({
              type: UPDATE_WORKSPACE_TEAM,
              payload: response?.data?.results?.workspace_team
            });
          }
        }
      });
  };
};

export const getWorkspaceTeamSeats = ({
  token,
  team_id,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .getWorkspaceTeamSeats({
        token,
        team_id
      })
      .then(response => {
        if (response?.problem != null) {
          onError(response?.problem);
        } else if (response?.data?.error != false) {
          onError(response?.data?.error);
        } else {
          onSuccess(response?.data?.results);
        }
      });
  };
};

export const getWorkspaceTeamLicenses = ({
  token,
  type,
  team_id,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .getWorkspaceTeamLicenses({
        token,
        type,
        team_id
      })
      .then(response => {
        if (response?.problem != null) {
          onError(response?.problem);
        } else if (response?.data?.error != false) {
          onError(response?.data?.error);
        } else {
          onSuccess(response?.data?.results);
        }
      });
  };
};

export const editWorkspaceTeamLicenses = ({
  token,
  type,
  team_id,
  seat_type,
  license_id,
  assign_to,
  old_assigned_user,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .editWorkspaceTeamLicenses({
        token,
        type,
        team_id,
        seat_type,
        license_id,
        assign_to,
        old_assigned_user
      })
      .then(response => {
        if (response?.problem != null) {
          onError(response?.problem);
        } else if (response?.data?.error != false) {
          onError(response?.data?.error);
        } else {
          onSuccess(response?.data?.results);
        }
      });
  };
};

export const editWorkspaceTeamSeats = ({
  token,
  team_id,
  seat_id,
  type,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .editWorkspaceTeamSeats({
        token,
        team_id,
        seat_id,
        type
      })
      .then(response => {
        if (response?.problem != null) {
          onError(response?.problem);
        } else if (response?.data?.error != false) {
          onError(response?.data?.error);
        } else {
          onSuccess(response?.data?.results);

          if (!!response?.data?.results?.workspace_teams) {
            dispatch({
              type: GET_WORKSPACE_TEAMS,
              payload: response?.data?.results?.workspace_teams
            });
          }
        }
      });
  };
};
