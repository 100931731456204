import React, { Component } from "react";
import { Wrapper, CardBody, Copy, Form } from "app/NativeComponents/common";

import Buttons from "./Buttons";

class Body extends Component {
  render() {
    return (
      <>
        <Wrapper style={{ padding: 25 }}>
          <Copy style={{ textAlign: "center" }}>
            Your team has been deactivated by your account administrator. Please
            contact your account administrator to reactivate your team.
          </Copy>
        </Wrapper>
      </>
    );
  }
}

export default Body;
