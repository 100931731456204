import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Page,
  Spinner,
  Wrapper,
  Card,
  Copy,
  Bold,
  Row
} from "app/NativeComponents/common";
import {
  InputBox,
  GhostButton,
  InlineButton,
  OnboardingWrapper,
  OnboardingNavbar,
  SmallLabel,
  SelectBox
} from "app/NativeComponents/snippets";

import { Header1, Header3 } from "app/NativeComponents/common";

import {
  logout,
  startRedirect,
  pushSidePanel,
  updateUser,
  showSuccess,
  showErrorMessage,
  getMarketingOnboardingData,
  dismissMobileKeyboard
} from "app/NativeActions";
class CreatePasswordStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: props.user?.password,
      password_confirm: props.user?.password_confirm,
      loading_onboarding: false
    };

    this._password = React.createRef();
    this._password_confirm = React.createRef();
    this._loading_onboarding = React.createRef();

    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
    this.continueToNextStep = this.continueToNextStep.bind(this);
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/onboarding/create-password");
    }
  }

  checkIfNeedsToSave() {
    if (
      !!this.state.password &&
      !!this.state.password_confirm &&
      this.state.password === this.state.password_confirm
    ) {
      return true;
    }
    return false;
  }

  be4e5901716335b339560cd067cc9ba3;

  continueToNextStep() {
    if (this.checkIfNeedsToSave()) {
      this.props.getMarketingOnboardingData({
        token: this.props.token,
        type: "create_password",
        password: this.state.password,
        zip: this.state.zip,
        onLoading: () => {
          this.setState({
            loading_onboarding: true
          });
        },
        onError: error => {
          this.setState({
            loading_onboarding: false
          });

          this.props.showErrorMessage(error, "Error");
        },
        onSuccess: ({ filters, title }) => {
          this.props.updateUser({
            token: this.props.token,
            type: "onboarding_stepthrough_step",
            payload: {
              onboarding_stepthrough_current_step:
                !this.props.user?.phone || !this.props.user?.address
                  ? "more_info_step"
                  : "credit_card_step"
            },
            onLoading: () => {},
            onError: () => {
              this.setState({
                loading_onboarding: false
              });
            },
            onSuccess: () => {
              this.setState({
                loading_onboarding: false
              });
            }
          });
        }
      });
    }
  }

  render() {
    const { colors, user } = this.props;
    if (this.props.init && this.props.user) {
    } else if (this.props.loading) {
      return <Spinner />;
    }

    return (
      <Page
        metaTitle={"👋 Welcome to DealMachine! - DealMachine for Real Estate"}
        responsive_design={true}
      >
        <OnboardingWrapper>
          <>
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: 25,
                maxWidth: 500,
                margin: this.props.device == "desktop" ? "0px auto" : 0
              }}
            >
              <Header1
                style={{
                  textAlign: "center"
                }}
              >
                <Bold>👋 Welcome to DealMachine, {user?.firstname}!</Bold>
              </Header1>
              <Wrapper style={{ padding: 25, paddingBottom: 0 }}>
                <Header3
                  style={{
                    textAlign: "center"
                  }}
                >
                  Please create a password. This will be used to log in to your
                  account.
                </Header3>
              </Wrapper>

              <Card style={{ marginBottom: 25 }}>
                <InputBox
                  input_ref={this._password}
                  name="password"
                  returnKeyType="next"
                  blurOnSubmit={false}
                  autoCapitalize="words"
                  keyboardType="default"
                  placeholder="Password"
                  onChange={value => {
                    this.setState({
                      password: value
                    });
                  }}
                  value={this.state.password}
                  onSubmitEditing={() => {
                    if (this._password_confirm?.current) {
                      this._password_confirm.current.focus();
                    }
                  }}
                  type="password"
                  secureTextEntry={true}
                />

                <InputBox
                  input_ref={this._password_confirm}
                  name="password_confirm"
                  returnKeyType="next"
                  blurOnSubmit={false}
                  autoCapitalize="words"
                  keyboardType="default"
                  placeholder="Confirm Password"
                  type="password"
                  secureTextEntry={true}
                  onChange={value => {
                    this.setState({
                      password_confirm: value
                    });
                  }}
                  value={this.state.password_confirm}
                  onSubmitEditing={() => {
                    this.continueToNextStep();
                  }}
                />
              </Card>
              <Wrapper style={{ alignSelf: "stretch" }}>
                <GhostButton
                  loading={this.state.loading_onboarding}
                  button_type="full"
                  disabled={!this.checkIfNeedsToSave()}
                  onPress={() => {
                    this.continueToNextStep();
                  }}
                  primary={true}
                  icon_right="arrow-forward"
                >
                  Continue
                </GhostButton>
              </Wrapper>
            </Wrapper>

            <Wrapper
              style={{
                maxWidth: 500,
                width: "100%",
                margin: this.props.device == "desktop" ? "5px auto" : 0
              }}
            ></Wrapper>
          </>
        </OnboardingWrapper>
      </Page>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { isMobile, device, initialRedirect } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect
  };
};

export default connect(mapStateToProps, {
  logout,
  startRedirect,
  pushSidePanel,
  updateUser,
  showSuccess,
  showErrorMessage,
  getMarketingOnboardingData
})(CreatePasswordStep);
