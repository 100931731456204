import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  KeyboardView,
  Row,
  Wrapper,
  Bold,
  Copy
} from "app/NativeComponents/common";
import {
  NewHeader,
  SmallLabel,
  InputBox,
  SelectItem,
  GhostButton
} from "app/NativeComponents/snippets";

import {
  generateWorkspaceTeamName,
  popSidePanel,
  addWorkspaceTeam,
  showErrorMessage,
  numberWithCommas,
  showSuccess
} from "app/NativeActions";

class AddNewTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      team_name: "",
      firstname: "",
      lastname: "",
      email: "",
      does_team_have_allowance: false,
      monthly_allowance_cents: 0,
      should_team_handle_funds: true
    };

    this._firstname = React.createRef();
    this._lastname = React.createRef();
    this._email = React.createRef();
    this._team_name = React.createRef();

    this.getName = this.getName.bind(this);
  }

  componentDidMount() {}

  getName() {
    if (!!this.state.firstname && !!this.state.lastname) {
      this.setState({
        team_name:
          this.state.firstname.trim() +
          " " +
          this.state.lastname.trim() +
          "'s Team"
      });
    }
  }

  render() {
    return (
      <Container>
        <NewHeader
          title={"Add New Team"}
          subtitle={""}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.props.popSidePanel();
            }
          }}
        />

        <KeyboardView style={{ flex: 1 }}>
          <SmallLabel
            description={
              "This user will be the leader of this team. They will be able to add and remove members to this team."
            }
          >
            Team Leader
          </SmallLabel>

          <Row>
            <Wrapper style={{ flex: 1 }}>
              <InputBox
                input_ref={this._firstname}
                name="firstname"
                returnKeyType="next"
                blurOnSubmit={false}
                autoCapitalize="words"
                keyboardType="default"
                placeholder="First Name"
                onChange={value => {
                  this.setState(
                    {
                      firstname: value
                    },
                    () => {
                      this.getName();
                    }
                  );
                }}
                value={this.state.firstname}
                onSubmitEditing={() => {
                  if (this._lastname?.current) {
                    this._lastname.current.focus();
                  }
                }}
                type="text"
              />
            </Wrapper>

            <Wrapper style={{ flex: 1 }}>
              <InputBox
                input_ref={this._lastname}
                name="lastname"
                returnKeyType="next"
                blurOnSubmit={false}
                autoCapitalize="words"
                keyboardType="default"
                placeholder="Last Name"
                type="text"
                onChange={value => {
                  this.setState(
                    {
                      lastname: value
                    },
                    () => {
                      this.getName();
                    }
                  );
                }}
                value={this.state.lastname}
                onSubmitEditing={() => {
                  if (this._email?.current) {
                    this._email.current.focus();
                  }
                }}
              />
            </Wrapper>
          </Row>

          <InputBox
            input_ref={this._email}
            name="email"
            returnKeyType="next"
            blurOnSubmit={false}
            autoCapitalize="none"
            keyboardType="email-address"
            placeholder="Email Address"
            type="text"
            onChange={value => {
              this.setState({
                email: value
              });
            }}
            onFocus={() => {}}
            value={this.state.email}
            onSubmitEditing={() => {
              if (this._team_name?.current) {
                this._team_name.current.focus();
              }
            }}
          />

          <SmallLabel
            description={
              "Give this team a name and decide how you want to manage it."
            }
          >
            Account Settings
          </SmallLabel>
          <InputBox
            input_ref={this._team_name}
            name="team_name"
            returnKeyType="next"
            blurOnSubmit={false}
            autoCapitalize="words"
            keyboardType="default"
            placeholder="Team Name"
            validationRequired={true}
            loading={this.state.loading_name}
            onChange={value => {
              this.setState({
                team_name: value
              });
            }}
            onFocus={() => {
              this.getName();
            }}
            value={this.state.team_name}
            onSubmitEditing={() => {}}
            type="text"
          />

          <SelectItem
            selected={!this.state.should_team_handle_funds}
            onPress={() => {
              this.setState({
                should_team_handle_funds: !this.state.should_team_handle_funds
              });
            }}
            select_type="toggle"
            description={
              "When set to true, this administrator account will cover all marketing charges for this team. Otherwise the team leader will be billed for any charges."
            }
          >
            Cover Marketing Charges
          </SelectItem>

          {this.state.should_team_handle_funds ? (
            <>
              <SelectItem
                selected={this.state.does_team_have_allowance}
                onPress={() => {
                  this.setState({
                    does_team_have_allowance:
                      !this.state.does_team_have_allowance,
                    monthly_allowance_cents: !this.state
                      .does_team_have_allowance
                      ? 10000
                      : 0
                  });
                }}
                select_type="toggle"
                description={
                  "Set a monthly allowance for this team. This will add X number of marketing credits to the team every month. Any charges over the allowance will be billed to the team leader."
                }
              >
                Add Allowance
              </SelectItem>
              {this.state.does_team_have_allowance && (
                <InputBox
                  input_ref={this._allowance}
                  name="allowance"
                  placeholder="Monthly Allowance"
                  type="text"
                  onChange={value => {
                    const numbers = parseInt(value.replace(/\D+/g, ""));

                    this.setState({
                      monthly_allowance_cents:
                        parseInt(numbers) === 0 || !numbers
                          ? ""
                          : parseInt(numbers)
                    });
                  }}
                  value={
                    !!this.state.monthly_allowance_cents
                      ? "$" +
                        numberWithCommas(
                          parseInt(
                            this.state.monthly_allowance_cents / 100
                          ).toString()
                        )
                      : ""
                  }
                  options={[
                    {
                      value: "10000",
                      label: "$100"
                    },
                    {
                      value: "20000",
                      label: "$200"
                    },
                    {
                      value: "30000",
                      label: "$300"
                    },
                    {
                      value: "40000",
                      label: "$400"
                    },
                    {
                      value: "50000",
                      label: "$500"
                    },
                    {
                      value: "100000",
                      label: "$1,000"
                    },
                    {
                      value: "200000",
                      label: "$2,000"
                    },
                    {
                      value: "300000",
                      label: "$3,000"
                    },
                    {
                      value: "400000",
                      label: "$4,000"
                    },
                    {
                      value: "500000",
                      label: "$5,000"
                    }
                  ]}
                />
              )}
            </>
          ) : null}

          <GhostButton
            button_type="full"
            loading={this.state.loading_add}
            onPress={() => {
              this.props.addWorkspaceTeam({
                token: this.props.token,
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                email: this.state.email,
                team_name: this.state.team_name,
                does_team_have_allowance: this.state.does_team_have_allowance,
                monthly_allowance_cents: this.state.monthly_allowance_cents,
                should_team_handle_funds: this.state.should_team_handle_funds,
                onLoading: () => {
                  this.setState({
                    loading_add: true
                  });
                },
                onError: error => {
                  this.setState({
                    loading_add: false
                  });

                  this.props.showErrorMessage(error, "Error");
                },
                onSuccess: () => {
                  this.setState(
                    {
                      loading_add: false
                    },
                    () => {
                      this.props.showSuccess(
                        "Team added successfully",
                        "Success"
                      );
                      this.props.popSidePanel();
                    }
                  );
                }
              });
            }}
            primary={true}
          >
            Add Team
          </GhostButton>
          {this.state.add_allowance && (
            <Wrapper style={{ padding: 25 }}>
              <Copy style={{ textAlign: "center", fontSize: 12 }}>
                Your first billing of{" "}
                <Bold>{"$" + numberWithCommas(this.state.allowance)}</Bold> will
                happen immediately.
              </Copy>
            </Wrapper>
          )}
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user, user_partner_info } = auth;
  const { device, isIphoneX, isMobile, platform, desktopMobile } = native;
  const { colors } = settings;
  return {
    user,
    user_partner_info,
    token,
    device,
    isIphoneX,
    isMobile,
    platform,
    desktopMobile,
    colors
  };
};

export default connect(mapStateToProps, {
  generateWorkspaceTeamName,
  addWorkspaceTeam,
  showErrorMessage,
  popSidePanel,
  showSuccess
})(AddNewTeam);
