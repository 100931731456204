import React, { Component } from "react";
import {
  Wrapper,
  Card,
  CardBody,
  Title,
  Copy,
  Bold,
  Row,
  Button,
  Icon
} from "app/NativeComponents/common";
import {
  InputBox,
  InlineButton,
  InformationItem,
  ConfirmInputButton
} from "app/NativeComponents/snippets";

import EditableCustomField from "./EditableCustomField";

class CustomFields extends Component {
  getGroupedCustomFields(custom_fields) {
    let grouped_custom_fields = [];
    if (custom_fields) {
      for (const [key, value] of Object.entries(custom_fields)) {
        const custom_field_group = value;
        let found_group = false;
        for (let j = 0; j < grouped_custom_fields.length; j++) {
          if (
            custom_field_group.custom_field_group_id ==
            grouped_custom_fields[j].id
          ) {
            found_group = true;
          }
        }

        if (!found_group) {
          grouped_custom_fields.push({
            id: custom_field_group.custom_field_group_id,
            title: custom_field_group.group_title,
            group_order: custom_field_group.group_order,
            fields: []
          });
        }

        for (let j = 0; j < grouped_custom_fields.length; j++) {
          if (
            custom_field_group.custom_field_group_id ==
            grouped_custom_fields[j].id
          ) {
            grouped_custom_fields[j].fields.push(custom_field_group);
          }
        }
      }

      for (let i = 0; i < grouped_custom_fields.length; i++) {
        grouped_custom_fields[i].fields.sort((a, b) =>
          a.field_order > b.field_order ? 1 : -1
        );
      }
      grouped_custom_fields.sort((a, b) =>
        a.group_order > b.group_order ? 1 : -1
      );
    }
    return grouped_custom_fields;
  }

  formatItems(field_options) {
    let options = field_options.split(",");

    let formatted_options = [
      {
        value: "",
        label: "--"
      }
    ];
    for (let i = 0; i < options.length; i++) {
      formatted_options.push({
        value: options[i]?.trim(),
        label: options[i]?.trim()
      });
    }

    return formatted_options;
  }

  render() {
    const {
      property,
      property: { deal },
      colors,
      user,
      search,
      no_editing
    } = this.props;

    if (deal) {
      let grouped_custom_fields = this.getGroupedCustomFields(
        deal?.custom_fields
      );

      return (
        <>
          {grouped_custom_fields.map((grouped_custom_field, i) => {
            return (
              <Wrapper key={"custom_field_group_" + i}>
                {!search ? (
                  <Wrapper
                    style={{
                      padding: 25,
                      paddingTop: 15,
                      paddingBottom: 15
                    }}
                  >
                    <Copy>
                      <Bold>{grouped_custom_field.title}</Bold>
                    </Copy>
                  </Wrapper>
                ) : null}

                {grouped_custom_field.fields.map((custom_field, j) => {
                  if (
                    custom_field.title
                      .toLowerCase()
                      .indexOf(search?.toLowerCase()) !== -1
                  ) {
                    return (
                      <EditableCustomField
                        key={"field" + j}
                        {...this.props}
                        no_editing={no_editing}
                        custom_field={custom_field}
                      />
                    );
                  }
                  return null;
                })}
              </Wrapper>
            );
          })}
        </>
      );
    }

    return null;
  }
}

export default CustomFields;
