import React, { Component } from "react";
import {
  Wrapper,
  Row,
  ProfilePic,
  Copy,
  Bold,
  Icon,
  Button
} from "app/NativeComponents/common";
import { openUrl, renderDate } from "app/NativeActions";
import moment from "moment";
import ActivityImage from "./ActivityImage";

class ActivityItem extends Component {
  render() {
    const displayName =
      this.props.item.user_firstname && this.props.item.user_lastname
        ? this.props.item.user_firstname + " " + this.props.item.user_lastname
        : this.props.item.user_email;
    switch (this.props.item.activity_type) {
      case "leads_added":
        return (
          <Button
            onPress={() => {
              let lead_text =
                this.props.item.activity_number == 1
                  ? "1 lead"
                  : this.props.item.activity_number + " leads";
              this.props.setActivityPropertiesType({
                title: "Leads Added",
                subtitle:
                  "Showing " +
                  lead_text +
                  " added by " +
                  displayName +
                  " on " +
                  renderDate(this.props.item.date_created, true),
                slug: this.props.item.activity_type,
                date: moment(this.props.item.date_created).format("YYYY-MM-DD"),
                team_member_id: this.props.item.user_id
              });
              this.props.pushSidePanel({
                slug: "activity_property_list",
                overlay: true
              });
            }}
            style={{ padding: 20 }}
          >
            <Row style={{ alignItems: "flex-start" }}>
              <ProfilePic
                size={36}
                email={this.props.item.user_email}
                image={this.props.item.user_image}
                style={{ marginTop: 2, marginRight: 10 }}
              />
              <Wrapper style={{ flex: 1 }}>
                <Copy>
                  <Bold>{displayName}</Bold> added{" "}
                  <Bold>{this.props.item.activity_number}</Bold>{" "}
                  {this.props.item.activity_number == 1 ? "lead" : "leads"}
                </Copy>
                <Copy
                  style={{
                    color: this.props.colors.light_text_color,
                    fontSize: 12
                  }}
                >
                  {renderDate(this.props.item.date_created, true)}
                </Copy>
              </Wrapper>
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: 50
                }}
              >
                <Icon size={18} icon="keyboard-arrow-right" />
              </Wrapper>
            </Row>
          </Button>
        );

      case "build_list":
        if (this.props.item.activity_number == 1) {
          return (
            <Button
              onPress={() => {
                if (this.props.item?.property) {
                  this.props.pushSidePanel({
                    slug: "property",
                    id: this.props.item?.property?.property_id,
                    focus_side_panel:
                      this.props.user?.user_settings?.property_expanded ===
                      "not_expanded"
                        ? false
                        : true,
                    overlay: true,
                    data: {
                      property: this.props.item?.property
                    }
                  });
                  this.props.pushSidePanel({
                    slug: "activity_property_list",
                    overlay: true
                  });
                }
              }}
              style={{ padding: 20 }}
            >
              <Row style={{ alignItems: "flex-start" }}>
                <ProfilePic
                  size={36}
                  email={this.props.item.user_email}
                  image={this.props.item.user_image}
                  style={{ marginTop: 2, marginRight: 10 }}
                />
                <Wrapper style={{ flex: 1 }}>
                  <Copy>
                    <Bold>{displayName}</Bold> created the list{" "}
                    <Bold>{this.props.item.activity_description}</Bold> and
                    generated the lead{" "}
                    <Bold>{this.props.item.lead_address}</Bold>
                  </Copy>
                  <Copy
                    style={{
                      color: this.props.colors.light_text_color,
                      fontSize: 12
                    }}
                  >
                    {renderDate(this.props.item.date_created)}
                  </Copy>
                  <ActivityImage {...this.props} />
                </Wrapper>
                <Wrapper
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    width: 50
                  }}
                >
                  <Icon size={18} icon="keyboard-arrow-right" />
                </Wrapper>
              </Row>
            </Button>
          );
        }

        return (
          <Button
            onPress={() => {
              let lead_text =
                this.props.item.activity_number == 1
                  ? "1 lead"
                  : this.props.item.activity_number + " leads";
              this.props.setActivityPropertiesType({
                title: "Leads generated from list",
                subtitle:
                  "Showing " +
                  lead_text +
                  " generated from the list " +
                  this.props.item.activity_description +
                  " on " +
                  renderDate(this.props.item.date_created, true),
                slug: this.props.item.activity_type,
                date: moment(this.props.item.date_created).format("YYYY-MM-DD"),
                team_member_id: this.props.item.user_id,
                list_id: this.props.item.activity_number_2
              });
              this.props.pushSidePanel({
                slug: "activity_property_list",
                overlay: true
              });
            }}
            style={{ padding: 20 }}
          >
            <Row style={{ alignItems: "flex-start" }}>
              <ProfilePic
                size={36}
                email={this.props.item.user_email}
                image={this.props.item.user_image}
                style={{ marginTop: 2, marginRight: 10 }}
              />
              <Wrapper style={{ flex: 1 }}>
                <Copy>
                  <Bold>{displayName}</Bold> created the list{" "}
                  <Bold>{this.props.item.activity_description}</Bold> and
                  generated <Bold>{this.props.item.activity_number}</Bold>{" "}
                  {this.props.item.activity_number == 1 ? "lead" : "leads"}
                </Copy>
                <Copy
                  style={{
                    color: this.props.colors.light_text_color,
                    fontSize: 12
                  }}
                >
                  {renderDate(this.props.item.date_created, true)}
                </Copy>
              </Wrapper>
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: 50
                }}
              >
                <Icon size={18} icon="keyboard-arrow-right" />
              </Wrapper>
            </Row>
          </Button>
        );

      case "mailers_sent":
        return (
          <Button
            onPress={() => {
              let lead_text =
                this.props.item.activity_number == 1
                  ? "1 mail piece"
                  : this.props.item.activity_number + " mail pieces";
              this.props.setActivityPropertiesType({
                title: "Mailers sent",
                subtitle:
                  "Showing " +
                  lead_text +
                  " sent on " +
                  renderDate(this.props.item.date_created, true),
                slug: this.props.item.activity_type,
                date: moment(this.props.item.date_created).format("YYYY-MM-DD")
              });
              this.props.pushSidePanel({
                slug: "activity_property_list",
                overlay: true
              });
            }}
            style={{ padding: 20 }}
          >
            <Row style={{ alignItems: "flex-start" }}>
              <Wrapper
                size={36}
                style={{
                  marginRight: 10,
                  width: 36,
                  height: 36,
                  borderRadius: 18,
                  backgroundColor: this.props.colors.gray_color,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Icon size={18} icon="mail" />
              </Wrapper>
              <Wrapper style={{ flex: 1 }}>
                <Copy>
                  <Bold>{this.props.item.activity_number}</Bold>{" "}
                  {this.props.item.activity_number == 1
                    ? "mail piece sent"
                    : "mail pieces sent"}
                </Copy>
                <Copy
                  style={{
                    color: this.props.colors.light_text_color,
                    fontSize: 12
                  }}
                >
                  {renderDate(this.props.item.date_created, true)}
                </Copy>
              </Wrapper>

              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: 50
                }}
              >
                <Icon size={18} icon="keyboard-arrow-right" />
              </Wrapper>
            </Row>
          </Button>
        );

      case "skip_trace":
        return (
          <Button
            onPress={() => {
              let lead_text =
                this.props.item.activity_number == 1
                  ? "1 lead"
                  : this.props.item.activity_number + " leads";
              this.props.setActivityPropertiesType({
                title: "Leads skip traced",
                subtitle:
                  "Showing " +
                  lead_text +
                  " skip traced by " +
                  displayName +
                  " on " +
                  renderDate(this.props.item.date_created, true),
                slug: this.props.item.activity_type,
                date: moment(this.props.item.date_created).format("YYYY-MM-DD"),
                team_member_id: this.props.item.user_id
              });
              this.props.pushSidePanel({
                slug: "activity_property_list",
                overlay: true
              });
            }}
            style={{ padding: 20 }}
          >
            <Row style={{ alignItems: "flex-start" }}>
              <ProfilePic
                size={36}
                email={this.props.item.user_email}
                image={this.props.item.user_image}
                style={{ marginTop: 2, marginRight: 10 }}
              />
              <Wrapper style={{ flex: 1 }}>
                <Copy>
                  <Bold>{displayName}</Bold> skip traced{" "}
                  <Bold>{this.props.item.activity_number}</Bold>{" "}
                  {this.props.item.activity_number == 1 ? "lead" : "leads"}
                </Copy>
                <Copy>
                  {this.props.item.activity_number_2} of{" "}
                  {this.props.item.activity_number} successful
                </Copy>
                <Copy
                  style={{
                    color: this.props.colors.light_text_color,
                    fontSize: 12
                  }}
                >
                  {renderDate(this.props.item.date_created, true)}
                </Copy>
              </Wrapper>
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: 50
                }}
              >
                <Icon size={18} icon="keyboard-arrow-right" />
              </Wrapper>
            </Row>
          </Button>
        );

      case "export":
        return (
          <Wrapper style={{ padding: 20 }}>
            <Row style={{ alignItems: "flex-start" }}>
              <ProfilePic
                size={36}
                email={this.props.item.user_email}
                image={this.props.item.user_image}
                style={{ marginTop: 2, marginRight: 10 }}
              />
              <Wrapper style={{ flex: 1 }}>
                <Copy>
                  <Bold>{displayName}</Bold> exported{" "}
                  {this.props.item.activity_description.includes("contacts")
                    ? "contacts"
                    : "properties"}
                  .
                </Copy>
                <Copy
                  style={{
                    color: this.props.colors.light_text_color,
                    fontSize: 12
                  }}
                >
                  {renderDate(this.props.item.date_created)}
                </Copy>
                <Button
                  style={{ marginTop: 5 }}
                  onPress={() => {
                    openUrl(this.props.item.activity_description);
                  }}
                >
                  <Row>
                    <Icon
                      size={14}
                      icon={"get-app"}
                      style={{ marginRight: 5 }}
                    />
                    <Copy>Download CSV</Copy>
                  </Row>
                </Button>
              </Wrapper>
            </Row>
          </Wrapper>
        );

      case "bulk_import":
        return (
          <Wrapper style={{ padding: 20 }}>
            <Row style={{ alignItems: "flex-start" }}>
              <ProfilePic
                size={36}
                email={this.props.item.user_email}
                image={this.props.item.user_image}
                style={{ marginTop: 2, marginRight: 10 }}
              />
              <Wrapper style={{ flex: 1 }}>
                <Copy>
                  <Bold>{displayName}</Bold> imported a list.{" "}
                  <Bold>{this.props.item.activity_number}</Bold> of{" "}
                  <Bold>{this.props.item.activity_number_2}</Bold> were
                  successfully imported.
                </Copy>
                <Copy
                  style={{
                    color: this.props.colors.light_text_color,
                    fontSize: 12
                  }}
                >
                  {renderDate(this.props.item.date_created)}
                </Copy>
              </Wrapper>
            </Row>
          </Wrapper>
        );

      case "routes":
        return (
          <Button
            onPress={() => {
              let lead_text =
                this.props.item.activity_number == 1
                  ? "1 lead"
                  : this.props.item.activity_number + " leads";
              this.props.setActivityPropertiesType({
                title: "Leads added on drive",
                subtitle:
                  "Showing " +
                  lead_text +
                  " added by " +
                  displayName +
                  " during their drive on " +
                  renderDate(this.props.item.date_created),
                slug: this.props.item.activity_type,
                route_id: this.props.item.activity_id,
                team_member_id: this.props.item.user_id
              });
              this.props.pushSidePanel({
                slug: "activity_property_list",
                overlay: true
              });
            }}
            style={{ padding: 20 }}
          >
            <Row style={{ alignItems: "flex-start" }}>
              <ProfilePic
                size={36}
                email={this.props.item.user_email}
                image={this.props.item.user_image}
                style={{ marginTop: 2, marginRight: 10 }}
              />
              <Wrapper style={{ flex: 1 }}>
                <Copy>
                  <Bold>{displayName}</Bold> drove{" "}
                  <Bold>
                    {this.props.item.activity_number_2 &&
                    this.props.item.activity_number_2 != ""
                      ? parseFloat(this.props.item.activity_number_2).toFixed(2)
                      : "0"}
                  </Bold>{" "}
                  miles adding <Bold>{this.props.item.activity_number}</Bold>{" "}
                  {this.props.item.activity_number == 1 ? "lead" : "leads"}
                </Copy>
                <Copy
                  style={{
                    color: this.props.colors.light_text_color,
                    fontSize: 12
                  }}
                >
                  {renderDate(this.props.item.date_created)}
                </Copy>
                <ActivityImage {...this.props} />
              </Wrapper>
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: 50
                }}
              >
                <Icon size={18} icon="keyboard-arrow-right" />
              </Wrapper>
            </Row>
          </Button>
        );

      case "notes":
        return (
          <Button
            onPress={() => {
              if (this.props.item?.property) {
                this.props.pushSidePanel({
                  slug: "property",
                  id: this.props.item?.property?.property_id,
                  focus_side_panel:
                    this.props.user?.user_settings?.property_expanded ===
                    "not_expanded"
                      ? false
                      : true,
                  overlay: true,
                  data: {
                    property: this.props.item?.property
                  }
                });
              }
            }}
            style={{ padding: 20 }}
          >
            <Row style={{ alignItems: "flex-start" }}>
              <ProfilePic
                size={36}
                email={this.props.item.user_email}
                image={this.props.item.user_image}
                style={{ marginTop: 2, marginRight: 10 }}
              />
              <Wrapper style={{ flex: 1 }}>
                <Copy>
                  <Bold>{displayName}</Bold> wrote a note for{" "}
                  <Bold>{this.props.item.lead_address}</Bold>.
                </Copy>
                <Copy
                  style={{
                    color: this.props.colors.light_text_color,
                    fontSize: 12
                  }}
                >
                  {renderDate(this.props.item.date_created)}
                </Copy>
                <Copy>{this.props.item.activity_description}</Copy>
              </Wrapper>
            </Row>
          </Button>
        );

      case "smart_list":
        const { activity_number, activity_number_2 } = this.props.item;

        const isAddToList = activity_number == "add_to_list";
        const action = isAddToList ? "Added" : "Removed";
        const pluralSuffix = activity_number_2 == 1 ? "" : "s";

        const subtitle =
          action +
          " " +
          " " +
          activity_number_2 +
          " lead" +
          pluralSuffix +
          " from list via smart updates.";

        let lead_text = activity_number_2 == 1 ? " lead was " : " leads were ";
        let change_text =
          activity_number === "add_to_list"
            ? "added to list "
            : "removed from ";
        let date_updated = moment(this.props.item.date_created).format(
          "YYYY-MM-DD"
        );
        return (
          <Wrapper style={{ padding: 20 }}>
            <Button
              style={{ marginTop: 5 }}
              onPress={() => {
                this.props.pushSidePanel({
                  slug: "view_list_history_item",
                  overlay: true,
                  data: {
                    list_history_item: {
                      change_type: activity_number,
                      history_date: date_updated,
                      source_type: "smart_list",
                      id: this.props.item.lead_id + "_" + date_updated,
                      list_id: this.props.item.lead_id,
                      number_of_leads: activity_number_2,
                      user_email: this.props.item.user_email,
                      user_firstname: this.props.item.user_firstname,
                      user_id: this.props.item.user_id,
                      user_image: this.props.item.user_image,
                      user_lastname: this.props.item.user_lastname
                    },
                    subtitle: subtitle
                  }
                });
              }}
            >
              <Row style={{ alignItems: "flex-start" }}>
                <ProfilePic
                  size={36}
                  email={this.props.item.user_email}
                  image={this.props.item.user_image}
                  style={{ marginTop: 2, marginRight: 10 }}
                />

                <Wrapper style={{ flex: 1 }}>
                  <Copy>
                    <Bold>{this.props.item.activity_number_2}</Bold>
                    {lead_text}
                    {change_text}{" "}
                    <Bold>{this.props.item.activity_description}</Bold> via
                    smart list update.
                  </Copy>
                  <Copy
                    style={{
                      color: this.props.colors.light_text_color,
                      fontSize: 12
                    }}
                  >
                    {renderDate(this.props.item.date_created)}
                  </Copy>
                </Wrapper>
                <Icon size={18} icon="keyboard-arrow-right" />
              </Row>
            </Button>
          </Wrapper>
        );

      case "tasks":
        return (
          <Button
            onPress={() => {
              this.props.pushSidePanel({
                slug: "edit_task",
                overlay: true,
                data: { task: { id: this.props.item.activity_id } }
              });
            }}
            style={{ padding: 20 }}
          >
            <Row style={{ alignItems: "flex-start" }}>
              <ProfilePic
                size={36}
                email={this.props.item.user_email}
                image={this.props.item.user_image}
                style={{ marginTop: 2, marginRight: 10 }}
              />
              <Wrapper style={{ flex: 1 }}>
                <Copy>
                  <Bold>{displayName}</Bold> {this.props.item.activity_title}.
                </Copy>
                <Copy
                  style={{
                    color: this.props.colors.light_text_color,
                    fontSize: 12
                  }}
                >
                  {renderDate(this.props.item.date_created)}
                </Copy>
                <ActivityImage {...this.props} />
              </Wrapper>
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: 50
                }}
              >
                <Icon size={18} icon="keyboard-arrow-right" />
              </Wrapper>
            </Row>
          </Button>
        );

      default:
        return (
          <Wrapper style={{ padding: 20 }}>
            <Row style={{ alignItems: "flex-start" }}>
              <ProfilePic
                size={36}
                email={this.props.item.user_email}
                image={this.props.item.user_image}
                style={{ marginTop: 2, marginRight: 10 }}
              />
              <Wrapper style={{ flex: 1 }}>
                <Copy>
                  <Bold>{displayName}</Bold> {this.props.item.activity_title}.
                </Copy>
                <Copy
                  style={{
                    color: this.props.colors.light_text_color,
                    fontSize: 12
                  }}
                >
                  {renderDate(this.props.item.date_created)}
                </Copy>
              </Wrapper>
            </Row>
          </Wrapper>
        );
    }
  }
}

export default ActivityItem;
