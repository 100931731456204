import React, { PureComponent } from "react";
import { Row, Wrapper, Copy, Scroll } from "app/NativeComponents/common";
import {
  UpsellButton,
  GhostButton,
  SmallHeader,
  BottomNavBar,
  InlineButton,
  ConfirmInlineButton,
  InputBox,
  SelectItem,
  SmallLabel
} from "app/NativeComponents/snippets";
import AdvancedFilters from "app/DealMachineCore/reuseable/AdvancedFilters";
class HighlightFilters extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      is_selecting: false,
      loading: false,
      deleting: false,
      saved_filter: null,
      confirm_save: false,
      filter_id: props.filter_id || null,
      filter_name: props?.filter_name || "",
      default_filter: props.default_filter || false,
      filters: props.filters || {
        data: [],
        andor_type: "or"
      }
    };

    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
    this._filter_name = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.filters) !==
        JSON.stringify(prevProps.filters) &&
      this.props.device === "desktop"
    ) {
      this.props.setAppliedHighlight({
        applied_highlight: this.props.filters
      });
    }
  }

  checkIfNeedsToSave() {
    if (
      (JSON.stringify(this.props.filters) !==
        JSON.stringify(this.state.filters) &&
        this.props.filters?.data?.length > 0) ||
      this.state.default_filter != this.props.default_filter
    ) {
      return true;
    }
  }

  saveFilter(apply) {
    const { filter_name, filter_id, default_filter } = this.state;
    const { filters } = this.props;

    this.props.updateSavedFilters({
      token: this.props.token,
      title: filter_name,
      filters,
      filter_type: "highlights",
      type: !filter_id ? "create_saved_filter" : "update_saved_filter",
      default_filter: default_filter ? 1 : 0,
      advanced_filter_id: filter_id,
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          loading: false
        });
        if (results?.saved_filters) {
          this.setState(
            {
              filters: results?.saved_filters[0]?.filters,
              filter_id: results?.saved_filters[0]?.id,
              filter_name: results?.saved_filters[0]?.title,
              default_filter: results?.saved_filters[0]?.default_filter
            },
            () => {
              this.props.updateFilters({
                filters: results?.saved_filters[0]?.filters,
                filter_id: results?.saved_filters[0]?.id,
                filter_name: results?.saved_filters[0]?.title,
                default_filter: results?.saved_filters[0]?.default_filter
              });
            }
          );
        }

        if (apply) {
        }
      }
    });
  }

  render() {
    const { colors, isMobile, filters } = this.props;
    const { loading, filter_name } = this.state;

    let num = 0;
    for (let i = 0; i < this.props.filters?.data?.length; i++) {
      for (let j = 0; j < this.props.filters?.data[i]?.data?.length; j++) {
        num++;
      }
    }

    const can_edit = true;

    return (
      <>
        <AdvancedFilters
          filters={filters}
          use_list_builder_info={true}
          editable={can_edit}
          open_filter={this.props.open_filter}
          renderHeader={
            !this.state.is_selecting && can_edit
              ? () => {
                  return (
                    <SmallHeader
                      title={
                        filters.data.length == 0 && !this.props.has_map_filters
                          ? "Select a filter:"
                          : "More filters:"
                      }
                    ></SmallHeader>
                  );
                }
              : null
          }
          onSelecting={is_selecting => {
            this.setState({
              is_selecting: is_selecting
            });
          }}
          onChange={filters => {
            this.props.updateFilters({ filters });
          }}
        />
        {this.props.checkIfNeedsToApply() &&
        !this.state.is_selecting &&
        can_edit &&
        this.props.device === "mobile" ? (
          <BottomNavBar>
            <InlineButton
              button_type="full"
              primary={true}
              icon={num == 0 ? "close" : "tune"}
              style={{ backgroundColor: colors.orange_color_muted }}
              description={
                num == 0
                  ? ""
                  : num == 1
                    ? "1 Additional Filters"
                    : num + " Additional Filters"
              }
              onPress={() => {
                this.props.setAppliedHighlight({
                  applied_highlight: this.props.filters
                });
                this.props.popSidePanel();
              }}
            >
              {num == 0 ? "Clear More Filters" : "Apply More Filters"}
            </InlineButton>
          </BottomNavBar>
        ) : this.props.filter_id &&
          !this.checkIfNeedsToSave() &&
          !this.state.is_selecting &&
          can_edit ? (
          <BottomNavBar>
            <ConfirmInlineButton
              button_type="full"
              confirm_text="Really Delete?"
              loading_text="Deleting"
              confirm_icon="delete"
              loading={this.state.deleting}
              onPress={() => {
                this.props.updateSavedFilters({
                  token: this.props.token,
                  type: "remove_saved_filter",
                  advanced_filter_id: this.props.filter_id,
                  filter_type: "highlights",
                  onLoading: () => {
                    this.setState({
                      deleting: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      deleting: false
                    });
                  },
                  onSuccess: results => {
                    this.setState({
                      deleting: false
                    });

                    this.props.popSidePanel();
                  }
                });
              }}
            >
              Delete Saved Highlights
            </ConfirmInlineButton>
          </BottomNavBar>
        ) : this.props.applied_highlight?.data?.length > 0 &&
          !this.props.checkIfNeedsToApply() &&
          can_edit ? (
          <BottomNavBar>
            <ConfirmInlineButton
              button_type="full"
              confirm_text="Really Clear?"
              loading_text="Clearing"
              loading={false}
              onPress={() => {
                this.props.setAppliedHighlight({
                  applied_highlight: {
                    data: [],
                    andor_type: "or"
                  }
                });

                this.props.popSidePanel();

                if (this.props.closeHighlightPopover) {
                  this.props.closeHighlightPopover();
                }
              }}
            >
              {"Clear All Additonal Filters"}
            </ConfirmInlineButton>
          </BottomNavBar>
        ) : null}
      </>
    );
  }
}

export default HighlightFilters;
