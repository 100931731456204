import React, { PureComponent } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Copy,
  Row,
  Button,
  Bold,
  Spin
} from "app/NativeComponents/common";
import { GhostButton, UpsellButton } from "app/NativeComponents/snippets";
import {
  checkIfUserHasMetadata,
  setActiveDialerContact,
  decryptData
} from "app/NativeActions";
class CallButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  render() {
    const { property, phone, contact, row_height, toggle_lead_images, user } =
      this.props;
    const { loading } = this.state;

    return checkIfUserHasMetadata("dialer") &&
      (user?.team_owner == 1 || user?.can_send_message == 1) &&
      !property?.deal?.updating?.is_updating ? (
      <Wrapper
        className="deal-preview"
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          bottom: 0,
          width: toggle_lead_images ? "100%" : "auto",
          /*backgroundColor: toggle_lead_images
                          ? dark_mode == "dark_mode"
                            ? "rgba(0,0,0, 0.25)"
                            : "rgba(255, 255, 255, 0.75)"
                          : colors.card_color*/
          margin: toggle_lead_images ? 0 : 10,
          height: toggle_lead_images ? "100%" : row_height,
          alignItems: "flex-end",
          justifyContent: "center"
        }}
      >
        <UpsellButton
          primary={false}
          meta_slug="dialer"
          disabled={user.can_send_message == 0}
          onPress={() => {
            this.props.setActiveDialerContact({
              contact: {
                ...contact,
                selected_phone: {
                  number: decryptData(phone?.phone_number_formatted),
                  type: phone?.type,
                  do_not_call_flag: phone?.do_not_call,
                  owner: phone?.carrier
                },
                associated_lead: property,
                one_off_call: true
              },
              queued_numbers:
                !this.props.current_call_session ||
                this.props.current_call_session?.completed
                  ? []
                  : null
            });
          }}
          renderLockedChildren={({ hovering }) => {
            return (
              <>
                <GhostButton
                  icon={"phone"}
                  loading={loading}
                  noPress={true}
                  pressedIn={hovering}
                  primary={true}
                  style={{
                    margin: 0
                  }}
                  innerStyle={{
                    padding: 5,
                    paddingRight: 10,
                    paddingLeft: 10
                  }}
                >
                  Call
                </GhostButton>
              </>
            );
          }}
          renderChildren={({ hovering }) => {
            return (
              <>
                <GhostButton
                  icon={"phone"}
                  loading={loading}
                  noPress={true}
                  pressedIn={hovering}
                  primary={true}
                  style={{
                    margin: 0
                  }}
                  innerStyle={{
                    padding: 5,
                    paddingRight: 10,
                    paddingLeft: 10
                  }}
                >
                  Call
                </GhostButton>
              </>
            );
          }}
        />
      </Wrapper>
    ) : null;
  }
}

const mapStateToProps = ({ auth, settings, filter, dialer }) => {
  const { token, user } = auth;
  const { dark_mode } = settings;
  const { toggle_lead_images } = filter;
  const { current_call_session } = dialer;
  return {
    token,
    user,
    dark_mode,
    toggle_lead_images,
    current_call_session
  };
};

export default connect(mapStateToProps, {
  setActiveDialerContact
})(CallButton);
