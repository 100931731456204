import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  Wrapper,
  Title
} from "app/NativeComponents/common";
import {
  NewHeader,
  InlineButton,
  GhostButton
} from "app/NativeComponents/snippets";

import Body from "./Body";

import {
  /*mobile*/
  toggleCanPop,
  lockDrawer,
  toggleDrawer,
  appRedirect,
  updateUser,
  logout,
  displayIntercom,

  /*common functions*/
  dismissMobileKeyboard
} from "app/NativeActions";

class WorkspaceTeamRemoved extends Component {
  componentDidMount() {}

  render() {
    return (
      <Container>
        {this.props.device == "mobile" ? (
          <NewHeader title="Inactive Account" />
        ) : (
          <Wrapper style={{ padding: 25 }}>
            <Title style={{ textAlign: "center" }}>Deactivated Account</Title>
          </Wrapper>
        )}

        <KeyboardView style={{ flex: 1 }}>
          <Body {...this.props} />
          <GhostButton
            button_type="full"
            id="intercom_button"
            onPress={() => displayIntercom()}
          >
            Talk To Support
          </GhostButton>
          <InlineButton button_type="full" onPress={() => this.props.logout()}>
            Logout
          </InlineButton>
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native }) => {
  const { token, user } = auth;
  const { device } = native;

  return {
    token,
    user,
    device
  };
};

export default connect(mapStateToProps, {
  /*mobile*/
  toggleCanPop,
  lockDrawer,
  toggleDrawer,

  appRedirect,

  updateUser,
  logout
})(WorkspaceTeamRemoved);
