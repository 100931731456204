import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  KeyboardView,
  Wrapper,
  Copy,
  Row,
  Card
} from "app/NativeComponents/common";
import {
  NewHeader,
  SpinWrapper,
  GhostButton,
  InlineButton,
  List,
  SelectItem,
  SmallLabel,
  AttentionBox,
  InlineTabs,
  PopoverMenu,
  IconButton,
  BottomNavBar
} from "app/NativeComponents/snippets";
import {
  popSidePanel,
  pushSidePanel,
  renderDate,
  manageSubscription,
  getTeam
} from "app/NativeActions";

class DialerLicenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "dialer"
    };
  }

  componentDidMount() {
    this.getDialerSeats();

    if (!this.props.team_loading && this.props.my_team?.length === 0) {
      this.props.getTeam({ token: this.props.token, type: "load" });
    }
  }

  getDialerSeats() {}

  componentDidUpdate(prevProps) {}

  render() {
    const { colors } = this.props;
    const dialer_seats =
      (this.props.source_of_truth?.dialer_seats &&
        this.props.source_of_truth?.dialer_seats.filter(
          seat => seat.seat_type === "dialer"
        )) ||
      [];
    const voicemail_seats =
      (this.props.source_of_truth?.dialer_seats &&
        this.props.source_of_truth?.dialer_seats.filter(
          seat => seat.seat_type === "voicemail"
        )) ||
      [];
    return (
      <Container>
        <NewHeader
          title={"Dialer Licenses"}
          leftButton={{
            icon: "arrow-back",
            onPress: this.props.popSidePanel
          }}
        />
        {this.props.user?.workspace_id ? null : (
          <AttentionBox
            title={"Buy a Dialer License"}
            description={
              "Tired of paying per minute? Buy a dialer license and make unlimited calls. Licenses are limited to one member on your team."
            }
          />
        )}

        <InlineTabs
          tab_type="underline"
          selected_tab={this.state.tab}
          tabs={[
            {
              onPress: () => {
                this.setState({
                  tab: "dialer"
                });
              },
              slug: "dialer",
              title: "Unlimited Dialer"
            },

            {
              onPress: () => {
                this.setState({
                  tab: "voicemail"
                });
              },
              slug: "voicemail",
              title: "Unlimited AI Voicemails"
            }
          ]}
        />
        {this.state.tab === "dialer" ? (
          <>
            {dialer_seats?.length === 0 ? (
              <>
                <Wrapper
                  style={{
                    padding: 25,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Copy>
                    {this.props.user?.workspace_id
                      ? "You don't have any licenses for unlimited dialer minutes. Talk to your administrator to get a licenses."
                      : "You don't have any licenses for unlimited dialer minutes."}
                  </Copy>
                </Wrapper>
                {this.props.user?.workspace_id ? null : (
                  <GhostButton
                    primary={true}
                    button_type={"full"}
                    onPress={() => {
                      this.props.pushSidePanel({
                        slug: "buy_dialer_license",
                        overlay: true
                      });
                    }}
                  >
                    Buy Dialer License
                  </GhostButton>
                )}
              </>
            ) : (
              <>
                <List
                  rowNumber={1}
                  style={{ flex: 1 }}
                  items={dialer_seats}
                  infiniteScroll={true}
                  itemStructure={({ item, index }) => {
                    return (
                      <SelectItem
                        key={"dialer_seat" + index}
                        select_type={"none"}
                        subtext={renderDate(item.date_created)}
                        description={"Dialer License"}
                        onPress={() => {
                          this.props.pushSidePanel({
                            slug: "edit_dialer_license",
                            overlay: true,
                            data: {
                              assigned_user: item?.assigned_user,
                              seat_type: "dialer",
                              license_id: item?.id
                            }
                          });
                        }}
                        icon={"keyboard-arrow-right"}
                      >
                        {!!item?.assigned_user && item?.assigned_user != 0
                          ? !!item.firstname
                            ? item.firstname + " " + item.lastname
                            : item.email
                          : "Unassigned License"}
                      </SelectItem>
                    );
                  }}
                  canRefresh={false}
                  onRefresh={() => {}}
                  is_refreshing={false}
                  canLoadMore={false}
                  isLoadingMore={false}
                  onLoadMore={() => {}}
                />

                {this.props.user?.workspace_id ? null : (
                  <BottomNavBar>
                    <InlineButton
                      primary={true}
                      icon={"add"}
                      button_type={"full"}
                      onPress={() => {
                        this.props.pushSidePanel({
                          slug: "buy_dialer_license",
                          overlay: true
                        });
                      }}
                    >
                      Buy Dialer License
                    </InlineButton>
                  </BottomNavBar>
                )}
              </>
            )}
          </>
        ) : this.state.tab === "voicemail" ? (
          <>
            {voicemail_seats?.length === 0 ? (
              <>
                <Wrapper
                  style={{
                    padding: 25,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  {this.props.user?.workspace_id ? (
                    <Copy>
                      You don't have any licenses for unlimited AI voicemails.
                      Talk to your administrator to get a licenses.
                    </Copy>
                  ) : (
                    <Copy>
                      You don't have any licenses for unlimited AI voicemails.
                    </Copy>
                  )}
                </Wrapper>

                {this.props.user?.workspace_id ? null : (
                  <GhostButton
                    primary={true}
                    button_type={"full"}
                    onPress={() => {
                      this.props.pushSidePanel({
                        slug: "buy_voicemail_license",
                        overlay: true
                      });
                    }}
                  >
                    Buy AI Voicemail License
                  </GhostButton>
                )}
              </>
            ) : (
              <>
                <List
                  rowNumber={1}
                  style={{ flex: 1 }}
                  items={voicemail_seats}
                  infiniteScroll={true}
                  itemStructure={({ item, index }) => {
                    return (
                      <SelectItem
                        key={"dialer_seat" + index}
                        select_type={"none"}
                        subtext={renderDate(item.date_created)}
                        description={"AI Voicemail License"}
                        icon={"keyboard-arrow-right"}
                        onPress={() => {
                          this.props.pushSidePanel({
                            slug: "edit_dialer_license",
                            overlay: true,
                            data: {
                              assigned_user: item?.assigned_user,
                              seat_type: "voicemail",
                              license_id: item?.id
                            }
                          });
                        }}
                      >
                        {!!item?.assigned_user && item?.assigned_user != 0
                          ? !!item.firstname
                            ? item.firstname + " " + item.lastname
                            : item.email
                          : "Unassigned License"}
                      </SelectItem>
                    );
                  }}
                  canRefresh={false}
                  onRefresh={() => {}}
                  is_refreshing={false}
                  canLoadMore={false}
                  isLoadingMore={false}
                  onLoadMore={() => {}}
                />

                {this.props.user?.workspace_id ? null : (
                  <BottomNavBar>
                    <InlineButton
                      primary={true}
                      icon={"add"}
                      button_type={"full"}
                      onPress={() => {
                        this.props.pushSidePanel({
                          slug: "buy_voicemail_license",
                          overlay: true
                        });
                      }}
                    >
                      Buy AI Voicemail License
                    </InlineButton>
                  </BottomNavBar>
                )}
              </>
            )}
          </>
        ) : null}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, billing, team }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile, device } = native;
  const { source_of_truth } = billing;
  const { my_team, team_loading } = team;

  return {
    token,
    user,
    isMobile,
    colors,
    device,
    source_of_truth,
    my_team,
    team_loading
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  manageSubscription,
  getTeam
})(DialerLicenses);
