import {
  LOGOUT,
  GET_LISTS,
  REFRESH_LISTS,
  GET_LISTS_FAIL,
  GET_LISTS_SUCCESS,
  LOAD_MORE_LISTS,
  LOAD_MORE_LISTS_SUCCESS,
  RELOAD_LISTS,
  CREATE_LIST,
  CREATE_LIST_FAIL,
  CREATE_LIST_SUCCESS,
  UPDATE_LIST,
  UPDATE_LIST_FAIL,
  UPDATE_LIST_SUCCESS,
  REMOVE_LIST,
  REMOVE_LIST_FAIL,
  REMOVE_LIST_SUCCESS,
  SET_LIST_MODAL,
  RESET_LIST_MODAL,
  TOGGLE_LIST_MODAL,
  UPDATE_LIST_SEARCH,
  LIST_COUNT,
  LIST_COUNT_FAIL,
  LIST_COUNT_SUCCESS,
  BUILD_LIST,
  BUILD_LIST_FAIL,
  BUILD_LIST_SUCCESS,
  SET_ACTIVE_LIST,
  GET_SINGLE_LIST_ITEM,
  REFRESH_SINGLE_LIST_ITEM,
  GET_SINGLE_LIST_ITEM_SUCCESS,
  EDIT_ACTIVE_LIST,
  GET_MAIL_FAILED_DEALS,
  GET_MAIL_FAILED_DEALS_FAIL,
  GET_MAIL_FAILED_DEALS_SUCCESS,
  RELOAD_LIST,
  ADD_LIST_TO_LIST_TABS,
  REMOVE_LIST_FROM_LIST_TABS,
  SET_ACTIVE_LIST_TAB,
  TOGGLE_LIST_LIBRARY,
  SET_EDIT_LIST_INFO,
  TOGGLE_NEW_LIST,
  REORDER_LIST_TABS,
  GET_PINNED_LISTS_SUCCESS,
  UPDATE_LEAD_SUCCESS,
  UPDATE_LIST_BUILDER_PROGRESS,
  GET_LIST_BUILDER_TEMPLATES_SUCCESS,
  UPDATE_LIST_BUILDER_LOCATION,
  LOGIN_USER_SUCCESS,
  AI_LIST_BUILD_SCAN,
  COMPLETE_AI_LIST_BUILDER,
  AI_LIST_BUILD_START,
  REMOVE_AI_LIST_BUILD_SCAN,
  ADD_AI_LIST_BUILD_SCAN_FROM_QUEUE,
  GET_CURRENT_AI_LIST_BUILD,
  CANCEL_AI_LIST_BUILD,
  DISMISS_AI_LIST_BUILDER
} from "app/DealMachineCore/types";

const INITIAL_STATE = {
  lists: [],

  lists_loading: false,
  lists_refreshing: false,
  lists_begin: 0,
  lists_loaded_all: false,
  lists_limit: 25,
  list_modal: { selected_lists: [] },

  list_search: "",

  get_list_count: 0,
  get_list_count_loading: false,

  active_list: null,
  active_list_progress: 0,
  active_list_other_workspace_team_success_counter: 0,
  active_list_passed_counter: 0,
  loading_single_list_item: false,
  refreshing_single_list_item: false,
  lists_limits: null,
  edit_active_list: null,
  show_list_modal: false,

  list_tabs: [],
  active_list_tab: "all_leads",

  show_list_library: false,
  edit_list_info: null,
  show_new_list: false,
  building_lists: [],
  list_builder_templates: [],
  recently_built_lists: [],

  list_builder_location: {
    latitude: null,
    longitude: null,
    zoom: 13,
    pitch: 0,
    heading: 0,
    show_leads_on_map: true,
    show_lists_on_map: true,
    show_highlights_on_map: true,
    map_type: "standard"
  },
  list_builder_limits: {},
  ai_list_building: null,
  ai_list_scan_animations: [],
  ai_list_scans: [],
  completed_scans: []
};

const containsListTab = (list_tabs, list) => {
  for (let i = 0; i < list_tabs.length; i++) {
    if (list_tabs[i]?.id === list?.id) {
      return true;
    }
  }

  return false;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE
      };

    case GET_LISTS:
      return {
        ...state,
        list_properties_error: "",
        lists_begin: 0,
        lists_loaded_all: false,
        lists_loading: true,
        lists: []
      };

    case RELOAD_LISTS:
      return {
        ...state,
        list_properties_error: "",
        lists_begin: 0,
        lists_loaded_all: false
      };
    case LOAD_MORE_LISTS:
      return {
        ...state,
        list_properties_error: "",
        lists_loading: true,
        lists_refreshing: false
      };
    case RELOAD_LIST:
      return {
        ...state,
        active_list:
          state.active_list &&
          action.payload.list &&
          state.active_list.id == action.payload.list.id
            ? action.payload.list
            : state.active_list,
        lists:
          state.lists && state.lists.length > 0 && action.payload.list
            ? state.lists.map((list, i) => {
                if (list.id == action.payload.list.id) {
                  return action.payload.list;
                }
                return list;
              })
            : state.lists,

        active_list_tab:
          action.payload.list?.id == state.active_list_tab?.id
            ? action.payload.list
            : state.active_list_tab,
        list_tabs:
          state.list_tabs && state.list_tabs.length > 0 && action.payload.list
            ? state.list_tabs.map((list, i) => {
                if (list.id == action.payload.list.id) {
                  return action.payload.list;
                }
                return list;
              })
            : state.list_tabs
      };
    case REFRESH_LISTS:
      return {
        ...state,
        list_properties_error: "",
        lists_loading: true,
        lists_refreshing: true,
        lists_begin: 0,
        lists_loaded_all: false,
        lists: []
      };
    case GET_LISTS_FAIL:
      return {
        ...state,
        lists_loading: false,
        lists_refreshing: false,
        loading_single_list_item: false,
        refreshing_single_list_item: false
      };
    case GET_LISTS_SUCCESS:
      return {
        ...state,
        lists_refreshing: false,
        lists_loading: false,
        lists: action.payload.lists,
        lists_begin: state.lists_begin + action.payload.lists.length,
        lists_loaded_all: action.payload.lists.length < state.lists_limit,
        lists_limits: action.payload.lists_limits
          ? action.payload.lists_limits
          : state.lists_limits
      };

    case LOAD_MORE_LISTS_SUCCESS:
      return {
        ...state,
        lists_refreshing: false,
        lists_loading: false,
        lists: state.lists.concat(action.payload.lists),
        lists_begin: state.lists_begin + action.payload.lists.length,
        lists_loaded_all: action.payload.lists.length < state.lists_limit,
        lists_limits: action.payload.lists_limits
          ? action.payload.lists_limits
          : state.lists_limits
      };
    case GET_PINNED_LISTS_SUCCESS:
      return {
        ...state,
        list_tabs: action.payload.lists
      };
    case RESET_LIST_MODAL:
      return {
        ...state,
        list_modal: INITIAL_STATE.list_modal,
        list_search: INITIAL_STATE.list_search
      };

    case SET_LIST_MODAL:
      return {
        ...state,
        list_modal: action.payload.selected_lists
          ? action.payload
          : { ...action.payload, selected_lists: [] }
      };
    case TOGGLE_LIST_MODAL:
      return {
        ...state,
        show_list_modal: action.payload
      };
    case UPDATE_LIST_SEARCH:
      return {
        ...state,
        list_search: action.payload
      };
    case BUILD_LIST:
      return {
        ...state,
        list_loading: true
      };
    case SET_ACTIVE_LIST:
      return {
        ...state,
        active_list: action.payload,
        active_list_progress: 0,
        active_list_other_workspace_team_success_counter: 0,
        active_list_passed_counter: 0
      };

    case BUILD_LIST_FAIL:
      return {
        ...state,
        list_loading: false
      };
    case BUILD_LIST_SUCCESS:
      return {
        ...state,
        list_search: "",
        list_modal: state.list_modal
          ? {
              ...state.list_modal,
              selected_lists:
                action.payload.lists.length > 0
                  ? [
                      ...state.list_modal.selected_lists,
                      action.payload.lists[0]
                    ]
                  : state.list_modal.selected_lists
            }
          : state.list_modal,
        lists:
          action.payload.lists.length > 0
            ? [action.payload.lists[0], ...state.lists]
            : state.lists,
        active_list:
          action.payload.lists.length > 0
            ? action.payload.lists[0]
            : state.active_list,
        lists_limits: action.payload.lists_limits
          ? action.payload.lists_limits
          : state.lists_limits
      };
    case CREATE_LIST:
      return {
        ...state,
        list_loading: true
      };
    case CREATE_LIST_FAIL:
      return {
        ...state,
        list_loading: false
      };
    case CREATE_LIST_SUCCESS:
      return {
        ...state,
        list_loading: false,
        list_search: "",
        list_modal: state.list_modal
          ? {
              ...state.list_modal,
              selected_lists:
                action.payload.lists.length > 0
                  ? [
                      ...state.list_modal.selected_lists,
                      action.payload.lists[0]
                    ]
                  : state.list_modal.selected_lists
            }
          : state.list_modal,
        lists:
          action.payload.lists.length > 0
            ? [action.payload.lists[0], ...state.lists]
            : state.lists,
        lists_limits: action.payload.lists_limits
          ? action.payload.lists_limits
          : state.lists_limits
      };

    case UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        lists: action.payload.new_list
          ? [action.payload.new_list, ...state.lists]
          : state.lists
      };
    case REMOVE_LIST_SUCCESS:
      return {
        ...state,
        lists: state.lists.filter(({ id }) => id != action.payload),
        active_list_tab:
          action.payload == state.active_list_tab?.id || state.list_tabs == 1
            ? "all_leads"
            : state.active_list_tab,
        list_tabs: state.list_tabs.filter(({ id }) => id != action.payload),
        active_list: null
      };
    case LIST_COUNT:
      return {
        ...state,
        get_list_count: 0,
        get_list_count_loading: true
      };
    case LIST_COUNT_FAIL:
      return {
        ...state,
        get_list_count_loading: false
      };
    case LIST_COUNT_SUCCESS:
      return {
        ...state,
        get_list_count: action.payload.lead_count,
        get_list_count_loading: false
      };
    case GET_SINGLE_LIST_ITEM:
      return {
        ...state,
        loading_single_list_item: true,
        refreshing_single_list_item: false
      };
    case REFRESH_SINGLE_LIST_ITEM:
      return {
        ...state,
        loading_single_list_item: true,
        refreshing_single_list_item: true
      };
    case GET_SINGLE_LIST_ITEM_SUCCESS:
      return {
        ...state,
        loading_single_list_item: false,
        refreshing_single_list_item: false,
        active_list: action.payload.lists
          ? action.payload.lists[0]
          : state.active_list,
        edit_active_list: action.payload.lists
          ? action.payload.lists[0]
          : state.active_list,
        lists: action.payload.lists
          ? state.lists.map((list, i) => {
              if (action.payload.lists[0].id == list.id) {
                return action.payload.lists[0];
              }
              return list;
            })
          : state.lists
      };
    case UPDATE_LIST_SUCCESS:
      return {
        ...state,
        active_list: action.payload.lists
          ? action.payload.lists[0]
          : state.active_list,
        lists: action.payload.lists
          ? state.lists.map((list, i) => {
              if (action.payload.lists[0].id == list.id) {
                return action.payload.lists[0];
              }
              return list;
            })
          : state.lists,
        active_list_tab:
          state.active_list_tab?.id &&
          state.active_list_tab &&
          action.payload.lists[0].id == state.active_list_tab.id
            ? action.payload.lists[0]
            : state.active_list_tab,
        list_tabs: action.payload.lists
          ? state.list_tabs.map((list, i) => {
              if (action.payload.lists[0].id == list.id) {
                return action.payload.lists[0];
              }
              return list;
            })
          : state.list_tabs
      };
    case EDIT_ACTIVE_LIST:
      return {
        ...state,
        edit_active_list: {
          ...state.edit_active_list,
          [action.payload.prop]: action.payload.value
        }
      };

    case SET_ACTIVE_LIST_TAB:
      return {
        ...state,
        active_list_tab: action.payload.list
      };
    case REORDER_LIST_TABS:
      return {
        ...state,
        list_tabs: action.payload
      };
    case ADD_LIST_TO_LIST_TABS:
      return {
        ...state,
        active_list_tab: action.payload.list,
        list_tabs: !containsListTab(state.list_tabs, action.payload.list)
          ? [...state.list_tabs, action.payload.list]
          : state.list_tabs
      };

    case REMOVE_LIST_FROM_LIST_TABS:
      return {
        ...state,
        active_list_tab:
          action.payload.list.id == state.active_list_tab?.id ||
          state.list_tabs == 1
            ? "all_leads"
            : state.active_list_tab,
        list_tabs: state.list_tabs.filter(
          ({ id }) => id != action.payload.list?.id
        )
      };

    case TOGGLE_LIST_LIBRARY:
      return {
        ...state,
        show_list_library: action.payload
      };

    case SET_EDIT_LIST_INFO:
      return {
        ...state,
        edit_list_info: action.payload
      };

    case TOGGLE_NEW_LIST:
      return {
        ...state,
        show_new_list: action.payload
      };

    case UPDATE_LIST_BUILDER_PROGRESS:
      let found_list = false;
      let bl = JSON.parse(JSON.stringify(state.building_lists));
      for (let i = 0; i < bl.length; i++) {
        if (bl[i].list_id === action.payload?.list?.id) {
          bl[i].list = action.payload.list;
          bl[i].total = action.payload.total
            ? parseInt(action.payload.total)
            : parseInt(bl[i].total);
          bl[i].success_counter = action.payload.success_counter
            ? parseInt(bl[i].success_counter) +
              parseInt(action.payload.success_counter)
            : bl[i].success_counter;
          bl[i].other_workspace_team_success_counter = action.payload
            .other_workspace_team_success_counter
            ? parseInt(bl[i].other_workspace_team_success_counter) +
              parseInt(action.payload.other_workspace_team_success_counter)
            : bl[i].other_workspace_team_success_counter;
          bl[i].passed_counter = action.payload.passed_counter
            ? parseInt(bl[i].passed_counter) +
              parseInt(action.payload.passed_counter)
            : bl[i].passed_counter;
          found_list = true;
        }
      }
      if (!found_list) {
        bl.push({
          list_id: action.payload.list?.id,
          total: action.payload.total ? parseInt(action.payload.total) : 0,
          success_counter: action.payload.success_counter
            ? parseInt(action.payload.success_counter)
            : 0,
          other_workspace_team_success_counter: action.payload
            .other_workspace_team_success_counter
            ? parseInt(action.payload.other_workspace_team_success_counter)
            : 0,
          passed_counter: action.payload.passed_counter
            ? parseInt(action.payload.passed_counter)
            : 0
        });
      }
      return {
        ...state,
        building_lists: bl,
        active_list_progress:
          parseInt(state.active_list_progress) +
          parseInt(action.payload.success_counter),
        active_list_other_workspace_team_success_counter:
          parseInt(state.active_list_other_workspace_team_success_counter) +
          parseInt(action.payload.other_workspace_team_success_counter),
        active_list_passed_counter:
          parseInt(state.active_list_passed_counter) +
          parseInt(action.payload.passed_counter)
      };

    case GET_LIST_BUILDER_TEMPLATES_SUCCESS:
      return {
        ...state,
        list_builder_templates: action.payload.list_builder_templates,
        recently_built_lists: action.payload.recently_built_lists,
        list_builder_limits: action.payload.list_builder_limits
      };

    case UPDATE_LIST_BUILDER_LOCATION:
      return {
        ...state,
        list_builder_location: {
          ...state.list_builder_location,
          ...action.payload
        }
      };

    case LOGIN_USER_SUCCESS:
      return {
        ...state,

        list_builder_location: {
          ...state.list_builder_location,
          ...action.payload?.user?.user_settings?.list_builder_location
        }
      };

    case AI_LIST_BUILD_START:
      return {
        ...state,
        ai_list_building: {
          list_id: action.payload?.list?.id,
          list: action.payload?.list,
          passed_counter: 0,
          total_counter: 0,
          failed_counter: 0,
          chunk_size: action.payload?.chunk_size,
          cancel_building: false
        },
        ai_list_scans: [],
        ai_list_scan_animations: [],
        completed_scans: []
      };

    case GET_CURRENT_AI_LIST_BUILD:
      return {
        ...state,
        ai_list_building: {
          list_id: action.payload?.list?.id,
          list: action.payload?.list,
          passed_counter: parseInt(action.payload?.passed_count),
          total_counter: parseInt(action.payload?.total_count),
          failed_counter: parseInt(action.payload?.failed_count),
          cancel_building: false
        },
        ai_list_scans: [],
        ai_list_scan_animations: [],
        completed_scans: []
      };
    case CANCEL_AI_LIST_BUILD:
      return {
        ...state,
        ai_list_building: {
          ...state.ai_list_building,
          list: action.payload,
          cancel_building: true
        }
      };

    case AI_LIST_BUILD_SCAN:
      return {
        ...state,
        ai_list_building:
          action.payload?.ai_scan?.list_id == state.ai_list_building?.list_id
            ? {
                ...state.ai_list_building,
                passed_counter:
                  state.ai_list_building.passed_counter +
                  action.payload.passed_counter,
                total_counter:
                  state.ai_list_building.total_counter +
                  action.payload.total_counter,
                failed_counter:
                  state.ai_list_building.failed_counter +
                  action.payload.failed_counter
              }
            : state.ai_list_building,

        //if less than 3 add one...other wise...look for an item where complete is true and replace it...only replace one
        ai_list_scan_animations:
          action.payload?.ai_scan?.list_id == state.ai_list_building?.list_id &&
          state.ai_list_building?.list?.building == 1
            ? state.ai_list_scan_animations.length < 3
              ? [...state.ai_list_scan_animations, action.payload?.ai_scan]
              : state.ai_list_scan_animations.map((scan, index) => {
                  if (
                    index ===
                    state.ai_list_scan_animations.findIndex(s => s.complete)
                  ) {
                    return action.payload?.ai_scan;
                  }
                  return scan;
                })
            : state.ai_list_scan_animations,

        ai_list_scans:
          action.payload?.ai_scan?.list_id == state.ai_list_building?.list_id
            ? state.ai_list_scans.length > 100
              ? [...state.ai_list_scans.slice(0, 99), action.payload?.ai_scan]
              : [...state.ai_list_scans, action.payload?.ai_scan]
            : state.ai_list_scans
      };

    case REMOVE_AI_LIST_BUILD_SCAN:
      const newScan = state.ai_list_scans
        .slice()
        .reverse()
        .find(
          scan =>
            !state.completed_scans.includes(scan.property_id) &&
            (scan.has_passed_criteria ||
              !state.ai_list_scans.some(
                s =>
                  s.has_passed_criteria &&
                  !state.completed_scans.includes(s.property_id)
              ))
        );
      /*

      if (newScan) {
        return {
          ...state,
          ai_list_scan_animations: state.ai_list_scan_animations.map(
            (scan, index) => {
              if (
                index ===
                state.ai_list_scan_animations.findIndex(s => s.complete)
              ) {
                return newScan;
              }
              return scan;
            }
          ),
          completed_scans: [
            ...state.completed_scans,
            action.payload.property_id
          ]
        };
      }
        */

      return {
        ...state,
        ai_list_scan_animations:
          state.ai_list_building?.list?.building == 1
            ? state.ai_list_scan_animations.map(scan => {
                if (scan.property_id == action.payload.property_id) {
                  return { ...scan, complete: true };
                }
                return scan;
              })
            : state.ai_list_scan_animations,
        completed_scans: [...state.completed_scans, action.payload.property_id]
      };

    case ADD_AI_LIST_BUILD_SCAN_FROM_QUEUE:
      break;

    case COMPLETE_AI_LIST_BUILDER:
      return {
        ...state,
        ai_list_building: {
          ...state.ai_list_building,
          list: action.payload
        }
      };

    case DISMISS_AI_LIST_BUILDER:
      return {
        ...state,
        ai_list_building: null,
        ai_list_scan_animations: [],
        ai_list_scans: [],
        completed_scans: []
      };
    default:
      return state;
  }
};
