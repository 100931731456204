import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  NewHeader,
  SelectItem,
  SmallLabel,
  AttentionBox
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  pushSidePanel,
  openUrl,
  AppConfig
} from "app/NativeActions";

class BillingSettings extends Component {
  handleBack() {
    this.props.popSidePanel();
  }

  render() {
    return (
      <Container>
        <NewHeader
          title="Billing"
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />

        <KeyboardView style={{ flex: 1 }}>
          {this.props.user?.team_type !== "workspace" ? (
            <SelectItem
              select_type="none"
              icon="keyboard-arrow-right"
              onPress={() => {
                this.props.pushSidePanel({ slug: "manage_auto_reload" });
              }}
            >
              Manage Auto-Reload
            </SelectItem>
          ) : null}

          <SelectItem
            select_type="none"
            icon="keyboard-arrow-right"
            onPress={() => {
              if (this.props.isMobile) {
                openUrl(
                  AppConfig().api_url +
                    "billing/customer-portal/?token=" +
                    this.props.token,
                  true
                );
              } else {
                openUrl(
                  AppConfig().api_url +
                    "billing/customer-portal/?token=" +
                    this.props.token +
                    "&return_url=" +
                    AppConfig().app_url,
                  true
                );
              }
            }}
          >
            Payment Methods
          </SelectItem>

          <SelectItem
            select_type="none"
            icon="keyboard-arrow-right"
            onPress={() => {
              openUrl(
                AppConfig().api_url +
                  "billing/customer-portal/?token=" +
                  this.props.token +
                  "&return_url=" +
                  AppConfig().app_url,
                true
              );
            }}
          >
            Invoices
          </SelectItem>
          {this.props.user?.team_type !== "workspace" ? (
            <SelectItem
              select_type="none"
              icon="keyboard-arrow-right"
              onPress={() => {
                this.props.pushSidePanel({ slug: "marketing_credits" });
              }}
            >
              Marketing Spend Report
            </SelectItem>
          ) : null}
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { device, platform, isMobile } = native;
  const { colors } = settings;

  return { token, user, device, colors, platform, isMobile };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel
})(BillingSettings);
