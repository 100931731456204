import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  Wrapper,
  Copy,
  Title,
  Bold,
  Header1,
  Header3,
  Page
} from "app/NativeComponents/common";
import {
  NewHeader,
  InlineButton,
  GhostButton,
  OnboardingWrapper
} from "app/NativeComponents/snippets";

import {
  openUrl,
  renderDate,
  AppConfig,
  displayIntercom,
  startRedirect,
  manageSubscription,
  toggleChurnBuster,
  tabNavigation,
  logout
} from "app/NativeActions";

class PausedAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resume_loading: false
    };
  }
  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/paused-account");
    }
  }

  render() {
    const { source_of_truth, user } = this.props;
    return (
      <Page
        metaTitle={"Paused Account - DealMachine for Real Estate"}
        responsive_design={true}
      >
        <OnboardingWrapper>
          <Wrapper style={{ padding: 25 }}>
            <Header1 style={{ textAlign: "center" }}>
              <Bold>We'll see you again, soon.</Bold>
            </Header1>
            {user?.can_mimic_team_owner == 1 ? (
              <Header3 style={{ textAlign: "center" }}>
                Your account is paused and will resume on{" "}
                {renderDate(
                  source_of_truth?.subscription?.pause_resumes_at,
                  true
                )}
              </Header3>
            ) : !!source_of_truth?.subscription?.pause_resumes_at ? (
              <Header3 style={{ textAlign: "center" }}>
                Your account is paused and will resume on{" "}
                {renderDate(
                  source_of_truth?.subscription?.pause_resumes_at,
                  true
                )}
                . An admin on your account can resume at any time.
              </Header3>
            ) : (
              <Header3 style={{ textAlign: "center" }}>
                Your account is paused and will resume on{" "}
                {renderDate(
                  source_of_truth?.subscription?.pause_resumes_at,
                  true
                )}
                . An admin on your account can resume at any time.
              </Header3>
            )}
          </Wrapper>
          <Wrapper
            style={{
              width: "100%",
              margin: this.props.device == "desktop" ? "5px auto" : 0,
              alignSelf: "stretch"
            }}
          >
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                alignSelf: "stretch"
              }}
            >
              {user?.can_mimic_team_owner == 1 ? (
                <>
                  {!this.props.toggle_churn_buster ? (
                    <GhostButton
                      primary={true}
                      button_type={"full"}
                      loading={this.state.resume_loading}
                      icon_right={"arrow-right-alt"}
                      onPress={() => {
                        this.props.manageSubscription({
                          token: this.props.token,
                          type: "resume_subscription",
                          onLoading: () => {
                            this.setState({
                              resume_loading: true
                            });
                          },
                          onError: () => {
                            this.setState({
                              resume_loading: false
                            });
                          },
                          onSuccess: () => {
                            this.setState(
                              {
                                resume_loading: false
                              },
                              () => {
                                this.props.tabNavigation({ slug: "leads" });
                                this.props.toggleChurnBuster(false);
                              }
                            );
                          }
                        });
                      }}
                    >
                      Resume Subscription
                    </GhostButton>
                  ) : null}
                  <InlineButton
                    button_type="full"
                    id="intercom_button"
                    onPress={() => {
                      displayIntercom();
                    }}
                  >
                    Need help? Talk to a human.
                  </InlineButton>
                  <InlineButton
                    button_type="full"
                    onPress={() => this.props.logout()}
                  >
                    Logout
                  </InlineButton>
                </>
              ) : (
                <>
                  <InlineButton
                    button_type="full"
                    id="intercom_button"
                    onPress={() => {
                      displayIntercom();
                    }}
                  >
                    Need help? Talk to a human.
                  </InlineButton>
                  <InlineButton
                    button_type="full"
                    onPress={() => this.props.logout()}
                  >
                    Logout
                  </InlineButton>
                </>
              )}
            </Wrapper>
          </Wrapper>
        </OnboardingWrapper>
      </Page>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing, marketing }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors } = settings;
  const { source_of_truth } = billing;
  const { toggle_churn_buster } = marketing;
  return { token, user, device, colors, source_of_truth, toggle_churn_buster };
};

export default connect(mapStateToProps, {
  logout,
  startRedirect,
  manageSubscription,
  toggleChurnBuster,
  tabNavigation
})(PausedAccount);
