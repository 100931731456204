import React, { Component } from "react";

import { InlineButton } from "app/NativeComponents/snippets";
class CallToActions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirm_mail: false
    };

    this.startMail = this.startMail.bind(this);
  }

  startMail(property) {
    this.props.updateLead({
      token: this.props.token,
      deal_ids: property?.deal?.id,
      campaign_id: property?.deal?.current_default_campaign_id,
      type: "start_mailers",
      onLoading: () => {
        this.props.updateMainState({
          mail_loading: true
        });
      },
      onError: () => {
        this.props.updateMainState({
          mail_loading: false
        });
      },
      onSuccess: results => {
        if (this.props.propertyUpdated && results?.properties?.length == 1) {
          this.props.propertyUpdated(results.properties[0]);
        }

        this.setState({
          confirm_mail: false
        });
        this.props.updateMainState({
          mail_loading: false
        });

        this.props.changeTab("mail");
      }
    });
  }

  render() {
    const {
      property,
      colors,

      mail_loading,
      onboarding,
      nextOnboardingStep,
      token,
      platform,
      user
    } = this.props;

    const has_permission = user.can_approve_mail == 1;

    const disabled =
      property?.deal?.removed == 1 ||
      property?.deal?.archived == 1 ||
      property?.deal?.closed == 1 ||
      property?.deal?.deal_status_type == "disposition_status" ||
      property?.deal?.deal_status_type == "acquisition_status";

    return (
      <>
        {property?.deal?.approved == 1 &&
        !property?.deal?.paused &&
        !property?.deal?.campaign_complete ? (
          <>
            {property?.deal?.deal_status_type == "inactive_status" ||
            property?.deal?.deal_status_type == "marketing_complete_status" ||
            property?.deal?.deal_status_type == "disposition_status" ||
            property?.deal?.deal_status_type == "acquisition_status" ||
            property?.deal?.archived ||
            property?.deal?.closed ? (
              <InlineButton
                noPress={true}
                disabled={true}
                button_type={this.props.button_type}
                tooltip={"Your lead cannot start mail based on its status."}
              >
                Mail Disabled
              </InlineButton>
            ) : this.props.user?.pause_sending == 1 ? (
              <InlineButton
                onPress={() => {
                  this.props.changeTab("mail");
                }}
                button_type={this.props.button_type}
                icon="pause"
                style={{
                  backgroundColor: colors.active_color_muted,
                  borderColor: colors.actionable_text_color
                }}
                textColor={colors.actionable_text_color}
              >
                Mail Queue Paused
              </InlineButton>
            ) : (
              <InlineButton
                onPress={() => {
                  this.props.changeTab("mail");
                }}
                button_type={this.props.button_type}
                icon="check"
                style={{
                  backgroundColor: colors.active_color_muted,
                  borderColor: colors.actionable_text_color
                }}
                textColor={colors.actionable_text_color}
              >
                Mail Sending
              </InlineButton>
            )}
          </>
        ) : property?.deal?.paused == 1 &&
          !property?.deal?.campaign_complete ? (
          <InlineButton
            onPress={() => {
              this.props.changeTab("mail");
            }}
            button_type={this.props.button_type}
            icon="pause"
            style={{
              backgroundColor: colors.active_color_muted,
              borderColor: colors.actionable_text_color
            }}
            textColor={colors.actionable_text_color}
          >
            Mail Paused
          </InlineButton>
        ) : property?.deal?.campaign_complete ? (
          <InlineButton
            noPress={true}
            button_type={this.props.button_type}
            onPress={() => {
              this.props.changeTab("mail");
            }}
            icon="check"
            style={{
              backgroundColor: colors.active_color_muted,
              borderColor: colors.actionable_text_color
            }}
            textColor={colors.actionable_text_color}
          >
            Mail Complete
          </InlineButton>
        ) : property?.deal?.deal_status_type == "inactive_status" ||
          property?.deal?.deal_status_type == "marketing_complete_status" ||
          property?.deal?.deal_status_type == "disposition_status" ||
          property?.deal?.deal_status_type == "acquisition_status" ||
          property?.deal?.archived ||
          property?.deal?.closed ? (
          <InlineButton
            noPress={true}
            disabled={true}
            button_type={this.props.button_type}
            tooltip={"Your lead cannot start mail based on its status."}
          >
            Mail Disabled
          </InlineButton>
        ) : (
          <InlineButton
            primary={true}
            loading={mail_loading}
            disabled={!has_permission || disabled}
            button_type={this.props.button_type}
            tooltip={
              disabled && !property?.deal ? "Add lead to start mail." : ""
            }
            tooltipPlacement={"top"}
            onPress={() => {
              if (
                this.props.source_of_truth?.has_stripe_card_on_file ||
                (!!this.props.user?.workspace_id &&
                  !this.props.user?.workspace_settings
                    ?.should_team_handle_funds)
              ) {
                if (
                  !property?.deal ||
                  (property?.deal && property?.deal.removed == 1)
                ) {
                  this.props.addDeal({
                    token: this.props.token,
                    property: property,
                    route_id: this.props.current_route
                      ? this.props.current_route?.route_id
                      : 0,
                    address: property ? property.property_address : null,
                    address2: property ? property.property_address2 : null,
                    city: property ? property.property_address_city : null,
                    state: property ? property.property_address_state : null,
                    zip: property ? property.property_address_zip : null,
                    devicetype: this.props.isMobile
                      ? "mobile_map"
                      : "desktop_map",
                    add_type: "no_active",
                    onLoading: () => {
                      this.props.updateMainState({
                        mail_loading: true
                      });
                    },
                    onError: () => {
                      this.props.updateMainState({
                        mail_loading: false
                      });
                    },
                    onSuccess: results => {
                      if (this.props.propertyUpdated && results) {
                        this.props.propertyUpdated(results);

                        this.startMail(results);
                      } else {
                        this.props.updateMainState({
                          mail_loading: false
                        });
                      }
                    }
                  });
                } else {
                  this.startMail(property);
                }
              } else {
                this.props.pushSidePanel({
                  slug: "add_card_prompt",
                  overlay_modal: true,
                  data: {
                    title:
                      "Start sending mail and get in touch with REAL property owners.",
                    description:
                      "We need a card on file to start sending mail.",
                    dismiss_button:
                      "No thanks. Let me continue without sending mail."
                  },
                  locked: true
                });
              }
            }}
          >
            Start Mail
          </InlineButton>
        )}
      </>
    );
  }
}

export default CallToActions;
