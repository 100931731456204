import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Container,
  KeyboardView,
  Spinner,
  Bold,
  Wrapper,
  Spin
} from "app/NativeComponents/common";
import {
  NewHeader,
  InlineButton,
  BottomNavBar
} from "app/NativeComponents/snippets";

import {
  /*mobile*/
  toggleCanPop,
  authFieldChanged,
  loginUser,
  registerUser,
  appRedirect,
  getUser,
  getSourceOfTruth,
  getStats,
  setOAuthInfo,
  /*common functions*/
  dismissMobileKeyboard,
  getUserFromAppleAuth,
  displayIntercom,
  pushSidePanel,
  popAllSidePanels,
  replaceSidePanel,
  popSidePanel,
  getCookies
} from "app/NativeActions";

import Body from "./Body";
import axios from "axios";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      oauth_loading: false,
      ip_address: null
    };

    this.handleBack = this.handleBack.bind(this);
    this.getIPAddress = this.getIPAddress.bind(this);
  }
  componentWillUnmount() {
    if (this.props.device == "mobile") {
      this.props.toggleCanPop("");
    }
  }

  componentDidMount() {
    if (this.props.device == "mobile") {
      this.props.toggleCanPop("normal");
    } else if (this.props.device == "desktop") {
      /* redirect if logged in */
      const token = window.localStorage.getItem("token");
      if (token != null) {
        this.props.getUser({
          token,
          device: this.props.device,
          redirect: true
        });
        this.props.getSourceOfTruth({ token });
        //this.props.getStats({token});
      }
    }
    this.getIPAddress();
  }

  async getIPAddress() {
    const res = await axios.get("https://geolocation-db.com/json/");
    this.setState({
      ip_address: res?.data?.IPv4
    });
  }

  handleBack() {
    /* mobile */
    dismissMobileKeyboard();
    this.props.popSidePanel();
  }
  appleLogin(appleresponse = null) {
    /* mobile */
    dismissMobileKeyboard();
    const {
      email,
      password,
      device,
      initialRedirect,
      platform,
      user_info,
      getUserFromAppleAuth
    } = this.props;
    if (appleresponse) {
      const cookies = getCookies();

      getUserFromAppleAuth({
        appleToken:
          device === "mobile"
            ? appleresponse?.identityToken
            : appleresponse?.authorization?.id_token,
        onSuccess: results => {
          if (results?.user_exists) {
            this.props.loginUser({
              device,
              initialRedirect,
              apple_id:
                device === "mobile"
                  ? appleresponse?.identityToken
                  : appleresponse?.authorization?.id_token,
              onLoading: () => {
                this.setState({
                  oauth_loading: true
                });
              },
              onError: () => {
                this.setState({
                  oauth_loading: false
                });
              },
              onSuccess: () => {
                this.setState({
                  oauth_loading: false
                });
                this.props.popAllSidePanels();
              }
            });
          } else {
            this.props.registerUser({
              email: appleresponse?.user?.email || results?.user_email,
              firstname: appleresponse?.user?.name?.firstName,
              lastname: appleresponse?.user?.name?.lastName,
              accepted_terms: 1,
              check_phone: 0,
              initialRedirect,
              from_campaign: user_info?.from_campaign,
              from_source: user_info?.from_source,
              branch_id: user_info?.branch_id,
              platform,
              device,
              apple_id: appleresponse?.authorization?.id_token,
              first_promoter_ref: cookies?.first_promoter_ref,
              first_promoter_cookie: cookies?.first_promoter_cookie,
              hubspot_cookie: cookies?.hubspot_cookie,
              hubspot_page_url:
                this.props.device == "mobile"
                  ? "dealmachine://"
                  : "https://app.dealmachine.com/sign-up",
              hubspot_page_title:
                this.props.device == "mobile"
                  ? "App Signup Page"
                  : "Web App Signup Page",
              ip_address: this.state.ip_address,
              onLoading: () => {
                this.setState({ oauth_loading: true });
              },
              onError: () => {
                this.setState({ oauth_loading: false });
              },
              onSuccess: () => {}
            });
          }
        }
      });
    } else {
      this.props.showErrorMessage("Apple authentication failed.", "Error");
    }
  }
  login(oAuth = null) {
    /* mobile */
    dismissMobileKeyboard();
    const { email, password, device, initialRedirect, platform, user_info } =
      this.props;
    const cookies = getCookies();
    if (oAuth) {
      const { email, familyName, givenName, imageUrl } = oAuth?.profileObj;
      this.props.setOAuthInfo(oAuth);
      this.props.registerUser({
        email: email,
        device,
        firstname: givenName,
        lastname: familyName,
        image: imageUrl,
        accepted_terms: 1,
        check_phone: 0,
        initialRedirect,
        oAuthToken: oAuth?.accessToken,
        oAuthIdToken: oAuth?.idToken,
        from_campaign: user_info?.from_campaign,
        from_source: user_info?.from_source,
        branch_id: user_info?.branch_id,
        first_promoter_ref: cookies?.first_promoter_ref,
        first_promoter_cookie: cookies?.first_promoter_cookie,
        hubspot_cookie: cookies?.hubspot_cookie,
        hubspot_page_url:
          this.props.device == "mobile"
            ? "dealmachine://"
            : "https://app.dealmachine.com/sign-up",
        hubspot_page_title:
          this.props.device == "mobile"
            ? "App Signup Page"
            : "Web App Signup Page",
        ip_address: this.state.ip_address,
        platform,
        onLoading: () => {
          this.setState({ oauth_loading: true });
        },
        onError: () => {
          this.setState({ oauth_loading: false });
        },
        onSuccess: () => {}
      });
    } else {
      this.props.loginUser({
        email,
        password,
        device,
        initialRedirect,
        onLoading: () => {
          this.setState({
            loading: true
          });
        },
        onError: () => {
          this.setState({
            loading: false
          });
        },
        onSuccess: () => {}
      });
    }
  }

  checkIfNeedsToSave() {
    if (
      (this.props.email && this.props.email != "") ||
      (this.props.password && this.props.password != "")
    ) {
      return true;
    }

    return false;
  }

  renderHeader() {
    if (this.props.device == "mobile") {
      return (
        <NewHeader
          title="Log In"
          leftButton={{
            onPress: this.handleBack,
            icon: this.props.isMobile ? "arrow-back" : "close"
          }}
        />
      );
    }
  }
  render() {
    if (this.props.no_container) {
      return (
        <Body
          {...this.props}
          login={this.login.bind(this)}
          checkIfNeedsToSave={this.checkIfNeedsToSave.bind(this)}
        />
      );
    }

    return (
      <Container title={"Log in to your account"}>
        {this.renderHeader()}
        <KeyboardView style={{ flex: 1 }}>
          <Body
            {...this.props}
            login={this.login.bind(this)}
            appleLogin={this.appleLogin.bind(this)}
            checkIfNeedsToSave={this.checkIfNeedsToSave.bind(this)}
            loading={this.state.loading}
          />
        </KeyboardView>
        {this.props.device == "mobile" ? (
          <BottomNavBar>
            <Row style={{ justifyContent: "space-between" }}>
              <InlineButton
                onPress={() => {
                  this.props.pushSidePanel({
                    slug: "forgot_password",
                    overlay: true
                  });
                }}
              >
                Forgot Password
              </InlineButton>

              <InlineButton onPress={displayIntercom} id="intercom_button">
                Need help? Talk to a human.
              </InlineButton>
            </Row>
          </BottomNavBar>
        ) : (
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              padding: 25
            }}
          >
            <InlineButton internal={true} rel={""} href={"/sign-up"}>
              Don't have an account yet? Get started here
            </InlineButton>
            <InlineButton internal={true} rel={""} href={"/forgot-password"}>
              Forgot Password
            </InlineButton>
          </Wrapper>
        )}

        {this.state.oauth_loading ? (
          <Wrapper
            style={{
              backgroundColor: "rgba(0,0,0,0.6)",
              position: this.props.device == "desktop" ? "fixed" : "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Spin color={this.props.colors.white_text_color} />
          </Wrapper>
        ) : null}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, drawer, marketing }) => {
  const { user, email, password, loading } = auth;
  const { device, platform, initialRedirect, isMobile } = native;
  const { colors } = settings;
  const { stats } = drawer;
  const { user_info } = marketing;
  return {
    user,
    email,
    password,
    loading,
    device,
    platform,
    stats,
    initialRedirect,
    isMobile,
    colors,
    user_info
  };
};

export default connect(mapStateToProps, {
  /*mobile*/
  toggleCanPop,
  appRedirect,

  getUser,
  getSourceOfTruth,
  getStats,
  setOAuthInfo,
  authFieldChanged,
  loginUser,
  registerUser,
  getUserFromAppleAuth,
  pushSidePanel,
  popAllSidePanels,
  replaceSidePanel,
  popSidePanel
})(Login);
