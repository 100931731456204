import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Wrapper,
  Copy,
  Bold,
  ProgressBar
} from "app/NativeComponents/common";
import {
  SpinWrapper,
  GhostButton,
  InlineButton
} from "app/NativeComponents/snippets";

import WorkspaceTeam from "./WorkspaceTeam";

import MainHeader from "app/DealMachineCore/reuseable/MainHeader";

import {
  getWorkspaceTeams,
  pushSidePanel,
  showSuccess,
  resendWorkspaceTeamInvitation,
  setModal,
  toggleModal,
  editWorkspaceTeam
} from "app/NativeActions";

class WorkspaceManageTeams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teams_loading: false
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    // Add any necessary initialization logic

    this.getData();
  }

  getData() {
    const { token } = this.props;
    this.props.getWorkspaceTeams({
      token,
      onLoading: () => {
        this.setState({ teams_loading: true });
      },
      onError: () => {
        this.setState({ teams_loading: false });
      },
      onSuccess: results => {
        this.setState({
          teams_loading: false
        });
      }
    });
  }

  render() {
    const { teams_loading } = this.state;
    const { workspace_teams, colors, source_of_truth } = this.props;

    const teams_current_limit = source_of_truth?.subscription?.metadata
      ?.number_of_workspace_teams
      ? parseInt(
          source_of_truth?.subscription?.metadata?.number_of_workspace_teams
        )
      : 0;
    const teams_count = parseInt(
      source_of_truth?.current_limits?.number_of_workspace_teams?.amount
    );

    return (
      <>
        <Row style={{ flex: 1, overflow: "hidden" }}>
          <Wrapper
            style={{ flex: 1, alignSelf: "stretch", position: "relative" }}
          >
            <Row>
              <Wrapper style={{ flex: 1 }}>
                <MainHeader title={"Manage Teams"} />
              </Wrapper>
              {workspace_teams.length > 0 ? (
                <Wrapper style={{ paddingTop: 25 }}>
                  <InlineButton
                    onPress={() => {
                      this.props.pushSidePanel({
                        slug: "add_new_team",
                        overlay: true
                      });
                    }}
                    icon="add"
                    primary={true}
                    tooltip={
                      teams_count >= teams_current_limit
                        ? "You have reached the maximum number of teams allowed for your plan. Talk to your customer success manager to upgrade your plan."
                        : ""
                    }
                    disabled={teams_count >= teams_current_limit}
                  >
                    Add New Team
                  </InlineButton>
                </Wrapper>
              ) : null}
            </Row>

            {teams_loading ? (
              <SpinWrapper text={"Loading"} />
            ) : workspace_teams.length == 0 ? (
              <>
                <Wrapper
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <Wrapper style={{ padding: 15 }}>
                    <Copy style={{ textAlign: "center" }}>
                      You have not added any teams to your account.
                    </Copy>
                  </Wrapper>

                  <GhostButton
                    primary={true}
                    onPress={() => {
                      this.props.pushSidePanel({
                        slug: "add_new_team",
                        overlay: true
                      });
                    }}
                    icon="add"
                    style={{ marginBottom: 15 }}
                  >
                    Add New Team
                  </GhostButton>
                </Wrapper>
              </>
            ) : (
              <>
                {workspace_teams.map((team, index) => {
                  return (
                    <WorkspaceTeam
                      key={"team_" + index}
                      team={team}
                      colors={colors}
                      token={this.props.token}
                      showSuccess={this.props.showSuccess}
                      resendWorkspaceTeamInvitation={
                        this.props.resendWorkspaceTeamInvitation
                      }
                      source_of_truth={this.props.source_of_truth}
                      pushSidePanel={this.props.pushSidePanel}
                      setModal={this.props.setModal}
                      toggleModal={this.props.toggleModal}
                      editWorkspaceTeam={this.props.editWorkspaceTeam}
                    />
                  );
                })}
              </>
            )}
          </Wrapper>
        </Row>
      </>
    );
  }
}

const mapStateToProps = ({ auth, native, billing, settings, workspaces }) => {
  const { token, user, user_partner_info } = auth;
  const { device, isIphoneX, isMobile, platform, desktopMobile } = native;
  const { colors } = settings;
  const { workspace_teams } = workspaces;
  const { source_of_truth } = billing;
  return {
    user,
    user_partner_info,
    token,
    device,
    isIphoneX,
    isMobile,
    platform,
    desktopMobile,
    colors,
    workspace_teams,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  getWorkspaceTeams,
  pushSidePanel,
  showSuccess,
  resendWorkspaceTeamInvitation,
  setModal,
  toggleModal,
  editWorkspaceTeam
})(WorkspaceManageTeams);
