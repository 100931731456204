import React, { Component } from "react";
import { connect } from "react-redux";

import {
  KeyboardView,
  Container,
  LeftPanelContainer,
  WebContainer,
  Wrapper,
  CardBody,
  Spin,
  Row,
  Copy,
  Bold
} from "app/NativeComponents/common";
import {
  NewHeader,
  SearchBar,
  BottomNavBar,
  InlineButton,
  UpsellButton,
  SelectItem
} from "app/NativeComponents/snippets";

import {
  appRedirect,
  popSidePanel,
  pushSidePanel,
  toggleModal,
  setModal,
  selectActiveTeamMember,
  getTeamMembers,
  toggleCanPop,
  lockDrawer,
  setInviteType,
  toggleDrawer,
  dismissMobileKeyboard,
  checkIfUserHasMetadata,
  submitMarketingForm,
  numberWithCommas
} from "app/NativeActions";

import TeamList from "./TeamList";

class Team extends Component {
  constructor(props) {
    super(props);

    this.state = {
      team_search: "",
      team_tab: "all",
      team_tab_title: "All",
      loading_sales_form: false,
      submitted_sales_form: false
    };
    this.updateTeamSearch = this.updateTeamSearch.bind(this);
    this.toggleTeamTab = this.toggleTeamTab.bind(this);

    this.handleBack = this.handleBack.bind(this);
  }

  updateTeamSearch(value) {
    this.setState({
      team_search: value
    });
  }

  toggleTeamTab({ value, title }) {
    this.setState({
      team_tab: value,
      team_tab_title: title
    });
  }

  componentDidMount() {
    this.props.selectActiveTeamMember(null);
    //TODO double check billing?
    this.loadItems();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.team_search !== this.state.team_search) {
      clearInterval(this._search_interval);
      this._search_interval = setTimeout(() => {
        this.loadItems();
      }, 250);
    } else if (prevState.team_tab !== this.state.team_tab) {
      this.loadItems();
    }
  }

  componentWillUnmount() {
    clearInterval(this._search_interval);
  }

  loadItems() {
    this.props.getTeamMembers({
      token: this.props.token,
      load_type: "load",
      type: this.state.team_tab,
      search: this.state.team_search,
      begin: 0
    });
  }

  renderBody() {
    if (this.props.team_members) {
      return (
        <>
          <TeamList
            team_search={this.state.team_search}
            team_tab={this.state.team_tab}
            team_tab_title={this.state.team_tab_title}
            updateTeamSearch={this.updateTeamSearch}
            toggleTeamTab={this.toggleTeamTab}
            {...this.props}
          />
        </>
      );
    }

    return null;
  }

  handleBack() {
    dismissMobileKeyboard();
    this.props.popSidePanel();
  }

  render() {
    const { source_of_truth, all_feature_metadata } = this.props;
    let total_number_of_team_members_current_limit = parseInt(
      source_of_truth?.subscription?.metadata?.number_of_team_members
    );
    if (!!source_of_truth?.subscription?.metadata?.number_of_drivers) {
      total_number_of_team_members_current_limit += parseInt(
        source_of_truth?.subscription?.metadata?.number_of_drivers
      );
    }

    if (!!source_of_truth?.subscription?.metadata?.additional_team_members) {
      total_number_of_team_members_current_limit += parseInt(
        source_of_truth?.subscription?.metadata?.additional_team_members
      );
    }

    const total_number_of_team_members_count = parseInt(
      this.props.user?.teammate_count
    );

    let lock_invite_button = false;
    let prompt_user_to_upgrade = false;
    let feature_upsell_info = null;

    if (
      total_number_of_team_members_current_limit !== "unlimited" &&
      total_number_of_team_members_count >=
        total_number_of_team_members_current_limit
    ) {
      prompt_user_to_upgrade = true;
      for (let i = 0; i < all_feature_metadata?.length; i++) {
        if (all_feature_metadata[i].slug === "number_of_team_members") {
          console.log(all_feature_metadata[i].unlock_limit);
        }
        if (
          all_feature_metadata[i].slug === "number_of_team_members" &&
          all_feature_metadata[i].unlock_limit >=
            total_number_of_team_members_count &&
          (all_feature_metadata[i].unlock_limit <
            feature_upsell_info?.unlock_limit ||
            feature_upsell_info == null)
        ) {
          feature_upsell_info = all_feature_metadata[i];
        }
      }

      if (!feature_upsell_info) {
        lock_invite_button = true;
      }
    }

    if (
      !this.props.isMobile ||
      this.props.active_team_member == null ||
      this.props.device == "mobile"
    ) {
      return (
        <Container>
          <NewHeader
            title="Team"
            leftButton={{
              icon: "arrow-back",
              onPress: this.handleBack
            }}
          />
          <Row>
            <Wrapper style={{ flex: 1 }}>
              <SearchBar
                title={"Search Team"}
                onChange={value => {
                  this.setState({
                    team_search: value
                  });
                }}
                value={this.state.team_search}
                style={{
                  margin: 10,
                  borderWidth: 0,
                  borderBottomWidth: 0,
                  marginRight: 0
                }}
              />
            </Wrapper>
            {this.props.user?.can_mimic_team_owner == 1 ? (
              <>
                <InlineButton
                  tooltip={
                    lock_invite_button
                      ? "You have reached your team member limit. Talk to sales to increase your limit."
                      : null
                  }
                  tooltipPlacement="top"
                  disabled={lock_invite_button}
                  icon={lock_invite_button ? "lock" : null}
                  onPress={() => {
                    if (!checkIfUserHasMetadata("paid_plan")) {
                      this.props.pushSidePanel({
                        slug: "purchase_plan_prompt",
                        overlay_modal: true,
                        data: {
                          title: "Grow your team With DealMachine Pro.",
                          dismiss_button:
                            "No thanks. Let me continue without a team.",
                          no_free: true,
                          event_slug: "in_app_team_settings"
                        },
                        locked: true
                      });
                    } else {
                      if (prompt_user_to_upgrade) {
                        this.props.pushSidePanel({
                          slug: "unlock_feature_modal",
                          overlay: true,
                          data: {
                            feature_upsell_info,
                            renderContent: () => {
                              return (
                                <Wrapper style={{ padding: 25 }}>
                                  <Copy>
                                    You have{" "}
                                    <Bold>
                                      {numberWithCommas(
                                        total_number_of_team_members_count
                                      )}{" "}
                                      team members
                                    </Bold>{" "}
                                    team members on your account. Adding another
                                    team member will exceed your limit of{" "}
                                    <Bold>
                                      {numberWithCommas(
                                        total_number_of_team_members_current_limit
                                      )}
                                    </Bold>
                                    . Upgrade to continue.
                                  </Copy>
                                </Wrapper>
                              );
                            },
                            buttonText: "Upgrade And Invite",
                            confirmText: "Confirm Upgrade?",
                            onSuccess: () => {
                              this.props.pushSidePanel({
                                slug: "invite_by_email",
                                overlay: true
                              });
                            }
                          }
                        });
                      } else {
                        this.props.pushSidePanel({
                          slug: "invite_by_email",
                          overlay: true
                        });
                      }
                    }
                  }}
                  style={{ marginRight: 0, marginLeft: 0 }}
                >
                  {this.props.isMobile ? "Invite" : "Invite By Email"}
                </InlineButton>
                {/*checkIfUserHasMetadata("driver_recruitment") ? (
                  <Wrapper
                    style={{
                      alignSelf: "stretch",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <UpsellButton
                      type="plan"
                      slug="driving"
                      contentful_slug="unlock_recruit_drivers"
                      tier={2}
                      meta_slug="driver_recruitment"
                      onPress={() => {
                        this.props.pushSidePanel({
                          slug: "recruit_drivers",
                          overlay: true,
                          focus_side_panel: true
                        });
                      }}
                      renderLockedChildren={({ hovering }) => {
                        return (
                          <InlineButton
                            icon="lock"
                            noPress={true}
                            pressedIn={hovering}
                            primary={true}
                          >
                            Recruit Drivers
                          </InlineButton>
                        );
                      }}
                      renderChildren={({ hovering }) => {
                        return (
                          <InlineButton
                            noPress={true}
                            pressedIn={hovering}
                            primary={true}
                          >
                            Recruit Drivers
                          </InlineButton>
                        );
                      }}
                    />
                  </Wrapper>
                ) : null*/}
              </>
            ) : null}
          </Row>
          <KeyboardView style={{ flex: 1 }}>{this.renderBody()}</KeyboardView>
          {this.props.user?.can_mimic_team_owner == 1 &&
          !this.props.user?.workspace_id &&
          lock_invite_button ? (
            <BottomNavBar>
              {this.state.submitted_sales_form ? (
                <Wrapper style={{ padding: 25 }}>
                  <Copy style={{ textAlign: "center" }}>
                    Thanks for reaching out! We'll be in touch soon!
                  </Copy>
                </Wrapper>
              ) : (
                <SelectItem
                  noPress={true}
                  select_type="none"
                  renderRight={() => {
                    return (
                      <InlineButton
                        loading={this.state.loading_sales_form}
                        primary={true}
                        onPress={() => {
                          this.props.submitMarketingForm({
                            token: this.props.token,
                            type: "contact_sales",
                            onLoading: () => {
                              this.setState({
                                loading_sales_form: true
                              });
                            },
                            onError: () => {},
                            onSuccess: () => {
                              this.setState({
                                loading_sales_form: false,
                                submitted_sales_form: true
                              });
                            }
                          });
                        }}
                      >
                        Contact Sales
                      </InlineButton>
                    );
                  }}
                  description="Contact us for custom pricing for larger scale operations."
                >
                  Need more data? Have a larger team?
                </SelectItem>
              )}
            </BottomNavBar>
          ) : null}
        </Container>
      );
    }

    return null;
  }
}

const mapStateToProps = ({ auth, settings, native, billing, team }) => {
  const { token, user } = auth;
  const { colors } = settings;

  const { device, platform, isMobile } = native;

  const { plan_modules, card_info, source_of_truth, all_feature_metadata } =
    billing;

  const {
    active_team_member,
    team_members,
    team_members_loading,
    team_members_error,
    team_members_refreshing,
    team_members_loaded_all,
    team_members_limit,
    team_members_begin,
    team_members_search
  } = team;

  return {
    token,
    user,
    device,
    platform,
    isMobile,

    plan_modules,
    card_info,

    colors,

    active_team_member,
    team_members,
    team_members_loading,
    team_members_error,
    team_members_refreshing,
    team_members_loaded_all,
    team_members_limit,
    team_members_begin,
    team_members_search,

    source_of_truth,
    all_feature_metadata
  };
};

export default connect(mapStateToProps, {
  appRedirect,
  popSidePanel,
  pushSidePanel,
  toggleModal,
  setModal,
  selectActiveTeamMember,
  getTeamMembers,
  toggleCanPop,
  lockDrawer,
  setInviteType,
  toggleDrawer,
  submitMarketingForm
})(Team);
