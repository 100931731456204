import React, { Component } from "react";

import MLSInfo from "./MLSInfo";
import LienInfo from "./LienInfo";

import PropertyInfo from "./PropertyInfo";
import CustomFields from "./CustomFields";
import PropertyPhotos from "./PropertyPhotos";

import { ConfirmLabel } from "app/NativeComponents/snippets";
class AllPropertyInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info_search: ""
    };
  }

  render() {
    const {
      property,
      search,
      no_editing,
      user,
      include_custom_fields = true
    } = this.props;

    return (
      <>
        {this.props.show_photos ? (
          <PropertyPhotos property={property} {...this.props} />
        ) : (
          <>
            <MLSInfo {...this.props} search={search} property={property} />
            <PropertyInfo {...this.props} search={search} property={property} />
            <LienInfo {...this.props} search={search} property={property} />
            {property.deal && include_custom_fields ? (
              <CustomFields
                property={property}
                {...this.props}
                search={search}
                no_editing={no_editing}
              />
            ) : null}
          </>
        )}
      </>
    );
  }
}

export default AllPropertyInfo;
