import React, { Component } from "react";
import { Wrapper, Row, KeyboardView, Container  } from "app/NativeComponents/common";
import { NewHeader, SearchBar, InlineButton } from "app/NativeComponents/snippets";
import ListView from "./ListView";
import { connect } from "react-redux";
import {
  popSidePanel
} from "app/NativeActions";

class SelectExportColumns extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device === "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    this.state = {
      column_search: panel_data?.column_search || "",
      list_columns: panel_data?.list_columns || []
    };
  }

  handleBack() {
    this.props.popSidePanel();
  }

  checkIfNeedsToSave = () => {
    const initialColumns = (this.props.device === "mobile"
      ? this.props?.route?.params?.panel_data
      : this.props.panel_data)?.list_columns || [];
    const currentColumns = this.state.list_columns || [];

    const initialSelected = initialColumns.filter(col => col.show).map(col => col.slug);
    const currentSelected = currentColumns.filter(col => col.show).map(col => col.slug);

    return (
      initialSelected.length !== currentSelected.length ||
      !initialSelected.every(slug => currentSelected.includes(slug))
    );
  };

  checkListItem = (item) => {
    this.setState((prevState) => ({
      list_columns: prevState.list_columns.map((column) =>
        column.slug === item.slug ? { ...column, show: !column.show } : column
      ),
    }));
  };

  toggleAllSelection(selectAll) {
    this.setState({
      list_columns: this.state.list_columns.map(column => ({
        ...column,
        show: selectAll
      }))
    });
  }

  handleSave = () => {
    const selectedColumns = this.state.list_columns.filter(column => column.show);
    const listColumns = this.state.list_columns
    const panel_data =
      this.props.device === "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    if (panel_data?.onSave) {
      panel_data.onSave(selectedColumns, listColumns);
      panel_data.setIncludeAllColumns(false);
    }
    this.props.popSidePanel();
  };

  render()
  {
    const panel_data =
      this.props.device === "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;
    
    return (
      <Container>
        <NewHeader
          title="Select Columns to Export"
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <SearchBar
          style={{ margin: 10 }}
          title="Search"
          value={this.state.column_search}
          onChange={value => this.setState({ column_search: value })}
        />
        <Row>
          <InlineButton onPress={() => this.toggleAllSelection(true)}>
            Select All
          </InlineButton>
          <InlineButton onPress={() => this.toggleAllSelection(false)}>
            Clear All
          </InlineButton>
          <Wrapper style={{ marginLeft: "auto" }}>
            <InlineButton
              disabled={!this.checkIfNeedsToSave()}
              onPress={() => this.handleSave()}
            >
              Save &amp; Close
            </InlineButton>
          </Wrapper>
        </Row>
        <KeyboardView style={{ flex: 1 }}>
          <ListView
            {...this.props}
            column_search={this.state.column_search}
            list_columns={this.state.list_columns}
            checkListItem={this.checkListItem}
            updateListItemFormat={panel_data?.updateListItemFormat}
          />
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors, dark_mode } = settings;
  return {
    token,
    user,
    device,
    colors,
    dark_mode
  };
};
export default connect(mapStateToProps, {
  popSidePanel
})(SelectExportColumns);
